import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { BsInfoSquareFill } from 'react-icons/bs';
import { currencySymbols } from 'utils';
import { RIStyles } from 'pages/reference-implementation/Style';

const CostSummaryCard = ({ catalog, costEstimate, previewButton, submitButton, validateButton, handleCostInfoBtn }) => {
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Typography sx={RIStyles.estimatedCostSum}>Estimated Cost Summary</Typography>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                <Box sx={RIStyles?.CostSummary?.cardWrapper}>
                    <Grid container>
                        <Grid item xs={10} sx={{ textAlign: 'left' }}>
                            <Box sx={RIStyles?.CostSummary?.labelStyle}>Total One Time Cost:</Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <Box sx={RIStyles?.CostSummary?.valueStyle}>
                                    {costEstimate?.totalOneTimeCost?.estCost?.toString() && currencySymbols[costEstimate?.totalOneTimeCost?.currency]}
                                    {(costEstimate?.totalOneTimeCost?.estCost?.toString() && costEstimate?.totalOneTimeCost?.estCost?.toFixed(2)) || '---'}
                                </Box>
                                {costEstimate?.totalRecurringCost?.estCost?.toString() && (
                                    <Box component="span" sx={RIStyles?.CostSummary?.squareInfoIcon}>
                                        <BsInfoSquareFill
                                            data-testid="onetime-btn"
                                            onClick={() => handleCostInfoBtn('one time')}
                                            style={RIStyles?.CostSummary?.infoIcon}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" sx={{ mx: 0, my: '10px' }} />
                    <Grid container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Grid
                            item
                            xs={`${
                                costEstimate?.totalRecurringCost?.estCost?.toString() &&
                                costEstimate?.totalRecurringCost?.estCost?.toString().split('.')?.[0]?.length > 4
                                    ? 7
                                    : 10
                            }`}
                            sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', textAlign: 'left' }}
                        >
                            <Box sx={RIStyles?.CostSummary?.labelStyle}>Total Monthly Recurring Cost:</Box>
                        </Grid>
                        <Grid
                            item
                            xs={`${
                                costEstimate?.totalRecurringCost?.estCost?.toString() &&
                                costEstimate?.totalRecurringCost?.estCost?.toString().split('.')?.[0]?.length > 4
                                    ? 5
                                    : 2
                            }`}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <Box sx={RIStyles?.CostSummary?.colon}>&nbsp;</Box>
                                <Box sx={RIStyles?.CostSummary?.valueStyle}>
                                    {costEstimate?.totalRecurringCost?.estCost?.toString() && currencySymbols[costEstimate?.totalRecurringCost?.currency]}
                                    {(costEstimate?.totalRecurringCost?.estCost?.toString() && costEstimate?.totalRecurringCost?.estCost?.toFixed(2)) || '---'}
                                </Box>
                                {costEstimate?.totalRecurringCost?.estCost?.toString() && (
                                    <Box component="span" sx={RIStyles?.CostSummary?.squareInfoIcon}>
                                        <BsInfoSquareFill
                                            data-testid="monthly-btn"
                                            onClick={() => handleCostInfoBtn('monthly')}
                                            style={RIStyles?.CostSummary?.infoIcon}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={1} sx={{ py: 1 }}>
                    {previewButton && (
                        <Grid item xs={12} sx={RIStyles?.CostSummary?.cartWrapper}>
                            {previewButton}
                        </Grid>
                    )}
                    <Grid item xs={12} sx={RIStyles?.CostSummary?.cartWrapper}>
                        <Box sx={RIStyles?.CostSummary?.orText}>OR</Box>
                    </Grid>
                    {submitButton && (
                        <Grid item xs={12} sx={RIStyles?.CostSummary?.cartWrapper}>
                            {submitButton}
                        </Grid>
                    )}
                    {validateButton && (
                        <Grid item xs={12} sx={RIStyles?.CostSummary?.cartWrapper}>
                            {validateButton}
                        </Grid>
                    )}
                </Grid>
                {catalog && (
                    <Box sx={RIStyles?.CostSummary?.notes}>
                        <Typography component="span" sx={RIStyles?.CostSummary?.noteBold}>
                            Note:{' '}
                        </Typography>
                        {`${catalog?.name} has pre-approved ISA: ${catalog?.['isa-number']}, however application ISA request should be submitted and it should be completed before Production Go-Live.`}
                    </Box>
                )}
            </Box>
        </>
    );
};
CostSummaryCard.propTypes = {
    catalog: PropTypes.any,
    submitButton: PropTypes.element,
    previewButton: PropTypes.element,
    validateButton: PropTypes.element,
    costEstimate: PropTypes.any,
    handleCostInfoBtn: PropTypes.func,
};

CostSummaryCard.defaultProps = {
    catalog: undefined,
    costEstimate: undefined,
    submitButton: undefined,
    previewButton: undefined,
    validateButton: undefined,
    handleCostInfoBtn: () => null,
};

export default React.memo(CostSummaryCard);
