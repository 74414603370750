import homeWorkIcon from 'assets/images/home_work.svg';
import devxDailyIcon from 'assets/images/devX_daily.svg';
import allInclusiveIcon from 'assets/images/all_inclusive.svg';
import adminPanelSettingsIcon from 'assets/images/admin_panel_settings.svg';
import devxLunchpadIcon from 'assets/images/devX_lunchpad.svg';

export const appListItems = [
    {
        icon: homeWorkIcon,
        label: 'DevX - Home',
        // path: process.env.REACT_APP_DEVX_HOME,
        disabled: true,
        customIcon: false,
    },
    {
        icon: adminPanelSettingsIcon,
        label: 'DevX  - Admin',
        // path: process.env.REACT_APP_DEVX_ADMIN,
        disabled: true,
        customIcon: false,
    },
    {
        icon: allInclusiveIcon,
        label: 'DevX - Agile',
        // path: process.env.REACT_APP_DEVX_AGILE,
        disabled: true,
        customIcon: false,
    },
    {
        image: devxDailyIcon,
        label: 'DevX - Daily',
        path: process.env.REACT_APP_DEVX_DAILY,
        disabled: false,
        customIcon: true,
    },
    {
        icon: devxLunchpadIcon,
        label: 'DevX - Launchpad',
        path: process.env.REACT_APP_DEVX_LAUNCHPAD,
        disabled: false,
        customIcon: false,
    },
];

export const APP_MENU_POPOVER_TITLE = 'DevX Apps';
