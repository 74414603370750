import { getRetentionPeriod } from 'utils';
import { getRetentionData } from 'utils/getRetentionData';

export const getKafkaOrderSummary = (item) => {
    return {
        catalog: item?.catalogType,
        'Cost Center': item?.catalogConfig?.environmentDetails?.costCenter,
        'Project Name': item?.catalogConfig?.environmentDetails?.projectName,
        'Topic Name': item?.catalogConfig?.topicName,
        Project: item?.catalogConfig?.project,
        Partitions: item?.catalogConfig?.partitionCount,
        'Business Unit': item?.catalogConfig?.environmentDetails?.businessUnit,
        'Retention Period': item?.catalogConfig?.retentionUnit
            ? getRetentionPeriod(item?.catalogConfig?.retentionPeriod, item?.catalogConfig?.retentionUnit)
            : getRetentionData(item?.catalogConfig?.retentionPeriod),
        Department: item?.catalogConfig?.environmentDetails?.department,
        'Application Support DL': item?.catalogConfig?.appSupportMailId,
        Cluster: item.catalogConfig?.cluster,
        ...(item?.catalogConfig?.ldapGroup && {
            'LDAP Group Name': item?.catalogConfig?.ldapGroup,
        }),
        'Write Access User': item?.catalogConfig?.producerId !== null ? 'Yes' : 'No',
        ...(item?.catalogConfig?.producerId && {
            'Write Access User Name': item?.catalogConfig?.producerId,
        }),
        'Read Access Required': item?.catalogConfig?.isReadAccessRequired ? 'Yes' : 'No',
        'Read Access User': item?.catalogConfig?.consumerId !== null ? 'Yes' : 'No',
        ...(item?.catalogConfig?.consumerId && {
            'Read Access User Name': item?.catalogConfig?.consumerId,
        }),
        ...(item?.catalogConfig?.consumerGroup && {
            'Consumer Group': item?.catalogConfig?.consumerGroup,
        }),
        'Transactional ID': item?.catalogConfig?.isTransactional ? 'Yes' : 'No',
        ...(item?.catalogConfig?.isTransactional && {
            'Transaction Access User': item?.catalogConfig?.transactionPrincipalId,
            'Transaction ID Prefix': item?.catalogConfig?.transactionPrefixText,
        }),
        ...(item?.catalogConfig?.size && {
            size: item?.catalogConfig?.size,
        }),
    };
};
export default {};
