import { OIDCRequestType, MACHINERequestType, ROLE_TO_SHOW, SIGNATURE_ROLE_TO_SHOW, SAMLRequestType } from './constant';

export const isReadyToSubmit = (order = {}, validationStates = {}) => {
    const { appUrlValid, loginUrlValid, rolesValid, destinationUrlValid, recipientUrlValid, serviceProviderSigningCert } = validationStates;

    // Check for missing required validation states for non-MACHINERequestTypes
    const requiredStates = ['appUrlValid', 'rolesValid', 'destinationUrlValid', 'recipientUrlValid', 'serviceProviderSigningCert'];
    const missingStates = order?.requestType !== MACHINERequestType && requiredStates?.filter((state) => !(state in validationStates));

    if (missingStates?.length > 0) {
        return { isValid: false, message: `Missing required validation states: ${missingStates?.join(', ')}` };
    }

    // Check for missing mandatory properties
    const mandatoryProperties = ['requestType', 'sector', 'environment', 'businessApplication', 'applicationCI', 'applicationName', 'appOwner'];
    const missingProperty = mandatoryProperties?.find((property) => !order?.[property]);

    if (missingProperty) {
        return { isValid: false, message: `Missing mandatory property: ${missingProperty}` };
    }

    // Check for appType
    if (order?.requestType === OIDCRequestType && !order?.appType) {
        return { isValid: false, message: 'App Type is missing' };
    }

    // Check for accessRestrictions
    if (order?.requestType === OIDCRequestType && order?.dataClassification?.toLowerCase() === 'internal' && !order?.accessRestrictions?.length) {
        return { isValid: false, message: 'Application Access Control is missing' };
    }

    // Check for dataClassification
    if (order?.requestType !== MACHINERequestType && !order?.dataClassification) {
        return { isValid: false, message: 'Data Classification is missing' };
    }

    // Check if roles are required based on dataClassification and authorizationRolesRequired
    if (ROLE_TO_SHOW.includes(order?.dataClassification?.toLowerCase()) && !order?.authorizationRolesRequired) {
        return { isValid: false, message: 'Authorization Roles are missing' };
    }

    // Check if roles are valid if authorizationRolesRequired is 'yes'
    if (order?.authorizationRolesRequired === 'yes' && ROLE_TO_SHOW.includes(order?.dataClassification?.toLowerCase()) && !rolesValid) {
        return { isValid: false, message: 'Authorization Roles are missing' };
    }

    // Check for applicationUrl
    if (order?.requestType === SAMLRequestType && !order?.applicationUrl) {
        return { isValid: false, message: 'Application URL is missing' };
    }

    // Check for appShortName
    if (
        order?.requestType !== MACHINERequestType &&
        !order?.appShortName &&
        (order?.dataClassification?.toLowerCase() === 'internal' ||
            (order?.dataClassification?.toLowerCase() !== 'internal' && order?.isIdxAttributeRequired === 'no'))
    ) {
        return { isValid: false, message: 'Application Short Name is missing' };
    }

    // Check if appUrlValid is true
    if (order?.requestType === SAMLRequestType && !appUrlValid) {
        return { isValid: false, message: 'Application URL is invalid' };
    }

    // Check for logoutUrl
    if (order?.requestType === OIDCRequestType && order?.isLogoutUrlRequired === 'yes' && !order?.logoutUrl) {
        return { isValid: false, message: 'Logout URL is missing' };
    }
    // Check for loginUrl
    if (order?.requestType === OIDCRequestType && (!order?.loginUrl || order?.loginUrl?.length === 0) && !loginUrlValid) {
        return { isValid: false, message: 'Login URL is missing' };
    }
    // Check for SAML-specific validations
    if (order?.requestType === SAMLRequestType) {
        if (!order?.recipientUrl) {
            return { isValid: false, message: 'Recipient URL is missing for SAML 2.0' };
        }
        if (!order?.destinationUrl) {
            return { isValid: false, message: 'Destination URL is missing for SAML 2.0' };
        }
        if (!order?.audienceURI) {
            return { isValid: false, message: 'Audience URI is missing for SAML 2.0' };
        }
        if (!destinationUrlValid) {
            return { isValid: false, message: 'Destination URL is invalid for SAML 2.0' };
        }
        if (!recipientUrlValid) {
            return { isValid: false, message: 'Recipient URL is invalid for SAML 2.0' };
        }
        if (!order?.nameIdFormat) {
            return { isValid: false, message: 'Name ID Format is missing for SAML 2.0' };
        }
        if (!serviceProviderSigningCert) {
            return { isValid: false, message: 'Service Provider Signing Certificate is missing for SAML 2.0' };
        }
        if (
            SIGNATURE_ROLE_TO_SHOW.includes(order?.dataClassification?.toLowerCase()) &&
            order?.isCertificateAvailable === 'no' &&
            order?.businessJustification === null
        ) {
            return { isValid: false, message: 'Business Justification is missing for SAML 2.0' };
        }
        if (
            SIGNATURE_ROLE_TO_SHOW.includes(order?.dataClassification?.toLowerCase()) &&
            order?.isCertificateAvailable === 'yes' &&
            order?.serviceProviderSigningCert === null
        ) {
            return { isValid: false, message: 'Service Provider Signing Certificate is missing for SAML 2.0' };
        }
        if (order?.serviceProviderSigningCert === '') {
            return { isValid: false, message: 'Service Provider Signing Certificate is empty for SAML 2.0' };
        }
    }

    // Check for idxAttribute
    if (
        order?.requestType !== MACHINERequestType &&
        order?.dataClassification?.toLowerCase() !== 'internal' &&
        order?.isIdxAttributeRequired === 'yes' &&
        !order?.idxAttribute
    ) {
        return { isValid: false, message: 'IDX Attribute is missing' };
    }

    return { isValid: true, message: 'All checks passed successfully' };
};

export const searchHardcodedGpids = (key, hardcodeGPIDS) => {
    if (!key || !hardcodeGPIDS || !Array.isArray(hardcodeGPIDS)) {
        return { isValid: false, data: ['No Data Found'] };
    }

    // Perform a case-insensitive search on gpid or name
    const lowerKey = key.toString().toLowerCase();
    const matchedValues = hardcodeGPIDS.filter(
        (entry) =>
            entry.gpid.toLowerCase() === lowerKey ||
            entry.name.toLowerCase().includes(lowerKey) ||
            `${entry.gpid} - ${entry.name}`.toLowerCase().includes(lowerKey)
    );

    if (matchedValues.length > 0) {
        return { isValid: true, data: matchedValues };
    }
    return { isValid: false, data: ['No Data Found'] };
};

export default isReadyToSubmit;
