import React from 'react';
import { Grid } from '@mui/material';
import FormCard from 'pages/reference-implementation/components/form-card';
import Form from 'pages/reference-implementation/provisioning/RI003O/components/Form';
import Heading from 'pages/reference-implementation/components/Heading';
import { useParams } from 'react-router-dom';
import { isShowAppRegionField, ShowAppUrlBasedOnCatalogCode } from 'pages/reference-implementation/provisioning/RI003O/utils';
import { RICatalogCode003, RICatalogCode003O, RICatalogCode003A, RICatalogCode005 } from 'pages/reference-implementation/utils/RICatalogCode';
import { useRecoilValue } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI003O/store';

const ApplicationSection = () => {
    const order = useRecoilValue(OrderPayload);
    const { catalogCode } = useParams();
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Heading title="Application" />
                </Grid>
            </Grid>
            <FormCard>
                <Grid container spacing={4}>
                    {/* Project section start */}
                    <Grid item xs={12} sm={12}>
                        <Form.IsExternalRadio />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.Application />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.ApplicationName />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.ApplicationShortName />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.AppOwnerSearch />
                    </Grid>
                    {/*  <Grid item xs={12} sm={6}>
                        <Form.ProjectId />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.BusinessUnit />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <Form.Sector />
                    </Grid>
                    {order?.sector && ['cgf']?.includes(order?.sector?.toLowerCase()) && (
                        <Grid item xs={12} sm={6}>
                            <Form.Division />
                        </Grid>
                    )}
                    {isShowAppRegionField({ order }) && (
                        <Grid item xs={12} sm={6}>
                            <Form.ApplicationRegion />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <Form.Environments />
                    </Grid>
                    {['production']?.includes(order?.environment?.toLowerCase()) && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <Form.ITEApplicationService />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.ISANumber />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={6}>
                        <Form.BudgetCode />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.BusinessContact />
                    </Grid>
                    {/*  <Grid item xs={12} sm={6}>
                        <Form.BillingInfo />
                    </Grid> */}
                    {/* Project section end */}
                    {/* <Form.Program /> */}
                    <Grid item xs={12} sm={6}>
                        <Form.DataClassification />
                    </Grid>
                    {/*  <Grid item xs={12} sm={6}>
                        <Form.BusinessApp />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.ApplicationService />
                    </Grid> */}
                    {order?.isExternal === 'Yes' && catalogCode && ShowAppUrlBasedOnCatalogCode?.includes(catalogCode) && (
                        <Grid item xs={12} sm={6}>
                            <Form.AppUrl />
                        </Grid>
                    )}
                    {order?.isExternal === 'Yes' && [RICatalogCode003, RICatalogCode003O, RICatalogCode003A, RICatalogCode005]?.includes(catalogCode) && (
                        <Grid item xs={12} sm={6}>
                            <Form.ApplicationSupportDl />
                        </Grid>
                    )}
                    {order?.isExternal === 'Yes' && [RICatalogCode003, RICatalogCode003O, RICatalogCode003A, RICatalogCode005]?.includes(catalogCode) && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <Form.CertificateOwnerOne />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.CertificateOwnerTwo />
                            </Grid>
                        </>
                    )}
                </Grid>
            </FormCard>
        </>
    );
};

export default ApplicationSection;
