import React from 'react';
import { Box, Card, CircularProgress, Divider, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useRecoilState, useRecoilValue } from 'recoil';
import Empty from 'components/empty';
import { currencySymbols } from 'utils';
import { cartStyle } from 'pages/cart/style';
import { BsInfoSquare } from 'react-icons/bs';
import { toggleCostModals, costSummaryData } from 'pages/cart/store';
import ApplicationCostDetails from './ApplicationCostDetails';

const CostSummary = ({ cartBtn, isLoading }) => {
    const [modal, setModal] = useRecoilState(toggleCostModals);
    const costEstimate = useRecoilValue(costSummaryData);
    const handleCostInfoBtn = (type) => {
        setModal({
            ...modal,
            isCost: { costType: type, isCostOpen: true },
        });
    };
    return (
        <Card sx={cartStyle?.CostSummary?.card}>
            <Box sx={cartStyle?.CostSummary?.headingWrapper}>
                <Typography sx={cartStyle?.CostSummary?.heading}>Cart Summary</Typography>
            </Box>
            <Divider />
            {isLoading && (
                <Empty>
                    <CircularProgress size={16} disableShrink sx={cartStyle?.CostSummary?.loaderIcon} />
                </Empty>
            )}
            {!isLoading && (
                <>
                    <Box className="customized-scrollbar" sx={cartStyle?.CostSummary?.summaryWrapper}>
                        <Grid container spacing={0} sx={cartStyle?.CostSummary?.costEstWrapper}>
                            <Grid item xs={12} sx={cartStyle?.CostSummary?.gridItemPadding}>
                                <Typography sx={cartStyle.CostSummary.application}>Application(s)</Typography>
                            </Grid>
                            {costEstimate?.cartSummary?.map((app, idx) => (
                                <ApplicationCostDetails app={app} key={idx} />
                            ))}
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sx={cartStyle?.CostSummary?.gridItemPadding}>
                                <Typography sx={cartStyle.CostSummary.totalCost}>Total Cost</Typography>
                            </Grid>
                            <Grid item xs={12} sx={cartStyle?.CostSummary?.qtyWrapper}>
                                <Box sx={cartStyle?.CostSummary?.labelStyle}>
                                    Total One-Time Cost:{' '}
                                    <span className="totonecost">
                                        {currencySymbols[costEstimate?.estimatedCosts?.totalOneTimeCost?.currency]}
                                        {costEstimate?.estimatedCosts?.totalOneTimeCost?.estCost?.toFixed(2)}
                                        <span>
                                            {' '}
                                            <BsInfoSquare
                                                onClick={() => handleCostInfoBtn('one time')}
                                                style={cartStyle?.CostSummary?.totalOneTImeInfo}
                                                data-testid="onetime-icon"
                                            />
                                        </span>
                                    </span>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={cartStyle?.CostSummary?.qtyWrapper}>
                                <Box sx={cartStyle?.CostSummary?.labelStyle}>
                                    Total Recurring Cost:{' '}
                                    <span className="totreccost">
                                        {currencySymbols[costEstimate?.estimatedCosts?.totalRecurringCost?.currency]}
                                        {costEstimate?.estimatedCosts?.totalRecurringCost?.estCost?.toFixed(2)}
                                        <span>
                                            <BsInfoSquare
                                                onClick={() => handleCostInfoBtn('monthly')}
                                                style={cartStyle?.CostSummary?.infoIconStyle}
                                                data-testid="monthly-icon"
                                            />
                                        </span>
                                    </span>
                                </Box>
                                <span style={cartStyle?.CostSummary?.unit} className="permonth">
                                    (per month)
                                </span>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={cartStyle?.CostSummary?.note}>
                                    <b>* Note: </b>Total est. cost does not include DR / Backup Services & PepsiCo internal overheads.
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {cartBtn}
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </Card>
    );
};
CostSummary.propTypes = {
    cartBtn: PropTypes.any,
    // costEstimate: PropTypes.any,
    isLoading: PropTypes.bool,
};

CostSummary.defaultProps = {
    cartBtn: undefined,
    // costEstimate: undefined,
    isLoading: false,
};

export default React.memo(CostSummary);
