// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Grid, Paper, Stack } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Modal from 'components/modal';
import { isDataDiskConfigFullfilled, sspSubmitOrder, toggleSspModels } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import Btn from 'components/button';
import Select, { CssTextField } from 'components/select';
import { addDataDisk, removeDataDisk, updateDataDisk, updateDataDiskByIndex, validateDisk } from 'pages/provisioning/store/day2-operation/selecters';
// import { FaTrashAlt } from 'react-icons/fa';
import deleteIcon from 'assets/images/delete_icon.svg';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import HelpImg from 'pages/provisioning/_components/helpImg';

const ConfigDataDiskModal = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, setIsHPConfig] = useRecoilState(isDataDiskConfigFullfilled);
    const [lastModifiedDDCount, setLastModifiedDDCount] = React.useState(undefined);
    const [lastModifiedDD, setLastModifiedDD] = React.useState(undefined);
    const addDisk = useSetRecoilState(addDataDisk);
    const removeDisk = useSetRecoilState(removeDataDisk);
    const validate = useSetRecoilState(validateDisk);
    const updateDisk = useSetRecoilState(updateDataDisk);
    const updateDiskIndex = useSetRecoilState(updateDataDiskByIndex);
    const storageTypeApi = useMutation(() => axios.get(`${endPoints.ssp.day2Operation.storageType}`));
    const reset = () => {
        setIsOpen({ ...isOpen, isDataDiskModalOpen: false });
        setOrder({
            ...order,
            dataDisks: lastModifiedDD,
            dataDisksCount: lastModifiedDDCount,
        });
        validate();
    };
    const changeSelection = (key, selected, index) => {
        updateDisk({ key, selected, index });
    };
    const updateDataDiskIndex = (key, selected, index) => {
        updateDiskIndex({ key, selected, index });
    };
    const isFormValid = () => {
        let errorCount = 0;
        let isDataDiskValid = false;
        if (order?.dataDisks?.length) {
            order?.dataDisks?.forEach((disk) => {
                if (
                    !(
                        disk?.lun !== null &&
                        disk?.diskName !== null &&
                        disk?.storageType !== null &&
                        disk?.diskSizeInGib !== null &&
                        disk?.maxIops !== null &&
                        disk?.maxThroughput !== null &&
                        disk?.encryption !== null &&
                        disk?.hostCaching !== null
                    )
                ) {
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
        }
        isDataDiskValid = errorCount === 0;
        return isDataDiskValid;
    };
    React.useEffect(() => {
        if (isOpen?.isDataDiskModalOpen) {
            setLastModifiedDD(order?.dataDisks);
            setLastModifiedDDCount(order?.dataDisksCount);
            storageTypeApi.mutate();
        }
    }, [isOpen?.isDataDiskModalOpen]);
    return (
        <Modal title="Config Data Disk" handleClose={() => reset()} open={isOpen.isDataDiskModalOpen}>
            <Box sx={ProvisioningStyles.modalBox.wrapper}>
                <Box sx={ProvisioningStyles.modalBox.contentWrapper}>
                    {order?.dataDisks?.map((disk, index) => (
                        <React.Fragment key={index.toString()}>
                            <Paper variant="outlined" sx={ProvisioningStyles.modalBox.paper} elevation={0} square>
                                <Grid container spacing={3.125}>
                                    <Grid item xs={6}>
                                        <Stack direction="column" spacing={2}>
                                            <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                                                <Select
                                                    data-testid={`lun${index.toString()}`}
                                                    key={`lun${index.toString()}`}
                                                    label="LUN"
                                                    value={disk?.lun || null}
                                                    options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                                                    handleOnSelect={(value) => updateDataDiskIndex('lun', value, index)}
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    getOptionLabel={(option) => option}
                                                    isRequired
                                                    sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                                />
                                                <HelpImg title="Logical unit number of the data disk. This value is used to identify data disks within the VM and therefore must be unique for each data disk attached to a VM." />
                                            </Stack>
                                            <CssTextField
                                                data-testid={`diskName${index.toString()}`}
                                                id="outlined-size-small"
                                                size="small"
                                                label="Disk Name"
                                                variant="outlined"
                                                fullWidth
                                                onChange={(e) => {
                                                    changeSelection('diskName', e.target.value, disk);
                                                }}
                                                value={disk?.diskName || ''}
                                                sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                                autoComplete="off"
                                                className={disk?.diskName === '' || disk?.diskName === null || disk?.diskName === undefined ? 'validate' : ''}
                                            />
                                            <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                                                <Select
                                                    key={`storageTypeName${index.toString()}`}
                                                    label="Storage Type"
                                                    value={disk?.storageType || null}
                                                    options={storageTypeApi?.data?.data || []}
                                                    handleOnSelect={(value) => updateDataDiskIndex('storageType', value, index)}
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    getOptionLabel={(option) => option}
                                                    isRequired
                                                    sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                                />
                                            </Stack>
                                            <Select
                                                key={`diskSizeInGib${index.toString()}`}
                                                label="Disk Size in GB"
                                                value={disk?.diskSizeInGib || null}
                                                options={['16', '32', '64', '120', '256', '512', '1024']}
                                                handleOnSelect={(value) => updateDataDiskIndex('diskSizeInGib', value, index)}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                getOptionLabel={(option) => option}
                                                isRequired
                                                sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack direction="column" spacing={2}>
                                            <Select
                                                key={`maxIops${index.toString()}`}
                                                label="Max IOPS"
                                                value={disk?.maxIops || null}
                                                options={['120', '240', '500', '1100', '2300', '5000', '7000', '16000', '18000', '20000']}
                                                handleOnSelect={(value) => updateDataDiskIndex('maxIops', value, index)}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                getOptionLabel={(option) => option?.toString()}
                                                isRequired
                                                sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                            />
                                            <Select
                                                key={`maxThroughput${index.toString()}`}
                                                label="Max Throughput"
                                                value={disk?.maxThroughput || null}
                                                options={['120', '240', '500', '1100', '2300', '5000', '7000', '16000', '18000', '20000']}
                                                handleOnSelect={(value) => updateDataDiskIndex('maxThroughput', value, index)}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                getOptionLabel={(option) => option?.toString()}
                                                isRequired
                                                sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                            />
                                            <Select
                                                key={`encryption${index.toString()}`}
                                                label="Encryption"
                                                value={disk?.encryption || null}
                                                options={['SSE with PMK', 'Platform managed key']}
                                                handleOnSelect={(value) => updateDataDiskIndex('encryption', value, index)}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                getOptionLabel={(option) => option?.toString()}
                                                isRequired
                                                sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                            />
                                            <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                                                <Select
                                                    key={`hostCaching${index.toString()}`}
                                                    label="Host Caching"
                                                    value={disk?.hostCaching || null}
                                                    options={['None', 'Read only', 'Read/write']}
                                                    handleOnSelect={(value) => updateDataDiskIndex('hostCaching', value, index)}
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    getOptionLabel={(option) => option?.toString()}
                                                    isRequired
                                                    sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                                />
                                                <HelpImg title="With disk caching enabled, VMs can achieve higher levels of performance." />
                                            </Stack>
                                            {order.dataDisks.length > 1 && (
                                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                                    <Box onClick={() => removeDisk(disk)} sx={ProvisioningStyles.dataDiskModel.deleteWrapper}>
                                                        {/* <FaTrashAlt
                                                            style={ProvisioningStyles.dataDiskModel.delete}
                                                            onClick={() => removeDisk(disk)}
                                                            className="w-7 h-7 text-red-base cursor-pointer"
                                                            data-testid={`remove-disk-${index.toString()}`}
                                                        /> */}
                                                        <img
                                                            data-testid={`remove-disk-${index.toString()}`}
                                                            style={ProvisioningStyles.dataDiskModel.delete}
                                                            src={deleteIcon}
                                                            alt="deleteIcon"
                                                        />
                                                    </Box>
                                                </Box>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </React.Fragment>
                    ))}
                </Box>
                <Box className="footer" sx={ProvisioningStyles.modalBox.actionWrapper}>
                    {order.dataDisks.length < order.maxDataDiskCount && (
                        <Btn className="custom-btn" variant="outlined" color="cmpPrimary" onClick={() => addDisk({ count: 1 })} data-testid="add-disk">
                            ADD DISK
                        </Btn>
                    )}
                    <Btn
                        className="custom-btn"
                        sx={ProvisioningStyles.modalBox.saveBtn}
                        variant="contained"
                        color="cmpPrimary"
                        onClick={() => {
                            setIsOpen({ ...isOpen, isDataDiskModalOpen: false });
                            setIsHPConfig(true);
                        }}
                        disabled={!isFormValid()}
                        data-testid="save"
                    >
                        SAVE
                    </Btn>
                    <Btn
                        className="custom-btn"
                        sx={ProvisioningStyles.modalBox.cancelBtn}
                        onClick={() => reset()}
                        variant="outlined"
                        color="cmpWarning"
                        layout="outline"
                        data-testid="cancel"
                    >
                        CANCEL
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfigDataDiskModal;
