import React from 'react';
import { FormControl, FormControlLabel, Box, Stack, Grid, Typography } from '@mui/material';
import GithubOnboardingStyles, { CustomRadioGroup, CustomRadio } from 'pages/github-onboarding/style';
import HelpImg from '../helpImg';

const MigrateOrCreateProject = () => {
    return (
        <Stack direction="row" spacing={1} sx={GithubOnboardingStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '36px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ ...GithubOnboardingStyles.radioFields.radioText, opacity: '0.5' }} id="autoinflateRadioGrp">
                    Do you want to create a new repository or migrate repositories?
                </Box>
                <CustomRadioGroup
                    data-testid="create-or-migrate-project"
                    aria-label="createOrMigrateProject"
                    name="createOrMigrateProjectRadioBtnGroup"
                    row
                    value="migrate"
                >
                    <FormControlLabel value="create" control={<CustomRadio disabled />} label="Create new" />
                    <FormControlLabel value="migrate" control={<CustomRadio disabled />} label="Migrate" />
                    <HelpImg
                        maxWidth="500px"
                        title={
                            <>
                                <Box sx={GithubOnboardingStyles.helpImgStyle.wrapper}>
                                    <Box sx={GithubOnboardingStyles.helpImgStyle.namingRuleWrapper}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography sx={GithubOnboardingStyles.helpImgStyle.typographyStyle}>
                                                    We&apos;re still building this page. Please reach out to SPA - GitHub Enterprise Support for assistance.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </>
                        }
                    />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default React.memo(MigrateOrCreateProject);
