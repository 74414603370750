import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { filterMenuItemsByRole, getUserScopes, LoggedUser, getLoggedUserEmail, getEnv } from 'utils';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useOktaAuth } from '@okta/okta-react';
// import DropDownMenu from 'components/dropdown-menu';
import { useHeaderStyle as style } from 'assets/style';
import { cartCount } from 'pages/cart/store';
import { AppBar, Toolbar, Button, Box, Grid, Badge, Avatar, Menu, Popover, Typography, Link, Divider } from '@mui/material';
// import { menuItems as MainNavigation } from 'utils/menuItems';
import CascadingHoverMenus from 'components/cascading-hover-menus';
// import closeIconBlack from 'assets/images/close-icon-black.svg';
import closeIcon from 'assets/images/close.svg';
import humbergerIcon from 'assets/images/apps.svg';
import headerStyles from 'assets/style/headerStyles';
import shoppingCart from 'assets/images/shopping-cart.svg';
import { appListItems } from './constants';

const AppHeader = ({ menus }) => {
    const history = useHistory();
    const location = useLocation();
    const okta = useOktaAuth();
    const [cmpCartCount, setCartCount] = useRecoilState(cartCount);
    const cartCountApi = useMutation(() => axios.get(endPoints.cart.getCartCount));
    // const persona = MainNavigation.find((menu) => menu.id === 'mypersona' && menu);
    const [activemenuItem, setActiveMenuItem] = React.useState(localStorage.getItem('activeMenu'));
    // const [switchToNewUI, setSwitchToNewUI] = React.useState(false);
    // const navItems = ['Home', 'About', 'Contact'];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorHumburgerEl, setHumberAnchorEl] = React.useState(null);
    // const [open, setOpen] = React.useState(false);
    // const [toggleZindex, settoggleZindex] = React.useState(false);
    const positionRef = React.useRef();
    const profileRef = React.useRef();

    const accessScopes = getUserScopes();
    const open = anchorEl;
    const openHumberger = anchorHumburgerEl;

    const getActiveItem = (path) => {
        if (path?.includes('/reference-implementation-services') || path?.includes('/reference-implementation-provisioning/')) {
            localStorage.setItem('activeMenu', 'Solutions');
            setActiveMenuItem('Solutions');
        } else if (
            path === '/services' ||
            path?.includes('provisioning') ||
            path?.includes('azure-key-vault') ||
            path?.includes('azure-load-balancer') ||
            path?.includes('app-microservices-stack') ||
            path?.includes('azure-application-gateway-url-onboarding') ||
            path?.includes('azure-internal-application-gateway-url-onboarding')
        ) {
            localStorage.setItem('activeMenu', 'Services');
            setActiveMenuItem('Services');
        } else if (path === '/orders') {
            localStorage.setItem('activeMenu', 'Orders');
            setActiveMenuItem('Orders');
        } else if (path === '/approvals') {
            localStorage.setItem('activeMenu', 'Approvals');
            setActiveMenuItem('Approvals');
        } else if (path === '/cart') {
            localStorage.setItem('activeMenu', 'cart');
            setActiveMenuItem('cart');
        } else if (
            path?.includes('/dashboard') ||
            path?.includes('/sku') ||
            path?.includes('/app-profile') ||
            path?.includes('/app-details') ||
            path?.includes('/landing-zones') ||
            path?.includes('/nsg-common-rule') ||
            path?.includes('/day2-operation') ||
            path?.includes('/sso-okta') ||
            path?.includes('/access-management-onboard-developers') ||
            path.includes('/mdt-onborading') ||
            path.includes('/security-tool') ||
            path.includes('/access-management-myidm') ||
            path.includes('/service-account-ci') ||
            path.includes('/project-onboarding') ||
            path.includes('/service-account-creation')
        ) {
            localStorage.setItem('activeMenu', 'More');
            setActiveMenuItem('More');
        } else {
            localStorage.setItem('activeMenu', '');
            setActiveMenuItem('');
        }
    };
    const initials = (str) =>
        str
            .match(/\b(\w)/g)
            .join('')
            .substring(0, 2)
            .toUpperCase();

    React.useEffect(() => {
        getActiveItem(location?.pathname);
    }, [location?.pathname]);
    React.useEffect(() => {
        cartCountApi.mutate();
    }, []);

    React.useEffect(() => {
        if (cartCountApi?.isSuccess) {
            setCartCount(cartCountApi?.data?.data?.itemCount || 0);
        }
    }, [cartCountApi?.isSuccess]);
    const handleMenuItemChange = (val) => {
        localStorage.setItem('activeMenu', val);
        setActiveMenuItem(val);
    };

    const handleHamburgerOpen = () => {
        setHumberAnchorEl(positionRef.current);
    };

    const handleHamburgerClose = () => {
        setHumberAnchorEl(null);
    };

    const handleOpen = () => {
        // settoggleZindex(true);
        setAnchorEl((prevAnchorEl) => (prevAnchorEl !== null ? null : profileRef.current));
    };

    // let timeoutId = null;

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    // const handleMenuClose = () => {
    //     settoggleZindex(false);
    //     setAnchorEl(null);
    // };

    // const handleMenuEnter = () => {
    //     settoggleZindex(true);
    //     if (timeoutId) {
    //         clearTimeout(timeoutId);
    //     }
    // };

    const handleLogout = () => {
        const newUser = localStorage.getItem('newUser');
        localStorage.clear();
        okta?.oktaAuth.tokenManager.clear();
        window.location.replace(getEnv('REACT_APP_OKTA_LOGOUT_URL'));
        localStorage.setItem('newUser', newUser);
    };

    const renderAppList = () => {
        return appListItems.map((item) => {
            return (
                <Link sx={{ ...headerStyles.link, opacity: item.disabled ? '0.65' : 'none' }} href={item.path} target="_blank" underline="none">
                    {item?.icon && (
                        <Box sx={{ ...headerStyles.humbergureImages }}>
                            <img src={item?.icon} alt={item?.label} />
                        </Box>
                    )}
                    {item?.image && (
                        <Box sx={headerStyles.listImage}>
                            <img src={item?.image} alt={item?.label} />
                        </Box>
                    )}
                    <span className="appLabel">{item?.label}</span>
                </Link>
            );
        });
    };

    return (
        <>
            <AppBar data-testid="header" position="static" sx={style.header} color="inherit">
                <Toolbar variant="dense" sx={{ paddingRight: '0!important', height: '64px' }}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Box sx={style.title}>
                                <Button className="headerLogo" component={NavLink} to="/" disableRipple disableFocusRipple>
                                    <img src="/static/images/devX_infra_logo.svg" alt="" />
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item sm sx={style.menuWrapper}>
                            <CascadingHoverMenus activeMenuItem={activemenuItem} menuItems={filterMenuItemsByRole(menus, accessScopes)} />
                        </Grid>
                        <Grid item sx={{ display: 'flex', gap: '20px', height: '61px' }}>
                            <Box
                                sx={{
                                    ...style.cart.container,
                                    display: { xs: 'none', sm: 'block' },
                                    padding: '20px',
                                    // borderBottom: `${activemenuItem === 'cart' && '4px solid #64C6EA'}`,
                                    borderBottom: `${activemenuItem === 'cart' && '2px solid #005CBC'}`,
                                }}
                                data-testid="cart-icon"
                                onClick={() => {
                                    history.push('/cart');
                                    setActiveMenuItem('');
                                    handleMenuItemChange('cart');
                                }}
                                name="cart"
                            >
                                <Badge
                                    badgeContent={cmpCartCount}
                                    sx={{
                                        '& .MuiBadge-badge': { fontWeight: '700 !important', color: '#AA001A !important' },
                                    }}
                                >
                                    <img src={shoppingCart} alt="shopping cart" style={style.cart.icon} />
                                </Badge>
                            </Box>
                            <Box
                                ref={profileRef}
                                sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    padding: '6px 0',
                                }}
                            >
                                <Button
                                    sx={{
                                        zIndex: (theme) => theme.zIndex.modal + 1,
                                        '&:hover, &.active': {
                                            background: 'none',
                                        },
                                        padding: '6px 0',
                                    }}
                                    onClick={handleOpen}
                                    // onMouseEnter={handleOpen}
                                    // onMouseLeave={handleClose}
                                    disableRipple
                                    disableFocusRipple
                                >
                                    <Avatar sx={style.small} type="button">
                                        {initials(LoggedUser().FullName())}
                                    </Avatar>
                                    <img src="/static/images/arrow_drop_down.svg" style={{ marginLeft: '2px' }} alt="" width={24} />
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleOpen}
                                    // MenuListProps={{
                                    //     onMouseLeave: handleMenuClose,
                                    //     onMouseEnter: handleMenuEnter,
                                    // }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    classes={{ paper: 'appProfileBox' }}
                                    transitionDuration={0}
                                >
                                    <Box display="flex" flexDirection="column" sx={{ ...style.profile.container, width: '400px !important' }}>
                                        <Box display="flex" sx={style.profile.label}>
                                            Profile
                                        </Box>
                                        <Box display="flex" gap={1.5}>
                                            <Box display="flex" sx={{ padding: '5px' }} flexDirection="column">
                                                <Box display="flex" gap={1}>
                                                    <img src="/static/images/account_circle.svg" alt="" width={25} />
                                                    <Box display="flex" sx={style.profile.profileUserName}>
                                                        {LoggedUser().FullName()}
                                                    </Box>
                                                </Box>
                                                <Box display="flex" sx={style.profile.email}>
                                                    {getLoggedUserEmail()}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ borderBottomWidth: '1.5px', marginTop: '5px' }} />
                                        <Box
                                            display="flex"
                                            gap={1.5}
                                            sx={{ marginTop: '5px', cursor: 'pointer', padding: '5px' }}
                                            onClick={() => handleLogout()}
                                        >
                                            <Box display="flex" gap={1}>
                                                <Box display="flex">
                                                    <img src="/static/images/exit_to_app.svg" alt="" width={25} />
                                                </Box>
                                                <Box display="flex" sx={style.profile.profileUserName}>
                                                    Logout
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Menu>
                            </Box>
                            <Box ref={positionRef}>
                                <Box sx={headerStyles.humbergureContainer} onClick={handleHamburgerOpen}>
                                    <img src={humbergerIcon} alt="Humberger Icon" />
                                </Box>
                                <Popover
                                    anchorEl={anchorHumburgerEl}
                                    open={openHumberger}
                                    onClose={handleHamburgerClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    classes={{ paper: 'appSwitcherBox' }}
                                    transitionDuration={0}
                                >
                                    <Grid container>
                                        <Grid item md={11}>
                                            <Typography sx={headerStyles.humbergureTitle}>DevX Apps</Typography>
                                        </Grid>
                                        <Grid item md={1}>
                                            <Box onClick={handleHamburgerClose} sx={headerStyles?.closeIconContainer}>
                                                <img style={headerStyles.humbergureCloseIcon} src={closeIcon} alt="Close Icon" />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box sx={headerStyles.imageContainer}>{renderAppList()}</Box>
                                </Popover>
                            </Box>
                            {/* <Box
                                data-testid="cart-icon"
                                onClick={() => {
                                    history.push('/cart');
                                    setActiveMenuItem('');
                                    handleMenuItemChange('cart');
                                }}
                                sx={{ ...style.cart.container, borderBottom: `${activemenuItem === 'cart' && '4px solid #64C6EA'}` }}
                                name="cart"
                            >
                                <Badge badgeContent={cmpCartCount} color="primary">
                                    <img src={shoppingCart} alt="shopping cart" style={style.cart.icon} />
                                </Badge>
                            </Box> */}
                            {/* <DropDownMenu
                                alignment="right"
                                name={persona.name}
                                subMenu={persona?.children}
                                handleChange={handleMenuItemChange}
                                activemenuItem={activemenuItem}
                            >
                                <ListItem sx={{ borderBottom: `${activemenuItem === persona.name && '4px solid #64C6EA'}` }}>
                                    <ListItemAvatar sx={style.avatarSize}>
                                        <Avatar sx={style.small} type="button">
                                            {initials(LoggedUser().FullName())}
                                        </Avatar>
                                    </ListItemAvatar>
                                </ListItem>
                            </DropDownMenu> */}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
};

AppHeader.propTypes = {
    menus: PropTypes.any,
};
AppHeader.defaultProps = {
    menus: [],
};

export default React.memo(AppHeader);
