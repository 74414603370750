import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';

export const SCARoles = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [options, setOptions] = React.useState([]);

    const rolesApi = useMutation(() => axios.get(endPoints.day2Operation.ProjectOnboarding.Jwt.scaRoles));

    // const setADOProjectType = (scaRoles) => {
    //     setOrder({
    //         ...order,
    //         scaRoles,
    //         snykMemberDetails: null,
    //         scaMember: null,
    //         ...(((order?.isADOProject === 'no' && order?.isClarityId === 'no') ||
    //             (order?.isADOProject === 'no' && order?.isClarityId === 'yes' && order?.clarityDetails?.devopsProject === null)) && {
    //             serviceResources: {
    //                 ...order.serviceResources,
    //                 isProjectAdmin: false,
    //                 isBoard: false,
    //                 isRepos: false,
    //                 isPipelines: false,
    //                 isSonarQube: false,
    //                 isArtifactory: false,
    //                 isSecurityTools: false,
    //                 isTestPlan: false,
    //             },
    //         }),
    //     });
    //     setValidForm({ ...validForm, isSCAMemberOrganizationInvalid: false, isDuplicateSCAMember: false });
    // };

    const setRoles = (scaRoles) => {
        setOrder({
            ...order,
            scaRoles,
        });
        setValidForm({ ...validForm, isSCAMemberOrganizationInvalid: false, isDuplicateSCAMember: false });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (scaRoles) => options.find((option) => scaRoles === option.value);

    // const handleOnSelectChange = (selected) => setADOProjectType(selected.value);
    const handleOnSelectChange = (selected) => setRoles(selected.value);

    React.useEffect(() => {
        if (rolesApi?.isSuccess) {
            setOptions(rolesApi?.data?.data?.map((app) => ({ label: app, value: app })));
        }
    }, [rolesApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.scaRoles) {
            setOrder({ ...order, scaRoles: options?.[0].value });
        }
        if (options && options?.length && order?.scaRoles) {
            const scaRoles = isExistsInOptions(order?.scaRoles);
            setOrder({ ...order, scaRoles: scaRoles?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        rolesApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.scaRoles && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.scaRoles, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="scaRoles-autocomplete"
                key="scaRoles"
                label="Roles *"
                value={order?.scaRoles || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={
                    rolesApi.isLoading ||
                    order?.adoPlatform !== 'Production' ||
                    (order?.adoPlatform === 'Production' && !order?.projectName && !order?.existingProjectName) ||
                    (order?.adoPlatform === 'Production' && validForm?.isProjectNameExists)
                }
                isLoading={rolesApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(SCARoles);
