import React from 'react';
import { useRecoilState } from 'recoil';
import { landZoneListFilter, toggleSummaryLZ } from 'pages/app-profiles/store';
import { Box, CircularProgress, Typography } from '@mui/material';
import LabelValue from 'components/label-value';
import Btn from 'components/button';
import Modal from 'components/modal';
import Styles from 'pages/app-profiles/landing-zones/style';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import toast from 'react-hot-toast';
import { getPayload } from './getPayload';

const LzSummary = () => {
    const [filterObj, setFilterObj] = useRecoilState(landZoneListFilter);
    const [lzSummary, setLzSummary] = useRecoilState(toggleSummaryLZ);
    const updateApi = useMutation((payload) => axios.put(`${endPoints.landingZones.approveDraftLZ}`, payload));
    const handleCloseModal = () => setLzSummary({ ...lzSummary, isOpen: false, lzDetails: null, form: null });
    const handleSubmit = async () => {
        const payload = await getPayload({
            appName: lzSummary?.form?.appProfileName,
            data: lzSummary?.form,
            type: 'edit',
        });
        const newPayload = { ...payload, isDraft: false };
        updateApi?.mutate(newPayload);
    };
    React.useEffect(() => {
        if (updateApi?.isSuccess) {
            setFilterObj({ ...filterObj, pageNumber: filterObj?.pageNumber });
            handleCloseModal();
            toast.success(updateApi?.data?.messages);
        }
    }, [updateApi?.isSuccess]);
    return (
        <Modal title={lzSummary?.lzDetails?.appProfileName || ''} handleClose={() => handleCloseModal()} open={lzSummary?.isOpen} sx={{ width: 'fit-content' }}>
            <>
                <Box sx={Styles?.summaryModal?.wrapper} className="lz-summary">
                    {lzSummary?.lzDetails && (
                        <>
                            <Box sx={Styles?.summaryModal?.contentWrapper}>
                                <Typography sx={Styles?.summaryModal?.heading} variant="subtitle1">
                                    Summary
                                </Typography>
                                {lzSummary?.lzDetails &&
                                    Object.keys(lzSummary?.lzDetails || {}).map((key, index) => (
                                        <React.Fragment key={`box${index}`}>
                                            {!['isDraft', 'id', 'appProfileName']?.includes(key) && (
                                                <Box className="app-details" key={`appDetails${key}`}>
                                                    <LabelValue key={[key]} label={key} value={lzSummary?.lzDetails[key]} />
                                                </Box>
                                            )}
                                        </React.Fragment>
                                    ))}
                            </Box>
                            {lzSummary?.lzDetails?.isDraft && (
                                <Box className="footer" sx={Styles?.summaryModal?.actionWrapper}>
                                    <Btn
                                        className="custom-btn"
                                        variant="contained"
                                        color="cmpPrimary"
                                        onClick={() => handleSubmit()}
                                        sx={{ textTransform: 'none' }}
                                        data-testid="approve"
                                    >
                                        Approve
                                        {updateApi?.isLoading && <CircularProgress sx={Styles?.summaryModal?.circularIcon} color="inherit" size={14} />}
                                    </Btn>
                                </Box>
                            )}
                            <Box className="footer" sx={Styles?.summaryModal?.supportWrapper}>
                                Have a question? Email our support team at{' '}
                                <Box component="span" sx={{ color: '#0047BA' }}>
                                    &nbsp;<a href="mailto:DL - Global DevX Operations?subject= &body= ">DL - Global DevX Operations</a>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </>
        </Modal>
    );
};

LzSummary.propTypes = {};

LzSummary.defaultProps = {};

export default LzSummary;
