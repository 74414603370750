export const COMMON = {
    projectId: 'api/static/lookups/v2/1.0/clarity-project/{projectId}',
    sectors: 'api/static/lookups/v2/1.0/sectors',
    regions: 'api/static/lookups/v2/1.0/regions',
    dataClassification: 'api/static/lookups/v2/1.0/data-classifications/{RID}',
    environments: 'api/static/lookups/v2/1.0/environments',
    program: 'api/static/lookups/v2/1.0/program',
    application: 'api/cmdb/lookups/1.0/application-service',
    cosmosPostgreSizes: 'api/static/lookups/v2/1.0/cosmos-postgre/sizes',
    azureServiceBus: 'api/static/lookups/v2/1.0/servicebus/sizes',
    applicationService: 'api/cmdb/lookups/1.0/business-application/services',
    businessApp: 'api/cmdb/lookups/1.0/business-application',
    costCenter: 'api/cmdb/lookups/1.0/projects',
    adGroups: 'api/eip/lookups/1.0/all-adgroups',
    download: 'api/order/ansible/v2/1.0/ess-payload',
    businessAppOwner: 'api/order/snow/1.0/{businessApplicationName}/it-owner',
    adGroupManager: 'api/employees/2.0/managers',
    appOwner: 'api/employees/3.0/employee/list',
    adGroupSuccess: 'ri-adgroup-validation-success.json',
    // appShortName: 'ri-appshortname.json',
    appShortName: 'api/ri/resource/name/1.0/app-short-name',
    // appShortNameValidation: 'ri-appshortname-validation.json',
    appShortNameValidation: 'api/ri/resource/name/1.0/app-short-name/availability',
    getappShortName: 'api/ri/resource/name/1.0/app-short-name/available',
    adGroupFailed: 'ri-adgroup-validation-failed.json',
    adGroupValidation: 'api/ri/order/myidm/1.0/validate-ad-group-access',
    isaValidation: 'api/order/snow/1.0/isa-number/validate',
};
export default COMMON;
