import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import { TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import theme from 'assets/style/theme';

// eslint-disable-next-line import/prefer-default-export
export const Item = styled(Paper)(() => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 1px #0000004B',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'flex-start',
    height: '50px',
}));

export const StyledCard = styled(Card)({
    // Add your card styles here if needed
    width: '286px',
    height: '123px',
    background: '#FFFFFF',
    // boxShadow: '0px 0px 2px #00000036',
    borderradius: '12px',
    margin: '20px 50px 20px 20px',
});
export const BoldTypography = styled(Typography)({
    fontWeight: '700',
    marginTop: '-30px',
    marginRight: '20px',
    fontSize: '60px',
});

export const StyledTableHeader = styled(TableCell)(() => ({
    color: '#005CBC',
    // fontSize: '14px',
    // fontStyle: 'normal',
    // fontWeight: '700',
    font: theme.typography.fontSize.$font_small_bold,
}));

export const StyledTableCell = styled(TableCell)(() => ({
    color: '#3A3A3A',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
}));
export const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

export const StyledDataTable = styled(TableContainer)(() => ({
    padding: '0px 0px 30px 0px',
    borderadius: '12px',
    width: '98% !important',
}));

export const style = {
    wrapper: {
        background: theme.palette.grey.lighter,
        color: theme.palette.white,
        overflow: 'hidden',
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    dataTable: {
        background: theme.palette.background.$color_background_layer_01,
        borderRadius: theme.spacing(0.75),
        width: '97%',
        marginLeft: '34px',
        boxShadow: '0 0 24px rgba(0, 92, 188, 0.08)',
        marginTop: '50px',
    },
    overViewIamge: {
        marginLeft: '20px',
        marginTop: '15px',
    },
    TypographyText: {
        fontSize: '20px',
    },
    allProjectDiv: {
        display: 'flex',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        height: '60px',
    },
    stickyNote: {
        margin: '20px',
    },
    projectText: {
        color: '#0047BA',
        fontSize: '18px',
        fontWeight: '700',
        marginTop: '20px',
    },
    tabStyle: {
        color: '#616161',
        borderBottom: '1px solid #d7e5f8',
        textTransform: 'none',
        '&.Mui-selected': {
            color: '#005cbc',
        },
        '&:active': {
            borderBottom: '2px solid #005cbc',
        },
    },
    boxStyle: { padding: () => theme.spacing(1.875) },
    innerboxStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '3em',
    },
    descTypo: {
        marginTop: '50px',
    },
    breadcrumbIcon: {
        margin: '0 8px',
        width: '16px',
        height: '10px',
    },
    subHeader: {
        background: '#FBFCFE 0% 0% no-repeat padding-box !important',
        boxShadow: 'rgba(0, 92, 188, 0.15) 0px 0px 24px 0 !important',
        height: '60px',
        position: 'relative',
        zIndex: '99',
    },
    dividerStyle: { color: '#CCCCCC', borderBottom: '0px 1px 0px #00000036', marginY: () => theme.spacing(1.875) },
};
