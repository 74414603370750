import toast from 'react-hot-toast';
import { linuxVM, windowsVM, sqlIaaS, sqlPaaS, oracleIaaS, storageBlog, aksNS } from 'utils';
import { EXCLUDE_ROLE_TO_SHOW } from 'pages/sso-okta/utils/constant';
import {
    keyvaultCatalogDefinitionId,
    kafkaCatalogDefinitionId,
    nsgCatalogDefinitionId,
    eventhubDefinitionId,
    pECatalogDefinitionId,
    eipStackCatalogDefinitionId,
    couchbaseDbDefinitionId,
    ApplicationGateWayURLCatalogDefinitionID,
    SsoOktaCatalogDefinitionId,
    NewInternalApplicationGateWayProvisioningCatalogDefinitionID,
    SlesPgtDBCatalogDefinitionId,
} from 'utils/catalogDefinitionId';

export const validateObject = ({ data = {}, catalogCode = '' }) => {
    const result = [];
    const checkValue = (obj = {}, keys = []) => {
        keys.forEach((key) => {
            if (obj[key] === '' || obj[key] === undefined || obj[key] === null) {
                result.push(key);
            } else if (typeof obj[key] === 'object') {
                const subObject = Object.keys(obj[key]);
                checkValue(obj[key], subObject);
            }
        });
        return result;
    };
    const getexcludeArray = (code) => {
        let returnedArray = [];

        const vMArray = ['project', 'projectName', 'adGroup', 'samAccountId', 'networkSecurityGroup', 'applicationSecurityGroup'];
        const sqlexcludeArray = [
            'project',
            'projectName',
            'backupTier',
            'businessCi',
            'subNetName',
            'virtualNetwork',
            'virtualNetworkId',
            'elastic_pool',
            'elasticPool',
            'secondaryServerName',
            'failOverGroupName',
        ];
        const sqlIaasexcludeArray = ['project', 'projectName', 'adGroup', 'networkSecurityGroup', 'applicationSecurityGroup', 'diskLabel'];
        const oracleexcludeArray = ['project', 'projectName', 'samAccountId', 'networkSecurityGroup', 'applicationSecurityGroup', 'oracleId'];
        const storageExcludeArray = [
            'premiumAccountType',
            'applicationManager',
            'applicationManagerEmail',
            'applicationManagerGpid',
            'costCenter',
            'project',
            'projectName',
        ];
        const aksExcludeArray = ['project', 'projectName', 'adGroup', 'applicationTier'];
        const keyVaultExcludeArray = ['totalRecurringCost', 'totalOneTimeCost'];
        const kafkaExcludeArray = [
            'transactionPrincipalId',
            'transactionPrefixText',
            'project',
            'projectName',
            'size',
            'catalogSizeDisplayValue',
            'createdBy',
            'ldapGroup',
        ];
        const nsgExcludeArray = ['changeRequestNo'];
        const eventHubExcludeArray = [
            'geoRecoveryRegion',
            'name',
            'applicationManager',
            'applicationManagerEmail',
            'applicationManagerGpid',
            'costCenter',
            'project',
            'namespaceExist',
            'enableAutoInflate',
            'dataClassification',
            'selectedNetworks',
            'projectName',
        ];
        const privateEndPointExcludeArray = ['projectName', 'project'];
        const eiapExcludeArray = ['fqdn', 'ipAddress', 'sslCertificate', 'elasticPool', ...storageExcludeArray];
        const couchbaseExcludeArray = [
            'qty',
            'estCost',
            'samAccountId',
            'networkSecurityGroup',
            'applicationSecurityGroup',
            'estimatedCost',
            'serverRefresh',
            'isMemoryRequired',
            'osDiskType',
            'osDiskTypeCode',
        ];
        const RI001ExcludedArray = [
            'catalogCode',
            'location',
            'division',
            'region',
            'projectDetails',
            'docType',
            'parent',
            'validFrom',
            'validTo',
            'adGroupValue',
            'applicationRegion',
            'key',
            'value',
            'tags',
            'active-directory-group-owner-id',
        ];
        const appGateWayurlExcludeArray = ['projectDetails', 'project', 'scanNumber'];
        const RI003OExcludeArray = [
            'read-access-groupOwner',
            'write-access-groupOwner',
            'consumer-group',
            'is-transactional',
            'transaction-prefix-text',
            'producer-id',
            'consumer-id',
            'transaction-principal-id',
            'sql-account-name',
        ];
        const ssoExcludeArray = ['businessJustification'];
        const pgtHanaDBExcludeArray = ['ephemeralDiskAvailable', 'networkSecurityGroup', 'applicationSecurityGroup'];
        if (linuxVM.includes(code) || windowsVM.includes(code)) {
            returnedArray = vMArray;
        } else if (sqlPaaS.includes(code)) {
            returnedArray = sqlexcludeArray;
        } else if (sqlIaaS.includes(code)) {
            returnedArray = sqlIaasexcludeArray;
        } else if (oracleIaaS.includes(code)) {
            returnedArray = oracleexcludeArray;
        } else if (storageBlog.includes(code)) {
            returnedArray = storageExcludeArray;
        } else if (aksNS.includes(code)) {
            returnedArray = aksExcludeArray;
        } else if (keyvaultCatalogDefinitionId.includes(code)) {
            returnedArray = keyVaultExcludeArray;
        } else if (kafkaCatalogDefinitionId.includes(code)) {
            returnedArray = kafkaExcludeArray;
        } else if (nsgCatalogDefinitionId.includes(code)) {
            returnedArray = nsgExcludeArray;
        } else if (eventhubDefinitionId.includes(code)) {
            returnedArray = eventHubExcludeArray;
        } else if (pECatalogDefinitionId.includes(code)) {
            returnedArray = privateEndPointExcludeArray;
        } else if (eipStackCatalogDefinitionId.includes(code)) {
            returnedArray = [...eiapExcludeArray, ...sqlexcludeArray, ...aksExcludeArray];
        } else if (couchbaseDbDefinitionId.includes(code)) {
            returnedArray = [...couchbaseExcludeArray];
        } else if (['RI001'].includes(code)) {
            returnedArray = [...RI001ExcludedArray];
        } else if (['RI003O', 'RICONSUMERAPP'].includes(code)) {
            returnedArray = [...RI003OExcludeArray];
        } else if (ApplicationGateWayURLCatalogDefinitionID.includes(code)) {
            if (data?.certificateExists === false) {
                returnedArray = [...appGateWayurlExcludeArray, 'certificateOwnerOne', 'certificateOwnerTwo'];
            } else {
                returnedArray = [...appGateWayurlExcludeArray];
            }
        } else if (SsoOktaCatalogDefinitionId.includes(code)) {
            if (EXCLUDE_ROLE_TO_SHOW?.includes(data?.dataClasification?.toLowerCase()) && data?.isCertificateAvailable?.toLowerCase() === 'no') {
                returnedArray = [...ssoExcludeArray];
            } else {
                returnedArray = [];
            }
        } else if (NewInternalApplicationGateWayProvisioningCatalogDefinitionID.includes(code)) {
            returnedArray = [];
        } else if (SlesPgtDBCatalogDefinitionId?.includes(code)) {
            returnedArray = [...pgtHanaDBExcludeArray];
        }
        return returnedArray;
    };
    if (data) {
        const invalidFields = checkValue(data, Object.keys(data));
        if (invalidFields.length > 0) {
            // if (linuxVM.includes(catalogCode) || windowsVM.includes(catalogCode)) {
            const excludeArray = getexcludeArray(catalogCode);
            let results = [];
            invalidFields.forEach((item) => {
                if (!excludeArray?.includes(item)) {
                    results = [...results, item];
                }
            });

            if (results?.length > 0) {
                toast.error(`${results?.join(', ')} value(s) are missing.`);
                return {
                    isValid: false,
                    invalidFields: results,
                };
            }
            // }
        }
    }
    return {
        isValid: true,
        invalidFields: [],
    };
};
export default validateObject;
