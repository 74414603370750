import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Grid,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import toast from 'react-hot-toast';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorFallback from 'components/error-fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { PEPVIGIL } from 'pages/dlops-dashboard/utils/constant';
import { commonStyles as styles } from 'assets/style';
import { Item, StyledTableCell, ProgressIcon, style } from '../style';
import AccessToken from '../components/accessToken/accessToken';
import StyleTextField from '../styleTextField';
import breadcrumbIcon from '../../../assets/images/dlopsImages/arrow_forward.svg';

const EditExistingModelTags = () => {
    const { getAccessToken } = AccessToken();
    const [addtags, setAddTags] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteOpenPopUp, setDeletePopUp] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const [errors, setErrors] = useState({});
    const [openPopup, setOpenPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [showAccessFields, setShowAccessFields] = useState(false);
    const activeModelData = location.state.state?.tags || {};
    const [tags, setTags] = useState([
        {
            name: 'BaseLine Datasource',
            mkey: 'baseline_datasource',
            tooltipInfo: 'A URL that provides access to a CSV file containing details about the training dataset used for the model',
            mvalue: '',
        },
        {
            name: 'Baseline Datasource Version',
            mkey: 'baseline_datasource_version',
            tooltipInfo:
                'The version number of the baseline datasource, indicating the specific iteration or update of the CSV file containing the training dataset details',
            mvalue: '',
        },
        {
            name: 'Storage Account',
            mkey: 'storage_account',
            tooltipInfo:
                'An identifier for the cloud storage account used to store data and files associated with the project, including models, datasets, and other resources',
            mvalue: '',
        },
        {
            name: 'Market',
            mkey: 'market',
            tooltipInfo:
                'The specific market or environment in which the model is being deployed for use, indicating the target audience or application context',
            mvalue: '',
        },
        {
            name: 'Container Name',
            mkey: 'container_name',
            tooltipInfo: 'The name of the storage container used to organize and manage data and files within a cloud storage account',
            mvalue: '',
        },
        { name: 'Framework', mkey: 'framework', tooltipInfo: 'The software framework or library used to develop and implement the model', mvalue: '' },
        {
            name: 'Drift Distance Threshold',
            mkey: 'drift_distance_threshold',
            tooltipInfo:
                'Set the threshold value for the drift monitor. If the drift distance exceeds this value, the monitor will trigger. The default value as per Arize is 2.',
            mvalue: 2,
        },
    ]);
    useEffect(() => {
        if (activeModelData.length > 0) {
            // Update localStorage whenever activeModelData changes
            localStorage.setItem('activeModelData', JSON.stringify(activeModelData));
        }
    }, [activeModelData]);

    useEffect(() => {
        // const savedDefaultTags = localStorage.getItem('createDefaulttags');
        // const savedNewlyAddedTags = localStorage.getItem('newlyAddedtags');
        const savedActiveModelData = localStorage.getItem('activeModelData');

        // Restore default tags from localStorage
        // if (savedDefaultTags) {
        //     setTags(JSON.parse(savedDefaultTags));
        // }

        // // Restore newly added tags from localStorage
        // if (savedNewlyAddedTags) {
        //     setAddTags(JSON.parse(savedNewlyAddedTags));
        // }
        // eslint-disable-next-line no-nested-ternary
        const parsedActiveModelData = activeModelData.length > 0 ? activeModelData : savedActiveModelData ? JSON.parse(savedActiveModelData) : [];
        if (parsedActiveModelData.length > 0) {
            // Update tags based on activeModelData or savedActiveModelData
            const modelMetricsTag = parsedActiveModelData.find((item) => item.key === 'model_metrics');
            const modelMetricsKeys = modelMetricsTag ? JSON.parse(modelMetricsTag.value.replace(/'/g, '"')) : [];

            const updatedTags = tags.map((tag) => {
                const matchedTag = parsedActiveModelData.find((item) => item.key === tag.mkey);
                return {
                    ...tag,
                    mvalue: matchedTag ? matchedTag.value : tag.mvalue,
                };
            });
            const excludedKeys = ['model_metrics', 'accuracy', 'model_type', 'distribution_list', 'project_name'];
            const allExcludedKeys = new Set([...excludedKeys, ...modelMetricsKeys]);
            const unmatchedTags = parsedActiveModelData
                .filter((item) => !updatedTags.some((tag) => tag.mkey === item.key) && !allExcludedKeys.has(item.key))
                .map((item) => ({
                    name: item.key.replace(/_/g, ' '),
                    mkey: item.key,
                    mvalue: item.value,
                }));
            setTags(updatedTags);
            setAddTags((prevAddTags) => [...prevAddTags, ...unmatchedTags]);
        }
    }, []);

    const handleAddNewRow = () => {
        setAddTags([...addtags, { name: '', mkey: '', mvalue: '', checked: false }]);
    };
    const handleClosePopup = () => {
        setOpenPopup(false);
        setShowAccessFields(false);
        if (isSuccess) {
            localStorage.removeItem('create_project');
            localStorage.removeItem('editModelFormData');
            localStorage.removeItem('createNewtags');
            localStorage.removeItem('initialModelData');
            localStorage.removeItem('activeModelData');
            history.push('/pepvigil-model-versions');
        }
    };
    const handleCancel = () => {
        history.push('/pepvigil-model-versions');
        localStorage.removeItem('initialModelData');
        localStorage.removeItem('editModelFormData');
        localStorage.removeItem('activeModelData');
    };
    const handleBack = () => {
        history.push('/pepvigil-edit-existing-model');
    };
    const updateActiveModelData = (tagsData, addtagsData) => {
        const savedActiveModelData = JSON.parse(localStorage.getItem('activeModelData')) || [];
        const modelDataMap = savedActiveModelData.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
        }, {});
        tagsData.forEach((tag) => {
            modelDataMap[tag.mkey] = tag.mvalue;
        });
        addtagsData.forEach((addTags) => {
            modelDataMap[addTags.mkey] = addTags.mvalue;
        });
        const updatedModelData = Object.keys(modelDataMap).map((key) => ({
            key,
            value: modelDataMap[key],
        }));
        localStorage.setItem('activeModelData', JSON.stringify(updatedModelData));
    };
    const handleInputChange = (index, field, value) => {
        const updatedTags = [...tags];
        updatedTags[index][field] = value;
        setTags(updatedTags);
        // localStorage.setItem('createDefaulttags', JSON.stringify(updatedTags));
        updateActiveModelData(updatedTags, addtags);
        if (value) {
            setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                delete newErrors[index];
                return newErrors;
            });
        }
    };
    const handleInputTags = (index, field, value) => {
        const updatedRows = addtags.map((row, i) => {
            // When updating the name field, also update the mkey field
            if (i === index) {
                if (field === 'name') {
                    return { ...row, name: value, mkey: value }; // Update both name and mkey
                }
                return { ...row, [field]: value }; // Update the specified field
            }
            return row; // Return unchanged row
        });
        setAddTags(updatedRows);
        // localStorage.setItem('newlyAddedtags', JSON.stringify(updatedRows));

        // Update activeModelData
        updateActiveModelData(tags, updatedRows);
    };
    const handleNavigation = () => {
        history.push('/pepvigil-projects');
    };
    const handleRowCheck = (index) => {
        const updatedRows = addtags.map((row, i) => (i === index ? { ...row, isChecked: !row.isChecked } : row));
        setAddTags(updatedRows);
    };

    const handleDeleteRow = (index) => {
        setDeleteIndex(index);
        setDeletePopUp(true);
    };
    const deleteRow = (index) => {
        const updatedRows = addtags.filter((_, i) => i !== index);
        setAddTags(updatedRows);
    };
    const handleCancelDelete = () => {
        setDeletePopUp(false);
    };
    const handleDeleteAllSelected = () => {
        setDeletePopUp(true);
        setDeleteIndex('SelectedAll');
    };
    const handleSelectAll = (event) => {
        const { checked } = event.target;
        setAllSelected(checked);
        setIsAllChecked(checked);
        setAddTags(addtags.map((row) => ({ ...row, checked, isChecked: checked })));
    };
    const handleConfirmDelete = () => {
        if (deleteIndex === 'SelectedAll') {
            setAddTags(addtags.filter((row) => !row.isChecked));
        } else {
            deleteRow(deleteIndex);
        }
        setDeletePopUp(false);
        setAllSelected(false);
        setIsAllChecked(false);
    };
    // // Helper function to validate URLs
    const isValidURL = (url) => {
        const pattern = /^https:\/\/[a-zA-Z0-9_-]+\.blob\.core\.windows\.net\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_.-]+)*\/[a-zA-Z0-9_.-]+\.[a-z]+$/;
        return pattern.test(url);
    };
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                <Typography variant="body2" sx={ProgressIcon.circularProgressColor} style={{ marginLeft: '10px' }}>
                    Loading
                </Typography>
            </Box>
        );
    }

    const handleValidation = () => {
        let formIsValid = true;
        const newErrors = {};

        tags.forEach((tag, index) => {
            switch (tag.mkey) {
                case 'baseline_datasource':
                    if (!tag.mvalue) {
                        formIsValid = false;
                        newErrors[index] = 'Baseline datasource is required.';
                    } else if (!isValidURL(tag.mvalue)) {
                        formIsValid = false;
                        newErrors[index] = 'Please enter a valid URL.';
                    }
                    break;

                case 'baseline_datasource_version':
                    if (!tag.mvalue) {
                        formIsValid = false;
                        newErrors[index] = 'Baseline datasource version is required.';
                    } else if (!/^\d+(\.\d+)?$/.test(tag.mvalue)) {
                        formIsValid = false;
                        newErrors[index] = 'Please enter a valid number or decimal.';
                    }
                    break;
                case 'desc_version':
                    if (!tag.mvalue) {
                        formIsValid = false;
                        newErrors[index] = 'Description version is required.';
                    }
                    break;
                case 'description_model':
                case 'storage_account':
                case 'market':
                case 'container_name':
                case 'framework':
                    if (!tag.mvalue) {
                        formIsValid = false;
                        newErrors[index] = `${tag.mkey.replace(/_/g, ' ')} is required.`;
                    }
                    break;
                case 'drift_distance_threshold':
                    if (Number.isNaN(tag.mvalue) || tag.mvalue < 0.5 || tag.mvalue > 5 || !/^\d+(\.\d+)?$/.test(tag.mvalue)) {
                        newErrors[index] = 'Please enter a number between 0.5 and less than 5';
                    }
                    break;

                default:
                    break;
            }
        });

        addtags.forEach((tag, index) => {
            if (!tag.name) {
                formIsValid = false;
                newErrors[`add_${index}_name`] = 'Name is required.';
            }
            if (!tag.mvalue) {
                formIsValid = false;
                newErrors[`add_${index}_mvalue`] = 'Value is required.';
            }
        });

        // Update the errors state
        setErrors(newErrors);

        return formIsValid;
    };

    const updateEditExistingModel = async (accessTokenPromise, payload) => {
        try {
            const accessToken = await accessTokenPromise;
            const response = await axios.put(endPoints.editExistingModel, payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                },
            });
            return response?.data;
        } catch (error) {
            return error;
        }
    };
    const handleSaveTags = async () => {
        if (handleValidation()) {
            // localStorage.setItem('createDefaulttags', JSON.stringify(tags));
            // localStorage.setItem('newlyAddedtags', JSON.stringify(addtags));
            updateActiveModelData(tags, addtags);
            toast.success('Meta Data Tags Saved Successfully', {
                position: 'top-right',
                autoClose: 3000,
            });
        }
    };
    const classifyChanges = (initialTags, currentTags, currentAddTags, remainingTags) => {
        const changes = {
            create: [],
            update: [],
            delete: [],
        };

        // Convert initialTags into a map for easier lookup
        const initialTagsMap = initialTags?.tags.reduce((acc, tag) => {
            if (tag.key && tag.value) {
                // Ensures only valid keys and values are added
                acc[tag.key] = tag.value;
            }
            return acc;
        }, {});

        // Combine currentTags and remainingTags for processing
        const currentTagKeys = new Set([...currentTags, ...currentAddTags, ...remainingTags].map((tag) => tag.mkey));
        const ignoredKeys = ['storage_account', 'container_name', 'project_name'];
        const excludedKeys = ['desc_version', 'description_model'];
        // Handle updates and new tags in the main tags list
        [...currentTags, ...remainingTags].forEach((tag) => {
            if (ignoredKeys.includes(tag.mkey)) {
                return; // Skip processing for these keys
            }

            // Only consider tags with both key and value defined
            if (tag.mkey && tag.mvalue) {
                const initialValue = initialTagsMap[tag.mkey];
                if (initialValue === undefined) {
                    // This is a new tag; add only if both key and value are defined
                    changes.create.push({
                        operation: 'create',
                        tag: [
                            {
                                key: tag.mkey,
                                value: tag.mvalue,
                            },
                        ],
                    });
                } else if (initialValue !== tag.mvalue) {
                    // This is an updated tag with old and new values
                    changes.update.push({
                        operation: 'update',
                        tag: [
                            {
                                old_key: tag.mkey,
                                old_value: initialValue,
                                new_key: tag.mkey,
                                new_value: tag.mvalue,
                            },
                        ],
                    });
                }
                // Remove this tag from initialTagsMap to track deletions
                delete initialTagsMap[tag.mkey];
            }
        });

        // Handle additional tags (new rows) in currentAddTags
        currentAddTags.forEach((addTag) => {
            if (addTag.mkey && addTag.mvalue) {
                // Check for valid key and value
                const initialValue = initialTagsMap[addTag.mkey];
                if (initialValue === undefined) {
                    // This is a new tag, so create it
                    changes.create.push({
                        operation: 'create',
                        tag: [
                            {
                                key: addTag.mkey,
                                value: addTag.mvalue,
                            },
                        ],
                    });
                } else if (initialValue !== addTag.mvalue) {
                    // This is an updated tag
                    changes.update.push({
                        operation: 'update',
                        tag: [
                            {
                                old_key: addTag.mkey,
                                old_value: initialValue,
                                new_key: addTag.mkey,
                                new_value: addTag.mvalue,
                            },
                        ],
                    });
                }
                // Remove this tag from initialTagsMap to track deletions
                delete initialTagsMap[addTag.mkey];
            }
        });

        // Any remaining tags in initialTagsMap are considered deleted
        Object.keys(initialTagsMap).forEach((key) => {
            if (!ignoredKeys.includes(key) && initialTagsMap[key] && !currentTagKeys.has(key) && !excludedKeys.includes(key)) {
                changes.delete.push({
                    operation: 'delete',
                    tag: [
                        {
                            key,
                            value: initialTagsMap[key],
                        },
                    ],
                });
            }
        });

        return changes;
    };

    const handleSubmit = async () => {
        if (handleValidation()) {
            // Proceed with the submission
            const tagsToSave = tags.map(({ mkey, mvalue }) => ({ mkey, mvalue }));
            localStorage.setItem('createNewtags', JSON.stringify(tagsToSave));
            const modelFormData = JSON.parse(localStorage.getItem('editModelFormData')) || {};
            const initialModelDatas = JSON.parse(localStorage.getItem('initialModelData')) || [];
            const initialModelDesc = initialModelDatas.description || '';
            const currentModelDesc = modelFormData.model_desc || '';
            const modelDescChanged = initialModelDesc !== currentModelDesc;
            const currentTags = tags.map(({ mkey, mvalue }) => ({ mkey, mvalue }));
            const currentAddTags = addtags.map(({ mkey, mvalue }) => ({
                mkey,
                mvalue,
            }));

            const { create, update, delete: deleteTags } = classifyChanges(initialModelDatas, currentTags, currentAddTags, modelFormData.tags);
            const tagPayload = {
                tags: [
                    create.length > 0 && {
                        operation: 'create',
                        tags: create.flatMap(({ tag }) => tag.map(({ key, value }) => ({ key, value }))),
                    },
                    update.length > 0 && {
                        operation: 'update',
                        tags: update.flatMap(({ tag }) => {
                            return tag.map((old) => ({
                                old_key: old.old_key,
                                old_value: old.old_value,
                                new_key: old.new_key,
                                new_value: old.new_value,
                            }));
                        }),
                    },
                    deleteTags.length > 0 && {
                        operation: 'delete',
                        tags: deleteTags.flatMap(
                            (
                                { tag } // Flatten the tags
                            ) => tag.map(({ key, value }) => ({ key, value }))
                        ),
                    },
                ].filter(Boolean), // Filters out empty operations
            };
            const projectName = localStorage.getItem('project_name') || {};
            const payload = {
                project_name: projectName || '',
                model_name: modelFormData.model_name || '',
                model_version: modelFormData.model_version || '',
                // model_desc: modelFormData.model_desc || '',
                model_updatedby_gpid: modelFormData.model_updatedby_gpid || '',
                model_updatedby_user_details: modelFormData.model_updatedby_user_details,
                ...(modelDescChanged && { model_desc: currentModelDesc }),
                tags: tagPayload.tags,
            };
            try {
                setLoading(true);
                const response = await updateEditExistingModel(getAccessToken(), payload);

                if (response && response.status === 'success') {
                    setPopupMessage(<>Model updated successfully</>);
                    setIsSuccess(true);
                } else {
                    setPopupMessage('Failed to edit Existing Model.');
                    setIsSuccess(false);
                }
                setLoading(false);
                setOpenPopup(true);
            } catch (error) {
                setPopupMessage('Error saving data.');
                setIsSuccess(false);
                setLoading(false);
                setOpenPopup(true);
            }
        }
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {' '}
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        <Item sx={style.subHeader}>
                            <div
                                style={{
                                    marginLeft: '28px',
                                    marginTop: '8px',
                                    fontSize: '14px',
                                }}
                            >
                                <span>{PEPVIGIL}</span>
                                <img src={breadcrumbIcon} alt=" bredcrumb" style={style.breadcrumbIcon} />
                                <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleNavigation()}>
                                    Projects
                                </span>
                                <img src={breadcrumbIcon} alt=" bredcrumb" style={style.breadcrumbIcon} />
                                <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleCancel()}>
                                    Model Versions
                                </span>
                                <img src={breadcrumbIcon} alt=" bredcrumb" style={style.breadcrumbIcon} />
                                <span aria-hidden="true" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleBack()}>
                                    Edit Existing Model
                                </span>
                                <img src={breadcrumbIcon} alt=" bredcrumb" style={style.breadcrumbIcon} />
                                <span style={{ fontWeight: 'bold', color: '#005CBC' }}>Edit Model Tags</span>
                            </div>
                        </Item>
                    </Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    top: '164px',
                    left: '27px',
                    width: '1500px',
                    height: '70px',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: '4px',
                    opacity: 1,
                    marginTop: '20px',
                    padding: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: '100px',
                }}
            >
                <Typography sx={{ fontSize: '18px', fontWeight: '700', color: '#005CBC' }}>Edit Model Tags</Typography>
            </Box>
            <Dialog
                open={openPopup}
                onClose={handleClosePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        width: 600,
                        height: 'auto',
                        // padding: '20px',
                        borderRadius: '8px',
                    },
                }}
            >
                <Box sx={{ border: (theme) => `10px solid ${theme.palette.border.$color_border_default}` }}>
                    <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            {/* Conditionally render the success icon and popupMessage only if showAccessFields is false */}
                            {!showAccessFields && (
                                <>
                                    {isSuccess && (
                                        <CheckCircleIcon
                                            sx={{
                                                fontSize: '50px',
                                                color: '#005CBC',
                                                marginBottom: '0px',
                                            }}
                                        />
                                    )}
                                    <DialogTitle
                                        id="alert-dialog-title"
                                        sx={{
                                            marginBottom: '20px',
                                            fontSize: '16px',
                                            color: isSuccess ? 'black' : 'red', // Show title in red if failed
                                        }}
                                    >
                                        {isSuccess ? 'Updated' : 'Failed'}
                                    </DialogTitle>
                                    <Typography
                                        sx={{
                                            color: isSuccess ? 'black' : 'red', // Error message in red if failed
                                            fontSize: '14px',
                                            textAlign: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {popupMessage}
                                    </Typography>
                                    <DialogActions
                                        sx={{
                                            justifyContent: 'center',
                                            padding: '16px',
                                        }}
                                    >
                                        <Button
                                            onClick={handleClosePopup}
                                            sx={{ ...styles.modalSaveButton }}
                                            // sx={{
                                            //     backgroundColor: '#005CBC',
                                            //     width: '200px',
                                            //     height: '45px',
                                            //     color: 'white',
                                            //     '&:hover': {
                                            //         backgroundColor: '#005CBC',
                                            //     },
                                            // }}
                                        >
                                            Okay
                                        </Button>
                                    </DialogActions>
                                </>
                            )}
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
            {/* <TeamMemberDrawer open={openDrawer} onClose={handleDrawerClose} onSubmit={handleDrawerSubmit} sx={{ width: '90%' }} /> */}
            <Box
                sx={{
                    top: '164px',
                    left: '27px',
                    width: '1500px',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: '4px',
                    opacity: 1,
                    marginTop: '20px',
                    padding: '16px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: '100px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '20px',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                            width: '1135px',
                            paddingBottom: '10px',
                        }}
                    >
                        <Typography
                            sx={{
                                marginRight: '10px',
                                fontSize: '16px',
                                fontFamily: ["'Inter', sans-serif !important"],
                                color: '#0047BA',
                                fontWeight: '500',
                            }}
                        >
                            Meta Data Tags
                        </Typography>
                        <Box sx={{ display: 'flex', marginLeft: 'auto', gap: '10px' }}>
                            <Button
                                sx={{ ...styles.modalSaveButton, height: '36px', width: '139px' }}
                                // variant="contained"
                                // sx={{
                                //     backgroundColor: '#1378D4',
                                //     width: '139px',
                                //     height: '36px',
                                //     borderRadius: '4px',
                                // }}
                                onClick={handleAddNewRow}
                            >
                                Add New Tag
                            </Button>
                            <Button
                                sx={{ ...styles.modalSaveButton, height: '36px', width: '139px' }}
                                // variant="contained"
                                // sx={{
                                //     backgroundColor: '#1378D4',
                                //     width: '139px',
                                //     height: '36px',
                                //     borderRadius: '4px',
                                // }}
                                onClick={handleSaveTags}
                            >
                                Save Tags
                            </Button>
                            {allSelected && addtags.length >= 1 && (
                                <Button
                                    sx={{
                                        ...styles.primaryMediumDangerButton,
                                        '&.MuiButton-root:hover': {
                                            backgroundColor: (theme) => `${theme.palette.background.$color_background_button_primary_danger_hover}!important`,
                                        },

                                        height: '36px',
                                        width: '187px',
                                    }}
                                    // variant="contained"
                                    // sx={{
                                    //     backgroundColor: '#D40020',
                                    //     borderRadius: '4px',
                                    //     width: '187px',
                                    // }}
                                    onClick={handleDeleteAllSelected}
                                >
                                    Delete All Selected
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <Table>
                        <TableHead sx={{ backgroundColor: (theme) => theme.palette.cyan['90'], borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <TableRow>
                                <StyledTableCell sx={{ width: '40px', padding: '4px 8px', color: '#005CBC' }}>
                                    <Checkbox sx={{ color: '#005CBC' }} checked={isAllChecked} onChange={handleSelectAll} disableRipple disableElevation />
                                </StyledTableCell>
                                <StyledTableCell sx={{ color: '#005CBC', width: '200px', fontSize: '14px', padding: '12px 12px', fontWeight: '700' }}>
                                    Tag Name
                                </StyledTableCell>
                                <StyledTableCell sx={{ color: '#005CBC', width: '100px', fontSize: '14px', padding: '12px 12px', fontWeight: '700' }}>
                                    Value
                                </StyledTableCell>
                                <StyledTableCell sx={{ color: '#005CBC', width: '100px', fontSize: '14px', padding: '12px 12px', fontWeight: '700' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tags.map((row, index) => (
                                <TableRow key={index} sx={{ height: '40px' }}>
                                    <StyledTableCell sx={{ width: '40px', padding: '12px 12px' }} />
                                    <StyledTableCell sx={{ padding: '12px 12px', width: '200px' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {row.mkey !== 'drift_distance_threshold' ? (
                                                <Typography sx={{ color: '#E4080A', marginRight: '4px' }}>*</Typography>
                                            ) : null}
                                            {row.name}
                                            {row.tooltipInfo && (
                                                <Tooltip
                                                    title={row.tooltipInfo}
                                                    arrow
                                                    componentsProps={{
                                                        tooltip: { sx: { backgroundColor: '#272727', fontSize: '10px' } },
                                                        arrow: { sx: { color: '#272727' } },
                                                    }}
                                                >
                                                    <InfoOutlinedIcon
                                                        sx={{
                                                            fontSize: '16px',
                                                            color: '#005CBC',
                                                            marginLeft: '8px',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ padding: '12px 12px', width: '100px' }}>
                                        <StyleTextField
                                            value={row.mvalue}
                                            onChange={(e) => handleInputChange(index, 'mvalue', e.target.value)}
                                            variant="filled"
                                            fullWidth={row.mkey === 'baseline_datasource'}
                                            disabled={row.mkey === 'storage_account' || row.mkey === 'container_name'}
                                            sx={{
                                                // '& .MuiFilledInput-root': {
                                                //     background: '#FFFFFF',
                                                //     height: '32px',
                                                //     padding: '4px',
                                                // },
                                                '&.MuiTextField-root': {
                                                    border: 'none',
                                                },
                                                width: row.mkey === 'baseline_datasource' ? '500px' : '200px',
                                                border: `1px solid ${errors[index] ? 'd40020' : '#1378D4'}`,
                                                // borderRadius: '4px',
                                            }}
                                            inputProps={{
                                                style: { padding: '4px' },
                                            }}
                                        />
                                        {errors[index] && (
                                            <Typography sx={{ color: '#d40020', fontSize: '12px', marginTop: '4px' }}>{errors[index]}</Typography>
                                        )}
                                        {row.mkey === 'baseline_datasource' && (
                                            <Typography sx={{ fontSize: '10px', color: 'grey', marginTop: '4px' }}>
                                                Example:
                                                https://iaimlops.blob.core.windows.net/dlops-container/OC/baseline/dlops-model-oc/baseline_dlops-oc_20240425.csv
                                            </Typography>
                                        )}
                                    </StyledTableCell>
                                </TableRow>
                            ))}

                            {addtags &&
                                addtags.length > 0 &&
                                addtags.map((row, index) => (
                                    <TableRow key={index}>
                                        <StyledTableCell sx={{ width: '40px', padding: '12px 12px' }}>
                                            <Checkbox disableElevation disableRipple checked={row.isChecked} onChange={() => handleRowCheck(index)} />
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: '12px 12px', width: '200px' }}>
                                            <StyleTextField
                                                value={row.name}
                                                onChange={(e) => handleInputTags(index, 'name', e.target.value)}
                                                variant="filled"
                                                sx={{
                                                    marginTop: '8px',
                                                    // '& .MuiFilledInput-root': {
                                                    //     background: '#FFFFFF',
                                                    //     height: '36px',
                                                    //     padding: '10px',
                                                    // },
                                                    width: '200px',
                                                    // border: '1px solid #1378D4',
                                                    borderRadius: '4px',
                                                }}
                                                inputProps={{
                                                    style: { padding: '4px' },
                                                }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: '12px 12px', width: '100px' }}>
                                            <StyleTextField
                                                value={row.mvalue}
                                                onChange={(e) => handleInputTags(index, 'mvalue', e.target.value)}
                                                variant="filled"
                                                sx={{
                                                    marginTop: '8px',
                                                    // '& .MuiFilledInput-root': {
                                                    //     background: '#FFFFFF',
                                                    //     height: '36px',
                                                    //     padding: '10px',
                                                    // },
                                                    width: '200px',
                                                    // border: '1px solid #1378D4',
                                                    // borderRadius: '4px',
                                                }}
                                                inputProps={{
                                                    style: { padding: '4px' },
                                                }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ width: '100px', padding: '12px 12px' }}>
                                            {row.isChecked && (
                                                <Typography
                                                    onClick={() => handleDeleteRow(index)}
                                                    sx={{ color: '#1378D4', textDecoration: 'underline', cursor: 'pointer' }}
                                                >
                                                    Delete
                                                </Typography>
                                            )}
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button
                            variant="contained"
                            sx={{ ...styles.primaryMediumButton, marginTop: '50px' }}
                            // sx={{ backgroundColor: '#1378D4', marginTop: '50px' }}
                            onClick={handleBack}
                            disableRipple
                            disableElevation
                        >
                            Back
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ ...styles.secondaryMediumButton, marginTop: '50px' }}
                            // sx={{ marginTop: '50px' }}
                            onClick={handleCancel}
                            disableRipple
                            disableElevation
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ ...styles.primaryMediumButton, marginTop: '50px', marginLeft: '500px' }}
                            // sx={{ backgroundColor: '#1378D4', marginTop: '50px', marginLeft: '500px' }}
                            onClick={handleSubmit}
                            disableRipple
                            disableElevation
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Dialog
                open={deleteOpenPopUp}
                onClose={handleCancelDelete}
                PaperProps={{
                    sx: {
                        width: '480px',
                        // height: '200px',
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 0px 6px #00000074',
                        borderRadius: '4px',
                        // padding: '16px',
                    },
                }}
            >
                <Box
                    sx={{
                        ...styles.modalWrapper,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <DialogTitle sx={styles.modalTitle}>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={styles.modalContent}>Are you sure you want to delete the selected model tags?</DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', padding: '8px 16px', marginBottom: '10px' }}>
                        <Button
                            onClick={handleConfirmDelete}
                            disableElevation
                            disableRipple
                            sx={{ ...styles.modalSaveButton, width: '100px' }}
                            // color="primary"
                            // sx={{ background: '#1378D4 0% 0% no-repeat padding-box', width: '118px', height: '53px', color: 'white' }}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={handleCancelDelete}
                            sx={{ ...styles.modalCancelButton, width: '100px' }}
                            // color="primary"
                            // sx={{
                            //     width: '116px',
                            //     height: '53px',
                            //     background: '#FFFFFF 0% 0% no-repeat padding-box',
                            //     border: '1px solid #707070',
                            //     borderRadius: '6px',
                            //     color: '#000000',
                            // }}
                        >
                            No
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </ErrorBoundary>
    );
};

export default EditExistingModelTags;
