const ModalStyle = {
    modalWrapper: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
        backgroundColor: (theme) => theme?.palette?.background?.$color_background_layer_01,
    },
    headingWrapper: {
        // background: (theme) => theme?.palette?.background?.$color_background_layer_01,
        color: (theme) => theme?.palette?.textColors?.$color_text_primary,
        minHeight: '28px',
        marginTop: '15px',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        // letterSpacing: 1,
        fontSize: '18px',
        fontWeight: '700',
    },
    titleCloseIcon: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
};
export default ModalStyle;
