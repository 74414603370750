import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAxios } from 'hooks';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import ProvisioningStyles from 'pages/provisioning/kafka/style';
import { catalogs } from 'store';
import { sspSubmitOrder as eiapSspSubmitOrder } from 'pages/provisioning/store/eip-stack';
import {
    environmentDetails,
    isMandatoryTagsFullfilled,
    orderId,
    quickConfigDetails,
    sspSubmitOrder,
    toggleSspModels,
    selectedMembersList,
    isValidWriteIdxAccount,
    isValidLdapGroup,
    isValidReadIdxAccount,
    SecondaryApproverBanner,
} from 'pages/provisioning/store/kafka';
import { navigateToWithPageReload } from 'utils';
import { eiapSave } from 'utils/constant';
import {
    formOrderObjFromCart,
    formOrderObjFromOrderDetails,
    resetEnvironmentDetails,
    resetAppProfile,
    resetOrder,
    resetToggleSspModal,
    formOrderObjFromEiapObject,
} from './utils';
import { fetchAllMembers } from './utils/fetchMembers';
import { fetchAllAdGroupMembers } from './utils/fetchAdGroupMembers';
import { fetchLandingZones, findLandingZoneById } from './utils/fetchLandingZone';

const ProvisioningHeader = React.lazy(() => import('pages/provisioning/kafka/ProvisioningHeader'));
const ApplicationDetails = React.lazy(() => import('pages/provisioning/kafka/ApplicationDetails'));
const SspForm = React.lazy(() => import('pages/provisioning/kafka/SspForm'));
const Tags = React.lazy(() => import('pages/provisioning/kafka/Tags'));
const AddedToCartConfirmModal = React.lazy(() => import('pages/provisioning/kafka/AddedToCartConfirmModal'));
const UpdatedToCartConfirmModal = React.lazy(() => import('pages/provisioning/kafka/UpdatedToCartConfirmModal'));
const QuickConfig = React.lazy(() => import('pages/provisioning/kafka/QuickConfig'));
const ShowCostDetails = React.lazy(() => import('pages/provisioning/kafka/ShowCostDetails'));
const CustomSnackBar = React.lazy(() => import('pages/provisioning/kafka/components/CustomSnackBar'));

const KafkaProvisioningPage = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const componentId = queryParams.get('componentId');
    const [isLoading, setLoading] = React.useState(true);
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, resetOrderId] = useRecoilState(orderId);
    const [, resetEnvDetails] = useRecoilState(environmentDetails);
    const [, resetIsMandatoryTagsFullfilled] = useRecoilState(isMandatoryTagsFullfilled);
    const [, resetToggleSspModels] = useRecoilState(toggleSspModels);
    const [, resetQuickConfig] = useRecoilState(quickConfigDetails);
    const [, resetSelectedMembersList] = useRecoilState(selectedMembersList);
    const [, resetisValidWriteIdxAccount] = useRecoilState(isValidWriteIdxAccount);
    const [, resetisValidLdapGroup] = useRecoilState(isValidLdapGroup);
    const [, resetisValidReadIdxAccount] = useRecoilState(isValidReadIdxAccount);
    const [eiapOrder] = useRecoilState(eiapSspSubmitOrder);
    const [showSnackBar, setShowSnackBar] = useRecoilState(SecondaryApproverBanner);

    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const axios = useAxios();

    async function getCosts() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}/costs`);
        return data;
    }

    async function checkCatalog() {
        const data = cmpCatalogs?.find((item) => item?.catalogDefinitionId === catalogCode);
        const costs = await getCosts();
        if (cartItemId && operation && !catalogServiceId) {
            const { data: cartItem } = await axios.get(`${endPoints.cart.getCartItem}/${cartItemId}`);
            const adGroupOwner = cartItem?.itemDetail?.catalogService?.activeDirectoryGroupOwnerId
                ? await fetchAllMembers([cartItem?.itemDetail?.catalogService?.activeDirectoryGroupOwnerId])
                : null;
            const members = cartItem?.itemDetail?.catalogService?.catalogConfig?.members || [];
            const fetchedMembers = members?.length ? await fetchAllAdGroupMembers(members) : [];
            const orderObj = await formOrderObjFromCart(cartItem);
            const payload = {
                appProfileName: orderObj?.appProfile,
                environment: orderObj?.environment,
                appTier: 'KT', // Hardcoded
                region: orderObj?.region,
                cloudProvider: orderObj?.cloudProvider,
                cloudService: orderObj?.cloudService,
            };

            const landingZones = await fetchLandingZones(payload);
            const specificLandingZoneId = orderObj?.landingZoneDetails?.id;
            const specificLandingZone = specificLandingZoneId ? findLandingZoneById(landingZones, specificLandingZoneId) : null;
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                members: fetchedMembers,
                emptyCost: costs,
                ...(specificLandingZone && {
                    landingZone: specificLandingZone?.value,
                    landingZoneDetails: specificLandingZone?.data,
                }),
                ...(adGroupOwner && {
                    adGroupOwner: `${adGroupOwner?.[0]?.gpid} - ${adGroupOwner?.[0]?.name}`,
                    adGroupOwnerDetails: {
                        label: `${adGroupOwner?.[0]?.gpid} - ${adGroupOwner?.[0]?.name}`,
                        gpid: `${adGroupOwner?.[0]?.gpid}`,
                        name: `${adGroupOwner?.[0]?.name}`,
                        email: `${adGroupOwner?.[0]?.email}`,
                    },
                }),
            }));
        } else if (cartItemId && operation && catalogServiceId) {
            const { data: orderDetails } = await axios.get(`${endPoints.order.orderDetails}/${cartItemId}`);
            const { catalogServices } = orderDetails || {};
            const catalog = catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || {};
            const adGroupOwner = catalog?.activeDirectoryGroupOwnerId ? await fetchAllMembers([catalog?.activeDirectoryGroupOwnerId]) : null;
            const members = catalog?.catalogConfig?.members || [];
            const fetchedMembers = members?.length ? await fetchAllAdGroupMembers(members) : [];
            const orderObj = await formOrderObjFromOrderDetails(catalogServiceId, orderDetails);
            const payload = {
                appProfileName: orderObj?.appProfile,
                environment: orderObj?.environment,
                appTier: 'KT', // Hardcoded
                region: orderObj?.region,
                cloudProvider: orderObj?.cloudProvider,
                cloudService: orderObj?.cloudService,
            };

            const landingZones = await fetchLandingZones(payload);
            const specificLandingZoneId = orderObj?.landingZoneDetails?.id;
            const specificLandingZone = specificLandingZoneId ? findLandingZoneById(landingZones, specificLandingZoneId) : null;
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                members: fetchedMembers,
                ...(specificLandingZone && {
                    landingZone: specificLandingZone?.value,
                    landingZoneDetails: specificLandingZone?.data,
                }),
                ...(adGroupOwner && {
                    adGroupOwner: `${adGroupOwner?.[0]?.gpid} - ${adGroupOwner?.[0]?.name}`,
                    adGroupOwnerDetails: {
                        label: `${adGroupOwner?.[0]?.gpid} - ${adGroupOwner?.[0]?.name}`,
                        gpid: `${adGroupOwner?.[0]?.gpid}`,
                        name: `${adGroupOwner?.[0]?.name}`,
                        email: `${adGroupOwner?.[0]?.email}`,
                    },
                }),
            }));
        } else if (operation === eiapSave) {
            if (!eiapOrder?.appProfile || !eiapOrder?.landingZoneDetails || !eiapOrder?.sector || !eiapOrder?.environment || !eiapOrder?.region) {
                navigateToWithPageReload('/');
            }

            let orderObj;
            if (eiapOrder?.components?.[componentId]) {
                orderObj = await formOrderObjFromCart({ itemDetail: eiapOrder?.components?.[componentId] });
            } else {
                orderObj = await formOrderObjFromEiapObject(eiapOrder, data.cloudService);
            }
            setOrder((previous) => ({
                ...previous,
                catalogCode,
                catalogType: data?.displayName,
                cloudProvider: data?.cloudProvider,
                cloudService: data.cloudService,
                validationStatus: 'clearValidation',
                ...orderObj,
                eiapState: eiapOrder,
                componentId,
                operation: eiapSave,
                isTouched: false,
                emptyCost: costs,
            }));
        } else {
            setOrder((previous) => ({
                ...previous,
                sku: data?.images?.sku,
                catalogCode,
                catalogType: data?.displayName,
                cloudProvider: data?.cloudProvider,
                cloudService: data?.cloudService,
                validationStatus: 'clearValidation',
                isTouched: false,
                emptyCost: costs,
            }));
        }
        setLoading(false);
    }

    const reset = () => {
        setOrder({ ...order, ...resetAppProfile, ...resetOrder });
        resetOrderId('');
        resetEnvDetails(resetEnvironmentDetails);
        resetIsMandatoryTagsFullfilled(false);
        resetToggleSspModels(resetToggleSspModal);
        resetQuickConfig(undefined);
        resetSelectedMembersList([]);
        resetisValidWriteIdxAccount(false);
        resetisValidLdapGroup(false);
        resetisValidReadIdxAccount(false);
    };

    React.useEffect(() => {
        if (cmpCatalogs?.length && catalogCode) {
            setLoading(true);
            reset();
            checkCatalog();
        }
    }, [cmpCatalogs, catalogCode]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => reset();
        // eslint-disable-next-line
    }, []);
    const handleCustomSnackbar = () => {
        setShowSnackBar({ status: false, message: null });
    };
    return (
        <>
            <Helmet>
                <title>Kafka Provisioning - DevX Infra</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                }
            >
                {isLoading && (
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                )}
                {!isLoading && (
                    <Box sx={ProvisioningStyles.provisioningBox}>
                        <ProvisioningHeader />
                        <Box sx={ProvisioningStyles.sspform_wrapper}>
                            <SspForm />
                        </Box>
                        <br />
                        <ApplicationDetails />
                        <Tags />
                        <AddedToCartConfirmModal />
                        <UpdatedToCartConfirmModal />
                        <QuickConfig />
                        <ShowCostDetails />
                        <CustomSnackBar show={showSnackBar?.status} message={showSnackBar?.message} handleCloseCallback={handleCustomSnackbar} />;
                    </Box>
                )}
            </Suspense>
        </>
    );
};

export default KafkaProvisioningPage;
