import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import { OrderPayload } from 'pages/github-onboarding/store';

const ApplicationSupportGroup = () => {
    const [order] = useRecoilState(OrderPayload);

    return (
        <>
            <Box display="flex" alignItems="center" gap={1} data-testid="app-support-group">
                <CssTextField
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('100%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                        '& label.MuiInputLabel-shrink': {
                            color: '#0047BA !important',
                        },
                    }}
                    disabled
                    autoComplete="off"
                    label="Application Support Group (as in Service Now or Snow)"
                    value={order?.customProperties?.applicationSupportGroup || ''}
                />
            </Box>
        </>
    );
};

export default React.memo(ApplicationSupportGroup);
