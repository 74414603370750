/* eslint-disable import/no-named-default */

import ADOProjectName from './ADOProjectName';
import AvailableADOProject from './AvailableADOProject';
import MigrateOrCreateProject from './MigrateOrCreateProject';
import BusinessApplication from './BusinessApplication';
import ApplicationService from './ApplicationService';
import ApplicationOwner from './ApplicationOwner';
import ApplicationSupportGroup from './ApplicationSupportGroup';
import Capability from './Capability';
import CostCode from './CostCode';
import ServiceResources from './ServiceResources/ServiceResources';
import { default as Sector } from './Sector';

const Forms = {
    Sector,
    AvailableADOProject,
    ADOProjectName,
    MigrateOrCreateProject,
    BusinessApplication,
    ApplicationService,
    ApplicationOwner,
    ApplicationSupportGroup,
    Capability,
    CostCode,
    ServiceResources,
};

export default Forms;
