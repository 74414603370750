import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { OrderPayload } from 'pages/github-onboarding/store';
import defaultOrderValues from 'pages/github-onboarding/store/defaultValues';
import capabilityOptions from 'pages/github-onboarding/utils/capabilityOptions';

const Capability = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isTouched, setIsTouched] = React.useState(false);

    const setApplicationService = ({ value }) => {
        const { customProperties, applicationOwnerDetails, availableProjectRepos, ...rest } = defaultOrderValues;

        setOrder({
            customProperties: {
                ...order.customProperties,
                capability: value,
            },
            applicationOwnerDetails: order.applicationOwnerDetails,
            availableProjectRepos: order.availableProjectRepos,
            ...rest,
        });
    };

    const handleOnSelectChange = (selected) => {
        setApplicationService(selected);
    };

    React.useEffect(() => {
        setIsTouched(!order?.customProperties?.capability && order?.isTouched);
    }, [order?.customProperties?.capability, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                data-testid="capability"
                key="Capability"
                label="Capability*"
                value={capabilityOptions.find((option) => option.value === order?.customProperties?.capability) || null}
                options={capabilityOptions}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={!order.customProperties.applicationService}
                isRequired={isTouched}
                isOptionEqualToValue={(option, value) => option.value === value.value}
            />
        </Box>
    );
};

export default React.memo(Capability);
