const Styles = {
    provisionHeader: {
        pageTitle: {
            color: (theme) => theme.palette.white,
            textTransform: 'capitalize',
            fontSize: 16,
        },
        header: {
            borderColor: (theme) => theme.palette.gray.low,
            mx: (theme) => theme.spacing(4),
        },
        gridContainer: { alignItems: 'center' },
        gridItem: { display: 'flex' },
        spanStyle: { marginRight: '4px' },
        imageStyle: { width: '26px' },
    },

    formCard: {
        paper: {
            height: '100%',
            borderRadius: (theme) => theme.spacing(0.75),
            marginBottom: (theme) => theme.spacing(3.125),
            boxShadow: '0 0 24px rgba(0, 92, 188, 0.08)',
            background: (theme) => theme.palette.background.$color_background_layer_01,
        },
        column: {
            padding: (theme) => theme.spacing(2),
            position: 'relative',
        },
        heading: {
            color: (theme) => theme?.palette?.cmpPrimary.main,
            fontSize: (theme) => theme.spacing(2.25),
            fontWeight: '700',
        },
        form: {
            '& > *': {
                marginBottom: (theme) => theme.spacing(2.5),
            },
        },
        wrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: (theme) => theme.spacing(2),
            py: (theme) => theme.spacing(1),
            background: '#FBFCFE',
        },
        // preconfigbtn: {
        //     color: (theme) => theme.palette.blue.main,
        //     textTransform: 'none',
        //     borderWidth: (theme) => theme.spacing(0.25),
        //     borderStyle: 'solid',
        //     borderColor: (theme) => theme.palette.blue.main,
        //     '&:hover': {
        //         color: (theme) => theme.palette.cyan.main,
        //         borderWidth: (theme) => theme.spacing(0.25),
        //         borderStyle: 'solid',
        //         borderColor: (theme) => theme.palette.cyan.main,
        //         background: 'transparent',
        //     },
        // },
    },
    envDetails: {
        wrapper: {
            // background: (theme) => theme.palette.white,
            boxShadow: '0 0 24px rgba(0, 92, 188, 0.08) !important',
            borderRadius: '6px',
            border: 'none',
            background: (theme) => theme.palette.background.$color_background_layer_01,
        },
        titleWrapper: {
            px: (theme) => theme.spacing(2),
            py: (theme) => theme.spacing(0.8),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: (theme) => theme.palette.background.$color_background_layer_01,
        },
        typograpy: {
            color: (theme) => theme?.palette?.cmpPrimary?.main,
            fontSize: '18px',
            fontWeight: '700',
        },
        labelBOx: {
            background: (theme) => theme.palette.background.$color_background_layer_01,
            pt: (theme) => theme.spacing(4),
            pb: (theme) => theme.spacing(2),
            pl: (theme) => theme.spacing(4),
            pr: (theme) => theme.spacing(2),
        },
        loadingBox: { height: (theme) => theme.spacing(16.25), display: 'flex', alignItems: 'center', justifyContent: 'center' },
        crlProg: { color: (theme) => theme.palette.black },
        labelGrid: { color: '#003182' },
    },
    AppDetails: {
        span: { color: (theme) => theme?.palette?.black?.dark },
        cirPrg: { color: (theme) => theme.palette.black },
        circularBox: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    },
    CostSummary: {
        card: {
            background: '#FFFFFF',
            padding: '15px 25px',
            borderTop: '8px solid #F6EB39',
            boxShadow: '0 0 24px rgba(0, 92, 188, 0.08)',
            height: 'fit-content',
            // overflow: 'hidden',
            flexDirection: 'column',
            display: 'flex',
            // minHeight: '30%',
            // maxHeight: { xs: '100%', sm: '100%', md: '100%', lg: '600px' },
        },
        headingWrapper: { display: 'flex' },
        heading: {
            font: (theme) => theme.typography.fontSize.$font_heading_h6_medium,
            marginBottom: '8px',
            /*  fontSize: '18px',
            fontWeight: 'medium', */
            color: (theme) => theme?.palette?.black?.nero,
        },
        loaderIcon: { color: (theme) => theme?.palette?.black?.dark },
        // summaryWrapper: { flexGrow: 1, overflowY: 'auto', display: 'flex' },
        costEstWrapper: { py: 1 },
        labelWrapper: { textAlign: 'right' },
        estWrapper: { display: 'flex' },
        item: {
            font: (theme) => theme.typography.fontSize.$font_heading_h6_medium,
            /* fontSize: '18px',
        fontWeight: 'normal',
        fontFamily: 'Roboto', */
            color: (theme) => theme?.palette?.black?.dark,
        },
        unit: {
            font: (theme) => theme.typography.fontSize.$font_xsmall_regular,
            /* fontSize: '12px',
            fontWeight: '300', 
            fontFamily: 'Roboto',  */
            color: (theme) => theme?.palette?.black?.dark,
        },
        estCost: {
            font: (theme) => theme.typography.fontSize.$font_heading_h6_medium,
            /* fontSize: '18px',
            fontWeight: 'normal',
            fontFamily: 'Roboto', */
            color: (theme) => theme?.palette?.black?.dark,
        },
        colon: { px: 1.5 },
        qtyWrapper: { textAlign: 'right' },
        qtyLabel: {
            font: (theme) => theme.typography.fontSize.$font_body_regular,
            /* fontSize: '16px',
            fontWeight: 'normal',
            fontFamily: 'Roboto', */
            color: (theme) => theme?.palette?.black?.dark,
        },
        labelStyle: {
            font: (theme) => theme.typography.fontSize.$font_body_bold,
            /* fontSize: '16px', 
            fontWeight: 'bold',
            fontFamily: 'Roboto', */
            color: (theme) => theme?.palette?.black?.dark,
        },
        note: {
            font: (theme) => theme.typography.fontSize.$font_small_regular,
            /* fontSize: '14px', 
            fontWeight: 'normal',
            fontFamily: 'Roboto', */
            color: (theme) => theme?.palette?.black?.dark,
            wordBreak: 'break-word',
        },
        // cartWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        cartWrapper: { alignItems: 'center', justifyContent: 'center' },
        oneTimeBtn: {
            marginLeft: '10px',
            color: '#0047BA',
            fontSize: '24px',
            cursor: 'pointer',
        },
        monthlyBtn: {
            marginLeft: '10px',
            color: '#0047BA',
            fontSize: '24px',
            cursor: 'pointer',
        },
    },
    CostDetails: {
        card: {
            background: '#FFFFFF',
            px: '16px',
            pt: '16px',
            pb: '0px',
            border: '1px solid #E0E0E0',
            borderTop: '8px solid #F6EB39',
            boxShadow: '0px 3px 8px #0000000F',
            height: 'fit-content',
            // overflow: 'hidden',
            flexDirection: 'column',
            display: 'flex',
        },
        headingWrapper: { display: 'flex' },
        heading: {
            font: (theme) => theme.typography.fontSize.$font_heading_h6_medium,
            marginBottom: '8px',
            /* fontSize: '20px', 
        fontWeight: 'medium', 
        fontFamily: 'Stolzl'  */
        },
        loaderIcon: { color: (theme) => theme?.palette?.black?.dark },
        costEstWrapper: { py: 1 },
        labelWrapper: { textAlign: 'right' },
        estWrapper: { display: 'flex', alignItems: 'center' },
        item: {
            font: (theme) => theme.typography.fontSize.$font_heading_h6_medium,
            /* fontSize: '18px', 
            fontWeight: 'normal', 
            fontFamily: 'Roboto',  */
            color: (theme) => theme?.palette?.black?.dark,
        },
        unit: {
            font: (theme) => theme.typography.fontSize.$font_small_regular,
            /*   fontSize: '14px',
            fontWeight: '300',
            fontFamily: 'Roboto', */
            color: (theme) => theme?.palette?.black?.dark,
        },
        estCost: {
            font: (theme) => theme.typography.fontSize.$font_heading_h6_medium,
            /* fontSize: '18px', 
            fontWeight: 'normal', 
            fontFamily: 'Roboto', */
            color: (theme) => theme?.palette?.black?.dark,
        },
        colon: { px: 1.5 },
        qtyWrapper: { textAlign: 'right' },
        labelStyle: {
            font: (theme) => theme.typography.fontSize.$font_heading_h6_bold,
            /* fontSize: '18px', 
            fontWeight: 'bold', 
            fontFamily: 'Roboto',  */
            color: (theme) => theme?.palette?.black?.dark,
        },
        note: {
            font: (theme) => theme.typography.fontSize.$font_small_regular,
            /* fontSize: '14px', 
            fontWeight: 'normal', 
            fontFamily: 'Roboto',  */
            color: (theme) => theme?.palette?.black?.dark,
        },
        cartWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        infoWrapper: {
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            width: '200px',
            maxWidth: '500px',
        },
        infoBox: { overflow: 'auto' },
        gridcontainer: { padding: '10px 0px 10px 10px' },
        griditem: {
            mb: (theme) => theme.spacing('3px'),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // flexGrow:1
        },
        labelItem: {
            font: (theme) => theme.typography.fontSize.$font_small_regular,
            display: 'block',
            vaiant: (theme) => theme.typography.caption.fontSize,
            /* fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal', */
        },
        labelValue: {
            font: (theme) => theme.typography.fontSize.$font_small_regular,
            display: 'block',
            vaiant: (theme) => theme.typography.caption.fontSize,
            /* fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal', */
        },
    },
    StickHeader: {
        wrapper: {
            background: '#FBFCFE 0% 0% no-repeat padding-box',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // borderBottom: '1px solid #E0E0E0',
            minHeight: '68px',
            boxShadow: 'rgba(0, 92, 188, 0.15) 0px 0px 24px 0',
            // boxShadow: 'none',
            zIndex: 99,
        },
        subWrapper: { display: 'flex', alignItems: 'center', ml: '53px' },
        ImgSpan: { display: 'flex', alignItems: 'center' },
        img: { width: '33.43px', height: '33.43px' },
        keyvaultimg: { width: '53.43px', height: '33.43px' },
        title: {
            pl: 2,
            color: (theme) => theme.palette.textColors.$color_text_primary,
            font: (theme) => theme.typography.fontSize.$font_heading_h5_medium,
        },
        buttonWrapper: {
            mr: '53px',
        },
        showInfoIcon: {
            display: 'flex',
            alignItems: 'baseline',
        },
    },
    infoIcon: { color: '#0047BA', fontSize: '18px', marginTop: '3px' },
    backBtn: { marginLeft: '-20px' },
    backIcon: { color: (theme) => theme.palette.blue.main },
    page: {
        provisioning_wrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: (theme) => theme.spacing('100%'),
            height: 'calc(100vh - 100px)',
        },
        circularProgressColor: { color: (theme) => theme.palette.black.dark },
        provisioningBox: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
            width: (theme) => theme.spacing('100%'),
            height: (theme) => theme.spacing('100%'),
            minHeight: '600px',
        },
        sspform_wrapper: { flexGrow: 1, mx: (theme) => theme.spacing(6.625) },
    },
};

export default Styles;
