import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, FormControlLabel, Stack } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import { updateNodesDetails } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles, { CustomRadioGroup, CustomRadio } from 'pages/provisioning/couchbase/style';

const SslCertificate = ({ indexVal }) => {
    const [order] = useRecoilState(sspSubmitOrder);
    const updateNodesData = useSetRecoilState(updateNodesDetails);

    const setSslCertificate = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };
    const handleOnSelectChange = (selected) => {
        setSslCertificate('sslCertificate', selected, indexVal);
    };
    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '37.13px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', font: (theme) => theme.typography.fontSize.$font_xsmall_regular }} id="performanceRadioGrp">
                    SSL Cetrificate
                </Box>
                <CustomRadioGroup
                    aria-label="sslCertificate"
                    name="sslCertificateRadioBtnGroup"
                    row
                    value={order?.nodes?.[indexVal]?.sslCertificate || 'no'}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
                    <FormControlLabel value="no" sx={ProvisioningStyles.serverRefresh.radioBtnNoForm} control={<CustomRadio />} label="No" />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

SslCertificate.propTypes = {
    indexVal: PropTypes.number,
};

SslCertificate.defaultProps = {
    indexVal: 0,
};

export default React.memo(SslCertificate);
