import { atom } from 'recoil';

export const OrderPayload = atom({
    key: 'projectOnboard-OrderPayload',
    default: {
        sector: null,
        sectorDetails: null,
        adoPlatform: null,
        isADOProject: null,
        projectName: null,
        existingProjectName: null,
        projectDetails: null,
        existingAdminMembers: [],
        deletedAdminMembers: [],
        isClarityId: null,
        clarityId: null,
        clarityDetails: null,
        adoProjectType: null,
        applicationName: null,
        appShortName: null,
        businessApplication: null,
        businessApplicationDetails: null,
        applicationService: null,
        applicationOwner: null,
        applicationOwnerDetails: null,
        existingADOServices: null,
        serviceResources: {
            isProjectAdmin: false,
            isBoard: false,
            isRepos: false,
            isPipelines: false,
            isSonarQube: false,
            isArtifactory: false,
            isSecurityTools: false,
            isTestPlan: false,
        },

        adminAccessMember: null,
        adminAccessMemberDetails: null,
        adminAccessMemberList: [],
        allowedAdminMembers: 5,
        courseDetails: null,

        sonarQubeCodeRepo: null,
        sonarQubeRepoList: [],
        sonarQubeCodeRepoList: [{ repo: null, isRepoTouched: false }],
        repoName: null,
        sonarQubeUser: null,
        sonarQubeUserDetails: null,
        sonarQubeUserList: [],

        progLanguage: null,
        progLanguageDetails: null,
        buildTool: null,
        buildToolDetails: null,
        packageType: null,
        packageTypeDetails: null,
        artifactoryUser: null,
        artifactoryUserDetails: null,
        artifactoryUserList: [],

        organizationName: null,
        organizationNameDetails: null,
        applicationUrl: null,
        securityTool: null,
        securityToolCodeRepoList: [{ repo: null, isRepoTouched: false }],
        secToolRepoList: [],
        sastMember: null,
        sastMemberDetails: null,
        addTestPlanMembers: false,
        removeTestPlanMembers: false,
        hCode: null,
        hCodeDetails: null,
        testPlanUser: null,
        testPlanUserDetails: null,
        testPlanUserList: [],
        removedTestPlanUser: null,
        removedTestPlanUserDetails: null,
        removedTestPlanUserList: [],
        isCostApiLoading: false,
        isConfigureTouched: false,
        onboardRepositories: false,
        onboardServiceAccount: false,
        isServiceAccountExists: false,
        onboardSAST: false,
        onboardSCA: false,
        serviceAccount: null,
        onboardMembers: false,
        sastApplicationName: null,
        sastVersion: null,
        applicationNameWithVersions: [],
        scaRoles: null,
        securityToolRequired: null,
        fortifyMembers: [],
        snykMembers: [],
        snykPreviewMembers: [],
        snykMemberDetails: null,
        scaMember: null,
        sastBusinessApplicationDetails: null,
        sastOption: null,
        scaOption: null,
        newProjectOnboardMembers: false,
        newProjectOnboardServiceAccount: false,
        estimatedCost: {
            details: [],
            qty: null,
            totalOneTimeCost: {
                frequency: 'one time',
                estCost: null,
                currency: 'USD',
            },
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
        },
        isTouched: false,
        isToolsProject: false,
    },
});

export const showPreviewOrder = atom({
    key: 'projectOnboard-showPreviewOrder',
    default: null,
});

export const isSonarQubeRepoAvailable = atom({
    key: 'projectOnboard-isSonarQubeRepoAvailable',
    default: true,
});

export const validOnboardingForm = atom({
    key: 'projectOnboard-validOnboardingForm',
    default: {
        isClarityIdValid: false,
        isProjectNameValid: false,
        isProjectNameExists: false,
        isAppNameValid: false,
        isAppShortNameValid: false,
        isAppShortNameExist: false,
        isAppUrlValid: false,
        isRepoNameValid: false,
        isDuplicateAdminAccessMember: false,
        isDuplicateSASTMember: false,
        isDuplicateTestPlanUser: false,
        isDuplicateRemovedTestPlanUser: false,
        isDuplicateSonarQubeUser: false,
        isDuplicateArtifactoryUser: false,
        isAdminAccessMaxMemberLimit: false,
        isSASTMaxMemberLimit: false,
        isTestPlanMaxMemberLimit: false,
        isRemovedTestPlanMaxMemberLimit: false,
        isSonarQubeMaxMemberLimit: false,
        isArtifactoryMaxMemberLimit: false,
        isAdminMemberTrainingValid: false,
        isInvalidServiceAccount: false,
        isDuplicateSastApplication: false,
        isInvalidSASTApplicationName: false,
        isInvalidSASTApplicationVersion: false,
        isSCAMaxMemberLimit: false,
        isDuplicateSCAMember: false,
        isSCAMemberOrganizationInvalid: false,
        isAppVersionNameValid: false,
        isHCodeInvalid: false,
    },
});

export const toggleModal = atom({
    key: 'projectOnboard-toggleModal',
    default: {
        isProjectAdminOpen: false,
        isSonarQubeOpen: false,
        isArtifactoryOpen: false,
        isFortifySnykOpen: false,
        isTestPlanOpen: false,
        isSuccessOpen: false,
        action: '',
        isCost: { costType: '', isCostOpen: false },
        orderId: null,
    },
});

export const costEstimateState = atom({
    key: 'projectOnboard-costEstimateState',
    default: null,
});

export const isValidationLoading = atom({
    key: 'projectOnboard-isValidationLoading',
    default: false,
});

export const urlParams = atom({
    key: 'urlParams',
    default: {
        journeyId: null,
        appRegistrationId: null,
    },
});
