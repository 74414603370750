import React from 'react';
import PropTypes from 'prop-types';
import { Stack, FormControlLabel, Box, Typography } from '@mui/material';
import Select from 'pages/reference-implementation/components/select';
import ProvisioningStyles, { CustomRadioGroup, CustomRadio } from 'pages/provisioning/kafka/style';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { getRetention, retentionUnits, minuteOptions, hourOptions, dayOptions } from 'pages/provisioning/kafka/utils';

const Retention = (props) => {
    const { isRequired, handleOnSelect, retentionvalue, errors } = props;
    const [selectedRentionUnit, setSelectedRetentionUnit] = React.useState(retentionvalue?.retentionUnit);
    const retentionUnit = retentionvalue?.retentionUnit ?? retentionUnits[0];
    const retention = retentionvalue?.retention ?? null;
    const retentionDetails = retentionvalue?.retentionDetails ?? null;
    const [hoursSelected, setHoursSelected] = React.useState({ hours: null, mints: null });

    const setRetention = (retentionValue = null, retentionDetailsValue = null) => {
        const configureData = { retentionUnit: selectedRentionUnit, retention: retentionValue, retentionDetails: retentionDetailsValue };
        handleOnSelect(configureData);
    };

    const seldValueExistsInOptions = (retentionValue, options) => {
        return options.find((value) => retentionValue === value?.data?.millisec);
    };

    const handleOnretentionUnitChange = (selected) => {
        setHoursSelected({ hours: null, mints: null });
        setSelectedRetentionUnit(selected);
        const configureData = { retentionUnit: selected, retention: null, retentionDetails: null };
        handleOnSelect(configureData);
    };

    const handleOnSelectRetention = (type, selected) => {
        if (type === 'days') {
            // const configureData = { retentionUnit: selected, retention: null, retentionDetails: null };

            setRetention(selected?.data?.displayValue, selected?.data);
        } else if (type === 'hours') {
            setHoursSelected({ ...hoursSelected, hours: selected });
            setRetention(`${selected?.label}:${hoursSelected?.mints?.label || 0}`, {
                millisec: selected?.data?.millisec + (hoursSelected?.mints?.data?.millisec || 0),
            });
        } else if (type === 'mints') {
            setHoursSelected({ ...hoursSelected, mints: selected });
            setRetention(`${hoursSelected?.hours?.label || 0}:${selected?.label || 0}`, {
                millisec: (hoursSelected?.hours?.data?.millisec || 0) + selected?.data?.millisec,
            });
        }
    };

    React.useEffect(() => {
        if (retentionDetails && retentionUnit === retentionUnits[0]) {
            setHoursSelected({ hours: null, mints: null });
            const retentionValue = seldValueExistsInOptions(retentionDetails?.millisec, dayOptions);
            if (retentionValue) setRetention(retentionValue?.value, retentionValue?.data);
        }
        if (hoursSelected && retentionDetails && retentionUnit === retentionUnits[1]) {
            const hourMin = getRetention(retentionDetails?.millisec);
            const hourretention = seldValueExistsInOptions(1000 * 60 * 60 * hourMin?.hours, hourOptions); // converting hours to millisec (Ex.: 3600000 * value)
            const minretention = seldValueExistsInOptions(1000 * 60 * hourMin?.minutes, minuteOptions); // converting minutes to millisec (Ex.: 60000 * value)
            setHoursSelected({ hours: hourretention, mints: minretention });
            setRetention(`${hourMin?.hours}:${hourMin?.minutes}`, retentionDetails);
        }
    }, [retentionUnit]);
    return (
        <Box>
            <Stack direction="row">
                <Typography sx={ProvisioningStyles?.retention?.typography}>Retention Time (in days)</Typography>
                <HelpImg title=" Choose Days for beyond 24hr retention period" />
            </Stack>
            <Stack direction="row" sx={ProvisioningStyles?.retention?.wrapper}>
                <CustomRadioGroup
                    aria-label="RetentionTime"
                    name="RetentionRadioBtnGroup"
                    row
                    value={retentionUnit}
                    onChange={(event, value) => handleOnretentionUnitChange(value)}
                >
                    <FormControlLabel value={retentionUnits[0]} control={<CustomRadio />} label="In Days" />
                </CustomRadioGroup>
                <Select
                    key="indays"
                    label="Days"
                    sx={ProvisioningStyles?.retention?.daysDropdowm}
                    value={(retentionUnit === retentionUnits[0] && retention) || null}
                    options={dayOptions}
                    handleOnSelect={(value) => handleOnSelectRetention('days', value)}
                    disabled={retentionUnit === retentionUnits[1]}
                    isRequired={isRequired || errors?.showError}
                    dataTestId="retention-days-select"
                />

                <CustomRadioGroup
                    aria-label="RetentionTimehours"
                    name="RetentionRadioBtnHoursGroup"
                    row
                    value={retentionUnit}
                    onChange={(event, value) => handleOnretentionUnitChange(value)}
                >
                    <FormControlLabel value={retentionUnits[1]} control={<CustomRadio />} label="In Hours" data-testid="retention-hours-radioBtn" />
                </CustomRadioGroup>
                <Select
                    key="inhours"
                    label="Hours"
                    sx={ProvisioningStyles?.retention?.hoursDropDown}
                    value={hoursSelected?.hours || null}
                    options={hourOptions}
                    disabled={retentionUnit === retentionUnits[0]}
                    getOptionLabel={(option) => `${option?.label}`}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    handleOnSelect={(value) => handleOnSelectRetention('hours', value)}
                    isRequired={isRequired || errors?.showError}
                    dataTestId="retention-hours-select"
                />
                <Select
                    key="inmints"
                    label="Mins"
                    sx={ProvisioningStyles?.retention?.minDropDown}
                    value={hoursSelected?.mints || null}
                    options={minuteOptions}
                    disabled={retentionUnit === retentionUnits[0]}
                    getOptionLabel={(option) => `${option?.label}`}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    handleOnSelect={(value) => handleOnSelectRetention('mints', value)}
                    isRequired={isRequired || errors?.showError}
                    dataTestId="retention-mints-select"
                />
            </Stack>
        </Box>
    );
};

Retention.propTypes = {
    isRequired: PropTypes.bool,
    handleOnSelect: PropTypes.func,
    retentionvalue: PropTypes.any,
    errors: PropTypes.any,
};

Retention.defaultProps = {
    isRequired: false,
    handleOnSelect: () => {},
    retentionvalue: { retention: '', retentionUnit: 'In Days', retentionDetails: null },
    errors: {},
};

export default Retention;
