import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
// import { Styles } from 'pages/myidm/style';
import { commonStyles as style } from 'assets/style';
import {
    memberForm,
    optionsList,
    selectedMembersList,
    OrderPayload,
    toggleConfirmModel,
    invalidMember,
    showisExistADGrp,
    isAppShortNameValid,
    isAppShortNameExist,
    isAppNameValid,
    costEstimateState,
} from 'pages/myidm/store';
import { resetCostEstimateDetails } from 'pages/myidm/utils';
import Btn from 'components/button';

export const CancelRequest = () => {
    const [, setOptions] = useRecoilState(optionsList);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [modal, setModal] = useRecoilState(toggleConfirmModel);
    const [form, setForm] = useRecoilState(memberForm);
    const [, setMembers] = useRecoilState(selectedMembersList);
    const [, setInvalid] = useRecoilState(invalidMember);
    const [, setIsExistADGrp] = useRecoilState(showisExistADGrp);
    const [, setAppShortNameVaild] = useRecoilState(isAppShortNameValid);
    const [, setAppShortNameExist] = useRecoilState(isAppShortNameExist);
    const [, setApplicationNameVaild] = useRecoilState(isAppNameValid);
    const [, setcostEstimate] = useRecoilState(costEstimateState);

    const [isLoading, setLoading] = React.useState(false);

    const handleCancel = () => {
        setLoading(true);
        // setTimeout(() => {
        setOptions([]);
        setForm({
            ...form,
            selectedMember: null,
            member: null,
        });
        setMembers([]);
        setOrder({
            ...order,
            adGroup: null,
            businessApplication: null,
            businessApplicationDetails: null,
            environment: null,
            sector: null,
            isNewADGroup: null,
            AppShortName: null,
            groupOwner: null,
            groupOwnerDetails: null,
            adGroupType: null,
            applicationName: null,
            adGroupTypeDetails: null,
            members: null,
            mypamsafeDetails: [],
            nonSafeDetailsGpid: [],
            estimatedCost: resetCostEstimateDetails,
            adGroupMembers: null,
            isTouched: false,
        });
        setInvalid(true);
        setModal({ ...modal, isOpen: false, message: '', removeAll: false, removeAllAction: false });
        setLoading(false);
        setIsExistADGrp(false);
        setAppShortNameVaild(false);
        setAppShortNameExist(false);
        setApplicationNameVaild(false);
        setcostEstimate(resetCostEstimateDetails);
        // }, 300);
    };

    const handleDeleteConfirm = () => {
        setModal({ ...modal, isOpen: true, message: 'Are you sure you want to remove all members?', removeAll: true, removeAllAction: false });
    };

    React.useEffect(() => {
        if (modal?.removeAllAction) {
            handleCancel();
        }
    }, [modal.removeAllAction]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Btn
                size="medium"
                onClick={() => handleDeleteConfirm()}
                variant="outlined"
                disabled={isLoading}
                // sx={Styles?.AddMemberForm?.cancelBtn}
                // color="cmpPrimary"
                sx={{ ...style.secondaryMediumButton, width: '150px', marginTop: '8px' }}
                data-testid="cancel-btn"
            >
                {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Cancel'}
            </Btn>
        </Box>
    );
};

export default React.memo(CancelRequest);
