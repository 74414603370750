import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import {
    serviceList,
    sandboxServices,
    basicServices,
    intermediateServices,
    advancedServices,
    toolsOnboardingServices,
} from 'pages/project-onboarding/utils/constants';

export const getServiceName = (service) => {
    switch (service) {
        case 'projectAdmin':
            return 'Project Admin Members';
        case 'boards':
            return 'Board';
        case 'repos':
            return 'Repos';
        case 'pipelines':
            return 'Pipelines';
        case 'sonarQube':
            return 'SonarQube';
        case 'artifactory':
            return 'Artifactory (Coming soon in DevX Infra)';
        case 'securityTools':
            return 'SAST/SCA';
        case 'testplans':
            return 'Test Plan';
        default:
            return 'Board';
    }
};

export const getServiceVal = (service, serviceResources, isToolsProject) => {
    switch (service) {
        case 'projectAdmin':
            // return isToolsProject === true ? getIsToolsProjectAdminValue(isToolsProject, existingAdminMembers) : serviceResources?.isProjectAdmin;
            return serviceResources?.isProjectAdmin;
        case 'boards':
            return isToolsProject === true ? isToolsProject : serviceResources?.isBoard;
        case 'repos':
            return isToolsProject === true ? isToolsProject : serviceResources?.isRepos;
        case 'pipelines':
            return isToolsProject === true ? isToolsProject : serviceResources?.isPipelines;
        case 'sonarQube':
            return isToolsProject === true ? isToolsProject : serviceResources?.isSonarQube;
        case 'artifactory':
            return serviceResources?.isArtifactory;
        case 'securityTools':
            return serviceResources?.isSecurityTools;
        case 'testplans':
            return serviceResources?.isTestPlan;
        default:
            return false;
    }
};

const getSonarQubeValidationStatus = (order, validForm) => {
    if (order?.isADOProject === 'yes') {
        if (order?.isServiceAccountExists === 'yes' && order?.serviceAccount === null) {
            return false;
        }
        if (validForm?.isInvalidServiceAccount === true) {
            return false;
        }
        if (order?.onboardMembers && order?.sonarQubeUserList?.length === 0) {
            return false;
        }
        if (order?.onboardRepositories && order?.sonarQubeCodeRepoList?.some((repo) => repo?.repo === null || repo?.repo === '')) {
            return false;
        }
        if (!order?.onboardServiceAccount && !order?.onboardMembers && !order?.onboardRepositories) {
            return false;
        }

        return !Object.values({
            onboardServiceAccount: order?.onboardServiceAccount,
            onboardRepositories: order?.onboardRepositories,
            onboardMembers: order?.onboardMembers,
        }).every((value) => value === false);
    }
    if (validForm?.isInvalidServiceAccount === true) {
        return false;
    }
    if (order?.isADOProject === 'no' && order?.newProjectOnboardMembers) {
        return order?.sonarQubeUserList?.length > 0;
    }
    if (order?.isADOProject === 'no' && !order?.newProjectOnboardMembers && !order?.newProjectOnboardServiceAccount) {
        return false;
    }
    return order?.isADOProject === 'no' && !order?.newProjectOnboardMembers;
};

export const getBadgeColor = (service, order, validForm) => {
    const newProject =
        (order?.isADOProject === 'no' && order?.isClarityId === 'no') ||
        (order?.isADOProject === 'no' &&
            order?.isClarityId === 'yes' &&
            order?.clarityDetails &&
            (typeof order?.clarityDetails?.devopsProject === 'undefined' ||
                order?.clarityDetails?.devopsProject === null ||
                order?.clarityDetails?.devopsProject === undefined));
    switch (service) {
        case 'projectAdmin':
            return order?.adminAccessMemberList?.length + order?.existingAdminMembers?.length > 0;
        // case 'boards':
        //     return order?.adminAccessMemberList?.length > 0;
        case 'sonarQube':
            return getSonarQubeValidationStatus(order, validForm);
        case 'artifactory':
            return order?.progLanguage && order?.packageType && order?.buildTool && order?.artifactoryUserList?.length > 0;
        case 'securityTools':
            return order?.onboardSAST ? order?.fortifyMembers?.length > 0 : order?.snykMembers?.length > 0;
        case 'testplans':
            return order?.addTestPlanMembers || newProject
                ? order?.hCode && order?.testPlanUserList?.length > 0 && validForm?.isHCodeInvalid === false
                : order?.removedTestPlanUserList?.length > 0;
        default:
            return false;
    }
};

export const getExistingServices = (projectDetails) => {
    const existingServices = [];
    let existingAdminMemberCount = 0;
    Object.keys(projectDetails || {})?.forEach((key) => {
        if (projectDetails[key] !== null && projectDetails[key] !== 0) {
            if (key === 'projectAdmin' && projectDetails?.projectAdmin?.memberList?.length > 0) {
                existingAdminMemberCount = projectDetails[key]?.memberList?.length;
            }
            existingServices.push(key);
        }
    });
    const existingServicesResult =
        existingServices?.includes('projectAdmin') && existingAdminMemberCount < 5 ? existingServices?.filter((z) => z !== 'projectAdmin') : existingServices;

    return existingServicesResult;
};

const getDisabled = (service, availableService, order) => {
    let isDisabled = false;
    let disableServices = [];
    if (order?.adoProjectType === 'Basic') {
        disableServices =
            order?.isADOProject === 'yes' || (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null)
                ? [...difference(serviceList, basicServices), ...basicServices?.filter((z) => availableService?.includes(z))]
                : [...difference(serviceList, basicServices)];
    }
    if (order?.adoProjectType === 'Intermediate') {
        disableServices =
            order?.isADOProject === 'yes' || (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null)
                ? [...difference(serviceList, intermediateServices), ...intermediateServices?.filter((z) => availableService?.includes(z))]
                : [...difference(serviceList, intermediateServices)];
    }
    if (order?.adoProjectType === 'Advanced') {
        disableServices =
            order?.isADOProject === 'yes' || (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null)
                ? [...advancedServices?.filter((z) => availableService?.includes(z))]
                : [...difference(serviceList, advancedServices)];
    }

    if (order?.adoProjectType === 'Advanced' && order?.projectName !== null && order?.isToolsProject) {
        disableServices =
            order?.isADOProject === 'yes' || (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null)
                ? []
                : [
                      ...difference(
                          serviceList.filter((item) => item !== 'projectAdmin'),
                          toolsOnboardingServices
                      ),
                  ];
    }
    if (disableServices?.length > 0) {
        disableServices?.forEach((item) => {
            if (item === service) isDisabled = true;
        });
    } else if (order?.isADOProject === 'no') {
        isDisabled = false;
    }
    return isDisabled;
};

export const getServiceDisabled = (service, availableService, order) => {
    const availableService1 = availableService?.filter((item) => item !== 'projectAdmin');

    let isDisabled = false;
    if (order?.adoPlatform === 'Sandbox/Personal') {
        if (!sandboxServices?.includes(service)) {
            isDisabled = true;
        }
    } else if (order?.adoPlatform === 'Production') {
        if (order?.adoProjectType) {
            isDisabled = getDisabled(service, availableService1, order);
        } else isDisabled = true;
    } else isDisabled = true;
    return isDisabled;
};

export const getToolsOrderDisabled = (service, availableService, order) => {
    let isDisabled = false;
    if (order?.isToolsProject) {
        if ([...toolsOnboardingServices, ...availableService?.filter((z) => z !== 'projectAdmin')]?.includes(service)) {
            isDisabled = true;
        }

        if (service === 'projectAdmin' && !order?.projectName && !order?.existingProjectName) {
            isDisabled = true;
        }
    }
    return isDisabled;
};

export const getServiceChecked = (service, availableService, order) => {
    const availableService1 = availableService?.filter((item) => item !== 'projectAdmin');
    let checkedService = [];
    if (
        (order?.isADOProject === 'yes' || (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null)) &&
        order?.adoProjectType
    ) {
        if (order?.adoProjectType === 'Basic') {
            checkedService = [...intersection(availableService1, basicServices)];
        }
        if (order?.adoProjectType === 'Intermediate') {
            checkedService = [...intersection(availableService1, intermediateServices)];
        }
        if (order?.adoProjectType === 'Advanced') {
            checkedService = [...intersection(availableService1, advancedServices)];
        }
        if (order?.adoProjectType === 'Advanced' && order?.isToolsProject) {
            if (availableService1?.includes('boards')) {
                checkedService = [...toolsOnboardingServices, 'boards'];
            }
        }
    }

    return checkedService?.includes(service);
};

export const getModalTitle = (isOpen) => {
    let modalTitle = '';
    if (isOpen?.isProjectAdminOpen) modalTitle = 'Project Admin Members';
    else if (isOpen?.isFortifySnykOpen) modalTitle = 'SAST / SCA';
    else if (isOpen?.isTestPlanOpen) modalTitle = 'Test Plan';
    else if (isOpen?.isSonarQubeOpen) modalTitle = 'SonarQube';
    else if (isOpen?.isArtifactoryOpen) modalTitle = 'Artifactory';

    return modalTitle;
};

export const getOnCloseConfigData = (lastModifiedConfigData) => {
    return {
        adminAccessMember: lastModifiedConfigData?.adminAccessMember,
        adminAccessMemberDetails: lastModifiedConfigData?.adminAccessMemberDetails,
        adminAccessMemberList: lastModifiedConfigData?.adminAccessMemberList,
        existingAdminMembers: lastModifiedConfigData?.existingAdminMembers,
        deletedAdminMembers: lastModifiedConfigData?.deletedAdminMembers,
        organizationName: lastModifiedConfigData?.organizationName,
        applicationUrl: lastModifiedConfigData?.applicationUrl,
        securityTool: lastModifiedConfigData?.securityTool,
        sastMember: lastModifiedConfigData?.sastMember,
        scaMember: lastModifiedConfigData?.scaMember,
        sastMemberDetails: lastModifiedConfigData?.sastMemberDetails,
        sastApplicationName: lastModifiedConfigData?.sastApplicationName,
        sastVersion: lastModifiedConfigData?.sastVersion,
        applicationNameWithVersions: lastModifiedConfigData?.applicationNameWithVersions,
        scaRoles: lastModifiedConfigData?.scaRoles,
        fortifyMembers: lastModifiedConfigData?.fortifyMembers,
        snykMembers: lastModifiedConfigData?.snykMembers,
        sastOption: lastModifiedConfigData?.sastOption,
        scaOption: lastModifiedConfigData?.scaOption,
        snykPreviewMembers: lastModifiedConfigData?.snykPreviewMembers,
        onboardSAST: lastModifiedConfigData?.onboardSAST,
        onboardSCA: lastModifiedConfigData?.onboardSCA,
        snykMemberDetails: lastModifiedConfigData?.snykMemberDetails,
        sastBusinessApplicationDetails: lastModifiedConfigData?.sastBusinessApplicationDetails,
        sastApplicationDetails: lastModifiedConfigData?.sastApplicationDetails,
        securityToolCodeRepoList: lastModifiedConfigData?.securityToolCodeRepoList,
        securityToolRequired: lastModifiedConfigData?.securityToolRequired,
        addTestPlanMembers: lastModifiedConfigData?.addTestPlanMembers,
        removeTestPlanMembers: lastModifiedConfigData?.removeTestPlanMembers,
        hCode: lastModifiedConfigData?.hCode,
        hCodeDetails: lastModifiedConfigData?.hCodeDetails,
        testPlanUser: lastModifiedConfigData?.testPlanUser,
        testPlanUserDetails: lastModifiedConfigData?.testPlanUserDetails,
        testPlanUserList: lastModifiedConfigData?.testPlanUserList,
        removedTestPlanUser: lastModifiedConfigData?.removedTestPlanUser,
        removedTestPlanUserDetails: lastModifiedConfigData?.removedTestPlanUserDetails,
        removedTestPlanUserList: lastModifiedConfigData?.removedTestPlanUserList,
        sonarQubeUser: lastModifiedConfigData?.sonarQubeUser,
        sonarQubeUserDetails: lastModifiedConfigData?.sonarQubeUserDetails,
        sonarQubeUserList: lastModifiedConfigData?.sonarQubeUserList,
        progLanguage: lastModifiedConfigData?.progLanguage,
        progLanguageDetails: lastModifiedConfigData?.progLanguageDetails,
        buildTool: lastModifiedConfigData?.buildTool,
        buildToolDetails: lastModifiedConfigData?.buildToolDetails,
        packageType: lastModifiedConfigData?.packageType,
        packageTypeDetails: lastModifiedConfigData?.packageTypeDetails,
        artifactoryUser: lastModifiedConfigData?.artifactoryUser,
        artifactoryUserDetails: lastModifiedConfigData?.artifactoryUserDetails,
        artifactoryUserList: lastModifiedConfigData?.artifactoryUserList,
        isConfigureTouched: lastModifiedConfigData?.isConfigureTouched,
        onboardServiceAccount: lastModifiedConfigData?.onboardServiceAccount,
        onboardRepositories: lastModifiedConfigData?.onboardRepositories,
        onboardMembers: lastModifiedConfigData?.onboardMembers,
        newProjectOnboardMembers: lastModifiedConfigData?.newProjectOnboardMembers,
        newProjectOnboardServiceAccount: lastModifiedConfigData?.newProjectOnboardServiceAccount,
        isServiceAccountExists: lastModifiedConfigData?.isServiceAccountExists,
    };
};

export const getLastModifiedConfigData = (order) => {
    return {
        adminAccessMember: order?.adminAccessMember,
        adminAccessMemberDetails: order?.adminAccessMemberDetails,
        adminAccessMemberList: order?.adminAccessMemberList,
        existingAdminMembers: order?.existingAdminMembers,
        deletedAdminMembers: order?.deletedAdminMembers,
        sonarQubeUser: order?.sonarQubeUser,
        sonarQubeUserDetails: order?.sonarQubeUserDetails,
        sonarQubeUserList: order?.sonarQubeUserList,
        progLanguage: order?.progLanguage,
        progLanguageDetails: order?.progLanguageDetails,
        buildTool: order?.buildTool,
        buildToolDetails: order?.buildToolDetails,
        packageType: order?.packageType,
        packageTypeDetails: order?.packageTypeDetails,
        artifactoryUser: order?.artifactoryUser,
        artifactoryUserDetails: order?.artifactoryUserDetails,
        artifactoryUserList: order?.artifactoryUserList,
        organizationName: order?.organizationName,
        applicationUrl: order?.applicationUrl,
        securityTool: order?.securityTool,
        securityToolCodeRepoList: order?.securityToolCodeRepoList,
        sastMember: order?.sastMember,
        scaMember: order?.scaMember,
        sastMemberDetails: order?.sastMemberDetails,
        sastApplicationName: order?.sastApplicationName,
        sastVersion: order?.sastVersion,
        applicationNameWithVersions: order?.applicationNameWithVersions,
        scaRoles: order?.scaRoles,
        fortifyMembers: order?.fortifyMembers,
        snykMembers: order?.snykMembers,
        sastOption: order?.sastOption,
        scaOption: order?.scaOption,
        snykPreviewMembers: order?.snykPreviewMembers,
        onboardSAST: order?.onboardSAST,
        onboardSCA: order?.onboardSCA,
        snykMemberDetails: order?.snykMemberDetails,
        sastBusinessApplicationDetails: order?.sastBusinessApplicationDetails,
        sastApplicationDetails: order?.sastApplicationDetails,
        addTestPlanMembers: order?.addTestPlanMembers,
        removeTestPlanMembers: order?.removeTestPlanMembers,
        hCode: order?.hCode,
        hCodeDetails: order?.hCodeDetails,
        testPlanUser: order?.testPlanUser,
        testPlanUserDetails: order?.testPlanUserDetails,
        testPlanUserList: order?.testPlanUserList,
        removedTestPlanUser: order?.removedTestPlanUser,
        removedTestPlanUserDetails: order?.removedTestPlanUserDetails,
        removedTestPlanUserList: order?.removedTestPlanUserList,
        isConfigureTouched: order?.isConfigureTouched,
        onboardServiceAccount: order?.onboardServiceAccount,
        onboardRepositories: order?.onboardRepositories,
        onboardMembers: order?.onboardMembers,
        newProjectOnboardMembers: order?.newProjectOnboardMembers,
        newProjectOnboardServiceAccount: order?.newProjectOnboardServiceAccount,
        isServiceAccountExists: order?.isServiceAccountExists,
    };
};

export const getMemberAccessForPreview = (roleData, deletedAdminMembers, removedTestPlanUserList) => {
    const roleMap = {};
    const updateRole = (person, roleName) => {
        const { gpid } = person;
        if (!roleMap[gpid]) {
            roleMap[gpid] = { ...person };
            Object.keys(roleData).forEach((role) => {
                roleMap[gpid][role] = false;
            });
        }
        roleMap[gpid][roleName] = true;
    };
    Object.entries(roleData).forEach(([role, data]) => {
        data.forEach((person) => updateRole(person, role));
    });

    const deletedAdminMembersGpidArray = deletedAdminMembers?.map((member) => member.gpid);
    const result = Object.values(roleMap)?.map((z) => (deletedAdminMembersGpidArray?.includes(z.gpid) ? { ...z, projectAdmin: 'modify' } : z));

    const removedTestplanMembersGpidArray = removedTestPlanUserList?.map((member) => member.gpid);
    const result1 = result?.map((z) => (removedTestplanMembersGpidArray?.includes(z.gpid) ? { ...z, testPlan: 'modify' } : z));
    return result1;
};

// export const getRepoName = (name) => {
//     const url = 'https://dev.azure.com/PepsiCoIT/';
//     return `${url}${name}/_git/`;
// };
