/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { DateRange } from 'react-date-range';
import { CssTextField } from 'components/select';
import Popper from '@mui/material/Popper';
import format from 'date-fns/format';
import { addMonths } from 'date-fns';
import { Paper, Box, IconButton, ClickAwayListener } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { dateRangeValue } from './store';
import dashboardStyle from './style';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DashboardDateRange = () => {
    const [range, setRange] = useState([
        {
            startDate: addMonths(new Date(), -5),
            endDate: new Date(),
            key: 'selection',
        },
    ]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [, setDateRange] = useRecoilState(dateRangeValue);

    const handleSelect = (item) => {
        setRange([item?.selection]);
        setDateRange({ startDate: item?.selection?.startDate, endDate: item?.selection?.endDate });
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(!open);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const id = open ? 'simple-popper' : undefined;

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <CssTextField
                    mt={2}
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('100%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                        '& .MuiOutlinedInput-input': {
                            fontWeight: '500',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#0047BA',
                                color: '#AFB8CC',
                                borderWidth: '2px',
                            },
                            '&:hover fieldset': {
                                borderColor: '#0047BA',
                                borderWidth: '2px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#0047BA',
                                borderWidth: '2px',
                            },
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <>
                                <IconButton onClick={handleClick}>
                                    <CalendarMonthIcon color="primary" />
                                </IconButton>
                            </>
                        ),
                        readOnly: true,
                    }}
                    value={`${format(range[0].startDate, 'MM/dd/yyyy')} - ${format(range[0].endDate, 'MM/dd/yyyy')}`}
                />

                <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: '999' }}>
                    <Paper elevation={5} sx={dashboardStyle.dateRange.calendarWrap}>
                        <Box sx={dashboardStyle.orderFilter.popper.container}>
                            <DateRange
                                onChange={handleSelect}
                                editableDateInputs
                                moveRangeOnFirstSelection={false}
                                ranges={range}
                                months={1}
                                direction="horizontal"
                                maxDate={new Date()}
                            />
                        </Box>
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default DashboardDateRange;
