import { atom } from 'recoil';

export const toggleQuickConfig = atom({
    key: 'NSG-toggleQuickConfig',
    default: false,
});

export const quickConfigDetails = atom({
    key: 'NSG-quickConfigDetails',
    default: undefined,
});

export const toggleSspModels = atom({
    key: 'NSG-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        instanceDetailsModal: { open: false, index: null, type: null },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isMandatoryTagsFullfilled = atom({
    key: 'NSG-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'NSG-orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'NSG-sspOrder',
    default: {
        showPreview: null,
        appProfile: null,
        appProfileDetails: null,
        entityDetails: null,
        sector: null,
        appOwner: null,
        appOwnerDetails: null,
        environment: null,
        region: null,
        regionDisplayname: null,
        dataClassification: null,
        justification: '',
        isaNumber: '',
        instanceDetails: [
            {
                personaType: null,
                sourceName: '',
                isClone: false,
            },
        ],
        estimatedCost: {
            details: [],
            qty: null,
            totalOneTimeCost: {
                frequency: 'one time',
                estCost: null,
                currency: 'USD',
            },
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'There is no cost for the NSG Application Rule onboarding in the shared NSGs from DevX Infra. It is included in shared service of your resource group.',
        },
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const sourceNameErrors = atom({
    key: 'NSG-sourceNameErrors',
    default: [''],
});

export const isValidISANumber = atom({
    key: 'NSG-isValidISANumber',
    default: false,
});

export const isValidOwners = atom({
    key: 'NSG-isValidOwners',
    default: {
        isvalidAppOwner: true,
    },
});

export const environmentDetails = atom({
    key: 'NSG-environmentDetails',
    default: {
        Approval_Manager: '-',
        Project: '-',
    },
});

export const costEstimateState = atom({
    key: 'NSG-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'NSG-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const isValidEndpoint = atom({
    key: 'NSG-isValidEndpoint',
    default: false,
});

export const isValidDatabrickName = atom({
    key: 'NSG-isValidDatabrickName',
    default: true,
});
