import React from 'react';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import { OrderPayload, showisExistADGrp, memberForm } from 'pages/myidm/store';
import { CssTextField } from 'components/select';
import { InputInfo } from 'pages/myidm/components/input-info';

const BusinessApp = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [form, setForm] = useRecoilState(memberForm);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);
    const [, setIsExistADGrp] = useRecoilState(showisExistADGrp);

    const bAppGetApi = useMutation((param) =>
        axios.get(`${endPoints.riServices.RI001.businessApp}?applicationName=${encodeURIComponent(param?.toUpperCase())}`)
    );
    const bAppPostApi = useMutation((payload) => axios.post(`${endPoints.riServices.RI001.businessApp}`, payload));
    const setBussinessApplication = (businessApplication, businessAppDetails) => {
        setOrder({
            ...order,
            businessApplication,
            businessAppDetails,
            isNewADGroup: null,
            appShortName: null,
            groupOwner: null,
            groupOwnerDetails: null,
            adGroup: null,
            adGroupType: null,
            applicationName: null,
            adGroupTypeDetails: null,
            sector: null,
            environment: null,
            crossDomainUser: null,
            isUniversalADGroup: false,
            members: null,
            adGroupMembers: null,
            mypamsafeDetails: [],
        });
        setIsExistADGrp(false);
        setForm({
            ...form,
            selectedMember: null,
            member: null,
        });
    };
    const resetOptions = () => {
        setOptions([]);
    };
    const fetch = React.useMemo(
        () =>
            throttle((inputValue) => {
                bAppGetApi?.mutate(inputValue);
            }, 1000),
        []
    );
    const validateInput = () => {
        if (isTouched && order?.isTouched && !order?.businessApplication) {
            return 'validate';
        }
        return '';
    };
    React.useEffect(() => {
        resetOptions();
        if (ccInputValue !== '' && ccInputValue.length > 2) fetch(ccInputValue);
    }, [ccInputValue]);
    React.useEffect(() => {
        if (bAppGetApi?.isSuccess) {
            if (bAppGetApi?.data?.data?.applications) {
                const combinedValue = bAppGetApi?.data?.data?.applications.map((app) => ({
                    label: `${app.businessApplicationName}`,
                    value: app?.businessApplicationName,
                    data: app,
                }));
                setOptions(combinedValue);
            }
        }
    }, [bAppGetApi?.isSuccess]);
    React.useEffect(() => {
        if (bAppPostApi?.isSuccess) {
            if (bAppPostApi?.data?.data?.applications) {
                const combinedValue = bAppPostApi?.data?.data?.applications.map((app) => ({
                    label: `${app.businessApplicationName}`,
                    value: app?.businessApplicationName,
                    data: app,
                }));
                if (order?.businessAppDetails) combinedValue.push(order?.businessAppDetails);
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [bAppPostApi?.isSuccess]);
    React.useEffect(() => {
        bAppPostApi?.mutate({
            pageOffset: 0,
            pageLimit: 20,
        });
    }, []);
    const isExistsInOptions = (region) => {
        return options.find((option) => option?.label === region.label || option?.value === region);
    };

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else if (value) {
            setBussinessApplication(value?.label, value?.data);
        }
    }, [value]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.businessApplication) {
            setOrder({ ...order, businessApplication: options?.[0].label, businessAppDetails: options?.[0]?.data });
        }
        if (options && options?.length && order?.businessAppDetails && !order?.businessApplication) {
            const businessapp = isExistsInOptions(order?.businessAppDetails);
            setOrder({ ...order, businessApplication: businessapp?.label, businessAppDetails: businessapp?.data });
        }
    }, [options]);
    React.useEffect(() => {
        if (!order?.businessApplication && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.businessApplication, order?.isTouched]);
    const getNoOptionText = () => {
        let text = '';
        if (ccInputValue === '') {
            text = 'Type characters for suggestions.';
        } else if (options?.length === 0 && ccInputValue !== '') {
            text = 'No results found';
        }
        return text;
    };
    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Autocomplete
                    key="busisApplication"
                    size="small"
                    loading={bAppGetApi?.isLoading}
                    value={order?.businessApplication || null}
                    data-testid="businessApp-auto"
                    sx={{ width: '100%' }}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    onInputChange={(event, newInputValue) => {
                        setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                                value: newValue,
                                data: null,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                                value: newValue.inputValue,
                                data: null,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    onKeyUp={(event) => {
                        if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                            setOptions([]);
                            setBussinessApplication(null, null);
                        }
                    }}
                    filterOptions={(x) => x}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disableClearable
                    id="free-solo-with-text-demo"
                    options={options}
                    // freeSolo={false}
                    label="Business Application *"
                    renderInput={(params) => (
                        <CssTextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Business Application *"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                            }}
                            fullWidth
                        />
                    )}
                    className={validateInput()}
                    noOptionsText={getNoOptionText()}
                />
                <InputInfo title="Type characters for suggestions." />
            </Box>
        </>
    );
};

export default React.memo(BusinessApp);
