import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import Modal from 'components/modal';
import { CssTextField } from 'components/select';
import SsoOktaStyles from 'pages/sso-okta/style';
import deleteIcon from 'assets/images/delete_icon.svg';
import addIcon from 'assets/images/add_icon.svg';
import { useRecoilState } from 'recoil';
import Btn from 'components/button';
import { validateLoginLogoutURL } from 'pages/sso-okta/utils/index';
import { OrderPayload, isLoginUrlValid, toggleModels } from 'pages/sso-okta/store';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { commonStyles as style } from 'assets/style';

export const LoginUrl = ({ index }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [isOpen, setIsOpen] = useRecoilState(toggleModels);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setLoginUrlVaild] = useRecoilState(isLoginUrlValid);
    const [helperText, setHelperText] = React.useState(false);
    const [lastModifiedURL, setLastModifiedURL] = React.useState(undefined);

    React.useEffect(() => {
        if (!order?.loginUrl || order?.loginUrl.length === 0) {
            setOrder({
                ...order,
                loginUrl: [''],
            });
        }
    }, [order]);

    const setLoginUrl = (loginUrls) => {
        setOrder({
            ...order,
            loginUrl: loginUrls,
        });
    };

    const addLoginUrl = () => {
        const updatedLoginUrls = order?.loginUrl?.length ? [...order?.loginUrl] : [];
        updatedLoginUrls?.push('');
        setLoginUrl(updatedLoginUrls);
    };

    const removeLoginUrl = (removeIndex) => {
        const updatedLoginUrls = order?.loginUrl?.filter((_, idx) => idx !== removeIndex);
        setLoginUrl(updatedLoginUrls);
    };

    const updateLoginUrl = (value, updateIndex) => {
        const updatedLoginUrls = [...(order?.loginUrl || [])];
        updatedLoginUrls[updateIndex] = value;
        setLoginUrl(updatedLoginUrls);
    };

    React.useEffect(() => {
        const currentLoginUrl = order?.loginUrl?.[index] || '';
        const { isValid } = validateLoginLogoutURL(currentLoginUrl, order?.environment, order?.requestType);

        // Check if the current login URL is invalid
        if (currentLoginUrl && currentLoginUrl !== '' && !isValid) {
            setHelperText('Login URL is Invalid *');
            setLoginUrlVaild(false);
            return;
        }

        // Check if the current login URL is empty
        if (currentLoginUrl === '') {
            setLoginUrlVaild(false);
            setHelperText(false);
            return;
        }

        // Check if the current login URL matches any other login URL
        const urlOccurrences = order?.loginUrl?.filter((url) => url === currentLoginUrl).length;
        if (currentLoginUrl && urlOccurrences > 1) {
            setHelperText('Login URLs should be unique.');
            setLoginUrlVaild(false);
            return;
        }

        // Check if the current login URL matches any logout URL
        if (currentLoginUrl && order?.logoutUrl?.includes(currentLoginUrl)) {
            setHelperText(
                <>
                    <Box>Your login and logout URLs should not be the same.</Box>
                    <Box>Example: Login URL: https://devx-infra.dev.mypepsico.com/login/callback</Box>
                </>
            );
            setLoginUrlVaild(false);
            return;
        }

        // If all validations pass
        setHelperText(false);
        setLoginUrlVaild(true);
    }, [order?.loginUrl, order?.environment, order?.requestType, order?.logoutUrl, index]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.loginUrl?.[index]) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.loginUrl?.[index] && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.loginUrl, order?.isTouched, index]);

    React.useEffect(() => {
        if (isOpen?.isLoginModelOpen) {
            setLastModifiedURL(order?.loginUrl);
        }
    }, [isOpen?.isLoginModelOpen]);

    return (
        <Modal
            title="Login URL"
            handleClose={() => {
                setIsOpen({ ...isOpen, isLoginModelOpen: false });
                setOrder({
                    ...order,
                    loginUrl: lastModifiedURL,
                });
            }}
            open={isOpen.isLoginModelOpen}
            width="40%"
        >
            <Box sx={SsoOktaStyles.loginLogoutModal.wrapper}>
                <Stack direction="row" spacing={1}>
                    <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Add Login URL&apos;s</Typography>
                    <HelpImg
                        title={
                            <Box sx={{ whiteSpace: 'pre' }}>
                                <Box>It is also known as Single sign-on URL where the SAML assertion is sent with a HTTP POST.</Box>
                                <Box>This is often referred to as the SAML Assertion Consumer Service (ACS) URL for your application.</Box>
                                <Box sx={{ fontWeight: 'bold' }}>Login URL should be in this format - https://devx-infra.dev.mypepsico.com/login/callback</Box>
                            </Box>
                        }
                        maxWidth="600px"
                    />
                </Stack>
                <Box sx={SsoOktaStyles.loginLogoutModal.contentWrapper}>
                    {order?.loginUrl?.map((_, idx) => (
                        <Box key={idx?.toString()} sx={SsoOktaStyles.loginLogoutModal.rowWrapper} className="space-x-2 mb-5 animate-opacity">
                            <Box sx={SsoOktaStyles.loginLogoutModal.consumerGrpWrapper}>
                                <CssTextField
                                    size="small"
                                    sx={{
                                        width: (theme) => theme.spacing('100%'),
                                        '& .MuiFormHelperText-root': {
                                            marginTop: (theme) => theme.spacing('-2'),
                                        },
                                    }}
                                    autoComplete="off"
                                    label={`Login URL ${idx + 1} *`}
                                    error={!!helperText}
                                    helperText={helperText && <span>{helperText}</span>}
                                    value={order?.loginUrl?.[idx] || ''}
                                    onChange={(e) => updateLoginUrl(e.target.value, idx)}
                                    className={validateInput()}
                                    data-testid={`loginURLText-${idx}`}
                                />
                            </Box>
                            <Box sx={SsoOktaStyles.loginLogoutModal.iconWrapper}>
                                <Box className="flex w-full ">
                                    {idx !== 0 && (
                                        <Box onClick={() => removeLoginUrl(idx)} sx={SsoOktaStyles.loginLogoutModal.deleteWrapper}>
                                            <img data-testid="remove-btn" style={SsoOktaStyles.loginLogoutModal.delete} src={deleteIcon} alt="deleteIcon" />
                                        </Box>
                                    )}
                                    {idx === order?.loginUrl?.length - 1 && order?.loginUrl?.length < 3 && (
                                        <Box sx={SsoOktaStyles.loginLogoutModal.deleteWrapper} onClick={addLoginUrl}>
                                            <img style={SsoOktaStyles.loginLogoutModal.add} src={addIcon} alt="addIcon" />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="footer" sx={SsoOktaStyles.loginLogoutModal.actionWrapper} gap={2}>
                    <Btn
                        className="btn-disabled custom-btn"
                        variant="contained"
                        onClick={() => {
                            setIsOpen({ ...isOpen, isLoginModelOpen: false });
                            setOrder({
                                ...order,
                                loginUrl: order?.loginUrl,
                            });
                        }}
                        data-testid="save-btn"
                        sx={style.modalSaveButton}
                        disabled={!order?.loginUrl?.some((url) => url && url?.trim() !== '')}
                    >
                        Save
                    </Btn>
                    <Btn
                        className="custom-btn"
                        onClick={() => {
                            setIsOpen({ ...isOpen, isLoginModelOpen: false });
                            setOrder({
                                ...order,
                                loginUrl: lastModifiedURL,
                            });
                        }}
                        variant="outlined"
                        layout="outline"
                        data-testid="cancel-btn"
                        sx={style.modalCancelButton}
                    >
                        Cancel
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

LoginUrl.propTypes = {
    index: PropTypes.number.isRequired,
};

export default LoginUrl;
