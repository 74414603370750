/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
// import { BsQuestionSquare } from 'react-icons/bs';
// import Styles from 'pages/provisioning/_components/style';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} placement={props.placement} />)(
    ({ theme, ...props }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            backgroundColor: theme.palette.white,
            fontSize: 14,
            '&::before': {
                border: '1px solid #E9E9E9',
                color: theme?.palette?.black?.dark,
                backgroundColor: theme.palette.white,
                boxSizing: 'border-box',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            },
        },
        [`& .${tooltipClasses.tooltip}`]: {
            fontSize: 10,
            textAlign: 'left',
            whiteSpace: 'normal',
            width: 'auto',
            maxWidth: props.maxWidth, // Correctly using props
            height: '100%',
            backgroundColor: theme.palette.white,
            color: theme?.palette?.black?.dark,
            border: '1px solid #E9E9E9',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
    })
);

const HelpImg = (props) => {
    const { marginTop, title, maxWidth, placement, ...rest } = props;

    const StyledTooltip = styled(({ className, ...prop }) => <Tooltip {...prop} arrow classes={{ popper: className }} placement={placement} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.arrow}`]: {
                backgroundColor: theme.palette.white,
                fontSize: 14,
                '&::before': {
                    border: '1px solid #E9E9E9',
                    color: theme?.palette?.black?.dark,
                    backgroundColor: theme.palette.white,
                    boxSizing: 'border-box',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                },
            },
            [`& .${tooltipClasses.tooltip}`]: {
                fontSize: 10,
                textAlign: 'left',
                whiteSpace: 'normal',
                width: 'auto',
                maxWidth,
                height: '100%',
                backgroundColor: theme.palette.white,
                color: theme?.palette?.black?.dark,
                border: '1px solid #E9E9E9',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            },
        })
    );
    return (
        <StyledTooltip arrow title={title} data-testid="help-img">
            <Box component="span" {...rest} sx={{ marginTop: marginTop || '1px !important' }}>
                {/* <BsQuestionSquare style={Styles.infoIcon} /> */}
                <img src="/static/images/help_blue.svg" alt="" width={22} />
            </Box>
        </StyledTooltip>
    );
};

HelpImg.propTypes = {
    title: PropTypes.any,
    maxWidth: PropTypes.string,
    placement: PropTypes.string,
    marginTop: PropTypes.string,
};

HelpImg.defaultProps = {
    title: '',
    maxWidth: 'auto',
    placement: 'bottom',
    marginTop: '',
};
export { CustomTooltip };
export default React.memo(HelpImg);
