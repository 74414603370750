import { Table, TableBody, TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import React from 'react';
import PropTypes from 'prop-types';

const ReposList = ({ reposToMigrate, handleRemoveRepo }) => {
    return (
        <TableContainer sx={{ marginTop: 3 }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ padding: '2px', fontWeight: '600' }}>ADO Repo Name</TableCell>
                        <TableCell sx={{ padding: '2px', fontWeight: '600' }}>Target name</TableCell>
                        <TableCell sx={{ padding: '2px', fontWeight: '600', textAlign: 'right' }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reposToMigrate?.map((repo, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{ width: '40%', padding: '2px' }}>{repo?.adoName}</TableCell>
                            <TableCell sx={{ width: '30%', padding: '2px' }}>{repo?.githubName}</TableCell>
                            <TableCell sx={{ width: '30%', padding: '2px', textAlign: 'right' }}>
                                <HighlightOffOutlinedIcon
                                    data-testid={`repo-remove-${index}`}
                                    onClick={() => handleRemoveRepo(repo)}
                                    sx={{ cursor: 'pointer', fontSize: '16px', top: 3, position: 'relative' }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

ReposList.propTypes = {
    reposToMigrate: PropTypes.array,
    handleRemoveRepo: PropTypes.func,
};

ReposList.defaultProps = {
    reposToMigrate: [],
    handleRemoveRepo: undefined,
};

export default React.memo(ReposList);
