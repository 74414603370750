/* eslint-disable react/jsx-props-no-spreading */

import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyleTextField = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: theme.palette.cmpPrimary.main,
    },
    '& label.MuiFormLabel-filled': {
        // background: '#00709B',
        color: theme.palette.blue.main,
    },
    '& label': {
        color: theme.palette.gray.graniteGray,
        fontSize: '14px !important',
        fontWeight: '400 !important',
        fontFamily: theme.typography.fontFamily,
    },
    '& .MuiInputBase-input': {
        color: theme.palette.gray.graniteGray,
        fontSize: '14px !important',
        fontWeight: '400 !important',
        fontFamily: theme.typography.fontFamily,
        borderRadius: '4px',
        '&.Mui-disabled': {
            background: 'transparent',
        },
    },

    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            color: theme.palette.textColors.$color_text_tertiary,
            borderColor: theme.palette.border.$color_border_input,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.border.$color_border_input_hover,
        },
        '&.Mui-focused fieldset': {
            color: theme.palette.textColors.$color_text_primary,
            border: `2px solid ${theme.palette.border.$color_border_input_focus}`,
        },
    },
    '& .MuiInputLabel-root': {
        transform: 'translate(14px, 10px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -3px)',
        fontSize: '12px !important',
        fontWeight: '400 !important',
        fontFamily: theme.typography.fontFamily,
    },
    '& legend': {
        fontSize: '12px !important',
        fontWeight: '500 !important',
        fontFamily: theme.typography.fontFamily,
    },
    '& .Mui-disabled': {
        color: theme.palette.gray.graniteGray,
        backgroundColor: '#f4f4f4',
    },
}));

export default StyleTextField;
