import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const styles = {
    wrapper: { display: 'flex', flexDirection: 'column', backgroundColor: 'white', height: '100%', width: '100%' },
    container: {
        // display: 'flex',
        // justifyContent: 'flex-start',
        // width:'85%',
        height: '82px',
        borderBottom: '1px solid #E8E8E8',
        backgroundColor: '#ffffff',
        paddingLeft: '9.5%',
    },
    searchfield: { width: '85%', '&.MuiFormControl-root div': { height: 40 } },
    searchbtn: {
        backgroundColor: '#0047BA',
        color: '#FFFFFF',
        width: '100px',
        height: 40,
        '&:hover': {
            backgroundColor: '#F99C24',
            color: '#FFFFFF',
        },
    },
    emptywrapper: {
        // background: '#F8F8F8 0% 0% no-repeat padding-box',
    },
    helpcardWrapper: { overflow: 'auto' },
    helpcardContainer: { display: 'flex', justifyContent: 'center', my: 2 },
    helpCardBox: { display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' },

    helpCard: {
        wrapper: {
            mb: 1,
            border: '0.5px solid #E6E6E6',

            width: '90%',
        },
        title: {
            textAlign: 'justify',
            fontSize: 16,
            mb: 1,
            '&:hover': {
                cursor: 'pointer',
                color: '#0047BA',
                // backgroundColor: '#E5ECF8',
                textDecoration: 'underline',
            },
        },
        summary: {
            fontSize: 14,
            color: '#626262',
            lineHeight: 1.5,
            textAlign: 'justify',
            mb: 1,
        },
    },

    modal: {
        downloadBtn: {
            backgroundColor: '#F9A02C',
            color: 'white',
            letterSpacing: 1,
            '&:hover': {
                backgroundColor: '#F9A02C',
                color: 'white',
                letterSpacing: 1,
            },
            width: 150,
            height: 35,
        },
        circularprog: { marginLeft: '10px' },
        wrapper: { minWidth: '40%', width: '100%', height: '100%' },
        emptywrapper: { color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' },
        empty: { color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' },
        emptyMsg: { color: '#000000' },
        stackwrapper: { minWidth: '40%', height: '100%' },
        embed: { height: 'inherit !important', width: '100%' },
        filedownbtn: { color: 'white' },
    },
};

export default styles;

export const CustomTagButton = styled(Button)(() => ({
    backgroundColor: '#E5ECF8',
    cursor: 'unset',
    color: '#0047BA',
    height: 22,
    fontSize: 10,
    fontWeight: 400,
    px: 0,
    margin: 1,
    '&:hover': {
        background: '#E5ECF8',
        color: '#0047BA',
    },
}));
