/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack, CircularProgress } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { OrderPayload, isValidISANumber } from 'pages/reference-implementation/provisioning/RI004O/store';
import ProvisioningStyles from 'pages/provisioning/application-gateway/style';
import useDebounce from 'pages/reference-implementation/provisioning/RI004O/hooks/use-debounce';

const ISANumber = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setValidIsaNumber] = useRecoilState(isValidISANumber);
    const [value, setValue] = React.useState(order?.isaNumber || '');
    const [helperMsg, setHelperMsg] = React.useState({ showHelperMsg: false, helperMsg: '' });

    const debouncedValue = useDebounce(value, 500);

    const isaValidationApi = useMutation(() =>
        axios.post(`${endPoints.riServices.COMMON.isaValidation}?applicationService=${order?.iteAppService}&isaNumber=${debouncedValue}`)
    );

    const SetISADoc = (isaNumber = null) => {
        setOrder({
            ...order,
            isaNumber,
            isTouched: false,
        });
    };

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.isaNumber) || helperMsg?.showHelperMsg) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (order?.iteAppService && debouncedValue) {
            isaValidationApi.mutate();
        }
    }, [order?.iteAppService, debouncedValue]);

    React.useEffect(() => {
        if (isaValidationApi.isSuccess) {
            if (isaValidationApi.data?.isSuccess) {
                setValidIsaNumber(true);
                setHelperMsg({ showHelperMsg: false, helperMsg: '' });
            } else {
                setValidIsaNumber(false);
                setHelperMsg({
                    showHelperMsg: true,
                    helperMsg: isaValidationApi.data?.messages?.[0] || 'Invalid ISA Number',
                });
            }
        }
        if (isaValidationApi.isError) {
            setValidIsaNumber(false);
            setHelperMsg({
                showHelperMsg: true,
                helperMsg: 'Unable to validate ISA Number, please cross check given IsaNumber',
            });
        }
    }, [isaValidationApi.isSuccess, isaValidationApi.isError]);

    React.useEffect(() => {
        if (!order?.isaNumber && order?.isTouched) {
            setIsTouched(true);
        }
    }, [order?.isaNumber, order?.isTouched]);

    React.useEffect(() => {
        if (order?.isaNumber) {
            setValue(order?.isaNumber);
        } else if (order?.isaNumber === null) {
            setValue('');
            setValidIsaNumber(false);
        }
    }, [order?.isaNumber]);

    const handleChangeIsaValidationNumber = (val) => {
        setValidIsaNumber(false);
        setValue(val);
        SetISADoc(val);
    };

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('130%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="ISA Number *"
                helperText={helperMsg?.showHelperMsg && <span style={ProvisioningStyles.disposal.helpText}>{helperMsg.helperMsg}</span>}
                value={value || ''}
                placeholder="ISA Number"
                onChange={(e) => {
                    handleChangeIsaValidationNumber(e.target.value?.toUpperCase());
                }}
                className={validateInput()}
                data-testid="IsaNumberText"
                InputProps={{
                    endAdornment: <>{isaValidationApi.isLoading && <CircularProgress color="inherit" size={16} />}</>,
                }}
            />
        </Stack>
    );
};

export default ISANumber;
