import React from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import Style from 'pages/project-onboarding/style';
import InfoTooltip from 'pages/project-onboarding/components/info-tooltip';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';

const SonarQubeDetails = () => {
    const [order] = useRecoilState(OrderPayload);

    const LabelValue = (label, value) => (
        <Grid item xs={6} sm={6}>
            <Box sx={Style?.preview?.projectDetails?.subWrapper}>
                <Box sx={Style?.preview?.projectDetails?.label} display="flex" gap={1}>
                    {label}:
                    {order?.isServiceAccountExists === 'no' && label === 'Service Account Exist' && (
                        <InfoTooltip
                            maxWidth="400px"
                            title={
                                <>
                                    <Box sx={Style.helpImgStyle.wrapper}>
                                        <Box sx={Style.helpImgStyle.namingRuleWrapper}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={Style.helpImgStyle.namingRule}>
                                                    <Typography sx={Style.helpImgStyle.typographyStyle} marginBottom="4px">
                                                        If No option is selected, DevX Infra will create an AD service account as part of the same request and
                                                        onboard in SonarQube.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </>
                            }
                        />
                    )}
                </Box>
                <Box sx={Style?.preview?.projectDetails?.value}>{value ? value?.charAt(0)?.toUpperCase() + value?.slice(1) : '-'}</Box>
            </Box>
        </Grid>
    );
    return (
        <Box sx={Style?.preview?.wrapper}>
            <Box sx={Style?.preview?.heading}>SonarQube</Box>
            <Divider variant="horizontal" sx={Style?.preview?.divder} />
            <Grid container sx={Style?.preview?.fs?.container}>
                <Grid item xs={12} sm={12}>
                    <Stack direction="column" spacing={1}>
                        <Box sx={Style?.preview?.fs?.subWrapper}>
                            <Grid container sx={Style?.preview?.projectDetails?.container} spacing={2}>
                                {order?.isServiceAccountExists && LabelValue('Service Account Exist', order?.isServiceAccountExists)}
                                {order?.isServiceAccountExists === 'yes' && LabelValue('Service Account', order?.serviceAccount)}
                                {order?.onboardRepositories && LabelValue('Onboard Repositories', order?.onboardRepositories ? 'Yes' : 'No')}
                            </Grid>
                        </Box>
                        {order?.sonarQubeCodeRepoList?.length > 0 &&
                            order?.sonarQubeCodeRepoList?.some((repo) => repo?.repo !== '') &&
                            order?.isADOProject === 'yes' && (
                                <Box sx={Style?.preview?.fs?.subWrapper}>
                                    <Box sx={Style?.preview?.fs?.label}>Code Repository:</Box>
                                    <Box sx={Style?.preview?.fs?.value}>
                                        {order?.sonarQubeCodeRepoList?.map((repo, index) => (
                                            <Box key={index.toString()}>{repo?.repo}</Box>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SonarQubeDetails;
