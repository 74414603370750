import React from 'react';
import PropTypes from 'prop-types';
import { commonStyles as style } from 'assets/style';
import { Grid, Typography, Box, CircularProgress, Tooltip } from '@mui/material';
import dashboardStyle, { Item } from '../style';

function Card({ count, text, secondaryText, isLoading, tooltip }) {
    return (
        <Grid item xs={2}>
            <Item sx={dashboardStyle.card.itemwrapper}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px' }}>
                    {!isLoading && <Typography sx={dashboardStyle.card.title}>{count}</Typography>}
                    {isLoading && <CircularProgress sx={dashboardStyle.card.circularProgressColor} size={16} />}
                </Box>
                <Box sx={dashboardStyle.card.text_wrapper}>
                    {tooltip && tooltip !== '' && (
                        <Tooltip title={tooltip} placement="bottom">
                            <Typography sx={{ ...style.pointerCursor, fontSize: { md: '10px', lg: '14px' } }}>{text}</Typography>
                        </Tooltip>
                    )}
                    {!tooltip && <Typography sx={{ fontSize: { md: '11px', lg: '15px' } }}>{text}</Typography>}
                    <Typography sx={{ fontSize: { md: '11px', lg: '15px' } }}>{secondaryText}</Typography>
                </Box>
            </Item>
        </Grid>
    );
}

Card.propTypes = {
    count: PropTypes.number,
    text: PropTypes.string,
    secondaryText: PropTypes.string,
    isLoading: PropTypes.bool,
    tooltip: PropTypes.string,
};

Card.defaultProps = {
    count: 0,
    text: '',
    secondaryText: '',
    isLoading: true,
    tooltip: '',
};

export default React.memo(Card);
