import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import {
    sspSubmitOrder,
    toggleSspModels,
    isMandatoryTagsFullfilled,
    isValidNamespaceName,
    isInvalidEventhubName,
    isEventhubNameExist,
    isInvalidPartitionCount,
} from 'pages/provisioning/store/event-hub';
import { cartCount } from 'pages/cart/store';
import { selectOrder } from 'pages/orders/store';
import { commonStyles as style } from 'assets/style';
import Btn from 'components/button';
import { validateObject } from 'utils/validateObject';
import { getPayload } from 'pages/provisioning/event-hub/getPayload';
import { getCartDetails, getCartItemIdCartDetails } from 'utils/getCartDetails';

const CartBtn = () => {
    const history = useHistory();
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const [isLoading, setLoading] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const [maxAutoInflateValidate, setMaxAutoInflateValidate] = React.useState(false);
    const validNamespaceName = useRecoilValue(isValidNamespaceName);
    const invalidEventhubName = useRecoilValue(isInvalidEventhubName);
    const eventhubNameExist = useRecoilValue(isEventhubNameExist);
    const [invalidPartitionCount] = useRecoilState(isInvalidPartitionCount);
    const isMandatoryTags = useRecoilValue(isMandatoryTagsFullfilled);
    const [, setSelectedOrderId] = useRecoilState(selectOrder);
    const [isEventHubDetailEmpty, setEventHubDetailEmpty] = React.useState(false);
    const [namespaceExistInCart, setNamespaceExistInCart] = React.useState(false);
    const [, setCartCount] = useRecoilState(cartCount);
    const [existingNamespaceName, setExistingNamespaceName] = React.useState('');

    const handleRedirect = async () => {
        await setSelectedOrderId(cartItemId);
        setLoading(false);
        history.push('/orders');
    };
    const handleSubmit = async () => {
        setOrder({ ...order, cartMessage: false });
        setLoading(true);
        const payload = await getPayload({ order, catalogCode, catalogServiceId });
        try {
            const validate = await validateObject({ data: payload, catalogCode });
            if (validate?.isValid) {
                if (!namespaceExistInCart || (namespaceExistInCart && existingNamespaceName === order?.namespaceName)) {
                    setOrder({ ...order, cartMessage: false });
                    if (cartItemId && operation && !catalogServiceId) {
                        const updateCartItem = await axios.put(`${endPoints.cart.addItem}/${cartItemId}`, payload);
                        if (updateCartItem?.isSuccess) {
                            setModal({ ...modal, isUpdatedToCart: true });
                            setLoading(false);
                        } else {
                            setLoading(false);
                        }
                    }
                    if (cartItemId && operation && catalogServiceId) {
                        const saveChanges = await axios.put(
                            `${endPoints.order.saveOrder.replace('{orderId}', cartItemId).replace('{catalogServiceId}', catalogServiceId)}`,
                            payload?.catalogService
                        );
                        if (saveChanges?.isSuccess) {
                            handleRedirect();
                        } else {
                            setLoading(false);
                        }
                    }
                } else if (namespaceExistInCart && existingNamespaceName !== order?.namespaceName) {
                    setOrder({ ...order, cartMessage: true });
                    setLoading(false);
                }

                if (!cartItemId && !operation && !catalogServiceId) {
                    if (!namespaceExistInCart) {
                        setModal({ ...modal, isSubmitOrderSuccess: true });
                        setLoading(false);
                        const submitOrder = await axios.post(endPoints.cart.addItem, payload);
                        if (submitOrder?.data) {
                            const updatedCart = await axios.get(endPoints.cart.getCartCount);
                            if (updatedCart?.data) {
                                setCartCount(updatedCart?.data?.itemCount);
                            }
                            setModal({ ...modal, isSubmitOrderSuccess: true });
                            setLoading(false);
                        } else {
                            setLoading(false);
                        }
                    } else {
                        setOrder({ ...order, cartMessage: true });
                        setLoading(false);
                    }
                }
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            // eslint-disable-next-line
            console.log('Exception during schema validation', e);
        }
    };

    React.useEffect(() => () => setOrder({ ...order, cartMessage: false }), []);

    React.useEffect(() => {
        setMaxAutoInflateValidate(false);
        if (order?.isAutoInflate === 'yes' && !order?.autoInflateMaxThroughputUnits) setMaxAutoInflateValidate(true);
        if (order?.isAutoInflate === 'yes' && order?.autoInflateMaxThroughputUnits) {
            if (Number(order?.throughputUnits) > Number(order?.autoInflateMaxThroughputUnits)) {
                setMaxAutoInflateValidate(true);
            } else {
                setMaxAutoInflateValidate(false);
            }
        }
    }, [order?.isAutoInflate, order?.throughputUnits, order?.autoInflateMaxThroughputUnits]);

    React.useEffect(() => {
        let errorCount = 0;
        order?.eventHubDetails?.forEach((z) => {
            if (!z?.eventHubName || !z?.retention || !z?.partitionCount || !z?.consumerGroupList?.[0]?.value) {
                // eslint-disable-next-line no-plusplus
                errorCount++;
            }
            setEventHubDetailEmpty(errorCount !== 0);
        });
    }, [order?.eventHubDetails]);

    function duplicateEventHubName() {
        const eventHubNameArr = order?.eventHubDetails?.map((data) => data?.eventHubName);
        // eslint-disable-next-line
        for (var i = 0; i < eventHubNameArr?.length - 1; i++) {
            // eslint-disable-next-line
            for (var j = i + 1; j < eventHubNameArr?.length; j++) {
                if (eventHubNameArr[i] === eventHubNameArr[j]) {
                    return true;
                }
            }
        }
        return false;
    }

    React.useEffect(() => {
        setNamespaceExistInCart(false);
        const fetchData = async () => {
            const cartDetails = await getCartDetails({ catalogCode });
            if (cartDetails?.map((item) => item?.catalogConfig?.namespaceName)?.includes(order?.namespaceName)) {
                setNamespaceExistInCart(true);
            } else {
                setNamespaceExistInCart(false);
            }
            if (cartItemId) {
                const cartItemIdCartDetails = await getCartItemIdCartDetails({ catalogCode, cartItemId });
                if (cartItemIdCartDetails) {
                    setExistingNamespaceName(cartItemIdCartDetails?.namespaceName);
                }
            }
        };
        if (order?.namespaceName) fetchData();
    }, [order?.namespaceName]);

    const isReadyToSubmit = () =>
        !order?.environment ||
        !order?.sector ||
        !order?.region ||
        !order?.landingZoneDetails ||
        !order?.hCodeDetails ||
        !order?.namespaceName ||
        validNamespaceName ||
        maxAutoInflateValidate ||
        isEventHubDetailEmpty ||
        duplicateEventHubName() ||
        invalidEventhubName ||
        eventhubNameExist ||
        invalidPartitionCount ||
        isMandatoryTags;

    const handleValidation = () => {
        if (!isReadyToSubmit()) {
            handleSubmit();
        } else {
            const result = [];
            order?.eventHubDetails?.forEach((z) => {
                if (!z.eventHubName || !z.partitionCount || !z.retention) {
                    const updateIsTouched = { ...z, isEventHubTouched: true };
                    result.push(updateIsTouched);
                } else result.push(z);
            });
            setOrder({ ...order, eventHubDetails: result, isTouched: true });
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {cartItemId && operation && !catalogServiceId && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={isLoading}
                        sx={style?.primaryButton}
                        data-testid="update-cart-btn"
                    >
                        {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Update Cart'}
                    </Btn>
                )}
                {cartItemId && operation && catalogServiceId && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={isLoading}
                        sx={style?.primaryButton}
                        data-testid="save-changes-btn"
                    >
                        {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Save Changes'}
                    </Btn>
                )}
                {!cartItemId && !operation && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={isLoading}
                        sx={style?.primaryButton}
                        data-testid="add-to-cart-btn"
                    >
                        {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Add to Cart'}
                    </Btn>
                )}
            </Box>
        </>
    );
};

CartBtn.propTypes = {};

CartBtn.defaultProps = {};

export default React.memo(CartBtn);
