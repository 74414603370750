import { linuxOELCatalogDefinitionId, linuxSLESCatalogDefinitionId, SlesPgtCatalogDefinitionId, SlesPgtDBCatalogDefinitionId } from 'utils/catalogDefinitionId';

export const formOrderObjFromCart = (data = null, osType = null, serverType = null, sku = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const dataDisks = data?.itemDetail?.catalogService?.catalogConfig?.diskDefinitions || null;
    const os = data?.itemDetail?.catalogService?.catalogConfig?.os || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    const catalogCode = data?.itemDetail?.catalogService?.catalogServiceCode;
    const getDataDisks = () => {
        let modifiedDisks = [];
        if (![...SlesPgtDBCatalogDefinitionId]?.includes(catalogCode)) {
            modifiedDisks = dataDisks?.map((item) => ({
                // eslint-disable-next-line
                diskType: item?.diskType ? item?.diskType : ['Production'].includes(environmentDetails?.environment) ? 'Premium SSD' : 'Standard SSD',
                diskDriveLetter: item?.diskDriveLetter || '',
                mountPoint: item?.mountPoint || '',
                // eslint-disable-next-line
                fsType: item?.fsType ? item?.fsType : os?.osType === 'Windows' ? 'NTFS' : 'ext4',
                diskSizeInGib: item?.diskSizeInGib,
                ...([...linuxOELCatalogDefinitionId, ...SlesPgtCatalogDefinitionId]?.includes(catalogCode) && {
                    diskPurpose: item?.diskPurpose,
                    ...(['oracle_os', 'suse_lvm_app_fs']?.includes(item?.diskPurpose) && { mountPoint: item?.diskLabel, disableFields: true }),
                }),
            }));
        } else if ([...SlesPgtDBCatalogDefinitionId]?.includes(catalogCode)) {
            modifiedDisks = dataDisks?.map((item) => ({
                // eslint-disable-next-line
                ...item,
                disableFields: item?.fsType ? undefined : true,
            }));
        }
        return modifiedDisks;
    };
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: entityDetails?.costCenter,
            projectName: entityDetails?.project,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: data?.itemDetail?.catalogService?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        hCode: entityDetails?.costCenter || null,
        ...(entityDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!entityDetails?.costCenter && { hCodeDetails: null }),
        environment: environmentDetails?.environment || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        // regionDisplayName: data?.region?.name || null,
        ...(![...SlesPgtCatalogDefinitionId, ...SlesPgtDBCatalogDefinitionId]?.includes(catalogCode) && {
            osVersion: os?.publisher ? `${os?.publisher},${os?.sku}` : null,
        }),
        ...([...SlesPgtCatalogDefinitionId, ...SlesPgtDBCatalogDefinitionId]?.includes(catalogCode) && {
            osVersion: os?.sku,
            sapSid: catalogConfig?.sapUserName?.slice(0, 3),
            sapGid: catalogConfig?.sapGroupName,
            ...(catalogConfig?.sapAccountOwner && {
                sapAccountOwner: null,
                sapAccountOwnerDetails: { name: catalogConfig?.sapAccountOwner?.name, gpid: catalogConfig?.sapAccountOwner?.gpid },
            }),
            sapSolution: catalogConfig?.sapSolution,
        }), // osVersionDetail: data?.osType || null,
        osType: os?.osType,
        sku,
        vmType: data?.itemDetail?.catalogService?.catalogConfig?.vmType || null,
        dataDisksCount: dataDisks?.length || 0,
        catalogOffer: os?.offer,
        dbSizeDetails: { valueInGB: parseInt(catalogConfig?.dataBaseSize, 10) },
        dataDisks: getDataDisks() || [],
        domain: environmentDetails?.domain || null,
        // domainDetail: data?.domain || null,
        adousDefault: environmentDetails?.adOu || 'Default OU',
        ...(osType === 'Windows' && { adGroup: data?.itemDetail?.catalogService?.catalogConfig?.adGroup || '' }),
        ...(osType === 'Linux' && { samAccountId: data?.itemDetail?.catalogService?.catalogConfig?.samAccountId || '' }),
        ...(environmentDetails?.availabilityZones && {
            haDeployment: 'Availability Zones',
            availabilityZone: environmentDetails?.availabilityZones,
            isValidAvailabilityZone: true,
        }),
        ...(environmentDetails?.availabilitySet && {
            haDeployment: 'Availability Set',
            isValidAvailabilityZone: true,
        }),
        ...(catalogConfig?.serverRefresh && {
            retiringServerName: catalogConfig?.retiringServerName || null,
            retiringServerSysId: catalogConfig?.retiringServerSysId || null,
            scheduledRetirement: catalogConfig?.scheduledRetirement || null,
            disposalReason: catalogConfig?.disposalReason || null,
        }),
        quantity: data?.itemDetail?.catalogService?.quantity,
        timeZone: data?.itemDetail?.catalogService?.timeZone,
        acceleratedNetworking: catalogConfig?.acceleratedNetworking === true ? 'yes' : 'no',
        serverRefresh: catalogConfig?.serverRefresh === true ? 'yes' : 'no',
        serverType,
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null, osType = null, serverType = null, sku = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const dataDisks = catalogConfig?.diskDefinitions || null;
    const os = catalogConfig?.os || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;
    const getDataDisks = () => {
        let modifiedDisks = [];
        if (![...SlesPgtDBCatalogDefinitionId]?.includes(catalog?.catalogServiceCode)) {
            modifiedDisks = dataDisks?.map((item) => ({
                // eslint-disable-next-line
                diskType: item?.diskType ? item?.diskType : ['Production'].includes(environmentDetails?.environment) ? 'Premium SSD' : 'Standard SSD',
                diskDriveLetter: item?.diskDriveLetter || '',
                mountPoint: item?.mountPoint || '',
                // eslint-disable-next-line
                fsType: item?.fsType ? item?.fsType : os?.osType === 'Windows' ? 'NTFS' : 'ext4',
                diskSizeInGib: item?.diskSizeInGib,
                ...([...linuxOELCatalogDefinitionId, ...SlesPgtCatalogDefinitionId]?.includes(catalog?.catalogServiceCode) && {
                    diskPurpose: item?.diskPurpose,
                    ...(['oracle_os', 'suse_lvm_app_fs']?.includes(item?.diskPurpose) && { mountPoint: item?.diskLabel, disableFields: true }),
                }),
            }));
        } else if ([...SlesPgtDBCatalogDefinitionId]?.includes(catalog?.catalogServiceCode)) {
            modifiedDisks = dataDisks?.map((item) => ({
                // eslint-disable-next-line
                ...item,
                disableFields: item?.fsType ? undefined : true,
            }));
        }
        return modifiedDisks;
    };
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: environmentDetails?.projectName,
            billingEntity: catalogConfig?.billingEntity,
            sector: catalog?.sector,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        environment: environmentDetails?.environment || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        // regionDisplayName: data?.region?.name || null,
        ...(![...SlesPgtCatalogDefinitionId, ...SlesPgtDBCatalogDefinitionId]?.includes(catalog?.catalogServiceCode) && {
            osVersion: os?.publisher ? `${os?.publisher},${os?.sku}` : null,
        }),
        ...([...SlesPgtCatalogDefinitionId, ...SlesPgtDBCatalogDefinitionId]?.includes(catalog?.catalogServiceCode) && {
            osVersion: os?.sku,
            sapSid: catalogConfig?.sapUserName?.slice(0, 3),
            sapGid: catalogConfig?.sapGroupName,
            ...(catalogConfig?.sapAccountOwner && {
                // sapAccountOwner: catalogConfig?.sapAccountOwner,
                sapAccountOwner: null,
                sapAccountOwnerDetails: { name: catalogConfig?.sapAccountOwner?.name, gpid: catalogConfig?.sapAccountOwner?.gpid },
            }),
            // Gid: catalogConfig?.sapGroupId,
            // Uid: catalogConfig?.sapUserId,
            sapSolution: catalogConfig?.sapSolution,
        }),

        // osVersionDetail: data?.osType || null,
        osType: os?.osType,
        sku,
        vmType: catalogConfig?.vmType || null,
        dataDisksCount: dataDisks?.length || 0,
        catalogOffer: os?.offer,
        dbSizeDetails: { valueInGB: parseInt(catalogConfig?.dataBaseSize, 10) },
        dataDisks: getDataDisks() || [],
        domain: environmentDetails?.domain || null,
        // domainDetail: data?.domain || null,
        adousDefault: environmentDetails?.adOu || 'Default OU',
        ...(osType === 'Windows' && { adGroup: catalogConfig?.adGroup || '' }),
        ...(osType === 'Linux' && { samAccountId: catalogConfig?.samAccountId || '' }),
        ...(environmentDetails?.availabilityZones && {
            haDeployment: 'Availability Zones',
            availabilityZone: environmentDetails?.availabilityZones,
            isValidAvailabilityZone: true,
        }),
        ...(environmentDetails?.availabilitySet && {
            haDeployment: 'Availability Set',
            isValidAvailabilityZone: true,
        }),
        ...(catalogConfig?.serverRefresh && {
            retiringServerName: catalogConfig?.retiringServerName || null,
            retiringServerSysId: catalogConfig?.retiringServerSysId || null,
            scheduledRetirement: catalogConfig?.scheduledRetirement || null,
            disposalReason: catalogConfig?.disposalReason || null,
        }),
        acceleratedNetworking: catalogConfig?.acceleratedNetworking === true ? 'yes' : 'no',
        serverRefresh: catalogConfig?.serverRefresh === true ? 'yes' : 'no',
        quantity: catalog?.quantity,
        timeZone: catalog?.timeZone,
        serverType,
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    environment: null,
    appTier: null,
    appTierCode: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    envDetails: null,
    osVersion: null,
    osVersionDetail: null,
    osDiskType: null,
    vmType: null,
    vmTypeDetails: null,
    quantity: 1,
    maxDataDiskCount: null,
    dataDisksCount: null,
    dataDisks: [],
    defaultDataDisks: [],
    adGroup: null,
    hoursOfOperation: null,
    hoursOfOperationDetail: null, // { active: 1, id: '763cf923-85d9-43f7-b338-7be17fded399', name: 'Always ON', type: 'hours_of_operation' },
    timeZone: 'UTC',
    timeZoneDetail: null, // { id: 'd3bb59b6-72b3-49c0-a1c2-c0494ea28802', name: 'UTC', type: 'time_zones', value: 'Default-UTC' }
    domain: null,
    domainDetails: null,
    hCode: null,
    hCodeDetails: null,
    billingEntity: null,
    billingEntityDetails: null,
    adousDefault: null,
    adous: null,
    adousDetails: null,
    sector: null,
    sectorDetails: null,
    tags: [],
    tagsQc: null,
    estimatedCost: resetCostEstimateDetails,
    acceleratedNetworking: 'yes',
    serverRefresh: 'no',
    validationStatus: 'clearValidation',
    isTouched: false,
    haDeployment: null,
    availabilityZone: null,
    availabilityZoneOptions: [],
    retiringServerName: null,
    retiringServerSysId: null,
    disposalReason: null,
    scheduledRetirement: null,
    sapGid: null,
    sapSid: null,
    sapAccountOwner: null,
    sapAccountOwnerDetails: null,
    Gid: null,
    Uid: null,
    catalogOffer: null,
    catalogOfferDetail: null,
    dbSize: null,
    dbSizeDetails: null,
};

export const resetForQuickConfig = {
    environment: null,
    appTier: null,
    appTierCode: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    osVersion: null,
    osVersionDetail: null,
    vmType: null,
    vmTypeDetails: null,
    dataDisksCount: 0,
    dataDisks: [],
    domain: null,
    domainDetail: null,
    adousDefault: null,
    haDeployment: null,
    availabilityZone: null,
    availabilityZoneOptions: [],
};

export const resetEnvironmentDetails = {
    Subscription: '-',
    'DR Priority': '-',
    Resource_Group: '-',
    Metallic_Selection: '-',
    Backup_Tier: '-',
    Virtual_Network: '-',
    // Network_Security_Group: '-',
    Subnet_Name: '-',
    // Application_Security_Group: '-',
    Recovery_Vault_Name: '-',
    Approval_Manager: '-',
    Project: '-',
    Availability_Set: '-',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isDiskOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
    isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
    isPgtDiskOpen: false,
};
export const getSKUNameForSearch = ({ catalogCode }) => {
    if (linuxSLESCatalogDefinitionId?.includes(catalogCode)) {
        return 'SLES';
    }
    return 'OEL';
};
export const checkMountPointExists = (mountPoint, disableFields = false, reservedPaths = []) => {
    if (!disableFields) {
        const mountPoints = reservedPaths || [];
        // const expression=/^\/[a-zA-Z][a-zA-Z0-9_-]*$/;
        let ValidMountPoint = { shhowMessage: false, message: null };
        if (mountPoint && (mountPoint !== '' || mountPoint !== null)) {
            if (!/^\/(?:[a-zA-Z0-9_-]+\/?)*$/?.test(mountPoint)) {
                ValidMountPoint = { shhowMessage: true, message: 'should start with / followed by alphabet ' };
            } else if (mountPoints.indexOf(mountPoint) > -1) {
                ValidMountPoint = {
                    shhowMessage: true,
                    message: `following paths are reserved
                ${reservedPaths?.join(', ')}`,
                };
            } else {
                ValidMountPoint = { shhowMessage: false, message: null };
            }
        }
        return ValidMountPoint;
    }
    return '';
};

export const isMountPointDuplicate = (dataDisks) => {
    const mountPointsArray = dataDisks?.filter((disk) => disk?.mountPoint && disk?.mountPoint)?.map((d) => d?.mountPoint) || [];
    return new Set(mountPointsArray)?.size !== mountPointsArray?.length;
};
export const getDataDiskSize = (data) => {
    const size = data?.values[0]?.possibleValues || [];
    return size.map((item) => item);
};
export const isValidMountPoint = (disableField, mountPt) => {
    let result = true;
    if (!disableField) {
        // result = mountPt?.charAt(0) === '/';
        result = /^\/(?:[a-zA-Z0-9_-]+\/?)*$/?.test(mountPt);
    }
    return result;
};

export const ispgthanaFormValid = (dataDisks, reservedPaths) => {
    let errorCount = 0;
    let isDataDiskValid = false;
    if (dataDisks?.length) {
        dataDisks?.forEach((disk) => {
            if (
                !(
                    (disk?.disableFields &&
                        disk.diskSizeInGib !== '' &&
                        disk.diskType !== '' &&
                        disk.diskCount !== '' &&
                        disk.diskCount !== 0 &&
                        (disk?.diskTier && disk?.diskTier !== null ? /^[0-9]+$/.test(disk.diskCount) : true) &&
                        disk.diskCount !== '0' &&
                        disk.totalSize !== undefined &&
                        disk.totalSize !== '') ||
                    (!disk?.disableFields &&
                        disk.diskSizeInGib !== '' &&
                        disk.diskType !== '' &&
                        disk.mountPoint !== '' &&
                        disk.mountPoint !== undefined &&
                        disk.fsType !== '' &&
                        !reservedPaths?.includes(disk.mountPoint) &&
                        isValidMountPoint(disk?.disableFields, disk?.mountPoint))
                )
            ) {
                // eslint-disable-next-line no-plusplus
                errorCount++;
            }
        });
    }
    isDataDiskValid = errorCount === 0;
    return isDataDiskValid;
};
