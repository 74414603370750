const style = {
    warningIcon: { fill: '#D78B95', height: (theme) => theme.spacing(3), width: (theme) => theme.spacing(3), mt: (theme) => theme.spacing('-3px') },
    errorAlert: {
        // backgroundColor: '#AE172B',
        backgroundColor: (theme) => theme.palette.background.$color_background_button_primary_danger,
        color: '#FFFFFF',
        display: 'flex ',
        alignItems: 'center ',
        justifyContent: 'center ',
        fontSize: 14,
        '& .MuiAlert-action': {
            display: 'none',
        },
    },
};
export default style;
