import React from 'react';
import { Alert, Box, FormHelperText } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Select from 'components/select';
import Btn from 'components/button';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/github-onboarding/store';
import { commonStyles as style } from 'assets/style';
import reposLimit from '../../../../utils/config';

const ReposSearch = ({ isLoading, handleAddRepo, reposToMigrate }) => {
    const [order] = useRecoilState(OrderPayload);
    const [selected, setSelected] = React.useState(null);
    const [isRepoOnList, setIsRepoOnList] = React.useState(false);
    const reposCount = reposToMigrate.length;
    const repos = order?.availableProjectRepos || [];
    const repoOptions = repos.map((repo) => ({ label: repo, value: repo }));

    const handleAdd = () => {
        if (!selected) return;
        if (reposToMigrate.some((repo) => repo.adoName === selected)) {
            setIsRepoOnList(true);
            return;
        }
        if (reposCount < reposLimit) {
            handleAddRepo(selected);
            setSelected(null);
            setIsRepoOnList(false);
        }
    };

    return (
        <>
            <Box display="flex" alignItems="flex-start" gap={1}>
                <Box sx={{ width: '50%', maxWidth: '400px' }}>
                    <Select
                        data-testid="ado-repo-search"
                        label="ADO Repo Name"
                        value={repoOptions.find((option) => option.value === selected) || null}
                        options={repoOptions}
                        onChange={(_event, newValue) => setSelected(newValue.value)}
                        disabled={reposCount >= reposLimit || !repos.length || isLoading}
                        isLoading={isLoading}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                    <FormHelperText sx={{ fontSize: '12px', display: 'flex', alignItems: 'center', color: reposCount >= reposLimit ? '#d40020' : 'inherit' }}>
                        <InfoOutlinedIcon sx={{ fontSize: 16, marginRight: '4px', marginBottom: '2px', color: 'inherit' }} />
                        {`You can choose max. ${reposLimit} ADO Repos`}
                    </FormHelperText>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                    <Btn
                        size="small"
                        onClick={handleAdd}
                        variant="contained"
                        disabled={reposCount >= reposLimit || !selected}
                        sx={{ ...style.primaryMediumButton, width: '100px', marginTop: '3px' }}
                        data-testid="add-repo-btn"
                        className="btn-disabled"
                    >
                        Add Repo
                    </Btn>
                </Box>
            </Box>
            {isRepoOnList && (
                <Alert severity="error" color="error" sx={{ marginTop: 2 }}>
                    This repository has already been added to the list.
                </Alert>
            )}
            {reposCount >= reposLimit && (
                <Alert severity="error" color="error" sx={{ marginTop: 2 }}>
                    You’ve added {reposLimit} ADO Repos to the list. If you would like to add different ADO Repo, you need to remove one from the list.
                </Alert>
            )}
            <Alert severity="info" color="info" sx={{ marginTop: 2 }}>
                After migration ADO Repo will be in read mode only and developers should proceed in GitHub for further development.
            </Alert>
        </>
    );
};

ReposSearch.propTypes = {
    isLoading: PropTypes.bool,
    handleAddRepo: PropTypes.func,
    reposToMigrate: PropTypes.array,
};

ReposSearch.defaultProps = {
    isLoading: false,
    handleAddRepo: undefined,
    reposToMigrate: [],
};

export default React.memo(ReposSearch);
