import React from 'react';
import { toggleModal, orderPayload, validProvisionForm } from 'pages/mdt-onboarding/store';
import Btn from 'components/button';
import { Box, Badge, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { commonStyles as style } from 'assets/style';

const FilePatternWrapper = () => {
    const [, setModal] = useRecoilState(toggleModal);
    const [order] = useRecoilState(orderPayload);
    const [isValidForm] = useRecoilState(validProvisionForm);

    const getErrorBadge = () => {
        if (
            order?.sourceDirectory &&
            order?.environment &&
            (order?.sourceFileNamePatternList?.[0]?.fileName === null || isValidForm?.isSourceFileNamePatternValid)
        ) {
            if (isValidForm?.isSourceDirectoryValid) {
                return 'standard';
            }
            return 'dot';
        }
        return 'standard';
    };

    return (
        <Box display="flex">
            <Grid container justifyContent="flex-end">
                <Box sx={{ width: '100%', marginTop: '8px' }}>
                    <Badge sx={style.tagBadge} color="error" variant={getErrorBadge()}>
                        <Btn
                            data-testid="submit-btn"
                            size="small"
                            className="btn-disabled custom-btn"
                            fullWidth
                            variant="contained"
                            sx={style.serviceNewTagBtn}
                            // color="cmpPrimary"
                            disabled={!order?.sourceDirectory || isValidForm?.isSourceDirectoryValid || !order?.environment}
                            onClick={() => setModal({ isSourceFilenameModalOpen: true })}
                        >
                            Add Pattern
                        </Btn>
                    </Badge>
                </Box>
            </Grid>
        </Box>
    );
};

export default FilePatternWrapper;
