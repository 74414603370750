import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import axios, { endPoints } from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload } from 'pages/github-onboarding/store';
import defaultOrderValues from 'pages/github-onboarding/store/defaultValues';

const ApplicationService = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);

    const appServiceApi = useMutation((payload) => axios.post(`${endPoints.day2Operation.ProjectOnboarding.applicationService}`, payload));

    const setApplicationService = ({ value }) => {
        const { customProperties, availableProjectRepos, applicationOwnerDetails, ...rest } = defaultOrderValues;

        setOrder({
            ...rest,
            customProperties: {
                ...order.customProperties,
                applicationService: value,
            },
            applicationOwnerDetails: order.applicationOwnerDetails,
            availableProjectRepos: order.availableProjectRepos,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (applicationService) => options.find((option) => applicationService === option.value);

    const handleOnSelectChange = (selected) => setApplicationService(selected);

    React.useEffect(() => {
        if (appServiceApi?.isSuccess) {
            setOptions(appServiceApi?.data?.data?.applications?.map((app) => ({ label: app?.serviceName, value: app?.serviceName, data: app })));
        }
    }, [appServiceApi?.isSuccess]);

    React.useEffect(() => {
        if (!options?.length) return;

        const { applicationService } = order?.customProperties || {};

        if (options.length === 1 && !applicationService) {
            setOrder({ ...order, applicationService: options[0].value });
            return;
        }

        if (applicationService) {
            const existingService = isExistsInOptions(applicationService);
            setOrder({ ...order, applicationService: existingService?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        if (order?.customProperties?.businessApplicationId) {
            appServiceApi.mutate({
                businessApplicationId: order?.customProperties?.businessApplicationId,
                environment: 'prod',
            });
        }
    }, [order?.customProperties?.businessApplicationId]);

    React.useEffect(() => {
        setIsTouched(!order?.customProperties?.applicationService && order?.isTouched);
    }, [order?.customProperties?.applicationService, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                data-testid="application-service"
                key="ApplicationService"
                label="Application Service Name (choose from the dropdown) *"
                value={options.find((option) => option.value === order?.customProperties?.applicationService) || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={!order.customProperties.businessApplication || appServiceApi.isLoading}
                isLoading={appServiceApi.isLoading}
                isRequired={isTouched}
                isOptionEqualToValue={(option, value) => option.value === value.value}
            />
        </Box>
    );
};

export default React.memo(ApplicationService);
