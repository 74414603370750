import { atom } from 'recoil';

export const toggleSamRequest = atom({
    key: 'oracle_toggleSamRequest',
    default: false,
});

export const toggleQuickConfig = atom({
    key: 'oracle_toggleQuickConfig',
    default: false,
});

export const defaultVmDataDisk = atom({
    key: 'oracle_defaultVmDataDisk',
    default: undefined,
});
export const isDataDiskLoaded = atom({
    key: 'oracle_isDataDiskLoaded',
    default: false,
});
export const defaultOsDiskType = atom({
    key: 'oracle_defaultOsDiskType',
    default: undefined,
});
export const quickConfigDetails = atom({
    key: 'oracle_quickConfigDetails',
    default: undefined,
});
export const toggleSspModels = atom({
    key: 'oracle_toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isDiskOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
        isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
        isNvmeOpen: false,
    },
});

export const isEnvDetailsLoading = atom({
    key: 'oracle_isEnvDetailsLoading',
    default: false,
});

export const isConfigDiskFulfilled = atom({
    key: 'oracle_isConfigDiskFulfilled',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'oracle_isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'oracle_orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'oracle_sspOrder',
    default: {
        appProfile: null,
        entityDetails: null,
        environment: null,
        appTier: null,
        appTierCode: null,
        region: null,
        regionDisplayName: null,
        osType: null,
        osVersion: null,
        osDiskType: null,
        vmType: null,
        vmTypeDetails: null,
        quantity: 1,
        maxDataDiskCount: null,
        dataDisksCount: null,
        dataDisks: [],
        defaultDataDisks: [],
        adGroup: null,
        hoursOfOperation: null,
        hoursOfOperationDetail: null, // { active: 1, id: '763cf923-85d9-43f7-b338-7be17fded399', name: 'Always ON', type: 'hours_of_operation' },
        timeZone: 'UTC',
        timeZoneDetail: null, // { id: 'd3bb59b6-72b3-49c0-a1c2-c0494ea28802', name: 'UTC', type: 'time_zones', value: 'Default-UTC' }
        domain: null,
        domainDetails: null,
        hCode: null,
        hCodeDetails: null,
        billingEntity: null,
        billingEntityDetails: null,
        adousDefault: null,
        adous: null,
        adousDetails: null,
        sector: null,
        sectorDetails: null,
        tags: [],
        catalogSize: null,
        catalogSizeDetails: null,
        estimatedCost: {
            details: [],
            qty: null,
            totalOneTimeCost: {
                frequency: 'one time cost',
                estCost: null,
                currency: 'USD',
            },
            totalRecurringCost: {
                frequency: 'per item per month',
                estCost: null,
                currency: 'USD',
            },
            notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
        },
        catalogCode: null,
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        isTouched: false,
        haDeployment: null,
        availabilityZone: null,
        availabilityZoneOptions: [],
        retiringServerName: null,
        retiringServerSysId: null,
        oracleVersion: '19C',
        dbCharacterSet: null,
        dbNationalCharSet: null,
        databaseName: '',
        expDBSize: '',
        isManualDBName: false,
        nvmeSupport: 'no',
        migratingDBRadio: 'no',
        quickConfignvmeSupport: null,
    },
});

export const environmentDetails = atom({
    key: 'oracle_environmentDetails',
    default: {
        Subscription: '---',
        'DR Priority': '---',
        Resource_Group: '---',
        Metallic_Selection: '---',
        Backup_Tier: '---',
        Virtual_Network: '---',
        // Network_Security_Group: '-',
        Subnet_Name: '---',
        // Application_Security_Group: '-',
        // Recovery_Vault_Name: '---',
        Approval_Manager: '---',
        Project: '---',
        Availability_Set: '---',
        // 'Database Character Set': '-',
        // 'DB National Character Set': '-',
    },
});
export const toggleCurrentServer = atom({
    key: 'oracle_toggleCurrentServer',
    default: false,
});

export const CurrentServerDetails = atom({
    key: 'oracle_CurrentServerDetails',
    default: null,
});

export const costEstimateState = atom({
    key: 'oracle-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'oracle-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const isExpectedDbSizeValid = atom({
    key: 'oracle-isExpectedDbSizeValid',
    default: false,
});
export const isExpectedDbSizeValidApiLoading = atom({
    key: 'oracle-isExpectedDbSizeValidLoading',
    default: false,
});
export const isDBNameValid = atom({
    key: 'oracle-isDBNameValid',
    default: false,
});

export const isOsAPiLoading = atom({
    key: 'oracle-isOsAPiLoading',
    default: false,
});
