import { PEPVIGIL } from 'pages/dlops-dashboard/utils/constant';
import { Day2CatalogDefinitionId, ServiceAccountDefinitionId, nsgCatalogDefinitionId, ProjectOnboardCatalogDefinitionId } from './catalogDefinitionId';

// eslint-disable-next-line
export const menuItems = [
    {
        id: 'riServices',
        name: 'Solutions',
        allowedScopes: [],
        link: '/reference-implementation-services',
    },
    {
        id: 'services',
        name: 'Services',
        allowedScopes: [],
        link: '/services',
    },
    {
        id: 'orders',
        name: 'Orders',
        allowedScopes: [],
        link: '/orders',
    },
    {
        id: 'approvals',
        name: 'Approvals',
        allowedScopes: [],
        link: '/approvals',
    },
    {
        id: 'More',
        name: 'More',
        allowedScopes: [],
        children: [
            {
                id: 'appProfiles',
                name: 'App Profiles',
                allowedScopes: [],
                link: '/app-profile',
            },
            {
                id: 'Dashboard',
                name: 'Dashboard',
                allowedScopes: ['orderMgmt.write'],
                link: '/dashboard',
            },
            {
                id: 'azure-sku',
                name: 'Azure Resources',
                allowedScopes: ['orderMgmt.write'],
                link: '/sku',
            },
            {
                id: 'azure-nsg',
                name: 'NSG Automation',
                allowedScopes: ['orderMgmt.write', 'nsg.write'],
                link: `/nsg-common-rule/${nsgCatalogDefinitionId[0]}`,
            },
            {
                id: 'inventoryReport',
                name: 'Inventory Report',
                allowedScopes: [],
                link: `/inventory-list`,
            },
            {
                id: 'securityTool',
                name: 'Application Security Tool',
                allowedScopes: [],
                link: `/security-tool`,
            },
            {
                id: 'projectOnboarding',
                name: 'Project Onboarding',
                allowedScopes: [],
                link: `/project-onboarding/${ProjectOnboardCatalogDefinitionId[0]}`,
            },
            {
                id: 'githubOnboarding',
                name: 'GitHub Enterprise Onboarding',
                allowedScopes: [],
                link: `/github-onboarding`,
            },
            {
                id: 'service-account-creation',
                name: 'Service Account(AD/LDAP)',
                allowedScopes: [],
                link: `/service-account-creation/${ServiceAccountDefinitionId[0]}`,
            },
            // {
            //     id: 'service-account-ci',
            //     name: 'Service Account(SQL)',
            //     allowedScopes: [],
            //     link: `/service-account-ci/${ServiceAccountDefinitionId[0]}`,
            // },
            {
                id: 'onboard-application-service',
                name: 'Onboard Application Service',
                allowedScopes: [],
                link: `/onboard-application-service`,
            },
            {
                id: 'mdt-onboarding',
                name: 'MDT Onboarding',
                allowedScopes: [],
                link: `/mdt-onboarding`,
            },
            // {
            //     id: 'day-2-operation',
            //     name: 'Day2 Operation',
            //     allowedScopes: [],
            //     children: [
            //         {
            //             id: 'iaas-day-2-operation',
            //             name: 'IaaS',
            //             allowedScopes: [],
            //             link: `/day2-operation/AZWINVM2016`,
            //         },
            //         {
            //             id: 'paas-day-2-operation',
            //             name: 'PaaS',
            //             allowedScopes: [],
            //             link: `/day2-operation-paas/AZWINVM2016`,
            //         },
            //     ],
            // },
            {
                id: 'access-management',
                name: 'Access Management',
                allowedScopes: [],
                children: [
                    {
                        id: 'adding-secondary-id',
                        name: 'LDAP & Active Directory Group Membership',
                        allowedScopes: [],
                        link: `/access-management-myidm/${Day2CatalogDefinitionId[0]}`,
                    },
                    {
                        id: 'onboard-developers',
                        name: 'Onboarding Developers',
                        allowedScopes: [],
                        link: `/access-management-onboard-developers`,
                    },
                    {
                        id: 'ssoOkta',
                        name: 'SSO Integration',
                        allowedScopes: [],
                        link: `/sso-okta`,
                    },
                ],
            },
            {
                id: 'pepvigil',
                name: `${PEPVIGIL} Dashboard`,
                allowedScopes: [],
                children: [
                    // {
                    //     id: 'pepvigil-Overview',
                    //     name: `${PEPVIGIL} Overview Dashboard`,
                    //     allowedScopes: [],
                    //     link: `/pepvigil-overview-dashboard`,
                    // },
                    {
                        id: 'pepvigil-Projects',
                        name: `${PEPVIGIL} Projects`,
                        allowedScopes: [],
                        link: `/pepvigil-projects`,
                    },
                ],
            },
        ],
    },
    {
        id: 'mypersona',
        name: 'My Persona',
        allowedScopes: [],
        children: [
            {
                id: 'signout',
                name: 'Sign Out',
                allowedScopes: [],
            },
        ],
    },
];
