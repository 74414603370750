import React, { Suspense, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAxios } from 'hooks';
import { endPoints } from 'api/endpoints';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams, useHistory } from 'react-router-dom';
import { catalogs } from 'store';
import {
    costEstimateState,
    isLoadingCostApiState,
    environmentDetails,
    isMandatoryTagsFullfilled,
    orderId,
    quickConfigDetails,
    sspSubmitOrder,
    toggleSspModels,
    sourceNameErrors,
} from 'pages/provisioning/nsg-automation-rules/store';
import ProvisioningStyles from 'pages/provisioning/nsg-automation-rules/style';
import StickyHeader from 'pages/provisioning/nsg-automation-rules/components/sticky-header';
import { useMutation } from 'react-query';
import { formOrderObjFromOrderDetails, resetEnvironmentDetails, resetOrder, resetToggleSspModal, resetCostEstimateDetails } from './utils';
import CartBtn from './components/SubmitBtn';
import { isReadyToSubmit } from './utils/formValidation';

const SspForm = React.lazy(() => import('pages/provisioning/nsg-automation-rules/SspForm'));
const AddedToCartConfirmModal = React.lazy(() => import('pages/provisioning/nsg-automation-rules/components/modal/AddedToCartConfirmModal'));
const ShowCostDetails = React.lazy(() => import('pages/provisioning/nsg-automation-rules/components/ShowCostDetails'));
const PreviewDetails = React.lazy(() => import('pages/provisioning/nsg-automation-rules/components/Preview'));

const NsgAutomationRulesProvision = () => {
    const history = useHistory();
    const [isLoading, setLoading] = React.useState(true);
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [catalog, setCatalog] = useState(undefined);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, setCostEstimate] = useRecoilState(costEstimateState);
    const [, setEnvDetails] = useRecoilState(environmentDetails);
    const [, resetOrderId] = useRecoilState(orderId);
    const [, resetEnvDetails] = useRecoilState(environmentDetails);
    const [, resetIsMandatoryTagsFullfilled] = useRecoilState(isMandatoryTagsFullfilled);
    const [, resetToggleSspModels] = useRecoilState(toggleSspModels);
    const [, resetQuickConfig] = useRecoilState(quickConfigDetails);
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const axios = useAxios();
    const [, setIsLoadingCostApi] = useRecoilState(isLoadingCostApiState);
    const errors = useRecoilValue(sourceNameErrors);

    const errorValid = errors?.some((error) => error !== '');
    const previewApi = useMutation((payload) => axios.post(endPoints.ssp.nsgAutomationRules.preview, payload));
    const costCalcApi = useMutation((payload) => axios.post(`${endPoints.ssp.nsgAutomationRules.costCalculation}`, payload));

    const getPreviewOrder = async (orderObj) => {
        const payload = {
            environment: orderObj?.environment || null,
            region: orderObj?.region || null,
            sector: orderObj?.sector || null,
            dataClassification: orderObj?.dataClassification || null,
            personaDetails: orderObj?.instanceDetails || [],
        };
        const previewData = [];
        const response = await previewApi?.mutateAsync(payload);
        const nsgResourceDetails = response?.data?.personaDetails;
        if (nsgResourceDetails?.length > 0) {
            nsgResourceDetails.forEach((resourceDetail) => {
                const properties = {};
                Object.entries(resourceDetail).forEach(([key, value]) => {
                    properties[key] = value;
                });
                const type = resourceDetail?.personaType === 'Teradata' ? 'Teradata Persona Type' : '';
                if (!previewData[type]) {
                    previewData[type] = [];
                }
                previewData[type].push({ properties });
            });
        }
        setOrder({ ...orderObj, showPreview: 'preview-order', previewData });
    };

    async function getCosts() {
        const { data } = await axios.post(`${endPoints.ssp.nsgAutomationRules.costCalculation}`, {
            catalogServiceCode: catalogCode,
            quantity: 1,
        });
        return data;
    }
    async function checkCatalog() {
        const data = cmpCatalogs?.find((item) => item?.catalogDefinitionId === catalogCode);
        setCatalog(data);
        const costs = await getCosts();
        if (costs?.notes) {
            setCostEstimate({ ...resetCostEstimateDetails, notes: costs?.notes });
        }
        if (cartItemId && operation && catalogServiceId) {
            const { data: orderDetails } = await axios.get(`${endPoints.order.orderDetails}/${cartItemId}`);
            const orderObj = await formOrderObjFromOrderDetails(catalogServiceId, orderDetails);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                initCost: costs,
            }));
            setEnvDetails({
                Approval_Manager: orderObj?.entityDetails?.owner?.name,
                Project: orderObj?.entityDetails?.projectName,
            });
            if (operation === 'preview') {
                setOrder({ ...orderObj, showPreview: 'preview-order' });
                getPreviewOrder(orderObj);
            }
        } else {
            setOrder((previous) => ({
                ...previous,
                sku: data?.images?.sku,
                catalogCode,
                catalogType: data?.displayName,
                cloudProvider: data?.cloudProvider,
                cloudService: data?.cloudService,
                validationStatus: 'clearValidation',
                isTouched: false,
                emptyCost: costs,
                initCost: costs,
            }));
        }
        setLoading(false);
    }
    const reset = () => {
        setOrder({ ...order, ...resetOrder });
        resetOrderId('');
        resetEnvDetails(resetEnvironmentDetails);
        resetIsMandatoryTagsFullfilled(false);
        resetToggleSspModels(resetToggleSspModal);
        resetQuickConfig(undefined);
        setCostEstimate({ ...resetCostEstimateDetails });
    };

    const isShowBackBtn = () => {
        if (order?.showPreview === 'pre-preview-order' || order?.showPreview === 'preview-order') {
            return true;
        }
        return false;
    };
    const handleBackBtn = () => {
        if (order?.showPreview === 'pre-preview-order') {
            setOrder({ ...order, showPreview: null });
        }
        if (order?.showPreview === 'preview-order') {
            if (history?.location?.state?.from?.orderPage) {
                history.push(history?.location?.state?.from?.orderPage);
            } else {
                history.push(`/orders?seld_order_id=${cartItemId}&seld_catalog_service_code=${catalogCode}`);
            }
        }
    };
    const getHeaderButton = () => {
        if (order?.showPreview === 'pre-preview-order') {
            return <CartBtn isBgApiLoading={false} />;
        }
        return null;
    };
    React.useEffect(() => {
        if (cmpCatalogs?.length && catalogCode) {
            setLoading(true);
            reset();
            checkCatalog();
        }
    }, [cmpCatalogs, catalogCode]);

    React.useEffect(() => {
        setCostEstimate({ ...resetCostEstimateDetails, details: order?.emptyCost?.costs });
        if (isReadyToSubmit(order)?.isValid && !errorValid) {
            costCalcApi?.mutate({
                catalogServiceCode: catalogCode,
                quantity: order?.instanceDetails?.length || 1,
            });
        }
        // eslint-disable-next-line
    }, [
        order?.appProfile,
        order?.sector,
        order?.appOwner,
        order?.isaNumber,
        order?.justification,
        order?.instanceDetails,
        order?.region,
        order?.environment,
        order?.dataClassification,
        errorValid,
    ]);

    React.useEffect(() => {
        setIsLoadingCostApi({ isLoading: costCalcApi?.isLoading });
    }, [costCalcApi?.isLoading, costCalcApi?.isSuccess]);
    React.useEffect(() => {
        if (costCalcApi?.data?.data && isReadyToSubmit(order)?.isValid && !errorValid) {
            if (costCalcApi?.data?.data) setCostEstimate(costCalcApi?.data?.data);
            setOrder({ ...order, estimatedCost: costCalcApi?.data?.data });
        }
        // eslint-disable-next-line
    }, [costCalcApi?.isSuccess]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => reset();
        // eslint-disable-next-line
    }, []);

    // React.useEffect(() => {
    //     if (operation === 'preview' || order?.showPreview === 'preview-order') {
    //         getPreviewOrder();
    //     }
    // }, [operation, order?.showPreview]);

    return (
        <>
            <Helmet>
                <title>NSG Automation Rules - CMP</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                }
            >
                {isLoading && (
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                )}
                {!isLoading && (
                    <>
                        {catalog && (
                            <StickyHeader
                                title={
                                    (operation === 'edit' && order?.showPreview === 'pre-preview-order') || order?.showPreview === 'preview-order'
                                        ? `Order ID - ${cartItemId}`
                                        : catalog?.displayName
                                }
                                iconPath={catalog?.iconPath}
                                back={isShowBackBtn()}
                                handleBackBtn={() => handleBackBtn()}
                                showCartBtn={order?.showPreview}
                                cartBtn={getHeaderButton()}
                            />
                        )}
                        <Box sx={{ display: !order?.showPreview ? 'block' : 'none' }} data-testid="index-form">
                            <Box sx={ProvisioningStyles.provisioningBox}>
                                <Box sx={ProvisioningStyles.sspform_wrapper}>
                                    <SspForm />
                                </Box>
                            </Box>
                        </Box>
                        {(order?.showPreview === 'pre-preview-order' || order?.showPreview === 'preview-order') && <PreviewDetails />}
                        <ShowCostDetails />
                        <AddedToCartConfirmModal />
                    </>
                )}
            </Suspense>
        </>
    );
};

export default NsgAutomationRulesProvision;
