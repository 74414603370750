import React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import GithubOnboardingStyles from 'pages/github-onboarding/style';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/github-onboarding/store';

const ProjectDetails = () => {
    const [order] = useRecoilState(OrderPayload);
    const LabelValue = (label, value) => (
        <Grid item xs={6} sm={6}>
            <Box sx={GithubOnboardingStyles?.preview?.projectDetails?.subWrapper}>
                <Box sx={GithubOnboardingStyles?.preview?.projectDetails?.label}>{label}:</Box>
                <Box sx={GithubOnboardingStyles?.preview?.projectDetails?.value}>{value || '-'}</Box>
            </Box>
        </Grid>
    );

    return (
        <Box sx={GithubOnboardingStyles?.preview?.wrapper}>
            <Box sx={GithubOnboardingStyles?.preview?.heading}>Project Details</Box>
            <Divider variant="horizontal" sx={GithubOnboardingStyles?.preview?.divder} />
            <Grid container sx={GithubOnboardingStyles?.preview?.projectDetails?.container} spacing={2}>
                {LabelValue('Sector', order?.customProperties?.sector)}
                {LabelValue('Do you have ADO Project?', order?.existingProject ? 'Yes' : 'No')}
                {LabelValue('ADO Project Name', order?.customProperties?.adoProjectName)}
                {LabelValue('Do you want to create a new repository or migrate repositories?', 'Migrate')}
            </Grid>
        </Box>
    );
};

export default ProjectDetails;
