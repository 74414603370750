/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, IconButton, InputBase, Typography, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useRecoilState } from 'recoil';
import { appProfileTypeState, toggleCreateProfile } from 'pages/app-profiles/store';
import { useTranslation } from 'react-i18next';
import profileStyle from 'pages/app-profiles/profile/style';
import { scope } from 'utils/scope';
import { getUserScopes } from 'utils';
import { commonStyles as style } from 'assets/style';
import AppFilter from './components/Form/Filter';

const AppProfileHeader = () => {
    const { t } = useTranslation();
    const [appProfileType, setAppProfileType] = useRecoilState(appProfileTypeState);
    const [profileModal, setProfileModal] = useRecoilState(toggleCreateProfile);
    const [searchText, setSearchText] = React.useState('');
    React.useEffect(() => {
        if (appProfileType?.key === '') setSearchText('');
    }, [appProfileType?.key]);
    return (
        <Box sx={profileStyle?.header?.wrapper} data-testid="app-profile-header">
            <Box sx={profileStyle?.header?.container}>
                <Typography sx={profileStyle?.header?.headerText}>{t([`appProfile.title.appProfiles`])}</Typography>
                <Box sx={profileStyle?.header?.subWrapper}>
                    <Box sx={profileStyle?.header?.searchWrapper}>
                        <InputBase
                            sx={profileStyle?.header?.searchInput}
                            size="small"
                            fullWidth
                            placeholder={t([`appProfile.placeholder.searchAppName`])}
                            onChange={(e) => setSearchText(e.target.value)}
                            value={searchText}
                            data-testid="search-input"
                        />
                        {searchText && (
                            <IconButton
                                aria-label="search"
                                sx={profileStyle?.header?.clearIcon}
                                onClick={() => {
                                    setSearchText('');
                                    setAppProfileType({ ...appProfileType, key: '', pageNumber: 1 });
                                }}
                                data-testid="clear-btn"
                            >
                                <ClearIcon />
                            </IconButton>
                        )}
                        <IconButton
                            aria-label="Search App Name / Business CI"
                            sx={{ color: searchText ? '#fff' : '#A5A5A5' }}
                            onClick={() => setAppProfileType({ ...appProfileType, key: searchText, pageNumber: 1 })}
                            data-testid="search-btn"
                        >
                            <SearchIcon sx={{ color: (theme) => theme.palette.blue.main }} />
                        </IconButton>
                    </Box>
                    <Box>
                        <AppFilter />
                    </Box>
                    {getUserScopes()?.includes(scope[2]) && (
                        <Box sx={profileStyle?.header?.createBtnContainer}>
                            <Button
                                // sx={profileStyle?.header?.createBtn}
                                sx={{ ...style.primaryMediumButton, height: '38px' }}
                                variant="contained"
                                disableElevation
                                disableRipple
                                onClick={() => setProfileModal({ ...profileModal, isOpen: true, type: 'create' })}
                                data-testid="create-btn"
                            >
                                {t([`appProfile.btnText.createNew`])}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default AppProfileHeader;
