import React from 'react';
import { Box, Card, Divider, Typography, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import Btn from 'components/button';
import { SecondaryApprover, showPreviewOrder } from 'pages/reference-implementation/provisioning/RI002O/store';
import { useRecoilState } from 'recoil';
import Chip from 'components/Chip';
import Alert from 'components/alert';
import PreviewStyles from 'pages/reference-implementation/components/preview-details/style';
import { FAILED, SUCCESS } from 'utils/ValidationStatus';
import { exportToJson, getAccessToken } from 'utils';
import { riCatalogs } from 'store';
import DestroyAlert from 'components/order-summary-details/destroy-alert';
import { useParams } from 'react-router-dom';
import { RICatalogCode005 } from 'pages/reference-implementation/utils/RICatalogCode';
import ResourceWrapper from 'pages/reference-implementation/provisioning/RI002O/ResourceWrapper';
import MultipleResourceWrapper from 'pages/reference-implementation/provisioning/RI002O/MutipleResourceWrapper';
import html2canvas from 'html2canvas';
import { addLabelClass, addValueClass, generatePDF, removeLabelClass, removeValueClass } from 'pages/reference-implementation/utils';
import { isSecondaryGrpOwner } from 'pages/reference-implementation/provisioning/RI002O/utils/checkValidFields';
import { commonStyles as style } from 'assets/style';

const PreviewOrderDetails = (props) => {
    const { catalogCode } = useParams();
    const { order, details, onDownloadConfig, isDownloading } = props;

    const [secondaryApproverValue] = useRecoilState(SecondaryApprover);
    const gpid = getAccessToken('claims')?.gpid;
    const [showPreview, setShowPreview] = useRecoilState(showPreviewOrder);
    const [cmpRiCatalogs] = useRecoilState(riCatalogs);
    const [additionalInfo, setAdditionalInfo] = React.useState({});
    const checkIsMultipleResources = () => [RICatalogCode005]?.includes(catalogCode);
    const [loading, setLoading] = React.useState(false);
    const [defaultExpanded, setDefaultExpanded] = React.useState(true);
    const container = React.useRef(null);
    const getCatalogType = () => {
        const data = cmpRiCatalogs?.find((item) => item?.['reference-implementation-id'] === order?.catalogCode);
        return data?.name;
    };
    React.useEffect(() => {
        setAdditionalInfo({
            ...(order?.orderId && {
                'Order ID': order?.orderId,
            }),
            'Catalog Type': getCatalogType(),
            ...((details?.Location || order?.region) && {
                'Azure Region': details?.Location || order?.region,
            }),
            ...((details?.Environment || order?.environment) && {
                Environment: details?.Environment || order?.environment,
            }),
            ...(details?.Subscription && {
                Subscription: details?.Subscription,
            }),
            ...(order?.businessApp && {
                'Business Application': order?.businessApp,
            }),
            ...(order?.appProfile && {
                'Application Service': order?.appProfile,
            }),
            ...(order?.appService && {
                'Application Service': order?.appService,
            }),
            ...(order?.appOwnerDetails?.name && {
                'App Owner': order?.appOwnerDetails?.name,
            }),
            ...(isSecondaryGrpOwner({ order }) &&
                secondaryApproverValue?.value?.groupOwnerDetails?.name && {
                    'AD Group / Service Account Owner': secondaryApproverValue?.value?.groupOwnerDetails?.name,
                }),
            ...(order?.certificateOwnerOneDetails?.name && {
                'Certificate Owner1': order?.certificateOwnerOneDetails?.name,
            }),
            ...(order?.certificateOwnerTwoDetails?.name && {
                'Certificate Owner2': order?.certificateOwnerTwoDetails?.name,
            }),
        });
    }, [secondaryApproverValue]);

    const handleGeneratePdf = () => {
        setDefaultExpanded(false);
        setLoading(true);
        const label = document.getElementsByClassName('label');
        const value = document.getElementsByClassName('value');

        const divToPrint = document.getElementById('divToPrint');
        setTimeout(() => {
            try {
                addLabelClass(label);
                addValueClass(value);
                html2canvas(divToPrint, {
                    useCORS: true,
                    allowTaint: true,
                    scrollY: -window.scrollY,
                }).then((canvas) => {
                    generatePDF(canvas, order);
                    setLoading(false);
                    setDefaultExpanded(true);
                    removeLabelClass(label);
                    removeValueClass(value);
                });
            } catch (err) {
                setLoading(false);
                setDefaultExpanded(true);
                removeLabelClass(label);
                removeValueClass(value);
            }
        }, 1000);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }} px={7} py={3}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={PreviewStyles?.previewDetailsTextWrap}>
                    <Typography component="span" sx={PreviewStyles?.previewDetailsText}>
                        Provision Details (Preview)
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    {(showPreview === 'pre-preview-order' ||
                        (gpid === order?.createdBy &&
                            order?.currentOrderStatus === 'Validation' &&
                            [SUCCESS, FAILED]?.includes(order?.validationDetails?.status))) && (
                        <Btn
                            size="medium"
                            onClick={() => setShowPreview(null)}
                            variant="outlined"
                            // color="cmpPrimary2"
                            data-testid="edit-Btn"
                            // sx={PreviewStyles?.editBtn}
                            sx={{ ...style.secondaryMediumButton, minWidth: '64px', width: 'auto' }}
                        >
                            Edit Order
                        </Btn>
                    )}
                    {showPreview === 'pre-preview-order' && (
                        <Btn
                            size="medium"
                            onClick={() => {
                                exportToJson(
                                    order?.environmentDetails?.['tech-input-json'] || {},
                                    `${order?.applicationName?.replaceAll(' ', '')}_${order?.environment?.replaceAll(' ', '')}_tech_input_json`
                                );
                            }}
                            variant="outlined"
                            // color="cmpPrimary2"
                            data-testid="download-Btn"
                            // sx={PreviewStyles?.downloadBtn}
                            sx={{ ...style.secondaryMediumButton, minWidth: '64px', width: '252px' }}
                        >
                            Download Technical Input
                        </Btn>
                    )}
                    {showPreview === 'pre-preview-order' && (
                        <Btn
                            size="medium"
                            onClick={() => {
                                // printDocument();
                                handleGeneratePdf();
                            }}
                            variant="outlined"
                            disabled={loading}
                            // color="cmpPrimary2"
                            data-testid="pdf-Btn"
                            // sx={PreviewStyles?.downloadPdfBtn}
                            sx={{ ...style.secondaryMediumButton, width: 'auto', minWidth: '168px' }}
                            startIcon={<img src="/static/images/pdf.png" alt="download" width={30} height={30} alignItem="left" />}
                        >
                            {loading ? (
                                <>
                                    Exporting&nbsp;&nbsp;&nbsp;
                                    <CircularProgress color="inherit" size={16} />
                                </>
                            ) : (
                                'Export to PDF'
                            )}
                        </Btn>
                    )}
                    {showPreview !== 'pre-preview-order' && [SUCCESS]?.includes(order?.validationDetails?.status) && (
                        <Btn
                            size="medium"
                            disabled={isDownloading}
                            onClick={() => onDownloadConfig()}
                            variant="contained"
                            // color="cmpPrimary2"
                            data-testid="terraform-Btn"
                            // sx={PreviewStyles?.downloadBtn}
                            sx={{ ...style.primaryMediumButton, minWidth: '64px', width: '252px' }}
                        >
                            {isDownloading ? <CircularProgress color="inherit" size={16} /> : 'View Terraform Plan Output'}
                        </Btn>
                    )}
                </Box>
            </Box>
            {showPreview !== 'pre-preview-order' && [FAILED]?.includes(order?.validationDetails?.status) && (
                <Box sx={{ width: '100%', pt: '10px' }}>
                    <Alert
                        message={
                            <Box>
                                <Box sx={{ color: '#1E1E1E', size: '16px', fontWeight: 'medium', fontFamily: 'Stolzl' }}>Error Report</Box>
                                <Box sx={{ maxHeight: '200px', overflowY: 'scroll', width: '100%' }} className="customized-scrollbar-error">
                                    <Box>{order?.validationDetails?.statusDetail}</Box>
                                </Box>
                            </Box>
                        }
                        borderLeftColor="#DB1E36"
                        backgroundColor="#FCE8EA"
                        color="#000000"
                        boxShadow="none"
                    />
                </Box>
            )}
            {order?.validationDetails?.destroyingResources && (
                <Box sx={{ width: '100%', pt: '10px' }}>
                    <DestroyAlert />
                </Box>
            )}
            <Box id="divToPrint" className="divToPrint" ref={container} height="100%" width="100%" sx={PreviewStyles?.wrapper} gap={2}>
                <Box flex={1}>
                    <Box>
                        <Card sx={PreviewStyles?.card}>
                            <Typography component="span" sx={PreviewStyles?.generalInfo}>
                                {showPreview !== 'pre-preview-order' ? 'Validation Summary' : 'General Info'}
                            </Typography>
                            <Box sx={PreviewStyles?.generalWrapper}>
                                {showPreview !== 'pre-preview-order' && (
                                    <>
                                        <>
                                            <Divider variant="horizontal" sx={PreviewStyles?.devider} />
                                            <Typography component="span" sx={PreviewStyles?.generalKey}>
                                                Status:
                                            </Typography>
                                            <Typography component="span" sx={PreviewStyles?.generalValue}>
                                                {![FAILED, SUCCESS]?.includes(order?.validationDetails?.status) ? (
                                                    <Box
                                                        className="blink-bg"
                                                        component="div"
                                                        sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', height: '30px', width: '70px' }}
                                                    >
                                                        <Box sx={{ textTransform: 'capitalize' }}>{order?.validationDetails?.status}</Box>
                                                    </Box>
                                                ) : (
                                                    <Chip status={order?.validationDetails?.status || null}>
                                                        <Box sx={{ textTransform: 'capitalize' }}>{order?.validationDetails?.status}</Box>
                                                    </Chip>
                                                )}
                                            </Typography>
                                        </>

                                        {![FAILED]?.includes(order?.validationDetails?.status) && (
                                            <>
                                                <Divider variant="horizontal" sx={PreviewStyles?.devider} />
                                                <Typography component="span" sx={PreviewStyles?.generalKey}>
                                                    Estimated Time:
                                                </Typography>
                                                <Typography component="span" sx={PreviewStyles?.generalValue}>
                                                    5 Minutes
                                                </Typography>
                                            </>
                                        )}
                                    </>
                                )}
                                {additionalInfo &&
                                    Object.keys(additionalInfo)?.length &&
                                    Object.keys(additionalInfo).map((key, idx) => (
                                        <React.Fragment key={idx}>
                                            <Divider variant="horizontal" sx={PreviewStyles?.devider} />
                                            <Typography component="span" sx={PreviewStyles?.generalKey}>
                                                {key}
                                            </Typography>
                                            <Typography component="span" sx={PreviewStyles?.generalValue}>
                                                {additionalInfo?.[key]}
                                            </Typography>
                                        </React.Fragment>
                                    ))}
                            </Box>
                        </Card>
                    </Box>
                </Box>
                <Box flex={3} sx={PreviewStyles?.resourceWrapper}>
                    {!checkIsMultipleResources() && <ResourceWrapper resources={details?.resources} />}
                    {checkIsMultipleResources() && <MultipleResourceWrapper resources={details?.resources} defaultExpanded={!defaultExpanded} />}
                </Box>
            </Box>
        </Box>
    );
};

PreviewOrderDetails.propTypes = {
    order: PropTypes.any,
    details: PropTypes.any,
    isDownloading: PropTypes.bool,
    onDownloadConfig: PropTypes.func,
};
PreviewOrderDetails.defaultProps = {
    order: {},
    details: {},
    isDownloading: false,
    onDownloadConfig: () => null,
};
export default PreviewOrderDetails;
