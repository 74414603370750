const commonStyles = {
    pointerCursor: { cursor: 'pointer' },
    fullheight: { height: 100 },
    circularProgressColor: { color: 'inherit' },
    primaryButton: {
        font: (theme) => `${theme.typography.fontSize.$font_body_caps}!important`,
        width: '100%',
        height: '40px',
        textTransform: 'none',
        padding: '8px 24px',
        minWidth: '250px',
        borderRadius: (theme) => theme.palette.border.$border_radius,
        backgroundColor: (theme) => theme.palette.background.$color_background_button_primary,
        '&:hover': {
            backgroundColor: (theme) => theme.palette.background.$color_background_button_primary_hover,
        },
        '&:active': {
            backgroundColor: (theme) => theme.palette.button.$color_button_primary_active,
        },
    },
    primaryMediumButton: {
        font: (theme) => `${theme.typography.fontSize.$font_small_bold}!important`,
        width: '100%',
        height: '32px',
        color: '#fff',
        padding: '4px 16px',
        textTransform: 'none',
        borderRadius: (theme) => theme.palette.border.$border_radius,
        backgroundColor: (theme) => theme.palette.background.$color_background_button_primary,
        '&.MuiLoadingButton-root:hover': {
            backgroundColor: (theme) => theme.palette.button.$color_button_primary_hover,
        },
        '&:active': {
            backgroundColor: (theme) => theme.palette.button.$color_button_primary_active,
        },
    },

    primaryMediumDangerButton: {
        font: (theme) => `${theme.typography.fontSize.$font_small_bold}!important`,
        width: 'auto',
        height: '32px',
        padding: '4px 16px',
        color: '#fff',
        textTransform: 'none',
        borderRadius: (theme) => theme.palette.border.$border_radius,
        backgroundColor: (theme) => theme.palette.background.$color_background_button_primary_danger,
        '&.MuiLoadingButton-root:hover': {
            backgroundColor: (theme) => theme.palette.background.$color_background_button_primary_danger_hover,
        },
        '&:active': {
            backgroundColor: (theme) => theme.palette.background.$$color_background_button_primary_danger_active,
        },
    },

    secondaryMediumDangerButton: {
        font: (theme) => `${theme.typography.fontSize.$font_small_bold}!important`,
        width: 'auto',
        height: '32px',
        textTransform: 'none',
        padding: '3px 15px',
        borderRadius: (theme) => theme.palette.border.$border_radius,
        color: (theme) => theme.palette.background.$color_background_button_primary_danger,
        border: (theme) => `1px solid ${theme.palette.background.$color_background_button_primary_danger}`,
        '&.MuiButtonBase-root:hover': {
            color: (theme) => theme.palette.background.$color_background_button_primary_danger_hover,
            border: (theme) => `1px solid ${theme.palette.background.$color_background_button_primary_danger_hover} !important`,
        },
        '&:active': {
            color: (theme) => theme.palette.button.$color_button_primary_active,
            border: (theme) => `1px solid ${theme.palette.button.$$color_background_button_primary_danger_active} !important`,
        },
    },

    secondaryMediumButton: {
        font: (theme) => `${theme.typography.fontSize.$font_small_bold}!important`,
        width: '100%',
        height: '32px',
        textTransform: 'none',
        padding: '3px 15px',
        borderRadius: (theme) => theme.palette.border.$border_radius,
        color: (theme) => theme.palette.blue.strongDark,
        border: (theme) => `1px solid ${theme.palette.blue.strongDark}`,
        '&.MuiButtonBase-root:hover': {
            color: (theme) => theme.palette.button.$color_button_primary_hover,
            border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_hover} !important`,
        },
        '&:active': {
            color: (theme) => theme.palette.button.$color_button_primary_active,
            border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_active} !important`,
        },
    },
    secondaryButton: {
        font: (theme) => `${theme.typography.fontSize.$font_body_caps}!important`,
        width: '100%',
        height: '40px',
        textTransform: 'none',
        padding: '3px 15px',
        borderRadius: (theme) => theme.palette.border.$border_radius,
        color: (theme) => theme.palette.blue.strongDark,
        border: (theme) => `1px solid ${theme.palette.blue.strongDark}`,
        '&.MuiButtonBase-root:hover': {
            color: (theme) => theme.palette.button.$color_button_primary_hover,
            border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_hover} !important`,
        },
        '&:active': {
            color: (theme) => theme.palette.button.$color_button_primary_active,
            border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_active} !important`,
        },
    },
    secondarySmallButton: {
        font: (theme) => `${theme.typography.fontSize.$font_xsmall_caps}!important`,
        color: (theme) => theme.palette.interAction.$color_interaction_active,
        textTransform: 'none',
        borderRadius: (theme) => theme.palette.border.$border_radius,
        padding: '4px 8px',
        height: '24px',
        border: (theme) => `1px solid ${theme.palette.border.$color_border_secondary_button}`,
        '&:hover': {
            color: (theme) => theme.palette.button.$color_button_primary_hover,
            border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_hover} !important`,
            background: 'transparent',
        },
        '&:active': {
            color: (theme) => theme.palette.button.$color_button_primary_active,
            border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_active} !important`,
        },
    },

    tertiaryMediumButton: {
        font: (theme) => `${theme.typography.fontSize.$font_small_bold}!important`,
        height: '32px',
        padding: '1px 6px',
        color: (theme) => theme.palette.interAction.$color_interaction_active,
        textTransform: 'none',
        backgroundColor: 'transparent',
        '&.MuiLoadingButton-root:hover': {
            color: (theme) => theme.palette.button.$color_button_primary_hover,
            backgroundColor: 'transparent',
        },
        '&:active': {
            color: (theme) => theme.palette.button.$color_button_primary_active,
        },
    },

    configureMediumButton: {
        width: '100%',
        height: '32px',
        fontSize: '14px !important',
        fontWeight: '700',
        textTransform: 'none',
        padding: '3px 15px',
        borderRadius: (theme) => theme.palette.border.$border_radius,
        border: (theme) => `1px solid ${theme.palette.blue.strongDark}`,
        background: 'transparent',
        color: (theme) => theme.palette.blue.strongDark,
        '&.MuiButtonBase-root:hover': {
            color: (theme) => theme.palette.button.$color_button_primary_hover,
            border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_hover} !important`,
            background: 'transparent',
        },
        '&:active': {
            color: (theme) => theme.palette.button.$color_button_primary_active,
            border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_active} !important`,
        },
    },
    serviceNewTagBtn: {
        borderRadius: (theme) => theme.palette.border.$border_radius,
        textTransform: 'none',
        padding: '4px 8px',
        fontSize: '12px !important',
        fontWeight: '700',
        height: '24px',
        backgroundColor: (theme) => theme.palette.background.$color_background_button_primary,
        '&:hover': {
            backgroundColor: (theme) => theme.palette.background.$color_background_button_primary_hover,
        },
        '&:active': {
            backgroundColor: (theme) => theme.palette.button.$color_button_primary_active,
        },
    },
    serviceTagBtn: {
        borderRadius: (theme) => theme.palette.border.$border_radius,
        textTransform: 'none',
        padding: '4px 16px',
        fontWeight: '700',
        height: '32px',
        backgroundColor: (theme) => theme.palette.background.$color_background_button_primary,
        '&:hover': {
            backgroundColor: (theme) => theme.palette.background.$color_background_button_primary_hover,
        },
        '&:active': {
            backgroundColor: (theme) => theme.palette.button.$color_button_primary_active,
        },
    },
    tagBadge: { marginRight: (theme) => theme.spacing(2), '& .MuiBadge-badge': { right: '6px' } },
    cartBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
        fontFamily: (theme) => theme.typography.fontFamily,
        borderRadius: (theme) => theme.palette.border.$border_radius,
        backgroundColor: (theme) => theme.palette.blue.strongDark,
        padding: '8px 24px',
        fontWeight: '700',
        '&:hover': {
            backgroundColor: (theme) => theme.palette.background.$color_button_primary_hover,
        },
        '&:active': {
            backgroundColor: (theme) => theme.palette.button.$color_button_primary_active,
        },
    },
    modalSaveButton: {
        font: (theme) => `${theme.typography.fontSize.$font_small_caps}!important`,
        borderRadius: (theme) => theme.palette.border.$border_radius,
        backgroundColor: (theme) => theme.palette.background.$color_background_button_primary,
        color: '#fff',
        padding: '4px 16px',
        height: '32px',
        '&:hover': {
            backgroundColor: (theme) => theme.palette.background.$color_background_button_primary_hover,
        },
        '&:active': {
            backgroundColor: (theme) => theme.palette.button.$color_button_primary_active,
        },
    },
    modalCancelButton: {
        font: (theme) => `${theme.typography.fontSize.$font_small_caps}!important`,
        borderRadius: (theme) => theme.palette.border.$border_radius,
        color: (theme) => theme.palette.interAction.$color_interaction_active,
        padding: '3px 15px',
        // fontWeight: '700',
        // fontSize: '14px !important',
        backgroundColor: 'transparent',
        height: '32px',
        border: (theme) => `1px solid ${theme.palette.border.$color_border_secondary_button}`,
        '&:hover': {
            color: (theme) => theme.palette.border.$color_border_secondary_button_hover,
            border: (theme) => `1px solid ${theme.palette.border.$color_border_secondary_button_hover}`,
            backgroundColor: 'transparent',
        },
        '&:active': {
            color: (theme) => theme.palette.button.$color_button_primary_active,
            border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_active} !important`,
        },
    },
    noteBold: {
        fontWeight: '700',
    },
    chipTag: {
        borderRadius: '4px',
        fontSize: '12px',
        mr: 2,
        mt: 2,
        backgroundColor: (theme) => theme.palette.tag.$auxiliary_600_background_color,
        color: '#fff',
        '&:hover': {
            color: '#fff',
        },
    },
    chipCloseIcon: {
        width: '16px',
        height: '16px',
    },

    largeCircularprogressButton: {
        backgroundImage: 'url(/static/images/circular-loader.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        overflow: 'hidden',
        height: '80px',
        width: '80px',
        animation: ' 1.5s linear 0s infinite normal none running spin',
    },

    modalWrapper: {
        border: '10px solid #D7E5F8',
        padding: '40px',
    },

    modalMU: {
        border: '10px solid #D7E5F8',
        padding: '16px',
    },

    modalTitle: {
        fontSize: '24px',
        fontWeight: '700',
    },

    modalContent: {
        fontSize: '13px',
        fontWeight: '400',
        color: '#3A3A3A',
    },
};
export default commonStyles;
