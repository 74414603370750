import React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import PropTypes from 'prop-types';
import orderStyle from 'pages/orders/style';

const NsgAccordion = ({ title, children, open, defaultExpanded, isOrderPage, isExpandIconML }) => {
    const [show, setShow] = React.useState(false);
    React.useEffect(() => {
        setShow(open);
    }, []);
    /* istanbul ignore next */
    return (
        <Accordion
            data-testid="order-catalog-accordion"
            elevation={1}
            sx={{ ...orderStyle.orderCatalogServices.accordian }}
            className="borderclass"
            expanded={defaultExpanded || show}
            defaultExpanded={defaultExpanded}
            onChange={() => setShow(!show)}
        >
            <AccordionSummary
                sx={{
                    ...orderStyle.orderCatalogServices1.container,
                    borderBottom: 'none',
                    ...(!isOrderPage && {
                        height: '50px',
                        minHeight: '50px !important',
                    }),
                    ...(isExpandIconML && {
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            marginRight: '17px',
                        },
                    }),
                }}
                expandIcon={<ExpandMoreIcon sx={{ color: !isOrderPage ? '#616161' : '#0047BA' }} />}
            >
                <Typography variant="span" sx={{ fontSize: '16px', color: '#1E1E1E', fontWeight: 'normal' }}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ ...orderStyle.orderCatalogServices.children, paddingTop: 0 }}>{children && <>{children}</>}</AccordionDetails>
        </Accordion>
    );
};

NsgAccordion.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    open: PropTypes.any,
    defaultExpanded: PropTypes.bool,
    isOrderPage: PropTypes.bool,
    isExpandIconML: PropTypes.bool,
};

NsgAccordion.defaultProps = {
    title: 'Title Here',
    children: undefined,
    open: false,
    defaultExpanded: false,
    isOrderPage: false,
    isExpandIconML: false,
};

export default React.memo(NsgAccordion);
