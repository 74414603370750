import React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import PropTypes from 'prop-types';
import orderStyle from 'pages/orders/style';

const RIAccordion = ({ title, children, open, defaultExpanded }) => {
    const [show, setShow] = React.useState(false);
    React.useEffect(() => {
        setShow(open);
    }, []);
    return (
        <Accordion
            data-testid="order-catalog-accordion"
            elevation={1}
            sx={orderStyle.orderCatalogServices.accordian}
            className="borderclass"
            expanded={defaultExpanded || show}
            defaultExpanded={defaultExpanded}
            onChange={() => setShow(!show)}
        >
            <AccordionSummary sx={orderStyle.orderCatalogServices1.container} expandIcon={<ExpandMoreIcon sx={{ color: '#0047BA' }} />}>
                <Typography variant="span" sx={{ fontSize: '18px', color: '#1378D4', fontWeight: 'bold' }}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={orderStyle.orderCatalogServices.children}>{children && <>{children}</>}</AccordionDetails>
        </Accordion>
    );
};

RIAccordion.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    open: PropTypes.any,
    defaultExpanded: PropTypes.bool,
};

RIAccordion.defaultProps = {
    title: 'Title Here',
    children: undefined,
    open: false,
    defaultExpanded: false,
};

export default React.memo(RIAccordion);
