import React from 'react';
import Btn from 'components/button';
import axios, { endPoints } from 'api/request';
import { CircularProgress } from '@mui/material';
import { useMutation } from 'react-query';
import { orderPayload, validProvisionForm, successPopup } from 'pages/mdt-onboarding/store';
import { useRecoilState } from 'recoil';
// import ProvisioningStyles from 'pages/mdt-onboarding/style';
import { commonStyles as style } from 'assets/style';
import { isReadytoSubmit, getOrderPayload, resetOrder, resetvalidProvisionForm } from 'pages/mdt-onboarding/utils';
import ErrorBanner from './error-banner';

const SubmitOrderBtn = () => {
    const [order, setOrder] = useRecoilState(orderPayload);
    const [isvalidForm, setValidForm] = useRecoilState(validProvisionForm);
    const [isSubmitSuccess, setIsSubmitSuccess] = useRecoilState(successPopup);
    const [errorBannerOpen, setErrorBannerOpen] = React.useState(false);

    // const submitOrderApi = useMutation(() => axios.get(endPoints.mdt.submitOrder));
    const submitOrderApi = useMutation((payload) => axios.post(endPoints?.mdt?.Jwt?.submitOrder, payload));

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };

    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };

    React.useEffect(() => {
        if (submitOrderApi?.data?.data) {
            setIsSubmitSuccess({ ...isSubmitSuccess, isOpen: true, orderId: submitOrderApi?.data?.data?.orderId });
            setOrder({ ...order, resetOrder });
            setValidForm({ ...isvalidForm, resetvalidProvisionForm });
        }
    }, [submitOrderApi?.isSuccess]);

    const handleSubmit = async () => {
        const payload = await getOrderPayload({ order });
        // eslint-disable-next-line
        try {
            submitOrderApi?.mutate(payload);
            // }
        } catch (e) {
            // eslint-disable-next-line
            console.log('Exception during schema validation', e);
        }
    };

    const handleClick = () => {
        if (!isReadytoSubmit({ order, isvalidForm }) && order?.sourceFileNamePatternList[0].fileName) {
            handleSubmit();
        } else {
            setOrder({ ...order, isTouched: true });
            handleClickErrorBanner();
        }
    };

    return (
        <>
            <Btn
                data-testid="submit-btn"
                size="medium"
                variant="contained"
                className="btn-disabled"
                // color="cmpPrimary"
                // sx={ProvisioningStyles.submitOrder}
                sx={{ ...style.primaryMediumButton, width: '150px' }}
                onClick={() => handleClick()}
                disabled={submitOrderApi?.isLoading}
            >
                {submitOrderApi?.isLoading ? <CircularProgress color="inherit" size={16} /> : 'Submit Order'}
            </Btn>
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with * asterisk and click submit order."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </>
    );
};

export default SubmitOrderBtn;
