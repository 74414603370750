const defaultOrderValues = {
    gitHubPlatform: true,
    existingProject: true,
    migrateRepos: true,
    determineTeamsAndMembers: true,
    assignReposToTeams: true,
    date_Created: null,
    status: 'open',
    adoOrg: null,
    ghOrg: null,
    netMembersToAdd: 0,
    applicationOwnerDetails: {},
    customProperties: {
        sector: null,
        adoProjectName: null,
        businessApplication: null,
        businessApplicationId: null,
        applicationService: null,
        applicationOwner: null,
        applicationSupportGroup: null,
        cmpHCode: null,
        capability: null,
    },
    reposToMigrate: [],
    reposAssignedToTeams: [],
    teams: {
        defaultTeams: {},
        customTeams: [],
    },
    isTouched: false,
    currentTeams: [
        {
            teamName: 'DPA_RichApp_developers',
            isDefault: true,
            members: [],
            membersAdded: ['John Snow', 'Clark Kent'],
            membersRemoved: ['Rich Rich'],
        },
        {
            teamName: 'DPA_RichApp_devops',
            isDefault: true,
            members: [],
            membersAdded: ['Clark Kent'],
            membersRemoved: [],
        },
        {
            teamName: 'DPA_RichApp_finance_devops',
            isDefault: false,
            members: [],
            membersAdded: [],
            membersRemoved: [],
        },
        {
            teamName: 'DPA_RichApp_finance_developers',
            isDefault: false,
            members: [],
            membersAdded: [],
            membersRemoved: [],
        },
    ],
    // Fetched data
    availableProjectRepos: [],
    availableGithubRepos: null,
    availableTeams: null,
};

export const defaultValidOnboardingForm = { isProjectNameExists: false, isProjectNameValid: false, isAppNameValid: false };

export const defaultToggleModals = { isReposToMigrateOpen: false, isTeamsAndMembersOpen: false, isAssignReposOpen: false, action: '' };

export default defaultOrderValues;
