import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { OrderPayload } from 'pages/sso-okta/store';

export const IdxAttribute = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [helperText, setHelperText] = React.useState(false);
    const idxAttributeApi = useMutation(() =>
        axios.get(`${endPoints?.ssoOkta?.Jwt?.idxAttribute}/name/${order?.idxAttribute}/environment/${order?.environment}`)
    );

    const setIdxAttribute = (idxAttribute = null) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            idxAttribute,
        }));
    };

    React.useEffect(() => {
        if (order?.idxAttribute && order?.idxAttribute !== null && order?.idxAttribute !== '' && order?.idxAttribute !== undefined) {
            if (!order?.idxAttribute?.startsWith('pepapp')) {
                setHelperText(true);
            } else {
                setHelperText(false);
            }
        } else {
            setHelperText(false);
        }
    }, [order?.idxAttribute]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.idxAttribute) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (order?.environment && order?.idxAttribute) {
            idxAttributeApi.mutate();
        }
    }, [order?.environment, order?.idxAttribute]);
    React.useEffect(() => {
        if (!order?.idxAttribute && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.idxAttribute, order?.isTouched]);

    React.useEffect(() => {
        if (order?.dataClassification) {
            setIdxAttribute(null);
        }
    }, [order?.dataClassification]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="idxAttribute-name-box">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="IDX Attribute"
                error={helperText}
                helperText={helperText && <span>IDX Attribute is invalid.</span>}
                value={order?.idxAttribute || ''}
                onChange={(e) => setIdxAttribute(e.target.value)}
                className={validateInput()}
                InputProps={{
                    endAdornment: <>{idxAttributeApi?.isLoading && <CircularProgress color="inherit" size={16} />}</>,
                }}
            />
        </Box>
    );
};

export default IdxAttribute;
