import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Box } from '@mui/material';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import Alert from 'components/alert';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { catalogs } from 'store';
import { sspSubmitOrder, toggleSspModels, isMandatoryTagsFullfilled, costEstimateState, isLoadingCostApiState } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import { resetCostEstimateDetails } from 'pages/provisioning/event-hub/utils';
import StickyHeader from 'pages/provisioning/_components/StickyHeader';
import { getPayload } from 'pages/provisioning/event-hub/getPayload';
import ErrorBanner from '../../../components/ErrorBanner';

const ProvisioningHeader = () => {
    const { catalogCode, cartItemId, catalogServiceId } = useParams();
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [isWelcomeBannerOpen, setWelcomeBannerOpen] = React.useState(true);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, setCostEstimate] = useRecoilState(costEstimateState);
    const [, setIsLoadingCostApi] = useRecoilState(isLoadingCostApiState);
    const [modal] = useRecoilState(toggleSspModels);
    const isMandatoryTags = useRecoilValue(isMandatoryTagsFullfilled);

    const getCostPayload = () => {
        const payload = getPayload({ order, catalogCode, cartItemId, catalogServiceId });
        const costPayload = payload?.catalogService;
        delete costPayload?.estimatedCost;
        return costPayload;
    };

    const checkVarConditions = () =>
        order?.sector &&
        order?.environment &&
        order?.region &&
        order?.hCodeDetails &&
        order?.performance &&
        ((order?.performance === 'Standard' ? order?.throughputUnits : true) || (order?.performance === 'Premium' ? order?.processingUnits : true)) &&
        true;
    const cost = useMutation(() => axios.post(endPoints?.ssp?.costCalculation, getCostPayload()));

    React.useEffect(() => {
        setCostEstimate({ ...resetCostEstimateDetails, details: order?.emptyCost?.costs });
        if (checkVarConditions() === true) {
            cost?.mutate();
        }
        // eslint-disable-next-line
    }, [order?.sector, order?.environment, order?.region, order?.hCodeDetails, order?.performance, order?.throughputUnits, order?.processingUnits]);

    React.useEffect(() => {
        setIsLoadingCostApi({ isLoading: cost?.isFetching });
    }, [cost?.isFetching]);

    React.useEffect(() => {
        if (cost?.data?.data && checkVarConditions()) {
            if (cost?.data?.data) setCostEstimate(cost?.data?.data);
            setOrder({ ...order, estimatedCost: cost?.data?.data });
        }
        // eslint-disable-next-line
    }, [cost?.isSuccess]);

    const isReadyToSubmit = () =>
        !order?.sector ||
        !order?.environment ||
        !order?.region ||
        !order?.landingZoneDetails ||
        !order?.hCodeDetails ||
        !order?.namespaceName ||
        isMandatoryTags;

    React.useEffect(() => {
        setCostEstimate({ ...resetCostEstimateDetails, details: order?.emptyCost?.costs });
        // eslint-disable-next-line
    }, []);

    const getDisImage = (code) => {
        if (cmpCatalogs?.length) {
            const result = cmpCatalogs.filter((service) => service.catalogDefinitionId === code);
            return result[0]?.iconPath;
        }
        return null;
    };

    const getProvisiongTitle = (code) => {
        if (cmpCatalogs?.length) {
            const result = cmpCatalogs.filter((service) => service.catalogDefinitionId === code);
            return result[0]?.displayName;
        }
        return null;
    };

    return (
        <>
            {cmpCatalogs?.length > 0 && <StickyHeader title={`${getProvisiongTitle(catalogCode)} Provisioning`} imgSrc={getDisImage(catalogCode)} />}
            <Box sx={ProvisioningStyles.ProvisioningHeader.header}>
                {order?.isTouched && <ErrorBanner show={order?.isTouched && isReadyToSubmit()} />}
                {!order?.isTouched && isReadyToSubmit() && isWelcomeBannerOpen && !modal.isCostSplitOpen && (
                    <Alert
                        title={null}
                        message="Welcome! Please choose from the PreConfig options by clicking the button or fill out all of the required fields marked with the * asterisk to complete your order."
                        Icon={<InfoImg style={ProvisioningStyles.ProvisioningHeader.infoIcon} />}
                        // borderLeftColor="#0047BA"
                        borderLeftColor="#CCEBF9"
                        backgroundColor="#CCEBF9"
                        color="#000000"
                        ftSize="14px"
                        onClose={() => setWelcomeBannerOpen(false)}
                        closeicon
                    />
                )}
            </Box>
        </>
    );
};

ProvisioningHeader.propTypes = {};

ProvisioningHeader.defaultProps = {};

export default React.memo(ProvisioningHeader);
