import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { getMemberAccessForPreview } from 'pages/project-onboarding/utils/getFunctions';
import Style from 'pages/project-onboarding/style';

const ProjectOnboardingMembersDetails = (membersDetails) => {
    const roleData = {
        projectAdmin: [...membersDetails?.membersDetails?.projectAdmin, ...membersDetails?.membersDetails?.projectAdminRemovedMembers] || [],
        fs: membersDetails?.membersDetails?.fs || [],
        sca: membersDetails?.membersDetails?.sca || [],
        testPlan: [...membersDetails?.membersDetails?.testPlan, ...membersDetails?.membersDetails?.testPlanRemovedMembers] || [],
        sonarQube: membersDetails?.membersDetails?.sonarQube || [],
        artifactory: membersDetails?.membersDetails?.artifactory || [],
    };

    const getMemberAction = (action) => {
        switch (action) {
            case true:
                return <Box sx={{ color: (theme) => theme.palette.blue.main, font: (theme) => theme.typography.fontSize.$font_small_medium }}>New</Box>;
            case false:
                return null;
            case 'modify':
                return <Box sx={{ color: (theme) => theme.palette.orange.main, font: (theme) => theme.typography.fontSize.$font_small_medium }}>Modify</Box>;
            default:
                return null;
        }
    };

    const resoureDisplay = (title, action) => {
        return (
            <Box sx={Style?.preview?.memberAccess?.subWrapper} key={title}>
                <Box marginLeft="80px" minWidth={140} maxWidth={165}>
                    {title}
                </Box>
                <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                <Box sx={Style?.preview?.memberAccess?.value}>{getMemberAction(action)}</Box>
            </Box>
        );
    };

    const getLabelForSCA = (user) => {
        if (user?.sca && user?.role) {
            return user?.role && `SCA (${user?.role || ''})`;
        }
        if (user?.sca && !user?.role && roleData?.sca?.length > 0) {
            const scaUser = roleData?.sca?.find((sca) => sca?.gpid === user?.gpid);
            return `SCA (${scaUser?.role || ''})`;
        }
        return 'SCA';
    };

    if (
        getMemberAccessForPreview(roleData, membersDetails?.membersDetails?.projectAdminRemovedMembers, membersDetails?.membersDetails?.testPlanRemovedMembers)
            ?.length === 0
    )
        return <Box color="#000000">No member requested.</Box>;

    return (
        <Box sx={Style?.preview?.orderMemberWrapper}>
            {getMemberAccessForPreview(
                roleData,
                membersDetails?.membersDetails?.projectAdminRemovedMembers,
                membersDetails?.membersDetails?.testPlanRemovedMembers
            )?.map((member) => (
                <Grid container sx={Style?.preview?.memberAccess?.orderMemberContainer}>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="column" spacing={1}>
                            <Box sx={Style?.preview?.memberAccess?.subWrapper}>
                                <Box minWidth="95px">GPID</Box>
                                <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                                <Box sx={Style?.preview?.memberAccess?.value}>{member?.gpid}</Box>
                            </Box>
                            <Box sx={Style?.preview?.memberAccess?.subWrapper}>
                                <Box minWidth="95px">Member Name</Box>
                                <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                                <Box sx={Style?.preview?.memberAccess?.value}>{member?.name}</Box>
                            </Box>
                            <Box sx={Style?.preview?.memberAccess?.subWrapper}>
                                <Box minWidth="95px">Email ID</Box>
                                <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                                <Box sx={Style?.preview?.memberAccess?.value}>{member?.email === 'null' ? '-' : member?.email}</Box>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="column" spacing={1}>
                            {resoureDisplay('Project Admin', member?.projectAdmin)}
                            {resoureDisplay('SonarQube', member?.sonarQube)}
                            {resoureDisplay('Artifactory', member?.artifactory)}
                            {resoureDisplay('SAST', member?.fs)}
                            {resoureDisplay(getLabelForSCA(member), member?.sca)}
                            {resoureDisplay('Test Plan', member?.testPlan)}
                        </Stack>
                    </Grid>
                </Grid>
            ))}
        </Box>
    );
};

export default ProjectOnboardingMembersDetails;
