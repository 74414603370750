import { useMemo, useState } from 'react';

const usePagination = (data = [], rowsPerPage = 10) => {
    const [page, setPage] = useState(1);

    const totalPages = Math.ceil(data.length / rowsPerPage);

    const currentPageData = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;
        return data.slice(start, end);
    }, [page, data, rowsPerPage]);

    const handleChangePage = (_, value) => {
        setPage(value);
    };

    return {
        page,
        setPage,
        totalPages,
        currentPageData,
        handleChangePage,
    };
};

export default usePagination;
