import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Stack, Box } from '@mui/material';
// import SkuList from 'pages/sku/sku-list';
// import SkuHeader from 'pages/sku/sku-header';
import { useResetRecoilState } from 'recoil';
import Empty from 'components/empty';
import { selectedIdsState, skuTypeState, toggleDetailModal, totalCountState } from 'pages/sku/store';
import skuStyle from 'pages/sku/style';

const SkuHeader = React.lazy(() => import('pages/sku/sku-header'));
const SkuList = React.lazy(() => import('pages/sku/sku-list'));

const skuPage = () => {
    const resetSkuType = useResetRecoilState(skuTypeState);
    const resetSelectedIds = useResetRecoilState(selectedIdsState);
    const resetTotalCount = useResetRecoilState(totalCountState);
    const resetToggleDetailModal = useResetRecoilState(toggleDetailModal);
    const reset = () => {
        resetSkuType();
        resetSelectedIds();
        resetTotalCount();
        resetToggleDetailModal();
    };
    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => reset();
    }, []);
    return (
        <>
            <Helmet>
                <title>SKU - DevX Infra</title>
            </Helmet>
            <Suspense
                fallback={
                    <Stack sx={skuStyle?.loadingStack}>
                        <Empty color="#000000" isLoading />
                    </Stack>
                }
            >
                <Box sx={skuStyle?.wrapper} data-testid="sku-index">
                    <SkuHeader />
                    <SkuList />
                </Box>
            </Suspense>
        </>
    );
};
export default skuPage;
