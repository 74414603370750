/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Helmet } from 'react-helmet';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { useRecoilState } from 'recoil';
import ErrorFallback from 'components/error-fallback';
import Empty from 'components/empty';
import { riCatalogs } from 'store';
import { riCatalogDialog, riSelectedCatalog } from 'pages/reference-implementation/store';
// import styles, { CssTextField } from './styles';
import styles, { RefImpServices, CssTextField } from 'assets/style/styles';
import { findNested, getModifiedCatalogs } from './utils';

const CatalogService = React.lazy(() => import('pages/reference-implementation/services/CatalogService'));
const ServiceFilter = React.lazy(() => import('pages/reference-implementation/services/filter'));
const CustomizedDialogs = React.lazy(() => import('pages/reference-implementation/components/dialog'));

function RiServices() {
    const [searchText, setSearchText] = React.useState('');
    const [riCatlogsData, setRiCatalogsData] = React.useState([]);
    const [cmpRiCatalogs] = useRecoilState(riCatalogs);
    const [isOpen, setIsOpen] = useRecoilState(riCatalogDialog);
    const [selectedCatalog] = useRecoilState(riSelectedCatalog);
    const refToTop = React.useRef();

    React.useEffect(() => {
        setRiCatalogsData(getModifiedCatalogs(cmpRiCatalogs));
    }, [cmpRiCatalogs]);

    const findSearched = (val) => {
        if (searchText === '') {
            setRiCatalogsData(getModifiedCatalogs(cmpRiCatalogs));
        } else {
            const find = findNested(cmpRiCatalogs, val);
            setRiCatalogsData(getModifiedCatalogs([...new Set(find)]));
        }
    };
    React.useEffect(() => {
        if (searchText === '') {
            setRiCatalogsData(getModifiedCatalogs(cmpRiCatalogs));
        } else {
            findSearched(searchText);
        }
    }, [searchText]);

    const filterData = (riServiceData) => {
        const result = cmpRiCatalogs.filter((item) => {
            if (riServiceData['reference-implementation-id'] && riServiceData?.tag) {
                return item['reference-implementation-id'] === riServiceData['reference-implementation-id'] && item?.tags?.includes(riServiceData?.tag);
            }
            if (riServiceData['reference-implementation-id']) {
                return item['reference-implementation-id'] === riServiceData['reference-implementation-id'];
            }
            if (riServiceData?.tag) {
                return item?.tags?.includes(riServiceData?.tag);
            }
            if (!riServiceData['reference-implementation-id'] && !riServiceData?.tag) {
                return cmpRiCatalogs;
            }
            return true;
        });
        setRiCatalogsData(getModifiedCatalogs(result));
    };

    React.useEffect(() => {
        return refToTop.current && refToTop.current.scrollIntoView();
    }, []);

    return (
        <>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Helmet>
                    <title>Solutions - DevX Infra</title>
                </Helmet>
                <Suspense
                    fallback={
                        <Stack sx={styles.loadingStack}>
                            <Empty color="#000000" isLoading />
                        </Stack>
                    }
                >
                    <Stack spacing={2} sx={styles.mainstack} ref={refToTop}>
                        {cmpRiCatalogs?.length > 0 && (
                            <>
                                <Box sx={styles.headercontainer} spacing={1}>
                                    <Typography variant="h6" sx={styles.headingTypo}>
                                        {' '}
                                        Solutions
                                    </Typography>
                                    <Box sx={{ ...styles.actionContainer, alignContent: 'flex-end' }}>
                                        <Box sx={styles.searchbox}>
                                            <CssTextField
                                                fullWidth
                                                autoComplete="off"
                                                label="Search for solutions"
                                                size="small"
                                                variant="outlined"
                                                onChange={(e) => setSearchText(e.target.value)}
                                                value={searchText}
                                                InputProps={{
                                                    endAdornment: (
                                                        <>
                                                            {searchText && (
                                                                <IconButton
                                                                    aria-label="Search "
                                                                    sx={styles.clearicon}
                                                                    onClick={() => {
                                                                        setSearchText('');
                                                                    }}
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            )}
                                                            <>
                                                                <IconButton sx={styles.searchiconBtn} onClick={() => findSearched(searchText)}>
                                                                    <SearchIcon sx={styles.searchicon} />
                                                                </IconButton>
                                                            </>
                                                        </>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                        <ServiceFilter handleFilter={filterData} />
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="column" textAlign="center" spacing={4} justifyContent="center">
                                    <Box sx={RefImpServices.mainTextContainer}>
                                        Start Your Project Now! Explore a World of Possibilities with Cloud-Based Pre-Approved Solutions.
                                    </Box>
                                    <Box sx={RefImpServices.subTextContainer}>Utilize Pre-Approved Architecture to Accelerate Your Cloud Enablement Today.</Box>
                                </Box>
                                <Box sx={{ ...RefImpServices.newGridWrapper, maxWidth: '1160px' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            alignContent: 'stretch',
                                        }}
                                        justifyContent="center"
                                    >
                                        {riCatlogsData?.length <= 0 && (
                                            <Grid item xs={12}>
                                                <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" sx={styles.loadingStack}>
                                                    <Empty notFound color="#000000" />
                                                </Stack>
                                            </Grid>
                                        )}
                                        {riCatlogsData?.length > 0 && (
                                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ maxWidth: '1153px' }}>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    flexWrap="wrap"
                                                    justifyContent="center"
                                                    sx={{ gap: '11px', mb: '11px' }}
                                                >
                                                    {riCatlogsData?.slice(0, 4)?.map((riService, idx) => (
                                                        <Box display="flex" key={idx}>
                                                            <CatalogService service={riService} shownumber={false} idx={idx + 1} color="#CCCCCC" />
                                                        </Box>
                                                    ))}
                                                </Box>
                                                <Box display="flex" sx={{ felxGrow: '1', width: '100%' }}>
                                                    <Box
                                                        data-testid="banner-img-1"
                                                        width="100%"
                                                        height="162px"
                                                        maxWidth="1237px"
                                                        component="img"
                                                        src="/static/images/RI/banner.png"
                                                        alt=""
                                                    />
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    flexWrap="wrap"
                                                    sx={{ gap: '11px', mt: '11px' }}
                                                >
                                                    {riCatlogsData?.slice(4, riCatlogsData?.length)?.map((riService, idx) => (
                                                        <Box display="flex" key={idx}>
                                                            <CatalogService service={riService} shownumber={false} idx={idx + 1} color="#CCCCCC" />
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Stack>
                    <CustomizedDialogs
                        isOpen={isOpen}
                        setOpen={setIsOpen}
                        rid={selectedCatalog?.['reference-implementation-id']}
                        title={selectedCatalog?.name}
                        archDiagram={selectedCatalog?.archDiagram}
                        provisioningPath={`${selectedCatalog?.provisioningPath}/${selectedCatalog?.['reference-implementation-id']}`}
                        description={selectedCatalog?.description}
                        helpUrl={selectedCatalog?.['help-link']}
                        tags={selectedCatalog?.tags}
                        // archImg={selectedCatalog?.archImg}
                    />
                </Suspense>
            </ErrorBoundary>
        </>
    );
}

export default RiServices;
