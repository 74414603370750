import React from 'react';
import { Box, Grid } from '@mui/material';
import Forms from 'pages/github-onboarding/components/Form';
import AdoAlert from 'pages/github-onboarding/components/ado-alert';
import FormCard from 'pages/github-onboarding/components/form-card';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/github-onboarding/store';
import GithubOnboardingStyles, { LeftBox, ScrollableContainer } from 'pages/github-onboarding/style';

const InputForm = () => {
    const [order] = useRecoilState(OrderPayload);

    return (
        <ScrollableContainer sx={{ padding: '0px' }}>
            <Grid container spacing={3.125}>
                <Grid item xs={12} sm={6} md={8} data-testId="github-onboard-input-form">
                    <LeftBox>
                        <Box>
                            <FormCard title="Project">
                                <Grid container spacing={4} pt="30px">
                                    <Grid item xs={12} sm={6} sx={GithubOnboardingStyles?.formField}>
                                        <Forms.Sector />
                                    </Grid>
                                    <Grid item xs={12} sm={12} sx={GithubOnboardingStyles?.formField}>
                                        <Forms.AvailableADOProject />
                                    </Grid>
                                    {!order?.existingProject && (
                                        <Grid item xs={12} sm={12} sx={GithubOnboardingStyles?.formField}>
                                            <AdoAlert />
                                        </Grid>
                                    )}
                                    {order?.existingProject && (
                                        <>
                                            <Grid item xs={12} sm={6} sx={GithubOnboardingStyles?.formField}>
                                                <Forms.ADOProjectName />
                                            </Grid>
                                            <Grid item xs={12} sm={12} sx={GithubOnboardingStyles.formField}>
                                                <Forms.MigrateOrCreateProject />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </FormCard>
                            {order?.existingProject && (
                                <>
                                    <FormCard title="Application">
                                        <Grid container spacing={4} pt="30px">
                                            <Grid item xs={12} sm={6} sx={GithubOnboardingStyles?.formField}>
                                                <Forms.BusinessApplication />
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={GithubOnboardingStyles?.formField}>
                                                <Forms.ApplicationService />
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={GithubOnboardingStyles?.formField}>
                                                <Forms.ApplicationOwner />
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={GithubOnboardingStyles?.formField}>
                                                <Forms.ApplicationSupportGroup />
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={GithubOnboardingStyles?.formField}>
                                                <Forms.Capability />
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={GithubOnboardingStyles?.formField}>
                                                <Forms.CostCode />
                                            </Grid>
                                        </Grid>
                                    </FormCard>
                                    <Grid item xs={12} mt={3}>
                                        <FormCard title="Catalog of services">
                                            <Forms.ServiceResources />
                                        </FormCard>
                                    </Grid>
                                </>
                            )}
                        </Box>
                    </LeftBox>
                </Grid>
            </Grid>
        </ScrollableContainer>
    );
};

export default InputForm;
