import { styled } from '@mui/material/styles';
import { TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const SecurityToolStyles = {
    securityTool_wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: (theme) => theme.spacing('100%'),
        height: 'calc(100vh - 100px)',
    },
    circularProgressColor: { color: (theme) => theme.palette.black.dark },
    infoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    addBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
    },
    resubmitBtn: {
        width: (theme) => theme.spacing(20.75),
        height: (theme) => theme.spacing(4.375),
    },
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    MemberAction: {
        select: { width: '100%' },
        searchIcon: { cursor: 'pointer', color: '#0047BA' },
        infoicon: { color: '#0047BA' },
    },
    submitOrder: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypo: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        actionWrapper: { textAlign: 'center', my: (theme) => theme.spacing(0.25), py: (theme) => theme.spacing(2) },
        newOrder: { marginRight: (theme) => theme.spacing(2) },
        message: { fontSize: '30px', fontWeight: 500, color: (theme) => theme.palette.black.dark },
        description: { width: '500px', fontSize: '16px', fontWeight: 400, color: (theme) => theme.palette.black.dark },
    },
    removeBtn: {
        cursor: 'pointer',
        color: '#F4273B',
    },
    table: {
        tableWrapper: {
            marginTop: '4px',
            borderRadius: '3px',
            boxShadow: '1px 1px 2px #00000014',
        },
        tableRow: {
            background: 'transparent !important',
            border: '0 !important',
        },
        tableCell: {
            border: '0 !important',
        },
        main: {
            marginTop: '-9px',
            borderSpacing: '0 4px',
            minWidth: 500,
        },
    },
    emptyStack: {
        width: '100%',
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    codeRepoStyle: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        codeRepoWrapper: { width: '200%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        delete: {
            // fontSize: (theme) => theme.spacing(2.25),
            // fill: '#FFFFFF',
            // color: (theme) => theme.palette.white,
            cursor: 'pointer',
            width: '16px',
            height: '16px',
        },
        add: { width: '16px', height: '16px', cursor: 'pointer', marginTop: '7px', font: (theme) => theme.typography.fontSize.$font_heading_h5_medium },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        warningTypography: { color: (theme) => theme.palette.red.light },
        deleteWrapper: {
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
            // background: (theme) => theme?.palette?.red?.darkest,
            mt: 0.5,
        },
    },
};
export default SecurityToolStyles;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#0047BA',
        color: theme.palette.white,
        fontSize: '16px',
        padding: '0px',
        border: 0,
        paddingLeft: 15,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#242424',
        fontWeight: 'normal',
        '&:first-of-type': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.white,
    border: 0,
    paddingTop: '5px',
    height: '50px',
    '&:nth-of-type(1) td': {
        height: '60px',
        paddingTop: '15px',
    },
    '&:hover': {
        backgroundColor: '#E5F5FC',
    },
    // hide last border
    th: {
        marginLeft: 20,
    },
    '&:last-child td, &:last-child th': {},
}));
