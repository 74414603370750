import React from 'react';
import { Box, Divider } from '@mui/material';
import GithubOnboardingStyles from 'pages/github-onboarding/style';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/github-onboarding/store';

const MigrationReposDetails = () => {
    const [order] = useRecoilState(OrderPayload);

    return (
        <Box sx={GithubOnboardingStyles?.preview?.wrapper}>
            <Box sx={GithubOnboardingStyles?.preview?.heading}>Migrate Application Repos Details</Box>
            <Divider variant="horizontal" sx={GithubOnboardingStyles?.preview?.divder} />
            {order.reposToMigrate?.map((repo, index) => (
                <Box key={index} sx={GithubOnboardingStyles?.preview?.projectDetails?.value}>
                    {repo.githubName}
                </Box>
            ))}
        </Box>
    );
};

export default MigrationReposDetails;
