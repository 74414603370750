import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import DialogModal from 'components/dialog';
import SuccessImage from 'assets/images/success.png';
import { orderSuccessPopup, orderId, cart, cartCount } from 'pages/cart/store';
import { selectOrder } from 'pages/orders/store';
import { cartStyle } from 'pages/cart/style';
import { commonStyles as style } from 'assets/style';

const OrderSuccessPopup = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const isOrderSuccessPopupOpen = useRecoilValue(orderSuccessPopup);
    const [cmpOrderId] = useRecoilState(orderId);
    const [, setCart] = useRecoilState(cart);
    const [, setCartCount] = useRecoilState(cartCount);
    const setSelectedOrder = useSetRecoilState(selectOrder);

    const handleOrderIdOnClick = async () => {
        await setSelectedOrder({ orderId: cmpOrderId });
        history.push('/orders');
    };

    const handleUpdateCart = async () => {
        setIsLoading(true);
        const updatedCartSummary = await axios.get(endPoints.cart.getCart);
        if (updatedCartSummary?.data) {
            setCart(updatedCartSummary?.data?.cartSummary);
        } else {
            setCart([]);
        }
        const updatedCart = await axios.get(endPoints.cart.getCartCount);
        if (updatedCart?.data) {
            setCartCount(updatedCart?.data?.itemCount);
        } else {
            setCartCount(0);
        }
        setIsLoading(false);
    };
    return (
        <DialogModal open={isOrderSuccessPopupOpen} handleClose={() => {}}>
            <Box sx={cartStyle.submitOrder.wrapper}>
                <img src={SuccessImage} alt="" />
                <Typography gutterBottom sx={cartStyle.submitOrder.requsetTypography}>
                    Your Request is Submitted
                </Typography>
                <Typography gutterBottom sx={cartStyle.submitOrder.thankyouTypography}>
                    Thank you for using DevX Infra. Your order has been submitted successfully. Please go to
                    <Typography
                        component="span"
                        variant="body2"
                        sx={cartStyle.submitOrder.orderIdTypography}
                        onClick={() => {
                            handleUpdateCart();
                            handleOrderIdOnClick();
                        }}
                        data-testid="order-id"
                    >
                        {cmpOrderId?.join(', ')}
                    </Typography>
                    to review your order
                </Typography>
                <Box sx={cartStyle.submitOrder.newOrderBox}>
                    <Button
                        onClick={() => {
                            handleUpdateCart();
                            history.push('/services');
                        }}
                        disabled={isLoading}
                        // sx={cartStyle.submitOrder.newOrderBtn}
                        sx={{ ...style.primaryMediumButton, marginRight: '16px', width: 'auto' }}
                        disableElevation
                        variant="contained"
                        // color="cmpPrimary"
                        data-testid="place-new-order"
                    >
                        Place New Order
                    </Button>
                    <Button
                        onClick={() => {
                            handleUpdateCart();
                            history.push('/');
                        }}
                        component={Link}
                        to="/"
                        variant="outlined"
                        // color="cmpPrimary"
                        disableElevation
                        disabled={isLoading}
                        // sx={cartStyle.submitOrder?.backHome}
                        sx={{ ...style.secondaryMediumButton, width: 'auto' }}
                        data-testid="back"
                    >
                        Back to Home
                    </Button>
                </Box>
            </Box>
        </DialogModal>
    );
};
export default React.memo(OrderSuccessPopup);
