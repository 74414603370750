import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';

const DialogModal = (props) => {
    const { open, title, handleClose, children, width, contentStyle } = props;
    return (
        <Dialog data-testid="dialogModal" maxWidth={width} open={open} onClose={handleClose}>
            {title && (
                <DialogTitle sx={{ background: '#0047BA', color: '#ffffff' }}>
                    {title}
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent sx={{ ...contentStyle, border: (theme) => `10px solid ${theme.palette.border.$color_border_default}` }} dividers>
                {children}
            </DialogContent>
        </Dialog>
    );
};

DialogModal.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    children: PropTypes.element,
    width: PropTypes.string,
    handleClose: PropTypes.func,
    contentStyle: PropTypes.shape({}),
};

DialogModal.defaultProps = {
    title: undefined,
    open: false,
    width: 'sm',
    contentStyle: {},
    children: <span>content here</span>,
    handleClose: () => null,
};

export default DialogModal;
