import { styled } from '@mui/material/styles';
import { Paper, Tooltip, Button } from '@mui/material';

const dashboardStyle = {
    dashboard_wrapper: {
        display: 'flex',
        // width: { md: (theme) => theme.spacing(62), lg: (theme) => theme.spacing(182), xl: (theme) => theme.spacing(200) },
        width: '100%',
        overflowY: 'auto',
        // background: '#F8F8F8 0% 0% no-repeat padding-box',
    },
    box_wrapper: {
        // paddingTop: { md: (theme) => theme.spacing(2), lg: (theme) => theme.spacing(3) },
        width: '100%',
        // width: { md: (theme) => theme.spacing(62), lg: (theme) => theme.spacing(182), xl: (theme) => theme.spacing(200) },
        //    mx: (theme) => theme.spacing(10),
    },
    box_title: {
        height: '80px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderBottom: '1px solid #E0E0E0',
        paddingLeft: '49px',
        paddingRight: '49px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pb: (theme) => theme.spacing(1),
    },
    dashboard_title: {
        color: (theme) => theme.palette.black.dark,
        fontSize: (theme) => theme.spacing('30px'),
        fontWeight: 'medium',
        paddingTop: (theme) => theme.spacing('25px'),
    },
    dashboard_container: {
        flexGrow: 1,
        mt: (theme) => theme.spacing(3),
        pb: (theme) => theme.spacing(2),
        px: (theme) => theme.spacing('53px'),
        paddingTop: (theme) => theme.spacing('26px'),
    },
    chart: {
        chartPaper: {
            // padding: (theme) => theme.spacing(1)
            height: '350px',
        },
        chartTitle: {
            fontSize: (theme) => theme.spacing('20px'),
            color: (theme) => theme.palette.black.medium,
            py: (theme) => theme.spacing('4px'),
            paddingLeft: (theme) => theme.spacing('14px'),
            textTransform: 'uppercase',
            textAlign: 'left',
            // mb: (theme) => theme.spacing(1),
        },
    },
    card: {
        itemwrapper: { minHeight: '100px', width: 'auto' },
        title: { color: (theme) => theme.palette.white, fontWeight: 'medium', fontSize: { md: '30px', lg: '36px' } },
        text: { fontSize: (theme) => theme.spacing(0.5) },
        tooltiptext: { fontSize: (theme) => theme.spacing(0.1) },
        circularProgressColor: { color: 'inherit', height: '100px' },
        text_wrapper: { textAlign: 'center' },
    },
    ApplicationOnBoardStyle: {
        wrapper: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
        main: { paddingLeft: '-22px' },
        circularProgress_wrapper: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: 250 },
        chart_wrapper: {
            width: '100%',
            // width: { md: (theme) => theme.spacing(11zx2), lg: (theme) => theme.spacing(182), xl: (theme) => theme.spacing(200) },
            height: '280px',
            display: 'flex',
            overflowX: 'auto',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: 2,
            marginBottom: 2,
            '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
            'scrollbar-width': 'none' /* Firefox */,
            '::-webkit-scrollbar': {
                display: 'none' /* Safari and Chrome */,
            },
        },
        provisioned_resource: {
            width: '100%',
            // width: { md: (theme) => theme.spacing(11zx2), lg: (theme) => theme.spacing(182), xl: (theme) => theme.spacing(200) },
            // height: { md: '349px', lg: '361px' },
            display: 'flex',
            height: '280px',
            overflowY: 'auto',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: 2,
            marginBottom: 2,
            '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
            'scrollbar-width': 'none' /* Firefox */,
            // '::-webkit-scrollbar': {
            //     display: 'none' /* Safari and Chrome */,
            // },
        },
        composedChartStyles: {
            main: { width: 500, height: 250 },
            margin: {
                top: 10,
                right: 20,
                left: -10,
                bottom: 10,
            },
        },
        circularProg: { color: (theme) => theme.palette.blue.main },
    },
    orderCreationTrend: {
        margin: { top: 10, right: 10, left: -10, bottom: 10 },
        lineStyle: { backgroundColor: '#ffffff' },
        wraper: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: (theme) => theme.spacing(31) },
        circularProgress: { color: (theme) => theme.palette.blue.main },
    },
    provisionedServices: {
        wrapper: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: { md: '349px', lg: '360px' } },
        circularProgress: { color: (theme) => theme.palette.blue.main },
        gridStyle: {
            display: 'flex',
            flexDirection: 'column',
            padding: (theme) => theme.spacing(1),
            marginBottom: (theme) => theme.spacing(0.5),
        },
        boxStyle: { display: 'flex', alignItems: 'center' },
        gridItemStyle: { height: (theme) => theme.spacing(31), overflowY: 'scroll', marginRight: (theme) => theme.spacing(3) },
        typographyWrapper: { width: (theme) => theme.spacing(2.5), height: (theme) => theme.spacing(2.5), marginRight: (theme) => theme.spacing(1) },
        typographyStyle: { color: (theme) => theme.palette.blue[30], marginRight: '10px' },
        cutomtooltip: { width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(2) },
        cutomtooltipWrapper: {
            background: (theme) => theme.palette.white,
            justifyContent: 'flex-start',
            alignItems: 'center',
            px: (theme) => theme.spacing(0.5),
            marginRight: (theme) => theme.spacing(1),
            py: (theme) => theme.spacing(1),
        },
        reponsiveGridContainer: { display: 'flex', justifyContent: 'space-around', alignItems: 'center' },
        responsiveGridItem: { height: { md: '349px', lg: '361px' }, width: '100%' },
    },
    /* filterButton: {
        height: '38px',
        width: '100px',
        color: (theme) => theme.palette.blue.main,
        borderColor: (theme) => theme.palette.blue.main,
        border: '2px solid',
        '&:hover': {
            color: (theme) => theme.palette.cyan.main,
            borderColor: (theme) => theme.palette.cyan.main,
            border: '2px solid',
            backgroundColor: 'transparent !important',
        },
    },
    orderFilter: {
        container: {
            color: (theme) => theme.palette.white,
            borderColor: '#0063cc',
            border: '2px solid',
        },
        wrapper: {
            background: (theme) => theme.palette.white,
            width: 260,
            padding: 2,
            position: 'relative',
            top: '10px',
            marginRight: '10px',
        },
        arrow: {
            position: 'absolute',
            fontSize: 60,
            top: -34,
            left: 128,
            fill: (theme) => theme.palette.white,
            zIndex: 1,
        },
        filterIcon: { color: (theme) => theme.palette.white },
        closeIcon: { cursor: 'pointer', '&:hover': { color: '#f99c24' } },
        popper: {
            container: { display: 'flex', justifyContent: 'end' },
        },
    }, */
    dateRange: {
        calendarWrap: {
            padding: 2,
            position: 'relative',
            top: '10px',
            marginRight: '20px',
        },
    },
};
export const Item = styled(Paper)(({ theme }) => ({
    //   padding: theme.spacing(2.5, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#0047BA',
    color: theme.palette.white,

    padding: theme.spacing('10px'),
    // [theme.breakpoints.only('md')]: {

    // },
    // [theme.breakpoints.up('lg')]: {
    //     marginLeft: theme.spacing(0.5),
    // },
    // [theme.breakpoints.up('xl')]: {
    //     mx: theme.spacing(0.5),
    //     marginLeft: theme.spacing(3),
    // },
}));
export const CustomizedTooltip = styled(Tooltip)(({ theme }) => ({
    fontSize: theme.spacing(0.2),
}));
export const ColorButton = styled(Button)(() => ({
    background: '#0047BA',
    color: (theme) => theme.palette.white,
    '&:hover': {
        backgroundColor: '#0047BA',
        color: (theme) => theme.palette.white,
    },
}));
export default dashboardStyle;
