import React from 'react';
import { Box, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { orderPayload, isAppRegionRequired } from 'pages/provisioning/virtual-service/store';

export const Region = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [, setAppRegionReq] = useRecoilState(isAppRegionRequired);
    const [options, setOptions] = React.useState([]);

    const regionApi = useMutation(() => axios.get(`${endPoints.virtualService.regions}`));

    const setApplicationRegion = (region, regionDetails) => {
        setOrder({
            ...order,
            region,
            regionDetails,
            program: null,
            // department: null,
            aksCluster: null,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (region) => options.find((option) => region === option.value || region === option.data?.name);

    const handleOnSelectChange = (selected) => setApplicationRegion(selected.value, selected);

    React.useEffect(() => {
        if (regionApi?.isSuccess && regionApi?.data?.data) {
            setOptions(regionApi?.data?.data?.map((app) => ({ label: app?.displayName, value: app?.displayName, data: app })));
        } else {
            regionApi?.mutate();
        }
    }, [regionApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length > 0) setAppRegionReq(true);
        else setAppRegionReq(false);
        if (options && options?.length === 1 && !order?.region) {
            setOrder({ ...order, region: options?.[0].value });
        }
        if (options && options?.length && order?.region) {
            const region = isExistsInOptions(order?.region);
            setOrder({ ...order, region: region?.value });
        }
    }, [options]);

    React.useEffect(() => {
        if (order?.sector) {
            resetOptions();
            regionApi?.mutate();
        }
    }, [order?.sector]);

    React.useEffect(() => {
        if (!order?.region && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.region, order?.isTouched]);

    return (
        <>
            {/* {regionApi?.isSuccess && ( */}
            <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" gap={1}>
                    <Select
                        key="region"
                        dataTestId="app-region-autocomplete"
                        fullWidth
                        label="Region *"
                        value={order?.region || null}
                        options={options}
                        handleOnSelect={(value) => handleOnSelectChange(value)}
                        disabled={regionApi.isLoading}
                        isLoading={regionApi.isLoading}
                        isRequired={isTouched}
                    />
                </Box>
            </Grid>
            {/* )} */}
        </>
    );
};

export default React.memo(Region);
