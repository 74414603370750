/* eslint-disable import/no-named-default */

import { default as AvailableADOProject } from './ProjectComponents/AvailableADOProject';
import { default as AvailableClarityId } from './ProjectComponents/AvailableClarityId';
import { default as ClarityId } from './ProjectComponents/ClarityId';
import { default as ADOProjectName } from './ProjectComponents/ADOProjectName';
import { default as NewADOProjectName } from './ProjectComponents/NewADOProjectName';
import { default as Sector } from './ProjectComponents/Sector';
import { default as ADOPlatform } from './ProjectComponents/ADOPlatform';
import { default as ADOProjectType } from './ProjectComponents/ADOProjectType';

import { default as ApplicationName } from './ApplicationComponents/ApplicationName';
import { default as AppShortName } from './ApplicationComponents/AppShortName';
import { default as BusinessApplication } from './ApplicationComponents/BusinessApplication';
import { default as ApplicationService } from './ApplicationComponents/ApplicationService';
import { default as ApplicationOwner } from './ApplicationComponents/ApplicationOwner';

import { default as ServiceResources } from './ServiceResources';

import { default as AvailableServiceAccountQuestion } from './SonarqubeComponents/AvailableServiceAccountQuestion';
import { default as ServiceAccount } from './SonarqubeComponents/ServiceAccount';
import { default as OnboardQuestion } from './SonarqubeComponents/OnboardQuestion';
import { default as NewProjectOnboardQuestion } from './SonarqubeComponents/NewProjectOnboardQuestion';

import { default as SastScaScanOption } from './SastScaComponents/SastScaScanOption';
import { default as SASTOptions } from './SastScaComponents/Sast/SASTOptions';
import { default as SASTApplicationName } from './SastScaComponents/Sast/SASTApplicationName';
import { default as SASTApplicationNameSelect } from './SastScaComponents/Sast/SASTApplicationNameSelect';
import { default as SASTVersionSelect } from './SastScaComponents/Sast/SASTVersionSelect';
import { default as SASTVersion } from './SastScaComponents/Sast/SASTVersion';
import { default as SASTMember } from './ServiceMembers/SASTMember';

import { default as SCAOptions } from './SastScaComponents/Sca/SCAOptions';
import { default as OrganizationName } from './SastScaComponents/Sca/OrganizationName';
import { default as OrganizationNameSelect } from './SastScaComponents/Sca/OrganizationNameSelect';
import { default as SCARoles } from './SastScaComponents/Sca/SCARoles';
import { default as SCAMember } from './ServiceMembers/SCAMember';

const Forms = {
    AvailableADOProject,
    AvailableClarityId,
    ClarityId,
    ADOProjectName,
    NewADOProjectName,
    Sector,
    ADOPlatform,
    ADOProjectType,
    ApplicationName,
    AppShortName,
    BusinessApplication,
    ApplicationService,
    ApplicationOwner,
    ServiceResources,
    AvailableServiceAccountQuestion,
    ServiceAccount,
    OnboardQuestion,
    NewProjectOnboardQuestion,
    SastScaScanOption,
    SASTOptions,
    SASTApplicationName,
    SASTApplicationNameSelect,
    SASTVersionSelect,
    SASTVersion,
    SASTMember,
    SCAOptions,
    OrganizationName,
    OrganizationNameSelect,
    SCARoles,
    SCAMember,
};

export default Forms;
