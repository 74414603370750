import React from 'react';
import ErrorFallback from 'components/error-fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { PEPVIGIL } from 'pages/dlops-dashboard/utils/constant';
import { Item, style } from '../style';
import AddNewModel from './addNewModel';
import breadcrumbIcon from '../../../assets/images/dlopsImages/arrow_forward.svg';

const AddNewModelToExisitingProject = () => {
    const history = useHistory();
    const handleNavigation = () => {
        history.push('/pepvigil-projects');
        localStorage.removeItem('create_project');
    };
    const handleModelNavigation = () => {
        history.push('/pepvigil-registered-models');
    };
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {' '}
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        <Item sx={style.subHeader}>
                            <div
                                style={{
                                    marginLeft: '28px',
                                    marginTop: '8px',
                                    fontSize: '14px',
                                }}
                            >
                                <span>{PEPVIGIL}</span>
                                <img src={breadcrumbIcon} alt=" bredcrumb" style={style.breadcrumbIcon} />
                                <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleNavigation()}>
                                    Projects
                                </span>
                                <img src={breadcrumbIcon} alt=" bredcrumb" style={style.breadcrumbIcon} />
                                <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleModelNavigation()}>
                                    Models
                                </span>
                                <img src={breadcrumbIcon} alt=" bredcrumb" style={style.breadcrumbIcon} />
                                <span style={{ fontWeight: 'bold', color: '#005CBC' }}>Add New Model</span>
                            </div>
                        </Item>
                    </Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    top: '164px',
                    left: '27px',
                    width: '1500px',
                    height: '70px',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: '4px',
                    opacity: 1,
                    marginTop: '20px',
                    padding: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: '100px',
                }}
            >
                <Typography sx={{ fontSize: '18px', fontWeight: '700', color: '#0047BA' }}>Add a New Model</Typography>
            </Box>
            <AddNewModel />
        </ErrorBoundary>
    );
};

export default AddNewModelToExisitingProject;
