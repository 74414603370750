import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { Styles } from 'pages/mdt-onboarding/components/form-card/style';

const FormCard = ({ title, children }) => {
    return (
        <Box sx={Styles.formCard.paper}>
            {title && (
                <>
                    <Box sx={Styles.formCard.wrapper}>
                        <Typography sx={Styles.formCard.heading} variant="subtitle1">
                            <Stack direction="row">{title}</Stack>
                        </Typography>
                    </Box>
                    <Divider variant="middle" />
                </>
            )}
            <Box display="flex" gap={4} flexWrap="wrap" padding={3}>
                {children}
            </Box>
        </Box>
    );
};

FormCard.propTypes = {
    title: PropTypes.any,
    children: PropTypes.element,
};

FormCard.defaultProps = {
    title: undefined,
    children: <></>,
};

export default React.memo(FormCard);
