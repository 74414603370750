import { styled } from '@mui/material/styles';
import { TextField, RadioGroup, Radio } from '@mui/material';

const ProvisioningStyles = {
    card: {
        paper: {
            padding: (theme) => theme.spacing(2),
            background: (theme) => theme.palette.gray.lighter,
            height: (theme) => theme.spacing('100%'),
        },
        form: {
            '& > *': {
                marginBottom: (theme) => theme.spacing(2.5),
            },
        },

        box: { mb: (theme) => theme.spacing(0.375) },
        typography: { marginBottom: (theme) => theme.spacing(0.25) },
    },
    provisioning_wrapper: {
        // background: 'transparent linear-gradient(180deg, #3498DB 0%, #1A4C6E 100%) 0% 0% no-repeat padding-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: (theme) => theme.spacing('100%'),
        height: 'calc(100vh - 100px)',
    },
    circularProgressColor: { color: (theme) => theme.palette.black.dark },
    cleIntegration: {
        formControl: { marginLeft: '1px', height: '37.13px', justifyContent: 'center', width: '100% !important' },
        title: { color: '#0047BA', font: (theme) => theme.typography.fontSize.$font_xsmall_regular },
    },
    conjureIntegration: {
        formControl: { marginLeft: '1px', height: '37.13px', justifyContent: 'center', width: '100% !important' },
        title: { color: '#0047BA', font: (theme) => theme.typography.fontSize.$font_xsmall_regular },
    },
    DomainNameService: {
        formControl: { marginLeft: '1px', height: '37.13px', justifyContent: 'center', width: '100%' },
        title: { color: '#0047BA', font: (theme) => theme.typography.fontSize.$font_xsmall_regular },
        helpWrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        helpMsg: { flexGrow: 1, overflow: 'auto' },
    },
    EceIntegration: {
        formCtrl: { marginLeft: '1px', height: '37.13px', justifyContent: 'center', width: '100% !important' },
        title: { color: '#0047BA', font: (theme) => theme.typography.fontSize.$font_xsmall_regular },
    },
    fqdn: {
        width: (theme) => theme.spacing('100%'),
        '& .MuiFormHelperText-root': {
            marginTop: (theme) => theme.spacing('-2'),
        },
    },
    itldap: {
        width: (theme) => theme.spacing('100%'),
        '& .MuiFormHelperText-root': {
            marginTop: (theme) => theme.spacing('-2'),
        },
    },
    cartBtnWrapper: { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' },
    provisioningBox: {
        // background: 'transparent linear-gradient(180deg, #3498DB 0%, #1A4C6E 100%) 0% 0% no-repeat padding-box',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        width: (theme) => theme.spacing('100%'),
        height: (theme) => theme.spacing('100%'),
    },
    sspform_wrapper: { flexGrow: 1, mx: (theme) => theme.spacing(6.625) },
    ProvisioningHeader: {
        pageTitle: {
            color: (theme) => theme.palette.white,
            textTransform: 'uppercase',
            fontSize: (theme) => theme.spacing(2),
            mx: (theme) => theme.spacing(0.5),
        },
        header: {
            mx: (theme) => theme.spacing(6.625),
            mt: (theme) => theme.spacing(3.125),
        },
        gridContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        gridItem: { display: 'flex' },
        ImgSpan: { marginRight: '4px' },
        img: { width: 26 },
        catalogsApiLoadingSpan: {
            background: '#E0E0E0',
            width: 33,
            height: 33,
            marginRight: '4px',
            borderRadius: '50%',
        },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '24px', marginRight: '10px !important', marginLeft: '5px' },
        warningIcon: { fill: '#D78B95', height: (theme) => theme.spacing(3), width: (theme) => theme.spacing(3), mt: (theme) => theme.spacing('-3px') },
        errorAlert: {
            font: (theme) => theme.typography.fontSize.$font_small_regular,
            backgroundColor: '#AE172B',
            color: '#FFFFFF',
            display: 'flex ',
            alignItems: 'center ',
            justifyContent: 'center ',
            // fontSize: 14,
            '& .MuiAlert-action': {
                display: 'none',
            },
        },
    },
    disposal: {
        infoIcon: { color: (theme) => theme.palette.gray.main },
        helpText: { color: '#d40020' },
    },
    helpBox: {
        marginLeft: (theme) => theme.spacing(1),
    },
    serviceResources: {
        actionWrapper: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
        deleteWraper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
            // background: (theme) => theme.palette.white,
            marginRight: (theme) => theme.spacing(1),
            border: '2px solid #DB1E36',
            marginLeft: (theme) => theme.spacing(1),
        },
        deleteIconBtn: {
            fontSize: (theme) => theme.spacing(4.25),
            color: '#DB1E36',
            cursor: 'pointer',
            width: '16px',
            height: '16px',
        },
        btn: {
            width: (theme) => theme.spacing(13.75),
            height: (theme) => theme.spacing(4.375),
            textTransform: 'none',
        },
        outlinedBtn: {
            width: (theme) => theme.spacing(13.75),
            height: (theme) => theme.spacing(4.375),
            color: (theme) => theme.palette.blue.main,
            textTransform: 'none',
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.cyan.main,
                borderWidth: (theme) => theme.spacing(0.25),
                borderStyle: 'solid',
                borderColor: (theme) => theme.palette.cyan.main,
                background: 'transparent',
            },
        },
    },
    applicationSupportDl: {
        width: (theme) => theme.spacing('100%'),
        '& .MuiFormHelperText-root': {
            marginTop: (theme) => theme.spacing('-2'),
        },
    },
    serverRefresh: {
        textField: {
            input: {
                '&::placeholder': {
                    textAlign: { md: 'left', lg: 'left' },
                    fontSize: { md: (theme) => theme.spacing(1.625), lg: (theme) => theme.spacing(1.75) },
                    color: '#707070',
                    opacity: (theme) => theme.spacing(1),
                },
            },
            '& .MuiOutlinedInput-root': {
                display: { md: 'flex' },
                flexDirection: { md: 'row', lg: 'row' },
            },
        },
        radioGroup: {
            display: 'flex',
            justifyContent: 'right',
            width: 'inherit',
            fontSize: { md: (theme) => theme.spacing(1.25) },
            mr: { md: (theme) => theme.spacing(-2.25) },
            '& .MuiSvgIcon-root': {
                fontSize: { md: (theme) => theme.spacing(1.875), lg: (theme) => theme.spacing(2.25) },
            },
        },
        radioBtnNo: {
            '& .MuiSvgIcon-root': {
                fontSize: { md: (theme) => theme.spacing(1.875), lg: (theme) => theme.spacing(2.25) },
            },
        },
        radioBtnNoForm: {
            '& .css-j204z7-MuiFormControlLabel-root label': {
                marginRight: (theme) => theme.spacing(1.25),
            },
        },
    },
    previewStackBtn: {
        width: (theme) => theme.spacing(18.75),
        marginBottom: (theme) => theme.spacing(1.75),
        color: (theme) => theme.palette.blue.main,
        textTransform: 'none',
        borderWidth: (theme) => theme.spacing(0.25),
        borderStyle: 'solid',
        borderColor: (theme) => theme.palette.blue.main,
        '&:hover': {
            color: (theme) => theme.palette.cyan.main,
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.cyan.main,
            background: 'transparent',
        },
    },
    cartBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
    },
    TotEstCost: {
        wrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        totEstMessage: {
            fontSize: (theme) => theme.spacing(1.75),
            background: 'linear-gradient(to top, #29323C, #485563)',
            color: (theme) => theme.palette.cyan['90'],
            borderRadius: (theme) => theme.spacing(0.5),
            marginRight: (theme) => theme.spacing(0.35),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: (theme) => theme.spacing(0.5),
            py: (theme) => theme.spacing(0.3),
        },
        totEstCost: {
            fontSize: (theme) => theme.spacing(2),
            fontWeight: (theme) => theme.typography.fontWeightBold,
        },
        info: { color: (theme) => theme.palette.gray.light, marginRight: (theme) => theme.spacing(0.35) },
        submitOrder: {
            background: (theme) => theme.palette.orange.main,
            color: (theme) => theme.palette.white,
            fontSize: (theme) => theme.spacing(2),
            width: (theme) => theme.spacing(18.75),
            height: (theme) => theme.spacing(4.375),
        },
        alert: {
            mb: (theme) => theme.spacing(3.125),
            background: (theme) => theme.palette.red.main,
            color: (theme) => theme.palette.white,
            '& .MuiAlert-icon': {
                color: (theme) => theme.palette.white,
            },
        },
        welcomeAlert: {
            mt: (theme) => theme.spacing(3.125),
            background: (theme) => theme.palette.blue[30],
            color: (theme) => theme.palette.white,
            '& .MuiAlert-icon': {
                color: (theme) => theme.palette.white,
            },
        },
    },
    Dialog: {
        title: { font: (theme) => theme.typography.fontSize.$font_heading_h5_bold, color: '#000000', paddingTop: '15px', paddingBottom: '10px' },
        msgDiv: { font: (theme) => theme.typography.fontSize.$font_xsmall_regular, padding: '5px 11px' },
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: '428px',
            height: '225px',
            paddingTop: '20px',
            paddingBottom: '5px',
        },
        IconColor: '#C02223',
        icon: { fill: '#C02223', fontSize: '60px' },
        AzIcon: '#d02424',
        backBtnBox: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2), paddingBottom: '5px' },
        backBtn: {
            background: 'transparent',
            color: (theme) => theme.palette.interAction.$color_interaction_active,
            textTransform: 'none',
            borderRadius: (theme) => theme.palette.border.$border_radius,
            border: (theme) => `1px solid ${theme.palette.border.$color_border_secondary_button}`,
            padding: '3px 15px',
            font: (theme) => `${theme.typography.fontSize.$font_xsmall_caps} !important`,
            height: '32px',
            '&:hover': {
                color: (theme) => theme.palette.button.$color_button_primary_hover,
                border: (theme) => `1px solid ${theme.palette.background.$color_background_button_primary_hover} !important`,
                background: 'transparent',
            },
            '&:active': {
                color: (theme) => theme.palette.button.$color_button_primary_active,
                border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_active} !important`,
            },
        },
        AgreeBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            padding: '3px 15px',
            font: (theme) => `${theme.typography.fontSize.$font_xsmall_caps} !important`,
            height: '32px',
            textTransform: 'none',
            borderRadius: (theme) => theme.palette.border.$border_radius,
            backgroundColor: (theme) => theme.palette.background.$color_background_button_primary,
            '&:hover': {
                backgroundColor: (theme) => theme.palette.background.$color_background_button_primary_hover,
            },
            '&:active': {
                backgroundColor: (theme) => theme.palette.button.$color_button_primary_active,
            },
        },
    },
    application: {
        stack: { direction: 'row', alignItems: 'center', justifyContent: 'center' },
    },
    Sector: {
        box: {
            bgcolor: (theme) => theme.palette.white,
        },
    },
    box: {
        bgcolor: (theme) => theme.palette.white,
    },
    stack: {
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    infoIcon: { color: '#0047BA', fontSize: '18px', marginTop: '5px' },
    costCenter: { width: '100%' },
    costSplit: {
        costSplitWrap: {
            display: 'flex',
            // alignItems: 'center',
            paddingTop: (theme) => theme.spacing(2),
            paddingBottom: (theme) => theme.spacing(2),
            paddingLeft: (theme) => theme.spacing(3),
            paddingRight: (theme) => theme.spacing(3),
            flexDirection: 'column',
            // borderBottom: (theme) => `1px solid ${theme.palette.gray.low}`,
            background: 'linear-gradient(to right, #093028, #237A57)',
            width: '100%',
            marginTop: (theme) => theme.spacing(1),
        },
        flexRow: {
            display: 'flex',
            width: '100%',
            marginBottom: (theme) => theme.spacing(1),
        },
        costSplitBlock: {
            width: '30%',
            display: 'flex',
            // justifyContent: 'space-between',
            '&:first-child': {
                marginLeft: '-0.5rem',
            },
            '&:not(:first-child)': {
                paddingLeft: '0.5rem',
                // marginLeft: '-0.5rem',
            },
            '&:not(:last-child)': {
                paddingLeft: '0.5rem',
            },
        },
        costSplitBlockLeft: {
            display: 'flex',
            flexDirection: 'column',
        },
        costSplitTitle: {
            fontSize: (theme) => theme.spacing('1rem'),
            color: (theme) => theme.palette.white,
        },
        costSplitSubTitle: {
            fontSize: '0.875rem',
            color: (theme) => theme.palette.white,
        },
        estimatedCost: {
            fontSize: (theme) => theme.spacing('1.5rem'),
            marginLeft: 'auto',
            fontWeight: (theme) => theme.typography.fontWeightMedium,
            marginRight: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.white,
        },
        noteWrapper: { display: 'flex', marginTop: (theme) => theme.spacing(1), alignItems: 'flex-start' },
        divider: { backgroundColor: (theme) => theme.palette.blue.lighter },
        loadingBox: { display: 'flex', width: '100%', height: (theme) => theme.spacing(10), alignItems: 'center', justifyContent: 'center' },
        circularProgress: { color: (theme) => theme.palette.white },
        notedevider: { width: '100%', backgroundColor: (theme) => theme.palette.blue.lighter, marginBottom: (theme) => theme.spacing(1) },
    },
    envContainer: {
        // badge: { marginRight: (theme) => theme.spacing(2) },
        samBtn: {
            marginRight: (theme) => theme.spacing(2),
            color: (theme) => theme.palette.blue.main,
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            textTransform: 'none',
            borderColor: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.cyan.main,
                borderWidth: (theme) => theme.spacing(0.25),
                borderStyle: 'solid',
                borderColor: (theme) => theme.palette.cyan.main,
                background: 'transparent',
            },
        },
        applicationBtn: {
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',
            textTransform: 'none',
            borderColor: (theme) => theme.palette.cyan.main,
            color: (theme) => theme.palette.cyan[70],
        },
        outlinedBtn: {
            color: (theme) => theme.palette.blue.main,
            textTransform: 'none',
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.cyan.main,
                borderWidth: (theme) => theme.spacing(0.25),
                borderStyle: 'solid',
                borderColor: (theme) => theme.palette.cyan.main,
                background: 'transparent',
            },
        },
        tagBtn: { textTransform: 'none' },
    },
    submitOrder: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '20px', paddingBottom: '5px' },
        orderIdTypo: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        actionWrapper: { textAlign: 'center', my: (theme) => theme.spacing(0.25), py: (theme) => theme.spacing(2) },
        newOrder: { marginRight: (theme) => theme.spacing(2), width: '180px', textTransform: 'none' },
        checkOutBtn: {
            width: '180px',
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                borderWidth: '2px',
                border: '2px solid #00A0DD',
                backgroundColor: 'white',
            },
        },
        message: { font: (theme) => theme.typography.fontSize.$font_heading_h4_medium, color: (theme) => theme.palette.black.dark },
        description: { width: '500px', font: (theme) => theme.typography.fontSize.$font_body_regular, color: (theme) => theme.palette.black.dark },
        iconWrapper: {
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
        },
    },
    tags: {
        wrapper: { minWidth: (theme) => theme.spacing(75), width: (theme) => theme.spacing(100), display: 'flex', flexDirection: 'column', height: '100%' },
        contentWrapper: { flexGrow: 1, px: (theme) => theme.spacing(2) },
        box: { display: 'flex', flexDirection: 'column' },
        titlebox: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(2) },
        name: { fontWeight: 'bold', width: '31%' },
        colon: { width: (theme) => theme.spacing(0.25), mx: 1.5 },
        val: { width: (theme) => theme.spacing(50), fontWeight: 'bold', flexGrow: 1 },
        dataWrapper: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(1) },
        dataNameWrapper: {
            display: 'flex',
            alignItems: 'center',
            width: '30%',
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',

            borderRadius: (theme) => theme.spacing(0.25),
            borderColor: 'gray.lowMedium',
            py: (theme) => theme.spacing(0.5),
            px: (theme) => theme.spacing(1.5),
        },
        dataColan: { width: (theme) => theme.spacing(0.75), mx: (theme) => theme.spacing(1) },
        dataValueBoxWrapper: { flexGrow: 1 },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(0.25) },
        resetBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#6E6F6F',
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
        activeSelectField: {
            background: 'transparent',
            '& label.MuiFormLabel-filled,label.MuiInputLabel-shrink': {
                // background: '#00709B',
                color: '#0047BA',
                padding: '0 2px',
            },
            '& label.Mui-disabled+div fieldset': {
                border: ' 1px solid #E9E9E9',
            },
            '& label.MuiFormLabel-filled+div fieldset': {
                border: ' 1px solid #0047BA',
            },
            '& label.Mui-focused+div fieldset': {
                border: ' 2px solid #0047BA !important',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0047BA',
            },
        },
    },

    preview: {
        catalogDetails: { ml: '39px' },
        wrapper: { width: '100%', bgcolor: 'background.paper' },
        tabList: {
            background: '#E6E6E6 0% 0% no-repeat padding-box',
            '& .MuiTabList-flexContainer': {
                display: 'flex',
                justifyContent: 'space-evenly',
            },
            flexGrow: 1,
        },
        tab: {
            minWidth: '150px',
            color: '#1E1E1E',
            maxWidth: 'unset',
            flexGrow: 1,
            fontSize: 14,
            borderRight: '2px solid #ffffff',
            '&.Mui-selected': {
                outline: 'none',
                background: '#0047BA 0% 0% no-repeat padding-box',
                color: '#ffffff',
            },
        },
        editBtn: {
            width: (theme) => theme.spacing(13.75),
            height: (theme) => theme.spacing(4.375),
            textTransform: 'none',
            border: '2px solid #0047BA',
            marginTop: '10px',
            marginLeft: '44px',
        },
        griditem: { color: '#003182' },
        gridContainer: { marginBottom: '20px !important' },
        grid: { paddingLeft: (theme) => theme.spacing(4) },
        connectionTitle: { font: (theme) => theme.typography.fontSize.$font_heading_h6_medium, color: '#0047BA', marginLeft: 1, MarginTop: 1, MarginBottom: 1 },
    },

    dialog: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' },
        icon: { color: '#d02424' },
        backBtn: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2) },
        AgreeBtn: { marginRight: (theme) => theme.spacing(1.25), textTransform: 'none' },
        noBtn: {
            color: '#6E6F6F',
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
    },
};
export default ProvisioningStyles;

export const ServerTextField = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: theme?.palette?.blue?.main,
    },
    '& label.MuiFormLabel-filled': {
        // background: '#00709B',
        color: theme?.palette?.blue?.main,
        padding: theme?.spacing(0.2),
    },
    '& label': {
        // color: '#AFB8CC',
        // borderRadius: 10,
    },
    '& .MuiInputBase-input': {
        color: theme?.palette?.blue?.dark,
        background: theme?.palette?.white,
        '&.Mui-disabled': {
            background: 'transparent',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme?.palette?.gray?.light,
            color: '#AFB8CC',
        },
        '&:hover fieldset': {
            borderColor: theme?.palette?.gray?.light,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme?.palette?.gray?.light,
        },
    },
    input: {
        '&::placeholder': {
            [theme.breakpoints.only('md')]: {
                fontSize: theme.spacing(1.625),
                textAlign: 'left',
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: theme.spacing(1.75),
                textAlign: 'left',
            },
            color: '#707070',
            opacity: 1,
        },
    },
}));
export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
