/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Switch as SwitchMui } from '@mui/material';

export const CssSwitchField = styled(SwitchMui)(({ theme }) => ({
    '& .Mui-checked': {
        '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.blue.strongDark,
        },
        color: `${theme.palette.blue.strongDark} !important`,
        transform: 'translateX(26px) !important',
    },
    '& .MuiSwitch-switchBase': {
        padding: '1px',
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#ffffff',
        // width: '19px',
        width: '18px',
        height: '18px',
        margin: '1px',
        borderRadius: '50%',
        position: 'relative',
        top: '1px',
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#818181',
        opacity: '1 !important',
        '&:after, &:before': {
            color: 'white',
            fontSize: '10px',
            position: 'absolute',
            // top: '5px',
            top: '2px',
        },
        '&:after': {
            // content: "'ON'",
            left: '7px',
        },
        '&:before': {
            // content: "'OFF'",
            right: '3px',
        },
        borderRadius: '3px',
    },
}));

const Switch = (props) => {
    const { handleChange, ...rest } = props;
    return (
        <CssSwitchField
            data-testid="switch"
            // sx={{ width: '49px', height: '21px', padding: '0px' }}
            sx={{ width: '48px', height: '24px', padding: '0px', borderRadius: '200px' }}
            onChange={handleChange}
            {...rest}
        />
    );
};

Switch.propTypes = {
    handleChange: PropTypes.func,
};

Switch.defaultProps = {
    handleChange: undefined,
};

export default Switch;
