import React from 'react';
import { FormControl, FormControlLabel, Typography, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import Btn from 'components/button';
import { OrderPayload, toggleModal, isPrivilegedADGroupValid } from 'pages/reference-implementation/provisioning/RI002/store';
import riStyle, { CustomRadioGroup, CustomRadio } from 'pages/reference-implementation/Style';
import FormCard from 'pages/reference-implementation/components/form-card';
import PrivilegedADGroupOwner from './PrivilegedADGroupOwner';

const PrivilegedADGroup = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [modal, setModal] = useRecoilState(toggleModal);
    const [, setPrivilegedADGroupValid] = useRecoilState(isPrivilegedADGroupValid);

    const setPrivilegedADGroup = (privilegedADGroup) => {
        setOrder({
            ...order,
            privilegedADGroup,
            privilegedADGroupOwner: null,
            privilegedADGroupOwnerDetails: null,
            privilegedADGroupList: [{ contributorValue: '' }],
        });
        setPrivilegedADGroupValid(false);
    };
    const handleOnSelectChange = (selected) => {
        setPrivilegedADGroup(selected);
    };
    const getButtonLabel = () => {
        let btnLabel = 'Select Privileged Group(s)';
        if (order?.privilegedADGroupList?.[0]?.contributorValue?.length === 0) {
            btnLabel = 'Select Privileged Group(s)';
        } else {
            btnLabel = `You Selected (${order?.privilegedADGroupList?.length}) Privileged ${order?.privilegedADGroupList?.length === 1 ? 'Group' : 'Groups'}`;
        }
        return btnLabel;
    };
    return (
        <FormCard title="Access Management">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={riStyle?.form?.accessMgmt?.heading}>PRIVILEGED GROUPS</Typography>
                    <Typography my="10px" sx={riStyle?.form?.accessMgmt?.label}>
                        Do you have a Privileged Active Directory Group
                    </Typography>
                    <FormControl>
                        <CustomRadioGroup
                            aria-label="PrivilegedADGroup"
                            name="privilegedadgroupRadioBtnGroup"
                            row
                            value={order?.privilegedADGroup || null}
                            onChange={(event, value) => handleOnSelectChange(value)}
                        >
                            <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes, I do" />
                            <FormControlLabel
                                value="No"
                                sx={{
                                    marginLeft: '60px',
                                }}
                                control={<CustomRadio />}
                                label="No, I don't"
                            />
                        </CustomRadioGroup>
                    </FormControl>
                </Grid>
                {order?.privilegedADGroup === 'Yes' && (
                    <Grid item xs={12} sm={6}>
                        <Btn
                            className="btn-disabled custom-btn"
                            fullWidth
                            onClick={() => {
                                setModal({
                                    ...modal,
                                    isPrivilegedADGroupOpen: true,
                                });
                            }}
                            size="small"
                            variant="contained"
                            data-testid="add-con"
                            sx={riStyle.form.privilegedButton}
                        >
                            {getButtonLabel()}
                        </Btn>
                    </Grid>
                )}
                {order?.privilegedADGroup === 'No' && (
                    <Grid item xs={12} sm={6}>
                        <PrivilegedADGroupOwner />
                    </Grid>
                )}
            </Grid>
        </FormCard>
    );
};

export default PrivilegedADGroup;
