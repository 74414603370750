import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Stack, Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Empty from 'components/empty';
import landingStyle from 'pages/landing-new/style';
import { riCatalogDialog, riSelectedCatalog } from 'pages/reference-implementation/store';
import { isRIEnabled } from 'utils';

const Banner = React.lazy(() => import('pages/landing-new/components/Banner'));
const Roles = React.lazy(() => import('pages/landing-new/components/roles'));
const RiCatalogService = React.lazy(() => import('pages/landing-new/components/RICatalogService'));
const CatalogService = React.lazy(() => import('pages/landing-new/components/CatalogService'));
const CustomizedDialogs = React.lazy(() => import('pages/reference-implementation/components/dialog'));

const LandingPageNew = () => {
    const [isOpen, setIsOpen] = useRecoilState(riCatalogDialog);
    const [selectedCatalog] = useRecoilState(riSelectedCatalog);

    return (
        <>
            <Helmet>
                <title>Landing Page - DevX Infra</title>
            </Helmet>
            <Suspense
                fallback={
                    <Stack sx={landingStyle?.loadingStack}>
                        <Empty color="#000000" isLoading />
                    </Stack>
                }
            >
                <Box data-testid="landing-page-new" sx={landingStyle.landingWraper}>
                    <Banner />
                    {isRIEnabled() && <RiCatalogService />}
                    <CatalogService />
                    <Roles />
                    <CustomizedDialogs
                        isOpen={isOpen}
                        setOpen={setIsOpen}
                        rid={selectedCatalog?.['reference-implementation-id']}
                        title={selectedCatalog?.name}
                        provisioningPath={`${selectedCatalog?.provisioningPath}/${selectedCatalog?.['reference-implementation-id']}`}
                        description={selectedCatalog?.description}
                        helpUrl={selectedCatalog?.['help-link']}
                        tags={selectedCatalog?.tags}
                        // archImg={selectedCatalog?.archImg}
                    />
                </Box>
            </Suspense>
        </>
    );
};

export default LandingPageNew;
