const PreviewStyles = {
    previewHeaderText1: { font: (theme) => theme.typography.fontSize.$font_heading_h6_bold, color: '#021D49' },
    previewHeaderText2: { font: (theme) => theme.typography.fontSize.$font_body_regular, color: '#021D49' },
    card: {
        boxShadow: '0px 0px 2px #0000003E',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        marginTop: 2,
        padding: '15px 15px 15px 25px',
    },
    generalInfo: { font: (theme) => theme.typography.fontSize.$font_heading_h6_medium, color: '#000', letterSpacing: '-0.14px ' },
    generalWrapper: { display: 'flex', flexDirection: 'column', marginTop: '-5px' },
    divider: { color: '#707070', marginTop: 1, marginBottom: 1 },
    keyText: {
        font: (theme) => theme.typography.fontSize.$font_body_medium,
        // fontSize: '16px',
        color: '#000',

        letterSpacing: '0px ',
    },
    valueText: {
        font: (theme) => theme.typography.fontSize.$font_small_regular,
        // fontSize: '14px',
        color: '#000',
        // fontFamily: 'SansRegular',
        letterSpacing: '0px ',
        wordWrap: 'break-word',
    },
    vsCard: {
        boxShadow: '0px 0px 2px #0000003E',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        opacity: 1,
        padding: '24px 0 10px 15px',
        marginBottom: 1.5,
    },
    vsType: {
        font: (theme) => theme.typography.fontSize.$font_heading_h6_bold,
        // fontSize: '18px',
        color: '#000',
        // fontFamily: 'SansBold',
        letterSpacing: '-0.14px',
        pb: 1,
    },
    vsSubType: {
        font: (theme) => theme.typography.fontSize.$font_body_bold,
        // fontSize: '16px',
        color: '#1E1E1E',
        // fontFamily: 'SansBold',
        letterSpacing: '0px',
    },
    vsTypeHeaders: {
        font: (theme) => theme.typography.fontSize.$font_body_medium,
        // fontSize: '16px',
        color: '#1378D4',

        letterSpacing: '0',
        paddingBottom: 2,
        textAlign: 'left',
        width: '33.33%',
    },
    previewBtn: {
        font: (theme) => `${theme.typography.fontSize.$font_small_medium}`,
        borderRadius: '4px',
        // fontSize: '14px !important',

        textTransform: 'none',
    },
};
export default PreviewStyles;
