export const Styles = {
    formCard: {
        paper: {
            height: '100%',
            borderRadius: (theme) => theme.spacing(0.75),
            marginBottom: (theme) => theme.spacing(3.125),
            border: '1px solid #E0E0E0',
        },
        column: {
            padding: (theme) => theme.spacing(2),
            position: 'relative',
        },
        heading: {
            color: (theme) => theme?.palette?.black?.dark,
            fontSize: (theme) => theme.spacing(2.5),
            fontWeight: 'medium',
        },
        wrapper: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: (theme) => theme.spacing(2), py: (theme) => theme.spacing(1) },
    },
};

export default Styles;
