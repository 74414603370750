/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Badge, Stack } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import SsoOktaStyles from 'pages/sso-okta/style';
import { OrderPayload, toggleModels } from 'pages/sso-okta/store';
import Btn from 'components/button';
import { LoginUrl } from './LoginUrl';

const LoginUrlBtn = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleModels);
    const order = useRecoilValue(OrderPayload);
    return (
        <>
            <Stack direction="row" spacing={1} sx={{ ...SsoOktaStyles.modalBtnStyle.stack, justifyContent: 'space-between' }}>
                <Box>Login URL *</Box>
                <Box sx={{ marginTop: '7px !important' }}>
                    <Badge sx={{ width: 'fit-content' }} color="error" variant={order?.LoginUrl?.[0]?.value.length === 0 ? 'dot' : 'standard'}>
                        <Btn
                            className="btn-disabled custom-btn"
                            fullWidth
                            onClick={() => setIsOpen({ ...isOpen, isLoginModelOpen: true })}
                            size="small"
                            sx={SsoOktaStyles.modalBtnStyle.modalBtn}
                            variant="contained"
                            data-testid="add-login-url"
                        >
                            Add
                        </Btn>
                    </Badge>
                </Box>
            </Stack>
            <LoginUrl index={0} />
        </>
    );
};

export default LoginUrlBtn;
