import React from 'react';
import { Grid, Stack } from '@mui/material';
import Form from 'pages/service-account-creation/components/Form';
import FormCard from 'pages/service-account-creation/components/form-card';
import { useRecoilState } from 'recoil';
import { SQLServer } from 'pages/service-account-creation/store';

const ProjectForm = () => {
    const [isSQLServer] = useRecoilState(SQLServer);
    return (
        <Grid>
            <Grid item md={8} borderRadius={1.5} gap={1}>
                <FormCard title="Application" gap={0} margin={2}>
                    <Grid item container spacing={2} sx={{ mt: 2, mb: 3 }}>
                        <Grid item md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.Sector />
                            </Stack>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.ApplicationName />
                            </Stack>

                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.Environments />
                            </Stack>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.ApplicationService />
                            </Stack>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.ApplicationShortName />
                            </Stack>
                            {isSQLServer && (
                                <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                    <Form.SQLServerName />
                                </Stack>
                            )}
                        </Grid>
                    </Grid>
                </FormCard>
            </Grid>
            <Grid item md={8} borderRadius={1.5} gap={1} mt={2}>
                <FormCard title="Resource" gap={0} margin={2}>
                    <Grid item container spacing={1} sx={{ mt: 2, mb: 3 }}>
                        <Grid item md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.AccountType />
                            </Stack>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={2}>
                                <Form.AccountOwner />
                            </Stack>
                        </Grid>
                    </Grid>
                </FormCard>
            </Grid>
        </Grid>
    );
};

export default ProjectForm;
