import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import { convertToTitleCase, toDateTime } from 'utils';
import mappingLabel from 'utils/mappingLabel';

const LabelValue = (props) => {
    const { label, value, isCamelToTitle, width, sx, children, istextTransform, labelFlex } = props;
    const newLable = isCamelToTitle && !['DR Priority', ...Object.values(mappingLabel)].includes(label) ? convertToTitleCase(label) : label;
    const dateTimeRegex = /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/;

    const [assigneeHoverStatus, setAssigneeHover] = useState(false);

    const assigneeRef = useRef();
    const compareSize = () => {
        const compareAssignee = assigneeRef.current.scrollWidth > assigneeRef.current.clientWidth;
        setAssigneeHover(compareAssignee);
    };
    useEffect(() => {
        if (label) {
            compareSize();
            window.addEventListener('resize', compareSize);
        }
    }, [label]);

    // remove resize listener again on "componentWillUnmount"
    useEffect(
        () => () => {
            window.removeEventListener('resize', compareSize);
        },
        []
    );
    return (
        <Box data-testid="key_label" sx={sx} display="flex" className="key" flexWrap="wrap">
            <Tooltip
                interactive="true"
                disableHoverListener={!assigneeHoverStatus}
                title={newLable}
                componentsProps={{
                    tooltip: { sx: { backgroundColor: '#272727', fontSize: '10px' } },
                    arrow: { sx: { color: '#272727' } },
                }}
            >
                <Box
                    className="label"
                    ref={assigneeRef}
                    width={width}
                    flex={labelFlex}
                    style={{
                        textTransform: `${istextTransform ? 'capitalize' : ''}`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {newLable}:
                </Box>
            </Tooltip>
            <Box className="value" sx={{ width: 400, wordBreak: 'break-all' }} flex={3}>
                {!children && (dateTimeRegex.test(value) ? toDateTime(value) : value || '-')}
                {children && <>{children}</>}
            </Box>
        </Box>
    );
};

LabelValue.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    isCamelToTitle: PropTypes.bool,
    width: PropTypes.number,
    labelFlex: PropTypes.number,
    children: PropTypes.element,
    sx: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
    istextTransform: PropTypes.bool,
};

LabelValue.defaultProps = {
    value: '-',
    isCamelToTitle: true,
    width: 200,
    labelFlex: 1,
    children: null,
    sx: { padding: 1 },
    istextTransform: true,
};

export default React.memo(LabelValue);
