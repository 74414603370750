import React from 'react';
import { Grid, Badge, Table, TableBody, TableContainer, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useRecoilState } from 'recoil';
import Btn from 'components/button';
import { OrderPayload, toggleModal } from 'pages/github-onboarding/store';
import { commonStyles as style } from 'assets/style';

const ServiceResources = () => {
    const [order] = useRecoilState(OrderPayload);
    const [isOpen, setIsOpen] = useRecoilState(toggleModal);

    const isConfigEnable =
        order.customProperties.businessApplication &&
        order.customProperties.applicationService &&
        order.customProperties.capability &&
        order.customProperties.cmpHCode;

    return (
        <Grid container spacing={2} data-testid="service-resources">
            <Typography sx={{ padding: 2, display: 'flex', alignItems: 'center' }} variant="subtitle2">
                <InfoOutlinedIcon sx={{ fontSize: 16, marginRight: 1, marginBottom: '2px', color: '#1976d2' }} />
                Select services that are required for your project
            </Typography>
            <TableContainer sx={{ paddingLeft: 2 }}>
                <Table size="small">
                    <TableBody style={{ width: '100%' }}>
                        <TableRow>
                            <TableCell align="left" style={{ width: '30%' }}>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Migrate application repos
                                </Typography>
                            </TableCell>
                            <TableCell align="right" style={{ width: '20%', padding: '6px 5px' }}>
                                <Badge color="error" sx={style.tagBadge} variant="standard">
                                    <Btn
                                        size="small"
                                        disabled={!isConfigEnable}
                                        onClick={() => setIsOpen({ ...isOpen, isReposToMigrateOpen: true })}
                                        variant="contained"
                                        data-testid="configure-btn"
                                        className="btn-disabled"
                                        sx={style.primaryMediumButton}
                                    >
                                        CONFIGURE
                                    </Btn>
                                </Badge>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" style={{ width: '30%' }}>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    User access management
                                </Typography>
                            </TableCell>
                            <TableCell align="right" style={{ width: '20%', padding: '6px 5px' }}>
                                <Badge color="error" sx={style.tagBadge} variant="standard">
                                    <Btn
                                        size="small"
                                        disabled={!isConfigEnable}
                                        onClick={() => setIsOpen({ ...isOpen, isTeamsAndMembersOpen: true })}
                                        variant="contained"
                                        data-testid="configure-btn"
                                        className="btn-disabled"
                                        sx={style.primaryMediumButton}
                                    >
                                        CONFIGURE
                                    </Btn>
                                </Badge>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" style={{ width: '30%' }}>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Repo access control
                                </Typography>
                            </TableCell>
                            <TableCell align="right" style={{ width: '20%', padding: '6px 5px' }}>
                                <Badge color="error" sx={style.tagBadge} variant="standard">
                                    <Btn
                                        size="small"
                                        disabled={!isConfigEnable}
                                        onClick={() => setIsOpen({ ...isOpen, isAssignReposOpen: true })}
                                        variant="contained"
                                        data-testid="configure-btn"
                                        className="btn-disabled"
                                        sx={style.primaryMediumButton}
                                    >
                                        CONFIGURE
                                    </Btn>
                                </Badge>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default React.memo(ServiceResources);
