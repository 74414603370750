import React from 'react';
import { Box } from '@mui/material';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { OrderPayload } from 'pages/project-onboarding/store';

const OrganizationNameSelect = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState(order?.organizationName || '');
    const [options, setOptions] = React.useState([]);

    const organizationNameApi = useMutation(() =>
        axios.get(
            `${endPoints.day2Operation.ProjectOnboarding.Jwt.organizationName
                .replace('{sector}', order?.sector?.toLowerCase())
                .replace('{orgName}', ccInputValue)}`
        )
    );
    const setOrganizationName = (organization) => {
        setOrder({
            ...order,
            organizationName: organization?.label,
            organizationNameDetails: organization?.data,
        });
    };
    const resetOptions = () => {
        setOptions([]);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                organizationNameApi.mutate();
            }, 1000),
        []
    );

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (organizationNameApi?.isSuccess) {
            if (organizationNameApi?.data?.data?.teamNames) {
                const combinedValue = organizationNameApi?.data?.data?.teamNames?.map((app) => ({
                    label: `${app.name}`,
                    value: `${app.name}`,
                    data: app,
                }));
                setOptions(combinedValue);
            }
        }
    }, [organizationNameApi?.isSuccess]);

    React.useEffect(() => {
        if (value === null) {
            resetOptions();
        }
        if (value !== null && value?.value) {
            setOrganizationName(value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.organizationName && order?.isTouched) setIsTouched(true);
    }, [order?.organizationName, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="team-name-autocomplete"
                key="organizationName"
                loading={organizationNameApi?.isLoading}
                label="Organization Name *"
                placeholder="Type to search"
                value={order?.organizationName || null}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue || '');
                }}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            label: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            label: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                onBlur={() => {
                    if (!value || !order?.organizationName) {
                        resetOptions();
                    }
                }}
                onKeyUp={(event) => {
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                        resetOptions();
                        setOrder({
                            ...order,
                            organizationName: null,
                            organizationNameDetails: null,
                        });
                    }
                }}
                filterOptions={(x) => x}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                }}
                renderOption={(props, option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <li {...props}>{option.label}</li>
                )}
                freeSolo
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(OrganizationNameSelect);
