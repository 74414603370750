import React from 'react';
import { CircularProgress } from '@mui/material';
import Btn from 'components/button';
import { useRecoilState } from 'recoil';
import { commonStyles as style } from 'assets/style';
import { requestDetails } from '../store';
// import styles from './style';

const CancelBtn = () => {
    const [isLoading, setisLoading] = React.useState(false);
    const [details, setDetails] = useRecoilState(requestDetails);
    const handleRequest = () => {
        setisLoading(true);
        setDetails({
            ...details,
            applicationCI: null,
            applicationCIDetails: null,
            sector: null,
        });
        setisLoading(false);
    };

    return (
        <Btn
            size="medium"
            onClick={() => handleRequest()}
            variant="outlined"
            disabled={isLoading}
            sx={{ ...style.secondaryMediumButton, width: '102px', marginRight: '8px' }}
            // sx={styles?.canceltBtn}
            // color="cmpPrimary"
            data-testid="update-cart-btn"
        >
            {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Cancel'}
        </Btn>
    );
};

export default CancelBtn;
