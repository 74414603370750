import React from 'react';
import { Grid, Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import Form from 'pages/myidm/components/Form';
import FormCard from 'pages/myidm/components/form-card';
import { OrderPayload } from 'pages/myidm/store';
import { AD_GROUP, LDAP_GROUP } from 'pages/myidm/utils/constant';
// import AddMemberSearch from 'pages/myidm/components/Form/AddMemberSearch';

const MemberForm = () => {
    const order = useRecoilValue(OrderPayload);
    React.useEffect(() => [order]);
    return (
        <>
            <Grid item xs={12}>
                <FormCard title="Application " gap={0} margin={2}>
                    <Grid item container spacing={2} sx={{ mt: 2, mb: 3 }}>
                        <Grid item md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={2}>
                                <Form.BusinessApp />
                                <Form.Sector />
                                {order?.isNewADGroup && order?.isNewADGroup === 'yes' && order?.groupType === AD_GROUP && (
                                    <>
                                        <Form.ApplicationService />
                                        <Form.ApplicationName />
                                        <Form.AppShortName />
                                    </>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={2}>
                                <Form.Environments />
                                {order?.isNewADGroup && order?.isNewADGroup === 'yes' && order?.groupType === LDAP_GROUP && <Form.ProjectName />}
                                {order?.isNewADGroup && order?.isNewADGroup === 'yes' && order?.groupType === AD_GROUP && <Form.ADGroupType />}
                            </Stack>
                        </Grid>
                    </Grid>
                </FormCard>
            </Grid>
            <Grid item xs={12}>
                <FormCard title="Resource" gap={0} margin={2}>
                    <Grid item container spacing={2} sx={{ mt: 2, mb: 3 }}>
                        <Grid item md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={2}>
                                <Form.GroupType />
                                <Form.SelectMemberBtn />
                            </Stack>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={2}>
                                {order?.isNewADGroup && order?.isNewADGroup === 'yes' && <Form.GroupOwner />}
                                {(!order?.isNewADGroup || order?.isNewADGroup === 'no') && order?.groupType === AD_GROUP && <Form.ADGroup />}
                                {(!order?.isNewADGroup || order?.isNewADGroup === 'no') && order?.groupType === LDAP_GROUP && <Form.LDAPGroup />}
                            </Stack>
                        </Grid>
                    </Grid>
                </FormCard>
            </Grid>
        </>
    );
};

export default MemberForm;
