import React from 'react';
import { Box } from '@mui/material';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { OrderPayload, validOnboardingForm, toggleModal, isSonarQubeRepoAvailable, urlParams } from 'pages/project-onboarding/store';
import { resetOrder, resetValidOnboardingForm, resetToggleModal } from 'pages/project-onboarding/utils';
import { getExistingServices } from 'pages/project-onboarding/utils/getFunctions';
import useDebounce from 'pages/myidm/components/hooks/use-debounce';

const ADOProjectName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [params] = useRecoilState(urlParams);
    const [value, setValue] = React.useState(order?.projectName || null);
    const [ccInputValue, setCCInputValue] = React.useState(order?.projectName || '');
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);
    const [validProjectName, setValidProjectName] = React.useState(null);
    const [, setValidProvisionForm] = useRecoilState(validOnboardingForm);
    const [, setToggleModal] = useRecoilState(toggleModal);
    const [, setRepoAvailable] = useRecoilState(isSonarQubeRepoAvailable);

    const projectNameGetApi = useMutation((projectValue) =>
        axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.Jwt?.adoProjectName}/${projectValue}?adoPlatform=${order?.adoPlatform}`)
    );
    const projectDetailsApi = useMutation((projectName) => axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.Jwt?.adoProjectDetails}/${projectName}`));
    const SecToolCodeRepoApi = useMutation((projectName) => axios.get(`${endPoints.day2Operation.ProjectOnboarding.Jwt.securityToolRepo}/${projectName}`));
    const existingMembersApi = useMutation((adGroupName) => axios.get(`${endPoints.day2Operation.ProjectOnboarding.adGroupMembersApi}/${adGroupName}`));
    const debouncedInputValue = useDebounce(validProjectName, 500);
    const flag = false;

    React.useEffect(() => {
        if (existingMembersApi?.isSuccess) {
            if (existingMembersApi?.data?.data?.length) {
                const deletedAdminMembersGpidArray = order?.deletedAdminMembers?.map((member) => member.gpid) || [];
                setOrder({
                    ...order,
                    existingADOServices: [...order?.existingADOServices, 'projectAdmin'],
                    existingAdminMembers: existingMembersApi?.data?.data
                        ?.filter((member) => !deletedAdminMembersGpidArray?.includes(member?.gpid))
                        .map((member) => ({
                            gpid: member?.gpid,
                            email: member?.email,
                            name: `${member.firstName} ${member.lastName}`,
                        })),
                });
            }
        }
    }, [existingMembersApi?.isSuccess]);

    React.useEffect(() => {
        if (projectDetailsApi?.isSuccess) {
            if (projectDetailsApi?.data?.data) {
                setOrder({
                    ...order,
                    projectDetails: projectDetailsApi?.data?.data,
                    adoProjectType: order?.adoProjectType || 'Advanced',
                    existingADOServices: getExistingServices(projectDetailsApi?.data?.data),
                });
                existingMembersApi?.mutate(projectDetailsApi?.data?.data?.projectAdmin?.name);
                // existingMembersApi?.mutate('Team.App.CMP.NonProd.cmpov20');
            }
        }
    }, [projectDetailsApi?.isSuccess]);

    React.useEffect(() => {
        if (SecToolCodeRepoApi?.isSuccess) {
            setOrder({
                ...order,
                secToolRepoList: SecToolCodeRepoApi?.data?.data?.repoNames?.map((app) => ({ label: app, value: app })),
            });
        }
    }, [SecToolCodeRepoApi?.isSuccess]);

    const setProjectName = (projectNameVal) => {
        const { sector, adoPlatform, isADOProject, projectName, courseDetails, ...rest } = resetOrder;

        if (params?.journeyId === null) {
            setOrder({
                ...order,
                projectName: projectNameVal,
                ...rest,
                serviceResources: {
                    ...order.serviceResources,
                    // isProjectAdmin: false,
                    isBoard: false,
                    isRepos: false,
                    isPipelines: false,
                    isSonarQube: false,
                    isArtifactory: false,
                    isSecurityTools: false,
                    isTestPlan: false,
                },
            });
            setValidProvisionForm(resetValidOnboardingForm);
        } else {
            setOrder({
                ...order,
                projectName: projectNameVal,
            });
            setValidProvisionForm((prevState) => ({
                ...prevState,
                ...resetValidOnboardingForm,
                isInvalidServiceAccount: prevState.isInvalidServiceAccount,
            }));
        }

        setToggleModal(resetToggleModal);
        setRepoAvailable(true);
    };

    const resetOptions = () => {
        setOptions(initOptions);
        setProjectName(null);
    };

    React.useEffect(() => {
        if (projectNameGetApi?.isSuccess) {
            if (projectNameGetApi?.data?.data?.value) {
                const combinedValue = projectNameGetApi?.data?.data?.value.map((app) => ({ label: app, value: app }));
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [projectNameGetApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.projectName) {
            projectDetailsApi?.mutate(order?.projectName);
        }
    }, [order?.projectName]);

    React.useEffect(() => {
        if (order?.projectName && flag) {
            SecToolCodeRepoApi.mutate(order?.projectName);
        }
    }, [order?.projectName, order?.serviceResources?.isSecurityTools]);

    React.useEffect(() => {
        if (debouncedInputValue !== null) {
            projectNameGetApi.mutate(debouncedInputValue);
        }
    }, [debouncedInputValue]);

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) setValidProjectName(ccInputValue);
    }, [ccInputValue]);

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else {
            setProjectName(value?.label);
            setOptions([]);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.projectDetails && order?.isTouched) setIsTouched(true);
    }, [order?.projectDetails, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} marginRight={3.4}>
            <Select
                dataTestId="existing-projectName-autocomplete"
                key="adoProjectName"
                label="ADO Project Name *"
                placeholder="Type to search"
                value={order?.projectName || null}
                isLoading={projectNameGetApi?.isLoading || projectDetailsApi?.isLoading}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            label: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            label: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                onBlur={() => {
                    if (!value || !order?.projectName) {
                        setValidProjectName(null);
                        setOptions([]);
                        setProjectName(null);
                    }
                }}
                onKeyUp={(event) => {
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                        setOrder({
                            ...order,
                            projectName: null,
                        });
                        setOptions([]);
                    }
                }}
                filterOptions={(x) => x}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                }}
                renderOption={(props, option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <li {...props}>{option.label}</li>
                )}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(ADOProjectName);
