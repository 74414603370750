import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAxios } from 'hooks';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import ProvisioningHeader from 'pages/provisioning/day2-operation-paas/ProvisioningHeader';
import ApplicationDetails from 'pages/provisioning/day2-operation-paas/ApplicationDetails';
import SspForm from 'pages/provisioning/day2-operation-paas/SspForm';
import Tags from 'pages/provisioning/day2-operation-paas/Tags';
import AddedToCartConfirmModal from 'pages/provisioning/day2-operation-paas/AddedToCartConfirmModal';
import UpdatedToCartConfirmModal from 'pages/provisioning/day2-operation-paas/UpdatedToCartConfirmModal';
import QuickConfig from 'pages/provisioning/day2-operation-paas/QuickConfig';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';
import {
    environmentDetails,
    isMandatoryTagsFullfilled,
    orderId,
    quickConfigDetails,
    sspSubmitOrder,
    toggleSspModels,
} from 'pages/provisioning/store/day2-operation-paas';
import ShowCostDetails from 'pages/provisioning/day2-operation-paas/ShowCostDetails';
import {
    resetEnvironmentDetails,
    resetOrder,
    resetToggleSspModal,
    formOrderObjFromCart,
    formOrderObjFromOrderDetails,
} from 'pages/provisioning/day2-operation-paas/utils';
import ConfigTagsModal from 'pages/provisioning/day2-operation-paas/ConfigTagsModal';

const Day2OperationPaasPage = () => {
    const [isLoading, setLoading] = React.useState(true);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, resetOrderId] = useRecoilState(orderId);
    const [, resetEnvDetails] = useRecoilState(environmentDetails);
    const [, resetIsMandatoryTagsFullfilled] = useRecoilState(isMandatoryTagsFullfilled);
    const [, resetToggleSspModels] = useRecoilState(toggleSspModels);
    const [, resetQuickConfig] = useRecoilState(quickConfigDetails);
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const axios = useAxios();

    async function getCosts() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}/costs`);
        return data;
    }
    async function checkCatalog() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}`);
        const costs = await getCosts();
        if (cartItemId && operation && !catalogServiceId) {
            const { data: cartItem } = await axios.get(`${endPoints.cart.getCartItem}/${cartItemId}`);
            const orderObj = await formOrderObjFromCart(cartItem);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                emptyCost: costs,
            }));
        }
        if (cartItemId && operation && catalogServiceId) {
            const { data: orderDetails } = await axios.get(`${endPoints.order.orderDetails}/${cartItemId}`);
            const orderObj = await formOrderObjFromOrderDetails(catalogServiceId, orderDetails);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
            }));
        } else {
            setOrder((previous) => ({
                ...previous,
                sku: data?.images?.sku,
                catalogCode,
                catalogType: data?.displayName,
                cloudService: data?.cloudService,
                validationStatus: 'clearValidation',
                isTouched: false,
                emptyCost: costs,
            }));
        }
        setLoading(false);
    }

    const reset = () => {
        setOrder({ ...order, ...resetOrder });
        resetOrderId('');
        resetEnvDetails(resetEnvironmentDetails);
        resetIsMandatoryTagsFullfilled(false);
        resetToggleSspModels(resetToggleSspModal);
        resetQuickConfig(undefined);
    };

    React.useEffect(() => {
        if (catalogCode) {
            setLoading(true);
            reset();
            checkCatalog();
        }
    }, [catalogCode]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => reset();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>PaaS - Day2Operation - DevX Infra</title>
            </Helmet>
            {isLoading && (
                <Box sx={ProvisioningStyles.provisioning_wrapper}>
                    <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                    <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                        Loading
                    </Typography>
                </Box>
            )}
            {!isLoading && (
                <Box sx={ProvisioningStyles.provisioningBox}>
                    <ProvisioningHeader />
                    <Box sx={ProvisioningStyles.sspform_wrapper}>
                        <SspForm />
                    </Box>
                    <br />
                    <ApplicationDetails />
                    <Tags />
                    <AddedToCartConfirmModal />
                    <UpdatedToCartConfirmModal />
                    <QuickConfig />
                    <ShowCostDetails />
                    <ConfigTagsModal />
                </Box>
            )}
        </>
    );
};

export default Day2OperationPaasPage;
