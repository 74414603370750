import React from 'react';
import { Box } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/github-onboarding/store';
import defaultOrderValues from 'pages/github-onboarding/store/defaultValues';

import Select, { CssTextField } from 'components/select';

const filter = createFilterOptions();
const BusinessApplication = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);

    const bAppGetApi = useMutation(() => axios.get(`${endPoints.day2Operation.ProjectOnboarding.businessApp}?applicationName=${ccInputValue?.toUpperCase()}`));
    const bAppPostApi = useMutation((payload) => axios.post(`${endPoints.day2Operation.ProjectOnboarding.businessApp}`, payload));

    const setBusinessApplication = (businessApplicationName, businessApplicationDetails) => {
        const { customProperties, ...rest } = defaultOrderValues;

        setOrder({
            customProperties: {
                ...order.customProperties,
                businessApplication: businessApplicationName,
                applicationSupportGroup: businessApplicationDetails?.supportGroup,
                businessApplicationId: businessApplicationDetails?.businessApplicationId,
            },
            ...rest,
        });
    };

    const resetOptions = () => {
        setOptions(initOptions);
        setBusinessApplication(null, null);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                bAppGetApi?.mutate();
            }, 1000),
        []
    );

    const validateInput = () => {
        if (isTouched && order?.isTouched && !order?.customProperties?.businessApplication) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue !== '' && ccInputValue.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        let apiData = null;

        if (bAppPostApi?.isSuccess) {
            apiData = bAppPostApi?.data?.data?.applications;
        } else if (bAppGetApi?.isSuccess) {
            apiData = bAppGetApi?.data?.data?.applications;
        }

        if (!apiData) return;

        const combinedValue = apiData.map((app) => ({
            label: app.businessApplicationName,
            value: app.businessApplicationName,
            data: app,
        }));

        if (bAppPostApi?.isSuccess && order?.customProperties?.businessApplicationDetails) {
            combinedValue.push(order.customProperties.businessApplicationDetails);
        }

        setOptions(combinedValue);

        if (bAppPostApi?.isSuccess) {
            setInitOptions(combinedValue);
        }
    }, [bAppGetApi?.isSuccess, bAppPostApi?.isSuccess]);

    const isExistsInOptions = (region) => options.find((option) => option?.label === region.label || option?.value === region);

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else if (value) {
            setBusinessApplication(value?.label, value?.data);
        }
    }, [value]);

    React.useEffect(() => {
        if (!options?.length) return;

        const { businessApplication } = order?.customProperties || {};

        if (options.length === 1 && !businessApplication) {
            setBusinessApplication(options[0].label, options[0].data);
            return;
        }

        if (businessApplication) {
            const foundOption = isExistsInOptions(businessApplication);

            if (foundOption) {
                setBusinessApplication(foundOption.label, foundOption.data);
            }
        }
    }, [options]);

    React.useEffect(() => {
        setIsTouched(!order?.customProperties?.businessApplication && order?.isTouched);
    }, [order?.customProperties?.businessApplication, order?.isTouched]);

    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Select
                    data-testid="business-application"
                    key="businessApplication"
                    size="small"
                    loading={bAppGetApi?.isLoading}
                    label="Business Application Name *"
                    disabled={!order.customProperties.adoProjectName}
                    value={order?.customProperties?.businessApplication || null}
                    onInputChange={(event, newInputValue) => {
                        setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                                value: newValue,
                                data: null,
                            });
                        } else if (newValue && newValue.inputValue) {
                            setValue({
                                label: newValue.inputValue,
                                value: newValue.inputValue,
                                data: null,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.label;
                    }}
                    filterOptions={(optionValues, params) => {
                        const filtered = filter(optionValues, params);
                        const { inputValue } = params;
                        const isExisting = optionValues.some((option) => inputValue === option.label);

                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                label: `"${inputValue}"`,
                            });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="businessApplication"
                    options={options}
                    freeSolo
                    renderInput={(params) => (
                        <CssTextField
                            {...params}
                            label="Business Application Name *"
                            sx={{
                                '& label.MuiInputLabel-shrink': {
                                    color: '#0047BA !important',
                                },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                            }}
                            fullWidth
                        />
                    )}
                    className={validateInput()}
                />
            </Box>
        </>
    );
};

export default React.memo(BusinessApplication);
