import React from 'react';
import { Box, Checkbox, FormControlLabel, InputLabel, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';

const TestPlanOptions = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);

    const handleChange = (e, type) => {
        if (type === 'add') {
            setOrder({
                ...order,
                addTestPlanMembers: !order?.addTestPlanMembers,
            });
            if (!e.target.checked) {
                setOrder({
                    ...order,
                    addTestPlanMembers: false,
                    testPlanUserList: [],
                });
                setValidForm({
                    ...isValidForm,
                    isDuplicateTestPlanUser: false,
                    isTestPlanMaxMemberLimit: false,
                });
            }
        }
        if (type === 'remove') {
            setOrder({
                ...order,
                removeTestPlanMembers: !order?.removeTestPlanMembers,
            });
            if (!e.target.checked) {
                setOrder({
                    ...order,
                    removeTestPlanMembers: false,
                    removedTestPlanUserList: [],
                });
                setValidForm({
                    ...isValidForm,
                    isDuplicateRemovedTestPlanUser: false,
                    isRemovedTestPlanMaxMemberLimit: false,
                });
            }
        }
    };

    return (
        <>
            <Box mb={2} mt={1} sx={{ color: '#0047BA', fontSize: '12px', fontWeight: '600' }} id="autoinflateRadioGrp">
                Select the Test Plan Options*
            </Box>
            <Box display="flex" flexDirection="row" marginTop="-20px" justifyContent="start" gap={8}>
                <Box>
                    <FormControlLabel
                        control={<Checkbox onChange={(e) => handleChange(e, 'add')} checked={order?.addTestPlanMembers} />}
                        label={
                            <InputLabel htmlFor="short-description">
                                <Grid sx={{ color: '#0047BA', fontSize: '12px', fontWeight: 700 }} item xs={6} sm={6}>
                                    Add Members
                                </Grid>
                            </InputLabel>
                        }
                    />
                </Box>
                <Box>
                    <FormControlLabel
                        control={<Checkbox onChange={(e) => handleChange(e, 'remove')} checked={order?.removeTestPlanMembers} />}
                        label={
                            <InputLabel htmlFor="short-description">
                                <Grid sx={{ color: '#0047BA', fontSize: '12px', fontWeight: 700 }} item xs={6} sm={6}>
                                    Remove Members
                                </Grid>
                            </InputLabel>
                        }
                    />
                </Box>
            </Box>
        </>
    );
};

export default React.memo(TestPlanOptions);
