import React from 'react';
import { useRecoilState } from 'recoil';
import { Grid, Stack } from '@mui/material';
import FormCard from 'pages/provisioning/_components/FormCard';
import Forms from 'pages/provisioning/couchbase/components/Form';
import { sspSubmitOrder, costEstimateState, isLoadingCostApiState, toggleSspModels } from 'pages/provisioning/store/couchbase';
import CostSummary from 'pages/provisioning/_components/CostSummary';
import EnvironmentContainer from 'pages/provisioning/couchbase/EnvironmentContainer';
import CartBtn from 'pages/provisioning/couchbase/components/CartBtn';
import NodesForm from 'pages/provisioning/couchbase/components/NodesForm';

const ProvisioningForm = () => {
    const [order] = useRecoilState(sspSubmitOrder);
    const [costEstimate] = useRecoilState(costEstimateState);
    const [isLoadingCostApi] = useRecoilState(isLoadingCostApiState);
    const [modal, setModal] = useRecoilState(toggleSspModels);

    const handleCostInfoBtn = (type) => {
        setModal({
            ...modal,
            isCost: { costType: type, isCostOpen: true },
        });
    };

    return (
        <Grid container spacing={3.125} pt={3.125} pb={1}>
            <Grid item xs={8}>
                <Grid item xs={12}>
                    <FormCard title="Application">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Forms.Application />
                                    <Forms.Sector />
                                    <Forms.Environments />
                                    <Forms.AppTier />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Forms.Region />
                                    <Forms.LandingZones />
                                    <Forms.BillingEntities />
                                    <Forms.CostCenter />
                                </Stack>
                            </Grid>
                        </Grid>
                    </FormCard>
                </Grid>
                <Grid item xs={12}>
                    <FormCard title="Instance Details">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Forms.Cluster />
                                    <Forms.NodesCount />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Forms.DbVersion />
                                </Stack>
                            </Grid>
                        </Grid>
                    </FormCard>
                </Grid>
                {order?.nodesCount !== null && order?.nodes?.map((z, index) => <NodesForm indexVal={index} />)}
                {order?.nodesCount !== null && (
                    <Grid item xs={12}>
                        <FormCard title="Service Mapping">
                            <Forms.ServiceMappings />
                        </FormCard>
                    </Grid>
                )}
                {order?.nodesCount !== null && (
                    <Grid item xs={12}>
                        <FormCard title="Add Bucket" marginTop="5px">
                            <Forms.AddBucket />
                        </FormCard>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <EnvironmentContainer />
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <CostSummary handleCostInfoBtn={handleCostInfoBtn} cartBtn={<CartBtn />} costEstimate={costEstimate} isLoading={isLoadingCostApi?.isLoading} />
            </Grid>
        </Grid>
    );
};

export default ProvisioningForm;
