import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import axios from 'api/request';
import Btn from 'components/button';
import { Alert, Box } from '@mui/material';
import GithubOnboardingStyles from 'pages/github-onboarding/style';
import { OrderPayload, toggleModal } from 'pages/github-onboarding/store';
import { commonStyles as style } from 'assets/style';
import { endPoints } from 'api/endpoints';
import GithubReposSearch from './GithubReposSearch';
import AssignReposList from './AssignReposList';
import AssigReposTeam from './AssigReposTeam';
import extractGithubNames from '../../../../utils/extractReposName';
import checkIfAllReposAssigned from '../../../../utils/checkIfAllReposAssigned';

const AssignReposToTeams = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isOpen, setIsOpen] = useRecoilState(toggleModal);
    const [teamsAndAssignedRepos, setTeamsAndAssignedRepos] = useState([]);
    const [isRepoWithoutTeam, setIsRepoWithoutTeam] = useState(false);
    const reposToMigrate = extractGithubNames(order?.reposToMigrate || []);

    const reposListGetApi = useMutation(() => axios.get(`${endPoints.githubOnboarding.githubRepos}?org=PepsiCOIT-Actions-Archived`));

    const setAssignedRepos = () => {
        setOrder({
            ...order,
            reposAssignedToTeams: teamsAndAssignedRepos,
        });
    };

    useEffect(() => {
        const allTeams = order?.currentTeams || [];

        if (order.reposAssignedToTeams?.length) {
            setTeamsAndAssignedRepos([...order.reposAssignedToTeams]);
            return;
        }

        const customTeams = allTeams.filter((team) => !team.isDefault);

        setTeamsAndAssignedRepos([
            {
                teamName: 'GitHub Repos for default Developers & DevOps',
                isDefault: true,
                repos: reposToMigrate,
            },
            ...customTeams.map((team) => ({
                teamName: team.teamName,
                isDefault: false,
                repos: reposToMigrate,
            })),
        ]);
    }, [order?.currentTeams]);

    useEffect(() => {
        const isAllReposAssigned = checkIfAllReposAssigned(teamsAndAssignedRepos, reposToMigrate);

        setIsRepoWithoutTeam(!isAllReposAssigned);
    }, [teamsAndAssignedRepos, reposToMigrate]);

    React.useEffect(() => {
        if (reposListGetApi?.isSuccess) {
            setOrder({
                ...order,
                availableGithubRepos: reposListGetApi?.data?.repositories,
            });
        }
    }, [reposListGetApi?.isSuccess]);

    useEffect(() => {
        if (!order?.availableGithubRepos) {
            reposListGetApi.mutate();
        }
    }, []);

    const addRepoToTeam = (teamName, newRepo) =>
        setTeamsAndAssignedRepos((prev) =>
            prev.map((team) => {
                if (team.teamName === teamName && !team.repos.includes(newRepo)) {
                    return {
                        ...team,
                        repos: [...team.repos, newRepo],
                    };
                }
                return team;
            })
        );

    const removeRepoFromTeam = (teamName, repoToRemove) => {
        setTeamsAndAssignedRepos((prev) =>
            prev.map((team) => {
                if (team.teamName === teamName) {
                    return {
                        ...team,
                        repos: team.repos.filter((repo) => repo !== repoToRemove),
                    };
                }
                return team;
            })
        );
    };

    const modalClose = () =>
        setIsOpen({
            ...isOpen,
            isReposToMigrateOpen: false,
            isTeamsAndMembersOpen: false,
            isAssignReposOpen: false,
        });

    const handleSave = () => {
        setAssignedRepos();
        modalClose();
    };

    return (
        <>
            <Box sx={GithubOnboardingStyles.modal.wrapper}>
                <Box sx={{ ...GithubOnboardingStyles.modal.contentWrapper, paddingTop: '10px' }}>
                    {isRepoWithoutTeam && (
                        <Alert severity="error" color="error" sx={{ marginBottom: 2 }}>
                            All repositories you choose to migrate have to be assign at least to the one of the teams. Please choose “Cancel” and configure the
                            “Assign repos to teams” again.
                        </Alert>
                    )}
                    <Alert severity="info" color="info" sx={{ marginBottom: 2 }}>
                        If you want to add additional respos besides what you’re migrating, or are already assigned to your team, please search for more GitHub
                        repos and add them to your team(s).
                    </Alert>
                    {teamsAndAssignedRepos.map((team, index) => (
                        <AssigReposTeam teamName={team.teamName} isDefaultTeam={team.isDefault} key={`${team.teamName}_${index}`}>
                            <GithubReposSearch
                                isLoading={reposListGetApi.isLoading}
                                handleAddRepo={addRepoToTeam}
                                reposList={team.repos}
                                teamName={team.teamName}
                            />
                            <AssignReposList reposList={team.repos} handleRemoveRepo={removeRepoFromTeam} teamName={team.teamName} />
                        </AssigReposTeam>
                    ))}
                </Box>

                <Box className="footer" sx={GithubOnboardingStyles.modal.actionWrapper} gap={2}>
                    <Btn
                        className="custom-btn btn-disabled"
                        variant="contained"
                        disabled={isRepoWithoutTeam || order?.isCostApiLoading}
                        onClick={() => {
                            handleSave();
                        }}
                        data-testid="config-save-btn"
                        sx={style.modalSaveButton}
                    >
                        Save
                    </Btn>

                    <Btn
                        className="custom-btn"
                        onClick={modalClose}
                        variant="outlined"
                        sx={style.modalCancelButton}
                        layout="outline"
                        data-testid="config-cancel-btn"
                    >
                        Cancel
                    </Btn>
                </Box>
            </Box>
        </>
    );
};

export default React.memo(AssignReposToTeams);
