import { atom } from 'recoil';
import defaultOrderValues, { defaultToggleModals, defaultValidOnboardingForm } from './defaultValues';

export const OrderPayload = atom({
    key: 'githubOnboard-OrderPayload',
    default: defaultOrderValues,
});

export const showPreviewOrder = atom({
    key: 'githubOnboard-showPreviewOrder',
    default: null,
});

export const validOnboardingForm = atom({
    key: 'githubOnboard-validOnboardingForm',
    default: defaultValidOnboardingForm,
});

export const toggleModal = atom({
    key: 'githubOnboard-toggleModal',
    default: defaultToggleModals,
});

export const isValidationLoading = atom({
    key: 'githubOnboard-isValidationLoading',
    default: false,
});
