import { RICatalogCode003, RICatalogCode003A, RICatalogCode004, RICatalogCode004A, RICatalogCode005 } from 'pages/reference-implementation/utils/RICatalogCode';

export const ShowAppUrlBasedOnCatalogCode = [RICatalogCode003, RICatalogCode003A, RICatalogCode005];
export const checkIsApplicable = (options) => !(options?.length === 1 && options?.[0]?.value?.toLowerCase() === 'not applicable');
export const isShowAppRegionField = ({ order }) => {
    const sector = order?.sector?.toLowerCase();
    // if (order?.sector && ['amesa']?.includes(sector) && order?.catalogCode === RICatalogCode005) {
    //     return true;
    // }
    if (
        order?.sector &&
        ['cgf', 'amesa', 'snt dps']?.includes(sector) &&
        [RICatalogCode003, RICatalogCode003A, RICatalogCode004, RICatalogCode004A, RICatalogCode005]?.includes(order?.catalogCode)
    ) {
        return true;
    }
    return false;
};
export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    // const privilegedADGroup = catalogConfig?.contributorAdGroup?.length ? 'Yes' : 'No';
    const nonPrivilegedADGroup = catalogConfig?.readerAdGroup?.length ? 'Yes' : 'No';
    return {
        catalogCode: catalog?.catalogServiceCode,
        projectId: catalogConfig?.projectId ? catalogConfig?.projectId : null,
        projectDetails: catalogConfig?.projectId
            ? {
                  businessUnit: catalogConfig?.businessUnit,
                  hyperionNumber: catalogConfig?.budgetCode,
                  impactedSectors: catalogConfig?.sector,
                  projectNumber: catalogConfig?.projectId,
              }
            : null,
        businessUnit: catalogConfig?.businessUnit,
        sector: catalogConfig?.sector,
        program: catalogConfig?.program === 'Global Shared' ? 'Not Applicable' : catalogConfig?.program,
        appUrl: catalogConfig?.applicationUrl,
        appContextPath: catalogConfig?.applicationContextPath,
        ...(catalogConfig?.sector?.toLowerCase() === 'cgf' && { division: catalogConfig?.division }),
        ...(['cgf', 'amesa', 'snt dps']?.includes(catalogConfig?.sector?.toLowerCase()) && { region: catalogConfig?.applicationRegion }),
        budgetCode: catalogConfig?.costCenter,
        billingInfo: catalogConfig?.billingInformation,
        environment: catalogConfig?.environment,
        dataClassification: catalogConfig?.dataClassification,
        businessAppService: catalogConfig?.businessApplication && catalogConfig?.applicationService ? 'Yes' : 'No',
        // businessApp: catalogConfig?.businessApplication ? catalogConfig?.businessApplication : catalogConfig?.applicationName,
        businessApp: catalogConfig?.businessApplication,
        appService: !catalogConfig?.applicationService
            ? `${catalogConfig?.applicationName} ${['production']?.includes(catalogConfig?.environment?.toLowerCase()) ? 'PROD' : 'NON-PROD'}`
            : catalogConfig?.applicationService,
        applicationName: catalogConfig?.applicationName,
        appShortName: catalogConfig?.applicationShortName,
        // appOwner: data?.manager?.id,
        appOwner: null,
        appOwnerDetails: { name: data?.manager?.name, gpid: data?.manager?.id },
        businessContact: null,
        businessContactDetails: { gpid: catalogConfig?.businessOwnerId },
        // privilegedADGroup,
        nonPrivilegedADGroup,
        // ...(privilegedADGroup === 'Yes' && {
        //     privilegedADGroupList: catalogConfig?.contributorAdGroup.map((app) => ({
        //         contributorValue: app,
        //         contributorInputValue: app,
        //     })),
        // }),
        ...(nonPrivilegedADGroup === 'Yes' && {
            nonPrivilegedADGroupList: catalogConfig?.readerAdGroup.map((app) => ({
                readerValue: app,
                readerInputValue: app,
            })),
        }),
        ...(!['production']?.includes(catalogConfig?.environment?.toLowerCase()) &&
            nonPrivilegedADGroup === 'No' && {
                nonPrivilegedADGroupOwner: null,
                nonPrivilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
            }),
        // ...(!['production']?.includes(catalogConfig?.environment?.toLowerCase()) &&
        //     privilegedADGroup === 'No' && {
        //         privilegedADGroupOwner: null,
        //         privilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
        //     }),
        // ...(['production']?.includes(catalogConfig?.environment?.toLowerCase()) &&
        //     privilegedADGroup === 'No' && {
        //         privilegedADGroupOwner: null,
        //         privilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
        //     }),
        createdBy: data?.created?.by,
        appSupportMailId: catalogConfig?.appSupportMailId,
        ...([RICatalogCode003A, RICatalogCode003, RICatalogCode005]?.includes(catalog?.catalogServiceCode) && {
            certificateOwnerOneDetails: { gpid: catalogConfig?.certificateOwnerOne, name: catalogConfig?.certificateOwnerOneName },
            certificateOwnerTwoDetails: { gpid: catalogConfig?.certificateOwnerTwo, name: catalogConfig?.certificateOwnerTwoName },
        }),
        isTouched: false,
    };
};
export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

export const resetOrder = {
    catalogCode: null,
    projectId: null,
    projectDetails: null,
    businessUnit: null,
    sector: null,
    program: null,
    appUrl: null,
    appContextPath: null,
    division: null,
    budgetCode: null,
    budgetCodeDetails: null,
    billingInfo: null,
    environment: null,
    dataClassification: null,
    environmentDetails: null,
    businessAppService: 'No',
    businessApp: null,
    appService: null,
    appOwner: null,
    appOwnerDetails: null,
    certificateOwnerOne: null,
    certificateOwnerOneDetails: null,
    certificateOwnerTwo: null,
    certificateOwnerTwoDetails: null,
    applicationName: null,
    appShortName: null,
    businessContact: null,
    businessContactDetails: null,
    // privilegedADGroup: null,
    nonPrivilegedADGroup: null,
    nonPrivilegedADGroupList: [{ readerValue: '' }],
    // privilegedADGroupList: [{ contributorValue: '' }],
    // privilegedADGroupOwner: null,
    // privilegedADGroupOwnerDetails: null,
    nonPrivilegedADGroupOwner: null,
    nonPrivilegedADGroupOwnerDetails: null,
    appSupportMailId: null,
    isTouched: false,
};
export const isUniqueOwner = (value = null, selectedvalues = []) => {
    return selectedvalues.indexOf(value) === -1;
};
