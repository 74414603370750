import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
// import InfoIcon from '@mui/icons-material/Info';
// import Styles from 'pages/reference-implementation/Style';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const InfoTooltip = (props) => {
    const { title, maxWidth, placement } = props;

    const StyledTooltip = styled(({ className, ...prop }) => <Tooltip {...prop} arrow classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            backgroundColor: theme.palette.white,
            fontSize: 14,
            '&::before': {
                border: '1px solid #E9E9E9',
                color: theme?.palette?.black?.dark,
                backgroundColor: theme.palette.white,
                boxSizing: 'border-box',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            },
        },
        [`& .${tooltipClasses.tooltip}`]: {
            fontSize: 10,
            textAlign: 'left',
            whiteSpace: 'normal',
            width: 'auto',
            maxWidth,
            height: '100%',
            backgroundColor: theme.palette.white,
            color: theme?.palette?.black?.dark,
            border: '1px solid #E9E9E9',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
    }));
    return (
        <StyledTooltip arrow title={title} data-testid="help-img" placement={placement}>
            <Box component="span">
                {/* <InfoIcon style={{ ...Styles, color: infoIconColor }} /> */}
                <img src="/static/images/info_blue.svg" alt="" width={22} />
            </Box>
        </StyledTooltip>
    );
};

InfoTooltip.propTypes = {
    title: PropTypes.any,
    maxWidth: PropTypes.string,
    placement: PropTypes.any,
    // infoIconColor: PropTypes.any,
};

InfoTooltip.defaultProps = {
    title: '',
    maxWidth: 'auto',
    placement: 'bottom',
    // infoIconColor: '#1478D4',
};

export default React.memo(InfoTooltip);
