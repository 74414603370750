/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
// import Styles from 'pages/provisioning/_components/style';
// import { FaInfoCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const InfoImg = (props) => {
    const { title, infoColor, maxWidth } = props;
    const StyledTooltip = styled(({ className, ...prop }) => <Tooltip {...prop} arrow classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            backgroundColor: infoColor ? '#FFFFFF' : '#0047BA',
            fontSize: 14,
            '&::before': {
                border: '1px solid #E9E9E9',
                color: theme.palette.black.dark,
                backgroundColor: theme.palette.white,
                boxSizing: 'border-box',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 0px',
            },
        },
        [`& .${tooltipClasses.tooltip}`]: {
            fontSize: 10,
            textAlign: 'left',
            whiteSpace: 'normal',
            width: 'auto',
            maxWidth,
            height: '100%',
            backgroundColor: theme.palette.white,
            color: theme.palette.black.dark,
            border: '1px solid #E9E9E9',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
    }));

    return (
        <StyledTooltip arrow title={title} data-testid="info-img">
            <Box component="span" sx={{ marginTop: '6px !important' }}>
                <img src="/static/images/info_blue.svg" alt="" width={22} />
                {/* <FaInfoCircle style={infoColor} /> */}
            </Box>
        </StyledTooltip>
    );
};

InfoImg.propTypes = {
    title: PropTypes.any,
    infoColor: PropTypes.any,
    maxWidth: PropTypes.string,
};

InfoImg.defaultProps = {
    title: '',
    // infoColor: '#0047BA',
    infoColor: { color: '#0047BA', fontSize: '20px', marginTop: '3px' },
    maxWidth: 'auto',
};

export default React.memo(InfoImg);
