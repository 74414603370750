import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Box, Typography, IconButton } from '@mui/material';
import { useRecoilValue } from 'recoil';
import DialogModal from 'components/dialog';
import AddedToCartImg from 'assets/images/added-to-cart.png';
import { toggleSspModels } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import { Close } from '@mui/icons-material';
import Btn from 'components/button';
import { commonStyles as style } from 'assets/style';

const UpdatedToCartConfirmModal = () => {
    const history = useHistory();
    const modal = useRecoilValue(toggleSspModels);

    return (
        <DialogModal open={modal.isUpdatedToCart} handleClose={() => {}} data-testid="dialogModal">
            <>
                <IconButton
                    onClick={() => history.push('/services')}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <Box sx={ProvisioningStyles.submitOrder.wrapper}>
                    <img src={AddedToCartImg} alt="" />
                    <Typography variant="h5" gutterBottom align="center" sx={ProvisioningStyles.submitOrder.message}>
                        Updated cart
                    </Typography>
                    <Typography component="span" gutterBottom align="center" sx={ProvisioningStyles.submitOrder.description}>
                        <Box>Thank you for using DevX Infra!</Box>
                        <Box>Your item was successfully updated to the cart.</Box>
                    </Typography>
                    <Box sx={ProvisioningStyles.submitOrder.actionWrapper}>
                        <Btn
                            className="custom-btn"
                            onClick={() => history.push('/services')}
                            component={Link}
                            to="/services"
                            // sx={ProvisioningStyles.submitOrder.newOrder}
                            sx={{ ...style.primaryMediumButton, marginRight: '6px', width: 'auto' }}
                            disableElevation
                            variant="contained"
                            // color="primary"
                            data-testid="keep-btn"
                        >
                            Keep Shopping
                        </Btn>
                        <Btn
                            className="custom-btn"
                            onClick={() => history.push('/cart')}
                            component={Link}
                            to="/cart"
                            variant="outlined"
                            // color="primary"
                            disableElevation
                            sx={{ ...style.secondaryMediumButton, width: 'auto' }}
                            data-testid="checkout-btn"
                        >
                            Checkout
                        </Btn>
                    </Box>
                </Box>
            </>
        </DialogModal>
    );
};
export default React.memo(UpdatedToCartConfirmModal);
