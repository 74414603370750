import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { RIStyles } from 'pages/reference-implementation/Style';
import ProvisioningStyles from 'pages/provisioning/kafka/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const TopicName = (props) => {
    const { isRequired, handleOnSelect, topicNamevalue, errors } = props;

    const namingConventionRules = [
        `Prefixed with "pep-"`,
        'Sector, Project should be alphabet',
        'Service name and Process name can contain alpha numeric values',
        'Versioning is optional, should start with V and numeric values',
        'Min.: 6 chars to Max.: 209 chars',
        'Can contain only hyphen(-) as special char',
        'Should not contain consecutive hyphens',
    ];

    const getToolTip = () => {
        return (
            <HelpImg
                title={
                    <>
                        <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                            <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.topDividerRule}>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle2}>
                                            Topic Name should be pep-[sector]-[project]-[servicename]-[processname]-v1
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle2}>Example: pep-amesa-cmp-log-data-v1</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.namingRule}>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle2}>
                                            <span style={{ fontWeight: 600 }}>Naming Convention Rules:</span>
                                        </Typography>
                                        {namingConventionRules?.map((nameConv, index) => {
                                            return (
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle2} key={`topic-rules-${index}`}>
                                                    {index + 1}) {nameConv}
                                                </Typography>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        );
    };
    return (
        <Stack direction="row" spacing={1} sx={RIStyles.stack}>
            <CssTextField
                size="small"
                fullWidth
                autoComplete="off"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                placeholder="pep-sector-env-service-name"
                label="Topic Name *"
                error={errors?.showError || isRequired}
                helperText={errors?.showError && <span style={{ color: '#d40020' }}>{errors?.errorMessage}</span>}
                value={topicNamevalue || ''}
                onChange={(e) => handleOnSelect(e.target.value)}
                className={errors?.showError || isRequired ? 'validate' : ''}
                data-testid="topicNametext"
            />

            {getToolTip()}
        </Stack>
    );
};

TopicName.propTypes = {
    errors: PropTypes.any,
    handleOnSelect: PropTypes.func,
    isRequired: PropTypes.bool,
    topicNamevalue: PropTypes.string,
};

TopicName.defaultProps = {
    errors: null,
    handleOnSelect: () => {},
    isRequired: false,
    topicNamevalue: '',
};

export default TopicName;
