// A custom theme for this app
import { createTheme } from '@mui/material';

const theme = createTheme({
    typography: {
        fontFamily: ["'Inter', sans-serif !important"],
        // fontSize:{
        //     2:'2px',
        //     12:'12px',
        //     10:'10px',
        //     13:'13px',
        //     14:'14px',
        //     20:'20px',
        //     rem:{
        //        1:'0.5rem',
        //         2:'2rem',
        //         22:'2rem',
        //         3:'3rem',
        //         5:'5rem',
        //         12:'1.2rem',
        //         15:'1.5rem'
        //     }
        // }
        fontSize: {
            $font_heading_h2_bold: '700 40px/56px Inter, sans-serif',
            $font_heading_h3_bold: '700 36px/56px Inter, sans-serif',
            $font_heading_h4_bold: '700 32px/56px Inter, sans-serif',
            $font_heading_h4_medium: '500 32px/56px Inter, sans-serif',
            $font_heading_h5_bold: '700 24px/40px Inter, sans-serif',
            $font_heading_h5_medium: '500 24px/40px Inter, sans-serif',
            $font_heading_h6_bold: '700 18px/32px Inter, sans-serif',
            $font_heading_h6_medium: '500 18px/32px Inter, sans-serif',
            $font_body_bold: '700 16px/24px Inter, sans-serif',
            $font_body_medium: '500 16px/24px Inter, sans-serif',
            $font_body_regular: '400 16px/24px Inter, sans-serif',
            $font_body_caps: '700 16px/24px Inter, sans-serif',
            $font_small_bold: '700 14px/20px Inter, sans-serif',
            $font_small_medium: '500 14px/20px Inter, sans-serif',
            $font_small_regular: '400 14px/20px Inter, sans-serif',
            $font_small_caps: '700 14px/20px Inter, sans-serif',
            $font_xsmall_regular: '400 12px/20px Inter, sans-serif',
            $font_xsmall_caps: '700 12px/20px Inter, sans-serif',
            $font_xxsmall_bold: '700 10px/16px Inter, sans-serif',
            $font_xxsmall_regular: '400 10px/16px Inter, sans-serif',
            $font_xxsmall_caps: '700 10px/16px Inter, sans-serif',
            $font_heading_h6_new_medium: '500 20px/32px Inter, sans-serif',
        },
    },
    palette: {
        cmpSuccess: {
            main: '#658835',
            dark: '#84A05D',
            contrastText: '#FFFFFF',
        },
        cmpDanger: {
            main: '#AE172B',
            dark: '#BE4555',
            contrastText: '#FFFFFF',
        },
        cmpPrimary: {
            main: '#0047BA',
            dark: '#00A0DD',
            contrastText: '#FFFFFF',
        },
        cmpPrimary2: {
            main: '#1478D4',
            dark: '#1478D4',
            contrastText: '#FFFFFF',
            light: '#CCEAF6',
        },
        cmpSelected: {
            main: '#01408B',
            dark: '#01408B',
            contrastText: '#FFFFFF',
        },
        cmpBlack: {
            main: '#000000',
            dark: '#000000',
            doply: '#3A3A3A',
            contrastText: '#FFFFFF',
        },
        cmpWarning: {
            main: '#707070',
            dark: '#707070',
            contrastText: '#707070',
        },
        white: '#FFFFFF',
        black: {
            dark: '#000000',
            medium: '#1E1E1E',
            nero: '#161616',
        },
        yellow: '#F9E61E',
        gray: {
            dark: '#626262',
            medium: '#A5A5A5',
            lowMedium: '#CCCCCC',
            low: '#C8C8C8',
            light: '#E9E9E9',
            lighter: '#F8F8F8',
            lightest: '#E0E0E0',
            main: '#b0b0b0',
            44: '#707070',
            33: '#333333',
            darkest: '#a3a1a1',
            darker: '#E8E8E8',
            lightMedium: '#A5A5A5',
            graniteGray: '#616161',
        },
        blue: {
            main: '#0047BA',
            30: '#003182',
            40: '#6690D5',
            60: '#001C4A',
            70: '#99B5E3',
            80: '#00358F',
            90: '#E5ECF8',
            alice: '#E3F0FF',
            dark: '#27344B',
            light: '#BFC6D6',
            lighter: '#66c6ea',
            lightest: '#AFB8CC',
            lightMedium: '#3381FF',
            strongDark: '#005CBC',
        },
        cyan: {
            main: '#00A0DD',
            30: '#003182',
            40: '#6690D5',
            60: '#001C4A',
            70: '#99D9F1',
            80: '#4D5970',
            90: '#E5F5FC',
        },
        green: {
            main: '#91C24C',
            30: '#658835',
            40: '#BDDA93',
            60: '#3A4E1E',
            70: '#D3E7B7',
            80: '#16BF5E',
            90: '#F4F9ED',
        },
        orange: {
            main: '#F99C24',
            30: '#AF6D19',
            40: '#FBC37B',
            60: '#643E0E',
            70: '#FDD7A7',
            90: '#FFF5E8',
        },
        pink: {
            main: '#E50695',
            30: '#A10568',
            40: '#EF69BF',
            60: '#5C023B',
            70: '#F59BD4',
            90: '#FDE5F4',
        },
        red: {
            main: '#DB1E36',
            30: '#991525',
            40: '#E97886',
            60: '#580C15',
            70: '#F1A5AE',
            90: '#FCE8EA',
            dark: '#d02424',
            light: '#FF3939',
            darkest: '#D40020',
        },
        background: {
            $color_background_default: '#F3F7FD',
            $color_background_hover: '#E5E5E5',
            $color_background_layer_01: '#FBFCFE',
            $color_background_layer_02: '#EFF5FC',
            $color_background_button_primary: '#005CBC',
            $color_background_button_primary_hover: '#004A96',
            $color_info_background: '#CCEBF9',
            $color_background_button_primary_danger: '#D40020',
            $color_background_button_primary_danger_hover: '#AA001A',
            $color_background_button_primary_danger_active: '#7f0013',
        },
        border: {
            $color_border_default: '#D7E5F8',
            $color_border_divider: '#DFDFDF',
            $border_radius: '24px',
            $color_border_input: '#C0C0C0',
            $color_border_input_hover: '#818181',
            $color_border_input_focus: '#337DC9',
            $color_border_secondary_button: '#005CBC',
            $color_border_secondary_button_hover: '#004A96',
        },
        textColors: {
            $color_text_primary: '#3A3A3A',
            $color_text_secondary: '#616161',
            $color_text_tertiary: '#A0A0A0',
        },
        interAction: {
            $color_interaction_active: '#005CBC',
            $color_interaction_hover: '#DFEAF9',
        },
        button: {
            $color_button_primary_hover: '#004A96',
            $color_buttongroup_default: '#E7EFFB',
            $color_button_primary_active: '#003771',
        },
        tag: {
            $auxiliary_600_background_color: '#0078a7',
            $red_600_background_color: '#aa001a',
            $green_600_background_color: '#006a42',
        },
    },
});

export default theme;
