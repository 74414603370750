import { useMutation } from 'react-query';
import React, { useState } from 'react';
import axios, { endPoints } from 'api/request';
import { useRecoilState } from 'recoil';
import Btn from 'components/button';
import { Box } from '@mui/material';
import GithubOnboardingStyles from 'pages/github-onboarding/style';
import { OrderPayload, toggleModal } from 'pages/github-onboarding/store';
import defaultOrderValues from 'pages/github-onboarding/store/defaultValues';
import { commonStyles as style } from 'assets/style';
import ReposSearch from './ReposSearch';
import convertRepoName from '../../../../utils/convertRepoName';
import ReposList from './ReposList';

const AdoReposToMigrate = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isOpen, setIsOpen] = useRecoilState(toggleModal);
    const [reposToMigrate, setReposToMigrate] = useState([]);

    const reposListGetApi = useMutation(() =>
        axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.Jwt?.sonarQubeRepo}/${order?.customProperties?.adoProjectName}`)
    );

    const setADORepos = () => {
        const { reposAssignedToTeams } = defaultOrderValues;

        setOrder({
            ...order,
            reposToMigrate,
            reposAssignedToTeams,
        });
    };

    const modalClose = () =>
        setIsOpen({
            ...isOpen,
            isReposToMigrateOpen: false,
            isTeamsAndMembersOpen: false,
            isAssignReposOpen: false,
        });

    const handleSave = () => {
        setADORepos();
        modalClose();
    };

    const handleAddRepo = (newRepo) => {
        setReposToMigrate((prevRepos) => [...prevRepos, { adoName: newRepo, githubName: convertRepoName(order.customProperties.capability, newRepo) }]);
    };

    const handleRemoveRepo = (removedRepo) => {
        setReposToMigrate((prevRepos) => prevRepos.filter((repo) => repo.adoName !== removedRepo.adoName));
    };

    React.useEffect(() => {
        if (reposListGetApi?.isSuccess) {
            setOrder({
                ...order,
                availableProjectRepos: reposListGetApi?.data?.data?.repoNames,
            });
        }
    }, [reposListGetApi?.isSuccess]);

    React.useEffect(() => {
        if (!order?.availableProjectRepos?.length) {
            reposListGetApi.mutate();
        }
        if (order.reposToMigrate.length) {
            const repos = order.reposToMigrate.map((repo) => ({
                adoName: repo.adoName,
                githubName: convertRepoName(order.customProperties.capability, repo.adoName),
            }));
            setReposToMigrate(repos);
        }
    }, []);

    return (
        <>
            <Box sx={GithubOnboardingStyles.modal.wrapper}>
                <Box sx={{ ...GithubOnboardingStyles.modal.contentWrapper, paddingTop: '10px' }}>
                    <ReposSearch isLoading={reposListGetApi.isLoading} handleAddRepo={handleAddRepo} reposToMigrate={reposToMigrate} />
                    {reposToMigrate.length ? <ReposList reposToMigrate={reposToMigrate} handleRemoveRepo={handleRemoveRepo} /> : null}
                </Box>
                <Box className="footer" sx={GithubOnboardingStyles.modal.actionWrapper} gap={2}>
                    <Btn
                        className="custom-btn btn-disabled"
                        variant="contained"
                        disabled={!reposToMigrate.length}
                        onClick={() => {
                            handleSave();
                        }}
                        data-testid="config-save-btn"
                        sx={style.modalSaveButton}
                    >
                        Save
                    </Btn>
                    <Btn
                        className="custom-btn"
                        onClick={modalClose}
                        variant="outlined"
                        sx={style.modalCancelButton}
                        layout="outline"
                        data-testid="config-cancel-btn"
                    >
                        Cancel
                    </Btn>
                </Box>
            </Box>
        </>
    );
};

export default React.memo(AdoReposToMigrate);
