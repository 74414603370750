const newFooterStyles = {
    footer: {
        flex: '0 1 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // background: '#27344B',
        background: (theme) => theme.palette.background.$color_background_layer_01,
        padding: (theme) => theme.spacing(0, 3),
        color: (theme) => theme.palette.textColors.$color_text_secondary,
        borderTop: '1px solid #DFDFDF',
        fontSize: 14,
        '& .link': {
            color: (theme) => theme.palette.textColors.$color_text_secondary,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        height: '72px',
    },
    copytext: {
        font: (theme) => theme.typography.fontSize.$font_small_regular,
        // fontSize: '16px',
        // fontFamily: (theme) => theme.typography.fontFamily,
        color: (theme) => theme.palette.textColors.$color_text_secondary,
        // fontWeight: 'normal',
        display: 'flex',
        padding: '11px',
    },
    copyRightText: { fontSize: '100%', mr: '10px' },
    rightBox: {
        font: (theme) => theme.typography.fontSize.$font_body_regular,
        // fontSize: '16px',
        // fontFamily: (theme) => theme.typography.fontFamily,
        color: (theme) => theme.palette.textColors.$color_text_secondary,
        // fontWeight: 'normal',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    infoStyle: { color: (theme) => theme.palette.white, marginLeft: (theme) => theme.spacing(-0.875) },
};

export default newFooterStyles;
