export const Styles = {
    formCard: {
        paper: {
            height: '100%',
            borderRadius: (theme) => theme.spacing(0.75),
            marginBottom: (theme) => theme.spacing(3.125),
            boxShadow: '0 0 24px rgba(0, 92, 188, 0.08)',
            background: (theme) => theme.palette.background.$color_background_layer_01,
        },
        column: {
            padding: (theme) => theme.spacing(2),
            position: 'relative',
        },
        heading: {
            color: (theme) => theme?.palette?.cmpPrimary.main,
            font: (theme) => theme.typography.fontSize.$font_heading_h6_bold,
        },
        headerText: {
            fontSize: '10px',
        },
        wrapper: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: (theme) => theme.spacing(2), paddingBottom: '5px' },
    },
};

export default Styles;
