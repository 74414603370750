import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useSetRecoilState } from 'recoil';
import { Box, Stack } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import Empty from 'components/empty';
import ErrorFallback from 'components/error-fallback';
import approvalStyle from 'pages/approvals/style';
import { selectedApprovalOrder, selectedRows, multiApprovalDialog, isApproveAll, isApprove, isOrderDetailsOpen } from './store';

const ApprovalHeader = React.lazy(() => import('pages/approvals/approval-header'));
const ApprovalList = React.lazy(() => import('pages/approvals/approval-list'));

const ApprovalsPage = () => {
    const resetSelectedOrders = useSetRecoilState(selectedApprovalOrder);
    const resetSelectedRows = useSetRecoilState(selectedRows);
    const resetMultiApproval = useSetRecoilState(multiApprovalDialog);
    const resetIsApproveAll = useSetRecoilState(isApproveAll);
    const resetIsApprove = useSetRecoilState(isApprove);
    const resetIsOrderDetailsOpen = useSetRecoilState(isOrderDetailsOpen);

    const resetStore = () => {
        resetSelectedOrders(undefined);
        resetSelectedRows([]);
        resetMultiApproval(false);
        resetIsApproveAll(false);
        resetIsApprove(undefined);
        resetIsOrderDetailsOpen(false);
    };

    // eslint-disable-next-line
    React.useEffect(() => {
        // resetStore();
        return () => {
            resetStore();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>My Approvals - DevX Infra</title>
            </Helmet>
            <Suspense
                fallback={
                    <Stack sx={approvalStyle.loadingStack}>
                        <Empty isLoading color="#000000" />
                    </Stack>
                }
            >
                <Box sx={approvalStyle.container}>
                    <ApprovalHeader />
                    <ApprovalList />
                </Box>
            </Suspense>
        </ErrorBoundary>
    );
};

export default React.memo(ApprovalsPage);
