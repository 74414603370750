import React, { lazy, memo, Suspense, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid, CircularProgress } from '@mui/material';
import GithubOnboardingStyles from './style';
import InputForm from './components/InputForm';
import ConfigureServiceModal from './components/Form/ServiceResources/ConfigureServiceModal';
import PreviewOrderDetails from './components/preview/preview-order-details';

const StickyHeader = lazy(() => import('pages/github-onboarding/components/sticky-header'));

const GithubOnboarding = () => {
    const [showPreview, setShowPreview] = useState(false);

    const handlePreview = () => setShowPreview((prev) => !prev);

    return (
        <>
            <Helmet>
                <title>GitHub Project Onboarding</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={GithubOnboardingStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={GithubOnboardingStyles.circularProgressColor} />
                    </Box>
                }
            >
                <>
                    <StickyHeader isPreview={showPreview} handlePreview={handlePreview} />
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <Grid display="flex" flexDirection="column" padding={4} gap={3} data-testId="github-onboard">
                                {showPreview && <PreviewOrderDetails />}
                                <Box sx={{ display: showPreview ? 'none' : 'block' }}>
                                    <InputForm />
                                    <ConfigureServiceModal />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </Suspense>
        </>
    );
};

export default memo(GithubOnboarding);
