import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Chip, Typography } from '@mui/material';
import GithubOnboardingStyles from 'pages/github-onboarding/style';

const AssigReposTeam = ({ teamName, isDefaultTeam, children }) => {
    return (
        <Card sx={GithubOnboardingStyles.card}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <Typography variant="h3" mr={1} sx={GithubOnboardingStyles.title}>
                    {teamName}
                </Typography>
                <Chip label={isDefaultTeam ? 'Default' : 'Custom'} size="small" color="primary" />
            </Box>
            {children}
        </Card>
    );
};

AssigReposTeam.propTypes = {
    teamName: PropTypes.string,
    isDefaultTeam: PropTypes.bool,
    children: PropTypes.node,
};

AssigReposTeam.defaultProps = {
    teamName: '',
    isDefaultTeam: true,
    children: null,
};

export default React.memo(AssigReposTeam);
