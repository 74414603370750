import React from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { FiAlertTriangle } from 'react-icons/fi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import toast from 'react-hot-toast';
import { commonStyles as style } from 'assets/style';
import { toggleConfirmModel } from './store';

const ConfirmModal = () => {
    const [modal, setModal] = useRecoilState(toggleConfirmModel);

    const handleYes = () => {
        if (modal?.type === 'delete') toast.success('Deleted successfully');
        setModal({ ...modal, isOpen: false });
    };
    const handleNo = () => {
        setModal({ ...modal, isOpen: false });
    };
    return (
        <>
            <Dialog open={modal?.isOpen} onClose={handleNo} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogContent
                    sx={{
                        maxWidth: modal?.type === 'delete' ? '375px' : '450px',
                        border: (theme) => `10px solid ${theme.palette.border.$color_border_default}`,
                    }}
                >
                    <Box display="flex" flexDirection="column" alignItems="center" style={{ textAlign: 'center' }}>
                        {modal?.type === 'save' && <CheckCircleIcon sx={{ fontSize: '50px', color: '#16BF5E' }} />}
                        {modal?.type === 'delete' && <FiAlertTriangle color="#d02424" size={70} />}
                        <Typography variant="h5">{modal?.message}</Typography>
                        {modal?.type === 'save' && <Box sx={{ color: '#626262', fontSize: '18px' }}>You want to add App defaults</Box>}
                    </Box>

                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button sx={style.modalSaveButton} variant="contained" onClick={() => handleYes()} className="uppercase tracking-wider text-sm">
                            {modal?.type !== 'save' ? 'Yes' : 'Continue'}
                        </Button>
                        <Button
                            variant="outlined"
                            // style={{ marginLeft: '10px' }}
                            sx={{ ...style.modalCancelButton, marginLeft: '10px' }}
                            onClick={() => handleNo()}
                            layout="outline"
                            className="uppercase tracking-wider text-sm"
                        >
                            {modal?.type !== 'save' ? 'No' : 'DO LATER'}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default ConfirmModal;
