/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { isLoadingCostApiState, sspSubmitOrder, toggleSspModels, sourceNameErrors } from 'pages/provisioning/nsg-automation-rules/store';
import { selectOrder } from 'pages/orders/store';
// import ProvisioningStyles from 'pages/provisioning/nsg-automation-rules/style';
import Btn from 'components/button';
import { getPayload } from 'pages/provisioning/nsg-automation-rules/getPayload';
import { useMutation } from 'react-query';
import { commonStyles as style } from 'assets/style';
import { isReadyToSubmit } from '../utils/formValidation';

const ErrorBanner = lazy(() => import('pages/reference-implementation/components/error-banner'));

const SubmitBtn = () => {
    const history = useHistory();
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const submitApi = useMutation((payload) => axios.post(`${endPoints.ssp.nsgAutomationRules.submit}`, payload));
    const reSubmitApi = useMutation((payload) => axios.put(`${endPoints.ssp.nsgAutomationRules.reSubmit}/${cartItemId}`, payload));
    const [isLoading, setLoading] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [isLoadingCostApi, setIsLoadingCostApi] = useRecoilState(isLoadingCostApiState);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const [, setSelectedOrderId] = useRecoilState(selectOrder);
    const [errorBannerOpen, setErrorBannerOpen] = React.useState(false);
    const errors = useRecoilValue(sourceNameErrors);

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };
    const handleRedirect = async () => {
        await setSelectedOrderId(cartItemId);
        setLoading(false);
        history.push('/orders');
    };
    const handleSubmit = async () => {
        setLoading(true);
        const payload = await getPayload({ order, catalogCode, catalogServiceId });
        setLoading(false);
        try {
            if (cartItemId && operation && !catalogServiceId) {
                const updateCartItem = await axios.put(`${endPoints.cart.addItem}/${cartItemId}`, payload);
                if (updateCartItem?.isSuccess) {
                    setModal({ ...modal, isUpdatedToCart: true });
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
            if (cartItemId && operation && catalogServiceId) {
                reSubmitApi.mutate(payload);
                // const saveChanges = await axios.put(
                //     `${endPoints.order.saveOrder.replace('{orderId}', cartItemId).replace('{catalogServiceId}', catalogServiceId)}`,
                //     payload?.catalogService
                // );
                // if (saveChanges?.isSuccess) {
                //     handleRedirect();
                // } else {
                //     setLoading(false);
                // }
            }
            if (!cartItemId && !operation && !catalogServiceId) {
                submitApi?.mutate(payload);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            // eslint-disable-next-line
            console.log('Exception during schema validation', e);
        }
    };

    const handleValidation = () => {
        const errorValid = errors?.some((error) => error !== '');
        if (isReadyToSubmit(order)?.isValid && !errorValid) {
            handleSubmit();
        } else {
            handleClickErrorBanner();
        }
        setOrder({ ...order, isTouched: true });
    };
    React.useEffect(() => {
        if (submitApi?.isSuccess) {
            setModal({ ...modal, isSubmitOrderSuccess: true, orderId: submitApi?.data?.data?.orderId });
        }
    }, [submitApi?.isSuccess]);
    React.useEffect(() => {
        if (reSubmitApi?.isSuccess) {
            handleRedirect();
        }
    }, [reSubmitApi?.isSuccess]);
    React.useEffect(() => {
        setIsLoadingCostApi({ isLoading: reSubmitApi?.isLoading || submitApi?.isLoading });
    }, [submitApi?.isLoading, reSubmitApi?.isLoading]);
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {cartItemId && operation && catalogServiceId && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        color="cmpPrimary"
                        disabled={isLoading || submitApi?.isLoading || reSubmitApi?.isLoading || isLoadingCostApi?.isLoading}
                        sx={style?.primaryButton}
                        data-testid="save-changes-btn"
                    >
                        {isLoading || submitApi?.isLoading || reSubmitApi?.isLoading ? <CircularProgress color="inherit" size={16} /> : 'Re-Submit Order'}
                    </Btn>
                )}
                {!cartItemId && !operation && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        color="cmpPrimary"
                        disabled={isLoading || submitApi?.isLoading || reSubmitApi?.isLoading || isLoadingCostApi?.isLoading}
                        sx={style?.primaryButton}
                        data-testid="add-to-cart-btn"
                    >
                        {isLoading || submitApi?.isLoading || reSubmitApi?.isLoading ? <CircularProgress color="inherit" size={16} /> : 'Submit Order'}
                    </Btn>
                )}
            </Box>
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click submit."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </>
    );
};

SubmitBtn.propTypes = {};

SubmitBtn.defaultProps = {};

export default React.memo(SubmitBtn);
