import React from 'react';
import { Box, Divider, Typography, Paper } from '@mui/material';
import Btn from 'components/button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import { Link, useParams } from 'react-router-dom';
import CreateLandingZone from 'pages/app-profiles/landing-zones/CreateLandingZone';
import { useRecoilState } from 'recoil';
import CreateAppProfile from 'pages/app-profiles/profile/CreateAppProfile';
import CreateAppDefault from 'pages/app-profiles/CreateAppDefault';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmDeleteModal from 'pages/app-profiles/landing-zones/ConfirmDeleteModal';
import { useTranslation } from 'react-i18next';
import { appProfileTypeState, landZoneListFilter, profileForm, toggleCreateLandingZone, toggleCreateProfile } from 'pages/app-profiles/store';
import profileStyle from 'pages/app-profiles/profile/style';
import LandZonesFilter from 'pages/app-profiles/landing-zones/components/Form/landZones-filter';
import LandingZoneList from 'pages/app-profiles/landing-zones/LandingZoneList';
import LzSummary from 'pages/app-profiles/landing-zones/LzSummary';
import { getUserScopes } from 'utils';
import { scope } from 'utils/scope';
import toast from 'react-hot-toast';
import { getAppProfileData } from 'pages/app-profiles/profile/getAppProfileData';
import { getPayload } from 'pages/app-profiles/profile/getPayload';
import { isAppOwner } from 'pages/app-profiles/utils';
import { commonStyles as style } from 'assets/style';
import { clearLandingZoneFromLocalStorage } from '../landing-zones/utils';

const AppProfileDetailsPage = () => {
    const { t } = useTranslation();
    const [pageSize] = React.useState('10');
    const [appProfileType, setAppProfileType] = useRecoilState(appProfileTypeState);
    const [profForm, setProfForm] = useRecoilState(profileForm);
    const [profileModal, setProfileModal] = useRecoilState(toggleCreateProfile);
    const [lzModal, setLZModal] = useRecoilState(toggleCreateLandingZone);
    // const [form, setFormData] = useRecoilState(landingZoneForm);
    const [lzFilterObj] = useRecoilState(landZoneListFilter);
    const { id } = useParams();
    const profileByIDApi = useMutation((payload) => axios.get(`${endPoints.appProfile.appProfileById}/${payload?.id}`));
    const landingZoneListApi = useMutation((payload) => axios.post(endPoints.landingZones.landingZones, payload));
    const updateApi = useMutation((payload) => axios.put(`${endPoints.appProfile.submitAppProfile}`, payload));
    const handleApproval = () => {
        const formData = getAppProfileData({ data: profileByIDApi?.data?.data });
        const payload = getPayload({ data: formData, type: 'edit' });
        const newPayload = { ...payload, isDraft: false };
        updateApi.mutate(newPayload);
    };
    React.useEffect(() => {
        if (profileByIDApi?.data?.data?.name && !landingZoneListApi?.isLoading) {
            landingZoneListApi.mutate({
                appProfileName: profileByIDApi?.data?.data?.name,
                appTier: '',
                cloudProvider: '',
                cloudService: '',
                environment: '',
                page: 1,
                region: '',
                size: 5,
            });
        }
    }, [profileByIDApi?.data?.data?.name, pageSize, lzFilterObj]);
    React.useEffect(() => {
        profileByIDApi.mutate({ id });
    }, [appProfileType]);
    React.useEffect(() => {
        if (updateApi?.isSuccess) {
            setAppProfileType({ ...appProfileType, pageNumber: 1 });
            toast.success(updateApi?.data?.messages[0]);
        }
    }, [updateApi?.isSuccess]);

    React.useEffect(() => {
        clearLandingZoneFromLocalStorage();
        return () => {
            clearLandingZoneFromLocalStorage();
        };
    }, []);

    return (
        <>
            <CreateAppDefault />
            <CreateLandingZone appProfileName={profileByIDApi?.data?.data?.name} />
            <ConfirmDeleteModal />
            <CreateAppProfile />
            <LzSummary />
            <Box data-testid="landing-zone-header" sx={profileStyle?.profiledetailsheader?.wrapper}>
                <Box sx={profileStyle?.profiledetailsheader?.container}>
                    <Typography sx={profileStyle?.profiledetailsheader?.typography}>
                        <IconButton component={Link} to="/app-profile">
                            <ArrowBackIosNewIcon sx={profileStyle?.profiledetailsheader?.backIcon} />
                        </IconButton>
                        {profileByIDApi?.data?.data?.name}
                    </Typography>
                </Box>
            </Box>

            <Box sx={profileStyle?.details?.wrapper}>
                {profileByIDApi?.isLoading && (
                    <Box sx={profileStyle?.details?.loader?.wrapper}>
                        <CircularProgress color="inherit" size={20} sx={profileStyle?.details?.loader?.circleIcon} />
                    </Box>
                )}
                {!profileByIDApi?.isLoading && (
                    <>
                        <Paper sx={profileStyle?.details?.profileHeader?.wrapper} elevation={0}>
                            <Box sx={profileStyle?.details?.profileHeader?.subWrapper}>
                                <Typography variant="h6" style={profileStyle?.details?.profileHeader?.typography}>
                                    <span style={profileStyle?.details?.profileHeader?.headingTxt}>{t([`appProfile.title.appDetails`])}</span>
                                </Typography>
                                <Box sx={{ display: 'flex' }}>
                                    {isAppOwner({ appOwnerGpid: profileByIDApi?.data?.data?.owner?.id }) && profileByIDApi?.data?.data?.isDraft && (
                                        <Btn
                                            sx={profileStyle?.details?.profileHeader?.modifyBtn}
                                            variant="contained"
                                            color="cmpPrimary"
                                            onClick={() => handleApproval()}
                                            data-testid="approve-btn"
                                            disabled={updateApi?.isLoading}
                                        >
                                            Approve
                                            {updateApi?.isLoading && (
                                                <CircularProgress sx={profileStyle?.details?.profileHeader?.circularIcon} color="inherit" size={14} />
                                            )}
                                        </Btn>
                                    )}
                                    {(getUserScopes()?.includes(scope[2]) || isAppOwner({ appOwnerGpid: profileByIDApi?.data?.data?.owner?.id })) && (
                                        <Btn
                                            // sx={profileStyle?.details?.profileHeader?.modifyBtn}
                                            sx={{ ...style.primaryMediumButton, marginLeft: '10px' }}
                                            variant="contained"
                                            // color="cmpPrimary"
                                            onClick={() => {
                                                const appProfileData = getAppProfileData({ data: profileByIDApi?.data?.data });
                                                setProfForm({
                                                    ...profForm,
                                                    ...appProfileData,
                                                });
                                                setProfileModal({ ...profileModal, isOpen: true, type: 'edit' });
                                            }}
                                            data-testid="edit-app-profile"
                                            className="btn-disabled"
                                            disabled={updateApi?.isLoading}
                                        >
                                            Edit App Profile
                                        </Btn>
                                    )}
                                </Box>
                            </Box>
                            <Divider />
                            <Box sx={profileStyle?.details?.profileDetails?.wrapper}>
                                <Box sx={profileStyle?.details?.profileDetails?.item}>
                                    <Box sx={profileStyle?.details?.profileDetails?.label}>{t([`appProfile.label.appName`])}</Box>
                                    <Box sx={profileStyle?.details?.profileDetails?.value}>{profileByIDApi?.data?.data?.name || '-'}</Box>
                                </Box>
                                <Divider orientation="vertical" flexItem />
                                <Box sx={profileStyle?.details?.profileDetails?.item}>
                                    <Box sx={profileStyle?.details?.profileDetails?.label}>{t([`appProfile.label.businessCI`])}</Box>
                                    <Box sx={profileStyle?.details?.profileDetails?.value}>{profileByIDApi?.data?.data?.businessCi || '-'}</Box>
                                </Box>
                                <Divider orientation="vertical" flexItem />
                                <Box sx={profileStyle?.details?.profileDetails?.item}>
                                    <Box sx={profileStyle?.details?.profileDetails?.label}>{t([`appProfile.label.financialDetails`])}</Box>
                                    <Box sx={profileStyle?.details?.profileDetails?.value}>{profileByIDApi?.data?.data?.financialDetails || '-'}</Box>
                                </Box>
                            </Box>
                            <Divider />
                            <Box sx={profileStyle?.details?.profileDetails?.wrapper}>
                                <Box sx={profileStyle?.details?.profileDetails?.item}>
                                    <Box sx={profileStyle?.details?.profileDetails?.label}>{t([`appProfile.label.projectName`])}</Box>
                                    <Box sx={profileStyle?.details?.profileDetails?.value}>{profileByIDApi?.data?.data?.projectName || '-'}</Box>
                                </Box>
                                <Divider orientation="vertical" flexItem />
                                <Box sx={profileStyle?.details?.profileDetails?.item}>
                                    <Box sx={profileStyle?.details?.profileDetails?.label}>{t([`appProfile.label.recoveryPriority`])}</Box>
                                    <Box sx={profileStyle?.details?.profileDetails?.value}>{profileByIDApi?.data?.data?.recoveryPriority || '-'}</Box>
                                </Box>
                                <Divider orientation="vertical" flexItem />
                                <Box sx={profileStyle?.details?.profileDetails?.item}>
                                    <Box sx={profileStyle?.details?.profileDetails?.label}>{t([`appProfile.label.billingEntity`])}</Box>
                                    <Box sx={profileStyle?.details?.profileDetails?.value}>{profileByIDApi?.data?.data?.billingEntity || '-'}</Box>
                                </Box>
                            </Box>
                            <Divider />
                            <Box sx={profileStyle?.details?.profileDetails?.wrapper}>
                                <Box sx={profileStyle?.details?.profileDetails?.item}>
                                    <Box sx={profileStyle?.details?.profileDetails?.label}>{t([`appProfile.label.sector`])}</Box>
                                    <Box sx={profileStyle?.details?.profileDetails?.value}>{profileByIDApi?.data?.data?.sector || '-'}</Box>
                                </Box>
                                <Divider orientation="vertical" flexItem />
                                <Box sx={profileStyle?.details?.profileDetails?.item}>
                                    <Box sx={profileStyle?.details?.profileDetails?.label}>{t([`appProfile.label.appOwner`])}</Box>
                                    <Box sx={profileStyle?.details?.profileDetails?.value}>{profileByIDApi?.data?.data?.owner?.name || '-'}</Box>
                                </Box>
                                <Divider orientation="vertical" flexItem />
                                <Box sx={profileStyle?.details?.profileDetails?.item}>
                                    <Box sx={profileStyle?.details?.profileDetails?.label}>{t([`appProfile.label.status`])}</Box>
                                    <Box sx={profileStyle?.details?.profileDetails?.value}>{profileByIDApi?.data?.data?.isDraft ? 'Draft' : 'Approved'}</Box>
                                </Box>
                            </Box>
                        </Paper>
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 5 }}>
                                <Typography sx={{ color: '#000000', fontSize: '24px', fontWeight: 'medium' }}>Landing Zones</Typography>

                                <Box>
                                    <LandZonesFilter appProfileName={profileByIDApi?.data?.data?.name || ''} />
                                    {getUserScopes()?.includes(scope[2]) && (
                                        <Btn
                                            variant="contained"
                                            // color="cmpPrimary"
                                            onClick={() => {
                                                // setFormData({ ...form, cloudProvider: 'Azure', cloudService: 'IaaS/VM' });
                                                setLZModal({ ...lzModal, isOpen: true, type: 'create', appProfileName: profileByIDApi?.data?.data?.name });
                                            }}
                                            // sx={profileStyle?.details?.profileHeader?.newLzBtn}
                                            sx={{ ...style.primaryMediumButton, marginLeft: '10px', width: 'auto' }}
                                            data-testid="new-landing-zone"
                                        >
                                            New Landing Zone
                                        </Btn>
                                    )}
                                </Box>
                            </Box>
                            <LandingZoneList appProfileName={profileByIDApi?.data?.data?.name} appOwnerGpid={profileByIDApi?.data?.data?.owner?.id} />
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default AppProfileDetailsPage;
