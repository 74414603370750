import React from 'react';
import { FormControl, FormControlLabel, Typography, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import Btn from 'components/button';
import { OrderPayload, toggleModal, isNonPrivilegedADGroupValid } from 'pages/reference-implementation/provisioning/RI002O/store';
import riStyle, { CustomRadioGroup, CustomRadio } from 'pages/reference-implementation/Style';
import FormCard from 'pages/reference-implementation/components/form-card';
import NonPrivilegedADGroupOwner from './NonPrivilegedADGroupOwner';

const NonPrivilegedADGroup = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [modal, setModal] = useRecoilState(toggleModal);
    const [, setNonPrivilegedADGroupValid] = useRecoilState(isNonPrivilegedADGroupValid);

    const setNonPrivilegedADGroup = (nonPrivilegedADGroup) => {
        setOrder({
            ...order,
            nonPrivilegedADGroup,
            nonPrivilegedADGroupOwner: null,
            nonPrivilegedADGroupOwnerDetails: null,
            nonPrivilegedADGroupList: [{ readerValue: '' }],
        });
        setNonPrivilegedADGroupValid(false);
    };
    const handleOnSelectChange = (selected) => {
        setNonPrivilegedADGroup(selected);
    };
    const getButtonLabel = () => {
        let btnLabel = 'Select Non-Privileged Group(s)';
        if (order?.nonPrivilegedADGroupList?.[0]?.readerValue?.length === 0) {
            btnLabel = 'Select Non-Privileged Group(s)';
        } else {
            btnLabel = `You selected (${order?.nonPrivilegedADGroupList?.length}) Non-privileged ${
                order?.nonPrivilegedADGroupList?.length === 1 ? 'Group' : 'Groups'
            }`;
        }
        return btnLabel;
    };
    return (
        <FormCard>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={riStyle?.form?.accessMgmt?.heading}>NON-PRIVILEGED GROUPS</Typography>
                    <Typography my="10px" sx={riStyle?.form?.accessMgmt?.label}>
                        Do you have a Non-Privileged Active Directory Group <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <FormControl data-testid="non-priv-radio-box">
                        <CustomRadioGroup
                            aria-label="NonPrivilegedADGroupADGroup"
                            name="nonPrivilegedADGroupadgroupRadioBtnGroup"
                            row
                            value={order?.nonPrivilegedADGroup || null}
                            onChange={(event, value) => handleOnSelectChange(value)}
                        >
                            <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes, I do" />
                            <FormControlLabel
                                value="No"
                                sx={{
                                    marginLeft: '60px',
                                }}
                                control={<CustomRadio />}
                                label="No, I don't"
                            />
                        </CustomRadioGroup>
                    </FormControl>
                </Grid>
                {order?.nonPrivilegedADGroup === 'Yes' && (
                    <Grid item xs={12} sm={6}>
                        <Btn
                            className="btn-disabled custom-btn"
                            fullWidth
                            onClick={() => {
                                setModal({
                                    ...modal,
                                    isNonPrivilegedADGroupOpen: true,
                                });
                            }}
                            size="small"
                            variant="contained"
                            color="cmpPrimary2"
                            data-testid="add-con"
                            sx={riStyle.form.privilegedButton}
                        >
                            {getButtonLabel()}
                        </Btn>
                    </Grid>
                )}
                {order?.nonPrivilegedADGroup === 'No' && (
                    <Grid item xs={12} sm={6}>
                        <NonPrivilegedADGroupOwner />
                    </Grid>
                )}
            </Grid>
        </FormCard>
    );
};

export default NonPrivilegedADGroup;
