const Styles = {
    summaryModal: {
        heading: {
            color: (theme) => theme?.palette?.black?.dark,
            fontSize: (theme) => theme.spacing(2.5),
            fontWeight: 'medium',
        },
        wrapper: {
            width: 704,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        contentWrapper: { flexGrow: 1, px: (theme) => theme.spacing(2), overflowY: 'scroll' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(0.25) },
        supportWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center', p: (theme) => theme.spacing(0.25) },
        circularIcon: { marginLeft: (theme) => theme.spacing(1.25) },
    },
};

export default Styles;
