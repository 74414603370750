import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAxios } from 'hooks';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import ProvisioningHeader from 'pages/provisioning/day2-operation/ProvisioningHeader';
import ApplicationDetails from 'pages/provisioning/day2-operation/ApplicationDetails';
import SspForm from 'pages/provisioning/day2-operation/SspForm';
import Tags from 'pages/provisioning/day2-operation/Tags';
import AddedToCartConfirmModal from 'pages/provisioning/day2-operation/AddedToCartConfirmModal';
import UpdatedToCartConfirmModal from 'pages/provisioning/day2-operation/UpdatedToCartConfirmModal';
import QuickConfig from 'pages/provisioning/day2-operation/QuickConfig';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import {
    environmentDetails,
    isSkuConfigFullfilled,
    isStorageDiskConfigFullfilled,
    isDataDiskConfigFullfilled,
    isMandatoryTagsFullfilled,
    orderId,
    quickConfigDetails,
    sspSubmitOrder,
    toggleSspModels,
} from 'pages/provisioning/store/day2-operation';
import ShowCostDetails from 'pages/provisioning/day2-operation/ShowCostDetails';
import ConfigDataDiskModal from 'pages/provisioning/day2-operation/ConfigDataDiskModal';
import ConfigStorageDiskModal from 'pages/provisioning/day2-operation/ConfigStorageDiskModal';
import ConfigSkuModal from 'pages/provisioning/day2-operation/ConfigSkuModal';
import ConfirmDD from 'pages/provisioning/day2-operation/ConfirmDD';
import {
    resetEnvironmentDetails,
    resetOrder,
    resetToggleSspModal,
    formOrderObjFromCart,
    formOrderObjFromOrderDetails,
    isSkuFormFilled,
    isHpFormFilled,
    isStorageDiskFormFilled,
} from 'pages/provisioning/day2-operation/utils';

const Day2OperationPage = () => {
    const [isLoading, setLoading] = React.useState(true);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, setIsBackendPoolsConfig] = useRecoilState(isSkuConfigFullfilled);
    const [, setIsStorageDiskConfig] = useRecoilState(isStorageDiskConfigFullfilled);
    const [, setIsHPConfig] = useRecoilState(isDataDiskConfigFullfilled);
    const [, resetOrderId] = useRecoilState(orderId);
    const [, resetEnvDetails] = useRecoilState(environmentDetails);
    const [, resetIsMandatoryTagsFullfilled] = useRecoilState(isMandatoryTagsFullfilled);
    const [, resetToggleSspModels] = useRecoilState(toggleSspModels);
    const [, resetQuickConfig] = useRecoilState(quickConfigDetails);
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const axios = useAxios();

    async function getCosts() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}/costs`);
        return data;
    }
    async function checkCatalog() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}`);
        const costs = await getCosts();
        if (cartItemId && operation && !catalogServiceId) {
            const { data: cartItem } = await axios.get(`${endPoints.cart.getCartItem}/${cartItemId}`);
            const orderObj = await formOrderObjFromCart(cartItem);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                emptyCost: costs,
            }));
            await setIsBackendPoolsConfig(isSkuFormFilled({ order }));
            await setIsStorageDiskConfig(isStorageDiskFormFilled({ order }));
            await setIsHPConfig(isHpFormFilled({ order }));
        }
        if (cartItemId && operation && catalogServiceId) {
            const { data: orderDetails } = await axios.get(`${endPoints.order.orderDetails}/${cartItemId}`);
            const orderObj = await formOrderObjFromOrderDetails(catalogServiceId, orderDetails);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
            }));
            await setIsBackendPoolsConfig(isSkuFormFilled({ order }));
            await setIsStorageDiskConfig(isStorageDiskFormFilled({ order }));
            await setIsHPConfig(isHpFormFilled({ order }));
        } else {
            setOrder((previous) => ({
                ...previous,
                sku: data?.images?.sku,
                catalogCode,
                catalogType: data?.displayName,
                cloudService: data?.cloudService,
                validationStatus: 'clearValidation',
                isTouched: false,
                emptyCost: costs,
            }));
        }
        setLoading(false);
    }

    const reset = () => {
        setOrder({ ...order, ...resetOrder });
        setIsBackendPoolsConfig(false);
        setIsStorageDiskConfig(false);
        setIsHPConfig(false);
        resetOrderId('');
        resetEnvDetails(resetEnvironmentDetails);
        resetIsMandatoryTagsFullfilled(false);
        resetToggleSspModels(resetToggleSspModal);
        resetQuickConfig(undefined);
    };

    React.useEffect(() => {
        if (catalogCode) {
            setLoading(true);
            reset();
            checkCatalog();
        }
    }, [catalogCode]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => reset();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Day 2 Operation - DevX Infra</title>
            </Helmet>
            {isLoading && (
                <Box sx={ProvisioningStyles.provisioning_wrapper}>
                    <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                    <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                        Loading
                    </Typography>
                </Box>
            )}
            {!isLoading && (
                <Box sx={ProvisioningStyles.provisioningBox}>
                    <ProvisioningHeader />
                    <Box sx={ProvisioningStyles.sspform_wrapper}>
                        <SspForm />
                    </Box>
                    <br />
                    <ApplicationDetails />
                    <Tags />
                    <AddedToCartConfirmModal />
                    <UpdatedToCartConfirmModal />
                    <QuickConfig />
                    <ShowCostDetails />
                    <ConfigDataDiskModal />
                    <ConfigStorageDiskModal />
                    <ConfigSkuModal />
                    <ConfirmDD />
                </Box>
            )}
        </>
    );
};

export default Day2OperationPage;
