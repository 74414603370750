import { styled } from '@mui/material/styles';
import { RadioGroup, Radio } from '@mui/material';

const ProvisioningStyles = {
    card: {
        paper: {
            padding: (theme) => theme.spacing(2),
            background: (theme) => theme?.palette?.gray?.lighter,
            height: (theme) => theme.spacing('100%'),
        },
        form: {
            '& > *': {
                marginBottom: (theme) => theme.spacing(2.5),
            },
        },
        box: { mb: (theme) => theme.spacing(0.375) },
        typography: { marginBottom: (theme) => theme.spacing(0.25) },
    },
    provisioning_wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: (theme) => theme.spacing('100%'),
        height: 'calc(100vh - 100px)',
    },
    circularProgressColor: { color: (theme) => theme.palette.black.dark },
    provisioningBox: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        width: (theme) => theme.spacing('100%'),
        height: (theme) => theme.spacing('100%'),
    },
    sspform_wrapper: { flexGrow: 1, mx: (theme) => theme.spacing(6.625) },
    ProvisioningHeader: {
        pageTitle: {
            color: (theme) => theme.palette.white,
            textTransform: 'uppercase',
            fontSize: (theme) => theme.spacing(2),
            mx: (theme) => theme.spacing(0.5),
        },
        header: {
            mx: (theme) => theme.spacing(6.625),
            mt: (theme) => theme.spacing(3.125),
        },
        gridContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        gridItem: { display: 'flex' },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '24px', marginRight: '10px !important', marginLeft: '5px' },
    },
    warningTypography: { color: (theme) => theme.palette.red.light },
    cartBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
    },
    application: {
        stack: { direction: 'row', alignItems: 'center', justifyContent: 'center' },
    },
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalBtnStyle: {
        stack: { alignItems: 'center', justifyContent: 'flex-start' },
        btnWrapper: { width: '100%', ml: (theme) => theme.spacing(0.25) },
        // badgeStyle: { width: '100%' },
        modalBtn: {
            paddingTop: (theme) => theme.spacing(1),
            paddingBottom: (theme) => theme.spacing(1),
            height: (theme) => theme.spacing(4.641),
            fontSize: { md: (theme) => theme.spacing(1.25), lg: (theme) => theme.spacing(1.5), textTransform: 'none' },
        },
    },
    costCenter: { width: '100%' },
    disposal: {
        infoIcon: { color: (theme) => theme.palette.gray.main },
        helpText: { color: '#d40020' },
    },
    radioFields: {
        stack: { alignItems: 'center', justifyContent: 'flex-start' },
        radioBtnNoForm: {
            '& .css-j204z7-MuiFormControlLabel-root label': {
                marginRight: (theme) => theme.spacing(1.25),
            },
        },
    },
    helpImgStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        namingRule: { mb: (theme) => theme.spacing(1) },
        topDividerRule: { borderBottom: '1px solid #AFB8CC' },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', vaiant: (theme) => theme.typography.caption.fontSize },
    },
    consumerGroupModal: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        consumerGrpWrapper: { width: '120%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        delete: {
            // fontSize: (theme) => theme.spacing(2.25),
            // fill: '#FFFFFF',
            // color: (theme) => theme.palette.white,
            // cursor: 'pointer',
            width: '16px',
            height: '16px',
        },
        add: {
            // fontSize: (theme) => theme.spacing(2.25),
            // fill: '#FFFFFF',
            // color: (theme) => theme.palette.white,
            cursor: 'pointer',
            width: '16px',
            height: '16px',
            // marginTop: '7px', fontSize: '25px'
        },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        cancelBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#6E6F6F',
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
        warningTypography: { color: (theme) => theme.palette.red.light },
        addWrapper: {
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
            // background: '#0047BA',
            mt: 0.5,
        },
        deleteWrapper: {
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
            // background: (theme) => theme.palette.red.darkest,
            mt: 0.5,
        },
    },
    envContainer: {
        // badge: { marginRight: (theme) => theme.spacing(2) },
        applicationBtn: {
            textTransform: 'none',
            color: (theme) => theme.palette.blue.main,
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.cyan.main,
                borderWidth: (theme) => theme.spacing(0.25),
                borderStyle: 'solid',
                borderColor: (theme) => theme.palette.cyan.main,
                background: 'transparent',
            },
        },
        tagBtn: { textTransform: 'none' },
    },
    quickConfig: {
        spanColor: { color: '#000000' },
        labelValue: { padding: (theme) => theme.spacing(0.5, 1) },
        selectBtn: {
            marginLeft: (theme) => theme.spacing('auto'),
            display: 'flex',
            alignItems: 'flex-end',
            pr: (theme) => theme.spacing(1.5),
            pb: (theme) => theme.spacing(1.5),
        },
        selectedPaper: {
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue.strongDark,
        },
        paper: {
            // borderStyle: 'solid',
            // borderColor: (theme) => theme.palette.border.$color_border_input,
            borderColor: (theme) => theme.palette.white,
        },
        title: {
            font: (theme) => theme.typography.fontSize.$font_body_medium,
            width: '93%',
            flexShrink: 0,
            // fontSize: '16px',
            // fontWeight: '700',
            color: (theme) => theme.palette.textColors.$color_text_primary,
        },
        envTitle: {
            backgroundColor: '#FFF',
            borderRadius: '5px',
            '&:hover': {
                backgroundColor: (theme) => theme.palette.background.$color_background_hover,
            },
        },
        selectedBtn: {
            font: (theme) => `${theme.typography.fontSize.$font_small_bold}!important`,
            borderRadius: (theme) => theme.palette.border.$border_radius,
            backgroundColor: (theme) => theme.palette.background.$color_background_button_primary,
            // fontWeight: '700',
            color: '#fff',
            padding: '4px 16px',
            // fontSize: '14px !important',
            height: '32px',
            '&:hover': {
                backgroundColor: (theme) => `${theme.palette.background.$color_background_button_primary_hover} !important`,
            },
            '&:active': {
                backgroundColor: (theme) => `${theme.palette.button.$color_button_primary_active} !important`,
            },
            '&.Mui-disabled': {
                backgroundColor: '#003771',
                color: 'white',
            },
        },
        wrapper: {
            background: '#FBFBFB 0% 0% no-repeat padding-box',
            boxShadow: 'none',
            color: (theme) => theme.palette.white,
            borderRadius: '5px',
            // borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',
            borderTop: (theme) => `1px solid ${theme.palette.border.$color_border_divider}`,
            // borderColor: (theme) => theme.palette.border.$color_border_input,
            '&:before': {
                display: 'none',
            },
        },
        content: {
            background: (theme) => theme.palette.white,
            margin: (theme) => theme.spacing(0.25),
            marginTop: (theme) => theme.spacing(0),
            color: (theme) => theme.palette.gray[33],
            borderBottomLeftRadius: (theme) => theme.spacing(1),
            borderBottomRightRadius: (theme) => theme.spacing(1),
        },
        cirProgWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        labeledPaper: { display: 'flex', flexDirection: 'column' },
        emptycontainer: { backgroundColor: '#FFFFFF' },
        emptymsg: { font: (theme) => theme.typography.fontSize.$font_heading_h6_medium, color: '#000000' },
        emptySuggestion: { font: (theme) => theme.typography.fontSize.$font_body_regular },
        expandIcon: { color: (theme) => theme.palette.white },
    },
    submitOrder: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypo: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        actionWrapper: { textAlign: 'center', my: (theme) => theme.spacing(0.25), py: (theme) => theme.spacing(2) },
        newOrder: { marginRight: (theme) => theme.spacing(2) },
        message: { font: (theme) => theme.typography.fontSize.$font_heading_h4_medium, color: (theme) => theme.palette.black.dark },
        description: { width: '500px', font: (theme) => theme.typography.fontSize.$font_body_regular, color: (theme) => theme.palette.black.dark },
    },
    tags: {
        wrapper: { minWidth: (theme) => theme.spacing(75), width: (theme) => theme.spacing(100), display: 'flex', flexDirection: 'column', height: '100%' },
        contentWrapper: { flexGrow: 1, px: (theme) => theme.spacing(2) },
        box: { display: 'flex', flexDirection: 'column' },
        titlebox: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(2) },
        name: { fontWeight: 'bold', width: '31%' },
        colon: { width: (theme) => theme.spacing(0.25), mx: 1.5 },
        val: { width: (theme) => theme.spacing(50), fontWeight: 'bold', flexGrow: 1 },
        dataWrapper: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(1) },
        dataNameWrapper: {
            display: 'flex',
            alignItems: 'center',
            width: '30%',
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',

            borderRadius: (theme) => theme.spacing(0.25),
            borderColor: 'gray.lowMedium',
            py: (theme) => theme.spacing(0.5),
            px: (theme) => theme.spacing(1.5),
        },
        dataColan: { width: (theme) => theme.spacing(0.75), mx: (theme) => theme.spacing(1) },
        dataValueBoxWrapper: { flexGrow: 1 },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(0.25) },
        resetBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#6E6F6F',
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
        activeSelectField: {
            background: 'transparent',
            '& label.MuiFormLabel-filled,label.MuiInputLabel-shrink': {
                color: '#0047BA',
                padding: '0 2px',
            },
            '& label.Mui-disabled+div fieldset': {
                border: ' 1px solid #E9E9E9',
            },
            '& label.MuiFormLabel-filled+div fieldset': {
                border: ' 1px solid #0047BA',
            },
            '& label.Mui-focused+div fieldset': {
                border: ' 2px solid #0047BA !important',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0047BA',
            },
        },
    },
};

export default ProvisioningStyles;

export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
