import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import { useRecoilValue, useRecoilState } from 'recoil';
import DialogModal from 'components/dialog';
import SuccessImage from 'assets/images/success.png';
import { toggleModal, urlParams } from 'pages/project-onboarding/store';
import { commonStyles as style } from 'assets/style';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Style } from './style';

export const DialogBox = ({ handleNewOrderBtn, handleHomeBtn, operation, redirectTo }) => {
    const history = useHistory();
    const [isLoading] = React.useState(false);
    const [params] = useRecoilState(urlParams);
    const modal = useRecoilValue(toggleModal);

    const handleOrderIdOnClick = async () => {
        history.push('/orders');
    };

    React.useEffect(() => {
        if (params?.journeyId && modal?.isSuccessOpen) {
            const redirectTimeout = setTimeout(() => {
                window.location.href = redirectTo;
            }, 5000);

            return () => {
                clearTimeout(redirectTimeout);
            };
        }
        return null;
    }, [modal?.isSuccessOpen]);

    return (
        <DialogModal open={modal?.isSuccessOpen}>
            <Box sx={Style.wrapper}>
                <>
                    <img src={SuccessImage} alt="" />
                    <Typography gutterBottom sx={Style.requsetTypography}>
                        Your Request is {operation === 'edit' ? 'Re-submitted' : 'Submitted'}
                    </Typography>
                    {params?.journeyId ? (
                        <>
                            <Typography gutterBottom sx={Style.thankyouTypography}>
                                Thank you for using DevX Infra. Your order has been submitted.
                            </Typography>
                            <Typography gutterBottom sx={Style.thankyouTypography}>
                                You are now being redirected to DevX Launchpad
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography gutterBottom sx={Style.thankyouTypography}>
                                Thank you for using DevX Infra. Your order has been {operation === 'edit' ? 're-submitted' : 'submitted'} successfully. Please
                                go to
                                <Typography
                                    component="span"
                                    variant="body2"
                                    sx={Style.orderIdTypography}
                                    onClick={() => handleOrderIdOnClick()}
                                    data-testid="order-id"
                                >
                                    {modal?.orderId}
                                </Typography>
                                to review your order
                            </Typography>
                        </>
                    )}
                </>

                {operation !== 'edit' && !params?.journeyId && (
                    <Box sx={Style.newOrderBox}>
                        <Button
                            data-testid="placeReq-btn"
                            onClick={() => handleNewOrderBtn()}
                            disabled={isLoading}
                            disableElevation
                            variant="contained"
                            // sx={Style.leftBtn}
                            sx={{ ...style.primaryMediumButton, marginRight: '16px', width: 'auto' }}
                            // color="cmpPrimary"
                        >
                            Place New Order
                        </Button>
                        <Button
                            data-testid="backHome-btn"
                            onClick={() => handleHomeBtn()}
                            disabled={isLoading}
                            disableElevation
                            variant="outlined"
                            // sx={Style.rightBtn}
                            sx={{ ...style.secondaryMediumButton, width: 'auto' }}
                            // color="cmpPrimary"
                        >
                            Back to Home
                        </Button>
                    </Box>
                )}
                {operation === 'edit' && <Box marginBottom="30px" />}
                {params?.journeyId && (
                    <Box sx={Style?.toolsImageContainer}>
                        <img src="/static/images/devx-infra.svg" alt="devX Infra" width={120} />
                        <KeyboardDoubleArrowRightIcon />
                        <img src="/static/images/devXLaunchpad.svg" alt="devX Launchpad" width={145} />
                    </Box>
                )}
            </Box>
        </DialogModal>
    );
};

DialogBox.propTypes = {
    handleNewOrderBtn: PropTypes.func,
    handleHomeBtn: PropTypes.func,
    operation: PropTypes.string,
    redirectTo: PropTypes.string,
};

DialogBox.defaultProps = {
    handleNewOrderBtn: () => null,
    handleHomeBtn: () => null,
    operation: '',
    redirectTo: '',
};
export default React.memo(DialogBox);
