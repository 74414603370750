import { atom } from 'recoil';

export const OrderPayload = atom({
    key: 'ssoOkta-OrderPayload',
    default: {
        applicationCI: null,
        applicationCIDetails: null,
        applicationOwner: null,
        applicationOwnerDetails: null,
        applicationName: null,
        appShortName: null,
        applicationUrl: null,
        loginUrl: [],
        logoutUrl: [],
        environment: null,
        envShortName: null,
        businessApplicationDetails: null,
        sector: null,
        organization: null,
        azureRepo: null,
        member: null,
        memberDetails: null,
        roles: [{ role: '' }],
        authorizationRolesRequired: 'no',
        isCertificateAvailable: 'no',
        businessJustification: null,
        requestType: null,
        recipientUrl: null,
        destinationUrl: null,
        audienceURI: null,
        serviceProviderSigningCert: null,
        isTouched: false,
        accessRestrictions: [],
        isLogoutUrlRequired: 'no',
        idxAttribute: null,
        isIdxAttributeRequired: 'no',
    },
});

export const orderId = atom({
    key: 'ssoOkta-orderId',
    default: undefined,
});

export const isAppNameValid = atom({
    key: 'ssoOkta-isAppNameValid',
    default: true,
});

export const isAppUrlValid = atom({
    key: 'ssoOkta-isAppUrlValid',
    default: true,
});

export const isLoginUrlValid = atom({
    key: 'ssoOkta-isLoginUrlValid',
    default: true,
});

export const isLogoutUrlValid = atom({
    key: 'ssoOkta-isLogoutUrlValid',
    default: true,
});

export const ssoOktaDialogPopup = atom({
    key: 'ssoOkta-ssoOktaDialogPopup',
    default: false,
});

export const isAppShortNameValid = atom({
    key: 'ssoOkta-isAppShortNameValid',
    default: false,
});

export const isAppShortNameExist = atom({
    key: 'ssoOkta-isAppShortNameExist',
    default: false,
});

export const isRolesValid = atom({
    key: 'ssoOkta-isRolesValid',
    default: false,
});

export const toggleModels = atom({
    key: 'ssoOkta-toggleModels',
    default: {
        isRoleOpen: false,
        isLoginModelOpen: false,
        isLogoutModelOpen: false,
    },
});

export const isDestUrlValid = atom({
    key: 'ssoOkta-isDestUrlValid',
    default: true,
});
export const isRecipientUrlValid = atom({
    key: 'ssoOkta-isRecipientUrlValid',
    default: true,
});
export const isAudienceURIValid = atom({
    key: 'ssoOkta-isAudienceURIValid',
    default: true,
});

export const isServiceProviderSigningCertValid = atom({
    key: 'ssoOkta-isServiceProviderSigningCertValid',
    default: true,
});

export const isBusinessJustificationValid = atom({
    key: 'ssoOkta-isBusinessJustificationValid',
    default: true,
});
