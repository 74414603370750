import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, Box, Typography } from '@mui/material';
import { FiAlertTriangle } from 'react-icons/fi';
import Btn from 'components/button';
import { commonStyles as style } from 'assets/style';
import ProvisioningStyles from '../../vm/style';

export default function ConfirmDialog({ title, primaryBtnText, secondaryBtnText, primaryBtnAction, secondaryBtnAction, content, isModalOpen }) {
    return (
        <Dialog
            open={isModalOpen}
            onClose={secondaryBtnAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            data-testid="confirmDialog"
        >
            <DialogContent sx={style.modalWrapper}>
                <Box sx={ProvisioningStyles.confirmDD.dialogContentBox}>
                    <FiAlertTriangle color={ProvisioningStyles.Dialog.IconColor} size={70} />
                    <Typography sx={style.modalTitle} variant="span">
                        {title}
                    </Typography>
                    {content ? <div style={style.modalContent}>{content}</div> : null}
                </Box>

                <Box sx={ProvisioningStyles.confirmDD.dialogActionBox}>
                    <Btn
                        variant="contained"
                        onClick={() => primaryBtnAction()}
                        className="uppercase tracking-wider text-sm custom-btn"
                        sx={style?.modalSaveButton}
                        data-testid="yes-btn"
                    >
                        {primaryBtnText}
                    </Btn>
                    <Btn
                        variant="outlined"
                        sx={{ ...style?.modalCancelButton, ml: 1 }}
                        onClick={() => secondaryBtnAction()}
                        layout="outline"
                        className="uppercase tracking-wider text-sm custom-btn"
                        data-testid="no-btn"
                    >
                        {secondaryBtnText}
                    </Btn>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

ConfirmDialog.propTypes = {
    title: PropTypes.string,
    primaryBtnText: PropTypes.string.isRequired,
    secondaryBtnText: PropTypes.string.isRequired,
    primaryBtnAction: PropTypes.func.isRequired,
    secondaryBtnAction: PropTypes.func.isRequired,
    content: PropTypes.any,
    isModalOpen: PropTypes.bool.isRequired,
};

ConfirmDialog.defaultProps = {
    title: '',
    content: null,
};
