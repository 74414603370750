import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAxios } from 'hooks';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import ProvisioningHeader from 'pages/provisioning/event-hub/ProvisioningHeader';
import ApplicationDetails from 'pages/provisioning/event-hub/ApplicationDetails';
import SspForm from 'pages/provisioning/event-hub/SspForm';
import Tags from 'pages/provisioning/event-hub/Tags';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import AddedToCartConfirmModal from 'pages/provisioning/event-hub/AddedToCartConfirmModal';
import UpdatedToCartConfirmModal from 'pages/provisioning/event-hub/UpdatedToCartConfirmModal';
import QuickConfig from 'pages/provisioning/event-hub/QuickConfig';
import ShowCostDetails from 'pages/provisioning/event-hub/ShowCostDetails';
import { catalogs } from 'store';
import {
    environmentDetails,
    isMandatoryTagsFullfilled,
    orderId,
    quickConfigDetails,
    sspSubmitOrder,
    toggleSspModels,
} from 'pages/provisioning/store/event-hub';
import { formOrderObjFromCart, formOrderObjFromOrderDetails, resetEnvironmentDetails, resetAppProfile, resetOrder, resetToggleSspModal } from './utils';

const EventHubProvisioningPage = () => {
    const [isLoading, setLoading] = React.useState(true);
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, resetOrderId] = useRecoilState(orderId);
    const [, resetEnvDetails] = useRecoilState(environmentDetails);
    const [, resetIsMandatoryTagsFullfilled] = useRecoilState(isMandatoryTagsFullfilled);
    const [, resetToggleSspModels] = useRecoilState(toggleSspModels);
    const [, resetQuickConfig] = useRecoilState(quickConfigDetails);
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const axios = useAxios();

    async function getCosts() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}/costs`);
        return data;
    }

    async function checkCatalog() {
        const data = cmpCatalogs?.find((item) => item?.catalogDefinitionId === catalogCode);
        const costs = await getCosts();
        if (cartItemId && operation && !catalogServiceId) {
            const { data: cartItem } = await axios.get(`${endPoints.cart.getCartItem}/${cartItemId}`);
            const orderObj = await formOrderObjFromCart(cartItem);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                emptyCost: costs,
            }));
        }
        if (cartItemId && operation && catalogServiceId) {
            const { data: orderDetails } = await axios.get(`${endPoints.order.orderDetails}/${cartItemId}`);
            const orderObj = await formOrderObjFromOrderDetails(catalogServiceId, orderDetails);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
            }));
        } else {
            setOrder((previous) => ({
                ...previous,
                sku: data?.images?.sku,
                catalogCode,
                catalogType: data?.displayName,
                cloudProvider: data?.cloudProvider,
                cloudService: data?.cloudService,
                validationStatus: 'clearValidation',
                isTouched: false,
                emptyCost: costs,
            }));
        }
        setLoading(false);
    }

    const reset = () => {
        setOrder({ ...order, ...resetAppProfile, ...resetOrder });
        resetOrderId('');
        resetEnvDetails(resetEnvironmentDetails);
        resetIsMandatoryTagsFullfilled(false);
        resetToggleSspModels(resetToggleSspModal);
        resetQuickConfig(undefined);
    };

    React.useEffect(() => {
        if (cmpCatalogs?.length && catalogCode) {
            setLoading(true);
            reset();
            checkCatalog();
        }
    }, [cmpCatalogs, catalogCode]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => reset();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Event Hub Provisioning - DevX Infra</title>
            </Helmet>
            {isLoading && (
                <Box sx={ProvisioningStyles.provisioning_wrapper}>
                    <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                    <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                        Loading
                    </Typography>
                </Box>
            )}
            {!isLoading && (
                <Box sx={ProvisioningStyles.provisioningBox}>
                    <ProvisioningHeader />
                    <Box sx={ProvisioningStyles.sspform_wrapper}>
                        <SspForm />
                    </Box>
                    <br />
                    <ApplicationDetails />
                    <AddedToCartConfirmModal />
                    <UpdatedToCartConfirmModal />
                    <QuickConfig />
                    <ShowCostDetails />
                    <Tags />
                </Box>
            )}
        </>
    );
};

export default EventHubProvisioningPage;
