/* istanbul ignore file */
import React from 'react';
import { Grid, Box, TableContainer, Table, TableBody, Typography, Divider } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import ArtifactoryUser from 'pages/project-onboarding/components/Form/ServiceMembers/ArtifactoryUser';
import PackageTypes from 'pages/project-onboarding/components/Form/ArtifactoryComponents/PackageTypes';
import BuildTools from 'pages/project-onboarding/components/Form/ArtifactoryComponents/BuildTools';
import ProgrammingLanguages from 'pages/project-onboarding/components/Form/ArtifactoryComponents/ProgrammingLanguages';
import ServiceDialog from './ServiceDialog';

const ArtifatoryConfiguration = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [deleteMember, setDeleteMember] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleDeleteMember = () => {
        if (order?.artifactoryUserDetails) {
            if (deleteMember?.gpid === order?.artifactoryUserDetails?.gpid) {
                setOrder({
                    ...order,
                    artifactoryUserList: null,
                    artifactoryUserDetails: null,
                });
                setValidForm({ ...isValidForm, isDuplicateArtifactoryUser: false });
            }
        }
        setOrder({
            ...order,
            artifactoryUserList: order?.artifactoryUserList?.filter((item) => item.gpid !== deleteMember?.gpid),
        });
        setValidForm({ ...isValidForm, isArtifactoryMaxMemberLimit: false });
    };

    const handleNo = () => {
        setIsOpen(false);
    };
    const handleYes = () => {
        handleDeleteMember();
        setIsOpen(false);
    };

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <ProgrammingLanguages />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <BuildTools />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PackageTypes />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ArtifactoryUser />
                </Grid>
            </Grid>
            <Box display="flex" flexDirection="column" mt={2}>
                <Box display="flex" mt={2}>
                    <Typography variant="h6" mr={1}>
                        Member Access List *
                    </Typography>
                </Box>
                <Divider />
                <Box display="flex">
                    {order?.artifactoryUserList?.length > 0 ? (
                        <Box width="100%">
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        {order?.artifactoryUserList &&
                                            order?.artifactoryUserList?.length &&
                                            order?.artifactoryUserList?.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left" width="1">
                                                        <Typography>{item?.gpid || ''}</Typography>
                                                    </TableCell>
                                                    <TableCell align="left" width="1">
                                                        {item?.name || '-'}
                                                    </TableCell>
                                                    <TableCell align="left" width="1">
                                                        {item?.email === 'null' || !item?.email ? '-' : item?.email}
                                                    </TableCell>
                                                    <TableCell width="1">
                                                        <DeleteIcon
                                                            sx={ProjectOnboardingStyles?.removeBtn}
                                                            color="error"
                                                            onClick={() => {
                                                                setDeleteMember(item);
                                                                setIsOpen(true);
                                                            }}
                                                            className="animate-opacity"
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : (
                        <Box mt={2}>
                            <Typography variant="body1">No Members Selected</Typography>
                        </Box>
                    )}
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" mt={2}>
                    {order?.artifactoryUserList && order?.artifactoryUserList?.length > 0 ? (
                        <Typography>
                            <b>{order?.artifactoryUserList?.length}</b> {order?.artifactoryUserList?.length < 2 ? 'Member' : 'Members'} Selected{' '}
                        </Typography>
                    ) : (
                        <>&nbsp;</>
                    )}
                </Box>
            </Box>
            <ServiceDialog isOpen={isOpen} member={deleteMember?.name} handleNo={handleNo} handleYes={handleYes} />
        </>
    );
};

export default React.memo(ArtifatoryConfiguration);
