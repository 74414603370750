import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';

export const OrganizationName = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [projectName, setProjectName] = React.useState(null);

    const formatSector = (sector) => {
        return sector.replace(/ /g, '_').replace(/&/g, 'n');
    };

    React.useEffect(() => {
        const isProjectSector =
            order?.adoPlatform === 'Sandbox/Personal' ||
            (order?.adoPlatform === 'Production' &&
                ((order?.isADOProject === 'no' && order?.isClarityId === 'no') ||
                    (order?.isADOProject === 'no' &&
                        order?.isClarityId === 'yes' &&
                        order?.clarityDetails &&
                        (typeof order?.clarityDetails?.devopsProject === 'undefined' ||
                            order?.clarityDetails?.devopsProject === null ||
                            order?.clarityDetails?.devopsProject === undefined))));

        const getProjectName = () => {
            let projectNameVal = null;
            if (isProjectSector) projectNameVal = `${formatSector(order?.sector)}_${order?.projectName}`;
            else if (
                order?.adoPlatform === 'Production' &&
                order?.isClarityId === 'yes' &&
                order?.clarityDetails &&
                order?.clarityDetails?.devopsProject !== null
            ) {
                projectNameVal = order?.existingProjectName;
            } else projectNameVal = order?.projectName;
            return projectNameVal;
        };
        setProjectName(getProjectName());
    }, []);

    React.useEffect(() => {
        if (projectName !== null)
            setOrder({
                ...order,
                organizationName: projectName,
            });
    }, [projectName]);

    return (
        <Box display="flex" sx={{ width: '100%' }} marginRight={3.4}>
            <CssTextField data-testid="orgName-text" size="small" disabled autoComplete="off" label="Organization Name *" value={projectName} />
        </Box>
    );
};

export default React.memo(OrganizationName);
