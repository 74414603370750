import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import DialogModal from 'components/dialog';
import SuccessImage from 'assets/images/success.png';
import { cartStyle } from 'pages/cart/style';
import { selectOrder } from 'pages/orders/store';
import { commonStyles as style } from 'assets/style';
import { ResetRequestDetails } from '../utils';
import { successPopup, requestDetails } from '../store';

const OrderSuccessPopup = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const [modal, setModal] = useRecoilState(successPopup);
    const [details, setDetails] = useRecoilState(requestDetails);
    const setSelectedOrder = useSetRecoilState(selectOrder);
    const handleOrderIdOnClick = async () => {
        await setSelectedOrder({ orderId: modal?.orderId });
        history.push('/orders');
    };
    const storeReset = async () => {
        setDetails({
            ...details,
            applicationCI: null,
            applicationCIDetails: null,
            sector: null,
        });
        setModal(false);
        setIsLoading(false);
    };

    return (
        <DialogModal open={modal?.isOpen} handleClose={() => {}} data-testid="orderSuccessModal">
            <Box sx={cartStyle.submitOrder.wrapper}>
                <img src={SuccessImage} alt="" />
                <Typography gutterBottom sx={cartStyle.submitOrder.requsetTypography}>
                    Thank you for using DevX Infra.
                </Typography>
                <Typography gutterBottom sx={cartStyle.submitOrder.thankyouTypography}>
                    <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) }}
                        onClick={() => {
                            handleOrderIdOnClick();
                        }}
                        data-testid="order-id"
                    >
                        {modal?.orderId}
                    </Typography>
                    is your Reference number. You will receive an email shortly with the result.
                </Typography>
                <Box sx={cartStyle.submitOrder.newOrderBox}>
                    <Button
                        onClick={() => {
                            setIsLoading(true);
                            storeReset();
                        }}
                        disabled={isLoading}
                        // sx={cartStyle.submitOrder.newOrderBtn}
                        sx={{ ...style.primaryMediumButton, width: 'auto', marginRight: '16px' }}
                        disableElevation
                        variant="contained"
                        // color="primary"
                        data-testid="placeReq-btn"
                    >
                        Place New Request
                    </Button>
                    <Button
                        onClick={() => {
                            setDetails(ResetRequestDetails);
                            history.push('/');
                            setIsLoading(false);
                        }}
                        component={Link}
                        to="/"
                        variant="outlined"
                        // color="primary"
                        sx={{ ...style.secondaryMediumButton, width: 'auto' }}
                        disableElevation
                        disabled={isLoading}
                        data-testid="backHome-btn"
                    >
                        Back to Home
                    </Button>
                </Box>
            </Box>
        </DialogModal>
    );
};
export default React.memo(OrderSuccessPopup);
