import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { Styles } from 'pages/reference-implementation/components/form-card/style';
import { InputInfo } from 'pages/reference-implementation/components/input-info';

const FormCard = ({ title, marginBottom, children, showInfoIcon, infoMessage, padding, margin }) => {
    const borderColor = title === 'Access Management' ? '1px solid #D7E5F8' : 'none';
    const boxShadow = title !== 'Access Management' ? '0 0 24px rgba(0, 92, 188, 0.08)' : 'none';
    return (
        <Box borderRadius={1.5} marginBottom={marginBottom} border={borderColor} boxShadow={boxShadow} bgcolor="#FBFCFE">
            {title && title !== 'Access Management' && (
                <>
                    <Box sx={Styles.formCard.wrapper}>
                        <Typography sx={Styles.formCard.heading} variant="subtitle1">
                            <Stack direction="row">{title}</Stack>
                            {showInfoIcon && <InputInfo title={infoMessage} />}
                        </Typography>
                    </Box>
                    <Divider variant="middle" />
                </>
            )}
            <Box display="flex" gap={4} flexWrap="wrap" padding={padding} sx={{ ml: margin, mr: margin, mb: 0 }}>
                {children}
            </Box>
        </Box>
    );
};

FormCard.propTypes = {
    title: PropTypes.any,
    children: PropTypes.element,
    marginBottom: PropTypes.any,
    showInfoIcon: PropTypes.bool,
    infoMessage: PropTypes.string,
    padding: PropTypes.any,
    margin: PropTypes.any,
};

FormCard.defaultProps = {
    title: undefined,
    children: <></>,
    marginBottom: 2,
    showInfoIcon: false,
    infoMessage: '',
    padding: 4,
    margin: 0,
};

export default React.memo(FormCard);
