import React from 'react';
import { Box, Stack, Grow, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState, useSetRecoilState } from 'recoil';
// import { FaTrashAlt } from 'react-icons/fa';
import deleteIcon from 'assets/images/delete_icon.svg';
import { orderPayload, selectedVSService, validVSForm, currentSubModule } from 'pages/provisioning/virtual-service/store';
import { addAllowedOrigin, removeAllowedOrigin, updateAllowedOrigin } from 'pages/provisioning/virtual-service/store/selectors';
import Btn from 'components/button';
import ConfirmDialog from 'pages/provisioning/virtual-service/dialog-box/ConfirmDialog';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';
import InfoImg from 'components/info-img';
import { commonStyles as style } from 'assets/style';

const AllowedOrigins = () => {
    const [isModalOpen, setModal] = React.useState(false);
    const [confirmModal, setConfirmModal] = React.useState(null);
    const [activeIndex, setActiveIndex] = React.useState(0);

    const [order] = useRecoilState(orderPayload);
    const [validForm, setValidForm] = useRecoilState(validVSForm);
    const [selectedVS] = useRecoilState(selectedVSService);
    const [, setCurentSubModule] = useRecoilState(currentSubModule);
    const addOrigin = useSetRecoilState(addAllowedOrigin);
    const removeOrigin = useSetRecoilState(removeAllowedOrigin);
    const updateOrigin = useSetRecoilState(updateAllowedOrigin);

    const handleValueChange = (value, index) => {
        setActiveIndex(index);
        updateOrigin({ value, index, selectedVS });
    };

    const handleAction = (type) => {
        if (type === 'yes') {
            removeOrigin({ confirmModal, selectedVS });
        }
        setConfirmModal(null);
        setModal(false);
    };

    React.useEffect(() => {
        setValidForm({
            ...validForm,
            isInvalidAllowedOrigin: order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins?.some((origin) => origin?.isInvalid === true),
        });
    }, [order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins]);

    const checkDuplicate = (header) => {
        const allowedOrigins = order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins;
        const allowedOriginsList = allowedOrigins?.map((item) => item?.allowedOrigin);
        const duplicateAllowedOrigins = allowedOriginsList?.filter((item, index) => allowedOriginsList?.indexOf(item) !== index);
        if (header?.allowedOrigin) {
            return duplicateAllowedOrigins?.indexOf(header?.allowedOrigin) > -1;
        }
        return false;
    };

    React.useEffect(() => {
        setCurentSubModule('allowedOrigins');
        return () => {
            setCurentSubModule(null);
        };
    }, []);

    const getToolTip = () => {
        return (
            <InfoImg
                title={
                    <>
                        <Box className={ProvisioningStyles.infoStyle.wrapper}>
                            <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Allowed Origins:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            Allowed origins in a virtual service define the specific domains or IP addresses permitted to access the service.
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle} />
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>1) Allowed origin should be unique.</Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>2) It can have alpha-numeric value.</Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>3) It should start with https:// or http://</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={ProvisioningStyles.infoStyle.bottomDividerRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Example: </span>
                                            https://api-na.qa.mypepsico.com
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        );
    };

    return (
        <>
            {order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins.map((header, index) => (
                <React.Fragment key={`allowed-origin-grow-${index}`}>
                    <Grow
                        in={!!order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins?.length}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins?.length ? { timeout: 500 } : {})}
                    >
                        <Stack direction="row" spacing={2} sx={{ mb: 2 }} key={index.toString()} alignItems="baseline">
                            <Box display="flex" alignItems="center" width="100%" gap={2} data-testid={`allowed-origin-${index}`}>
                                <CssTextField
                                    size="small"
                                    fullWidth
                                    autoComplete="off"
                                    label="Allowed Origin *"
                                    error={header?.isInvalid || checkDuplicate(header) || header?.isAllowedOriginTouched}
                                    helperText={
                                        (header?.isInvalid && activeIndex === index && 'Allowed origin should be a vaild url.') ||
                                        (checkDuplicate(header) && 'Duplicate allowed origin is not allowed.')
                                    }
                                    value={header?.allowedOrigin || ''}
                                    onChange={(e) => handleValueChange(e.target.value, index)}
                                    className={header?.isInvalid || checkDuplicate(header) ? 'validate' : ''}
                                />
                            </Box>
                            {order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins.length > 1 && (
                                <Box>
                                    {order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins.length > 1 ? (
                                        <Box
                                            data-testid={`remove-allowed-origin-${index}`}
                                            style={ProvisioningStyles.vsType.delete}
                                            onClick={() => {
                                                setConfirmModal({ header, index });
                                                setModal(true);
                                            }}
                                        >
                                            <img height="16px" width="16px" src={deleteIcon} alt="deleteIcon" />
                                        </Box>
                                    ) : /* <FaTrashAlt
                                                data-testid={`remove-allowed-origin-${index}`}
                                                style={ProvisioningStyles.vsType.delete}
                                                onClick={() => {
                                                    setConfirmModal({ header, index });
                                                    setModal(true);
                                                }}
                                                className="cursor-pointer"
                                            /> */
                                    null}
                                </Box>
                            )}
                            {getToolTip()}
                        </Stack>
                    </Grow>
                    {index === order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins.length - 1 &&
                    order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins.length < 10 ? (
                        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', pt: 1 }}>
                            <Btn
                                variant="contained"
                                // sx={{ minWidth: '42px', width: 'auto', height: '32px', marginTop: '-10px' }}
                                sx={style.serviceTagBtn}
                                disableFocusRipple
                                disableRipple
                                onClick={() => addOrigin({ count: 1, selectedVS })}
                                disabled={false}
                                data-testid="add-another-origin"
                            >
                                {/* <AddIcon sx={{ fontSize: '25px' }} /> */}
                                Add Another Origin
                            </Btn>
                        </Box>
                    ) : (
                        <span className="w-7 h-7" />
                    )}
                </React.Fragment>
            ))}
            {isModalOpen && (
                <ConfirmDialog
                    isModalOpen={isModalOpen}
                    title="Are you sure you want to remove Allowed Origin?"
                    primaryBtnText="Yes"
                    secondaryBtnText="No"
                    primaryBtnAction={() => handleAction('yes')}
                    secondaryBtnAction={() => handleAction('no')}
                    isCompact
                />
            )}
        </>
    );
};

export default AllowedOrigins;
