import React from 'react';
import { Badge, Box, Grid } from '@mui/material';
import Btn from 'components/button';
import SsoOktaStyles from 'pages/sso-okta/style';
import { useRecoilState } from 'recoil';
import { toggleModels, OrderPayload } from 'pages/sso-okta/store';
import { ROLE_TO_SHOW } from 'pages/sso-okta/utils/constant';
import { commonStyles as style } from 'assets/style';

export const RoleWrapper = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleModels);
    const [order] = useRecoilState(OrderPayload);
    return (
        <>
            {order?.authorizationRolesRequired === 'yes' && ROLE_TO_SHOW?.includes(order?.dataClassification?.toLowerCase()) && (
                <Grid display="flex" justifyContent="space-between" alignItems="center">
                    <Box justifyContent="flex-start" sx={SsoOktaStyles?.roleWrapper.label}>
                        Role
                    </Box>
                    <Box sx={{ width: '30%', display: 'flex', justifyContent: 'end' }}>
                        <Badge sx={style.tagBadge} color="error" variant={order?.roles?.[0]?.role?.length === 0 ? 'dot' : 'standard'}>
                            <Btn
                                // sx={{ width: '100%' }}
                                sx={style.primaryMediumButton}
                                size="medium"
                                onClick={() => setIsOpen({ ...isOpen, isRoleOpen: true })}
                                variant="contained"
                                // color="cmpPrimary"
                            >
                                ADD *
                            </Btn>
                        </Badge>
                    </Box>
                </Grid>
            )}
        </>
    );
};

export default React.memo(RoleWrapper);
