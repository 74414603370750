import styled from '@emotion/styled';
import { Box, Radio, RadioGroup } from '@mui/material';

const GithubOnboardingStyles = {
    projectOnboarding_wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: (theme) => theme.spacing('100%'),
        height: 'calc(100vh - 100px)',
    },
    circularProgressColor: { color: (theme) => theme.palette.black.dark },
    formField: {
        paddingTop: '15px !important',
        paddingLeft: '32px',
    },
    radioFields: {
        radioText: { fontSize: '12px', fontWeight: 'normal' },
        stack: { alignItems: 'center', justifyContent: 'flex-start' },
        radioBtnNoForm: {
            '& .css-j204z7-MuiFormControlLabel-root label': {
                marginRight: (theme) => theme.spacing(1.25),
            },
        },
    },
    helpImgStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%', padding: '10px' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        namingRule: { mb: (theme) => theme.spacing(1) },
        topDividerRule: { borderBottom: '1px solid #AFB8CC' },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', vaiant: (theme) => theme.typography.caption.fontSize },
        emptyLine: { marginBottom: '5px' },
    },
    modal: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        groupWrapper: { width: '100%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        deleteWrapper: {
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3.375),
            height: (theme) => theme.spacing(3.375),
            mt: 0.5,
        },
        delete: {
            cursor: 'pointer',
            width: '16px',
            height: '16px',
        },
        add: { dth: '16px', height: '16px', cursor: 'pointer', marginTop: '7px', fontSize: '25px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        warningTypography: { color: (theme) => theme.palette.red.light },
    },
    card: {
        padding: 2,
        marginBottom: 2,
        boxShadow: 'none',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        background: '#fff',
        zIndex: 0,
    },
    title: {
        color: (theme) => theme.palette.black.dark,
        border: 'none',
        fontWeight: '700',
    },
    paginationRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
    },
    preview: {
        header: {
            previewDetailsTextWrap: { paddingRight: 3 },
            previewDetailsText: {
                font: (theme) => theme.typography.fontSize.$font_heading_h6_medium,
                color: '#021D49',
            },
        },
        teamHeader: {
            color: '#005CBC',
            fontWeight: '700',
        },
        editBtn: {
            borderRadius: '4px',
            font: (theme) => theme.typography.fontSize.$font_body_medium,
            width: (theme) => theme.spacing(23),
            height: (theme) => theme.spacing(5),
            textTransform: 'none',
        },
        wrapper: {
            padding: '20px',
            background: '#FFFFFF',
            color: '#616161',
            fontWeight: 400,
            border: '1px solid #CCCCCC',
            borderRadius: '6px',
        },
        orderMemberWrapper: {
            background: '#F3F7FD',
            color: '#616161',
            font: (theme) => theme.typography.fontSize.$font_small_regular,
            border: '1px solid #CCCCCC',
            overflow: 'scroll',
            maxHeight: '405px',
            borderRadius: '6px',
            '::-webkit-scrollbar-thumb': {
                backgroundColor: '#c8c8c8 !important',
            },
        },
        heading: {
            color: '#0047BA',
            font: (theme) => theme.typography.fontSize.$font_heading_h6_medium,
        },
        divder: { color: '#707070', marginTop: 1, marginBottom: 1 },
        memberAccess: {
            container: { background: '#F3F7FD', padding: '15px', mb: '15px' },
            orderMemberContainer: { background: '#F3F7FD', padding: '15px', mb: '10px' },
            subWrapper: { display: 'flex', alignItems: 'center' },
            label: { width: '170px' },
            value: { fontWeight: 500, wordBreak: 'break-all' },
            colon: { px: '10px' },
            doneIcon: { color: '#10a700', fontSize: 'x-large' },
        },
        projectDetails: {
            container: { py: '10px' },
            subWrapper: { display: 'flex', flexDirection: 'column' },
            label: {
                font: (theme) => theme.typography.fontSize.$font_body_medium,
                color: '#1E1E1E',
            },
            value: {
                font: (theme) => theme.typography.fontSize.$font_small_regular,
                color: '#616161',
            },
        },
        fs: {
            container: { py: '10px' },
            subWrapper: { display: 'flex' },
            label: { fontWeight: 500, fontSize: '18px', color: '#1E1E1E', width: '205px' },
            value: { fontWeight: 400, fontSize: '16px', color: '#616161' },
        },
    },
};
export default GithubOnboardingStyles;

export const ScrollableContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(2.25),
    paddingBottom: theme.spacing(3.75),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
}));

export const LeftBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    paddingRight: theme.spacing(2),
}));

export const StickyRightBox = styled(Box)({
    width: '100%',
    height: '100vh',
    position: 'sticky',
    top: 0,
});

export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
