export const resetPageFilter = {
    catalogType: null,
    catalogTypeDetail: null,
    region: null,
    regionDetail: null,
    sector: null,
    sectorDetail: null,
    environment: null,
    environmentDetail: null,
    requestor: null,
    requestorDetails: null,
    appOwner: null,
    appOwnerDetails: null,
};
export const labelMapping = {
    ORDER_ID: 'Order ID',
    ORDER_ITEM_ID: 'Order Item ID',
    STATUS: 'Status',
    REQUESTOR: 'Requestor',
    DATE: 'Order Submission Time',
    MANAGER: 'Manager',
    APPLICATION_OWNER: 'Application Owner',
    APPLICATION_NAME: 'Application Name',
    SECTOR: 'Sector',
    REGION: 'Region',
    ENVIRONMENT: 'Environment',
    CLUSTER: 'Cluster',
    TOPIC_NAME: 'Topic Name',
    LDAP_GROUP: 'LDAP Group',
    BOOTSTRAP_URL: 'Bootstrap URL',
    NAMESPACE: 'Namespace',
    FQDN: 'FQDN',
    IP: 'IP',
    IDM_REQUEST_NUMBER: 'IDM Request Number',
    CMP_PROVISIONING_REQUEST: 'DevX Infra Provisioning Request',
    DATABASE_NAME: 'Database Name',
    LOGICAL_HOST_NAME: 'Logical Host Name',
    ACCOUNT_NAME: 'Account Name',
    CONTAINER_NAME: 'Container Name',
    LOAD_BALANCER_NAME: 'Load Balancer Name',
    FRONTEND_IPS: 'Frontend IP',
    PRIVATE_ENDPOINT_TYPE: 'Private Endpoint Type',
    PRIVATE_ENDPOINT_NAME: 'Private Endpoint Name',
    PRIVATE_ENDPOINT_RESOURCE_GROUP: 'Private Endpoint Resource Group',
    PRIVATE_ENDPOINT_SUBSCRIPTION: 'Private Endpoint Subscription',
    SOURCE_RESOURCE_NAME: 'Source Resource Name',
    SOURCE_RESOURCE_TYPE: 'Source Resource Type',
    TARGET_RESOURCE_NAME: 'Target Resource Name',
    TARGET_RESOURCE_TYPE: 'Target Resource Type',
    TARGET_RESOURCE_SUB_TYPE: 'Target Resource Sub Type',
    ADO_OWNER: 'ADO Owner',
    APPLICATION_SERVICE: 'Application Service',
    ADO_PROJECT_TYPE: 'ADO Project Type',
    EXISTING_PROJECT: 'Existing Project',
    ADO_PLATFORM: 'ADO Platform',
    BUSINESS_APPLICATION: 'Business Application',
    ADO_PROJECT_NAME: 'ADO Project Name',
    PROJECT_ADMIN_AD_GROUP: 'Project Admin AD Group',
    ADO_PROJECT_URL: 'ADO Project URL',
    SONARQUBE_URL: 'SonarQube URL',
    SONARQUBE_SERVICE_ACCOUNT_REQUEST: 'SonarQube Service Account Request',
    FORTIFY_URL: 'Fortify URL',
    SYNK_URL: 'Synk URL',
    SERVICE_ACCOUNT: 'Service Account',
    MYPAM_SAFE_NAME: 'MyPAM Safe Name',
    MYPAM_SAFE_OWNER: 'MyPAM Safe Owner',
    TEST_PLAN_MEMBERS: 'Test Plan Members',
    SONAR_SERVICE_CONNECTION: 'Sonar Service Connection',
    SONAR_PROJECT_NAMES: 'Sonar Project Names',
    SERVICE_ACCOUNT_REQUEST: 'Service Account Request',
    SSO_APPLICATION_NAME: 'SSO Application Name',
    CLIENT_ID: 'Client ID',
    TYPE: 'Type',
    APPLICATION_CI: 'Application CI',
    APPLICATION_TYPE: 'Application Type',
    DATA_CLASSIFICATION: 'Data Classification',
    APPLICATION_SHORT_NAME: 'Application Short Name',
    LOGIN_URL: 'Login URL',
    LOGOUT_URL: 'Logout URL',
    ROLES: 'Roles',
    APPLICATION_URL: 'Application URL',
    RECIPIENT_URL: 'Recipient URL',
    H_CODE: 'H-Code',
    ADD_MEMBERS_REQUEST_NUMBER: 'Add Members Request Number',
    SAST_URL: 'SAST URL',
    SCA_URL: 'SCA URL',
    BUDGET_CODE: 'Budget Code',
};
export default {};
