import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import TestPlanOptions from 'pages/project-onboarding/components/Form/TestPlanComponents/TestPlanOptions';
import TestPlanUser from 'pages/project-onboarding/components/Form/ServiceMembers/TestPlanUser';
import TestPlanRemoveUser from 'pages/project-onboarding/components/Form/ServiceMembers/TestPlanRemoveUser';
import HCode from 'pages/project-onboarding/components/Form/TestPlanComponents/HCode';
import AddMemberList from 'pages/project-onboarding/components/Form/TestPlanComponents/AddMemberList';
import RemoveMemberList from 'pages/project-onboarding/components/Form/TestPlanComponents/RemoveMemberList';

const TestPlanConfiguration = () => {
    const [order] = useRecoilState(OrderPayload);

    const existingProject =
        order?.isADOProject === 'yes' || (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null);

    const newProject =
        (order?.isADOProject === 'no' && order?.isClarityId === 'no') ||
        (order?.isADOProject === 'no' &&
            order?.isClarityId === 'yes' &&
            order?.clarityDetails &&
            (typeof order?.clarityDetails?.devopsProject === 'undefined' ||
                order?.clarityDetails?.devopsProject === null ||
                order?.clarityDetails?.devopsProject === undefined));

    return (
        <>
            {existingProject && (
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <TestPlanOptions />
                    </Grid>
                </Grid>
            )}
            {(order?.addTestPlanMembers || newProject) && (
                <>
                    <Box display="flex" my={2}>
                        <Typography variant="h6" mr={1} sx={ProjectOnboardingStyles.configSubHeader}>
                            Add Members & Assign Basic + Test License *
                        </Typography>
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <HCode />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TestPlanUser />
                        </Grid>
                    </Grid>
                    <AddMemberList />
                </>
            )}
            {order?.removeTestPlanMembers && (
                <>
                    <Box display="flex" my={2}>
                        <Typography variant="h6" mr={1} sx={ProjectOnboardingStyles.configSubHeader}>
                            Remove Members & Assign Basic License *
                        </Typography>
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <TestPlanRemoveUser />
                        </Grid>
                    </Grid>
                    <RemoveMemberList />
                </>
            )}
        </>
    );
};

export default React.memo(TestPlanConfiguration);
