import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import Modal from 'components/modal';
import Btn from 'components/button';
import deleteIcon from 'assets/images/delete_icon.svg';
import addIcon from 'assets/images/add_icon.svg';
import SsoOktaStyles from 'pages/sso-okta/style';
import { OrderPayload, isLogoutUrlValid, toggleModels } from 'pages/sso-okta/store';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { commonStyles as style } from 'assets/style';
import { validateLoginLogoutURL } from 'pages/sso-okta/utils/index';

export const LogoutUrl = ({ index }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [isOpen, setIsOpen] = useRecoilState(toggleModels);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setLogoutUrlVaild] = useRecoilState(isLogoutUrlValid);
    const [helperText, setHelperText] = React.useState(false);
    const [lastModifiedURL, setLastModifiedURL] = React.useState(undefined);

    React.useEffect(() => {
        if (!order?.logoutUrl || order?.logoutUrl.length === 0) {
            setOrder({
                ...order,
                logoutUrl: [''],
            });
        }
    }, [order]);

    const setLogoutUrl = (logoutUrls) => {
        setOrder({
            ...order,
            logoutUrl: logoutUrls,
        });
    };

    const addLogoutUrl = () => {
        const updatedLogoutUrls = [...(order?.logoutUrl || [])];
        updatedLogoutUrls?.push('');
        setLogoutUrl(updatedLogoutUrls);
    };

    const removeLogoutUrl = (removeIndex) => {
        const updatedLogoutUrls = order?.logoutUrl?.filter((_, idx) => idx !== removeIndex);
        setLogoutUrl(updatedLogoutUrls);
    };

    const updateLogoutUrl = (value, updateIndex) => {
        const updatedLogoutUrls = [...(order?.logoutUrl || [])];
        updatedLogoutUrls[updateIndex] = value;
        setLogoutUrl(updatedLogoutUrls);
    };

    React.useEffect(() => {
        const currentLogoutUrl = order?.logoutUrl?.[index] || '';
        const { isValid } = validateLoginLogoutURL(currentLogoutUrl, order?.environment, order?.requestType);
        // Check if the current logout URL is invalid
        if (currentLogoutUrl && currentLogoutUrl !== '' && !isValid) {
            setHelperText('Logout URL is Invalid');
            setLogoutUrlVaild(false);
            return;
        }

        // Check if the current logout URL is empty
        if (currentLogoutUrl === '') {
            setLogoutUrlVaild(false);
            setHelperText(false);
            return;
        }

        // Check if the current logout URL matches any other logout URL
        const urlOccurrences = order?.logoutUrl?.filter((url) => url === currentLogoutUrl).length;
        if (currentLogoutUrl && urlOccurrences > 1) {
            setHelperText("Logout URL's should be unique.");
            setLogoutUrlVaild(false);
            return;
        }

        // Check if the current logout URL matches any logout URL
        if (currentLogoutUrl && order?.logoutUrl?.includes(currentLogoutUrl)) {
            setHelperText(
                <>
                    <Box>Your login and logout URLs should not be the same.</Box>
                    <Box>Example: Logout URL: https://devx-infra.dev.mypepsico.com/</Box>
                </>
            );
            setLogoutUrlVaild(false);
            return;
        }

        // If all validations pass
        setHelperText(false);
        setLogoutUrlVaild(true);
    }, [order?.logoutUrl, order?.loginUrl, order?.environment, order?.requestType, index]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.logoutUrl) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.logoutUrl?.[index] && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.logoutUrl, order?.isTouched]);

    React.useEffect(() => {
        if (isOpen?.isLogoutModelOpen) {
            setLastModifiedURL(order?.logoutUrl);
        }
    }, [isOpen?.isLogoutModelOpen]);

    return (
        <Modal
            title="Logout URL"
            handleClose={() => {
                setIsOpen({ ...isOpen, isLogoutModelOpen: false });
                setOrder({
                    ...order,
                    logoutUrl: lastModifiedURL,
                });
            }}
            open={isOpen.isLogoutModelOpen}
            width="40%"
        >
            <Box sx={SsoOktaStyles.loginLogoutModal.wrapper}>
                <Stack direction="row" spacing={1}>
                    <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Add Logout URL&apos;s</Typography>
                    <HelpImg
                        title={
                            <Box sx={{ whiteSpace: 'pre' }}>
                                <Box>It is also known as Single sign-on URL where the SAML assertion is sent with a HTTP POST.</Box>
                                <Box>This is often referred to as the SAML Assertion Consumer Service (ACS) URL for your application.</Box>
                                <Box sx={{ fontWeight: 'bold' }}>Logout URL should be in this format - https://devx-infra.dev.mypepsico.com/</Box>
                            </Box>
                        }
                        maxWidth="600px"
                    />
                </Stack>
                <Box sx={SsoOktaStyles.loginLogoutModal.contentWrapper}>
                    {(order?.logoutUrl || [])?.map((_, idx) => (
                        <Box key={idx.toString()} sx={SsoOktaStyles.loginLogoutModal.rowWrapper} className="space-x-2 mb-5 animate-opacity">
                            <Box sx={SsoOktaStyles.loginLogoutModal.consumerGrpWrapper}>
                                <CssTextField
                                    size="small"
                                    sx={{
                                        width: (theme) => theme.spacing('100%'),
                                        '& .MuiFormHelperText-root': {
                                            marginTop: (theme) => theme.spacing('-2'),
                                        },
                                    }}
                                    autoComplete="off"
                                    label={`Logout URL ${idx + 1}`}
                                    error={!!helperText}
                                    helperText={helperText && <span>{helperText}</span>}
                                    value={order?.logoutUrl?.[idx] || ''}
                                    onChange={(e) => updateLogoutUrl(e.target.value, idx)}
                                    className={validateInput()}
                                    data-testid={`logoutURLText-${idx}`}
                                />
                            </Box>
                            <Box sx={SsoOktaStyles.loginLogoutModal.iconWrapper}>
                                <Box className="flex w-full ">
                                    {idx !== 0 && (
                                        <Box onClick={() => removeLogoutUrl(idx)} sx={SsoOktaStyles.loginLogoutModal.deleteWrapper}>
                                            <img data-testid="remove-btn" style={SsoOktaStyles.loginLogoutModal.delete} src={deleteIcon} alt="deleteIcon" />
                                        </Box>
                                    )}
                                    {idx === order?.logoutUrl?.length - 1 && order?.logoutUrl?.length < 3 && (
                                        <Box sx={SsoOktaStyles.loginLogoutModal.deleteWrapper} onClick={addLogoutUrl}>
                                            <img style={SsoOktaStyles.loginLogoutModal.add} src={addIcon} alt="addIcon" />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="footer" sx={SsoOktaStyles.loginLogoutModal.actionWrapper} gap={2}>
                    <Btn
                        className="btn-disabled custom-btn"
                        variant="contained"
                        onClick={() => {
                            setIsOpen({ ...isOpen, isLogoutModelOpen: false });
                            setOrder({
                                ...order,
                                loginUrl: order?.loginUrl,
                            });
                        }}
                        data-testid="save-btn"
                        sx={style.modalSaveButton}
                        disabled={!order?.logoutUrl?.some((url) => url && url?.trim() !== '')}
                    >
                        Save
                    </Btn>
                    <Btn
                        className="custom-btn"
                        onClick={() => {
                            setIsOpen({ ...isOpen, isLogoutModelOpen: false });
                            setOrder({
                                ...order,
                                logoutUrl: lastModifiedURL,
                            });
                        }}
                        variant="outlined"
                        layout="outline"
                        data-testid="cancel-btn"
                        sx={style.modalCancelButton}
                    >
                        Cancel
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

LogoutUrl.propTypes = {
    index: PropTypes.number.isRequired,
};

export default LogoutUrl;
