import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import DialogModal from 'components/dialog';
import { FiAlertTriangle } from 'react-icons/fi';
import { commonStyles as style } from 'assets/style';

const ServiceDialog = (props) => {
    const { isOpen, member, handleNo, handleYes, title } = props;

    return (
        <DialogModal open={isOpen} handleClose={() => {}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <>
                    <FiAlertTriangle color="#C02223" size={45} />
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: '#000000',
                            paddingTop: '15px',
                            paddingBottom: '20px',
                            textWrap: 'preety',
                        }}
                    >
                        <>
                            {title} {member}?
                        </>
                    </Typography>
                </>

                <Box sx={{ textAlign: 'center' }}>
                    <Button
                        data-testid="removeMemberNo-btn"
                        onClick={() => handleNo()}
                        disableElevation
                        variant="contained"
                        sx={{ ...style.modalSaveButton, marginRight: '10px' }}
                    >
                        No
                    </Button>
                    <Button
                        data-testid="removeMemberYes-btn"
                        onClick={() => {
                            handleYes();
                        }}
                        disableElevation
                        variant="outlined"
                        sx={style.modalCancelButton}
                    >
                        Yes
                    </Button>
                </Box>
            </Box>
        </DialogModal>
    );
};

ServiceDialog.propTypes = {
    isOpen: PropTypes.bool,
    member: PropTypes.string,
    handleNo: PropTypes.func,
    handleYes: PropTypes.func,
    title: PropTypes.string,
};

ServiceDialog.defaultProps = {
    isOpen: false,
    member: '',
    handleNo: () => null,
    handleYes: () => null,
    title: 'Are you sure you want to remove',
};

export default React.memo(ServiceDialog);
