import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const headerStyles = {
    header: {
        background: (theme) => theme.palette.background.$color_background_layer_01,
        // background: '#001F50',
        flex: '0 1 auto',
        height: 'auto',
        borderBottom: '1px solid #D7E5F8',
        zIndex: 2,
        // borderBottom: (theme) => theme.palette.border.$color_border,
        // boxShadow: '#E6EFF8 0 8px 10px 0',
        boxShadow: 'rgba(0, 92, 188, 0.15) 0px 0px 24px 0',
        fontSize: '16px !important',
        '@media (max-width: 1366px)': {
            fontSize: '14px !important',
        },
        '@media (min-width: 1920px)': {
            fontSize: '18px !important',
        },
        // fontSize: { md: '16px', lg: '14px', xs: '14px', xl: '18px' },
    },
    headermenu: {
        padding: { md: '18px  12px', lg: '18px 20px', xs: '10px 5px' },
        fontWeight: 'normal',
        height: '63px',
    },
    profile: {
        container: {
            padding: '8px 16px 10px 16px',
            width: '310px !important',
            '@media (max-width: 1366px)': {
                width: '310px !important',
            },
            '@media (min-width: 1920px)': {
                width: '427px !important',
            },
        },
        label: {
            fontWeight: '700',
            color: '#3a3a3a',
            textAlign: 'left',
            marginBottom: '8px',
            fontSize: '12.25px !important',
            '@media (max-width: 1366px)': {
                fontSize: '12.25px !important',
            },
            '@media (min-width: 1920px)': {
                fontSize: '15.75px !important',
            },
        },
        profileUserName: {
            fontWeight: '700',
            alignItems: 'center',
            color: '#3a3a3a',
            fontSize: '14px !important',
            '@media (max-width: 1366px)': {
                fontSize: '14px !important',
            },
            '@media (min-width: 1920px)': {
                fontSize: '18px !important',
            },
        },
        email: {
            fontWeight: '400',
            color: '#3a3a3a',
            marginLeft: '34px',
            fontSize: '8.75px !important',
            '@media (max-width: 1366px)': {
                fontSize: '8.75px !important',
            },
            '@media (min-width: 1920px)': {
                fontSize: '11.25px !important',
            },
        },
    },

    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        // marginRight: (theme) => theme.spacing(4),
        '.menuIcon': {
            color: (theme) => theme.palette.textColors.$color_text_secondary,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
    small: {
        width: '40px',
        height: '40px',
        // font: (theme) => theme.typography.fontSize.$font_small_bold,
        fontSize: '11.9px',
        '@media (max-width: 1366px)': {
            fontSize: '11.9px !important',
        },
        '@media (min-width: 1920px)': {
            fontSize: '15.3px !important',
        },
        fontWeight: 700,
        // fontSize: (theme) => theme.spacing(1.7),
        // fontWeight: 'bold',
        background: '#B2CAEA',
        color: '#005cbc',
        letterSpacing: 'unset',
        lineHeight: 3,
    },
    listIcon: {
        minWidth: (theme) => theme.spacing(5),
        color: 'inherit',
    },
    button: {
        margin: (theme) => theme.spacing(1),
        color: (theme) => theme.palette.blue.light,
        height: (theme) => theme.spacing(5),
        padding: (theme) => theme.spacing(1, 2),
        '&:hover, &.active': {
            background: '#1D2434',
        },
        '& .btnTxt': {
            position: 'relative',
            top: 1,
        },
    },
    avatarTxt: {
        color: (theme) => theme.palette.textColors.$color_text_secondary,
        fontSize: 16,
        textTransform: 'captalize',
    },
    menuitemColor: { color: (theme) => theme.palette.textColors.$color_text_secondary, paddingTop: '5px', paddingBottom: '5px' },
    logoImgWidth: { width: 90 },
    dropdownArrow: { fontSize: (theme) => theme.spacing(4), paddingLeft: '10px' },
    rightArrow: { fontSize: (theme) => theme.spacing(4), paddingLeft: '10px' },
    menuWrapper: { display: 'flex', height: '61px', fontSize: '1em' },
    avatarSize: { minWidth: (theme) => theme.spacing(5) },
    cart: {
        container: {
            cursor: 'pointer',
            color: 'action.active',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiBadge-badge': {
                font: (theme) => theme.typography.fontSize.$font_xsmall_regular,
                background: '#B2CAEA',
                color: (theme) => theme.palette.textColors.$color_text_secondary,
                // fontWeight: 400,
                // fontSize: '12px',
            },
            '&:hover': {
                backgroundColor: '#dfeaf9 !important',
            },
        },
        icon: {
            fontSize: 30,
            color: (theme) => theme.palette.textColors.$color_text_secondary,
        },
    },

    listwrapper: {
        width: '100% !important',
        backgroundColor: '#5A5A5A',
        color: (theme) => theme.palette.textColors.$color_text_secondary,
        padding: '0px 0px 0px 0px !important',
        transition: '0s',
    },

    collapsemenuitem: {
        backgroundColor: '#B9B6B6',
        paddingTop: '9px',
        paddingBottom: '15px',
        borderBottom: '1px solid #E5E4E4',
        width: '115%',
        transition: '0s',
        marginLeft: '-7.5%',
        '&:hover': {
            backgroundColor: '#B9B6B6',
            //    padding: '3px 2px',
        },
    },
    hovermenus: {
        '& ul.MuiList-root': {
            backgroundColor: (theme) => theme.palette.background.$color_background_layer_01,
            padding: 0,
            fontSize: '16px !important',
            '@media (max-width: 1366px)': {
                fontSize: '14px !important',
            },
            '@media (min-width: 1920px)': {
                fontSize: '18px !important',
            },
        },
    },
    hovermenuitems: {
        backgroundColor: (theme) => theme.palette.background.$color_background_layer_01,
        borderBottom: '1px solid #DFDFDF',

        '&:hover': {
            // backgroundColor: (theme) => theme.palette.background.$color_background_layer_01,
            backgroundColor: (theme) => theme.palette.interAction.$color_interaction_hover,
        },
    },
    disabled: {
        pointerEvents: 'none',
        color: '#aaa',
        cursor: 'not-allowed',
    },
    cascadingMenu: {
        menuItem: {
            navLink: {
                textDecoration: 'none',
                color: (theme) => theme.palette.textColors.$color_text_secondary,
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&:active': {
                    borderBottom: 'none',
                },
            },
            subMenu: {
                maxWidth: '350px',
                wordWrap: 'break-word',
                textWrap: 'wrap',
                fontSize: '16px !important',
                '@media (max-width: 1366px)': {
                    fontSize: '14px !important',
                },
                '@media (min-width: 1920px)': {
                    fontSize: '18px !important',
                },
            },
            disabledNavLink: {
                pointerEvents: 'none',
                color: '#aaa',
                cursor: 'not-allowed',
            },
        },
    },

    humbergureContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: (theme) => theme.palette.background.$color_background_button_primary,
        width: '52px',
        height: '63px',
        cursor: 'pointer',
    },
    humbergureTitle: {
        color: '#3A3A3A',
        fontWeight: '700',
        fontSize: '12.25px',
        lineHeight: '19.75px',
        '@media (max-width: 1366px)': {
            fontSize: '12.25px',
            //   fontSize: '14px !important',
        },
        '@media (min-width: 1920px)': {
            fontSize: '15.75px',
            // fontSize: '18px !important',
        },
    },
    humbergureCloseIcon: { cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' },
    closeIconContainer: { display: 'flex', alignItems: 'end', justifyContent: 'end', padding: '1px 6px' },
    humbergureImages: {
        background: '#B2CAEA',
        color: '#3A3A3A',
        borderRadius: '8px',
        width: '42px',
        height: '42px',
        '@media (max-width: 1366px)': {
            width: '42px',
            height: '42px',
            //   fontSize: '14px !important',
        },
        '@media (min-width: 1920px)': {
            img: {
                height: '31px',
                width: '31px',
            },
            width: '54px',
            height: '54px',
            // fontSize: '18px !important',
        },
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    imageContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        padding: '24px 0 12px',
        gap: '30px 0',
    },
    link: {
        alignItems: 'center',
        flexDirection: 'column',
        color: '#3a3a3a',
        fontSize: '12px',
        rowGap: '7px',
        display: 'flex',
        cursor: 'pointer',
        padding: 0,
        justifyContent: 'center',
        // textDecoration: 'none',
    },

    listImage: {
        img: {
            width: '42px',
            height: '42px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            '@media (max-width: 1366px)': {
                width: '42px',
                height: '42px',
                //   fontSize: '14px !important',
            },
            '@media (min-width: 1920px)': {
                width: '54px',
                height: '54px',
                // fontSize: '18px !important',
            },
        },
    },
    eventPopover: { top: '0 !important', borderRadius: '8px !important' },
};

export const NavLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    textDecoration: 'none',
    // margin: theme.spacing(1),
    color: theme.palette.textColors.$color_text_secondary,
    height: 'auto',
    '&:hover': {
        background: theme.palette.background.$color_background_layer_01,
    },
    '&:active': {
        borderBottom: theme.palette.border.$color_border_bottom,
        borderRadius: '0px',
    },
}));

export default headerStyles;
