import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
// import ProvisioningStyles from 'pages/myidm/style';
import Btn from 'components/button';
import { commonStyles as style } from 'assets/style';

export const AddMemberBtn = ({ handleAddMember, invalid }) => {
    const [isLoading] = React.useState(false);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto' }}>
            <Btn
                size="medium"
                onClick={() => handleAddMember()}
                variant="contained"
                disabled={invalid}
                className="btn-disabled"
                // sx={ProvisioningStyles?.addmemBtn}
                // color="cmpPrimary"
                sx={{ ...style.primaryMediumButton, width: '135px' }}
                data-testid="addmember-btn"
            >
                {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Add Member'}
            </Btn>
        </Box>
    );
};

AddMemberBtn.propTypes = {
    handleAddMember: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
};

AddMemberBtn.defaultProps = {};

export default React.memo(AddMemberBtn);
