import React from 'react';
import { Box, Typography } from '@mui/material';
import GithubOnboardingStyles from 'pages/github-onboarding/style';
import ProjectDetails from './project-details';
import ApplicationDetails from './application-details';
import MigrationReposDetails from './migration-repos-details';
import UserAccessDetails from './user-access-details';
import ReposAccessControlDetails from './repos-access-control-details';

const PreviewOrderDetails = () => {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} data-testid="preview-header">
                <Box sx={GithubOnboardingStyles?.preview?.header?.previewDetailsTextWrap}>
                    <Typography component="span" sx={GithubOnboardingStyles?.preview?.header?.previewDetailsText}>
                        GitHub Details (Preview)
                    </Typography>
                </Box>
            </Box>
            <ProjectDetails />
            <ApplicationDetails />
            <MigrationReposDetails />
            <UserAccessDetails />
            <ReposAccessControlDetails />
        </>
    );
};

export default PreviewOrderDetails;
