import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, toggleModal, validOnboardingForm } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles, { CustomRadioGroup, CustomRadio } from 'pages/project-onboarding/style';

const SCAOptions = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [isOpen] = useRecoilState(toggleModal);

    const setSCAOption = (scaOption) => {
        setOrder({
            ...order,
            scaOption,
            organizationName: null,
            organizationNameDetails: null,
            scaRoles: null,
            snykMembers: [],
        });

        setValidForm({
            ...isValidForm,
            isDuplicateSASTMember: false,
            isSASTMaxMemberLimit: false,
            isDuplicateSCAMember: false,
            isSCAMaxMemberLimit: false,
            isSCAMemberOrganizationInvalid: false,
        });
    };

    const handleOnSelectChange = (selected) => {
        setSCAOption(selected);
    };

    const getValidate = () => {
        if (isOpen?.isFortifySnykOpen && !order?.scaOption && order?.isConfigureTouched) return 'ritmCheckbox';
        return '';
    };

    return (
        <Stack direction="row" spacing={1} sx={ProjectOnboardingStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '36px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', fontSize: '12px', fontWeight: '600' }} id="autoinflateRadioGrp">
                    Select the SCA Onboarding Option *
                </Box>
                <CustomRadioGroup
                    data-testid="sca-radio-group"
                    aria-label="sca"
                    name="scaRadioBtnGroup"
                    row
                    value={order?.scaOption || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                    className={getValidate()}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                >
                    <FormControlLabel
                        value="AccessExistingOrganization"
                        control={<CustomRadio className={getValidate()} />}
                        label="Request access for existing Organization in SCA"
                    />
                    <FormControlLabel value="CreateNewOrganization" control={<CustomRadio className={getValidate()} />} label="Create an Organization in SCA" />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default React.memo(SCAOptions);
