import React from 'react';
import { FormControl, FormControlLabel, Stack, Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/sso-okta/store';
import SsoOktaStyles, { CustomRadioGroup, CustomRadio } from 'pages/sso-okta/style';

export const LogoutUrlRadioBtn = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const setLogoutRadioBtn = (value) => {
        setOrder({
            ...order,
            logoutUrl: [],
            isLogoutUrlRequired: value,
        });
    };
    const handleOnSelectChange = (selected) => {
        setLogoutRadioBtn(selected);
    };

    return (
        <Stack direction="row" spacing={1} sx={SsoOktaStyles.stack}>
            <FormControl sx={SsoOktaStyles?.form?.radioLabel}>
                <Box sx={{ color: '#0047BA', fontSize: '12px', fontWeight: 'bold' }} data-testid="logoutRadioGrp">
                    Do you need Logout URL?
                </Box>
                <CustomRadioGroup
                    aria-label="LogoutUrlRadio"
                    name="logoutUrlRequiredBtnGroup"
                    row
                    value={order?.isLogoutUrlRequired || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
                    <FormControlLabel
                        value="no"
                        sx={{
                            marginLeft: '60px',
                        }}
                        control={<CustomRadio />}
                        label="No"
                    />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default LogoutUrlRadioBtn;
