import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, FormControlLabel, Typography } from '@mui/material';
import { RIStyles, CustomRadioGroup, CustomRadio } from 'pages/reference-implementation/Style';

const WriteAccessRadioBtn = (props) => {
    const { isWriteAccessValue, handleOnSelect, isRequired } = props;
    return (
        <Box>
            <Typography sx={RIStyles?.configuration?.cosmos?.heading}>Do You have Write Access Service Account?</Typography>
            <FormControl error={isRequired}>
                <CustomRadioGroup
                    aria-label="WrtireGroup"
                    name="writegroupRadioBtnGroup"
                    row
                    value={isWriteAccessValue}
                    onChange={(event, value) => {
                        handleOnSelect(value);
                    }}
                >
                    <FormControlLabel
                        value="Yes"
                        control={
                            <CustomRadio
                                sx={{
                                    color: `${isRequired ? '#d40020' : '#0047BA'}`,
                                }}
                            />
                        }
                        label="Yes"
                        data-testid="writeAccessYes"
                    />
                    <FormControlLabel
                        value="No"
                        sx={{
                            marginLeft: '60px',
                        }}
                        control={
                            <CustomRadio
                                sx={{
                                    color: `${isRequired ? '#d40020' : '#0047BA'}`,
                                }}
                            />
                        }
                        label="No"
                        data-testid="writeAccessNo"
                    />
                </CustomRadioGroup>
                {/* {isRequired && <FormHelperText sx={{ color: '#d40020', fontSize: 12 }}>Please Provide Write Access Service Account</FormHelperText>} */}
            </FormControl>
        </Box>
    );
};

WriteAccessRadioBtn.propTypes = {
    isWriteAccessValue: PropTypes.any,
    handleOnSelect: PropTypes.func,
    isRequired: PropTypes.any,
};

WriteAccessRadioBtn.defaultProps = {
    isWriteAccessValue: 'no',
    handleOnSelect: () => {},
    isRequired: false,
};

export default WriteAccessRadioBtn;
