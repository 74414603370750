/* eslint-disable import/no-named-default */

import { default as ProjectId } from './ProjectId';
import { default as BusinessUnit } from './BusinessUnit';
import { default as Sector } from './Sector';
import { default as Division } from './Division';
import { default as BudgetCode } from './BudgetCode';
import { default as BillingInfo } from './BillingInfo';
import { default as Environments } from './Environments';
import { default as DataClassification } from './DataClassification';
import { default as BusinessAppServiceRadioBtn } from './BusinessAppServiceRadioBtn';
import { default as BusinessApp } from './BusinessApp';
import { default as ApplicationShortName } from './ApplicationShortName';
import { default as ApplicationService } from './ApplicationService';
import { default as BusinessAppText } from './BusinessAppText';
import { default as ApplicationServiceText } from './ApplicationServiceText';
import { default as AppOwnerSearch } from './AppOwnerSearch';
import { default as NonPrivilegedADGroupOwner } from './NonPrivilegedADGroupOwner';
import { default as PrivilegedADGroupOwner } from './PrivilegedADGroupOwner';
import { default as BusinessContact } from './BusinessContact';
import { default as NonPrivilegedADGroupModal } from './NonPrivilegedADGroupModal';
import { default as ADGroupReader } from './ADGroupReader';
import { default as PrivilegedADGroupModal } from './PrivilegedADGroupModal';
import { default as ADGroupContributor } from './ADGroupContributor';
import { default as AppOwner } from './AppOwner';
import { default as ApplicationRegion } from './ApplicationRegion';
import { default as AddADGroups } from './AddADGroups';
import { default as PrivilegedADGroup } from './PrivilegedADGroup';
import { default as NonPrivilegedADGroup } from './NonPrivilegedADGroup';
import { default as ApplicationName } from './ApplicationName';
import { default as PreviousOrderID } from './PreviousOrderID';
import { default as ISANumber } from './ISAnumber';
import { default as ITEApplicationService } from './ITEApplicationService';

const Forms = {
    ProjectId,
    BusinessUnit,
    Sector,
    Division,
    BudgetCode,
    BillingInfo,
    Environments,
    DataClassification,
    BusinessAppServiceRadioBtn,
    BusinessApp,
    ApplicationShortName,
    ApplicationService,
    BusinessAppText,
    ApplicationServiceText,
    AppOwnerSearch,
    NonPrivilegedADGroupOwner,
    PrivilegedADGroupOwner,
    BusinessContact,
    NonPrivilegedADGroupModal,
    ADGroupReader,
    PrivilegedADGroupModal,
    ADGroupContributor,
    NonPrivilegedADGroup,
    PrivilegedADGroup,
    ApplicationRegion,
    AddADGroups,
    AppOwner,
    ApplicationName,
    PreviousOrderID,
    ISANumber,
    ITEApplicationService,
};

export default Forms;
