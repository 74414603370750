import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, FormControlLabel, Typography } from '@mui/material';
import { CustomRadioGroup, CustomRadio, RIStyles } from 'pages/reference-implementation/Style';
import { commonStyles as style } from 'assets/style';
import Btn from 'components/button';

const Cosmos = (props) => {
    const { resource, handleDrawerAction } = props;
    const [formData, setFormData] = useState({
        isGeoReplication: resource?.configureData?.isGeoReplication ?? 'No',
    });
    const [, setErrors] = useState({});
    const [touched, setTouched] = useState({ isGeoReplication: true });

    const validateForm = () => {
        const newErrors = {};
        setErrors(newErrors);
        return { isValid: true, errors: newErrors };
    };

    const handleOnSelectChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
        setTouched({
            ...touched,
            [key]: true,
        });
    };

    const handleSubmit = () => {
        // Set all fields as touched to trigger validation errors display
        const checkFormValid = validateForm();
        handleDrawerAction('save', formData, checkFormValid);
    };

    const saveButton = () => (
        <Btn className="custom-btn" variant="contained" sx={style.modalSaveButton} onClick={() => handleSubmit()} data-testid="save-btn">
            Save
        </Btn>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            // sx={RIStyles.tags.resetBtn}
            sx={style.modalCancelButton}
            variant="outlined"
            onClick={() => handleDrawerAction('cancel')}
            data-testid="cancel-btn"
        >
            Cancel
        </Btn>
    );

    return (
        <Box style={RIStyles.tags.wrapper}>
            <Box sx={RIStyles.tags.contentWrapper}>
                <Typography sx={RIStyles?.configuration?.cosmos?.heading}>Geo Replication</Typography>
                <FormControl>
                    <CustomRadioGroup
                        aria-label="GeoReplication"
                        name="GeoReplicationRadioBtnGroup"
                        row
                        value={formData?.isGeoReplication}
                        onChange={(event, value) => handleOnSelectChange('isGeoReplication', value)}
                    >
                        <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" disabled />
                        <FormControlLabel
                            value="No"
                            sx={{
                                marginLeft: '60px',
                            }}
                            control={<CustomRadio />}
                            label="No"
                            disabled
                        />
                    </CustomRadioGroup>
                </FormControl>
            </Box>
            <Box className="footer" sx={RIStyles.tags.actionWrapper} gap={2}>
                {saveButton()}
                {cancelButton()}
            </Box>
        </Box>
    );
};
Cosmos.propTypes = {
    resource: PropTypes.any,
    handleDrawerAction: PropTypes.func,
};

Cosmos.defaultProps = {
    resource: null,
    handleDrawerAction: PropTypes.func,
};
export default Cosmos;
