import React, { useState, lazy, Suspense, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import axios from 'api/request';
import { useMutation } from 'react-query';
import {
    OrderPayload,
    selectedMembersList,
    memberForm,
    optionsList,
    orderId as cmpOrderId,
    orderSuccessPopup,
    showisExistADGrp,
    isAppShortNameValid,
    isAppShortNameExist,
    isAppNameValid,
    costEstimateState,
} from 'pages/myidm/store';
import { getAddMemberPayload } from 'pages/myidm/utils/getPayload';
import { endPoints } from 'api/endpoints';
import { isReadyToSubmit, resetCostEstimateDetails } from 'pages/myidm/utils';
import { OrderSuccessDialog } from 'pages/myidm/components/order-success';
// import ProvisioningStyles from 'pages/myidm/style';
import Btn from 'components/button';
import { commonStyles as style } from 'assets/style';

const ErrorBanner = lazy(() => import('pages/myidm/components/error-banner'));

export const SubmitRequest = () => {
    const { orderId, operation } = useParams();
    const [members] = useRecoilState(selectedMembersList);
    const [, setOrderId] = useRecoilState(cmpOrderId);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setMembers] = useRecoilState(selectedMembersList);
    const [form, setForm] = useRecoilState(memberForm);
    const [isSuccessOpen, setSuccessPopup] = useRecoilState(orderSuccessPopup);
    const [, setIsExistADGrp] = useRecoilState(showisExistADGrp);
    const [appShortNameValid, setAppShortNameVaild] = useRecoilState(isAppShortNameValid);
    const [appShortNameExist, setAppShortNameExist] = useRecoilState(isAppShortNameExist);
    const [applicationNameValid, setApplicationNameVaild] = useRecoilState(isAppNameValid);
    const [, setOptions] = useRecoilState(optionsList);
    const [, setCostEstimateDetail] = useRecoilState(costEstimateState);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const submitOrderApi = useMutation((payload) => axios.post(endPoints?.myIdm?.Jwt?.createOrder, payload));
    const editOrderApi = useMutation((payload) => axios.put(`${endPoints?.myIdm?.Jwt?.editOrder}/${orderId}`, payload));

    const resetForm = () => {
        setOptions([]);
        setForm({
            ...form,
            selectedMember: null,
            member: null,
        });
        setMembers([]);
        setOrder({
            ...order,
            adGroup: null,
            businessApplication: null,
            businessApplicationDetails: null,
            environment: null,
            envShortName: null,
            sector: null,
            isNewADGroup: null,
            AppShortName: null,
            applicationName: null,
            adGroupType: null,
            adGroupTypeDetails: null,
            groupOwner: null,
            groupOwnerDetails: null,
            appService: null,
            members: null,
            mypamsafeDetails: [],
            nonSafeDetailsGpid: [],
            isTouched: false,
        });
        setIsExistADGrp(false);
        setAppShortNameVaild(false);
        setAppShortNameExist(false);
        setApplicationNameVaild(false);
        setCostEstimateDetail(resetCostEstimateDetails);
    };
    const handleSubmit = async () => {
        const payload = getAddMemberPayload({ order, members });
        if (operation === 'edit') {
            editOrderApi.mutate(payload);
        } else {
            submitOrderApi.mutate(payload);
        }
    };

    // const isReadyToSubmit = () => {
    //     return (
    //         (order?.isNewADGroup === 'no' && !order?.adGroup && !order?.adGroupType) ||
    //         (order?.isNewADGroup === 'yes' && (!order?.appShortName || !order?.groupOwner || !order?.applicationName)) ||
    //         !order?.businessApplication ||
    //         !order?.environment ||
    //         !order?.sector ||
    //         !order?.adGroupType ||
    //         (order?.isNewADGroup === 'yes' && (!appShortNameValid || appShortNameExist)) ||
    //         members?.length === 0 ||
    //         (order?.isNewADGroup === 'yes' && (!applicationNameValid || !order?.appService)) ||
    //         (order?.adGroupType === 'Privileged' && order?.nonSafeDetailsGpid?.length > 0 && !order?.estimatedCost)
    //     );
    // };

    const handleValidation = () => {
        const validationStatus = isReadyToSubmit({ order, appShortNameValid, appShortNameExist, applicationNameValid });
        if (validationStatus?.isValid) {
            handleSubmit();
        } else {
            setErrorBannerOpen(true);
            setOrder({
                ...order,
                isTouched: true,
            });
        }
    };
    const handleReset = () => {
        setSuccessPopup(false);
        resetForm();
    };

    useEffect(() => {
        if (submitOrderApi?.data?.data?.orderId) {
            setOrderId(submitOrderApi?.data?.data?.orderId);
            setSuccessPopup(true);
        } else if (editOrderApi?.isSuccess) {
            setOrderId(orderId);
            setSuccessPopup(true);
        } else {
            setSuccessPopup(false);
        }
    }, [submitOrderApi?.isSuccess, editOrderApi?.isSuccess]);

    return (
        <Suspense
            fallback={
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 100px)" width="100%">
                    <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    <Typography variant="body2" sx={{ color: '#000000' }}>
                        Loading
                    </Typography>
                </Box>
            }
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {!orderId && !operation && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={submitOrderApi?.isLoading}
                        // sx={ProvisioningStyles?.cartBtn}
                        sx={{ ...style.primaryMediumButton, width: '150px' }}
                        data-testid="add-to-cart-btn"
                    >
                        {submitOrderApi?.isLoading ? <CircularProgress color="inherit" size={16} /> : 'Submit Request'}
                    </Btn>
                )}
                {orderId && operation === 'edit' && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={submitOrderApi?.isLoading}
                        // sx={ProvisioningStyles?.resubmitBtn}
                        sx={{ ...style.primaryMediumButton, width: '166px' }}
                        data-testid="update-to-cart-btn"
                    >
                        {editOrderApi?.isLoading ? <CircularProgress color="inherit" size={16} /> : 'Re-Submit Request'}
                    </Btn>
                )}
            </Box>
            <OrderSuccessDialog isOpen={isSuccessOpen} reset={handleReset} redirectUrl="/access-management-myidm/DAY2ADDMEMBERS" type={operation} />
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click submit request."
                    handleCloseCallback={() => setErrorBannerOpen(false)}
                />
            )}
        </Suspense>
    );
};

SubmitRequest.propTypes = {};

SubmitRequest.defaultProps = {};

export default React.memo(SubmitRequest);
