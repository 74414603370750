import React from 'react';
import { Alert, Box } from '@mui/material';
import Select from 'components/select';
import Btn from 'components/button';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/github-onboarding/store';
import { commonStyles as style } from 'assets/style';
import filterGithubRepos from '../../../../utils/filterGithubRepos';

const GithubReposSearch = ({ isLoading, handleAddRepo, reposList, teamName }) => {
    const [order] = useRecoilState(OrderPayload);
    const [selected, setSelected] = React.useState(null);
    const [isRepoOnList, setIsRepoOnList] = React.useState(false);
    const repos = filterGithubRepos(order?.availableGithubRepos, order.customProperties.capability, order.customProperties.businessApplication);
    const repoOptions = repos.map((repo) => ({ label: repo, value: repo }));

    const handleAdd = () => {
        if (!selected) return;
        if (reposList.includes(selected)) {
            setIsRepoOnList(true);
            return;
        }
        handleAddRepo(teamName, selected);
        setSelected(null);
        setIsRepoOnList(false);
    };

    return (
        <>
            <Box display="flex" alignItems="flex-start" gap={1}>
                <Box sx={{ width: '50%', maxWidth: '400px' }}>
                    <Select
                        data-testid="github-repo-search"
                        label="GitHub Repo"
                        value={repoOptions.find((option) => option.value === selected) || null}
                        options={repoOptions}
                        onChange={(_event, newValue) => setSelected(newValue.value)}
                        disabled={!repos.length || isLoading}
                        isLoading={isLoading}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                    <Btn
                        size="small"
                        onClick={handleAdd}
                        variant="contained"
                        disabled={!selected}
                        sx={{ ...style.primaryMediumButton, width: '100px', marginTop: '3px' }}
                        data-testid="add-github-repo-btn"
                        className="btn-disabled"
                    >
                        Add Repo
                    </Btn>
                </Box>
            </Box>
            {isRepoOnList && (
                <Alert severity="error" color="error" sx={{ marginTop: 2 }}>
                    This repository has already been added to the list.
                </Alert>
            )}
        </>
    );
};

GithubReposSearch.propTypes = {
    isLoading: PropTypes.bool,
    handleAddRepo: PropTypes.func,
    reposList: PropTypes.array,
    teamName: PropTypes.string,
};

GithubReposSearch.defaultProps = {
    isLoading: false,
    handleAddRepo: undefined,
    reposList: [],
    teamName: '',
};

export default React.memo(GithubReposSearch);
