import { atom } from 'recoil';

export const ArchImgUrl = atom({
    key: 'RI004O-ArchImgUrl',
    default: undefined,
});

export const isExpandArchDiagram = atom({
    key: 'RI004O-isExpandArchDiagram',
    default: false,
});

export const OrderPayload = atom({
    key: 'RI004O-OrderPayload',
    default: {
        catalogCode: null,
        projectId: null,
        projectDetails: null,
        businessUnit: null,
        sector: null,
        program: null,
        division: null,
        environment: null,
        dataClassification: null,
        siteName: null,
        budgetCode: null,
        budgetCodeDetails: null,
        billingInfo: null,
        nonPrivilegedADGroupList: [{ readerValue: '' }],
        // privilegedADGroupList: [{ contributorValue: '' }],
        environmentDetails: null,
        adGroup: 'Yes',
        // privilegedADGroup: null,
        nonPrivilegedADGroup: null,
        businessAppService: 'No',
        businessApp: null,
        appService: null,
        isTouched: false,
        estimatedCost: null,
        appOwner: null,
        appOwnerDetails: null,
        certificateOwnerOne: null,
        certificateOwnerOneDetails: null,
        certificateOwnerTwo: null,
        certificateOwnerTwoDetails: null,
        businessContact: null,
        businessContactDetails: null,
        // privilegedADGroupOwner: null,
        // privilegedADGroupOwnerDetails: null,
        nonPrivilegedADGroupOwner: null,
        nonPrivilegedADGroupOwnerDetails: null,
        applicationName: null,
        appShortName: null,
        isaNumber: null,
        iteAppService: null,
        appSupportMailId: null,
        resourceMgmt: null,
        isExternal: 'No',
    },
});

export const isGetAppShortNameLoading = atom({
    key: 'RI004O-isGetAppShortNameLoading',
    default: false,
});

export const isAppNameValid = atom({
    key: 'RI004O-isAppNameValid',
    default: false,
});

export const isAppShortNameValid = atom({
    key: 'RI004O-isAppShortNameValid',
    default: false,
});

export const isAppShortNameExist = atom({
    key: 'RI004O-isAppShortNameExist',
    default: false,
});

export const isBusinessNameValid = atom({
    key: 'RI004O-isBusinessNameValid',
    default: true,
});

export const isBudgetCodeValid = atom({
    key: 'RI004O-isBudgetCodeValid',
    default: true,
});

export const isBillingInfoValid = atom({
    key: 'RI004O-isBillingInfoValid',
    default: true,
});

export const isProjectIdValid = atom({
    key: 'RI004O-isProjectIdValid',
    default: false,
});

export const adGroupErrorMsg = atom({
    key: 'RI004O-adGroupErrorMsg',
    default: false,
});

export const contributorErrorMsg = atom({
    key: 'RI004O-contributorErrorMsg',
    default: false,
});

export const isADGroupValid = atom({
    key: 'RI004O-isADGroupValid',
    default: true,
});

export const isBusinessAppValid = atom({
    key: 'RI004O-isBusinessAppValid',
    default: false,
});

export const isAppUrlValid = atom({
    key: 'RI004O-isAppUrlValid',
    default: false,
});

export const isAppContextPathValid = atom({
    key: 'RI004O-isAppContextPathValid',
    default: false,
});

export const isNonPrivilegedADGroupValid = atom({
    key: 'RI004O-isNonPrivilegedADGroupValid',
    default: false,
});

export const isPrivilegedADGroupValid = atom({
    key: 'RI004O-isPrivilegedADGroupValid',
    default: false,
});

export const costEstimateState = atom({
    key: 'RI004O-costEstimateState',
    default: null,
});

export const toggleModal = atom({
    key: 'RI004O-toggleModal',
    default: {
        isADGroupModalOpen: false,
        isNonPrivilegedADGroupOpen: false,
        isPrivilegedADGroupOpen: false,
        isCost: { costType: '', isCostOpen: false },
    },
});

export const showPreviewOrder = atom({
    key: 'RI004O-showPreviewOrder',
    default: false,
});

export const previewDetails = atom({
    key: 'RI004O-previewdetails',
    default: null,
});
export const previewApiLoading = atom({
    key: 'RI004O-previewApiLoading',
    default: false,
});
export const isSectorDisabled = atom({
    key: 'RI004O-isSectorDisabled',
    default: true,
});
export const isBusinessUnitDisabled = atom({
    key: 'RI004O-isBusinessUnitDisabled',
    default: true,
});
export const isHcodeDisabled = atom({
    key: 'RI004O-isHcodeDisabled',
    default: true,
});
export const previewOrderError = atom({
    key: 'RI004O-previewError',
    default: {
        isPreviewError: false,
        message: '',
    },
});

export const isValidappSupportMailId = atom({
    key: 'RI004O-isValidappSupportMailId',
    default: false,
});
export const isValidOwners = atom({
    key: 'RI004O-isValidOwners',
    default: {
        isvalidAppOwner: true,
        isvalidCertificateOwnerOne: true,
        isvalidCertificateOwnerTwo: true,
    },
});
export const componentGroupsState = atom({
    key: 'RI004O-componentGroupsState',
    default: null,
});

export const SecondaryApprover = atom({
    key: 'RI004O-secondaryApprover',
    default: { selectedFieldFrom: { module: null, subModule: null }, value: { groupOwner: null, groupOwnerDetails: null } },
});

export const isValidISANumber = atom({
    key: 'RI004O-isValidISANumber',
    default: false,
});
