import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import Style from 'pages/project-onboarding/style';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { OrderPayload, showPreviewOrder, validOnboardingForm, toggleModal, isSonarQubeRepoAvailable, urlParams } from 'pages/project-onboarding/store';
import Btn from 'components/button';
import { getOrderPayload, resetOrder, resetValidOnboardingForm, resetToggleModal, getToolsOrderPayload } from 'pages/project-onboarding/utils';
import { DialogBox } from 'pages/project-onboarding/components/dialog-box';
import { commonStyles as style } from 'assets/style';

const PreviewHeader = () => {
    const { cartItemId, operation } = useParams();
    const [params] = useRecoilState(urlParams);
    const history = useHistory();
    const [showPreview, setShowPreview] = useRecoilState(showPreviewOrder);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setValidProvisionForm] = useRecoilState(validOnboardingForm);
    const [modal, setToggleModal] = useRecoilState(toggleModal);
    const [, setAvailableRepo] = useRecoilState(isSonarQubeRepoAvailable);

    const submitOrderApi = useMutation((payload) => axios.post(endPoints.day2Operation?.ProjectOnboarding?.Jwt?.submitOrder, payload));
    const editOrderApi = useMutation((payload) => axios.put(`${endPoints.day2Operation?.ProjectOnboarding?.Jwt?.editOrder}/${cartItemId}`, payload));
    const toolSubmitApi = useMutation((payload) => axios.post(`${endPoints?.devX?.submitTools?.replace('{journeyId}', params?.journeyId)}`, payload));

    React.useEffect(async () => {
        if (submitOrderApi?.data?.data) {
            if (params?.journeyId) {
                const payload = await getOrderPayload({ order });
                const toolsPayload = await getToolsOrderPayload(
                    payload,
                    { applicationId: params?.appRegistrationId, journeyId: params?.journeyId },
                    { applicationOwnerDetails: order?.applicationOwnerDetails, orderId: submitOrderApi?.data?.data?.orderId }
                );
                toolSubmitApi.mutate(toolsPayload);
            } else {
                setToggleModal({
                    ...modal,
                    isSuccessOpen: true,
                    orderId: submitOrderApi?.data?.data?.orderId,
                });
            }
            setValidProvisionForm(resetValidOnboardingForm);
        }
    }, [submitOrderApi?.isSuccess]);

    React.useEffect(() => {
        if (editOrderApi?.isSuccess) {
            setToggleModal({
                ...modal,
                isSuccessOpen: true,
                orderId: cartItemId,
            });
            setValidProvisionForm(resetValidOnboardingForm);
        }
    }, [editOrderApi?.isSuccess]);

    React.useEffect(() => {
        if (toolSubmitApi?.isSuccess) {
            setToggleModal({
                ...modal,
                isSuccessOpen: true,
            });
            //  setValidProvisionForm(resetValidOnboardingForm);
        }
    }, [toolSubmitApi?.isSuccess]);

    const handleSubmit = async () => {
        const payload = await getOrderPayload({ order });
        if (operation === 'edit') {
            const reSubmitPayload = { ...payload, 'order-id': cartItemId };
            editOrderApi.mutate(reSubmitPayload);
        } else {
            submitOrderApi.mutate(payload);
        }
    };

    const handleReset = () => {
        setShowPreview(null);
        setOrder(resetOrder);
        setValidProvisionForm(resetValidOnboardingForm);
        setToggleModal(resetToggleModal);
        setAvailableRepo(true);
    };

    const getButtonTitle = () => {
        let result = '';
        if (submitOrderApi?.isLoading || editOrderApi?.isLoading) {
            result = <CircularProgress color="inherit" size={16} />;
        } else if (operation !== 'edit') {
            result = 'Submit Request';
        } else result = 'Re-Submit Request';
        return result;
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} data-testid="preview-header">
            <Box sx={Style?.preview?.header?.previewDetailsTextWrap}>
                <Typography component="span" sx={Style?.preview?.header?.previewDetailsText}>
                    Provision Details (Preview)
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '10px' }}>
                {showPreview === 'pre-preview-order' && (
                    <Btn
                        size="medium"
                        onClick={() => setShowPreview(null)}
                        variant="outlined"
                        data-testid="preview-edit-btn"
                        // sx={Style?.preview?.editBtn}
                        sx={{ ...style.secondaryMediumButton, width: '184px' }}
                    >
                        Edit Order
                    </Btn>
                )}
                <Btn
                    size="medium"
                    onClick={() => handleSubmit()}
                    variant="contained"
                    sx={{ ...style.primaryMediumButton, width: '184px' }}
                    data-testid="preview-submit-btn"
                    // sx={Style?.preview?.editBtn}
                >
                    {getButtonTitle()}
                </Btn>
            </Box>
            <DialogBox
                handleNewOrderBtn={handleReset}
                handleHomeBtn={() => history.push('/')}
                operation={operation}
                redirectTo={`${process?.env?.REACT_APP_DEVX_LAUNCHPAD}my-journeys/${params?.journeyId}`}
            />
        </Box>
    );
};

export default PreviewHeader;
