import React, { lazy, Suspense } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    OrderPayload,
    isProjectIdValid,
    showPreviewOrder,
    previewDetails,
    isBusinessNameValid,
    isBudgetCodeValid,
    isAppShortNameValid,
    isAppShortNameExist,
    previewOrderError,
    isBusinessAppValid,
    isAppNameValid,
    isNonPrivilegedADGroupValid,
    isPrivilegedADGroupValid,
} from 'pages/reference-implementation/provisioning/RI002/store';
import { commonStyles as style } from 'assets/style';
import Btn from 'components/button';
import { getpreviewDetails } from 'pages/reference-implementation/provisioning/RI002/utils/getPayload';
import { isReadyToSubmit } from 'pages/reference-implementation/provisioning/RI002/utils/validation';
import PropTypes from 'prop-types';
import { customToast } from 'api/utils';

const ErrorBanner = lazy(() => import('pages/reference-implementation/components/error-banner'));

export const PreviewOrder = (props) => {
    const { isBgApiLoading } = props;
    const [isLoading, setLoading] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const projectIdValid = useRecoilValue(isProjectIdValid);
    const businessUnitValid = useRecoilValue(isBusinessNameValid);
    const budgetCodeValid = useRecoilValue(isBudgetCodeValid);
    const appShortNameVaild = useRecoilValue(isAppShortNameValid);
    const appShortNameExist = useRecoilValue(isAppShortNameExist);
    const businessAppValid = useRecoilValue(isBusinessAppValid);
    const appNameValid = useRecoilValue(isAppNameValid);
    const nonPrivilegedADGroupValid = useRecoilValue(isNonPrivilegedADGroupValid);
    const privilegedADGroupValid = useRecoilValue(isPrivilegedADGroupValid);
    const [errorBannerOpen, setErrorBannerOpen] = React.useState(false);
    const [, setShowPreview] = useRecoilState(showPreviewOrder);
    const [, setPreviewDetails] = useRecoilState(previewDetails);
    const [previewError, setPreviewError] = useRecoilState(previewOrderError);

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };

    const handleValidation = () => {
        document.getElementById('applayoutbox').scrollTop = 0;
        setLoading(true);
        if (
            !isReadyToSubmit({
                order,
                businessAppValid,
                projectIdValid,
                businessUnitValid,
                budgetCodeValid,
                appNameValid,
                appShortNameVaild,
                appShortNameExist,
                nonPrivilegedADGroupValid,
                privilegedADGroupValid,
            }) &&
            order?.environmentDetails
        ) {
            setPreviewDetails(getpreviewDetails({ order }));
            setShowPreview('pre-preview-order');
            setLoading(false);
        } else if (
            !isReadyToSubmit({
                order,
                businessAppValid,
                projectIdValid,
                businessUnitValid,
                budgetCodeValid,
                appNameValid,
                appShortNameVaild,
                appShortNameExist,
                nonPrivilegedADGroupValid,
                privilegedADGroupValid,
            }) &&
            !order?.environmentDetails
        ) {
            setOrder({ ...order, isTouched: false });
            setPreviewError({ ...previewError, isPreviewError: true });
            customToast(previewError?.message);
            setLoading(false);
        } else {
            setOrder({ ...order, isTouched: true });
            setPreviewError({ ...previewError, isPreviewError: false, message: '' });
            handleClickErrorBanner();
            setLoading(false);
        }
    };
    return (
        <Suspense
            fallback={
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 100px)" width="100%">
                    <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    <Typography variant="body2" sx={{ color: '#000000' }}>
                        Loading
                    </Typography>
                </Box>
            }
        >
            {' '}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Btn
                    size="medium"
                    onClick={() => handleValidation()}
                    variant="outlined"
                    disabled={isLoading || isBgApiLoading}
                    sx={style.secondaryButton}
                    data-testid="add-to-cart-btn"
                >
                    {isLoading && !isBgApiLoading ? <CircularProgress color="inherit" size={16} /> : 'Preview Order'}
                </Btn>
            </Box>
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click preview."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </Suspense>
    );
};

PreviewOrder.propTypes = {
    isBgApiLoading: PropTypes.bool,
};

PreviewOrder.defaultProps = {
    isBgApiLoading: false,
};

export default React.memo(PreviewOrder);
