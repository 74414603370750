/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, IconButton, InputBase, Typography } from '@mui/material';
import Select from 'components/select';
// import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { Link, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AppDefaultList from 'pages/app-profiles/AppDefaultList';
import ConfirmModal from 'pages/app-profiles/ConfirmModal';
import CreateAppDefault from 'pages/app-profiles/CreateAppDefault';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { toggleCreateAppDefault } from './store';

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderWidth: '0px',
        },
        '&:hover fieldset': {
            borderWidth: '0px',
        },
        '&.Mui-focused fieldset': {
            borderWidth: '0px',
        },
    },
});

const AppDefaultPage = () => {
    const [adModal, setADModal] = useRecoilState(toggleCreateAppDefault);
    const { profileType } = useParams();
    const [searchText, setSearchText] = React.useState('');
    const dummySelect = [
        { name: 'Azure', options: [{ label: 'Azure', value: 'Azure' }] },
        { name: 'Application', options: [{ label: 'App', value: 'App' }] },
        { name: 'Cloud Service', options: [{ label: 'Cloud', value: 'Cloud' }] },
    ];
    return (
        <>
            <Helmet>
                <title>App Defaults - DevX Infra</title>
            </Helmet>
            <CreateAppDefault />
            <ConfirmModal />
            <Box
                sx={{
                    background: 'linear-gradient(#3395d7, #205f89)',
                    color: '#ffffff',
                }}
                overflow="hidden"
                flexDirection="column"
                display="flex"
                width="100%"
                height="100%"
                px={3}
                pt={2}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', marginLeft: '-15px' }}>
                        <IconButton component={Link} to="/app-details/1">
                            <ArrowBackIosNewIcon sx={{ color: '#FFFFFF' }} />
                        </IconButton>
                        App Defaults - Pepcom
                    </Typography>
                </Box>
                {profileType === undefined && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex' }}>
                            {dummySelect.map((value) => (
                                <Box pr={2}>
                                    <Select
                                        key={`${value?.name}Key`}
                                        value={null}
                                        options={value?.options}
                                        handleOnSelect={() => {}}
                                        sx={{ width: '150px', borderColor: 'none' }}
                                        renderInput={(params) => (
                                            <CssTextField
                                                {...params}
                                                label={null}
                                                placeholder={value?.name}
                                                sx={{ backgroundColor: '#2B3A57', borderRadius: '5px' }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    sx: { color: '#FFFFFF' },
                                                    endAdornment: (
                                                        <>
                                                            {/* {skuTypesApi?.isLoading && <CircularProgress color="inherit" size={16} />} */}
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            ))}
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: 300, background: '#2B3A57', borderRadius: '2px' }}>
                                <InputBase
                                    sx={{ ml: 1, mt: 1, flex: 1, background: '#2B3A57', color: '#fff' }}
                                    size="small"
                                    fullWidth
                                    placeholder="Search App Name / Business CI"
                                    onChange={(e) => setSearchText(e.target.value)}
                                    value={searchText}
                                />
                                {searchText && (
                                    <IconButton
                                        aria-label="search"
                                        sx={{ color: '#fff' }}
                                        onClick={() => {
                                            setSearchText('');
                                            // setSkuType({ ...skyType, key: '', pageNumber: 1 });
                                        }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                                <IconButton
                                    aria-label="Search App Name / Business CI"
                                    sx={{ color: searchText ? '#fff' : '#A5A5A5' }}
                                    // onClick={() => setSkuType({ ...skyType, key: searchText, pageNumber: 1 })}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Box>
                            <Box pl={2} sx={{ height: '100%' }}>
                                <Box
                                    sx={{
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '35px',
                                        height: '35px',
                                        background: '#0047BA',
                                        marginRight: '8px',
                                    }}
                                    onClick={() => setADModal({ ...adModal, isOpen: true, type: 'create' })}
                                >
                                    <AddIcon
                                        style={{
                                            fontSize: '25px',
                                            color: '#FFFFFF',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
                <AppDefaultList />
            </Box>
        </>
    );
};

export default AppDefaultPage;
