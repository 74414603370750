import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import InputForm from 'pages/provisioning/virtual-service/InputForm';
import {
    orderPayload,
    showPreviewOrder,
    toggleModal,
    validVSForm,
    isDivisionRequired,
    isAppRegionRequired,
    isClusterLoading,
    isNamespaceLoading,
    isDisplayProgram,
    validationStatus,
    validationErrorReport,
    maxVSErrorMessage,
    isDuplicateCustomDomainName,
    costEstimateData,
    certCreationRequired,
    isValidOwners,
    defaultVSUpsertType,
    updateValidationError,
    isCostLoading,
    isValidCostCenter,
} from 'pages/provisioning/virtual-service/store';
import PreviewDetails from 'pages/provisioning/virtual-service/components/preview';
import { resetOrder, resetToggleModal, resetVSForm, resetValidOwners } from 'pages/provisioning/virtual-service/utils/resetFunction';
import { formOrderObjFromOrderDetails } from 'pages/provisioning/virtual-service/utils/getFunction';
import Styles from 'pages/provisioning/_components/style';
import { Box, CircularProgress, Typography } from '@mui/material';
import StickyHeader from 'pages/provisioning/virtual-service/components/sticky-header';
import Alert from 'components/alert';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { catalogs } from 'store';
import { virtualServiceTypes } from 'pages/provisioning/virtual-service/utils';

const ShowCostDetails = React.lazy(() => import('pages/provisioning/virtual-service/components/ShowCostDetails'));

const VirtualService = () => {
    const { catalogCode, cartItemId, operation } = useParams();
    const location = useLocation();

    const [cmpCatalogs] = useRecoilState(catalogs);
    const [catalog, setCatalog] = useState(undefined);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [, setToggleModal] = useRecoilState(toggleModal);
    const [, setVsForm] = useRecoilState(validVSForm);
    const [, setDivisionReq] = useRecoilState(isDivisionRequired);
    const [, setAppRegionReq] = useRecoilState(isAppRegionRequired);
    const [, setClusterLoading] = useRecoilState(isClusterLoading);
    const [, setNamespaceLoading] = useRecoilState(isNamespaceLoading);
    const [, setDisplayProgram] = useRecoilState(isDisplayProgram);
    const [showPreview, setShowPreview] = useRecoilState(showPreviewOrder);
    const [, setValidationStatus] = useRecoilState(validationStatus);
    const [, setValidationErrorReport] = useRecoilState(validationErrorReport);
    const [, setError] = useRecoilState(maxVSErrorMessage);
    const [, setIsDuplicate] = useRecoilState(isDuplicateCustomDomainName);
    const [, setCostData] = useRecoilState(costEstimateData);
    const [isRequired, setIsRequired] = useRecoilState(certCreationRequired);
    const [, setValidOwners] = useRecoilState(isValidOwners);
    const [, setVsAdditionalDetailUpsertType] = useRecoilState(defaultVSUpsertType);
    const [, setUpdateError] = useRecoilState(updateValidationError);
    const [, setCostLoading] = useRecoilState(isCostLoading);
    const [, setValidCostCenter] = useRecoilState(isValidCostCenter);
    const orderDetailsApi = useMutation(() => axios.get(`${endPoints?.virtualService?.orderDetails}/${cartItemId}`));
    const costDetailsApi = useMutation((payload) => axios.post(endPoints.virtualService.costDetails, payload));

    const handleReset = () => {
        setOrder(resetOrder);
        setToggleModal(resetToggleModal);
        setVsForm(resetVSForm);
        setShowPreview(false);
        setDivisionReq(false);
        setAppRegionReq(false);
        setClusterLoading(false);
        setNamespaceLoading(false);
        setDisplayProgram(false);
        setValidationStatus(null);
        setValidationErrorReport([]);
        setError(null);
        setIsRequired(false);
        setIsDuplicate(false);
        setCostData(null);
        setValidOwners(resetValidOwners);
        setVsAdditionalDetailUpsertType('');
        setUpdateError(false);
        setCostLoading(false);
        setValidCostCenter(true);
    };
    const callCostApi = (orderObj) => {
        let costObject = [];
        const costPayload = {
            catalogServiceCode: catalogCode,
            quantity: 1,
            catalogConfig: {
                aksVirtualService: [],
            },
        };
        if (
            orderObj?.aksVirtualServiceRequestType?.toLowerCase() === 'create' &&
            orderObj?.aksVirtualService?.length > 0 &&
            orderObj?.aksVirtualService?.[0]?.virtualServiceType !== null
        ) {
            orderObj?.aksVirtualService.forEach((data) => {
                costObject.push({
                    virtualServiceType: virtualServiceTypes?.filter((item) => item.name === data?.virtualServiceType)[0]?.code,
                    virtualServiceName: data?.virtualServiceName,
                    customDomain: [
                        {
                            name: data?.customDomain?.[0]?.name,
                        },
                    ],
                });
            });
            costPayload.catalogConfig.aksVirtualService = costObject;
            costDetailsApi?.mutate(costPayload);
        } else if (orderObj?.aksVirtualServiceRequestType?.toLowerCase() === 'update' && orderObj?.aksVirtualServiceUpdateCustomDomain?.length > 0) {
            costObject = orderObj?.aksVirtualServiceUpdateCustomDomain.map((item) => ({
                virtualServiceType: 'UPDATE_VS_CUSTOM_DOMAIN',
                customDomain: [
                    {
                        name: item?.name || null,
                    },
                ],
            }));
            costPayload.catalogConfig.aksVirtualService = costObject;
            costDetailsApi?.mutate(costPayload);
        }
    };

    React.useEffect(() => {
        if (costDetailsApi?.isSuccess) {
            setCostLoading(false);
            setCostData({ ...costDetailsApi?.data?.data, ...(!costDetailsApi?.data?.data?.qty && { qty: 1 }) });
        }
    }, [costDetailsApi?.isSuccess]);

    React.useEffect(() => {
        if (costDetailsApi?.isLoading) {
            setCostLoading(true);
        }
    }, [costDetailsApi?.isLoading]);

    async function checkCatalog() {
        try {
            if (operation === 'edit') {
                const { data: orderDetails } = await orderDetailsApi.mutateAsync();
                const orderObj = formOrderObjFromOrderDetails(orderDetails);
                callCostApi(orderObj);
                setOrder((previous) => ({
                    ...previous,
                    ...orderObj,
                }));
                if (orderDetails?.manager?.id?.toLowerCase() !== 'system') {
                    setIsRequired(true);
                } else setIsRequired(false);

                if (location?.state && location?.state === 'Validation failed') {
                    setValidationStatus(orderDetails?.validationDetails?.validationFailedDetail);
                } else {
                    setValidationStatus(null);
                }
            } else {
                setOrder((previous) => ({
                    ...previous,
                    isTouched: false,
                }));
            }
        } catch (err) {
            // setLoading(false);
        }
    }

    React.useEffect(() => {
        if (catalogCode) {
            handleReset();
            checkCatalog();
        }
    }, [operation]);
    React.useEffect(() => {
        if (cmpCatalogs) {
            const data = cmpCatalogs?.find((item) => item?.catalogDefinitionId === catalogCode);
            setCatalog(data);
        }
    }, [cmpCatalogs]);

    React.useEffect(() => {
        return () => handleReset();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        handleReset();
    }, [operation, cartItemId]);

    return (
        <>
            <Helmet>
                <title>Virtual Service Provisioning</title>
            </Helmet>
            <React.Suspense
                fallback={
                    <Box sx={Styles?.page?.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={Styles?.page?.circularProgressColor} />
                        <Typography variant="body2" sx={Styles?.page?.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                }
            >
                {catalog && <StickyHeader title={catalog?.displayName} iconPath={catalog?.iconPath} handleBackBtn={() => {}} />}
                <Box sx={Styles?.page?.provisioningBox} data-testid="index-form">
                    <Box sx={Styles?.page?.sspform_wrapper}>
                        <Box sx={{ display: !showPreview ? 'block' : 'none' }}>
                            {isRequired && (!order?.applicationOwner || !order?.certificateOwnerOne || !order?.certificateOwnerTwo || !order?.costCenter) && (
                                <Box sx={{ marginTop: '10px' }}>
                                    <Alert
                                        title={null}
                                        message="Please enter Application Owner, Certificate Owner One, Certificate Owner Two and Budget Code as certificate creation is required for this order."
                                        Icon={<InfoImg />}
                                        // borderLeftColor="#0047BA"
                                        borderLeftColor="#DFE6F2"
                                        backgroundColor="#DFE6F2"
                                        color="#000000"
                                        ftSize="14px"
                                    />
                                </Box>
                            )}
                            <InputForm />
                        </Box>
                        <Box sx={{ display: showPreview ? 'block' : 'none' }}>{showPreview && <PreviewDetails />}</Box>
                    </Box>
                </Box>
                <ShowCostDetails />
            </React.Suspense>
        </>
    );
};

export default VirtualService;
