import React from 'react';
import { useParams } from 'react-router-dom';
import { useAxios } from 'hooks';
import { endPoints } from 'api/endpoints';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import ShowCostDetails from 'pages/provisioning/couchbase/ShowCostDetails';
import { catalogs } from 'store';
import { environmentDetails, isMandatoryTagsFullfilled, orderId, sspSubmitOrder, toggleSspModels } from 'pages/provisioning/store/couchbase';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import ProvisioningHeader from 'pages/provisioning/couchbase/ProvisioningHeader';
import ProvisioningForm from 'pages/provisioning/couchbase/ProivisioningForm';
import ApplicationDetails from 'pages/provisioning/couchbase/ApplicationDetails';
import AddedToCartConfirmModal from 'pages/provisioning/couchbase/AddedToCartConfirmModal';
import UpdatedToCartConfirmModal from 'pages/provisioning/couchbase/UpdatedToCartConfirmModal';
import Tags from 'pages/provisioning/couchbase/Tags';
import { formOrderObjFromCart, formOrderObjFromOrderDetails, resetEnvironmentDetails, resetOrder, resetToggleSspModal } from './utils';

const CouchbaseProvisioning = () => {
    const [isLoading, setLoading] = React.useState(true);
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, resetOrderId] = useRecoilState(orderId);
    const [, resetEnvDetails] = useRecoilState(environmentDetails);
    const [, resetIsMandatoryTagsFullfilled] = useRecoilState(isMandatoryTagsFullfilled);
    const [, resetToggleSspModels] = useRecoilState(toggleSspModels);
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const axios = useAxios();

    async function getCosts() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}/costs`);
        return data;
    }

    async function checkCatalog() {
        const data = cmpCatalogs?.find((item) => item?.catalogDefinitionId === catalogCode);
        const costs = await getCosts();

        if (cartItemId && operation && !catalogServiceId) {
            const { data: cartItem } = await axios.get(`${endPoints.cart.getCartItem}/${cartItemId}`);
            const orderObj = await formOrderObjFromCart(cartItem, data?.images?.sku);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                sku: data?.images?.sku,
                emptyCost: costs,
            }));
        }
        if (cartItemId && operation && catalogServiceId) {
            const { data: orderDetails } = await axios.get(`${endPoints.order.orderDetails}/${cartItemId}`);
            const orderObj = await formOrderObjFromOrderDetails(catalogServiceId, orderDetails, data?.images?.sku);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                sku: data?.images?.sku,
            }));
        } else {
            setOrder((previous) => ({
                ...previous,
                sku: data?.images?.sku,
                catalogCode,
                catalogType: data?.displayName,
                cloudProvider: data?.cloudProvider,
                cloudService: data?.cloudService,
                validationStatus: 'clearValidation',
                isTouched: false,
                emptyCost: costs,
            }));
        }
        setLoading(false);
    }
    const reset = () => {
        setOrder({ ...order, ...resetOrder });
        resetOrderId('');
        resetEnvDetails(resetEnvironmentDetails);
        resetIsMandatoryTagsFullfilled(false);
        resetToggleSspModels(resetToggleSspModal);
    };
    React.useEffect(() => {
        if (cmpCatalogs?.length && catalogCode) {
            setLoading(true);
            reset();
            checkCatalog();
        }
    }, [cmpCatalogs, catalogCode]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => reset();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Couchbase Provisioning - DevX Infra</title>
            </Helmet>
            {isLoading && (
                <Box sx={ProvisioningStyles.provisioning_wrapper}>
                    <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                    <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                        Loading....
                    </Typography>
                </Box>
            )}
            {!isLoading && (
                <Box sx={ProvisioningStyles.provisioningBox}>
                    <ProvisioningHeader />
                    <Box sx={ProvisioningStyles.sspform_wrapper}>
                        <ProvisioningForm />
                    </Box>
                    <br />
                    <ApplicationDetails />
                    <AddedToCartConfirmModal />
                    <UpdatedToCartConfirmModal />
                    <ShowCostDetails />
                    <Tags />
                </Box>
            )}
        </>
    );
};

export default CouchbaseProvisioning;
