import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import throttle from 'lodash/throttle';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MultiSelect from 'components/multiSelect';
import { endPoints } from 'api/endpoints';
import { useAxios } from 'hooks';
import { useMutation } from 'react-query';
import Popper from '@mui/material/Popper';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Badge, Button, Paper, Box, Stack, ClickAwayListener, Grid } from '@mui/material';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { cmpSectors, catalogs, riCatalogs } from 'store';
import styles, { ColorButton, CssTextField } from 'assets/style/styles';
import filterIcon from 'assets/images/filter-icon.svg';
import { dashboardFilter } from './store';

const filterDefaultObj = {
    provider: null,
    services: null,
    sector: null,
    regions: null,
    environment: null,
    solutions: null,
    ApplicationService: null,
};

const DashboardFilter = () => {
    const axios = useAxios();
    const filteroption = createFilterOptions();
    const [catalogServices] = useRecoilState(catalogs);
    const [risolutions] = useRecoilState(riCatalogs);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [dashboardFilterObj, setDashboardFilterObj] = useRecoilState(dashboardFilter);
    const [filters, setFilter] = React.useState(filterDefaultObj);
    const [provider, setProvider] = React.useState([]);
    const [allCmpCatalogs, setAllCmpCatalogs] = React.useState([]);
    const [cmpcatalogs, setCmpCatalogs] = React.useState([]);
    const [cmpSolutions, setCmpSolutions] = React.useState([]);
    const [envOptions, setEnvoptions] = React.useState([]);
    const [appvalue, setAppValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [initOptions, setInitOptions] = React.useState([]);
    const sector = useRecoilValue(cmpSectors);
    const [ApplicationsServices, setApplicationServices] = React.useState([]);
    const [region, setRegion] = React.useState([]);
    const getProviders = useMutation(() => axios.get(endPoints.landingZones.cloudProvider));
    const getService = useMutation(() => axios.get(endPoints.ssp.dashboard.services));
    const environmentApi = useMutation(() => axios.get(endPoints.landingZones.environments));
    const bCIGetApi = useMutation(() => axios.get(`${endPoints.appProfile.businessCI}/${ccInputValue?.toUpperCase()}`));
    const bCIPostApi = useMutation((payload) => axios.post(`${endPoints.appProfile.businessCI}`, payload));
    const getRegion = useMutation(() => axios.get(endPoints.landingZones.regions));

    React.useEffect(() => {
        if (getProviders?.isSuccess) {
            setProvider(getProviders?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [getProviders?.isSuccess]);

    React.useEffect(() => {
        if (getRegion?.isSuccess) {
            setRegion(getRegion?.data?.data?.map((app) => ({ label: app.displayName, value: app.displayName, data: app })));
        }
    }, [getRegion?.isSuccess]);
    React.useEffect(() => {
        if (environmentApi?.isSuccess) {
            setEnvoptions(environmentApi?.data?.data?.map((app) => ({ label: app?.name, value: app?.name, data: app })));
        }
    }, [environmentApi?.isSuccess]);

    React.useEffect(() => {
        setCmpCatalogs(
            catalogServices?.map((app) => ({
                value: app?.displayName || app?.name,
                label: app?.catalogDefinitionId ? app?.catalogDefinitionId : app?.['reference-implementation-id'],
                data: app,
            }))
        );
        setAllCmpCatalogs(
            catalogServices?.map((app) => ({
                value: app?.displayName || app?.name,
                label: app?.catalogDefinitionId ? app?.catalogDefinitionId : app?.['reference-implementation-id'],
                data: app,
            }))
        );
        setCmpSolutions(
            risolutions?.map((app) => ({
                value: app?.displayName || app?.name,
                label: app?.catalogDefinitionId ? app?.catalogDefinitionId : app?.['reference-implementation-id'],
                data: app,
            }))
        );
    }, [catalogServices, risolutions]);
    React.useEffect(() => {
        getProviders.mutate();
        getService.mutate();
        environmentApi.mutate();
        getRegion.mutate();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(!open);
        setFilter(dashboardFilterObj);
    };

    const handleClickAway = () => {
        setFilter(dashboardFilterObj);
        setAnchorEl(null);
        setOpen(false);
    };

    const id = open ? 'simple-popper' : undefined;

    const handleOnSelect = (name, selected) => {
        if (name === 'provider') {
            setFilter({ provider: selected, service: null, sector: null, region: null, environment: null, ApplicationService: null, solutions: null });
        } else {
            setFilter({ ...filters, [name]: selected });
        }
    };

    React.useEffect(() => {
        if (!dashboardFilterObj) {
            setFilter(filterDefaultObj());
        }
        if (dashboardFilterObj) {
            setFilter(dashboardFilterObj);
        }
    }, [dashboardFilterObj]);

    const handleSubmitFilter = () => {
        const appliedFilters = {};
        if (filters) {
            Object.keys(filters)?.forEach((filter) => {
                if (filters[filter]) {
                    appliedFilters[filter] = filters[filter]?.value || filters[filter];
                }
            });
        }
        setDashboardFilterObj(appliedFilters);
        handleClick();
    };
    const getSelectedValue = (selectedServices, allValues, filterkey) => {
        let res = [];
        if (filterkey === 'regions') {
            res = selectedServices && selectedServices?.map((service) => allValues.filter((opt) => opt?.data?.name === service));
        } else {
            res = selectedServices && selectedServices?.map((service) => allValues.filter((opt) => opt.label === service));
        }
        return res?.length ? res.reduce((acc, val) => acc.concat(val), []) : [];
    };

    const sendSelectedValues = (selectedServices, filterKey) => {
        let res = [];
        if (filterKey !== 'regions') {
            res = selectedServices.map((service) => (service?.label ? service?.label : service));
        } else {
            res = selectedServices.map((service) => (service?.data ? service?.data?.name : service));
        }
        handleOnSelect(filterKey, res);
    };
    React.useEffect(() => {
        if (filters?.provider?.length > 0) {
            const res = filters?.provider?.map((providerItem) => allCmpCatalogs?.filter((val) => val?.data?.cloudProvider === providerItem).map((app) => app));
            setCmpCatalogs(res?.length ? res.reduce((acc, val) => acc.concat(val), []) : []);
        }
    }, [filters?.provider]);

    //     applicationService

    const resetOptions = () => {
        setApplicationServices(initOptions);
        // setHCode(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                bCIGetApi.mutate();
            }, 1000),
        []
    );
    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue !== '' && ccInputValue.length > 2) fetch();
    }, [ccInputValue, fetch]);
    React.useEffect(() => {
        if (bCIGetApi?.isSuccess) {
            if (bCIGetApi?.data?.data?.applications) {
                const combinedValue = bCIGetApi?.data?.data?.applications.map((app) => ({
                    label: `${app.ciName}`,
                    drRecoveryPriority: app?.drRecoveryPriority,
                }));
                setApplicationServices(combinedValue);
            }
        }
    }, [bCIGetApi?.isSuccess]);
    React.useEffect(() => {
        if (bCIPostApi?.isSuccess) {
            if (bCIPostApi?.data?.data?.applications) {
                const combinedValue = bCIPostApi?.data?.data?.applications.map((app) => ({
                    label: `${app.ciName}`,
                    drRecoveryPriority: app?.drRecoveryPriority,
                }));
                // if (form?.businessCIDetails) combinedValue.push(form?.businessCIDetails);
                setApplicationServices(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [bCIPostApi?.isSuccess]);
    React.useEffect(() => {
        bCIPostApi.mutate({
            pageOffset: 0,
            pageLimit: 20,
        });
    }, []);
    React.useEffect(() => {
        if (appvalue === null && initOptions?.length) {
            resetOptions();
        } else if (appvalue) {
            handleOnSelect('ApplicationService', appvalue);
        }
    }, [appvalue]);
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <Button
                    sx={styles.filterButton}
                    startIcon={
                        <Badge
                            variant="dot"
                            overlap="circular"
                            color={filters && Object.keys(filters)?.filter((key) => filters[key] !== null && filters[key] !== '')?.length ? 'error' : 'default'}
                        >
                            {/* <FilterAltIcon color="#0047BA" size={36} /> */}
                            <img src={filterIcon} alt="filter icon" />
                        </Badge>
                    }
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    onClick={handleClick}
                    data-testid="dashboardfilterbtn"
                >
                    Filter
                </Button>

                <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: '999' }}>
                    <Paper elevation={5} sx={{ ...styles.orderFilter.wrapper, width: '560px' }}>
                        <ArrowDropUpIcon sx={{ ...styles.orderFilter.arrow, right: '104px', left: 'auto' }} />
                        <Box sx={styles.orderFilter.popper.container}>
                            <CloseIcon
                                fontSize="small"
                                sx={styles.orderFilter.closeIcon}
                                onClick={() => {
                                    setFilter(filterDefaultObj);
                                    setDashboardFilterObj(filterDefaultObj);
                                    handleClick();
                                }}
                                data-testid="closeIcon"
                            />
                        </Box>
                        <Stack spacing={2} mb={2} pt={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <MultiSelect
                                        key="provider"
                                        data-testid="catalog-provider"
                                        value={getSelectedValue(filters?.provider, provider, 'provider')}
                                        options={provider}
                                        // sx={orderStyle?.orderFilter?.seletedFieldlabel}
                                        label="Select Providers"
                                        handleOnSelect={(value) => sendSelectedValues(value, 'provider')}
                                        filterOptions={(options, state) =>
                                            options.filter((element) => element?.value?.toLowerCase().includes(state?.inputValue?.toLowerCase()))
                                        }
                                        renderTags={(val) => (
                                            <Box sx={{ marginLeft: '10px' }} data-testid="catalog-service-text">{`${val?.length} Selected`}</Box>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MultiSelect
                                        key="catlogservice"
                                        data-testid="catalog-service"
                                        value={getSelectedValue(filters?.services, cmpcatalogs, 'services')}
                                        options={cmpcatalogs}
                                        // sx={orderStyle?.orderFilter?.seletedFieldlabel}
                                        label="Select Services"
                                        handleOnSelect={(value) => sendSelectedValues(value, 'services')}
                                        filterOptions={(options, state) =>
                                            options.filter((element) => element?.value?.toLowerCase().includes(state?.inputValue?.toLowerCase()))
                                        }
                                        renderTags={(val) => (
                                            <Box sx={{ marginLeft: '10px' }} data-testid="catalog-service-text">{`${val?.length} Selected`}</Box>
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <MultiSelect
                                        key="regionName"
                                        data-testid="regionName"
                                        value={getSelectedValue(filters?.regions, region, 'regions')}
                                        options={region}
                                        // sx={orderStyle?.orderFilter?.seletedFieldlabel}
                                        label="Select Regions"
                                        handleOnSelect={(value) => sendSelectedValues(value, 'regions')}
                                        filterOptions={(options, state) =>
                                            options.filter((element) => element?.value?.toLowerCase().includes(state?.inputValue?.toLowerCase()))
                                        }
                                        renderTags={(val) => (
                                            <Box sx={{ marginLeft: '10px' }} data-testid="catalog-service-text">{`${val?.length} Selected`}</Box>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MultiSelect
                                        key="sectorName"
                                        data-testid="sectorName"
                                        value={getSelectedValue(filters?.sector, sector, 'sector')}
                                        options={sector}
                                        // sx={orderStyle?.orderFilter?.seletedFieldlabel}
                                        label="Select Sectors"
                                        handleOnSelect={(value) => sendSelectedValues(value, 'sector')}
                                        filterOptions={(options, state) =>
                                            options.filter((element) => element?.value?.toLowerCase().includes(state?.inputValue?.toLowerCase()))
                                        }
                                        renderTags={(val) => (
                                            <Box sx={{ marginLeft: '10px' }} data-testid="catalog-service-text">{`${val?.length} Selected`}</Box>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {' '}
                                    <MultiSelect
                                        key="environment"
                                        data-testid="environmentName"
                                        value={getSelectedValue(filters?.environment, envOptions, 'environment')}
                                        options={envOptions}
                                        // sx={orderStyle?.orderFilter?.seletedFieldlabel}
                                        label="Select Environments"
                                        handleOnSelect={(value) => sendSelectedValues(value, 'environment')}
                                        filterOptions={(options, state) =>
                                            options.filter((element) => element?.value?.toLowerCase().includes(state?.inputValue?.toLowerCase()))
                                        }
                                        renderTags={(val) => (
                                            <Box sx={{ marginLeft: '10px' }} data-testid="catalog-service-text">{`${val?.length} Selected`}</Box>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MultiSelect
                                        key="solutions"
                                        data-testid="solutionsName"
                                        value={getSelectedValue(filters?.solutions, cmpSolutions, 'solutions')}
                                        options={cmpSolutions}
                                        // sx={orderStyle?.orderFilter?.seletedFieldlabel}
                                        label="Select Solutions"
                                        handleOnSelect={(value) => sendSelectedValues(value, 'solutions')}
                                        filterOptions={(options, state) =>
                                            options.filter((element) => element?.value?.toLowerCase().includes(state?.inputValue?.toLowerCase()))
                                        }
                                        renderTags={(val) => (
                                            <Box sx={{ marginLeft: '10px' }} data-testid="catalog-service-text">{`${val?.length} Selected`}</Box>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {' '}
                                    <Autocomplete
                                        key="businessCI"
                                        size="small"
                                        loading={bCIGetApi?.isLoading}
                                        value={filters?.ApplicationService || null}
                                        getOptionLabel={(option) => option.label}
                                        onInputChange={(event, newInputValue) => {
                                            setCCInputValue(newInputValue);
                                        }}
                                        onChange={(event, newValue) => {
                                            if (typeof newValue === 'string') {
                                                setAppValue({
                                                    label: newValue,
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                setAppValue({
                                                    label: newValue.inputValue,
                                                });
                                            } else {
                                                setAppValue(newValue);
                                            }
                                        }}
                                        filterOptions={(optionValues, params) => {
                                            const filtered = filteroption(optionValues, params);

                                            /*   const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = optionValues.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                label: `Add "${inputValue}"`,
                            });
                        }
*/
                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="free-solo-with-text-demo"
                                        options={ApplicationsServices}
                                        // sx={profileStyle.appWoner.select}
                                        freeSolo
                                        renderInput={(params) => (
                                            <CssTextField
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                label="Select Application Services"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: <>{params.InputProps.endAdornment}</>,
                                                }}
                                                fullWidth
                                            />
                                        )}
                                        data-testid="applicationName"
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                            <ColorButton disableElevation onClick={handleSubmitFilter} variant="contained" data-testid="applyfilterBtn" disableRipple>
                                Apply
                            </ColorButton>
                        </Stack>
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default DashboardFilter;
