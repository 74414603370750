import React from 'react';
import { Grid } from '@mui/material';
import FormCard from 'pages/reference-implementation/components/form-card';
import Form from 'pages/reference-implementation/provisioning/RI004O/components/Form';
import Heading from 'pages/reference-implementation/components/Heading';
import { isShowAppRegionField } from 'pages/reference-implementation/provisioning/RI004O/utils';
import { useRecoilValue } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI004O/store';

const ApplicationSection = () => {
    const order = useRecoilValue(OrderPayload);
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Heading title="Application" />
                </Grid>
            </Grid>
            <FormCard>
                <Grid container spacing={4}>
                    {/* Project section start */}
                    <Grid item xs={12} sm={6}>
                        <Form.Application />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.ApplicationName />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.ApplicationShortName />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.AppOwnerSearch />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.Sector />
                    </Grid>
                    {order?.sector && ['cgf']?.includes(order?.sector?.toLowerCase()) && (
                        <Grid item xs={12} sm={6}>
                            <Form.Division />
                        </Grid>
                    )}
                    {isShowAppRegionField({ order }) && (
                        <Grid item xs={12} sm={6}>
                            <Form.ApplicationRegion />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <Form.Environments />
                    </Grid>
                    {['production']?.includes(order?.environment?.toLowerCase()) && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <Form.ITEApplicationService />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.ISANumber />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={6}>
                        <Form.BudgetCode />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.BusinessContact />
                    </Grid>
                    {/* Project section end */}
                    {/* <Form.Program /> */}
                    <Grid item xs={12} sm={6}>
                        <Form.DataClassification />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.SiteName />
                    </Grid>
                </Grid>
            </FormCard>
        </>
    );
};

export default ApplicationSection;
