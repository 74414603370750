import React from 'react';
import { Stack, FormControl, Box, FormControlLabel, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI003O/store';
import { RIStyles, CustomRadioGroup, CustomRadio } from 'pages/reference-implementation/Style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const BusinessAppServiceRadioBtn = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);

    const setBusinessAppServiceRadioBtn = (businessAppService) => {
        setOrder({
            ...order,
            businessAppService,
            businessApp: null,
            businessAppDetails: null,
            appService: null,
            appOwner: null,
            appOwnerDetails: null,
        });
    };
    const handleOnSelectChange = (selected) => {
        setBusinessAppServiceRadioBtn(selected);
    };
    return (
        <Stack direction="row" spacing={1} sx={RIStyles.stack}>
            <FormControl sx={RIStyles?.form?.radioLabel}>
                <Box sx={{ color: '#0047BA', fontSize: '12px', fontWeight: 'normal' }} id="performanceRadioGrp">
                    Do you have Business application & Application Service?
                </Box>
                <CustomRadioGroup
                    aria-label="BusinessAppServiceRadioBtn"
                    name="businessAppServiceRadioBtnGroup"
                    row
                    value={order?.businessAppService || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" />
                    <FormControlLabel
                        value="No"
                        sx={{
                            marginLeft: '60px',
                        }}
                        control={<CustomRadio />}
                        label="No"
                    />
                </CustomRadioGroup>
            </FormControl>
            <HelpImg
                title={
                    <>
                        <Box sx={RIStyles.adinfoStyle.wrapper}>
                            <Box sx={RIStyles.adinfoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        {
                                            'Please reachout DevX Infra tactical team(DL - Global CMP Operations <DL - Global DevX Operations>) to create Business application and Application Service'
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Stack>
    );
};

export default BusinessAppServiceRadioBtn;
