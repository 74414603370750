import React from 'react';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { endPoints } from 'api/endpoints';
import { useHistory } from 'react-router-dom';
import { newFooterStyles as footerStyles } from 'assets/style';
import footerImage from 'assets/images/Footer_logo.svg';

const AppFooter = () => {
    const history = useHistory();
    const windowFeatures = 'left=100,top=100,width=720,height=520';
    // const [showpopupmodal, setShowpopupModal] = React.useState(false);
    // React.useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://teams.microsoft.com/share/launcher.js';
    //     script.async = true;
    //     script.defer = true;
    //     document.body.appendChild(script);
    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, [showpopupmodal]);
    return (
        <Box data-testid="footer" sx={footerStyles.footer}>
            {/* <Typography component="span" sx={footerStyles.copytext}>
                Cloud Marketplace
                <Typography component="span" sx={footerStyles.copyRightText}>
                    &copy;
                </Typography>
                2023
            </Typography> */}
            <img src={footerImage} alt="filter icon" />
            <Stack direction="row" spacing={2}>
                <Typography component="span" sx={footerStyles.copytext}>
                    <Typography component="span" sx={footerStyles.copyRightText}>
                        &copy;2024
                    </Typography>
                    DevX Infra. All rights reserved.
                </Typography>
                <Button onClick={() => history.push(endPoints.general.help)} sx={footerStyles.copytext} component={Link} className="link">
                    Help
                </Button>
                <Box sx={footerStyles.rightBox}>
                    <Button href={endPoints.general.support} component={Link} className="link" sx={footerStyles.copytext}>
                        Support
                    </Button>
                </Box>
                <Button href={endPoints.general.feedback} target="_blank" component={Link} className="link" sx={footerStyles.copytext}>
                    FeedBack
                </Button>
                <Button
                    className="link"
                    sx={footerStyles.rightBox}
                    onClick={() =>
                        window.open(
                            'https://teams.microsoft.com/l/team/19%3ajs_07umN2OVdidqT9QLSJBMrYJLTU2IF1PniTY-VIm41%40thread.tacv2/conversations?groupId=08771b1c-ba5b-4590-8598-a85d629ad318&tenantId=42cc3295-cd0e-449c-b98e-5ce5b560c1d3',
                            'mozillaWindow',
                            windowFeatures
                        )
                    }
                >
                    Chat with us:&nbsp;
                    <Box as="img" src="/static/images/teams.png" width={30} height={30} />
                    {/* <Box
                        // className="teams-share-button"
                        // onClick={() => setShowpopupModal(!showpopupmodal)}
                        onClick={() =>
                            window.open(
                                'https://teams.microsoft.com/_#/conversations/General?threadId=19:JNuMhDpQMXx158Sure27fiUj2xkSCu5ZPsPj7WNcAm41@thread.tacv2&ctx=channel',
                                'mozillaWindow',
                                windowFeatures
                            )
                        }
                        // target="_blank"
                        // rel="noreferrer"
                        // href="https://teams.microsoft.com/_#/conversations/General?threadId=19:JNuMhDpQMXx158Sure27fiUj2xkSCu5ZPsPj7WNcAm41@thread.tacv2&ctx=channel"
                    >
                        Teams
                    </Box> */}
                </Button>
            </Stack>
        </Box>
    );
};

export default AppFooter;
