import { camelToTitle } from 'utils';
import { ApplicationGateWayURLCatalogDefinitionID, ExternalPublicLoadBalancerURLCatalogDefinitionID } from 'utils/catalogDefinitionId';

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;
    return {
        ...(data?.id && { id: data?.id }),
        securityScanCompleted: 'yes',
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: catalogConfig?.budgetCode,
            projectName: entityDetails?.project,
            // billingEntity: entityDetails?.billingEntity,
            sector: catalogConfig?.sector,
        },
        sector: catalogConfig?.sector || null,
        environment: catalogConfig?.environment || null,
        applicationName: catalogConfig?.applicationName,
        region: catalogConfig?.region || null,
        program: null,
        programDetails: { value: catalogConfig?.program },
        dataClassification: catalogConfig?.dataClassification,
        loadBalancerUrl: catalogConfig?.loadBalancerUrl,
        loadBalancerFrontEndPort: catalogConfig?.loadBalancerFrontEndPort,
        regionDisplayname: catalogConfig?.region,
        backendFQDN: catalogConfig?.backendFqdn,
        appOwner: data?.manager?.id,
        appUrl: catalogConfig?.applicationUrl,
        backEndHealthProbe: catalogConfig?.backendHealthProbePath,
        ...(catalogConfig?.isaNumber && {
            isaNumber: catalogConfig?.isaNumber,
            isaValidation: true,
        }),
        ...(catalogConfig?.scanNumber && {
            scanNumber: catalogConfig?.scanNumber,
            scanValidation: true,
        }),
        sslEncryption: catalogConfig?.sslEncryption ? 'yes' : 'no',
        supportBackendCertificate: catalogConfig?.supportBackendCertificate,
        hCode: catalogConfig?.budgetCode || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        environmentDetails,
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        adGroup: catalogConfig?.adGroup,
        appSupportMailId: catalogConfig?.appSupportMailId,
        // gtmLoadBalancer: catalogConfig?.gtmLoadBalancerIpAddress,
        certificateOwner1: null,
        certificateOwner1Details: { gpid: catalogConfig?.certificateOwnerOne },
        certificateOwner2: null,
        certificateOwner2Details: { gpid: catalogConfig?.certificateOwnerTwo },
        isaValidation: true,
        isaValidationDetails: null,
        provisioning: true,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formUploadObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;

    return (
        catalogConfig?.filesUploadResponse?.uploadResponses?.map((item) => ({
            id: item?.id,
            name: item?.fileName,
            blobRelativeLink: item?.blobRelativeLink,
            size: item?.fileSize || 0,
        })) || []
    );
};
/**
 * Extracts the environment value from a given App URL if it matches the pattern.
 * @param {string} url - The App URL to extract the environment from.
 * @returns {string|null} - The extracted environment value or null if not found.
 */
export const extractEnvironment = (url) => {
    const urlPattern = /^[a-z0-9-]{3,20}\.(dev|qa|ite|preprod)?\.?(mypepsico|pepsico)\.com$/;
    const match = url.match(urlPattern);
    return match ? match[1] : null;
};
/**
 * Returns the environment keys that match the given environment value.
 * @param {string} env - The environment value to check.
 * @returns {string[]} - The matching environment keys.
 */
export const getMatchingEnvironments = (env) => {
    const environmentOrder = {
        development: ['dev'],
        qa: ['qa'], // ite is not required here as per the requirement
        'pre-production': ['preprod', 'ite'],
        preprod: ['preprod', 'ite'],
        production: [],
    };

    return Object.keys(environmentOrder).filter((key) => environmentOrder[key].includes(env));
};
export const environmentConfig = {
    development: {
        regex: /^[a-z0-9-]{3,20}\.dev\.(mypepsico|pepsico)\.com$/,
        message: `Application URL is Invalid. Must be in the format: xxx.dev.mypepsico.com or xxx.dev.pepsico.com (where xxx is 3-20 lower case characters and should not contain any '.' value)`,
    },
    qa: {
        regex: /^[a-z0-9-]{3,20}\.(qa|ite)\.(mypepsico|pepsico)\.com$/,
        message: `Application URL is Invalid. Must be in the format: xxx.qa.mypepsico.com or xxx.qa.pepsico.com or xxx.ite.mypepsico.com or xxx.ite.pepsico.com (where xxx is 3-20 lower case characters and should not contain any '.' value)`,
    },
    'pre-production': {
        regex: /^[a-z0-9-]{3,20}\.(preprod|ite)\.(mypepsico|pepsico)\.com$/,
        message: `Application URL is Invalid. Must be in the format: xxx.preprod.mypepsico.com or xxx.ite.mypepsico.com (where xxx is 3-20 lower case characters and should not contain any '.' value)`,
    },
    production: {
        regex: /^[a-z0-9-]{3,20}\.(mypepsico|pepsico)\.com$/,
        message: `Application URL is Invalid. Must be in the format: xxx.mypepsico.com or xxx.pepsico.com (where xxx is 3-20 lower case characters and should not contain any '.' value)`,
    },
};

export const validateAppURL = (url, environment) => {
    const config = environmentConfig[environment?.toLowerCase()];

    if (!config) {
        return {
            isValid: false,
            message: 'Invalid environment specified',
        };
    }

    const isValid = config.regex.test(url);
    return {
        isValid,
        message: isValid ? 'Valid URL' : config.message,
    };
};

export const resetOrder = {
    securityScanCompleted: 'yes',
    appProfile: null,
    region: null,
    regionDisplayname: null,
    appProfileDetails: null,
    appOwner: null,
    appOwnerDetails: null,
    certificateOwner1: null,
    certificateOwner1Details: null,
    certificateOwner2: null,
    certificateOwner2Details: null,
    // gtmLoadBalancer: null,
    appUrl: null,
    backendFQDN: null,
    backEndHealthProbe: null,
    hCode: null,
    hCodeDetails: null,
    entityDetails: null,
    environment: null,
    envShortName: null,
    isaNumber: null,
    sslEncryption: 'yes',
    supportBackendCertificate: 'Entrust Certificate',
    applicationName: null,
    routeType: 'Basic',
    routingPath: '/*',
    sector: null,
    sectorDetails: null,
    tags: [],
    program: null,
    adGroup: null,
    appSupportMailId: null,
    estimatedCost: null,
    // scanNumber: null,
    isaValidation: null,
    isaValidationMessage: null,
    isaValidationDetails: null,
    provisioning: false,
    placeNewOrder: new Date(),
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetCostEstimateDetails = (catalogCode) => {
    const notes = ApplicationGateWayURLCatalogDefinitionID.includes(catalogCode)
        ? 'There is no cost for the Application Gateway URL onboarding in the shared Application Gateway from DevX Infra. It is included in shared service of your resource group. However, one time cost applicable for the procurement of entrust Standard OV certificate part of automation process.'
        : 'There is no cost for the Public Load balancer URL onboarding in the shared Application Gateway from DevX Infra. It is included in shared service of your resource group. However, one time cost applicable for the procurement of entrust Standard OV certificate part of automation process.';

    return {
        totalRecurringCost: {
            frequency: 'monthly',
            estCost: 0,
            currency: 'USD',
        },
        totalOneTimeCost: {
            frequency: 'One Time',
            estCost: 0,
            currency: 'USD',
        },
        notes,
    };
};

export const resetEnvironmentDetails = {
    Subscription: '---',
    Resource_Group: '---',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
};
export const isUniqueOwner = (value = null, selectedvalues = []) => {
    return selectedvalues.indexOf(value) === -1;
};
export const getValueFromKey = (searchKey, keyValuePairs) => {
    return keyValuePairs[searchKey] ? keyValuePairs[searchKey] : camelToTitle(searchKey);
};
export const mappingLabel = {
    isaNumber: 'ISA Number',
    isaStatus: 'ISA Status',
    parentIsaNumber: 'Parent ISA Number',
    parentIsaNumberStatus: 'Parent ISA Number Status',
    scanNumber: 'SCAN Number',
    scanStatus: 'SCAN Status',
    scanTaskNumber: 'SCAN Task Number',
    scanTaskState: 'SCAN Task State',
};
export const SECTION = {
    ISA_VALIDATION: 'isaValidation',
    SCAN_VALIDATION: 'scanValidation',
    CERTIFICATE_OWNER: 'certificateOwner',
    PROVISIONING: 'provisioning',
    SCAN_VALIDATION_MSG: 'Your ISA Validation has been completed, please enter the Scan Number For URL Validation',
};
export const getCurrentSection = (order) => {
    const { ISA_VALIDATION, SCAN_VALIDATION_MSG, PROVISIONING } = SECTION;
    if (order?.isaValidation === null || order?.isaValidation === undefined) return ISA_VALIDATION;
    if ((order?.isaValidation === true && order?.isaValidationMessage !== SCAN_VALIDATION_MSG) || order?.scanValidation === true) {
        return PROVISIONING;
    }
    if (order?.isaValidation === true && order?.isaValidationMessage === SCAN_VALIDATION_MSG) {
        // return SCAN_VALIDATION;
        return PROVISIONING;
    }
    if (order?.isaValidation === false) {
        // return SCAN_VALIDATION;
        return PROVISIONING;
    }
    return null;
};

export const validateOrder = (order, validationStates) => {
    const {
        isValidAppURL,
        validISANumber,
        validApplicationName,
        isBackendHealthProbeValid,
        validApplicationSupportMail,
        validOwners,
        isValidLoadBalancerURL,
        isValidFrontendPort,
    } = validationStates;

    if (!order?.appProfile) return { isValid: false, message: 'App profile is missing' };
    if (!order?.environment) return { isValid: false, message: 'Environment is missing' };
    if (!order?.sector) return { isValid: false, message: 'Sector is missing' };
    if (!order?.region) return { isValid: false, message: 'Region is missing' };
    if (!order?.appOwner) return { isValid: false, message: 'App owner is missing' };
    if (!order?.appOwnerDetails) return { isValid: false, message: 'App owner details are missing' };
    if (!order?.appProfileDetails) return { isValid: false, message: 'App profile details are missing' };
    if (!order?.appUrl) return { isValid: false, message: 'App URL is missing' };
    if (!order?.hCode) return { isValid: false, message: 'H code is missing' };
    if (!order?.hCodeDetails) return { isValid: false, message: 'H code details are missing' };
    if (order?.sslEncryption === 'yes' && !order?.supportBackendCertificate) return { isValid: false, message: 'Support backend certificate is missing' };
    if (!order?.certificateExists && !order?.adGroup) return { isValid: false, message: 'AD group is missing' };
    if (!order?.certificateExists && !order?.appSupportMailId) return { isValid: false, message: 'App support mail ID is missing' };
    if (!order?.certificateExists && validApplicationSupportMail) return { isValid: false, message: 'Invalid application support mail' };
    if (!order?.certificateExists && !order?.certificateOwner1) return { isValid: false, message: 'Certificate owner 1 is missing' };
    if (!order?.certificateExists && !order?.certificateOwner1Details) return { isValid: false, message: 'Certificate owner 1 details are missing' };
    if (!order?.certificateExists && !order?.certificateOwner2) return { isValid: false, message: 'Certificate owner 2 is missing' };
    if (!order?.certificateExists && !order?.certificateOwner2Details) return { isValid: false, message: 'Certificate owner 2 details are missing' };
    if (ApplicationGateWayURLCatalogDefinitionID.includes(order?.catalogCode)) {
        if (!order?.program) return { isValid: false, message: 'Program is missing' };
        if (!order?.routeType) return { isValid: false, message: 'Route type is missing' };
        if (!order?.routingPath) return { isValid: false, message: 'Routing path is missing' };
        if (!order?.backEndHealthProbe) return { isValid: false, message: 'Backend health probe is missing' };
        if (!order?.backendFQDN) return { isValid: false, message: 'Backend FDQM is missing' };
        if (isBackendHealthProbeValid) return { isValid: false, message: 'Invalid backend health probe' };
    }
    if (ExternalPublicLoadBalancerURLCatalogDefinitionID.includes(order?.catalogCode)) {
        if (!order?.loadBalancerUrl) return { isValid: false, message: 'Load balancer url is missing' };
        if (!order?.loadBalancerFrontEndPort) return { isValid: false, message: 'Port is missing' };
        if (!order?.dataClassification) return { isValid: false, message: 'Data Classification is missing' };
        if (!isValidLoadBalancerURL) return { isValid: false, message: 'Invalid Load balancer url' };
        if (!isValidFrontendPort) return { isValid: false, message: 'Invalid frontend port' };
    }
    if (!isValidAppURL) return { isValid: false, message: 'Invalid app URL' };
    if (validISANumber) return { isValid: false, message: 'Invalid ISA number' };
    if (validApplicationName) return { isValid: false, message: 'Invalid application name' };
    if (!Object.values(validOwners).every((item) => item === true)) return { isValid: false, message: 'Invalid owners' };

    return { isValid: true, message: 'Order is valid' };
};
