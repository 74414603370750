import React from 'react';
import { Grid, Stack, Box, Typography, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import FormCard from 'pages/provisioning/_components/FormCard';
import Form from 'pages/provisioning/virtual-service/components';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import {
    toggleQuickConfig,
    toggleSubQuickConfig,
    orderPayload,
    selectedVSService,
    allowedMethods,
    validVSForm,
    maxVSErrorMessage,
    restrictedPorts,
    exposeHeaders,
    allowedHeaders,
    virtualServicesType,
    selectedVSCopy as selectedVSCopyData,
    selectedVSSubModuleCopy as selectedVSSubModuleCopyData,
    currentSubModule,
    multiFieldValidation,
    validationErrorReport,
    validationStatus,
    duplicateVirtualService as duplicateVirtualServiceData,
    vsRandomValue,
    defaultVSUpsertType,
    isDuplicateCustomDomainName,
    costEstimateData,
    certCreationRequired,
    customDomainFieldsDiasbled,
    isValidOwners,
    isCostLoading,
} from 'pages/provisioning/virtual-service/store';
import Alert from 'components/alert';
import { getRandomString } from 'pages/provisioning/virtual-service/utils/getFunction';
import { VirtualServiceAdded } from 'pages/provisioning/virtual-service/components/project/VirtualServiceAdded';
import { VirtualServicesDetails } from 'pages/provisioning/virtual-service/components/project/VirtualServicesDetails';
import VSDrawer from 'pages/provisioning/virtual-service/VSDrawer';
import {
    isReadyToSave,
    getSelectedVSServiceTypeIndex,
    isDuplicateVirtualService,
    isDuplicateHostName,
    virtualServiceTypes,
} from 'pages/provisioning/virtual-service/utils';
import {
    resetVSCommonFields,
    resetServiceDetailsValidation,
    virtualServiceDefaultValues,
    virtualServiceCustomDomainDefaultValues,
    resetVSForm,
    resetValidOwners,
} from 'pages/provisioning/virtual-service/utils/resetFunction';
import AllowedHeaders from 'pages/provisioning/virtual-service/components/project/AllowedHeaders';
import AllowedOrigins from 'pages/provisioning/virtual-service/components/project/AllowedOrigins';
import ExposeHeaders from 'pages/provisioning/virtual-service/components/project/ExposeHeaders';
import VSContainer from 'pages/provisioning/virtual-service/components/project/VSContainer';
import ErrorBanner from 'components/ErrorBanner';
import ConfirmDialog from 'pages/provisioning/virtual-service/dialog-box/ConfirmDialog';
import { CustomRadioGroup, CustomRadio } from './style';

const ProjectForm = () => {
    const allowedMethodsApi = useMutation(() => axios.get(endPoints?.virtualService?.Jwt?.allowedMethods));
    const allowedHeadersApi = useMutation(() => axios.get(endPoints?.virtualService?.Jwt?.allowedHeaders));
    const exposeHeadersApi = useMutation(() => axios.get(endPoints?.virtualService?.Jwt?.exposeHeaders));
    const restrictedPortsApi = useMutation(() => axios.get(endPoints?.virtualService?.Jwt?.restrictedPorts));
    const [isConfirmVSRequestTypeModalOpen, setConfirmVSRequestTypeModalOpen] = React.useState(false);
    const [, setCostData] = useRecoilState(costEstimateData);
    const [validatedStatus, setValidationStatus] = useRecoilState(validationStatus);
    const [isModalOpen, setModal] = React.useState(false);
    const [headerLabel, setHeaderLabel] = React.useState(null);
    const [errorBanner, setErrorBannerOpen] = React.useState({ error: false, message: '' });
    const [selectedVS, setSelectedVS] = useRecoilState(selectedVSService);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [isOpen, setIsOpen] = useRecoilState(toggleQuickConfig);
    const [isSubOpen, setSubIsOpen] = useRecoilState(toggleSubQuickConfig);
    const [validForm, setVSForm] = useRecoilState(validVSForm);
    const [vsTypes] = useRecoilState(virtualServicesType);
    const [selectedVSCopy, setSelectedVSCopy] = useRecoilState(selectedVSCopyData);
    const [selectedVSSubModuleCopy, setVSSubModuleCopy] = useRecoilState(selectedVSSubModuleCopyData);
    const [multiValidation, setMultiValidation] = useRecoilState(multiFieldValidation);
    const [error, setError] = useRecoilState(maxVSErrorMessage);
    const [, setDuplicateVirtualService] = useRecoilState(duplicateVirtualServiceData);
    const [curentActiveSubModule] = useRecoilState(currentSubModule);
    const [, setAllowedMethods] = useRecoilState(allowedMethods);
    const [, setAllowedHeaders] = useRecoilState(allowedHeaders);
    const [, setRestrictedPorts] = useRecoilState(restrictedPorts);
    const [, setExposeHeaders] = useRecoilState(exposeHeaders);
    const [validationError, setValidationError] = useRecoilState(validationErrorReport);
    const [, setRandomValue] = useRecoilState(vsRandomValue);
    const [, setIsRequired] = useRecoilState(certCreationRequired);
    const [, setIsDiasbled] = useRecoilState(customDomainFieldsDiasbled);
    const [isDomainNameDuplicate, setIsDomainNameDuplicate] = useRecoilState(isDuplicateCustomDomainName);
    const [vsAdditionalDetailUpsertType, setVsAdditionalDetailUpsertType] = useRecoilState(defaultVSUpsertType);
    const [, setValidOwners] = useRecoilState(isValidOwners);
    const [, setCostLoading] = useRecoilState(isCostLoading);
    const { catalogCode } = useParams();

    const isSingleVSType = [virtualServiceTypes?.[0]?.code, virtualServiceTypes?.[1]?.code].includes(
        getSelectedVSServiceTypeIndex(order, vsTypes, selectedVS)?.value
    );

    const costDetailsApi = useMutation((payload) => axios.post(endPoints.virtualService.costDetails, payload));
    const validateVertualServiceApi = useMutation((payload) => axios.post(endPoints.virtualService.validateServiceAccount, payload));

    const callCostApi = (aksVirtualService) => {
        const costPayload = {
            catalogServiceCode: catalogCode,
            quantity: 1,
            catalogConfig: {
                aksVirtualService: [],
            },
        };
        if (
            order?.aksVirtualServiceRequestType?.toLowerCase() === 'create' &&
            aksVirtualService?.length > 0 &&
            aksVirtualService?.[0]?.virtualServiceType !== null
        ) {
            const costObject = [];
            aksVirtualService.forEach((data) => {
                costObject.push({
                    virtualServiceType: virtualServiceTypes?.filter((item) => item.name === data?.virtualServiceType)[0]?.code,
                    virtualServiceName: data?.virtualServiceName,
                    customDomain: [
                        {
                            name: data?.customDomain?.[0]?.name,
                        },
                    ],
                });
            });
            costPayload.catalogConfig.aksVirtualService = costObject;
            costDetailsApi?.mutate(costPayload);
        } else if (order?.aksVirtualServiceRequestType?.toLowerCase() === 'update' && aksVirtualService?.length > 0) {
            const vsDomainData = aksVirtualService.map((item) => ({
                virtualServiceType: 'UPDATE_VS_CUSTOM_DOMAIN',
                customDomain: [
                    {
                        name: item?.name || null,
                    },
                ],
            }));
            costPayload.catalogConfig.aksVirtualService = vsDomainData;
            costDetailsApi?.mutate(costPayload);
        }
    };

    React.useEffect(() => {
        if (costDetailsApi?.isSuccess) {
            setCostLoading(false);
            setCostData({ ...costDetailsApi?.data?.data, ...(!costDetailsApi?.data?.data?.qty && { qty: 1 }) });
        }
    }, [costDetailsApi?.isSuccess]);

    React.useEffect(() => {
        if (costDetailsApi?.isLoading) {
            setCostLoading(true);
        }
    }, [costDetailsApi?.isLoading]);

    const checkIfFormIsValid = () => {
        if (curentActiveSubModule === 'allowedHeaders') {
            const result = [];
            order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedHeaders?.forEach((z) => {
                if (!z.allowedHeader) {
                    const updateIsTouched = { ...z, isAllowedHeaderTouched: true };
                    result.push(updateIsTouched);
                } else result.push(z);
            });
            setOrder({
                ...order,
                aksVirtualService: order?.aksVirtualService.map((vs, index) => {
                    if (index === selectedVS) {
                        return {
                            ...vs,
                            aksCors: {
                                ...vs?.aksCors,
                                allowedHeaders: result,
                            },
                        };
                    }
                    return vs;
                }),
            });
            return order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedHeaders?.some((origin) => origin.allowedHeader === null);
        }
        if (curentActiveSubModule === 'allowedOrigins') {
            const result = [];
            order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins?.forEach((z) => {
                if (!z.allowedOrigin || z.allowedOrigin === '') {
                    const updateIsTouched = { ...z, isAllowedOriginTouched: true };
                    result.push(updateIsTouched);
                } else result.push(z);
            });
            setOrder({
                ...order,
                aksVirtualService: order?.aksVirtualService.map((vs, index) => {
                    if (index === selectedVS) {
                        return {
                            ...vs,
                            aksCors: {
                                ...vs?.aksCors,
                                allowedOrigins: result,
                            },
                        };
                    }
                    return vs;
                }),
            });
            const allowedOrigins = order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins;
            const allowedOriginsList = allowedOrigins?.map((item) => item?.allowedOrigin)?.filter((item) => item !== null && item !== '');
            const duplicateAllowedOrigins = allowedOriginsList?.filter((item, index) => allowedOriginsList?.indexOf(item) !== index);

            return (
                order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins?.some(
                    (origin) => origin?.allowedOrigin === null || origin?.allowedOrigin === ''
                ) ||
                validForm?.isInvalidAllowedOrigin ||
                duplicateAllowedOrigins?.length > 0
            );
        }
        if (curentActiveSubModule === 'exposeHeaders') {
            const result = [];
            order?.aksVirtualService?.[selectedVS]?.aksCors?.exposeHeaders?.forEach((z) => {
                if (!z.exposeHeader) {
                    const updateIsTouched = { ...z, isExposeHeaderTouched: true };
                    result.push(updateIsTouched);
                } else result.push(z);
            });
            setOrder({
                ...order,
                aksVirtualService: order?.aksVirtualService.map((vs, index) => {
                    if (index === selectedVS) {
                        return {
                            ...vs,
                            aksCors: {
                                ...vs?.aksCors,
                                exposeHeaders: result,
                            },
                        };
                    }
                    return vs;
                }),
            });
            return order?.aksVirtualService?.[selectedVS]?.aksCors?.exposeHeaders?.some((origin) => origin.exposeHeader === null);
        }
        return true;
    };
    const checkMultiValidationIsValid = () => {
        return (
            multiValidation?.contextPath?.some((value) => value?.isInvalid === true) ||
            multiValidation?.serviceName?.some((value) => value?.isInvalid === true) ||
            multiValidation?.servicePort?.some((value) => value?.isInvalid === true) ||
            multiValidation?.hostNamePrefix?.some((value) => value?.isInvalid === true)
        );
    };
    const getValidationReportCheck = () => {
        return (
            validationError?.filter((vsError) => {
                if (vsError?.currentVSIndex === selectedVS && vsError?.error === true) {
                    return true;
                }
                return false;
            }).length > 0
        );
    };
    React.useEffect(() => {
        if (order?.aksVirtualServiceRequestType?.toLowerCase() === 'update') {
            const certArray = order?.aksVirtualServiceUpdateCustomDomain?.map((data) => data?.certificateExists);

            if (certArray?.includes(false)) {
                setIsRequired(true);
            } else {
                setIsRequired(false);
            }
        }
    }, [order?.aksVirtualServiceUpdateCustomDomain, order?.aksVirtualServiceRequestType]);

    const getCertRequiredValue = (aksVirtualService) => {
        const certArray = aksVirtualService?.map((data) => data?.certificateExists);
        if (certArray?.includes(false)) {
            setIsRequired(true);
        } else {
            setIsRequired(false);
            setOrder((prevOrder) => ({
                ...prevOrder,
                applicationOwner: null,
                applicationOwnerDetails: null,
                costCenter: null,
                costCenterDetails: null,
                certificateOwnerOne: null,
                certificateOwnerOneDetails: null,
                certificateOwnerTwo: null,
                certificateOwnerTwoDetails: null,
            }));
        }
        const customDomainSelectedArray = aksVirtualService?.map((data) => data?.customDomainSelected);
        if (customDomainSelectedArray?.includes('Yes')) {
            setIsDiasbled(false);
        } else {
            setIsDiasbled(true);
            setOrder((prevOrder) => ({
                ...prevOrder,
                applicationOwner: null,
                applicationOwnerDetails: null,
                costCenter: null,
                costCenterDetails: null,
                certificateOwnerOne: null,
                certificateOwnerOneDetails: null,
                certificateOwnerTwo: null,
                certificateOwnerTwoDetails: null,
            }));
        }
    };

    const handleDrawerAction = (type, subDrawer) => {
        if (!subDrawer) {
            if (type === 'save') {
                const { vsLength, duplicateVirtualService } = isDuplicateVirtualService(order);
                if (isDuplicateHostName(order)) {
                    setErrorBannerOpen({ error: true, message: `Duplicate Host Name not allowed, please use unique name.` });
                    return;
                }
                if (vsLength) {
                    setDuplicateVirtualService(duplicateVirtualService);
                    setErrorBannerOpen({ error: true, message: 'Duplicate Virtual Service Name not allowed, please use unique name.' });
                    return;
                }
                setDuplicateVirtualService([]);
                setErrorBannerOpen({ error: false, message: '' });

                if (
                    !isReadyToSave(order, selectedVS, validForm, vsTypes) &&
                    !checkMultiValidationIsValid() &&
                    !getValidationReportCheck() &&
                    !isDomainNameDuplicate
                ) {
                    validateVertualServiceApi?.mutate({
                        virtualServiceName: order?.aksVirtualService[selectedVS]?.virtualServiceName,
                        aksNamespace: order.aksNamespace,
                        customDomainName: order?.aksVirtualService[selectedVS]?.customDomain?.[0]?.name || null,
                        serviceNames:
                            order?.aksVirtualService[selectedVS]?.aksService?.filter((service) => service?.serviceName !== null)?.map((s) => s?.serviceName) ||
                            null,
                    });
                } else {
                    const result = [];
                    order?.aksVirtualService[selectedVS]?.hostNamesPrefix?.forEach((z) => {
                        if (!z.hostNamePrefix || z.hostNamePrefix === '') {
                            const updateIsTouched = { ...z, isHostNameTouched: true };
                            result.push(updateIsTouched);
                        } else result.push(z);
                    });

                    setOrder({
                        ...order,
                        aksVirtualService: order?.aksVirtualService.map((vs, index) => {
                            if (index === selectedVS) {
                                return {
                                    ...vs,
                                    hostNamesPrefix: result,
                                };
                            }
                            return vs;
                        }),
                        isConfigureTouched: true,
                    });
                    setErrorBannerOpen({ error: true, message: 'Please fill out the missing values marked with the * asterisk and click save.' });
                }
            } else if (type === 'cancel') {
                setOrder(() => ({
                    ...order,
                    aksVirtualService: order?.aksVirtualService.map((vs, index) => {
                        if (index === selectedVS) {
                            return {
                                ...selectedVSCopy,
                            };
                        }
                        return vs;
                    }),
                }));
                setDuplicateVirtualService([]);
                setMultiValidation({
                    contextPath: resetServiceDetailsValidation,
                    serviceName: resetServiceDetailsValidation,
                    servicePort: resetServiceDetailsValidation,
                    hostNamePrefix: resetServiceDetailsValidation,
                });
                setIsDomainNameDuplicate(false);
                setIsOpen(false);
            }
        } else if (subDrawer) {
            if (type === 'save') {
                if (checkIfFormIsValid()) {
                    // eslint-disable-next-line consistent-return
                    return setErrorBannerOpen({
                        error: true,
                        message: 'Please fill out the missing values marked with the * asterisk and click save.',
                    });
                    // eslint-disable-next-line consistent-return
                }
                setSubIsOpen(false);
                setIsOpen(true);
            } else if (type === 'cancel') {
                if (headerLabel === 'Allowed Headers') {
                    setOrder({
                        ...order,
                        aksVirtualService: order?.aksVirtualService.map((vs, index) => {
                            if (index === selectedVS) {
                                return {
                                    ...vs,
                                    aksCors: {
                                        ...vs?.aksCors,
                                        allowedHeaders: selectedVSSubModuleCopy?.aksCors?.allowedHeaders,
                                    },
                                };
                            }
                            return vs;
                        }),
                    });
                }
                if (headerLabel === 'Allowed Origins') {
                    setOrder({
                        ...order,
                        aksVirtualService: order?.aksVirtualService.map((vs, index) => {
                            if (index === selectedVS) {
                                return {
                                    ...vs,
                                    aksCors: {
                                        ...vs?.aksCors,
                                        allowedOrigins: selectedVSSubModuleCopy?.aksCors?.allowedOrigins,
                                    },
                                };
                            }
                            return vs;
                        }),
                    });
                }
                if (headerLabel === 'Expose Headers') {
                    setOrder({
                        ...order,
                        aksVirtualService: order?.aksVirtualService.map((vs, index) => {
                            if (index === selectedVS) {
                                return {
                                    ...vs,
                                    aksCors: {
                                        ...vs?.aksCors,
                                        exposeHeaders: selectedVSSubModuleCopy?.aksCors?.exposeHeaders,
                                    },
                                };
                            }
                            return vs;
                        }),
                    });
                }
                setSubIsOpen(false);
                setIsOpen(true);
            }
        }
        // eslint-disable-next-line consistent-return
        return null;
    };

    const handleSubDrawerAction = (type, label) => {
        setVSSubModuleCopy(order?.aksVirtualService?.[selectedVS]);
        setIsOpen(false);
        setHeaderLabel(label);
        setSubIsOpen(true);
    };
    React.useEffect(() => {
        if (validateVertualServiceApi?.isSuccess) {
            if (getSelectedVSServiceTypeIndex(order, vsTypes, selectedVS)?.value === virtualServiceTypes?.[1]?.code) {
                setOrder(() => ({
                    ...order,
                    isConfigureTouched: false,
                    aksVirtualService: order?.aksVirtualService.map((vs, index) => {
                        if (index === selectedVS) {
                            return {
                                ...vs,
                                virtualServiceType: order?.aksVirtualService[selectedVS]?.virtualServiceType,
                                aksService: order?.aksVirtualService[selectedVS]?.aksService.map((service) => {
                                    return {
                                        ...service,
                                        serviceName: order?.aksVirtualService[selectedVS]?.aksService[0]?.serviceName,
                                        servicePort: order?.aksVirtualService[selectedVS]?.aksService[0]?.servicePort,
                                    };
                                }),
                            };
                        }
                        return vs;
                    }),
                }));
            }
            setIsOpen(false);
            getCertRequiredValue(order?.aksVirtualService);
            callCostApi(order.aksVirtualService);
            const tempValidatedstatus = [...(validatedStatus || [])];
            tempValidatedstatus[selectedVS === null ? 0 : selectedVS] = {
                msg: [],
                customDomain: [],
                services: [],
                virtualServiceName: '',
                hostNames: [],
            };
            setValidationStatus(tempValidatedstatus);
        }
    }, [validateVertualServiceApi?.isSuccess]);
    React.useEffect(() => {
        if (validateVertualServiceApi?.isError) {
            const index = selectedVS === null ? 0 : selectedVS;

            const servicesArray =
                order?.aksVirtualService?.[selectedVS]?.aksService?.map((service) => ({
                    name: service?.serviceName,
                    msg: ['Please Provide Valid ServiceName'],
                })) || [];
            const customDomain = [
                {
                    name: order?.aksVirtualService?.[selectedVS]?.customDomain?.[0]?.name,
                    msg: ['Please Provide Valid CustomDomain'],
                },
            ];

            const vsStatus = [...(validatedStatus || [])];
            vsStatus[index] = {
                msg: ['Pelect valid Virtual Service Name'],
                customDomain,
                services: isSingleVSType ? servicesArray : [],
                virtualServiceName: order?.aksVirtualService?.[selectedVS]?.virtualServiceName || '',
            };
            setValidationStatus(vsStatus);
            setErrorBannerOpen({ error: true, message: validateVertualServiceApi?.error });
        }
    }, [validateVertualServiceApi?.isError]);
    const handleVSAction = (index, type) => {
        setSelectedVSCopy(order?.aksVirtualService?.[index]);
        setSelectedVS(index);
        setError(null);
        if (type === 'remove') {
            setOrder({
                ...order,
                ...resetVSCommonFields,
            });
            setModal(true);
        } else if (type === 'clone') {
            if (order.aksVirtualService.length >= 10) {
                return setError('You can add only 10 Virtual Services');
            }

            const updatedAksVirtualService = [...order.aksVirtualService];

            updatedAksVirtualService.splice(index, 0, order.aksVirtualService[index]);

            setOrder({
                ...order,
                aksVirtualService: updatedAksVirtualService.map((vs, i) => {
                    if (i === index + 1) {
                        return {
                            ...vs,
                            virtualServiceName: null,
                            aksService: vs?.aksService?.map((service) => {
                                return {
                                    ...service,
                                    serviceName: null,
                                };
                            }),
                            hostNamesPrefix: [{ hostNamePrefix: null }],
                            customDomainSelected: 'No',
                            customDomain: [
                                {
                                    name: null,
                                },
                            ],
                        };
                    }
                    return vs;
                }),
            });
        } else if (type === 'configure' || type === 'edit') {
            setRandomValue(getRandomString(4));
            setOrder({
                ...order,
                ...resetVSCommonFields,
            });
            setIsOpen(true);
        }
        return null;
    };
    const handleAction = (type) => {
        if (type === 'yes') {
            const updatedAksVirtualService = [...order.aksVirtualService];
            updatedAksVirtualService.splice(selectedVS, 1);
            getCertRequiredValue(updatedAksVirtualService);
            callCostApi(updatedAksVirtualService);
            setOrder({ ...order, aksVirtualService: updatedAksVirtualService });
            setSelectedVS(null);
        } else if (type === 'no') {
            setSelectedVS(null);
        }
        setModal(false);
    };

    const renderSubDrawer = () => {
        if (headerLabel === 'Allowed Headers') {
            return <AllowedHeaders order={order} setOrder={setOrder} />;
            // eslint-disable-next-line no-else-return
        } else if (headerLabel === 'Allowed Origins') {
            return <AllowedOrigins order={order} setOrder={setOrder} />;
        } // eslint-disable-next-line no-else-return
        else if (headerLabel === 'Expose Headers') {
            return <ExposeHeaders order={order} setOrder={setOrder} />;
        }
        return null;
    };

    React.useEffect(() => {
        if (allowedMethodsApi?.isSuccess) {
            setAllowedMethods(allowedMethodsApi?.data?.data?.allowedMethods?.map((method) => ({ label: method, value: method })));
        }
        if (exposeHeadersApi?.isSuccess) {
            setExposeHeaders(exposeHeadersApi?.data?.data?.exposeHeaders);
        }
        if (restrictedPortsApi?.isSuccess) {
            setRestrictedPorts(restrictedPortsApi?.data?.data?.restrictedPorts);
        }
        if (allowedHeadersApi?.isSuccess) {
            setAllowedHeaders(allowedHeadersApi?.data?.data?.allowedHeaders?.map((header) => ({ label: header, value: header })));
        }
    }, [allowedMethodsApi?.isSuccess, exposeHeadersApi?.isSuccess, restrictedPortsApi?.isSuccess, allowedHeadersApi?.isSuccess]);

    React.useEffect(() => {
        allowedMethodsApi?.mutate();
        allowedHeadersApi?.mutate();
        exposeHeadersApi?.mutate();
        restrictedPortsApi?.mutate();
    }, []);

    const checkIfVSTypeNotNull = () => {
        if (order?.aksVirtualService?.length > 0) {
            return order?.aksVirtualService.some((vs) => vs?.virtualServiceType !== null);
        }
        return false;
    };

    React.useEffect(() => {
        if (order?.aksVirtualService?.[selectedVS]?.customDomainSelected === 'No') {
            setOrder((prevOrder) => ({
                ...prevOrder,
                aksVirtualService: order?.aksVirtualService.map((vs, index) => {
                    if (index === selectedVS) {
                        return {
                            ...vs,
                            certificateExists: true,
                        };
                    }
                    return vs;
                }),
            }));
        }
    }, [order?.aksVirtualService?.[selectedVS]?.customDomainSelected]);

    const handleVSRequestTypeUpdate = (selectedRequestTypeValue) => {
        setVsAdditionalDetailUpsertType(selectedRequestTypeValue);
        if (vsAdditionalDetailUpsertType === '') {
            setOrder({
                ...order,
                aksVirtualServiceRequestType: selectedRequestTypeValue,
                aksVirtualServiceUpdateCustomDomain: virtualServiceCustomDomainDefaultValues,
            });
            setValidationStatus(null);
        } else {
            setConfirmVSRequestTypeModalOpen(true);
        }
    };

    const getValidate = () => {
        if (!order?.aksVirtualServiceRequestType && order?.isTouched) return 'ritmCheckbox';
        return '';
    };

    const handleVSRequestTypeUpdateConfirmAction = (actionType) => {
        if (actionType === 'yes') {
            setValidationStatus(null);
            setValidationError([]);
            setVSForm(resetVSForm);
            setCostData(null);
            setIsRequired(false);
            setOrder((prevOrder) => ({
                ...prevOrder,
                applicationOwner: null,
                applicationOwnerDetails: null,
                costCenter: null,
                costCenterDetails: null,
                certificateOwnerOne: null,
                certificateOwnerOneDetails: null,
                certificateOwnerTwo: null,
                certificateOwnerTwoDetails: null,
            }));
            setValidOwners(resetValidOwners);
            setIsDomainNameDuplicate(false);

            if (vsAdditionalDetailUpsertType?.toLowerCase() === 'create') {
                setOrder({
                    ...order,
                    aksVirtualServiceRequestType: vsAdditionalDetailUpsertType,
                    aksVirtualServiceUpdateCustomDomain: virtualServiceCustomDomainDefaultValues,
                });
            } else if (vsAdditionalDetailUpsertType?.toLowerCase() === 'update') {
                setOrder({
                    ...order,
                    aksVirtualServiceRequestType: vsAdditionalDetailUpsertType,
                    aksVirtualService: [virtualServiceDefaultValues],
                });
            }
        }
        setConfirmVSRequestTypeModalOpen(false);
    };

    return (
        <>
            {error && (
                <Box sx={{ mb: 1.5 }}>
                    <Alert
                        title={null}
                        message={error}
                        Icon={<ReportProblemIcon fontSize="medium" sx={{ fill: '#E71332' }} />}
                        borderLeftColor="#E71332"
                        backgroundColor="#FBE8EA"
                        color="#000000"
                        ftSize="14px"
                        onClose={() => setError(null)}
                        closeicon
                    />
                </Box>
            )}
            <FormCard title="Virtual service details" subTitle="Add up to 10 Virtual Services.">
                <Grid item container spacing={2} className="project-section">
                    <Grid item md={12} sm={12} xs={12}>
                        <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                            For applications deployed through Helm or existing virtual services, the custom domain host name configuration is supported through
                            Update Virtual Service option.
                        </Typography>
                        <Stack direction="column" spacing={2} sx={{ mb: 2.5 }}>
                            <CustomRadioGroup
                                aria-label="Do you want to create or update VS details?"
                                name="VSUpsertRadioBtnGroup"
                                row
                                value={order?.aksVirtualServiceRequestType}
                                onChange={(event, value) => handleVSRequestTypeUpdate(value)}
                                data-testid="vs-request-type-radioBtnGroup"
                                className={getValidate()}
                            >
                                <FormControlLabel
                                    value="CREATE"
                                    control={<CustomRadio />}
                                    data-testid="VSUpsertRadioBtnGroup-create"
                                    label="Create Virtual Services"
                                    disabled={!order?.sector || !order?.environment || !order?.aksNamespace || !order?.aksCluster}
                                />
                                <FormControlLabel
                                    value="UPDATE"
                                    control={<CustomRadio />}
                                    data-testid="VSUpsertRadioBtnGroup-update"
                                    label="Update Virtual Service with Custom Domain"
                                    disabled={!order?.sector || !order?.environment || !order?.aksNamespace || !order?.aksCluster}
                                />
                            </CustomRadioGroup>
                        </Stack>
                        <Stack direction="column" spacing={2}>
                            {(order?.aksVirtualServiceRequestType?.toLowerCase() === 'create' ||
                                order?.aksVirtualServiceRequestType?.toLowerCase() === 'update' ||
                                vsAdditionalDetailUpsertType?.toLowerCase() === 'update') && (
                                <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
                                    You can add upto 10 Virtual Services.
                                </Typography>
                            )}
                            {order?.aksVirtualServiceRequestType?.toLowerCase() === 'create' && (
                                <>
                                    <Form.VirtualServiceType />
                                    {order?.aksVirtualService?.length > 0 && checkIfVSTypeNotNull() && (
                                        <VirtualServiceAdded handleVSAction={(index, type) => handleVSAction(index, type)} />
                                    )}
                                </>
                            )}
                            {order?.aksVirtualServiceRequestType?.toLowerCase() === 'update' && <VirtualServicesDetails callCostApi={callCostApi} />}
                        </Stack>
                        <Stack direction="column" spacing={2}>
                            <VSDrawer
                                isOpen={isOpen || isSubOpen}
                                isSubOpen={isSubOpen}
                                title={isOpen ? 'Configure Virtual Service' : headerLabel}
                                subHeader={isOpen ? null : `Add up to 10 ${headerLabel}`}
                                handleDrawerAction={(type, subDrawer) => handleDrawerAction(type, subDrawer)}
                                isSaveLoading={validateVertualServiceApi?.isLoading}
                            >
                                {isOpen && !isSubOpen && (
                                    <VSContainer order={order} handleSubDrawerAction={(type, label) => handleSubDrawerAction(type, label)} />
                                )}
                                {!isOpen && isSubOpen && renderSubDrawer()}
                            </VSDrawer>
                        </Stack>
                        {isConfirmVSRequestTypeModalOpen && (
                            <ConfirmDialog
                                isModalOpen={isConfirmVSRequestTypeModalOpen}
                                title="All your details will be lost, Are you sure you want to proceed?"
                                primaryBtnText="Yes"
                                secondaryBtnText="No"
                                primaryBtnAction={() => handleVSRequestTypeUpdateConfirmAction('yes')}
                                secondaryBtnAction={() => handleVSRequestTypeUpdateConfirmAction('no')}
                            />
                        )}
                        {isModalOpen && (
                            <ConfirmDialog
                                isModalOpen={isModalOpen}
                                title="Are you sure you want to remove this Virtual Service?"
                                primaryBtnText="Yes"
                                secondaryBtnText="No"
                                primaryBtnAction={() => handleAction('yes')}
                                secondaryBtnAction={() => handleAction('no')}
                            />
                        )}
                        {errorBanner?.error && (
                            <ErrorBanner
                                show={errorBanner?.error}
                                message={errorBanner?.message}
                                handleCloseCallback={() => setErrorBannerOpen({ error: false, message: '' })}
                            />
                        )}
                    </Grid>
                </Grid>
            </FormCard>
        </>
    );
};

export default ProjectForm;
