import { atom } from 'recoil';

export const sspSubmitOrder = atom({
    key: 'iagw-sspOrder',
    default: {
        appProfile: null,
        appProfileDetails: null,
        applicationName: null,
        sector: null,
        sectorDetails: null,
        region: null,
        regionDisplayname: null,
        environment: null,
        envShortName: null,
        hCode: null,
        hCodeDetails: null,
        appOwner: null,
        appOwnerDetails: null,
        certificateOwner1: null,
        certificateOwner1Details: null,
        certificateOwner2: null,
        certificateOwner2Details: null,
        adGroup: null,
        appSupportMailId: null,
        dataClassification: null,
        csaRequestNumber: null,
        appUrl: null,
        sslEncryption: 'yes',
        supportBackendCertificate: null,
        cookieBasedAffinity: 'no',
        backendFQDN: null,
        backendPort: null,
        backendProtocol: null,
        backendHealthProbePath: null,
        healthProbeProtocol: null,
        entityDetails: null,
        estimatedCost: {
            details: [],
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'There is no cost for the Application Gateway URL onboarding in the shared Application Gateway from DevX Infra. It is included in shared service of your resource group. However, one time cost applicable for the procurement of entrust Standard certificate part of automation process.',
        },
        catalogCode: null,
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const environmentDetails = atom({
    key: 'iagw-environmentDetails',
    default: {
        Subscription: '---',
        Resource_Group: '---',
        'Application gateway Name': '---',
    },
});

export const isValidApplicationName = atom({
    key: 'iagw-validApplicationName',
    default: false,
});
export const isAppUrlValid = atom({
    key: 'iagw-isAppUrlValid',
    default: false,
});
export const isCertificateExists = atom({
    key: 'iagw-certificateExists',
    default: false,
});
export const isValidBackendHealthProbe = atom({
    key: 'iagw-validBackENdHealthProbe',
    default: false,
});
export const isValidFqdns = atom({
    key: 'iagw-isValidFqdns',
    default: false,
});
export const isValidBackendPort = atom({
    key: 'iagw-isValidBackendPort',
    default: false,
});
export const isValidUrlPath = atom({
    key: 'iagw-isValidUrlValid',
    default: false,
});
export const isValidISANumber = atom({
    key: 'iagw-isValidISANumber',
    default: false,
});
export const isValidScanNumber = atom({
    key: 'iagw-isValidScanNumber',
    default: false,
});
export const toggleSspModels = atom({
    key: 'iagw-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
        isBackendPoolsOpen: false,
        isHealthProbeOpen: false,
    },
});

export const isMandatoryTagsFullfilled = atom({
    key: 'iagw-isMandatoryTagsFullfilled',
    default: false,
});

export const isEnvDetailsLoading = atom({
    key: 'iagw-isEnvDetailsLoading',
    default: false,
});
export const costEstimateState = atom({
    key: 'iagw-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'iagw-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const orderId = atom({
    key: 'iagw-orderId',
    default: '',
});

export const isValidappSupportMailId = atom({
    key: 'iagw-isValidappSupportMailId',
    default: false,
});

export const isValidgtmLoadBalancer = atom({
    key: 'iagw-isValidgtmLoadBalancer',
    default: false,
});

export const isValidOwners = atom({
    key: 'iagw-isValidOwners',
    default: {
        isvalidAppOwner: true,
        isvalidCertificateOwner1: true,
        isvalidCertificateOwner2: true,
    },
});

export const isBackendPoolsConfigFullfilled = atom({
    key: 'iagw-isBackendPoolsConfigFullfilled',
    default: false,
});
export const isHealthProbeFullfilled = atom({
    key: 'iagw-isHealthProbeFullfilled',
    default: false,
});
