import { styled } from '@mui/material/styles';
import { TextField, Button, TableRow } from '@mui/material';
import tableSortLabelClasses from '@mui/material/TableSortLabel/tableSortLabelClasses';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const styles = {
    infoIcon: { color: '#FFFFFF', fill: '#F4273B', fontSize: '36px', marginRight: '10px !important', marginLeft: '5px' },
    loadingStack: { width: (theme) => theme.spacing('100%') },
    cataloginncontainer: { width: 'auto', display: 'flex', justifyContent: 'center' },
    gridWrapper: { paddingX: '0px' },
    gridcontain: { backgroundColor: (theme) => theme.palette.grey.lighter },
    griditemcontain: { mb: 2, backgroundColor: (theme) => theme.palette.grey.lighter },
    mainstack: {
        // background: (theme) => theme.palette.white,
        color: (theme) => theme.palette.black.dark,
        overflow: 'hidden',
        flexDirection: 'column',
        display: 'flex',
        width: (theme) => theme.spacing('100%'),
        height: (theme) => theme.spacing('100%'),
    },
    listWrapper: {
        color: (theme) => theme.palette.white,
        flexGrow: 1,
        overflow: 'auto',
        display: 'flex',
        paddingTop: (theme) => theme.spacing('26px'),
        paddingBottom: (theme) => theme.spacing(1),
        backgroundColor: (theme) => theme.palette.grey.lighter,
        paddingX: (theme) => theme.spacing('63px'),
    },
    headercontainer: {
        px: 6,
        py: 2,
        background: '#FBFCFE 0% 0% no-repeat padding-box',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #E0E0E0',
        width: '100%',
    },
    headingTypo: { color: (theme) => theme.palette.black.dark, fontSize: (theme) => theme.spacing('24px') },
    actionContainer: { display: 'flex', mr: (theme) => theme.spacing(1) },
    searchbox: {
        display: 'flex',
        alignItems: 'center',
        width: (theme) => theme.spacing(40),
        background: (theme) => theme.palette.white,
        borderRadius: '1px',
        mr: (theme) => theme.spacing(2),
    },
    clearicon: { color: (theme) => theme.palette.white },
    searchicon: { color: (theme) => theme.palette.blue.main },
    /* filterButton: {
        height: '38px',
        width: '100px',
        color: (theme) => theme.palette.blue.main,
        borderColor: (theme) => theme.palette.blue.main,
        border: '2px solid',
        '&:hover': {
            color: (theme) => theme.palette.cyan.main,
            borderColor: (theme) => theme.palette.cyan.main,
            border: '2px solid',
            backgroundColor: 'transparent !important',
        },
    },

    orderFilter: {
        container: {
            color: (theme) => theme.palette.white,
            borderColor: '#0063cc',
            border: '2px solid',
        },
        wrapper: {
            background: (theme) => theme.palette.white,
            width: 260,
            padding: 2,
            position: 'relative',
            top: 2,
        },
        arrow: {
            position: 'absolute',
            fontSize: 60,
            top: -34,
            left: 100,
            fill: (theme) => theme.palette.white,
            zIndex: -1,
        },
        filterIcon: { color: (theme) => theme.palette.white },
        closeIcon: { cursor: 'pointer', '&:hover': { color: '#f99c24' } },
        popper: {
            container: { display: 'flex', justifyContent: 'end' },
        },
    }, */
    list: {
        wrapper: {
            color: (theme) => theme.palette.white,
            flexGrow: 1,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'row',
            pt: (theme) => theme.spacing(1.7),
            pb: (theme) => theme.spacing(1),
            paddingLeft: (theme) => theme.spacing('53px'),
            paddingRight: (theme) => theme.spacing('53px'),
        },
        tableContainer: {
            borderRadius: '3px',
            boxShadow: '1px 1px 2px #00000014',
        },
        table: {
            marginTop: (theme) => theme.spacing(-1.125),
            borderSpacing: (theme) => theme.spacing(0),
            minWidth: 500,
            height: 'auto',
            paddingTop: (theme) => theme.spacing(1),
        },
        checkboxTblHead: { height: (theme) => theme.spacing(4), color: (theme) => theme.palette.white },
        loadingRow: { background: 'transparent !important' },
        loadingCell: { border: '0 !important' },
        checkbox: { height: (theme) => theme.spacing(3.75), fill: (theme) => theme.palette.blue.main },
        circularIcon: { marginLeft: (theme) => theme.spacing(1.25), color: '#0047BA' },
        styledTableSortLabel: {
            [`&.${tableSortLabelClasses.active}`]: {
                color: (theme) => theme.palette.white,
            },
            '&:hover': {
                color: (theme) => theme.palette.white,
            },
        },
        pagination: {
            background: '#ffffff',
            fontSize: '14px',
            width: 'auto',
            color: (theme) => theme.palette.black.main,
            border: '1px solid #E0E0E0',
            '.MuiTablePagination-selectLabel, .MuiTablePagination-input': {
                // fontWeight: "bold",
                // color: "blue"
                display: 'none',
            },
            '.MuiTablePagination-spacer': {
                flex: 0,
            },
            '.MuiToolbar-root': {
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                alignItems: 'center',
            },
            '.MuiTablePagination-displayedRows': {
                fontWeight: 500,
                fontSize: '1rem',
                color: '#1E1E1E',
                paddingTop: '2px',
            },
        },
        subscriptionCount: {
            fontSize: '16px',
            color: (theme) => theme.palette.black.dark,
            paddingTop: (theme) => theme.spacing(2.5),
            paddingLeft: (theme) => theme.spacing('54px'),
            paddingBottom: (theme) => theme.spacing(0.5),
        },
    },
    catalogServiceBtn: {
        padding: 'inherit',
        cursor: 'pointer',
    },
    editCatalogs: {},
    wrapper: { alignItems: 'center', justifyContent: 'center' },
    emptycontainer: { backgroundColor: '#FFFFFF' },
    emptymsg: { fontSize: '18px', color: '#000000', fontWeigtht: 'medium' },

    catalogdetails: {
        buttonWrapper: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mr: 3, mt: 3 },
        editBtn: { mr: 2 },
        cancelBtn: {
            marginLeft: '10px',
            color: '#6E6F6F',
            border: '2px solid #707070',
            backgroundColor: '#ffffff',
            textTransform: 'none',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                backgroundColor: '#ffffff',
            },
        },
    },
    editCatalogdetails: {
        container: { mt: 4, mx: 5 },
        circularProg: { marginLeft: '10px' },
        tagstypo: { fontSize: 28, fontWeight: 'medium' },
        chipWrapper: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', ml: 1, mt: 1, alignItems: 'center', alignContent: 'center' },
        chipTag: { borderRadius: 0, mr: 2, mt: 2, backgroundColor: (theme) => theme.palette.tag.$auxiliary_600_background_color, color: '#fff' },
        iconBtn: {
            backgroundColor: '#0047BA',
            width: 40,
            height: 30,
            borderRadius: 1,
            marginTop: '13px',
            '&:hover': {
                backgroundColor: '#0047BA',
                width: 40,
                height: 30,
                borderRadius: 1,
                marginTop: '13px',
            },
        },
        addicon: { color: '#FFFFFF' },
        textfield: {
            width: { sm: 200, md: 300 },
            '& .MuiInputBase-root': {
                height: '40px',
            },
            mt: 1,
        },
        actionWrapper: { position: 'absolute', bottom: '20px', right: '50px' },
        actioncontainser: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' },
        cancelbtn: {
            marginLeft: '10px',
            color: '#6E6F6F',
            border: '2px solid #707070',
            backgroundColor: '#ffffff',
            textTransform: 'none',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                backgroundColor: '#ffffff',
            },
        },
    },
};

export default styles;

export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#0047BA',
    },
    '& label.MuiFormLabel-filled': {
        // background: '#00709B',
        color: '#0047BA',
        padding: '0 2px',
    },
    '& label': {
        // color: '#AFB8CC',
        // borderRadius: 10,
    },
    '& .MuiInputBase-input': {
        color: '#27344B',
        background: '#FFFFFF',
        '&.Mui-disabled': {
            background: 'transparent',
        },
        '&:focus': {
            borderColor: '#0047BA',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E9E9E9',
            color: '#AFB8CC',
        },
        '&:hover fieldset': {
            borderColor: '#E9E9E9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#E9E9E9',
        },
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E0E0E0',
        borderRadius: '20px',
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #0047BA',
        borderRadius: '20px',
    },
});
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.cyan['90'],
        color: theme.palette.blue.strongDark,
        font: theme.typography.fontSize.$font_small_bold,
        // backgroundColor: '#0047BA',
        // color: theme.palette.white,
        // fontSize: '16px',
        border: 0,
        padding: '3px 5px',
    },
    [`&.${tableCellClasses.body}`]: {
        font: theme.typography.fontSize.$font_xsmall_regular,
        color: theme.palette.cmpBlack.doply,
        // fontSize: 14,
        // color: '#242424',
        // fontWeight: 'normal',
        padding: '3px 5px',
        height: theme.spacing(5.875),

        [theme.breakpoints.up('md')]: {
            '&:nth-of-type(2) button': {
                width: '160px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'block',
                wordBreak: 'normal',
                textAlign: 'left',
                fontSize: 14,
            },
            '&:nth-of-type(3)': {
                minWidth: '250px !important',
                maxWidth: '250px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: 14,
            },
            '&:nth-of-type(4)': {
                minWidth: '165px !important',
                maxWidth: '165px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                wordBreak: 'normal',
                textAlign: 'left',
            },
            '&:nth-of-type(5)': {
                minWidth: '160px !important',
                maxWidth: '160px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                wordBreak: 'normal',
                textAlign: 'left',
            },
        },
        [theme.breakpoints.up('lg')]: {
            '&:nth-of-type(2) button': {
                width: '210px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'block',
                wordBreak: 'normal',
                textAlign: 'left',
                fontSize: 14,
            },
            '&:nth-of-type(3)': {
                minWidth: '360px !important',
                maxWidth: '360px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: 14,
            },
            '&:nth-of-type(4)': {
                minWidth: '235px !important',
                maxWidth: '235px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                wordBreak: 'normal',
                textAlign: 'left',
            },
            '&:nth-of-type(5)': {
                minWidth: '180px !important',
                maxWidth: '180px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                wordBreak: 'normal',
                textAlign: 'left',
            },
        },
        [theme.breakpoints.up('xl')]: {
            '&:nth-of-type(2) button': {
                width: '250px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'block',
                wordBreak: 'normal',
                textAlign: 'left',
                fontSize: 14,
            },
            '&:nth-of-type(3)': {
                minWidth: '500px !important',
                maxWidth: '500px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: 14,
            },
            '&:nth-of-type(4)': {
                minWidth: '270px !important',
                maxWidth: '270px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                wordBreak: 'normal',
                textAlign: 'left',
            },
            '&:nth-of-type(5)': {
                minWidth: '200px !important',
                maxWidth: '200px !important',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                wordBreak: 'normal',
                textAlign: 'left',
            },
        },

        '&:first-of-type': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
            paddingLeft: 14,
        },

        '&:last-child': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
            paddingRight: 5,
        },
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.white,
    border: 0,
    paddingTop: '5px',
    height: '50px',
    boxShadow: 'inset 0 -2px #d7e5f8',
    // '&:hover': {
    //     backgroundColor: '#E5F5FC',
    // },
    // hide last border
    th: {
        marginLeft: 20,
    },
    '&:last-child td, &:last-child th': {},
}));

export const ColorButton = styled(Button)(() => ({
    background: '#0047BA',
    color: (theme) => theme.palette.white,
    '&:hover': {
        backgroundColor: '#0047BA',
        color: (theme) => theme.palette.white,
    },
}));

export const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#0047BA',
    },
    '& label.MuiFormLabel-filled,label.MuiInputLabel-shrink': {
        color: '#0047BA',
        padding: '0 2px',
    },
    '& label.MuiFormLabel-filled+div fieldset': {
        border: ' 1px solid #0047BA',
    },
    '& label.MuiInputLabel-shrink+div fieldset': {
        border: ' 1px solid #0047BA',
    },
    '& label+div fieldset': {
        '&:hover': {
            border: ' 1px solid #0047BA  !important',
        },
    },
    '& label': {},
    '& label.Mui-disabled': {
        color: '#A5A5A5',
    },
    '& label.Mui-disabled.MuiFormLabel-filled+div .MuiOutlinedInput-input': {
        color: '#000000 !important ',
        backgroundColor: '#FFFFFF',
    },
    '& label.Mui-disabled.MuiFormLabel-filled+div fieldset': {
        border: ' 1px solid #A5A5A5',
        color: '#000000',
    },

    '& .MuiInputBase-input': {
        color: '#000000',
        background: '#FFFFFF',
        '&.Mui-disabled': {
            backgroundColor: '#ffffff',
            color: '#000000',
        },
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
        color: '#000000',
        backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E9E9E9',
            color: '#000000',
        },
        '&:hover fieldset': {
            borderColor: '#E9E9E9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0047BA',
        },
    },
});
