import React from 'react';
import { useRecoilState } from 'recoil';
import Btn from 'components/button';
import { Box } from '@mui/material';
import GithubOnboardingStyles from 'pages/github-onboarding/style';
import { OrderPayload, toggleModal } from 'pages/github-onboarding/store';
import { commonStyles as style } from 'assets/style';

const TeamAndMembers = () => {
    const [order] = useRecoilState(OrderPayload);
    const [isOpen, setIsOpen] = useRecoilState(toggleModal);

    const modalClose = () =>
        setIsOpen({
            ...isOpen,
            isReposToMigrateOpen: false,
            isTeamsAndMembersOpen: false,
            isAssignReposOpen: false,
        });

    const handleSave = () => {
        modalClose();
    };

    return (
        <>
            <Box sx={GithubOnboardingStyles.modal.wrapper}>
                <Box sx={{ ...GithubOnboardingStyles.modal.contentWrapper, paddingTop: '10px' }}>Teams</Box>
                <Box className="footer" sx={GithubOnboardingStyles.modal.actionWrapper} gap={2}>
                    <Btn
                        className="custom-btn btn-disabled"
                        variant="contained"
                        disabled={order?.isCostApiLoading}
                        onClick={() => {
                            handleSave();
                        }}
                        data-testid="config-save-btn"
                        sx={style.modalSaveButton}
                    >
                        Save
                    </Btn>

                    <Btn
                        className="custom-btn"
                        onClick={modalClose}
                        variant="outlined"
                        sx={style.modalCancelButton}
                        layout="outline"
                        data-testid="config-cancel-btn"
                    >
                        Cancel
                    </Btn>
                </Box>
            </Box>
        </>
    );
};

export default React.memo(TeamAndMembers);
