import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { orderPayload, validVSForm, isNamespaceLoading } from 'pages/provisioning/virtual-service/store';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import useDebounce from 'pages/provisioning/virtual-service/hooks/use-debounce';
import InfoImg from 'components/info-img';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';

export const AksNamespace = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [validForm, setValidForm] = useRecoilState(validVSForm);
    const [helperText, setHelperText] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [validAksNamespace, setValidAksNamespace] = React.useState('');
    const [namespaceExists, setNamespaceExists] = React.useState(true);

    const [, setNamespaceLoading] = useRecoilState(isNamespaceLoading);

    const namespaceValidateApi = useMutation((payload) => axios.post(`${endPoints.virtualService.inventorynameSapceValidate}`, payload));
    const getistioGtWayUrlApi = useMutation(() => axios.get(`${endPoints.virtualService.istioGtWayUrl}?aksClusterName=${order?.aksCluster}`));

    const debouncedNamespaceValue = useDebounce(validAksNamespace, 500);
    const isValidNamespace = (namespace) => /^[a-zA-Z0-9_-]{3,64}$/.test(namespace);
    const hasValidParts = (namespace) => {
        if (!namespace) return false;
        const parts = namespace.includes('-') ? namespace.split('-') : namespace.split('_');
        return parts.length >= 4 && parts.length <= 5 && parts.slice(1).every((p) => /^[a-zA-Z0-9]{2,16}$/.test(p));
    };
    React.useEffect(() => {
        if (namespaceValidateApi?.isSuccess) {
            setNamespaceLoading(false);
            if (namespaceValidateApi?.data?.data?.results?.length > 0) {
                setValidForm({
                    ...validForm,
                    isNamespaceExists: true,
                });
                setNamespaceExists(true);
                setOrder({
                    ...order,
                    aksCluster: namespaceValidateApi?.data?.data?.results?.[0]?.CLUSTER,
                });
            } else {
                setValidForm({
                    ...validForm,
                    isNamespaceExists: false,
                });
                setNamespaceExists(false);
            }
        }
    }, [namespaceValidateApi?.isSuccess]);
    React.useEffect(() => {
        if (namespaceValidateApi?.isError) {
            setNamespaceLoading(false);
            setOrder({
                ...order,
                aksNamespace: '',
            });
        }
    }, [namespaceValidateApi?.isError]);

    React.useEffect(() => {
        if (namespaceValidateApi?.isLoading) {
            setNamespaceLoading(true);
        }
    }, [namespaceValidateApi?.isLoading]);

    React.useEffect(() => {
        setNamespaceExists(true);
        const isNamespaceValid = isValidNamespace(order.aksNamespace) && hasValidParts(order.aksNamespace) && order.aksNamespace.startsWith('pep-');
        if (debouncedNamespaceValue && order?.environment && order?.sector && order?.regionDetails && !helperText && order?.aksNamespace && isNamespaceValid) {
            const payload = {
                searchKey: debouncedNamespaceValue,
                catalogServiceCode: 'AZAKSNS',
                searchColumns: ['CLUSTER', 'NAMESPACE'],
                sortColumn: 'ORDER_ITEM_ID',
                sortType: 'DESC',
                pageNumber: 1,
                pageSize: 10,
                fromDate: '',
                toDate: '',
                region: order?.regionDetails?.data?.name,
                environment: order?.environment,
                requestor: '',
                applicationOwner: '',
            };
            namespaceValidateApi?.mutate(payload);
        }
    }, [debouncedNamespaceValue, order?.environment, order?.sector, order?.regionDetails, order?.aksNamespace, helperText?.toString()]);

    const handleValueChange = (value) => {
        if (value !== order?.aksNamespace) {
            setHelperText(false);
            setNamespaceExists(true);
            setOrder({
                ...order,
                aksNamespace: value,
                aksVirtualServiceUpdateCustomDomain: [{ aksIngressUrl: '', name: '', certificateExists: true }],
                aksVirtualService: [
                    {
                        virtualServiceType: null,
                        virtualServiceName: null,
                        hostNamesPrefix: [{ hostNamePrefix: null, isHostNameTouched: false }],
                        customDomainSelected: 'No',
                        customDomain: [
                            {
                                name: null,
                            },
                        ],
                        certificateExists: true,
                        corsPolicy: 'No',
                        aksService: [
                            {
                                serviceName: null,
                                servicePort: null,
                                contextPath: null,
                            },
                        ],
                        aksCors: {
                            allowedHeaders: [{ allowedHeader: null, isAllowedHeaderTouched: false }],
                            allowedOrigins: [{ allowedOrigin: null, isAllowedOriginTouched: false, isInvalid: false }],
                            exposeHeaders: [{ exposeHeader: null, isExposeHeaderTouched: false }],
                        },

                        isServiceNameInvalid: false,
                        isVirtualServiceNameInvalid: false,
                        isServicePortInvalid: false,
                        isHostNamePrefixInvalid: false,
                        isContextPathInvalid: false,
                    },
                ],
            });
        }
    };

    React.useEffect(() => {
        if (order?.aksNamespace) {
            const isNamespaceValid = isValidNamespace(order.aksNamespace);
            const isPartsValid = hasValidParts(order.aksNamespace);
            const startsWithPep = order.aksNamespace.startsWith('pep-');
            if (!startsWithPep) {
                setMessage('Namespace must start with "pep-" (e.g., "pep-[department]-[project]-[environment]-[optional]").');
                setHelperText(true);
                setValidForm({ ...validForm, isInvalidAksNamespace: true });
            } else if (!isPartsValid) {
                setMessage('Invalid namespace format. Format should be pep-[department]-[project]-[environment]-[optional].');
                // setMessage('Invalid namespace parts. Each part (after "pep-") must be 2-16 characters long and contain only letters and numbers.');
                setHelperText(true);
                setValidForm({ ...validForm, isInvalidAksNamespace: true });
            } else if (!isNamespaceValid) {
                setMessage(
                    'Invalid namespace format. It must be 3-64 characters long, containing only alphanumeric characters, hyphens (-), and underscores (_).'
                );
                setHelperText(true);
                setValidForm({ ...validForm, isInvalidAksNamespace: true });
            } else {
                setValidAksNamespace(order.aksNamespace);
                setMessage('');
                setHelperText(false);
                setValidForm({ ...validForm, isInvalidAksNamespace: false });
            }
        }
    }, [order?.aksNamespace]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.aksNamespace) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.aksNamespace && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.aksNamespace, order?.isTouched]);

    const getHelperText = () => {
        if (helperText) {
            return message;
        }
        if (!namespaceExists) {
            return 'Namespace does not exist';
        }
        return '';
    };
    React.useEffect(() => {
        if (getistioGtWayUrlApi?.data?.data) {
            setOrder({ ...order, istioGatewayUrl: getistioGtWayUrlApi?.data?.data?.istioGatewayUrl });
        }
    }, [getistioGtWayUrlApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.aksCluster && order?.aksNamespace && !helperText) {
            getistioGtWayUrlApi?.mutate();
        }
    }, [order?.aksCluster, order?.aksNamespace, helperText]);
    const helpInfoText = () => {
        const namespaceRules = [
            'Must start with "pep-" (e.g., "pep-[department]-[project]-[environment]-[optional]").',
            'Must be between 3 and 64 characters long.',
            'Can contain only alphanumeric characters, hyphens (-), and underscores (_).',
            'Each part after "pep-" must be 2-16 characters long and contain only letters and numbers.',
        ];
        return (
            <>
                <Box className={ProvisioningStyles.infoStyle.wrapper}>
                    <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                        <Grid container spacing={1}>
                            <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                    <span style={ProvisioningStyles.infoStyle.headingStyle}>AKS Namespace Name:</span>
                                </Typography>
                                <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                    A Kubernetes resource where the pods and deployments are logically grouped into namespaces to divide an AKS cluster.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={ProvisioningStyles.infoStyle.namingRule}>
                                <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                    <span style={{ fontWeight: 600 }}>Naming Convention Rules:</span>
                                </Typography>
                                {namespaceRules.map((rule, index) => (
                                    <Typography key={index} sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                        {index + 1}) {rule}
                                    </Typography>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </>
        );
    };
    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="aks-namespace-box">
            <CssTextField
                size="small"
                fullWidth
                autoComplete="off"
                label="AKS Namespace Name *"
                error={helperText || !namespaceExists}
                // helperText={helperText && <span>Invalid Namespace Name</span>}
                helperText={getHelperText()}
                value={order?.aksNamespace || ''}
                onChange={(e) => handleValueChange(e.target.value)}
                className={validateInput()}
                InputProps={{
                    endAdornment: <>{(namespaceValidateApi?.isLoading || getistioGtWayUrlApi?.isLoading) && <CircularProgress color="inherit" size={16} />}</>,
                }}
            />
            <InfoImg title={helpInfoText()} className="max-500" />
        </Box>
    );
};

export default React.memo(AksNamespace);
