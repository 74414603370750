import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, CircularProgress } from '@mui/material';
import { useAxios } from 'hooks';
import { riCatalogs } from 'store';
import { endPoints } from 'api/endpoints';
import { selectOrder } from 'pages/orders/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';
import { CssTextField } from 'pages/reference-implementation/components/select';
// import { RICatalogCode001, RICatalogCode001A } from 'pages/reference-implementation/utils/RICatalogCode';
import {
    OrderPayload,
    // uploadedFilesState,
    isProjectIdValid,
    isNonPrivilegedADGroupValid,
    isPrivilegedADGroupValid,
} from 'pages/reference-implementation/provisioning/RI001/store';
import { RIStyles } from 'pages/reference-implementation/Style';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { formOrderObjFromOrderDetails, resetOrder } from '../utils';

const PreviousOrderID = () => {
    const axios = useAxios();
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [cmpRiCatalogs] = useRecoilState(riCatalogs);
    // const [, setUploadedFiles] = useRecoilState(uploadedFilesState);
    const { catalogCode, operation } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [selectedOrderId, setSelectedOrderId] = useRecoilState(selectOrder);
    const [orderIdError, setOrderIdError] = React.useState('');
    const [, setProjectIdValid] = useRecoilState(isProjectIdValid);
    const [, setNonPrivilegedADGroupValid] = useRecoilState(isNonPrivilegedADGroupValid);
    const [, setPrivilegedADGroupValid] = useRecoilState(isPrivilegedADGroupValid);

    const data = cmpRiCatalogs?.find((item) => item['reference-implementation-id'] === catalogCode);

    const validateOrderId = (orderId) => {
        const isValid = /^(CMP|DEVX)[0-9]{6}$/.test(orderId);
        if (!isValid) {
            setOrderIdError('OrderId must start with "DEVX" followed by six digits.');
        } else {
            setOrderIdError('');
        }
    };

    const resetOrderState = () => {
        setOrder((prev) => ({ ...prev, selectedOrderId: '', ...resetOrder }));
        setSelectedOrderId({ orderId: '' });
        setOrderIdError('');
    };

    const updateOrderFromCatalog = async () => {
        if (!selectedOrderId?.orderId) return;
        setLoading(true);
        try {
            const orderDetails = await axios.get(`${endPoints?.riServices?.Jwt?.orderDetails}/${selectedOrderId?.orderId}`);
            if (!orderDetails?.data || !orderDetails?.data?.catalogServices) {
                setOrderIdError('OrderId not found');
                return;
            }
            if (selectedOrderId?.orderId && data?.name !== orderDetails?.data?.catalogServices[0]?.catalogType) {
                setOrderIdError(`OrderId must be related to ${data?.name}.`);
                return;
            }
            if (selectedOrderId?.orderId && !['Completed', 'Provisioning', 'Provisioning Completed']?.includes(orderDetails?.data?.currentStatus?.status)) {
                setOrderIdError(`OrderId must be either in Provisioning or Completed state.`);
                return;
            }
            const catalogServiceId = orderDetails?.data?.catalogServices[0]?.catalogServiceId;
            const orderObj = formOrderObjFromOrderDetails(catalogServiceId, orderDetails?.data);
            delete orderObj?.createdBy;
            // if ([RICatalogCode001, RICatalogCode001A]?.includes(catalogCode)) {
            //     setUploadedFiles(formUploadObjFromOrderDetails(catalogServiceId, orderDetails?.data));
            // }

            setProjectIdValid(true);
            setNonPrivilegedADGroupValid(true);
            setPrivilegedADGroupValid(true);
            setOrder((prev) => ({ ...prev, ...orderObj, orderId: selectedOrderId?.orderId }));
        } catch {
            setOrderIdError('OrderId not found');
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        if (!orderIdError) {
            setOrder({ ...order, orderId: null });
            updateOrderFromCatalog();
        }
    };

    React.useEffect(() => {
        if (!selectedOrderId?.orderId || /^(CMP|DEVX)[0-9]{6}$/.test(selectedOrderId?.orderId)) {
            setOrderIdError('');
        } else {
            setOrderIdError('OrderId must start with "DEVX" followed by numbers up to 6 digits.');
        }
    }, [selectedOrderId?.orderId]);

    React.useEffect(() => {
        if (catalogCode && operation !== 'edit' && operation !== 'preview') {
            resetOrderState();
        }
    }, [catalogCode, operation]);

    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <CssTextField
                    data-testid="pre-order-id"
                    label={<InputLabel label="Enter Previous Order ID" />}
                    size="small"
                    fullWidth
                    autoComplete="off"
                    error={Boolean(orderIdError)}
                    helperText={orderIdError || ''}
                    value={selectedOrderId?.orderId || ''}
                    onChange={(e) => {
                        const newOrderId = e.target.value;
                        setOrder((prev) => ({ ...prev, selectedOrderId: newOrderId }));
                        setSelectedOrderId({ orderId: newOrderId });
                        validateOrderId(newOrderId);
                    }}
                    // onBlur={() => !selectedOrderId?.orderId && resetOrderState()}
                    onKeyDown={(event) => event.key === 'Enter' && handleSearch()}
                    InputProps={{
                        endAdornment: (
                            <>
                                {selectedOrderId?.orderId && (
                                    <IconButton aria-label="close" color="inherit" size="small" onClick={resetOrderState}>
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                )}
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleSearch();
                                    }}
                                    data-testid="search-btn"
                                >
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} data-testid="search-loader" />
                                    ) : (
                                        <SearchIcon sx={RIStyles?.form?.searchIcon} />
                                    )}
                                </IconButton>
                            </>
                        ),
                    }}
                />
                <HelpImg title="Please search your previous OrderId in order to populate your data. Once your data is populated, you can submit the order after modifying fields such as environment and AD groups, etc." />
            </Box>
        </>
    );
};

export default React.memo(PreviousOrderID);
