import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAxios } from 'hooks';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { environmentDetails, isMandatoryTagsFullfilled, sspSubmitOrder, toggleSspModels, showPreviewStack } from 'pages/provisioning/store/eip-stack';
import ProvisioningStyles from 'pages/provisioning/eip-stack/style';
import { cmpRouteState, catalogs } from 'store';
import { formOrderObjFromCart, formOrderObjFromOrderDetails, resetEnvironmentDetails, resetOrder, resetToggleSspModal } from './utils';

const ProvisioningHeader = React.lazy(() => import('pages/provisioning/eip-stack/ProvisioningHeader'));
const ApplicationDetails = React.lazy(() => import('pages/provisioning/eip-stack/ApplicationDetails'));
const SspForm = React.lazy(() => import('pages/provisioning/eip-stack/SspForm'));
const Tags = React.lazy(() => import('pages/provisioning/eip-stack/Tags'));
const AddedToCartConfirmModal = React.lazy(() => import('pages/provisioning/eip-stack/AddedToCartConfirmModal'));
const UpdatedToCartConfirmModal = React.lazy(() => import('pages/provisioning/eip-stack/UpdatedToCartConfirmModal'));
const PreviewStack = React.lazy(() => import('pages/provisioning/eip-stack/previewStack'));
const ShowCostDetails = React.lazy(() => import('pages/provisioning/eip-stack/ShowCostDetails'));
const DeleteResourceModal = React.lazy(() => import('pages/provisioning/eip-stack/DeleteResourceModal'));

const EipStackProvisioningPage = () => {
    const [isLoading, setLoading] = React.useState(true);
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [cmpRoute] = useRecoilState(cmpRouteState);
    const [, resetEnvDetails] = useRecoilState(environmentDetails);
    const [, resetIsMandatoryTagsFullfilled] = useRecoilState(isMandatoryTagsFullfilled);
    const [, resetToggleSspModels] = useRecoilState(toggleSspModels);
    const [showPreview, setshowPreview] = useRecoilState(showPreviewStack);
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const axios = useAxios();
    const preLocation = cmpRoute?.currentLocation;

    async function getCosts() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}/costs`);
        return data;
    }
    const getTags = (orderObj) => {
        const tagsqc = {};
        orderObj?.tags?.forEach((tag) => {
            if (tag.name === 'Sector') {
                tagsqc[tag.name] = orderObj?.sector;
            } else if (tag.name === 'BillingEntity') {
                tagsqc[tag.name] = orderObj?.billingEntity;
            } else if (tag.name === 'CostCenter') {
                tagsqc[tag.name] = orderObj?.hCodeDetails?.label;
            } else {
                tagsqc[tag.name] = tag.defaultValue;
            }
        });
        return tagsqc;
    };
    async function checkCatalog() {
        const data = cmpCatalogs?.find((item) => item?.catalogDefinitionId === catalogCode);
        const costs = await getCosts();
        if (preLocation?.includes('/eiap-save')) {
            setOrder((previous) => ({
                ...previous,
                catalogCode,
                validationStatus: 'clearValidation',
                isTouched: false,
                tagsQc: previous?.tags?.length && getTags(previous),
                emptyCost: costs,
            }));
        } else if (cartItemId && operation && !catalogServiceId) {
            const { data: cartItem } = await axios.get(`${endPoints.cart.getCartItem}/${cartItemId}`);
            const orderObj = await formOrderObjFromCart(cartItem);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                config: {
                    components: data?.components,
                },
                emptyCost: costs,
            }));
        } else if (cartItemId && operation && catalogServiceId) {
            const { data: orderDetails } = await axios.get(`${endPoints.order.orderDetails}/${cartItemId}`);
            const orderObj = await formOrderObjFromOrderDetails(catalogServiceId, orderDetails);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                config: {
                    components: data?.components,
                },
            }));
        } else {
            setOrder((previous) => ({
                ...previous,
                catalogCode,
                catalogType: data?.displayName,
                cloudProvider: data?.cloudProvider,
                cloudService: data?.cloudService,
                validationStatus: 'clearValidation',
                isTouched: false,
                emptyCost: costs,
                config: {
                    components: data?.components,
                },
            }));
        }

        setLoading(false);
    }
    const reset = () => {
        if (catalogCode && !preLocation?.includes('/eiap-save')) {
            setOrder({ ...order, ...resetOrder });
            resetEnvDetails(resetEnvironmentDetails);
            resetIsMandatoryTagsFullfilled(false);
            resetToggleSspModels(resetToggleSspModal);
        }
    };
    React.useEffect(() => {
        if (cmpCatalogs?.length && catalogCode) {
            setLoading(true);
            reset();
            checkCatalog();
        }
    }, [cmpCatalogs, catalogCode]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        if (preLocation?.includes('/eiap-save')) {
            setshowPreview(showPreview);
        } else {
            setshowPreview(false);
        }
        return () => reset();
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <Helmet>
                <title>App Microservices Stack Provisioning - DevX Infra</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading....
                        </Typography>
                    </Box>
                }
            >
                {isLoading && (
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading....
                        </Typography>
                    </Box>
                )}
                {!isLoading && (
                    <Box sx={ProvisioningStyles.provisioningBox}>
                        <ProvisioningHeader />
                        <Box sx={ProvisioningStyles.sspform_wrapper}>{showPreview === true ? <PreviewStack /> : <SspForm />}</Box>
                        <br />
                        <ApplicationDetails />
                        <Tags />
                        <AddedToCartConfirmModal />
                        <UpdatedToCartConfirmModal />
                        <ShowCostDetails />
                        <DeleteResourceModal />
                    </Box>
                )}
            </Suspense>
        </>
    );
};

export default EipStackProvisioningPage;
