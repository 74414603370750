import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState, useRecoilValue } from 'recoil';
import { OrderPayload, isAppShortNameValid, isAppNameValid } from 'pages/service-account-creation/store';
import ProvisioningStyles from 'pages/service-account-creation/style';
// import { InputInfo } from 'pages/reference-implementation/components/input-info';
import { InputInfo } from 'pages/service-account-creation/components/input-info';

export const ApplicationShortName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setAppShortNameVaild] = useRecoilState(isAppShortNameValid);
    const appNameValid = useRecoilValue(isAppNameValid);
    const [errorText, setErrorText] = React.useState('');
    const [helperText, setHelperText] = React.useState(false);

    const setAppShortName = (appShortName = null) => {
        setOrder({
            ...order,
            appShortName,
        });
    };

    const hasWhiteSpace = (value) => {
        return value?.indexOf(' ') >= 0;
    };
    React.useEffect(() => {
        if (
            order?.appShortName &&
            order?.appShortName !== null &&
            order?.appShortName !== '' &&
            order?.appShortName !== undefined &&
            (order?.appShortName?.length < 2 || order?.appShortName?.length > 8)
        ) {
            setHelperText(true);
            setErrorText('Application short name must have min 2 chars and max 8');
            setAppShortNameVaild(false);
        } else {
            if (hasWhiteSpace(order?.appShortName)) {
                setHelperText(true);
                setErrorText('Space are not allowed in application short name.');
                return setAppShortNameVaild(false);
            }
            setHelperText(false);
            setAppShortNameVaild(true);
        }
        return null;
    }, [order?.appShortName]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.appShortName) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.appShortName && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.appShortName, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="Application Short Name *"
                error={helperText}
                helperText={helperText && <span>{errorText}</span>}
                value={order?.appShortName || ''}
                onChange={(e) => {
                    setAppShortName(e.target.value);
                }}
                disabled={!appNameValid || !order?.applicationName}
                className={validateInput()}
            />
            <InputInfo
                title={
                    <Grid item xs>
                        <Typography sx={ProvisioningStyles.infoLabel}>1) Application short name should be unique and should have 2-8 chars.</Typography>
                        <Typography sx={ProvisioningStyles.infoLabel}>2) Please select Account type first. </Typography>
                    </Grid>
                }
            />
        </Box>
    );
};

export default ApplicationShortName;
