import { atom } from 'recoil';

export const ArchImgUrl = atom({
    key: 'RI001-ArchImgUrl',
    default: undefined,
});

export const isExpandArchDiagram = atom({
    key: 'RI001-isExpandArchDiagram',
    default: false,
});

export const OrderPayload = atom({
    key: 'RI001-OrderPayload',
    default: {
        catalogCode: null,
        projectId: null,
        projectDetails: null,
        businessUnit: null,
        sector: null,
        division: null,
        environment: null,
        dataClassification: null,
        budgetCode: null,
        budgetCodeDetails: null,
        billingInfo: null,
        nonPrivilegedADGroupList: [{ readerValue: '' }],
        // privilegedADGroupList: [{ contributorValue: '' }],
        environmentDetails: null,
        adGroup: 'Yes',
        // privilegedADGroup: null,
        nonPrivilegedADGroup: null,
        businessAppService: 'Yes',
        businessApp: null,
        appService: null,
        isTouched: false,
        estimatedCost: null,
        appOwner: null,
        appOwnerDetails: null,
        businessContact: null,
        businessContactDetails: null,
        // privilegedADGroupOwner: null,
        // privilegedADGroupOwnerDetails: null,
        nonPrivilegedADGroupOwner: null,
        nonPrivilegedADGroupOwnerDetails: null,
        applicationName: null,
        appShortName: null,
        isaNumber: null,
        deletedFiles: [],
        iteAppService: null,
    },
});

export const uploadedFilesState = atom({
    key: 'RI001-uploadedFilesState',
    default: [],
});

export const isGetAppShortNameLoading = atom({
    key: 'RI001-isGetAppShortNameLoading',
    default: false,
});

export const isAppNameValid = atom({
    key: 'RI001-isAppNameValid',
    default: true,
});

export const isAppShortNameValid = atom({
    key: 'RI001-isAppShortNameValid',
    default: false,
});
export const isAppShortNameExist = atom({
    key: 'RI001-isAppShortNameExist',
    default: false,
});

export const isBusinessNameValid = atom({
    key: 'RI001-isBusinessNameValid',
    default: true,
});

export const isBudgetCodeValid = atom({
    key: 'RI001-isBudgetCodeValid',
    default: true,
});

export const isBillingInfoValid = atom({
    key: 'RI001-isBillingInfoValid',
    default: true,
});

export const isProjectIdValid = atom({
    key: 'RI001-isProjectIdValid',
    default: false,
});

export const adGroupErrorMsg = atom({
    key: 'RI001-adGroupErrorMsg',
    default: false,
});

export const contributorErrorMsg = atom({
    key: 'RI001-contributorErrorMsg',
    default: false,
});

export const isADGroupValid = atom({
    key: 'RI001-isADGroupValid',
    default: true,
});

export const isBusinessAppValid = atom({
    key: 'RI001-isBusinessAppValid',
    default: false,
});

export const isNonPrivilegedADGroupValid = atom({
    key: 'RI001-isNonPrivilegedADGroupValid',
    default: false,
});

export const isPrivilegedADGroupValid = atom({
    key: 'RI001-isPrivilegedADGroupValid',
    default: false,
});

export const costEstimateState = atom({
    key: 'RI001-costEstimateState',
    default: null,
});

export const toggleModal = atom({
    key: 'RI001-toggleModal',
    default: {
        isADGroupModalOpen: false,
        isNonPrivilegedADGroupOpen: false,
        isPrivilegedADGroupOpen: false,
        isCost: { costType: '', isCostOpen: false },
    },
});

export const showPreviewOrder = atom({
    key: 'RI001-showPreviewOrder',
    default: false,
});

export const previewDetails = atom({
    key: 'RI001-previewdetails',
    default: null,
});
export const previewApiLoading = atom({
    key: 'RI001-previewApiLoading',
    default: false,
});
export const isSectorDisabled = atom({
    key: 'RI001-isSectorDisabled',
    default: true,
});
export const isBusinessUnitDisabled = atom({
    key: 'RI001-isBusinessUnitDisabled',
    default: true,
});
export const isHcodeDisabled = atom({
    key: 'RI001-isHcodeDisabled',
    default: true,
});
export const previewOrderError = atom({
    key: 'RI001-previewError',
    default: {
        isPreviewError: false,
        message: '',
    },
});
export const isValidISANumber = atom({
    key: 'RI001-isValidISANumber',
    default: false,
});
