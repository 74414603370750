import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/github-onboarding/store';
import GithubOnboardingStyles from 'pages/github-onboarding/style';
import HelpImg from '../helpImg';

const CostCode = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);

    const setApplicationName = (cmpHCodeValue = null) => {
        setOrder({
            ...order,
            customProperties: {
                ...order.customProperties,
                cmpHCode: cmpHCodeValue,
            },
        });
    };

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="cost-code">
            <CssTextField
                data-testid="cost-code-text"
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                disabled={!order.customProperties.capability}
                autoComplete="off"
                label="Cost Center Code *"
                value={order?.customProperties?.cmpHCode || ''}
                onChange={(e) => setApplicationName(e.target.value)}
                onKeyDown={(event) => {
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (event.target.value || event.target.value !== '')) {
                        setApplicationName();
                    }
                }}
            />{' '}
            <HelpImg
                maxWidth="500px"
                title={
                    <>
                        <Box sx={GithubOnboardingStyles.helpImgStyle.wrapper}>
                            <Box sx={GithubOnboardingStyles.helpImgStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography sx={GithubOnboardingStyles.helpImgStyle.typographyStyle}>
                                            Please make sure that Cost Center Code is correct
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Box>
    );
};

export default React.memo(CostCode);
