import React from 'react';
import { Box, Stack, Grow, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState, useSetRecoilState } from 'recoil';
// import { FaTrashAlt } from 'react-icons/fa';
import deleteIcon from 'assets/images/delete_icon.svg';
import { orderPayload, selectedVSService, multiFieldValidation } from 'pages/provisioning/virtual-service/store';
import { addMultiContextPath, removeMultiContextPath, updateMultiSelection } from 'pages/provisioning/virtual-service/store/selectors';
import Btn from 'components/button';
import ConfirmDialog from 'pages/provisioning/virtual-service/dialog-box/ConfirmDialog';
import InfoImg from 'components/info-img';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';
import { commonStyles as style } from 'assets/style';

const MultiContextPath = () => {
    const [isModalOpen, setModal] = React.useState(false);
    const [confirmModal, setConfirmModal] = React.useState(null);
    const [helperText, setHelperText] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(0);

    const [order] = useRecoilState(orderPayload);
    const [multiValdation, setMultiValidation] = useRecoilState(multiFieldValidation);
    const [selectedVS] = useRecoilState(selectedVSService);
    const addContextPath = useSetRecoilState(addMultiContextPath);
    const removeContextPath = useSetRecoilState(removeMultiContextPath);
    const updateMultiSelectionField = useSetRecoilState(updateMultiSelection);

    React.useEffect(() => {
        if (
            order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath !== null &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath !== '' &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath !== undefined &&
            !/^(?!.*\/\/)[a-zA-Z0-9/_-]{1,255}(?<![_-])$/.test(order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath)
        ) {
            if (order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath?.charAt(0) !== '/') {
                setHelperText(true);
                setMultiValidation({
                    ...multiValdation,
                    contextPath: multiValdation?.contextPath?.map((item, index) => {
                        if (index === activeIndex) {
                            return { ...item, isInvalid: true, message: 'Context path should start with /' };
                        }
                        return item;
                    }),
                });
                return;
            }
            setHelperText(true);
            setMultiValidation({
                ...multiValdation,
                contextPath: multiValdation?.contextPath?.map((item, index) => {
                    if (index === activeIndex) {
                        return {
                            ...item,
                            isInvalid: true,
                            message: 'Context Path should have alpha-numeric value and can contain slash(/), underscore(_) and hyphen(-) as special chars.',
                        };
                    }
                    return item;
                }),
            });
        } else {
            if (
                order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath !== null &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath !== '' &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath !== undefined &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath?.charAt(0) !== '/'
            ) {
                setHelperText(true);
                setMultiValidation({
                    ...multiValdation,
                    contextPath: multiValdation?.contextPath?.map((item, index) => {
                        if (index === activeIndex) {
                            return { ...item, isInvalid: true, message: 'Context path should start with /' };
                        }
                        return item;
                    }),
                });
                return;
            }
            setHelperText(false);
            setMultiValidation({
                ...multiValdation,
                contextPath: multiValdation?.contextPath?.map((item, index) => {
                    if (index === activeIndex) {
                        return { ...item, isInvalid: false, isTouched: false, message: '' };
                    }
                    return item;
                }),
            });
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex]?.contextPath]);

    const handleValueChange = (value, index, field) => {
        setActiveIndex(index);
        updateMultiSelectionField({ value, index, selectedVS, field });
    };

    const handleAction = (type) => {
        if (type === 'yes') {
            removeContextPath({ confirmModal, selectedVS });
        }
        setConfirmModal(null);
        setModal(false);
    };

    const nullIndexes = order?.aksVirtualService?.[selectedVS]?.aksService?.reduce((indexes, service, index) => {
        if (service?.contextPath === null) {
            indexes.push(index);
        }
        return indexes;
    }, []);

    React.useEffect(() => {
        if (order?.isConfigureTouched) {
            setMultiValidation({
                ...multiValdation,
                contextPath: multiValdation?.contextPath?.map((item, index) => {
                    if (nullIndexes.includes(index)) {
                        return { ...item, isTouched: true };
                    }
                    return { ...item, isTouched: false };
                }),
            });
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.length, order?.isConfigureTouched]);

    const checkDuplicate = (service) => {
        const serviceValues = order?.aksVirtualService?.[selectedVS]?.aksService;
        const contextPathList = serviceValues?.map((item) => item?.contextPath);
        const duplicateContextPath = contextPathList?.filter((item, index) => contextPathList?.indexOf(item) !== index);
        if (service?.contextPath) {
            return duplicateContextPath?.indexOf(service?.contextPath) > -1;
        }
        return false;
    };

    return (
        <>
            {order?.aksVirtualService?.[selectedVS]?.aksService?.map((service, index) => (
                <React.Fragment key={`multi-context-path-${index}`}>
                    <Grow
                        in={order?.aksVirtualService?.[selectedVS]?.aksService?.length > 0}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(order?.aksVirtualService?.[selectedVS]?.aksService?.length > 0 ? { timeout: 500 } : {})}
                    >
                        <Stack direction="row" spacing={2} key={index.toString()} alignItems="baseline" className="multi-context-stack-container">
                            <Box display="flex" alignItems="center" gap={1} width="100%" data-testid={`context-path-box-${index}`}>
                                <CssTextField
                                    size="small"
                                    fullWidth
                                    autoComplete="off"
                                    label="Context Path *"
                                    error={
                                        (helperText && activeIndex === index) ||
                                        multiValdation?.contextPath?.[index]?.isInvalid ||
                                        multiValdation?.contextPath?.[index]?.isTouched ||
                                        checkDuplicate(service)
                                    }
                                    helperText={
                                        ((multiValdation?.contextPath?.[index]?.isInvalid || multiValdation?.contextPath?.[index]?.isTouched) &&
                                            multiValdation?.contextPath?.[index]?.message) ||
                                        (checkDuplicate(service) && activeIndex === index && 'Duplicate context path not allowed.')
                                    }
                                    value={service?.contextPath || ''}
                                    onChange={(e) => handleValueChange(e.target.value, index, 'contextPath')}
                                    className={service?.isInvalid || checkDuplicate(service) ? 'validate' : ''}
                                />
                                <InfoImg
                                    title={
                                        <>
                                            <Box className={ProvisioningStyles.infoStyle.wrapper}>
                                                <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                                            <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                <span style={ProvisioningStyles.infoStyle.headingStyle}>Context Path:</span>
                                                            </Typography>
                                                            <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                A context path in a virtual service is the root URL path that routes requests to a specific
                                                                backend service.
                                                            </Typography>
                                                            <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                <span style={ProvisioningStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                                            </Typography>
                                                            <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                1) Context path should start with /.
                                                            </Typography>
                                                            <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                2) It can have alpha-numeric value.
                                                            </Typography>
                                                            <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                3) It can only contain slash(/), underscore(_) and hyphen(-) as special chars.
                                                            </Typography>
                                                        </Grid>{' '}
                                                        <Grid item xs={12} sx={ProvisioningStyles.infoStyle.bottomDividerRule}>
                                                            <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                <span style={ProvisioningStyles.infoStyle.headingStyle}>Example: </span>
                                                                /v1/authorize-client_id
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </>
                                    }
                                />
                            </Box>
                            {order?.aksVirtualService?.[selectedVS]?.aksService?.length > 1 && (
                                <Box>
                                    <Box style={ProvisioningStyles.vsType.deleteWrapper}>
                                        {order?.aksVirtualService?.[selectedVS]?.aksService?.length > 1 ? (
                                            <Box
                                                data-testid={`remove-context-path-${index}`}
                                                style={ProvisioningStyles.vsType.delete}
                                                onClick={() => {
                                                    setConfirmModal({ service, index });
                                                    setModal(true);
                                                }}
                                            >
                                                <img height="16px" width="16px" src={deleteIcon} alt="deleteIcon" />
                                            </Box>
                                        ) : /* <FaTrashAlt
                                                data-testid={`remove-context-path-${index}`}
                                                style={ProvisioningStyles.vsType.delete}
                                                onClick={() => {
                                                    setConfirmModal({ service, index });
                                                    setModal(true);
                                                }}
                                                className="cursor-pointer"
                                            /> */
                                        null}
                                    </Box>
                                </Box>
                            )}
                        </Stack>
                    </Grow>
                    {index === order?.aksVirtualService?.[selectedVS]?.aksService?.length - 1 &&
                    order?.aksVirtualService?.[selectedVS]?.aksService?.length < 10 ? (
                        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <Btn
                                variant="contained"
                                sx={style?.serviceTagBtn}
                                disableFocusRipple
                                disableRipple
                                onClick={() => addContextPath({ count: 1, selectedVS })}
                                disabled={false}
                                data-testid="add-context-path"
                            >
                                Add Context Path
                            </Btn>
                        </Box>
                    ) : null}
                </React.Fragment>
            ))}
            {isModalOpen && (
                <ConfirmDialog
                    isModalOpen={isModalOpen}
                    title="Are you sure you want to remove Context Path?"
                    primaryBtnText="Yes"
                    secondaryBtnText="No"
                    primaryBtnAction={() => handleAction('yes')}
                    secondaryBtnAction={() => handleAction('no')}
                />
            )}
        </>
    );
};

export default MultiContextPath;
