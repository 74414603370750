import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@mui/material';

const PageNotFound = () => (
    <>
        <Helmet>
            <title>My Approvals - DevX Infra</title>
        </Helmet>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', heigth: '100%' }}>
            <Typography variant="body2">404 | Page Not Found</Typography>
        </Box>
    </>
);

export default PageNotFound;
