import React from 'react';
import { Box, Pagination, Table, TableBody, TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import PropTypes from 'prop-types';
import GithubOnboardingStyles from '../../../../style';
import usePagination from '../../../../hooks/usePagination';

const AssignReposList = ({ reposList, handleRemoveRepo, teamName }) => {
    const { page, totalPages, currentPageData, handleChangePage } = usePagination(reposList);

    return (
        <TableContainer sx={{ marginTop: 3 }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ padding: '2px', fontWeight: '600' }}>Repo Name</TableCell>
                        <TableCell sx={{ padding: '2px', fontWeight: '600', textAlign: 'right' }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentPageData.map((repo, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{ width: '30%', padding: '2px' }}>{repo}</TableCell>
                            <TableCell sx={{ width: '30%', padding: '2px', textAlign: 'right' }}>
                                <HighlightOffOutlinedIcon
                                    data-testid={`repo-remove-${index}`}
                                    onClick={() => handleRemoveRepo(teamName, repo)}
                                    sx={{ cursor: 'pointer', fontSize: '16px', top: 3, position: 'relative' }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {totalPages > 1 && (
                <Box sx={GithubOnboardingStyles.paginationRow}>
                    <Pagination color="primary" count={totalPages} page={page} onChange={handleChangePage} />
                </Box>
            )}
        </TableContainer>
    );
};

AssignReposList.propTypes = {
    reposList: PropTypes.array,
    handleRemoveRepo: PropTypes.func,
    teamName: PropTypes.string,
};

AssignReposList.defaultProps = {
    reposList: [],
    handleRemoveRepo: undefined,
    teamName: '',
};

export default React.memo(AssignReposList);
