import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles, { CustomRadioGroup, CustomRadio } from 'pages/provisioning/event-hub/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const GeoReplication = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);

    const setGeoReplicationValue = (isGeoReplication = 'yes') => {
        setOrder({
            ...order,
            isGeoReplication,
        });
    };

    const handleOnSelectChange = (selected) => {
        setGeoReplicationValue(selected);
    };

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '37.13px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', font: (theme) => theme.typography.fontSize.$font_xsmall_regular }} id="georeplicationRadioGrp">
                    Enable Geo Replication
                </Box>
                <CustomRadioGroup
                    aria-label="geoReplication"
                    name="geoReplicationRadioBtnGroup"
                    row
                    value={order?.isGeoReplication || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" disabled={order?.nsExist === 'yes' && order?.namespaceName} />
                    <FormControlLabel
                        value="no"
                        sx={ProvisioningStyles.radioFields.radioBtnNoForm}
                        control={<CustomRadio />}
                        label="No"
                        disabled={order?.nsExist === 'yes' && order?.namespaceName}
                    />
                </CustomRadioGroup>
            </FormControl>
            <HelpImg title="Geo Replication region should not be equal to primary region." />
        </Stack>
    );
};

export default GeoReplication;
