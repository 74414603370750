import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles, { CustomRadioGroup, CustomRadio } from 'pages/provisioning/event-hub/style';

const NSExist = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);

    const setNSExistValue = (nsExist) => {
        setOrder({
            ...order,
            nsExist,
            namespaceName: null,
            performance: 'Standard',
            isAutoInflate: 'no',
            autoInflateMaxThroughputUnits: null,
            throughputUnits: '1',
            processingUnits: '1',
            eventHubDetails: [
                {
                    eventHubName: '',
                    partitionCount: 1,
                    retention: null,
                    isEventHubTouched: false,
                    consumerGroupList: [{ value: '' }],
                },
            ],
            cartMessage: false,
            isTouched: false,
        });
    };

    const handleOnSelectChange = (selected) => {
        setNSExistValue(selected);
    };

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '37.13px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', font: (theme) => theme.typography.fontSize.$font_xsmall_regular }} id="nsExistRadioGrp">
                    Namespace Exist?
                </Box>
                <CustomRadioGroup
                    aria-label="nsExist"
                    name="nsExistRadioBtnGroup"
                    row
                    value={order?.nsExist || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
                    <FormControlLabel value="no" sx={ProvisioningStyles.radioFields.radioBtnNoForm} control={<CustomRadio />} label="No" />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default NSExist;
