import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { Styles } from './style';

const FormCard = ({ title, children, padding }) => {
    return (
        <Box sx={Styles.formCard.paper} padding={padding}>
            {title && (
                <>
                    <Box sx={Styles.formCard.wrapper}>
                        <Stack direction="column">
                            <Typography sx={Styles.formCard.heading} variant="subtitle1">
                                <Stack direction="row">{title}</Stack>
                            </Typography>
                            {title === 'SERVICE RESOURCES' && (
                                <>
                                    <Typography sx={{ fontSize: '10px' }}>Select services that are required for your project</Typography>
                                </>
                            )}
                        </Stack>
                    </Box>
                    <Divider variant="middle" />
                </>
            )}
            <Box display="flex" flexWrap="wrap" sx={{ margin: '16px' }}>
                {children}
            </Box>
        </Box>
    );
};

FormCard.propTypes = {
    title: PropTypes.any,
    children: PropTypes.element,
    padding: PropTypes.any,
};

FormCard.defaultProps = {
    title: undefined,
    children: <></>,
    padding: '10px',
};

export default React.memo(FormCard);
