import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm, toggleModal } from 'pages/github-onboarding/store';
import GithubOnboardingStyles, { CustomRadioGroup, CustomRadio } from 'pages/github-onboarding/style';
import defaultOrderValues, { defaultToggleModals, defaultValidOnboardingForm } from 'pages/github-onboarding/store/defaultValues';

const AvailableADOProject = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setValidProvisionForm] = useRecoilState(validOnboardingForm);
    const [, setToggleModal] = useRecoilState(toggleModal);

    const setAvailableADOProject = (isADOProjectVal = 'no') => {
        const { customProperties, ...rest } = defaultOrderValues;
        const { sector, ...customRest } = customProperties;

        if (isADOProjectVal === 'no') {
            setOrder({
                customProperties: {
                    ...customRest,
                    sector: order.customProperties.sector,
                },
                ...rest,
                existingProject: false,
            });
            setValidProvisionForm(defaultValidOnboardingForm);
        } else {
            setOrder({
                ...order,
                existingProject: true,
            });
            setValidProvisionForm((prevState) => ({
                ...prevState,
                isProjectExist: true,
            }));
        }

        setToggleModal(defaultToggleModals);
    };

    const handleOnSelectChange = (selected) => {
        setAvailableADOProject(selected);
    };

    return (
        <Stack direction="row" spacing={1} sx={GithubOnboardingStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '36px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ ...GithubOnboardingStyles.radioFields.radioText, opacity: '0.5' }} id="autoinflateRadioGrp">
                    Do you have ADO Project Name?
                </Box>
                <CustomRadioGroup
                    data-testid="available-ado-project"
                    aria-label="availableADOProject"
                    name="availableADOProjectRadioBtnGroup"
                    row
                    value={order.existingProject ? 'yes' : 'no'}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
                    <FormControlLabel value="no" control={<CustomRadio />} label="No" />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default React.memo(AvailableADOProject);
