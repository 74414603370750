import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload } from 'pages/github-onboarding/store';

export const Sector = () => {
    const resetOrderPayload = useResetRecoilState(OrderPayload);
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);

    const sectorApi = useMutation(() => axios.get(endPoints.day2Operation.ProjectOnboarding.sectors));

    const setSector = (sectorVal) => {
        resetOrderPayload();
        setOrder((prevState) => ({
            ...prevState,
            customProperties: {
                ...prevState.customProperties,
                sector: sectorVal,
            },
        }));
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (sector) => options.find((option) => sector === option.value);

    const handleOnSelectChange = (selected) => setSector(selected.value);

    React.useEffect(() => {
        if (sectorApi?.isSuccess) {
            setOptions(sectorApi?.data?.data?.sectors?.map((app) => ({ label: app?.name, value: app?.name })));
        }
    }, [sectorApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.sector) {
            setOrder({ ...order, customProperties: { ...order.customProperties, sector: options?.[0].value } });
        }
        if (options && options?.length && order?.sector) {
            const sector = isExistsInOptions(order?.sector);
            setOrder({ ...order, customProperties: { ...order.customProperties, sector: sector?.value } });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        sectorApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.customProperties?.sector && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.customProperties?.sector, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} marginRight={3.4}>
            <Select
                dataTestId="sector-autocomplete"
                key="sector"
                label="Sector *"
                value={order?.customProperties?.sector || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={sectorApi.isLoading || order?.isToolsProject}
                isLoading={sectorApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Sector);
