import React from 'react';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import { ProjectOnboardCatalogDefinitionId } from 'utils/catalogDefinitionId';

const AdoAlert = () => (
    <Alert severity="info" color="info">
        {"If you don't have any ADO Project, please "}
        <Link to={`/project-onboarding/${ProjectOnboardCatalogDefinitionId[0]}`} style={{ color: '#104e8b', textDecoration: 'none' }}>
            create new
        </Link>
        {' one.'}
    </Alert>
);

export default React.memo(AdoAlert);
