import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { RIStyles } from 'pages/reference-implementation/Style';
import Select from 'pages/reference-implementation/components/select';
import Btn from 'components/button';
import { useMutation } from 'react-query';
import { commonStyles as style } from 'assets/style';
import axios, { endPoints } from 'api/request';

const ServiceBus = (props) => {
    const { resource, handleDrawerAction } = props;
    const [formData, setFormData] = useState({
        serviceBusCapacity: resource?.configureData?.serviceBusCapacity || '',
    });
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const sizeGetApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.azureServiceBus}`));

    const validateForm = () => {
        const newErrors = {};

        // Validate serviceBus Capacity
        if (!formData.serviceBusCapacity) {
            newErrors.serviceBusCapacity = { type: 'required', message: 'service Bus Capacity is required' };
        }

        setErrors(newErrors);
        return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
    };

    const handleOnSelectChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
        setTouched({
            ...touched,
            [key]: true,
        });
    };

    const handleSubmit = () => {
        // Set all fields as touched to trigger validation errors display
        setTouched({
            serviceBusCapacity: true,
        });
        const checkFormValid = validateForm();
        handleDrawerAction('save', formData, checkFormValid);
    };

    const shouldShowError = (field) => {
        return touched[field] && !!errors[field];
    };

    const saveButton = () => (
        <Btn className="custom-btn" sx={style.modalSaveButton} variant="contained" color="cmpPrimary" onClick={() => handleSubmit()} data-testid="save-btn">
            Save
        </Btn>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            variant="outlined"
            color="cmpPrimary"
            onClick={() => handleDrawerAction('cancel')}
            data-testid="cancel-btn"
            sx={style.modalCancelButton}
        >
            Cancel
        </Btn>
    );

    useEffect(() => {
        if (Object.keys(touched).length > 0) {
            validateForm();
        }
    }, [formData]);

    const formatSizeLabel = (size) => {
        let formattedSize;
        if (size?.partitionCount && size?.messagingUnits) {
            formattedSize = `${size?.size} (Pricing Tier: ${size?.sku}, Partition Count: ${size?.partitionCount}, Messaging Units: ${size?.messagingUnits})`;
        } else {
            formattedSize = `${size?.size} (Pricing Tier: ${size?.sku})`;
        }
        return { label: formattedSize, value: size?.size };
    };

    const options = sizeGetApi?.data?.data?.serviceBusSizes?.map((size) => formatSizeLabel(size));
    const value = React.useMemo(
        () => options?.find((item) => item?.value === formData?.serviceBusCapacity) ?? null,
        [formData?.serviceBusCapacity, sizeGetApi]
    );

    useEffect(() => {
        sizeGetApi.mutate();
    }, []);

    return (
        <Box style={RIStyles.tags.wrapper}>
            <Box sx={RIStyles.tags.contentWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Select
                            key="capacity"
                            label="Capacity *"
                            loading
                            placeholder="Select Capacity"
                            options={options}
                            handleOnSelect={(selectedOption) => handleOnSelectChange('serviceBusCapacity', selectedOption?.value)}
                            isLoading={sizeGetApi.isLoading}
                            value={value}
                            isRequired={shouldShowError('serviceBusCapacity')}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="footer" sx={RIStyles.tags.actionWrapper} gap={2}>
                {saveButton()}
                {cancelButton()}
            </Box>
        </Box>
    );
};

ServiceBus.propTypes = {
    resource: PropTypes.any,
    handleDrawerAction: PropTypes.func,
};

ServiceBus.defaultProps = {
    resource: null,
    handleDrawerAction: PropTypes.func,
};

export default ServiceBus;
