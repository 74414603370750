import { styled } from '@mui/material/styles';
import { TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import theme from 'assets/style/theme';

// eslint-disable-next-line import/prefer-default-export
export const Item = styled(Paper)(() => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 1px #0000004B',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'flex-start',
    height: '50px',
}));

export const StyledCard = styled(Card)({
    // Add your card styles here if needed
    width: '286px',
    height: '123px',
    background: theme.palette.background.$color_background_layer_01,
    boxShadow: '0 0 24px rgba(0, 92, 188, 0.08)',
    borderRadius: theme.spacing(0.75),
    margin: '20px 50px 20px 20px',
});
export const BoldTypography = styled(Typography)({
    fontWeight: '700',
    marginTop: '-30px',
    marginRight: '20px',
    fontSize: '60px',
});

export const StyledTableHeader = styled(TableCell)(() => ({
    color: '#005CBC',
    // fontSize: '14px',
    // fontStyle: 'normal',
    // fontWeight: '600',
    font: theme.typography.fontSize.$font_small_bold,
}));

export const StyledTableCell = styled(TableCell)(() => ({
    color: '#3A3A3A',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
}));
export const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

export const StyledDataTable = styled(TableContainer)(() => ({
    padding: '0px 0px 30px 0px',
    borderadius: '12px',
    width: '98% !important',
}));

export const style = {
    wrapper: {
        background: theme.palette.grey.lighter,
        color: theme.palette.white,
        overflow: 'hidden',
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    dataTableFlex: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
        margin: '10px',
        paddingTop: '50px',
    },
    dataTableFlexRow: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.$color_background_layer_01,
        borderRadius: '5px 5px 0px 0px',
        margin: '20px 15px 0px 10px',
        border: '1px solid white',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dataTableModelRegistry: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.$color_background_layer_01,
        borderRadius: '5px 5px 0px 0px',
        margin: '30px 15px 0px 20px',
        border: '1px solid white',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dataTableDashboard: {
        background: theme.palette.background.$color_background_layer_01,
        borderRadius: theme.spacing(0.75),
        width: '98%',
        marginTop: '15px',
        margin: '0px 10px 10px 10px',
        boxShadow: '0 0 24px rgba(0, 92, 188, 0.08)',
    },
    dataTableDashboardModelRegistry: {
        background: theme.palette.background.$color_background_layer_01,
        borderRadius: '0px 0px 12px 12px',
        width: '98%',
        marginTop: '15px',
        margin: '0px 10px 10px 20px',
        boxShadow: '0px 0px 2px #00000036',
    },

    subText1B: {
        fontWeight: 600,
        fontSize: '18px',
        color: '#000000',
    },
    subText2B: {
        fontWeight: 400,
        fontSize: '12px',
        color: '#000000',
    },

    subText1: {
        fontWeight: 600,
        fontSize: '18px',
        color: '#000000',
    },

    subText2: {
        fontWeight: 400,
        fontSize: '12px',
        color: '#000000',
    },

    overViewIamge: {
        marginLeft: '20px',
        marginTop: '15px',
    },
    TypographyText1: {
        fontSize: '18px',
        fontWeight: 600,
        color: '#000000',
    },
    TypographyText2: {
        fontSize: '40px',
        fontWeight: 600,
        color: '#000000',
    },
    cardContainer: {
        width: '200px',
        height: '150px',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '15px 15px 15px 15px',
        marginLeft: '10px',
    },
    allProjectDiv: {
        display: 'flex',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        height: '60px',
    },
    modelRegistryDiv: {
        display: 'flex',
        height: '60px',
    },
    stickyNote: {
        margin: '20px',
    },
    projectText: {
        color: '#0047BA',
        fontSize: '18px',
        fontWeight: '700',
        marginTop: '20px',
    },
    cardText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        wordBreak: 'break-word',
        margin: '5px',
    },
    TypographyTexth2: {
        fontSize: '18px',
        fontWeight: 600,
        color: '#000000',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '190px',
        textAlign: 'center',
    },
    boxStyle: { padding: () => theme.spacing(1.875) },
    innerboxStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '3em',
    },
    descTypo: {
        marginTop: '50px',
    },
    dividerStyle: { color: '#CCCCCC', borderBottom: '0px 1px 0px #00000036', marginY: () => theme.spacing(1.875) },
    customdialogpaper: {
        maxWidth: '800px !important',
    },
    iframeContainer: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    iframe: {
        width: '100%',
        height: '800px',
        border: 'none',
    },
    breadcrumbIcon: {
        margin: '0 8px',
        width: '16px',
        height: '10px',
    },
    subHeader: {
        background: '#FBFCFE 0% 0% no-repeat padding-box !important',
        boxShadow: 'rgba(0, 92, 188, 0.15) 0px 0px 24px 0 !important',
        height: '60px',
        position: 'relative',
        zIndex: '99',
    },
    tablePaper: {
        background: theme.palette.background.$color_background_layer_01,
        borderRadius: theme.spacing(0.75),
        boxShadow: '0 0 24px rgba(0, 92, 188, 0.08)',
    },
};
