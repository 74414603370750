export const formatSector = (sector) => {
    return sector.replace(/ /g, '_').replace(/&/g, 'n');
};

export const isReadyToSubmit = ({ order, isValidForm }) => {
    const {
        sector,
        adoPlatform,
        isADOProject,
        projectName,
        projectDetails,
        existingProjectName,
        isClarityId,
        clarityDetails,
        adoProjectType,
        applicationService,
        applicationName,
        appShortName,
        applicationOwner,
        serviceResources,
        adminAccessMemberList,
        existingAdminMembers,
        sonarQubeUserList,
        progLanguage,
        buildTool,
        packageType,
        artifactoryUserList,
        hCode,
        testPlanUserList,
        isServiceAccountExists,
    } = order;
    const {
        isClarityIdValid,
        isProjectNameValid,
        isAppNameValid,
        isAppShortNameValid,
        isInvalidServiceAccount,
        isDuplicateSastApplication,
        isInvalidSASTApplicationName,
        isInvalidSASTApplicationVersion,
        isSCAMaxMemberLimit,
        isDuplicateSCAMember,
        isSCAMemberOrganizationInvalid,
        isHCodeInvalid,
    } = isValidForm;

    const existingProject = isADOProject === 'yes' || (isADOProject === 'no' && clarityDetails && clarityDetails?.devopsProject !== null);

    const newProject =
        (isADOProject === 'no' && isClarityId === 'no') ||
        (isADOProject === 'no' &&
            isClarityId === 'yes' &&
            clarityDetails &&
            (typeof clarityDetails?.devopsProject === 'undefined' || clarityDetails?.devopsProject === null || clarityDetails?.devopsProject === undefined));

    const isFormInfoIncomplete =
        !sector ||
        !adoPlatform ||
        !applicationService ||
        !applicationOwner ||
        !applicationName ||
        (isServiceAccountExists === 'no' && !applicationName) ||
        (adoPlatform === 'Production' &&
            (!isADOProject ||
                (adoPlatform === 'Production' && isClarityId === 'yes' && clarityDetails && clarityDetails?.devopsProject !== null
                    ? !existingProjectName
                    : !projectName) ||
                (isADOProject === 'no' && !isClarityId) ||
                !adoProjectType));
    const isFormInvalid = (isClarityId === 'yes' && !isClarityIdValid) || isProjectNameValid || isAppNameValid;
    const isProjectAdminIncomplete =
        (adoPlatform === 'Sandbox/Personal' && serviceResources?.isProjectAdmin && adminAccessMemberList?.length === 0) ||
        (adoPlatform === 'Production' &&
            ((isADOProject === 'no' && isClarityId === 'no') || projectDetails?.projectAdmin === null) &&
            serviceResources?.isProjectAdmin &&
            adminAccessMemberList?.length + existingAdminMembers?.length === 0) ||
        (adoPlatform === 'Production' &&
            (isADOProject === 'yes' || existingProjectName) &&
            existingAdminMembers?.length + adminAccessMemberList?.length === 0) ||
        (adoPlatform === 'Production' && existingAdminMembers?.length + adminAccessMemberList?.length === 0 && !serviceResources?.isProjectAdmin);
    const isSonarQubeIncomplete =
        serviceResources?.isSonarQube &&
        (isAppShortNameValid ||
            isInvalidServiceAccount ||
            (order?.isADOProject === 'no' && order?.newProjectOnboardMembers && sonarQubeUserList?.length === 0) ||
            (order?.isServiceAccountExists === 'no' && !applicationName && !appShortName) ||
            (order?.isADOProject === 'no' && order?.newProjectOnboardServiceAccount && !order?.isServiceAccountExists) ||
            (order?.isADOProject === 'no' &&
                order?.newProjectOnboardServiceAccount &&
                order?.isServiceAccountExists === 'yes' &&
                !order?.isServiceAccountExists) ||
            (order?.isADOProject === 'no' && order?.isServiceAccountExists === 'yes' && order?.serviceAccount === null) ||
            (order?.isADOProject === 'yes' && order?.isServiceAccountExists === 'yes' && order?.serviceAccount === null) ||
            (order?.onboardMembers && order?.sonarQubeUserList?.length === 0) ||
            (order?.onboardRepositories && order?.sonarQubeCodeRepoList?.some((repo) => repo?.repo === null || repo?.repo === '')));

    const isArtifactoryIncomplete =
        serviceResources?.isArtifactory &&
        (!appShortName || isAppShortNameValid || !progLanguage || !buildTool || !packageType || artifactoryUserList?.length === 0);
    const isFortifySnykIncomplete =
        (serviceResources?.isSecurityTools && !order?.onboardSAST && !order?.onboardSCA) ||
        isDuplicateSastApplication ||
        isInvalidSASTApplicationName ||
        isInvalidSASTApplicationVersion ||
        isSCAMaxMemberLimit ||
        isDuplicateSCAMember ||
        isSCAMemberOrganizationInvalid ||
        (order?.onboardSAST && order?.sastOption === null) ||
        (order?.onboardSAST &&
            order?.sastOption === 'AccessExistingApplication' &&
            (order?.applicationNameWithVersions?.length === 0 || order?.fortifyMembers?.length === 0)) ||
        (order?.onboardSAST && order?.sastOption === 'CreateNewApplication' && (order?.sastApplicationName === null || order?.fortifyMembers?.length === 0)) ||
        (order?.onboardSAST &&
            order?.sastOption === 'CreateNewVersionInExistingApplication' &&
            (order?.sastApplicationName === null || order?.fortifyMembers?.length === 0 || order?.sastVersion === null)) ||
        (order?.onboardSCA && (order?.organizationName === null || order?.snykMembers?.length === 0));
    const isTestPlanIncomplete =
        serviceResources?.isTestPlan &&
        ((existingProject && !order?.addTestPlanMembers && !order?.removeTestPlanMembers) ||
            ((order?.addTestPlanMembers || newProject) && (!hCode || testPlanUserList?.length === 0 || isHCodeInvalid)) ||
            (order?.removeTestPlanMembers && (order?.removedTestPlanUserList?.length === 0 || !order?.removedTestPlanUserList)));
    const isServiceResourceFormInValid = !(
        serviceResources?.isProjectAdmin ||
        serviceResources?.isBoard ||
        serviceResources?.isRepos ||
        serviceResources?.isPipelines ||
        serviceResources?.isSonarQube ||
        serviceResources?.isArtifactory ||
        serviceResources?.isSecurityTools ||
        serviceResources?.isTestPlan
    );

    return (
        isFormInfoIncomplete ||
        isServiceResourceFormInValid ||
        isFormInvalid ||
        isProjectAdminIncomplete ||
        isSonarQubeIncomplete ||
        isArtifactoryIncomplete ||
        isFortifySnykIncomplete ||
        isTestPlanIncomplete
    );
};

export const getOrderPayload = ({ order }) => {
    const isProjectSector =
        order?.adoPlatform === 'Sandbox/Personal' ||
        (order?.adoPlatform === 'Production' &&
            ((order?.isADOProject === 'no' && order?.isClarityId === 'no') ||
                (order?.isADOProject === 'no' &&
                    order?.isClarityId === 'yes' &&
                    order?.clarityDetails &&
                    (typeof order?.clarityDetails?.devopsProject === 'undefined' ||
                        order?.clarityDetails?.devopsProject === null ||
                        order?.clarityDetails?.devopsProject === undefined))));
    const getProjectName = () => {
        let projectNameVal = null;
        if (isProjectSector) projectNameVal = `${formatSector(order?.sector)}_${order?.projectName}`;
        else if (
            order?.adoPlatform === 'Production' &&
            order?.isClarityId === 'yes' &&
            order?.clarityDetails &&
            order?.clarityDetails?.devopsProject !== null
        ) {
            projectNameVal = order?.existingProjectName;
        } else projectNameVal = order?.projectName;
        return projectNameVal;
    };

    const setClarityId = (object) => {
        if (object?.isClarityId === 'yes') {
            return true;
            // eslint-disable-next-line
        } else if (object?.isClarityId === 'no') {
            return false;
        } else {
            return null;
        }
    };

    const setExistingProject = (object) => {
        if (object?.isADOProject === 'yes') {
            return true;
            // eslint-disable-next-line
        } else if (object?.isADOProject === 'no') {
            return false;
        } else {
            return null;
        }
    };

    const getOnboardRepoStatus = (object) => {
        if (object?.onboardRepositories === 'yes' || object?.onboardRepositories === true) {
            return 'yes';
        }
        return null;
    };

    const getSecurityToolRequired = (object) => {
        if (object?.onboardSAST && object?.onboardSCA) {
            return 'SAST/SCA';
        }
        if (object?.onboardSAST) {
            return 'SAST';
        }
        if (object?.onboardSCA) {
            return 'SCA';
        }
        return null;
    };

    const getSonarMembers = (object) => {
        if (object?.onboardMembers || order?.newProjectOnboardMembers) {
            return object?.sonarQubeUserList;
        }
        return [];
    };

    const payload = {
        sector: order?.sector,
        adoPlatform: order?.adoPlatform,
        existingProject: setExistingProject(order),
        projectName: getProjectName(),
        existingProjectName: order?.existingProjectName,
        existingClarityId: setClarityId(order),
        clarityId: order?.isClarityId === 'yes' ? order?.clarityId : null,
        clarityDetails: order?.clarityDetails
            ? {
                  docType: order?.clarityDetails?.docType,
                  projectNumber: order?.clarityDetails?.projectNumber,
                  projectStatus: order?.clarityDetails?.projectStatus,
                  devopsProject: order?.clarityDetails?.devopsProject,
              }
            : null,
        adoProjectType: order?.adoProjectType,
        applicationName: order?.applicationName,
        applicationShortName: order?.isServiceAccountExists === 'no' ? order?.appShortName : null,
        businessApplication: order?.businessApplication,
        applicationService: order?.applicationService,
        applicationOwner: order?.applicationOwnerDetails?.gpId,
        boards: order?.serviceResources?.isBoard,
        repos: order?.serviceResources?.isRepos,
        pipelines: order?.serviceResources?.isPipelines,
        projectAdmin: {
            members: order?.adminAccessMemberList || null,
            existingAdminMembers: order?.existingAdminMembers || null,
            removedMembers: order?.deletedAdminMembers || null,
            adGroupName: order?.projectDetails?.projectAdmin?.name || null,
        },
        sonarQube: order?.serviceResources?.isSonarQube
            ? {
                  members: getSonarMembers(order),
                  codeRepositories:
                      order?.sonarQubeCodeRepoList?.length > 0 && order?.onboardRepositories
                          ? order?.sonarQubeCodeRepoList
                                ?.filter((item) => item?.repo !== '')
                                .map((item) => ({
                                    url: item?.repo,
                                }))
                          : [],
                  onboardRepositories: getOnboardRepoStatus(order),
                  isServiceAccountExists: order?.isServiceAccountExists,
                  serviceAccount: order?.serviceAccount,
              }
            : null,
        artifactory: order?.serviceResources?.isArtifactory
            ? {
                  programmingLanguage: order?.progLanguage,
                  buildTool: order?.buildTool,
                  packageTypes: order?.packageType,
                  members: order?.artifactoryUserList,
              }
            : null,
        securityTool: order?.serviceResources?.isSecurityTools
            ? {
                  securityToolRequired: getSecurityToolRequired(order),
                  fortifyOption: order?.sastOption,
                  applicationNameWithVersions: ['AccessExistingApplication']?.includes(order?.sastOption) ? order?.applicationNameWithVersions : null,
                  applicationName: order?.sastApplicationName,
                  applicationVersion: ['CreateNewApplication', 'CreateNewVersionInExistingApplication']?.includes(order?.sastOption)
                      ? order?.sastVersion
                      : null,
                  snykOption: order?.scaOption,
                  organization: order?.onboardSCA ? order?.organizationName : null,
                  fortifyMembers: order?.onboardSAST ? order?.fortifyMembers : null,
                  snykMembers: order?.onboardSCA ? order?.snykMembers : null,
              }
            : null,
        testPlans: order?.serviceResources?.isTestPlan
            ? {
                  cmpHCode: order?.hCode,
                  members: order?.testPlanUserList,
                  removedMembers: order?.removedTestPlanUserList,
              }
            : null,
    };
    return payload;
};

export const getToolsOrderPayload = (payload, journeyData, order) => {
    const result = {
        journeyId: journeyData?.journeyId,
        applicationId: journeyData?.applicationId,
        workflowName: 'ToolsOnboarding',
        atomicWorkflowRequestList: [
            {
                atomicWorkflowName: 'ToolsOnboarding_ADO',
                inputData: {
                    orderId: order?.orderId,
                    sector: payload?.sector,
                    businessApplication: payload?.businessApplication,
                    applicationService: payload?.applicationService,
                    applicationName: payload?.applicationName,
                    applicationShortName: payload?.applicationShortName,
                    applicationOwner: order?.applicationOwnerDetails,
                    adoPlatform: payload?.adoPlatform,
                    adoProjectType: payload?.adoProjectType,
                    projectName: payload?.projectName,
                    existingProject: payload?.existingProject,
                    adoBoards: !!payload?.boards,
                    adoRepos: !!payload?.repos,
                    adoPipelines: !!payload?.pipelines,
                    adoProjectAdmin: payload?.projectAdmin,
                    ...(payload?.testPlans && {
                        testPlans: {
                            budgetCode: payload?.testPlans?.cmpHCode,
                            members: payload?.testPlans?.members,
                        },
                    }),
                    ...(payload?.sonarQube && {
                        sonarQube: {
                            members: payload?.sonarQube?.members,
                            codeRepositories: payload?.sonarQube?.codeRepositories,
                            serviceAccount: payload?.sonarQube?.serviceAccount,
                        },
                    }),
                    ...(payload?.securityTool && {
                        securityTool: payload?.securityTool,
                    }),
                },
            },
        ],
    };

    return result;
};

export const resetOrder = {
    sector: null,
    adoPlatform: null,
    isADOProject: null,
    projectName: null,
    projectDetails: null,
    existingProjectName: null,
    existingAdminMembers: [],
    deletedAdminMembers: [],
    isClarityId: null,
    clarityId: null,
    clarityDetails: null,
    adoProjectType: null,
    serviceAccount: null,
    applicationName: null,
    appShortName: null,
    businessApplication: null,
    businessApplicationDetails: null,
    applicationService: null,
    applicationOwner: null,
    applicationOwnerDetails: null,
    existingADOServices: null,
    serviceResources: {
        isProjectAdmin: false,
        isBoard: false,
        isRepos: false,
        isPipelines: false,
        isSonarQube: false,
        isArtifactory: false,
        isSecurityTools: false,
        isTestPlan: false,
    },
    adminAccessMember: null,
    adminAccessMemberDetails: null,
    adminAccessMemberList: [],
    allowedAdminMembers: 5,
    courseDetails: null,
    organizationName: null,
    organizationNameDetails: null,
    applicationUrl: null,
    securityTool: null,
    securityToolCodeRepoList: [{ repo: '', isRepoTouched: false }],
    sastMember: null,
    scaMember: null,
    sastMemberDetails: null,
    addTestPlanMembers: false,
    removeTestPlanMembers: false,
    hCode: null,
    hCodeDetails: null,
    testPlanUser: null,
    testPlanUserDetails: null,
    testPlanUserList: [],
    removedTestPlanUser: null,
    removedTestPlanUserDetails: null,
    removedTestPlanUserList: [],
    isCostApiLoading: false,
    repoName: null,
    sonarQubeUser: null,
    sonarQubeUserDetails: null,
    sonarQubeUserList: [],
    sonarQubeCodeRepo: null,
    sonarQubeRepoList: [],
    progLanguage: null,
    progLanguageDetails: null,
    buildTool: null,
    buildToolDetails: null,
    packageType: null,
    packageTypeDetails: null,
    artifactoryUser: null,
    artifactoryUserDetails: null,
    artifactoryUserList: [],
    isConfigureTouched: false,
    isServiceAccountExists: false,
    onboardServiceAccount: false,
    onboardRepositories: false,
    onboardMembers: false,
    sastApplicationName: null,
    onboardSAST: false,
    onboardSCA: false,
    sastVersion: null,
    applicationNameWithVersions: [],
    scaRoles: null,
    securityToolRequired: null,
    fortifyMembers: [],
    snykMembers: [],
    sastOption: null,
    scaOption: null,
    snykPreviewMembers: [],
    newProjectOnboardMembers: false,
    newProjectOnboardServiceAccount: false,
    estimatedCost: {
        details: [],
        qty: null,
        totalOneTimeCost: {
            frequency: 'one time',
            estCost: null,
            currency: 'USD',
        },
        totalRecurringCost: {
            frequency: 'monthly',
            estCost: null,
            currency: 'USD',
        },
        notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
    },
    isTouched: false,
};

export const resetValidOnboardingForm = {
    isClarityIdValid: false,
    isProjectNameValid: false,
    isProjectNameExists: false,
    isAppNameValid: false,
    isAppShortNameValid: false,
    isAppShortNameExist: false,
    isAppUrlValid: false,
    isRepoNameValid: false,
    isDuplicateAdminAccessMember: false,
    isDuplicateSASTMember: false,
    isDuplicateTestPlanUser: false,
    isDuplicateSonarQubeUser: false,
    isDuplicateArtifactoryUser: false,
    isAdminAccessMaxMemberLimit: false,
    isSASTMaxMemberLimit: false,
    isTestPlanMaxMemberLimit: false,
    isSonarQubeMaxMemberLimit: false,
    isArtifactoryMaxMemberLimit: false,
    isDuplicateSastApplication: false,
    isInvalidSASTApplicationName: false,
    isInvalidSASTApplicationVersion: false,
    isSCAMaxMemberLimit: false,
    isDuplicateSCAMember: false,
    isSCAMemberOrganizationInvalid: false,
    isHCodeInvalid: false,
    isInvalidServiceAccount: false,
};

export const resetToggleModal = {
    isProjectAdminOpen: false,
    isSonarQubeOpen: false,
    isArtifactoryOpen: false,
    isFortifySnykOpen: false,
    isTestPlanOpen: false,
    isSuccessOpen: false,
    action: '',
    isCost: { costType: '', isCostOpen: false },
    orderId: null,
};

export const restCostEstimate = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

const getProjectName = (values) => {
    if (
        values?.adoPlatform === 'SandBox/Personal' ||
        (values?.adoPlatform === 'Production' &&
            ((!values?.existingProject && !values?.existingClarityId) ||
                (!values?.existingProject && values?.existingClarityId && values?.clarityDetails?.devopsProject === null)))
    ) {
        return values?.projectName.replace(`${formatSector(values?.sector)}_`, '');
    }
    return values?.projectName;
};

const getIsADOProject = (object, param) => {
    if (object?.existingProject !== undefined) {
        if (param === 'formObj') return object?.existingProject === true ? 'yes' : 'no';
        return object?.existingProject === true;
    }
    return null;
};

const getIsClarityId = (object, param) => {
    if (object?.existingClarityId !== undefined) {
        if (param === 'formObj') return object?.existingClarityId === true ? 'yes' : 'no';
        return object?.existingClarityId === true;
    }
    return null;
};

const getClarityId = (object) => {
    if (object?.existingClarityId !== undefined) {
        return object?.clarityId ? object?.clarityId : '**NO PPMID**';
    }
    return null;
};

const getSonarRepos = (object) => {
    if (object?.codeRepositories?.length > 0) {
        return object?.codeRepositories?.map((item) => ({
            repo: item?.url,
        }));
    }
    return [{ repo: '', isRepoTouched: false }];
};

const getOnboadServiceAccount = (object) => {
    if (object?.isServiceAccountExists === 'yes' || object?.isServiceAccountExists === 'no') {
        return true;
    }
    return false;
};

export const formOrderObjFromOrderDetails = (data = null) => {
    if (data?.catalogServices?.[0]?.catalogConfig) {
        const response = data?.catalogServices?.[0]?.catalogConfig;
        return {
            sector: response?.sector,
            adoPlatform: response?.adoPlatform,
            isADOProject: response?.existingProject !== undefined ? getIsADOProject(response, 'formObj') : null,
            projectName: response?.existingProjectName === undefined ? getProjectName(response) : null,
            existingProjectName: response?.existingProjectName,
            isClarityId: response?.existingClarityId !== undefined ? getIsClarityId(response, 'formObj') : null,
            clarityId: getClarityId(response),
            clarityDetails: response?.clarityDetails || null,
            adoProjectType: response?.adoProjectType,
            applicationName: response?.applicationName,
            appShortName: response?.applicationShortName,
            businessApplication: response?.businessApplication,
            applicationService: response?.applicationService,
            applicationOwner: data?.manager?.name,
            applicationOwnerDetails: { owner: data?.manager?.name, gpId: data?.manager?.id },
            serviceResources: {
                isProjectAdmin: response?.projectAdmin?.members !== undefined,
                isBoard: response?.boards || false,
                isRepos: response?.repos || false,
                isPipelines: response?.pipelines || false,
                isSonarQube: response?.sonarQube !== undefined,
                isArtifactory: response?.artifactory !== undefined,
                isSecurityTools: response?.securityTool !== undefined,
                isTestPlan: response?.testPlans !== undefined,
            },
            onboardSAST: response?.securityTool?.securityToolRequired === 'SAST' || response?.securityTool?.securityToolRequired === 'SAST/SCA',
            onboardSCA: response?.securityTool?.securityToolRequired === 'SCA' || response?.securityTool?.securityToolRequired === 'SAST/SCA',
            sastOption: response?.securityTool?.fortifyOption,
            applicationNameWithVersions: response?.securityTool?.applicationNameWithVersions || [],
            sastApplicationName: response?.securityTool?.applicationName,
            sastVersion: response?.securityTool?.applicationVersion,
            scaOption: response?.securityTool?.snykOption,
            organizationName: response?.securityTool?.organization,
            snykMembers: response?.securityTool?.snykMembers || [],
            sonarQubeCodeRepo: response?.sonarQube?.codeRepository?.url,
            sonarQubeCodeRepoList: getSonarRepos(response?.sonarQube),
            onboardRepositories: response?.sonarQube?.onboardRepositories,
            isServiceAccountExists: response?.sonarQube?.isServiceAccountExists,
            newProjectOnboardMembers: response?.sonarQube?.members?.length > 0,
            newProjectOnboardServiceAccount: response?.sonarQube?.isServiceAccountExists === 'yes' || response?.sonarQube?.isServiceAccountExists === 'no',
            onboardServiceAccount: getOnboadServiceAccount(response?.sonarQube),
            onboardMembers: response?.sonarQube?.members?.length > 0,
            serviceAccount: response?.sonarQube?.serviceAccount,
            applicationUrl: response?.securityTool?.applicationURL,
            sonarQubeRepoList: [],
            adminAccessMemberList: response?.projectAdmin?.members || [],
            existingAdminMembers: response?.projectAdmin?.existingAdminMembers || [],
            deletedAdminMembers: response?.projectAdmin?.removedMembers || [],
            fortifyMembers: response?.securityTool?.fortifyMembers || [],
            sonarQubeUserList: response?.sonarQube?.members || [],
            artifactoryUserList: response?.artifactory?.members || [],
            progLanguage: response?.artifactory?.programmingLanguage,
            progLanguageDetails: response?.progLanguageDetails,
            buildTool: response?.artifactory?.buildTool,
            packageType: response?.artifactory?.packageTypes,
            buildToolDetails: response?.buildToolDetails,
            packageTypeDetails: response?.packageTypeDetails,
            addTestPlanMembers: response?.testPlans?.members !== undefined || response?.testPlans?.members?.length > 0,
            removeTestPlanMembers: response?.testPlans?.removedMembers !== undefined || response?.testPlans?.removedMembers?.length > 0,
            testPlanUserList: response?.testPlans?.members || [],
            removedTestPlanUserList: response?.testPlans?.removedMembers || [],
            hCode: response?.testPlans?.cmpHCode,
            hCodeDetails: response?.hCodeDetails,
            securityToolCodeRepoList: response?.securityTool?.codeRepository?.map((item) => ({
                repo: item?.url,
            })) || [{ repo: '', isRepoTouched: false }],
            isTouched: false,
        };
    }

    return null;
};

export const formToolsOrderObjFromOrderDetails = (response = null) => {
    if (response) {
        return {
            sector: response?.sector,
            businessApplication: response?.businessApplication,
            applicationService: response?.applicationService,
            applicationName: response?.applicationName,
            appShortName: response?.applicationShortName,
            applicationOwner: response?.applicationOwner?.name,
            applicationOwnerDetails: {
                owner: response?.applicationOwner?.name,
                gpId: response?.applicationOwner?.gpid,
                email: response?.applicationOwner?.email,
            },
            adoPlatform: response?.adoPlatform,
            adoProjectType: response?.adoProjectType,
            serviceResources: {
                // isProjectAdmin: response?.sonarQube !== undefined,
                isRepos: response?.sonarQube !== undefined,
                isPipelines: response?.sonarQube !== undefined,
                isSonarQube: response?.sonarQube !== undefined,
            },

            onboardRepositories: response?.sonarQube?.onboardRepositories,
            isServiceAccountExists: response?.sonarQube?.serviceAccountName !== undefined ? 'yes' : 'no',
            newProjectOnboardMembers: response?.sonarQube?.members?.length > 0,
            newProjectOnboardServiceAccount: response?.sonarQube?.serviceAccountName !== undefined ? 'yes' : 'no',
            onboardServiceAccount: response?.sonarQube !== undefined,
            onboardMembers: response?.sonarQube?.members?.length > 0,
            serviceAccount: response?.sonarQube?.serviceAccountName,
            sonarQubeUserList: response?.sonarQube?.members || [],
            isTouched: false,
            isToolsProject: true,
        };
    }
    return null;
};

export const getProjectOnboardingResubmitPayload = ({ order }) => {
    const response = order?.catalogServices?.[0]?.catalogConfig;
    const payload = {
        sector: response?.sector,
        adoPlatform: response?.adoPlatform,
        existingProject: response?.existingProject !== undefined ? getIsADOProject(response) : null,
        projectName: response?.existingProjectName === undefined ? getProjectName(response) : null,
        existingProjectName: response?.existingProjectName,
        existingClarityId: response?.existingClarityId !== undefined ? getIsClarityId(response) : null,
        clarityId: response?.clarityId !== undefined ? response?.clarityId : null,
        clarityDetails: response?.clarityDetails || null,
        adoProjectType: response?.adoProjectType,
        applicationService: response?.applicationService,
        applicationName: response?.applicationName,
        applicationShortName: response?.applicationShortName,
        applicationOwner: order?.manager?.id,
        boards: response?.boards,
        repos: response?.repos,
        pipelines: response?.pipelines,
        projectAdmin: response?.projectAdmin
            ? {
                  members: response?.projectAdmin?.members || [],
                  existingAdminMembers: response?.projectAdmin?.existingAdminMembers || [],
                  removedMembers: response?.projectAdmin?.removedMembers || null,
                  adGroupName: response?.projectAdmin?.adGroupName || null,
              }
            : null,
        sonarQube: response?.sonarQube
            ? {
                  members: response?.sonarQube?.members,

                  codeRepository: response?.sonarQube?.codeRepository
                      ? {
                            url: response?.sonarQube?.codeRepository?.url,
                        }
                      : null,
              }
            : null,

        artifactory: response?.artifactory
            ? {
                  programmingLanguage: response?.artifactory?.programmingLanguage,
                  buildTool: response?.artifactory?.buildTool,
                  packageTypes: response?.artifactory?.packageTypes,
                  members: response?.artifactory?.members || null,
              }
            : null,
        securityTool: response?.securityTool
            ? {
                  securityToolRequired: response?.securityTool?.securityToolRequired,
                  organization: response?.securityTool?.organizationName,
                  applicationURL: response?.securityTool?.applicationURL,
                  codeRepository:
                      response?.securityTool?.codeRepository?.map((item) => ({
                          name: 'https://dev.azure.com/PepsiCoIT',
                          url: item?.url,
                      })) || null,
                  members: response?.securityTool?.members || null,
              }
            : null,
        testPlans: response?.testPlans
            ? {
                  cmpHCode: response?.testPlans?.cmpHCode,
                  members: response?.testPlans?.members || null,
                  removedMembers: response?.testPlans?.removedMembers || null,
              }
            : null,
    };
    return payload;
};
