import { styled } from '@mui/material/styles';
import { RadioGroup, Radio } from '@mui/material';

export const RI002Styles = {
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        groupWrapper: { width: '100%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        deleteWrapper: {
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
            // background: (theme) => theme?.palette?.red?.darkest,
            mt: 0.5,
        },
        delete: {
            // fontSize: (theme) => theme.spacing(2.25),
            // fill: '#FFFFFF',
            // color: (theme) => theme.palette.white,
            cursor: 'pointer',
            width: '16px',
            height: '16px',
        },
        add: { width: '16px', height: '16px', cursor: 'pointer', marginTop: '7px', fontSize: '24px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        warningTypography: { color: (theme) => theme.palette.red.light },
        alertBox: {
            font: (theme) => theme.typography.fontSize.$font_small_medium,
            paddingTop: '4px',
            paddingBottom: '4px',
            background: (theme) => theme?.palette?.cmpPrimary2?.light,
            color: (theme) => theme?.palette?.cmpBlack?.main,
            // fontWeight: '700',
            // fontSize: '18px',
            '& .MuiAlert-action': {
                paddingTop: '8px !important',
            },
        },
        saveButton: {
            borderRadius: (theme) => theme.palette.border.$border_radius,
            backgroundColor: (theme) => theme.palette.blue.strongDark,
            padding: '4px 16px',
            '&.MuiButtonBase-root:hover': {
                backgroundColor: (theme) => theme.palette.background.$color_button_primary_hover,
            },
            '&:active': {
                backgroundColor: (theme) => theme.palette.button.$color_button_primary_active,
            },
        },
        cancelButton: {
            borderRadius: (theme) => theme.palette.border.$border_radius,
            color: (theme) => theme.palette.blue.strongDark,
            padding: '4px 16px',
            border: (theme) => `1px solid ${theme.palette.blue.strongDark}`,
            '&.MuiButtonBase-root:hover': {
                color: (theme) => theme.palette.background.$color_button_primary_hover,
                border: (theme) => `1px solid ${theme.palette.background.$color_button_primary_hover} !important`,
            },
            '&:active': {
                color: (theme) => theme.palette.button.$color_button_primary_active,
                border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_active} !important`,
            },
        },
    },
    warningTypography: { color: (theme) => theme.palette.red.light },
    infoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    adinfoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%', width: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    validateBtn: {
        width: (theme) => theme.spacing(20.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
    },
    emptyStack: {
        width: '100%',
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    welcomeBanner: {
        boxStyle: { marginLeft: '25px', marginRight: '25px' },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '24px', marginRight: '10px !important', marginLeft: '5px' },
    },
    fontStyle: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
};

export default RI002Styles;

export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
