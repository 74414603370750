// /* eslint-disable react/jsx-props-no-spreading import/no-cycle */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Btn from 'components/button';
import { OrderPayload, toggleModal, validOnboardingForm, isValidationLoading as isServiceAccountLoading, urlParams } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import Modal from 'components/modal';
import { getModalTitle, getOnCloseConfigData, getLastModifiedConfigData } from 'pages/project-onboarding/utils/getFunctions';
import { commonStyles as style } from 'assets/style';
import ErrorBanner from 'pages/reference-implementation/components/error-banner';
import ProjectAdminConfiguration from './ServiceConfiguration/ProjectAdminConfiguration';
import SecurityToolConfiguration from './ServiceConfiguration/SecurityToolConfiguration';
import TestPlanConfiguration from './ServiceConfiguration/TestPlanConfiguration';
import SonarQubeConfiguration from './ServiceConfiguration/SonarQubeConfiguration';
import ArtifactoryConfiguration from './ServiceConfiguration/ArtifactoryConfiguration';

const ConfigureServiceModal = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isOpen, setIsOpen] = useRecoilState(toggleModal);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [params] = useRecoilState(urlParams);
    const [isValidationLoading] = useRecoilState(isServiceAccountLoading);
    const [lastModifiedConfigData, setLastModifiedConfigData] = React.useState({});
    const [errorBannerOpen, setErrorBannerOpen] = React.useState(false);

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };

    React.useEffect(() => {
        if (isOpen?.isProjectAdminOpen || isOpen?.isFortifySnykOpen || isOpen?.isTestPlanOpen || isOpen?.isSonarQubeOpen || isOpen?.isArtifactoryOpen) {
            setLastModifiedConfigData({
                ...lastModifiedConfigData,
                ...getLastModifiedConfigData(order),
            });
            setValidForm({
                ...isValidForm,
                isDuplicateAdminAccessMember: false,
                isDuplicateSASTMember: false,
                isDuplicateTestPlanUser: false,
                isDuplicateSonarQubeUser: false,
                isDuplicateArtifactoryUser: false,
                isAdminAccessMaxMemberLimit: false,
                isSonarQubeMaxMemberLimit: false,
                isArtifactoryMaxMemberLimit: false,
                isSASTMaxMemberLimit: false,
                isTestPlanMaxMemberLimit: false,
                ...(params?.journeyId === null && {
                    isInvalidServiceAccount: false,
                }),
            });
        } else handleCloseErrorBanner();
    }, [isOpen?.isProjectAdminOpen, isOpen?.isFortifySnykOpen, isOpen?.isTestPlanOpen, isOpen?.isSonarQubeOpen, isOpen?.isArtifactoryOpen]);

    const handleSave = () => {
        const existingProject =
            order?.isADOProject === 'yes' || (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null);

        const newProject =
            (order?.isADOProject === 'no' && order?.isClarityId === 'no') ||
            (order?.isADOProject === 'no' &&
                order?.isClarityId === 'yes' &&
                order?.clarityDetails &&
                (typeof order?.clarityDetails?.devopsProject === 'undefined' ||
                    order?.clarityDetails?.devopsProject === null ||
                    order?.clarityDetails?.devopsProject === undefined));
        if (
            (isOpen?.isProjectAdminOpen && order?.adminAccessMemberList?.length + order?.existingAdminMembers?.length === 0) ||
            (isOpen?.isSonarQubeOpen && order?.projectDetails && order?.projectDetails?.repos !== 0 && order?.projectDetails?.repos === null) ||
            (isOpen?.isSonarQubeOpen && order?.onboardMembers && order?.sonarQubeUserList?.length === 0) ||
            (isOpen?.isSonarQubeOpen && order?.isADOProject === 'no' && order?.newProjectOnboardMembers && order?.sonarQubeUserList?.length === 0) ||
            (isOpen?.isSonarQubeOpen && order?.isADOProject === 'no' && order?.newProjectOnboardServiceAccount && !order?.isServiceAccountExists) ||
            (isOpen?.isSonarQubeOpen &&
                order?.isADOProject === 'no' &&
                order?.newProjectOnboardServiceAccount &&
                order?.isServiceAccountExists === 'yes' &&
                !order?.isServiceAccountExists) ||
            (isOpen?.isSonarQubeOpen && order?.isADOProject === 'no' && order?.isServiceAccountExists === 'yes' && order?.serviceAccount === null) ||
            (isOpen?.isSonarQubeOpen && order?.onboardRepositories && order?.sonarQubeCodeRepoList?.some((repo) => repo?.repo === null || repo?.repo === '')) ||
            (isOpen?.isSonarQubeOpen && order?.isADOProject === 'yes' && order?.isServiceAccountExists === 'yes' && order?.serviceAccount === null) ||
            (isOpen?.isSonarQubeOpen &&
                order?.isADOProject === 'yes' &&
                !order?.onboardMembers &&
                !order?.onboardRepositories &&
                !order?.onboardServiceAccount) ||
            (isOpen?.isSonarQubeOpen && isValidForm?.isInvalidServiceAccount) ||
            (isOpen?.isArtifactoryOpen &&
                (!order?.progLanguage ||
                    !order?.progLanguageDetails ||
                    !order?.buildTool ||
                    !order?.buildToolDetails ||
                    !order?.packageType ||
                    !order?.packageTypeDetails ||
                    order?.artifactoryUserList?.length === 0)) ||
            (isOpen?.isFortifySnykOpen && !order?.onboardSAST && !order?.onboardSCA) ||
            (isOpen?.isFortifySnykOpen && order?.onboardSAST && order?.sastOption === null) ||
            (isOpen?.isFortifySnykOpen &&
                order?.onboardSAST &&
                order?.sastOption === 'AccessExistingApplication' &&
                (order?.applicationNameWithVersions?.length === 0 || order?.fortifyMembers?.length === 0)) ||
            (isOpen?.isFortifySnykOpen &&
                order?.onboardSAST &&
                order?.sastOption === 'CreateNewApplication' &&
                (order?.sastApplicationName === null || order?.fortifyMembers?.length === 0)) ||
            (isOpen?.isFortifySnykOpen &&
                order?.onboardSAST &&
                order?.sastOption === 'CreateNewVersionInExistingApplication' &&
                (order?.sastApplicationName === null || order?.fortifyMembers?.length === 0 || order?.sastVersion === null)) ||
            (isOpen?.isFortifySnykOpen && order?.onboardSCA && order?.scaOption === null) ||
            (isOpen?.isFortifySnykOpen &&
                order?.onboardSCA &&
                order?.scaOption !== null &&
                (order?.organizationName === null || order?.scaRoles === null || order?.snykMembers?.length === 0)) ||
            (isOpen?.isTestPlanOpen && existingProject && !order?.addTestPlanMembers && !order?.removeTestPlanMembers) ||
            (isOpen?.isTestPlanOpen &&
                (order?.addTestPlanMembers || newProject) &&
                (!order?.hCode || order?.testPlanUserList?.length === 0 || isValidForm?.isHCodeInvalid)) ||
            (isOpen?.isTestPlanOpen && order?.removeTestPlanMembers && (!order?.removedTestPlanUserList || order?.removedTestPlanUserList?.length === 0)) ||
            isValidForm?.isInvalidSASTApplicationVersion ||
            isValidForm?.isDuplicateSastApplication ||
            isValidForm?.isInvalidSASTApplicationName ||
            isValidForm?.isInvalidSASTApplicationVersion ||
            isValidForm?.isSCAMaxMemberLimit ||
            isValidForm?.isDuplicateSCAMember ||
            isValidForm?.isSCAMemberOrganizationInvalid
        ) {
            const repoResult = [];
            order?.securityToolCodeRepoList?.forEach((z) => {
                if (!z?.repo) {
                    const updateIsTouched = { ...z, isRepoTouched: true };
                    repoResult.push(updateIsTouched);
                } else repoResult.push(z);
            });

            setOrder({ ...order, securityToolCodeRepoList: repoResult, isConfigureTouched: true });
            handleClickErrorBanner();
        } else {
            setValidForm({
                ...isValidForm,
                isSonarQubeMaxMemberLimit: false,
                isArtifactoryMaxMemberLimit: false,
                isTestPlanMaxMemberLimit: false,
                isSASTMaxMemberLimit: false,
                isAdminAccessMaxMemberLimit: false,
            });
            setOrder({
                ...order,
                isConfigureTouched: false,
                sonarQubeUser: null,
                sonarQubeUserDetails: null,
                artifactoryUser: null,
                artifactoryUserDetails: null,
                testPlanUser: null,
                testPlanUserDetails: null,
                adminAccessMember: null,
                adminAccessMemberDetails: null,
                sastMember: null,
                scaMember: null,
                sastMemberDetails: null,
            });
            setIsOpen({
                ...isOpen,
                isProjectAdminOpen: false,
                isFortifySnykOpen: false,
                isTestPlanOpen: false,
                isSonarQubeOpen: false,
                isArtifactoryOpen: false,
            });
        }
    };

    const getModalComponent = () => {
        let modalComponent = null;
        if (isOpen?.isProjectAdminOpen) {
            modalComponent = <ProjectAdminConfiguration />;
        }
        if (isOpen?.isSonarQubeOpen) {
            modalComponent = <SonarQubeConfiguration />;
        }
        if (isOpen?.isArtifactoryOpen) {
            modalComponent = <ArtifactoryConfiguration />;
        }
        if (isOpen?.isFortifySnykOpen) {
            modalComponent = <SecurityToolConfiguration />;
        }
        if (isOpen?.isTestPlanOpen) {
            modalComponent = <TestPlanConfiguration />;
        }
        return modalComponent;
    };

    return (
        <Modal
            data-testid="configureServiceModal"
            title={getModalTitle(isOpen)}
            minwidth="40%"
            width="60%"
            handleClose={() => {
                setIsOpen({
                    ...isOpen,
                    isProjectAdminOpen: false,
                    isFortifySnykOpen: false,
                    isTestPlanOpen: false,
                    isSonarQubeOpen: false,
                    isArtifactoryOpen: false,
                });
                setOrder({
                    ...order,
                    ...getOnCloseConfigData(lastModifiedConfigData),
                });
            }}
            open={isOpen?.isProjectAdminOpen || isOpen?.isFortifySnykOpen || isOpen?.isTestPlanOpen || isOpen?.isSonarQubeOpen || isOpen?.isArtifactoryOpen}
        >
            <Box sx={ProjectOnboardingStyles.modal.wrapper}>
                <Box sx={{ ...ProjectOnboardingStyles.modal.contentWrapper, paddingTop: '10px' }}>{getModalComponent()}</Box>
                <Box className="footer" sx={ProjectOnboardingStyles.modal.actionWrapper} gap={2}>
                    <Btn
                        className="custom-btn btn-disabled"
                        variant="contained"
                        // color="cmpPrimary2"
                        disabled={order?.isCostApiLoading || isValidationLoading}
                        onClick={() => {
                            handleSave();
                        }}
                        data-testid="config-save-btn"
                        sx={style.modalSaveButton}
                    >
                        Save
                    </Btn>

                    <Btn
                        className="custom-btn"
                        // sx={ProjectOnboardingStyles.modal.cancelBtn}
                        onClick={() => {
                            setIsOpen({
                                ...isOpen,
                                isProjectAdminOpen: false,
                                isFortifySnykOpen: false,
                                isTestPlanOpen: false,
                                isSonarQubeOpen: false,
                                isArtifactoryOpen: false,
                            });
                            setOrder({
                                ...order,
                                ...getOnCloseConfigData(lastModifiedConfigData),
                            });
                        }}
                        variant="outlined"
                        // color="cmpWarning"
                        sx={style.modalCancelButton}
                        layout="outline"
                        data-testid="config-cancel-btn"
                    >
                        Cancel
                    </Btn>
                </Box>
            </Box>
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click submit."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </Modal>
    );
};

export default ConfigureServiceModal;
