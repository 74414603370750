import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import FormCard from 'pages/reference-implementation/components/form-card';
import { useRecoilValue } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI003O/store';
import Form from 'pages/reference-implementation/provisioning/RI003O/components/Form';
import Heading from 'pages/reference-implementation/components/Heading';
import { InputInfo } from 'pages/reference-implementation/components/input-info';
import { RIStyles } from 'pages/reference-implementation/Style';

const AccessMgmtSection = () => {
    const order = useRecoilValue(OrderPayload);
    const isNonProduction = useMemo(() => {
        return !['production'].includes(order?.environment?.toLowerCase());
    }, [order?.environment]);
    return (
        <>
            {!['production']?.includes(order?.environment?.toLowerCase()) && (
                <>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Box sx={{ display: 'flex' }}>
                                <Heading title="Access Management" />
                                <Box sx={RIStyles?.form?.accessMgmt?.infoIconWrapper}>
                                    <InputInfo
                                        // iconFontSize="16px"
                                        maxWidth="400px"
                                        title={
                                            <>
                                                <Box sx={RIStyles.adinfoStyle.wrapper}>
                                                    <Box sx={RIStyles.adinfoStyle.namingRuleWrapper}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} sx={RIStyles.adinfoStyle.topLeftNamingRule}>
                                                                <Typography sx={RIStyles.infoStyle.typographyStyle}>
                                                                    If the AD Groups are not existing, new groups will be created in the below format.
                                                                </Typography>
                                                                <ul>
                                                                    <li>
                                                                        <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                                                            Reader: Team.App.&lt;Sector&gt;.&lt;Application Name&gt;
                                                                        </Typography>
                                                                    </li>
                                                                    <li>
                                                                        <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                                                            Contributor: Team.Support.&lt;Sector&gt;.&lt;Application Name&gt;
                                                                        </Typography>
                                                                    </li>
                                                                </ul>

                                                                <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                                                    if the AD Groups are existing, Please Choose AD Groups
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </>
                                        }
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <FormCard>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} sx={{ display: isNonProduction ? 'block' : 'none' }}>
                                <Form.NonPrivilegedADGroup />
                            </Grid>
                            {/* <Grid item xs={12} sm={12}>
                        <Form.PrivilegedADGroup />
                    </Grid> */}
                        </Grid>
                    </FormCard>
                </>
            )}
        </>
    );
};

export default AccessMgmtSection;
