import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { Styles } from 'pages/service-account-creation/components/form-card/style';

const FormCard = ({ title, children, margin, gap }) => {
    return (
        <Box sx={Styles.formCard.paper}>
            {title && (
                <>
                    <Box sx={Styles.formCard.wrapper}>
                        <Typography sx={Styles.formCard.heading} variant="subtitle1">
                            <Stack direction="row">{title}</Stack>
                        </Typography>
                    </Box>
                    <Divider variant="middle" />
                </>
            )}
            <Box display="flex" gap={gap} flexWrap="wrap" sx={{ ml: margin, mr: margin, mb: 0 }}>
                {children}
            </Box>
        </Box>
    );
};

FormCard.propTypes = {
    title: PropTypes.any,
    children: PropTypes.element,
    // padding: PropTypes.any,
    margin: PropTypes.any,
    gap: PropTypes.any,
};

FormCard.defaultProps = {
    title: undefined,
    children: <></>,
    // padding: 4,
    margin: 0,
    gap: 4,
};

export default React.memo(FormCard);
