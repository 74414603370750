import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
// import { BsQuestionSquare } from 'react-icons/bs';
// import Styles from 'pages/reference-implementation/Style';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const InputInfo = (props) => {
    const { title, maxWidth, children } = props;
    const StyledTooltip = styled(({ className, ...prop }) => <Tooltip {...prop} arrow classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            // backgroundColor: theme.palette.white,
            fontSize: 14,
            '&::before': {
                border: '1px solid #E9E9E9',
                color: theme?.palette?.black?.dark,
                backgroundColor: theme.palette.white,
                boxSizing: 'border-box',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            },
        },
        [`& .${tooltipClasses.tooltip}`]: {
            fontSize: 10,
            textAlign: 'left',
            whiteSpace: 'normal',
            width: 'auto',
            maxWidth,
            height: '100%',
            backgroundColor: theme.palette.white,
            color: theme?.palette?.black?.dark,
            border: '1px solid #E9E9E9',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
    }));
    const tooltipContent = children || (
        <Box component="span" sx={{ alignSelf: 'start', marginTop: '5px' }}>
            <img src="/static/images/help_blue.svg" alt="" width={22} />
            {/* <BsQuestionSquare style={{ ...Styles.infoIcon, fontSize: iconFontSize, color: iconColor }} /> */}
        </Box>
    );
    return (
        <StyledTooltip arrow title={title} data-testid="help-img">
            {tooltipContent}
        </StyledTooltip>
    );
};

InputInfo.propTypes = {
    title: PropTypes.any,
    maxWidth: PropTypes.string,
    // iconFontSize: PropTypes.any,
    // iconColor: PropTypes.any,
    children: PropTypes.node,
};

InputInfo.defaultProps = {
    title: '',
    maxWidth: 'auto',
    // iconFontSize: '20px',
    // iconColor: '#1478D4',
    children: null,
};

export default React.memo(InputInfo);
