/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { DateRange } from 'react-date-range';
import { CssTextField } from 'components/select';
import Popper from '@mui/material/Popper';
import format from 'date-fns/format';
import { Paper, Box, IconButton, ClickAwayListener } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { appdateRangeValue } from './store';
import dashboardStyle from './style';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const AppDashboardDateRange2 = () => {
    const [selectedRange, setappDateRange] = useRecoilState(appdateRangeValue);
    const [range, setRange] = useState({
        startDate: new Date(selectedRange?.startDate),
        endDate: new Date(selectedRange?.endDate),
        key: 'appselection',
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [maxDate, setMaxDate] = useState(new Date());

    const [selectedfocusedRange, setFocusedRange] = useState([]); // Initial focus on start date

    const handleSelect = (item) => {
        const { startDate } = item.appselection;
        // Calculate max date to 12 months after the selected minDate
        const maxDt = new Date(startDate.getFullYear() + 1, startDate.getMonth(), startDate.getDate());
        setMaxDate(maxDt);
        setRange(item?.appselection);
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(!open);
    };

    const handleClickAway = () => {
        setRange({
            startDate: new Date(selectedRange?.startDate),
            endDate: new Date(selectedRange?.endDate),
            key: 'appselection',
        });
        setAnchorEl(null);
        setOpen(false);
    };

    const id = open ? 'app-popper' : undefined;

    React.useEffect(() => {
        if (selectedfocusedRange[0] === 0 && selectedfocusedRange[1] === 0) {
            setAnchorEl(null);
            setOpen(false);
            setappDateRange({ startDate: range.startDate.toString(), endDate: range.endDate.toString() });
        }
    }, [selectedfocusedRange]);

    const handleRangeFocusChange = (focusedRange) => {
        setFocusedRange(focusedRange);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <CssTextField
                    mt={2}
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('100%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                        '& .MuiOutlinedInput-input': {
                            fontWeight: '500',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#0047BA',
                                color: '#AFB8CC',
                                borderWidth: '2px',
                            },
                            '&:hover fieldset': {
                                borderColor: '#0047BA',
                                borderWidth: '2px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#0047BA',
                                borderWidth: '2px',
                            },
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <>
                                <IconButton onClick={handleClick} data-testid="dashboardDate">
                                    <CalendarMonthIcon color="primary" />
                                </IconButton>
                            </>
                        ),
                        readOnly: true,
                    }}
                    value={`${format(range.startDate, 'MM/dd/yyyy')} - ${format(range?.endDate, 'MM/dd/yyyy')}`}
                />

                <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: '999' }}>
                    <Paper elevation={5} sx={dashboardStyle.dateRange.calendarWrap}>
                        <Box sx={dashboardStyle.orderFilter.popper.container}>
                            <DateRange
                                id="testingdatemetric"
                                onChange={handleSelect}
                                editableDateInputs={false}
                                moveRangeOnFirstSelection={false}
                                ranges={[range]}
                                months={1}
                                direction="horizontal"
                                maxDate={maxDate}
                                onRangeFocusChange={handleRangeFocusChange}
                            />
                        </Box>
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default AppDashboardDateRange2;
