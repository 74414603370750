import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Grid, Typography, IconButton, Alert } from '@mui/material';
import riStyle from 'pages/reference-implementation/Style';
import Btn from 'components/button';
import { CssTextField } from 'components/select';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {
    validateComponentLimits,
    validateSelection,
    getSelectedComponentFromCurrentComponent,
} from 'pages/reference-implementation/provisioning/RI003O/utils/validateSelection';
import { InputInfo } from 'pages/reference-implementation/components/input-info';
import ComponentCard from 'pages/reference-implementation/provisioning/RI003O/components/resource-mgmt/insert-components/ComponentCard';
import { commonStyles as style } from 'assets/style';
import { imageURL } from '../../../utils/constant';

const ComponentSelectionModal = ({ open, onClose, currentComponents, componentGroups, onInsert }) => {
    const [selectedComponents, setSelectedComponents] = useState(() => getSelectedComponentFromCurrentComponent(currentComponents));
    const [searchQuery, setSearchQuery] = useState('');
    const [errors, setErrors] = useState({});
    const [isExpanded, setIsExpanded] = useState(false);

    const handleCardClick = useCallback((groupHeader, componentId) => {
        setSelectedComponents((prevSelected) => {
            const isSelected = prevSelected[groupHeader]?.includes(componentId);
            const updatedGroupSelection = isSelected
                ? prevSelected[groupHeader].filter((id) => id !== componentId)
                : [...(prevSelected[groupHeader] || []), componentId];

            const newSelection = { ...prevSelected, [groupHeader]: updatedGroupSelection };
            const validationErrors = validateSelection(newSelection, componentGroups);
            setErrors(validationErrors);

            return newSelection;
        });
    }, []);

    const isComponentSelected = useCallback(
        (groupHeader, componentId) => {
            return selectedComponents[groupHeader]?.includes(componentId);
        },
        [selectedComponents]
    );

    const handleSearchChange = useCallback((event) => {
        setSearchQuery(event.target.value.toLowerCase());
    }, []);

    const filteredComponents = useCallback(
        (components) => {
            return components.filter((component) => component.componentName.toLowerCase().includes(searchQuery));
        },
        [searchQuery]
    );

    const handleInsertComponents = () => {
        let currentErrors = errors;
        const result = validateComponentLimits(currentComponents, selectedComponents);
        if (result.exceeded && result?.message === 'limit check not required') {
            currentErrors = { ...errors, limitReachedForInsert: result?.message };
        }
        onInsert(selectedComponents, currentErrors);
        if (Object.keys(currentErrors).length > 0) {
            return;
        }
        onClose();
    };

    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    const modalStyle = {
        ...riStyle?.componentSelectionModal?.wrapper,
        ...style.modalMU,
        ...(isExpanded && {
            width: '1011px',
            maxWidth: 'none',
            maxHeight: 'none',
        }),
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <CssTextField
                        autoComplete="off"
                        fullWidth
                        label="Search Services"
                        size="small"
                        variant="outlined"
                        placeholder="Search Services"
                        data-testid="search-input"
                        InputProps={{
                            endAdornment: (
                                <>
                                    {searchQuery && (
                                        <IconButton
                                            aria-label="Clear search"
                                            sx={riStyle?.componentSelectionModal?.clearIcon}
                                            onClick={() => setSearchQuery('')}
                                            data-testid="clear-icon"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                    <IconButton>
                                        <SearchIcon sx={riStyle?.componentSelectionModal?.searchIcon} data-testid="search-icon" />
                                    </IconButton>
                                </>
                            ),
                        }}
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <InputInfo title={isExpanded ? 'Click to collapse the menu' : 'Click to expand the menu'}>
                        <IconButton onClick={toggleExpand} data-testid="expand-btn">
                            {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
                        </IconButton>
                    </InputInfo>
                </Box>
                <Box sx={riStyle?.componentSelectionModal?.cardWrapper}>
                    {componentGroups?.map((group) => {
                        const filteredComponentsList = filteredComponents(group.components);
                        if (filteredComponentsList.length === 0) return null;

                        return (
                            <Box key={group.groupHeader}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    {group.groupHeader}
                                </Typography>
                                {errors[group.groupHeader] && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {errors[group.groupHeader]}
                                    </Alert>
                                )}
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    {filteredComponentsList.map((component) => (
                                        <Grid item xs={isExpanded ? 2 : 4} key={component.componentId}>
                                            <ComponentCard
                                                componentName={component.componentName}
                                                imageUrl={`${imageURL}/${component.configurationComponentCode}.svg`}
                                                isSelected={isComponentSelected(group.groupHeader, component.componentId) || false}
                                                onClick={() => handleCardClick(group.groupHeader, component.componentId)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        );
                    })}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
                    <Btn variant="contained" sx={style.modalSaveButton} data-testid="insert-btn" onClick={handleInsertComponents}>
                        Insert Services
                    </Btn>
                    <Btn sx={style.modalCancelButton} variant="outlined" onClick={onClose} data-testid="cancel-btn">
                        Cancel
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

ComponentSelectionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    currentComponents: PropTypes.any.isRequired,
    componentGroups: PropTypes.any.isRequired,
    onInsert: PropTypes.func.isRequired,
};

export default ComponentSelectionModal;
