/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { formattedDate, getLoggedUserGPID } from 'utils';
import {
    Box,
    Paper,
    CardContent,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    CircularProgress,
    Collapse,
    IconButton,
} from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import axios from 'axios';
import ErrorFallback from 'components/error-fallback';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { PEPVIGIL } from 'pages/dlops-dashboard/utils/constant';
import { Item, StyledCard, StyledTableHeader, StyledTableCell, BoldTypography, style, ProgressIcon } from './style';
import '../../assets/style/app.css';
import modeltrain from '../../assets/images/dlopsImages/model_training_FILL0_wght400_GRAD0_opsz24.svg';
import chartVolume from '../../assets/images/dlopsImages/chart-simple-solid.svg';
import warning from '../../assets/images/dlopsImages/warning_alerts.svg';
import stickyNotes from '../../assets/images/dlopsImages/sticky-note-solid.svg';
import AccessToken from './components/accessToken/accessToken';

const DlopsDashboard = () => {
    const [overviewData, setOverviewData] = useState([]);
    const [showFilterProdData, setShowFilterProdData] = useState([]);
    const { getAccessToken, handleTokenError } = AccessToken();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openRows, setOpenRows] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const history = useHistory();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowToggle = (index) => {
        setOpenRows((prevOpenRows) => {
            const newOpenRows = [...prevOpenRows];
            newOpenRows[index] = !newOpenRows[index];
            return newOpenRows;
        });
    };

    const dataCard = [
        { name: 'Models Live', img: modeltrain, num: showFilterProdData.length },
        { name: 'Volume', img: chartVolume, num: '0' },
        { name: 'Model Alerts', img: warning, num: '0' },
    ];
    const columns = [
        { name: '' },
        { name: 'Project name', renderCell: ({ row }) => row.projectName || 'undefined' },
        { name: 'Model name', renderCell: ({ row }) => row.projectName || 'undefined' },
        { name: 'Version', renderCell: ({ row }) => row.projectName || 'undefined' },
        { name: 'Stage', renderCell: ({ row }) => row.projectName || 'undefined' },
        { name: 'Type', renderCell: ({ row }) => row.projectName || 'undefined' },
        { name: 'Framework', renderCell: ({ row }) => row.projectName || 'undefined' },
        { name: 'Data source version' },
        { name: 'Creation date' },
    ];
    const getRegisteredModel = async (accessTokenPromise, gpid) => {
        setLoading(true);
        try {
            const accessToken = await accessTokenPromise;
            const response = await axios.get(endPoints.registeredModel, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                    gpid,
                },
            });
            setLoading(false);
            return response?.data;
        } catch (error) {
            setLoading(false);
            return handleTokenError(error, endPoints.registeredModel, 'get', null);
        }
    };

    const getRegisteredDlopsModelVersion = async (accessTokenPromise, gpid) => {
        setLoading(true);
        try {
            const accessToken = await accessTokenPromise;
            const response = await axios.get(endPoints.getAllRegisteredModelVersionFromDlops, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                    gpid,
                },
            });
            setLoading(false);
            return response?.data;
        } catch (error) {
            setLoading(false);
            return error;
        }
    };

    const filterData = (data) => {
        // Use a map to track the latest versions
        const latestVersionsMap = new Map();

        data.forEach((model) => {
            const { name, version } = model;
            if (!latestVersionsMap.has(name) || latestVersionsMap.get(name) < version) {
                latestVersionsMap.set(name, version);
            }
        });

        const filteredData = data.filter((model) => {
            const { name, version } = model;
            return version === latestVersionsMap.get(name);
        });

        return filteredData;
    };

    // eslint-disable-next-line consistent-return
    const getOverviewListNew = async () => {
        try {
            // const oktaClientId = process.env.REACT_APP_DLOPS_REGISTERED_MODEL_CLIENTID;
            // const oktaClientSecret = process.env.REACT_APP_DLOPS_REGISTERED_MODEL_CLIENTSECRET;
            const accessToken = await getAccessToken();
            const gpid = getLoggedUserGPID();
            const registeredModelResponse = await getRegisteredModel(accessToken, gpid);
            // const newAccessToken = localStorage.getItem('dlopsAccessToken');
            const getDlopsRegisteredModelResponse = await getRegisteredDlopsModelVersion(accessToken, gpid);
            const onBoardedProjectModel = getDlopsRegisteredModelResponse;
            let mergeModelData;

            if (registeredModelResponse?.registered_models && onBoardedProjectModel?.registered_models) {
                mergeModelData = [...registeredModelResponse.registered_models, ...onBoardedProjectModel.registered_models];
            } else if (registeredModelResponse?.registered_models) {
                mergeModelData = registeredModelResponse.registered_models;
            } else if (onBoardedProjectModel?.registered_models) {
                mergeModelData = onBoardedProjectModel.registered_models;
            } else {
                mergeModelData = [];
            }

            const latestVersions = mergeModelData.map((item) => {
                return item.latest_versions;
            });
            const listOflatestVersions = latestVersions.reduce((a, b) => a.concat(b), []);
            const filterByActiveModel = listOflatestVersions.filter((item) => {
                return item.current_stage === 'Production';
            });
            const filteredData = filterData(filterByActiveModel);
            const sortByVersionData = filteredData.sort((a, b) => b.version - a.version);
            setShowFilterProdData(sortByVersionData);
            setOverviewData(sortByVersionData);
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        getOverviewListNew();
    }, []);

    const navigateToProject = () => {
        history.push('/pepvigil-projects');
    };
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>{PEPVIGIL} Dashboard</title>
            </Helmet>
            <Box style={{ marginBottom: '20px' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <Item>
                                <div style={{ marginLeft: '28px', marginTop: '8px', fontSize: '14px' }}>
                                    <span>{PEPVIGIL}</span>
                                    <span style={{ fontWeight: 'bold', color: '#1378D4' }}>/Overview</span>
                                </div>
                            </Item>
                        </Typography>
                    </Grid>
                </Grid>
                <Box display="inline-flex" flexWrap="wrap" justifyContent="space-between">
                    {dataCard.map((item, index) => (
                        <StyledCard key={index} style={{ marginLeft: '34px', borderRadius: '12px' }}>
                            <img src={item.img} width={25} alt={item.img} style={style.overViewIamge} />
                            <CardContent>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="h6" style={style.TypographyText}>
                                        {item.name}
                                    </Typography>
                                    <BoldTypography variant="h2" className="align">
                                        {item.num}
                                    </BoldTypography>
                                </Box>
                            </CardContent>
                        </StyledCard>
                    ))}
                </Box>
                {overviewData && overviewData.length >= 0 && (
                    <div style={style.dataTable}>
                        <div
                            style={style.allProjectDiv}
                            data-testid="allprojectslink"
                            aria-hidden="true"
                            tabIndex={0}
                            role="link"
                            onClick={() => navigateToProject()}
                        >
                            <img src={stickyNotes} alt={stickyNotes} style={style.stickyNote} />
                            <h5 style={style.projectText}>All Projects ({showFilterProdData.length})</h5>
                        </div>

                        <TableContainer component={Paper} sx={style.tablePaper}>
                            {overviewData.length > 0 ? (
                                <Table>
                                    <TableHead style={{ fontSize: '16px' }}>
                                        <TableRow>
                                            {columns.map((column, index) => (
                                                <StyledTableHeader key={index} maxWidth={column.maxWidth}>
                                                    {column.name}
                                                </StyledTableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {overviewData
                                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Slice the data based on current page and rowsPerPage
                                            .map((row, index1) => (
                                                <>
                                                    <TableRow key={index1}>
                                                        <StyledTableCell>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                data-testid={`expandrow-${index1}`}
                                                                size="small"
                                                                onClick={() => handleRowToggle(index1)}
                                                            >
                                                                {openRows[index1] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        </StyledTableCell>

                                                        <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                            {row?.tags.find((tag) => tag.key === 'project_name')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>{row?.name}</StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>{row?.version}</StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>{row?.current_stage}</StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'model_type')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'framework')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'baseline_datasource_version')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>{formattedDate(row?.creation_timestamp)}</StyledTableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                                            <Collapse in={openRows[index1]} timeout="auto" unmountOnExit>
                                                                <Box margin={1} style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                                                                    <div>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '10px' }}>
                                                                            <div>
                                                                                {' '}
                                                                                <span style={{ fontSize: '14px', fontWeight: 600 }}>Description : </span>
                                                                                {row?.tags.find((tag) => tag.key === 'description_model')?.value || 'undefined'}
                                                                            </div>
                                                                            <div>
                                                                                <span style={{ fontSize: '14px', fontWeight: 600, marginLeft: '200px' }}>
                                                                                    Description Version :{' '}
                                                                                </span>
                                                                                {row?.tags.find((tag) => tag.key === 'desc_version')?.value || 'undefined'}
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div style={{ paddingTop: '10px' }}>
                                                                            <span style={{ fontSize: '14px', fontWeight: 600 }}> Training Dataset Url : </span>
                                                                            <span style={{ color: '#1378D4' }}>
                                                                                {row?.tags.find((tag) => tag.key === 'baseline_datasource')?.value ||
                                                                                    'undefined'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Box>
                                                            </Collapse>
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </>
                                            ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                                    No registered model to display
                                </Typography>
                            )}
                            {overviewData && overviewData.length > 0 ? (
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    component="div"
                                    count={overviewData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            ) : (
                                ''
                            )}
                        </TableContainer>
                    </div>
                )}
                {isLoading && (
                    <Box sx={ProgressIcon.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                        <Typography variant="body2" sx={ProgressIcon.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default DlopsDashboard;
