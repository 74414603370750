import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import closeIconBlack from 'assets/images/close-icon-black.svg';
import ModalStyle from './style';

const Modal = (props) => {
    const { open, title, handleClose, divider, minWidth, children, width, downloadButton, showDownLoadButton, backBtn, showinfoIcon, infoIcon } = props;

    return (
        <Drawer data-testid="modal" PaperProps={{ sx: { minWidth, width } }} anchor="right" open={open}>
            <Box sx={ModalStyle.modalWrapper}>
                <Box sx={ModalStyle.headingWrapper} display="flex" alignItems="center" px={2} justifyContent="space-between">
                    <Box width="80%">
                        <Typography sx={ModalStyle.title} variant="subtitle1">
                            {backBtn && <>{backBtn}</>}
                            {title}
                            {showinfoIcon && <Box style={{ marginLeft: '5px' }}>{infoIcon}</Box>}
                        </Typography>
                    </Box>
                    <Box sx={ModalStyle.titleCloseIcon} width="20%">
                        {showDownLoadButton && <Box>{downloadButton}</Box>}
                        <IconButton color="inherit" onClick={handleClose} aria-label="close-modal" data-testid="close">
                            <img src={closeIconBlack} alt="" />
                        </IconButton>
                    </Box>
                </Box>
                {divider && <Divider />}
                <Box overflow="auto" flexGrow={1} p={2}>
                    {children}
                </Box>
            </Box>
        </Drawer>
    );
};

Modal.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    divider: PropTypes.bool,
    children: PropTypes.element,
    minWidth: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    width: PropTypes.string,
    showDownLoadButton: PropTypes.bool,
    downloadButton: PropTypes.element,
    backBtn: PropTypes.element,
    showinfoIcon: PropTypes.bool,
    infoIcon: PropTypes.element,
};

Modal.defaultProps = {
    title: 'modal title',
    open: false,
    divider: false,
    minWidth: '40%',
    width: 'auto',
    children: <span>content here</span>,
    showDownLoadButton: false,
    downloadButton: <span>content here</span>,
    backBtn: undefined,
    showinfoIcon: false,
    infoIcon: <span>infoicon</span>,
};

export default React.memo(Modal);
