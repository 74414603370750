export const eiapSave = 'eiap-save';

export const REGION_DISPLAY_NAMES = {
    AUSTRALIA_EAST: 'Australia East',
    SOUTHEAST_ASIA: 'Southeast Asia',
};

export const ERROR_MESSAGES = {
    SAM_UNIX_ID_GROUP_NOT_AVAILABLE: 'The provided SAM UNIX ID and Group are not available.',
    SAM_UNIX_ID_GROUP_AVAILABLE: 'The provided SAM UNIX ID and Group are available.',
};

export default {};
