import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Box, Stack, Typography } from '@mui/material';
import { commonStyles as style } from 'assets/style';

const Empty = ({ isLoading, msg, fixed, children, notFound, isPopup, width, color, zIndex }) => (
    <>
        {(isLoading || msg) && (
            <Backdrop
                open
                sx={{
                    width: '100%',
                    height: '100%',
                    zIndex,
                    top: fixed ? 58 : 0,
                    background: 'transparent',
                    position: fixed ? 'absolute' : 'relative',
                }}
                data-testid="emptyLayout"
            >
                <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" minHeight={200}>
                    {isLoading && <Typography component="span" style={style.largeCircularprogressButton} />}
                    {!isLoading && (
                        <Stack className="animate-opacity" direction="column" alignItems="center">
                            {notFound && (
                                <img src={isPopup ? '/static/images/no-data-white.svg' : '/static/images/no-data.svg'} alt="" width={width} height={width} />
                            )}
                            {msg && !children && (
                                <Typography variant="h6" color={color} fontSize="10px">
                                    {msg}
                                </Typography>
                            )}
                            {children && <>{children}</>}
                        </Stack>
                    )}
                </Box>
            </Backdrop>
        )}
    </>
);

Empty.propTypes = {
    isLoading: PropTypes.bool,
    fixed: PropTypes.bool,
    notFound: PropTypes.bool,
    isPopup: PropTypes.bool,
    msg: PropTypes.string,
    width: PropTypes.number,
    zIndex: PropTypes.number,
    children: PropTypes.element,
    color: PropTypes.string,
};

Empty.defaultProps = {
    isLoading: false,
    fixed: false,
    notFound: false,
    isPopup: false,
    msg: 'No data',
    width: 200,
    zIndex: 999,
    children: undefined,
    color: '#FFFFFF',
};

export default React.memo(Empty);
