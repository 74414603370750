import React from 'react';
import { Box, Stack, Grow } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
// import { FaTrashAlt } from 'react-icons/fa';
import deleteIcon from 'assets/images/delete_icon.svg';
import { orderPayload, allowedHeaders, selectedVSService, currentSubModule } from 'pages/provisioning/virtual-service/store';
import { addExposeHeader, removeExposeHeader, updateExposeHeader } from 'pages/provisioning/virtual-service/store/selectors';
// import FormLabel from 'components/common/FormElements/FormLabel';
import Styles from 'pages/provisioning/virtual-service/style';
import Select from 'components/select';
import Btn from 'components/button';
// import AddIcon from '@mui/icons-material/Add';
import ConfirmDialog from 'pages/provisioning/virtual-service/dialog-box/ConfirmDialog';
import InfoImg from 'components/info-img';
import { commonStyles as style } from 'assets/style';

const ExposeHeaders = () => {
    const [isModalOpen, setModal] = React.useState(false);
    const [confirmModal, setConfirmModal] = React.useState(null);

    const [order] = useRecoilState(orderPayload);
    const [allowedHeadersOptions] = useRecoilState(allowedHeaders);
    const [selectedVS] = useRecoilState(selectedVSService);
    const [, setCurentSubModule] = useRecoilState(currentSubModule);
    const addHeader = useSetRecoilState(addExposeHeader);
    const removeHeader = useSetRecoilState(removeExposeHeader);
    const updateHeader = useSetRecoilState(updateExposeHeader);

    const handleValueChange = (value, index) => {
        updateHeader({ value, index, selectedVS });
    };

    const handleAction = (type) => {
        if (type === 'yes') {
            removeHeader({ confirmModal, selectedVS });
        }
        setConfirmModal(null);
        setModal(false);
    };

    React.useEffect(() => {
        setCurentSubModule('exposeHeaders');
        return () => {
            setCurentSubModule(null);
        };
    }, []);
    return (
        <>
            {order?.aksVirtualService?.[selectedVS]?.aksCors?.exposeHeaders?.map((header, index) => (
                <React.Fragment key={`expose-header-grow-${index}`}>
                    <Grow
                        in={!!order?.aksVirtualService?.[selectedVS]?.aksCors?.exposeHeaders?.length}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(order?.aksVirtualService?.[selectedVS]?.aksCors?.exposeHeaders?.length ? { timeout: 500 } : {})}
                    >
                        <Stack direction="row" spacing={2} sx={{ mb: 2 }} key={index.toString()} alignItems="center">
                            <Box display="flex" alignItems="center" gap={1} width="100%">
                                <Select
                                    key="sector"
                                    label="Header"
                                    value={header?.exposeHeader || null}
                                    options={allowedHeadersOptions.filter(
                                        (option) =>
                                            !order?.aksVirtualService?.[selectedVS]?.aksCors?.exposeHeaders?.some(
                                                (headerValue) => headerValue?.exposeHeader === option
                                            )
                                    )}
                                    isRequired={header?.isExposeHeaderTouched}
                                    handleOnSelect={(value) => handleValueChange(value, index)}
                                    data-testid={`expose-header-autocomplete-${index}`}
                                />
                                <InfoImg title="The expose header in a virtual service defines HTTP headers exposed by an application to downstream clients." />
                            </Box>
                            {order?.aksVirtualService?.[selectedVS]?.aksCors?.exposeHeaders.length > 1 && (
                                <Box>
                                    <Box>
                                        {order?.aksVirtualService?.[selectedVS]?.aksCors?.exposeHeaders.length > 1 ? (
                                            <Box
                                                data-testid={`remove-expose-header-${index}`}
                                                style={Styles.vsType.delete}
                                                onClick={() => {
                                                    setConfirmModal({ header, index });
                                                    setModal(true);
                                                }}
                                            >
                                                <img height="16px" width="16px" src={deleteIcon} alt="deleteIcon" />
                                            </Box>
                                        ) : /*  <FaTrashAlt
                                                data-testid={`remove-expose-header-${index}`}
                                                style={Styles.vsType.delete}
                                                onClick={() => {
                                                    setConfirmModal({ header, index });
                                                    setModal(true);
                                                }}
                                                className="cursor-pointer"
                                            /> */
                                        null}
                                    </Box>
                                </Box>
                            )}
                        </Stack>
                    </Grow>
                    {index === order?.aksVirtualService?.[selectedVS]?.aksCors?.exposeHeaders.length - 1 &&
                    order?.aksVirtualService?.[selectedVS]?.aksCors?.exposeHeaders?.length < 10 ? (
                        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', pt: 1 }}>
                            <Btn
                                variant="contained"
                                sx={style.serviceTagBtn}
                                // sx={{ minWidth: '42px', width: 'auto', height: '32px', marginTop: '-10px' }}
                                disableFocusRipple
                                disableRipple
                                onClick={() => addHeader({ count: 1, selectedVS })}
                                disabled={false}
                                data-testid="add-expose-header"
                            >
                                {/* <AddIcon sx={{ fontSize: '25px' }} /> */}
                                Add another expose header
                            </Btn>
                        </Box>
                    ) : (
                        <span className="w-7 h-7" />
                    )}
                </React.Fragment>
            ))}
            {isModalOpen && (
                <ConfirmDialog
                    isModalOpen={isModalOpen}
                    title="Are you sure you want to remove Expose Header?"
                    primaryBtnText="Yes"
                    secondaryBtnText="No"
                    primaryBtnAction={() => handleAction('yes')}
                    secondaryBtnAction={() => handleAction('no')}
                />
            )}
        </>
    );
};

export default React.memo(ExposeHeaders);
