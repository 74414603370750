import React from 'react';
import { Box } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useRecoilState } from 'recoil';
import axios, { endPoints } from 'api/request';
import { useMutation } from 'react-query';
import throttle from 'lodash/throttle';
import Select from 'pages/reference-implementation/components/select';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI002O/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

const filter = createFilterOptions();
export const ITEApplicationService = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState(order?.IteAppProfileDetails || null);
    const [ccInputValue, setCCInputValue] = React.useState(order?.iteAppService || '');

    const iteAppServiceApi = useMutation(() =>
        axios.get(`${endPoints.riServices.COMMON.application}?applicationServiceName=${ccInputValue}&includeIteApplicationsOnly=true`)
    );

    const setIteApplicationService = (iteAppService) => {
        setOrder({
            ...order,
            iteAppService,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) {
                    iteAppServiceApi.mutate();
                }
            }, 1000),
        [ccInputValue]
    );

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (iteAppServiceApi?.isSuccess) {
            if (iteAppServiceApi?.data?.data?.applications) {
                const combinedValue = iteAppServiceApi?.data?.data?.applications?.map((app) => ({
                    label: `${app?.serviceName}`,
                    value: `${app?.serviceName}`,
                }));
                setOptions(combinedValue);
                setOrder({
                    ...order,
                    appServiceDetails: iteAppServiceApi?.data?.data?.applications[0],
                });
            }
        }
    }, [iteAppServiceApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.cloudProvider && order?.cloudService && ccInputValue?.length > 2) {
            iteAppServiceApi.mutate();
        }
    }, [order?.cloudProvider, order?.cloudService]);

    React.useEffect(() => {
        if (value !== null && value?.label) {
            setIteApplicationService(value?.value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.iteAppService && order?.isTouched) setIsTouched(true);
    }, [order?.iteAppService, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="app-ci-autocomplete"
                key="IteApplicationService"
                label={<InputLabel label="ITE Application Service" isRequired />}
                placeholder="Type to search"
                value={order?.iteAppService || null}
                onInputChange={(event, newInputValue) => {
                    setCCInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            label: newValue,
                        });
                    } else if (newValue && newValue?.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            label: newValue?.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                onBlur={() => {
                    if (ccInputValue === '' || ccInputValue === null) {
                        setOrder({
                            ...order,
                            iteAppService: null,
                        });
                    }
                }}
                onKeyUp={(event) => {
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                        setOrder({
                            ...order,
                            iteAppService: null,
                        });
                    }
                }}
                filterOptions={(optionValues, params) => filter(optionValues, params)}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                }}
                renderOption={(props, option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <li {...props}>{option.label}</li>
                )}
                // sx={ProvisioningStyles.costCenter}
                freeSolo
                isRequired={isTouched}
                isLoading={iteAppServiceApi?.isLoading}
            />
        </Box>
    );
};

export default React.memo(ITEApplicationService);
