import React, { useEffect } from 'react';
import { Box, Grid, CircularProgress, Typography } from '@mui/material';
import Modal from 'components/modal';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
    toggleLandingZoneModal,
    landingZoneData,
    landZoneListFilter,
    islandingZoneLabelVaild,
    islandingZoneDescVaild,
    isISADocumentVaild,
    isAppNameValid,
    isProjectNameVaild,
    isLdapVaild,
} from 'pages/app-profiles/store';
import Form from 'pages/app-profiles/landing-zones-new/components/Form';
import {
    iaasVMCatalogServiceCode,
    iaasOracleCatalogServiceCode,
    iaasSQLCatalogServiceCode,
    paasSQLCatalogServiceCode,
    storageAccountCatalogServiceCode,
    aksCatalogServiceCode,
    iaasCouchbaseServiceCode,
    keyvaultCatalogServiceCode,
    AlbCatalogServiceCode,
    eventhubCatalogServiceCode,
    kafkaCatalogServiceCode,
    eipStackCatalogServiceCode,
    adfCatalogServiceCode,
} from 'utils/catalogServiceCode';
import ErrorBanner from 'components/ErrorBanner';
import Cmp from 'pages/app-profiles/landing-zones-new/components';
import Btn from 'components/button';
import toast from 'react-hot-toast';
import LandingZoneStyle from 'pages/app-profiles/landing-zones/LandingZoneStyle';
import { commonStyles as style } from 'assets/style';
import { getPayload } from './getPayload';
import { getSubmitData } from './getSubmitData';
import { formLandingZoneObject, resetLandingZoneData, clearLandingZoneFromLocalStorage, getLandingZoneFromLocalStorage } from './utils';

const LandingZoneModal = (props) => {
    const history = useHistory();
    const params = useParams();
    const { id, operation, landingZoneId } = params;
    const { appProfileName } = props;
    const [isLoading, setLoading] = React.useState(false);
    const [landingZoneModal, setLandingZoneModal] = useRecoilState(toggleLandingZoneModal);
    const [landingZoneDetails, setLandingZoneDetails] = useRecoilState(landingZoneData);
    const [filterObj, setFilterObj] = useRecoilState(landZoneListFilter);
    const [labelValid] = useRecoilState(islandingZoneLabelVaild);
    const [ISADocumentValid] = useRecoilState(isISADocumentVaild);
    const [descValid] = useRecoilState(islandingZoneDescVaild);
    const [appNameValid] = useRecoilState(isAppNameValid);
    const [projectNameVaild] = useRecoilState(isProjectNameVaild);
    const [ldapVaild] = useRecoilState(isLdapVaild);
    const [open, setOpen] = React.useState(false);

    const createApi = useMutation((payload) => axios.post(`${endPoints.landingZones.createLandingZone}`, payload));
    const updateApi = useMutation((payload) => axios.put(`${endPoints.landingZones.editLandingZone}`, payload));
    const handleClickErrorBanner = () => {
        setOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setOpen(false);
    };
    const handleClose = () => {
        handleCloseErrorBanner();
        if (!getLandingZoneFromLocalStorage()) {
            setLandingZoneModal({ ...landingZoneModal, isOpen: false });
            history.push(`/app-details/${id}`);
        }
        if (getLandingZoneFromLocalStorage() && !(landingZoneDetails?.service === eipStackCatalogServiceCode)) {
            const data = getLandingZoneFromLocalStorage();
            setLandingZoneDetails(data);
            setLandingZoneModal({ ...landingZoneModal, isOpen: true });
        }
        if (getLandingZoneFromLocalStorage() && landingZoneDetails?.service === eipStackCatalogServiceCode) {
            clearLandingZoneFromLocalStorage();
            setLandingZoneModal({ ...landingZoneModal, isOpen: false });
            history.push(`/app-details/${id}`);
        }
    };
    async function checkOperation() {
        if (operation === 'create') {
            setLandingZoneDetails({ ...landingZoneDetails, ...resetLandingZoneData });
        }
        if (operation === 'edit') {
            const dataObj = await axios.get(`${endPoints.landingZones.editLandingZone}/${landingZoneId}`);
            const landingZoneDataObj = await formLandingZoneObject(dataObj?.data);
            setLandingZoneDetails(landingZoneDataObj);
        }
    }

    useEffect(() => {
        if (operation) {
            setLandingZoneDetails({ ...landingZoneDetails, ...resetLandingZoneData });
            checkOperation();
        }
    }, [operation]);

    const isReadyToSubmit = getSubmitData({ data: landingZoneDetails, labelValid, descValid, ISADocumentValid, appNameValid, projectNameVaild, ldapVaild });

    const handleSubmit = async () => {
        if (isReadyToSubmit) {
            handleClickErrorBanner();
            setLandingZoneDetails({ ...landingZoneDetails, isTouched: true });
        } else {
            const data = getLandingZoneFromLocalStorage();
            if (data && data?.environment !== landingZoneDetails?.environment) {
                toast.error(`Choose environment as ${data?.environment} and region as ${data?.region} which you choosed on EIP stack.`);
            } else if (data && data?.region !== landingZoneDetails?.region) {
                toast.error(`Choose environment as ${data?.environment} and region as ${data?.region} which you choosed on EIP stack.`);
            } else {
                if (landingZoneDetails?.service === eipStackCatalogServiceCode) clearLandingZoneFromLocalStorage();
                const payload = await getPayload({ appName: appProfileName, data: landingZoneDetails, operation, landingZoneId });
                if (operation === 'create') {
                    createApi.mutate(payload);
                } else {
                    updateApi?.mutate(payload);
                }
            }
        }
    };

    React.useEffect(() => {
        if (createApi?.isSuccess && !getLandingZoneFromLocalStorage()) {
            setFilterObj({ ...filterObj, pageNumber: 1 });
            handleClose();
            toast.success(createApi?.data?.messages);
        }
        if (createApi?.isSuccess && getLandingZoneFromLocalStorage()) {
            const data = getLandingZoneFromLocalStorage();
            const components = data?.components?.map((item) => ({
                ...item,
                ...(item?.service === createApi?.data?.data?.cloudService && { landingZone: null, landingZoneDetails: { id: createApi?.data?.data?.id } }),
            }));
            data.components = components;
            setLandingZoneDetails(data);
            setLandingZoneModal({ ...landingZoneModal, isOpen: true });
            toast.success(createApi?.data?.messages);
        }
    }, [createApi?.isSuccess]);
    React.useEffect(() => {
        if (updateApi?.isSuccess) {
            setFilterObj({ ...filterObj, pageNumber: 1 });
            handleClose();
            toast.success(updateApi?.data?.messages);
            if (landingZoneDetails?.service === eipStackCatalogServiceCode) clearLandingZoneFromLocalStorage();
        }
    }, [updateApi?.isSuccess]);
    return (
        <Modal
            title={operation === 'create' ? `Create Landing Zone - ${appProfileName}` : `Modify Landing Zone - ${appProfileName}`}
            handleClose={() => handleClose()}
            open={landingZoneModal?.isOpen}
            sx={LandingZoneStyle?.createModal?.modal}
        >
            <Box style={LandingZoneStyle?.createModal?.wrapper}>
                {isLoading && (
                    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress color="inherit" size={16} sx={{ color: (theme) => theme.palette.black.dark }} />
                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.black.dark }}>
                            Loading
                        </Typography>
                    </Box>
                )}
                {!isLoading && (
                    <Box style={LandingZoneStyle?.createModal?.wrapper}>
                        {open && (
                            <ErrorBanner
                                show={open}
                                handleCloseCallback={handleCloseErrorBanner}
                                message="Error! Please fill out the missing values marked with the * asterisk and click save."
                            />
                        )}
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Box sx={LandingZoneStyle?.createModal?.fieldValue}>
                                    <Form.Provider />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={LandingZoneStyle?.createModal?.fieldValue}>
                                    <Form.Service />
                                </Box>
                            </Grid>
                            {landingZoneDetails?.service === iaasVMCatalogServiceCode && <Cmp.VmFields />}
                            {landingZoneDetails?.service === iaasSQLCatalogServiceCode && <Cmp.IaaSSQLFields />}
                            {landingZoneDetails?.service === iaasOracleCatalogServiceCode && <Cmp.OracleFields />}
                            {landingZoneDetails?.service === paasSQLCatalogServiceCode && <Cmp.PaaSSQLFields />}
                            {landingZoneDetails?.service === storageAccountCatalogServiceCode && <Cmp.StorageAccountFields />}
                            {landingZoneDetails?.service === aksCatalogServiceCode && <Cmp.AKSNamespaceFields />}
                            {landingZoneDetails?.service === iaasCouchbaseServiceCode && <Cmp.CouchbaseFields />}
                            {landingZoneDetails?.service === keyvaultCatalogServiceCode && <Cmp.AKSKeyVaultFields />}
                            {landingZoneDetails?.service === AlbCatalogServiceCode && <Cmp.ALBFields />}
                            {landingZoneDetails?.service === eventhubCatalogServiceCode && <Cmp.EventHubFields />}
                            {landingZoneDetails?.service === kafkaCatalogServiceCode && <Cmp.KafkaFields />}
                            {landingZoneDetails?.service === eipStackCatalogServiceCode && (
                                <Cmp.EIAPStackFields appProfileName={appProfileName} windowType={operation} setLoading={setLoading} />
                            )}
                            {landingZoneDetails?.service === adfCatalogServiceCode && <Cmp.ADFFields />}
                        </Grid>

                        <Box sx={LandingZoneStyle?.createModal?.btnWrapper}>
                            <Btn
                                variant="contained"
                                // color="cmpPrimary"
                                onClick={() => handleSubmit()}
                                disabled={createApi?.isLoading}
                                // style={{ textTransform: 'none' }}
                                sx={style.modalSaveButton}
                                data-testid="save-btn"
                            >
                                Save
                                {(createApi?.isLoading || updateApi?.isLoading) && (
                                    <CircularProgress sx={LandingZoneStyle?.createModal?.circularIcon} color="inherit" size={14} />
                                )}
                            </Btn>
                            <Btn
                                // style={LandingZoneStyle?.createModal?.cancelBtn}
                                sx={{ ...style.modalCancelButton, marginLeft: '10px' }}
                                variant="outlined"
                                layout="outline"
                                // color="cmpWarning"
                                onClick={() => handleClose()}
                                data-testid="cancel-btn"
                            >
                                Cancel
                            </Btn>
                        </Box>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

LandingZoneModal.propTypes = {
    appProfileName: PropTypes.string,
};
LandingZoneModal.defaultProps = {
    appProfileName: '',
};
export default LandingZoneModal;
