import React from 'react';
import { Box, Card, Chip, Grid, Typography } from '@mui/material';
import GithubOnboardingStyles from 'pages/github-onboarding/style';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/github-onboarding/store';

const ReposAccessControlDetails = () => {
    const [order] = useRecoilState(OrderPayload);

    return (
        <Box sx={GithubOnboardingStyles?.preview?.wrapper}>
            <Box sx={GithubOnboardingStyles?.preview?.heading}>Repo access control</Box>
            {order?.reposAssignedToTeams.map(({ teamName, isDefault, repos }, teamIndex) =>
                repos.length ? (
                    <Card key={teamIndex} sx={{ ...GithubOnboardingStyles.card, marginTop: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                            <Typography variant="h4" mr={1} sx={GithubOnboardingStyles?.preview?.teamHeader}>
                                {teamName}
                            </Typography>
                            <Chip label={isDefault ? 'Default' : 'Custom'} size="small" color="primary" />
                        </Box>
                        <Grid container sx={GithubOnboardingStyles?.preview?.projectDetails?.container} spacing={3}>
                            <Grid item xs={4} sm={4}>
                                <Typography variant="h4" sx={{ fontWeight: '500' }}>
                                    Repositories Added:
                                </Typography>
                                {repos.map((member, repoIndex) => (
                                    <Box key={repoIndex} sx={GithubOnboardingStyles?.preview?.projectDetails?.value}>
                                        {member}
                                    </Box>
                                ))}
                            </Grid>
                        </Grid>
                    </Card>
                ) : null
            )}
        </Box>
    );
};

export default ReposAccessControlDetails;
