import React, { useMemo, useState } from 'react';
import { Grid, Box } from '@mui/material';
import Heading from 'pages/reference-implementation/components/Heading';
import FormCard from 'pages/reference-implementation/components/form-card';
import { useRecoilState, useRecoilValue } from 'recoil';
import { OrderPayload, componentGroupsState, SecondaryApprover, isAppShortNameValid } from 'pages/reference-implementation/provisioning/RI004O/store';
import ResourceList from 'pages/reference-implementation/provisioning/RI004O/components/Form/ResourceList';
import ConfigurationModal from 'pages/reference-implementation/provisioning/RI004O/components/resource-mgmt/configuration/ConfigurationModal';
import ComponentSelectionModal from 'pages/reference-implementation/provisioning/RI004O/components/resource-mgmt/insert-components/ComponentSelectionModal';
import { getComponentsFromSchema } from 'pages/reference-implementation/provisioning/RI004O/utils';
import { getFirstErrorMessage, validateCloning, processComponentUpdates } from 'pages/reference-implementation/provisioning/RI004O/utils/validateSelection';
import ErrorBanner from 'pages/reference-implementation/components/error-banner';
import ConfigDrawer from 'pages/reference-implementation/provisioning/RI004O/components/resource-mgmt/ConfigDrawer';
import { selectedEnv } from 'pages/reference-implementation/provisioning/RI004O/utils/constant';
import { InputInfo } from 'pages/reference-implementation/components/input-info';
import { RI003Styles } from 'pages/reference-implementation/provisioning/RI003O/style';

const ResourceMgmtSection = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [userSelectedResource, setUserSelectedResource] = useState(null);
    const [currentConfigIndex, setCurrentConfigIndex] = useState(null);
    const isDrawerOpen = useMemo(() => !!userSelectedResource, [userSelectedResource]);
    const componentGroups = useRecoilValue(componentGroupsState);
    const [isOpenAdd, setIsOpenAdd] = React.useState(false);
    const [secondApprover, setSecondaryApprover] = useRecoilState(SecondaryApprover);
    const appShortNameValid = useRecoilValue(isAppShortNameValid);

    const [errorBannerOpen, setErrorBannerOpen] = React.useState({
        isOpen: false,
        message: 'Error! Please fill out the missing values marked with the * asterisk and click submit.',
    });
    const [isErrorOnSave, setIsErrorOnSave] = useState(false);
    // const [showConsumerModal, setShowConsumerModal] = useState(false);
    const [cloneErrors, setCloneErrors] = useState({});

    const handleClickErrorBanner = (message) => {
        setErrorBannerOpen({ isOpen: true, message });
    };
    const handleCloseErrorBanner = (message) => {
        setErrorBannerOpen({ isOpen: false, message });
    };
    const handleCloneResource = (index) => {
        const resourceMgmt = order?.resourceMgmt?.[selectedEnv];
        const newResource = resourceMgmt?.components[index];
        const resourceMgmtComponents = [...resourceMgmt?.components];
        const resourcestoAdd = [...resourceMgmtComponents.slice(0, index + 1), newResource, ...resourceMgmtComponents.splice(index + 1)];
        const updatedResourceComponents = {
            ...resourceMgmt,
            components: resourcestoAdd,
        };

        const validationErrors = validateCloning(newResource, resourceMgmt);
        setCloneErrors({ ...validationErrors, ...cloneErrors });
        if (!Object.keys(validationErrors)?.includes(`${newResource?.componentId}-${newResource?.componentName}`)) {
            setOrder((prevOrder) => ({
                ...prevOrder,
                resourceMgmt: {
                    ...prevOrder.resourceMgmt,
                    [selectedEnv]: updatedResourceComponents,
                },
            }));
        }
    };

    const handleRemoveResource = (index) => {
        const updatedResources = order?.resourceMgmt?.[selectedEnv]?.components?.filter((_, idx) => idx !== index);
        setOrder((prevOrder) => ({
            ...prevOrder,
            resourceMgmt: {
                ...prevOrder.resourceMgmt,
                [selectedEnv]: { components: updatedResources },
            },
        }));
    };
    const handleAddNewComponent = () => setIsOpenAdd(!isOpenAdd);

    const handleInsert = (selectedComponents, errors) => {
        if (Object.keys(errors).length > 0) {
            handleClickErrorBanner(getFirstErrorMessage(errors) || null);
            return;
        }
        const currentComponents = order?.resourceMgmt?.[selectedEnv]?.components || [];
        const updatedComponents = processComponentUpdates(currentComponents, selectedComponents, componentGroups);

        // Update the resourceMgmt in the order
        setOrder((prevOrder) => ({
            ...prevOrder,
            resourceMgmt: {
                ...prevOrder.resourceMgmt,
                [selectedEnv]: {
                    components: updatedComponents,
                },
            },
        }));

        setIsOpenAdd(false);
    };

    const handleConfigureResource = (index) => {
        setCurrentConfigIndex(index);
        setUserSelectedResource(order?.resourceMgmt?.[selectedEnv]?.components?.[index]);
    };

    const handleCancleDrawer = () => {
        setErrorBannerOpen({
            isOpen: false,
            message: '',
        });
        setIsErrorOnSave(false);
        setUserSelectedResource(null);
    };
    const handleDrawerAction = (type, formData, errors) => {
        if (type === 'cancel') {
            handleCancleDrawer();
            return;
        }

        if (!errors?.isValid) {
            setErrorBannerOpen({
                isOpen: true,
                message: 'Error! Please fill out the missing values marked with the * asterisk and click submit.',
            });
            return;
        }
        if (type === 'save') {
            // Update the selected component's configuration data
            const updatedComponents = order?.resourceMgmt?.[selectedEnv]?.components.map((component, index) => {
                if (index === currentConfigIndex) {
                    return {
                        ...component,
                        configureData: formData,
                    };
                }
                return component;
            });

            // Update the order state with the new components array
            setOrder((prevOrder) => ({
                ...prevOrder,
                resourceMgmt: {
                    ...prevOrder.resourceMgmt,
                    [selectedEnv]: {
                        components: updatedComponents,
                    },
                },
            }));
            handleCancleDrawer();
        }
    };

    const handleSecondApprover = (approverVal) => {
        setSecondaryApprover(approverVal);
    };
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Box sx={{ display: 'flex' }}>
                        <Heading title="Resource Management" />
                        <Box sx={RI003Styles?.ResourceMgmtSectionStyle?.infoIconWrapper}>
                            <InputInfo
                                iconFontSize="16px"
                                maxWidth="400px"
                                title="Sector, Environment (Division, Application Regional - depending on sector) must be filled in before enabling resource management."
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormCard>
                        <ResourceList
                            resources={order?.resourceMgmt?.[selectedEnv]?.components}
                            handleAddResource={handleCloneResource}
                            handleRemoveResource={handleRemoveResource}
                            handleConfigureResource={handleConfigureResource}
                            handleAddNewComponent={handleAddNewComponent}
                            disableCloneIcon={cloneErrors}
                            resourceMgmt={order?.resourceMgmt?.[selectedEnv]}
                            cloneErrors={cloneErrors}
                            order={order}
                            appShortNameValid={appShortNameValid}
                            componentGroups={componentGroups}
                        />
                    </FormCard>
                </Grid>
            </Grid>
            {isDrawerOpen && (
                <ConfigDrawer
                    isOpen={isDrawerOpen}
                    isSubOpen={false}
                    title={order?.resourceMgmt?.[selectedEnv]?.components?.[currentConfigIndex]?.componentName}
                    subHeader={null}
                    handleDrawerAction={handleDrawerAction}
                >
                    <>
                        <ConfigurationModal
                            userSelectedResource={order?.resourceMgmt?.[selectedEnv]?.components?.[currentConfigIndex]}
                            isErrorOnSave={isErrorOnSave}
                            handleDrawerAction={handleDrawerAction}
                            order={order}
                            secondApprover={secondApprover}
                            handleSecondOwner={handleSecondApprover}
                        />
                    </>
                </ConfigDrawer>
            )}
            {isOpenAdd ? (
                <ComponentSelectionModal
                    currentComponents={order?.resourceMgmt?.[selectedEnv]?.components || []}
                    componentGroups={getComponentsFromSchema({ componentGroups, exceptPrompt: ['mandatoryComponents'] })}
                    open={isOpenAdd}
                    onClose={() => handleAddNewComponent()}
                    onInsert={handleInsert}
                />
            ) : null}
            {errorBannerOpen?.isOpen && (
                <ErrorBanner show={errorBannerOpen?.isOpen} message={errorBannerOpen?.message} handleCloseCallback={() => handleCloseErrorBanner(null)} />
            )}
        </>
    );
};

export default ResourceMgmtSection;
