import React from 'react';
import Modal from 'components/modal';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useRecoilState, useResetRecoilState } from 'recoil';
import Alert from '@mui/material/Alert';
import toast from 'react-hot-toast';
import Ap from 'pages/app-profiles/profile/components/Form';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
// import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
import { appProfileTypeState, profileForm, toggleCreateProfile } from 'pages/app-profiles/store';
import Btn from 'components/button';
import { commonStyles as style } from 'assets/style';
import profileStyle, { CssTextField } from './style';
import { getPayload } from './getPayload';

// eslint-disable-next-line react/prop-types
const CreateAppProfile = () => {
    const { t } = useTranslation();
    const [appProfileType, setAppProfileType] = useRecoilState(appProfileTypeState);
    const [showUpdatedMsg, setShowUpdatedMsg] = React.useState(false);
    const [form, setForm] = useRecoilState(profileForm);
    const resetForm = useResetRecoilState(profileForm);
    const [modal, setModal] = useRecoilState(toggleCreateProfile);
    const createApi = useMutation((payload) => axios.post(`${endPoints.appProfile.submitAppProfile}`, payload));
    const updateApi = useMutation((payload) => axios.put(`${endPoints.appProfile.submitAppProfile}`, payload));

    const isReadyToSubmit = () =>
        !form?.appName ||
        !form?.businessCI ||
        !form?.financialDetails ||
        !form?.projectName ||
        !form?.recoveryPriority ||
        // // !form?.billingEntity ||
        // // !form?.sector ||
        !form?.appOwner;

    const handleSubmit = () => {
        const payload = getPayload({ data: form, type: modal?.type });
        if (modal?.type === 'create') createApi.mutate(payload);
        if (modal?.type === 'edit') {
            updateApi.mutate(payload);
        }
    };

    React.useEffect(() => {
        if (modal?.isOpen === false) {
            resetForm();
            setShowUpdatedMsg(false);
        }
    }, [modal?.isOpen]);
    React.useEffect(() => {
        if (createApi?.isSuccess) {
            setAppProfileType({ ...appProfileType, pageNumber: 1 });
            setModal({ ...modal, isOpen: false });
            toast.success(createApi?.data?.messages);
        }
    }, [createApi?.isSuccess]);
    React.useEffect(() => {
        if (updateApi?.isSuccess) {
            setAppProfileType({ ...appProfileType, pageNumber: 1 });
            toast.success(updateApi?.data?.messages[0]);
            setModal({ ...modal, isOpen: false });
        }
    }, [updateApi?.isSuccess]);
    return (
        <Modal
            title={modal?.type === 'create' ? t([`appProfile.title.newApp`]) : t([`appProfile.title.modifyApp`])}
            handleClose={() => setModal({ ...modal, isOpen: false })}
            open={modal?.isOpen}
        >
            <Box style={profileStyle.createAppProfile.wrapper}>
                <Box flexGrow={1} sx={profileStyle.createAppProfile.container}>
                    {showUpdatedMsg && modal?.type === 'edit' && (
                        <Box sx={profileStyle.createAppProfile.alertBox}>
                            <Alert variant="filled" severity="success" sx={profileStyle.createAppProfile.alert}>
                                Modified Data saved successfully
                            </Alert>
                        </Box>
                    )}
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            {' '}
                            <Ap.BusinessCI />
                        </Grid>
                        <Grid item md={6}>
                            <CssTextField
                                key="appName"
                                autoComplete="off"
                                fullWidth
                                label="App Name"
                                size="small"
                                variant="outlined"
                                onChange={(e) => setForm({ ...form, appName: e.target.value })}
                                value={form?.appName ?? ''}
                                data-testid="app-name"
                                // disabled
                            />
                        </Grid>
                        <Grid item md={6}>
                            {' '}
                            <Ap.FinancialDetails />
                        </Grid>
                        <Grid item md={6}>
                            <CssTextField
                                disabled
                                key="projectName"
                                autoComplete="off"
                                fullWidth
                                label="Project Name"
                                size="small"
                                variant="outlined"
                                onChange={(e) => setForm({ ...form, projectName: e.target.value })}
                                value={form?.projectName ?? ''}
                                data-testid="project-name"
                            />
                        </Grid>
                        <Grid item md={6}>
                            <CssTextField
                                disabled
                                key="recoveryPriority"
                                autoComplete="off"
                                fullWidth
                                label="RecoveryPriority"
                                size="small"
                                variant="outlined"
                                onChange={(e) => setForm({ ...form, recoveryPriority: e.target.value })}
                                value={form?.recoveryPriority ?? ''}
                                data-testid="recovery-priority"
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Ap.BillingEntities />
                        </Grid>

                        <Grid item md={6}>
                            {' '}
                            <Ap.Sector />
                        </Grid>
                        <Grid item md={6}>
                            <Ap.AppOwner />
                        </Grid>
                    </Grid>
                    <Box display="flex" alignitems="center" justifyContent="flex-end" sx={profileStyle.createAppProfile.btnwrapper}>
                        <Btn
                            className="btn-disabled custom-btn"
                            display="flex"
                            alignitems="center"
                            variant="contained"
                            // color="cmpPrimary"
                            sx={style.modalSaveButton}
                            onClick={() => handleSubmit()}
                            style={{ textTransform: 'none' }}
                            disabled={createApi?.isLoading || isReadyToSubmit() || updateApi?.isLoading}
                            data-testid="create-btn"
                        >
                            {t([`common.btnText.save`])}
                            {(createApi?.isLoading || updateApi?.isLoading) && (
                                <CircularProgress sx={profileStyle.createAppProfile.circularProg} color="inherit" size={14} />
                            )}
                        </Btn>
                        <Btn
                            // style={profileStyle.createAppProfile.cancelbtn}
                            sx={{ ...style.modalCancelButton, marginLeft: '10px' }}
                            variant="outlined"
                            // color="cmpWarning"
                            layout="outline"
                            onClick={() => setModal({ ...modal, isOpen: false })}
                            data-testid="cancel-btn"
                        >
                            {t([`common.btnText.cancel`])}
                        </Btn>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateAppProfile;
