import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import { camelToTitle, toDateTime } from 'utils';
import mappingLabel from 'utils/mappingLabel';
import InfoImg from 'components/info-img';

const LabelValue = (props) => {
    const { label, value, isCamelToTitle, width, sx, children, istextTransform, showinfo, infoMsg } = props;
    const dateTimeRegex = /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/;

    const [labelHoverStatus, setLabelHover] = useState(false);

    const labelRef = useRef();
    const compareSize = () => {
        const compareAssignee = labelRef?.current?.scrollWidth > labelRef?.current?.clientWidth;
        setLabelHover(compareAssignee);
    };
    useEffect(() => {
        if (label) {
            compareSize();
            window.addEventListener('resize', compareSize);
        }
    }, [label]);

    // remove resize listener again on "componentWillUnmount"
    useEffect(
        () => () => {
            window.removeEventListener('resize', compareSize);
        },
        []
    );
    return (
        <Box data-testid="key_label" sx={sx} display="flex" className="key" flexWrap="wrap">
            <Tooltip
                interactive="true"
                disableHoverListener={!labelHoverStatus}
                title={label}
                componentsProps={{
                    tooltip: { sx: { backgroundColor: '#272727', fontSize: '10px' } },
                    arrow: { sx: { color: '#272727' } },
                }}
            >
                <Box
                    className="label"
                    width={width}
                    flex={`${showinfo ? 1.5 : 1.5}`}
                    ref={labelRef}
                    style={{
                        textTransform: `${istextTransform ? 'capitalize' : ''}`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {isCamelToTitle &&
                    ![
                        'DR Priority',
                        'VM Type',
                        'LogicalHost Name',
                        'SQL Database Name',
                        'vCore Count',
                        'AD OU',
                        'AD Group',
                        'SAM Account ID',
                        'OS disk type',
                        'OS Timezone',
                        'DB Size',
                        'ASR Enabled',
                        'business CI',
                        'ISADoc',
                        'FQDN',
                        'IP',
                        'IDM Request Number',
                        'ISA Document',
                        'ADLS Gen2 Enabled',
                        'TCP Reset',
                        'Floating IP',
                        'SKU',
                        'FQDNS',
                        'vNet',
                        'FrontEnd IPs',
                        'TLS Version',
                        'OS',
                        'Application Support DL',
                        'FQDN Name',
                        'IP Address',
                        'SSL Certificate',
                        'Databrick/AML Workspace',
                        'DNS RITM Request',
                        'SSL Certificate RITM Request',
                        'AKS Servicename',
                        'Kafka Servicename',
                        'SQL Servicename',
                        'ASR',
                        'HA Deployment',
                        'SAM Account',
                        'Couchbase DB Version',
                        'CLE Integration',
                        'ECE Integration',
                        'IT LDAP Group',
                        'ADF Name',
                        'Requestor GPID',
                        'SOX',
                        'Reader AD Group',
                        'Contributor AD Group',
                        'AD Group Owner',
                        'Power BI Gateway Request URL',
                        'IDM Request No',
                        'Application CI',
                        'Application URL',
                        'GPID',
                        'SQL Server Name',
                        'Application Owner ID',
                        'SSL Encryption',
                        'Frontend IP',
                        'RITM Number',
                        'SAS Url',
                        'Project ID',
                        'ADO Project Name',
                        'ADO Platform',
                        'SonarQube Code Repository',
                        'Application Service CI',
                        'H-Code',
                        'AD Group Owner',
                        'myIDM Message',
                        'SonarQube Service account (myIDM #)',
                        'Clarity ID',
                        'ADO Project Type',
                        'Destination URL',
                        'Recipient URL',
                        'Login URL',
                        'Logout URL',
                        'SonarQube Service Account Request',
                        'Backend FQDN',
                        'SCAN Number',
                        'ISA Number',
                        'Application Support Mail ID',
                        'Firewall Port',
                        'Application Gateway Name',
                        'Subscription ID',
                        'Entity ID',
                        'Certificate Owner 1 ID',
                        'Certificate Owner 2 ID',
                        'GTM LoadBalancer IP Address',
                        'GTM LoadBalancer RITM Number',
                        'Certificate Owner 1',
                        'Certificate Owner 2',
                        'DevX Infra Provisioning Request',
                        'NVMe Support',
                        'OS SKU Image Name',
                        'AKS Cluster',
                        'AKS Namespace',
                        'Transactional ID',
                        'Transaction ID Prefix',
                        'SAST Request Type',
                        'SAST Application Version',
                        'SCA Request Type',
                        'SCA Organization',
                        'SAST Application Name',
                        'LDAP Group',
                        'LDAP Group Name',
                        'Write Access User Name',
                        'Read Access User Name',
                        'CSA Request Number',
                        'Load Balancer URL',
                        'SAP SID Name',
                        'SAP GID Name',
                        'SAP SID Account Owner',
                        'SAP Solution',
                        'SAP UID',
                        'SAP GID',
                        'SQL IaaS ON',
                        'SQL Server Availability Group Name',
                        'SQL Server Availability Group Endpoint Name',
                        'SQL Server Windows Failover Cluster Name',
                        'SQL Server Listener Name',
                        'SQL Database Authentication',
                        'SQL Account Name',
                        ...Object.values(mappingLabel),
                    ].includes(label)
                        ? camelToTitle(label)?.trim()
                        : label}
                    {showinfo && (
                        <span style={{ marginLeft: '3px', marginRight: '4px' }}>
                            <InfoImg title={infoMsg} placement="bottom" />
                        </span>
                    )}
                    :
                </Box>
            </Tooltip>
            <Box className="value" width="auto" flex={2.2} sx={{ wordBreak: 'break-word' }}>
                {!children && (dateTimeRegex.test(value) ? toDateTime(value) : value || '-')}
                {children && <>{children}</>}
            </Box>
        </Box>
    );
};

LabelValue.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    isCamelToTitle: PropTypes.bool,
    width: PropTypes.any,
    children: PropTypes.element,
    sx: PropTypes.objectOf(PropTypes.string),
    istextTransform: PropTypes.bool,
    showinfo: PropTypes.bool,
    infoMsg: '',
};

LabelValue.defaultProps = {
    value: '-',
    isCamelToTitle: true,
    width: 200,
    children: null,
    sx: { padding: 1 },
    istextTransform: true,
    showinfo: false,
    infoMsg: '',
};

export default React.memo(LabelValue);
