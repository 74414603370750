import React from 'react';
import { Box, Grow, Typography, Grid } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { orderPayload, selectedVSService, multiFieldValidation, restrictedPorts, validationStatus } from 'pages/provisioning/virtual-service/store';
import { addMultiContextPath, removeMultiContextPath, updateMultiSelection } from 'pages/provisioning/virtual-service/store/selectors';
import Btn from 'components/button';
// import { FaTrashAlt } from 'react-icons/fa';
import deleteIcon from 'assets/images/delete_icon.svg';
import ConfirmDialog from 'pages/provisioning/virtual-service/dialog-box/ConfirmDialog';
import InfoImg from 'components/info-img';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';
import { commonStyles as style } from 'assets/style';

const MultiServiceNameContextPath = () => {
    const [isModalOpen, setModal] = React.useState(false);
    const [confirmModal, setConfirmModal] = React.useState(null);
    const [contextHelperText, setContextHelperText] = React.useState(false);
    const [serviceHelperText, setServiceHelperText] = React.useState(false);
    const [serviceNameHelperText, setServiceNameHelperText] = React.useState(false);
    const [virtualServiceName, setVirtualServiceName] = React.useState(null);
    const [contextPathActiveIndex, setContextPathActiveIndex] = React.useState(0);
    const [servicePortActiveIndex, setServicePortActiveIndex] = React.useState(0);
    const [serviceNameActiveIndex, setServiceNameActiveIndex] = React.useState(0);
    const [order] = useRecoilState(orderPayload);
    const [multiValdation, setMultiValidation] = useRecoilState(multiFieldValidation);
    const [restrictedPortsOptions] = useRecoilState(restrictedPorts);
    const [validationErrorStatus] = useRecoilState(validationStatus);

    const [selectedVS] = useRecoilState(selectedVSService);
    const addContextPath = useSetRecoilState(addMultiContextPath);
    const removeContextPath = useSetRecoilState(removeMultiContextPath);
    const updateMultiSelectionField = useSetRecoilState(updateMultiSelection);

    React.useEffect(() => {
        if (
            order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath !== null &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath !== '' &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath !== undefined &&
            !/^(?!.*\/\/)[a-zA-Z0-9/_-]{1,255}(?<![_-])$/.test(order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath)
        ) {
            if (order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath?.charAt(0) !== '/') {
                setContextHelperText(true);
                setMultiValidation({
                    ...multiValdation,
                    contextPath: multiValdation?.contextPath?.map((item, index) => {
                        if (index === contextPathActiveIndex) {
                            return { ...item, isInvalid: true, message: 'Context path should start with /' };
                        }
                        return item;
                    }),
                });
                return;
            }
            setContextHelperText(true);
            setMultiValidation({
                ...multiValdation,
                contextPath: multiValdation?.contextPath?.map((item, index) => {
                    if (index === contextPathActiveIndex) {
                        return {
                            ...item,
                            isInvalid: true,
                            message: 'Context Path should have alpha-numeric value and can contain slash(/), underscore(_) and hyphen(-) as special chars.',
                        };
                    }
                    return item;
                }),
            });
        } else {
            if (
                order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath !== null &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath !== '' &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath !== undefined &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath?.charAt(0) !== '/'
            ) {
                setContextHelperText(true);
                setMultiValidation({
                    ...multiValdation,
                    contextPath: multiValdation?.contextPath?.map((item, index) => {
                        if (index === contextPathActiveIndex) {
                            return { ...item, isInvalid: true, message: 'Context path should start with /' };
                        }
                        return item;
                    }),
                });
                return;
            }
            setContextHelperText(false);
            setMultiValidation({
                ...multiValdation,
                contextPath: multiValdation?.contextPath?.map((item, index) => {
                    if (index === contextPathActiveIndex) {
                        return { ...item, isInvalid: false, isTouched: false, message: '' };
                    }
                    return item;
                }),
            });
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.[contextPathActiveIndex]?.contextPath]);

    React.useEffect(() => {
        if (
            order?.aksVirtualService?.[selectedVS]?.aksService?.[servicePortActiveIndex]?.servicePort &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[servicePortActiveIndex]?.servicePort !== null &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[servicePortActiveIndex]?.servicePort !== '' &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[servicePortActiveIndex]?.servicePort !== undefined &&
            !/^[0-9]{2,5}$/.test(order?.aksVirtualService?.[selectedVS]?.aksService?.[servicePortActiveIndex]?.servicePort)
        ) {
            setServiceHelperText(true);
            setMultiValidation({
                ...multiValdation,
                servicePort: multiValdation?.servicePort?.map((item, index) => {
                    if (index === servicePortActiveIndex) {
                        return { ...item, isInvalid: true, message: 'Service Port should contain 2 to 5 digits.' };
                    }
                    return item;
                }),
            });
        } else {
            if (restrictedPortsOptions.indexOf(order?.aksVirtualService?.[selectedVS]?.aksService?.[servicePortActiveIndex]?.servicePort) !== -1) {
                setServiceHelperText(true);

                setMultiValidation({
                    ...multiValdation,
                    servicePort: multiValdation?.servicePort?.map((item, index) => {
                        if (index === servicePortActiveIndex) {
                            return { ...item, isInvalid: true, message: 'This is a restricted Service Port.' };
                        }
                        return item;
                    }),
                });
                return;
            }
            setServiceHelperText(false);
            setMultiValidation({
                ...multiValdation,
                servicePort: multiValdation?.servicePort?.map((item, index) => {
                    if (index === servicePortActiveIndex) {
                        return { ...item, isInvalid: false, isTouched: false, message: '' };
                    }
                    return item;
                }),
            });
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.[servicePortActiveIndex]?.servicePort]);
    const checkServiceNameDuplicate = (value, serviceNameIndex) => {
        const serviceNameList = order?.aksVirtualService?.reduce((acc, item, index) => {
            if (index !== selectedVS) {
                item?.aksService?.forEach((aks) => acc.push(aks?.serviceName));
            } else if (item?.virtualServiceType === 'Virtual service with multiple service and context path') {
                item?.aksService?.forEach((servicedetails, serviceindex) => {
                    if (serviceindex !== serviceNameIndex) {
                        acc.push(servicedetails?.serviceName);
                    }
                });
            }
            return acc;
        }, []);

        return value ? serviceNameList.includes(value) : false;
    };
    React.useEffect(() => {
        const serviceName = order?.aksVirtualService?.[selectedVS]?.aksService?.[serviceNameActiveIndex]?.serviceName;

        if (serviceName && !/^[a-zA-Z0-9_-]{3,255}$/.test(serviceName)) {
            setServiceNameHelperText(true);
            setMultiValidation((prev) => ({
                ...prev,
                serviceName: prev?.serviceName?.map((item, index) =>
                    index === serviceNameActiveIndex
                        ? {
                              ...item,
                              isInvalid: true,
                              message:
                                  'Service name should have alpha-numeric value with min 3 chars, max 255 chars and can contain underscore(_) and hyphen(-) as special chars.',
                          }
                        : item
                ),
            }));
        } else if (serviceName && checkServiceNameDuplicate(serviceName, serviceNameActiveIndex)) {
            setServiceNameHelperText(true);
            setMultiValidation((prev) => ({
                ...prev,
                serviceName: prev?.serviceName?.map((item, index) =>
                    index === serviceNameActiveIndex
                        ? {
                              ...item,
                              isInvalid: true,
                              message: 'Service name already used in another Virtual service, please use unique one.',
                          }
                        : item
                ),
            }));
        } else {
            setServiceNameHelperText(false);
            setMultiValidation((prev) => ({
                ...prev,
                serviceName: prev?.serviceName?.map((item, index) =>
                    index === serviceNameActiveIndex ? { ...item, isInvalid: false, isTouched: false, message: '' } : item
                ),
            }));
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.[serviceNameActiveIndex]?.serviceName]);

    const handleValueChange = (value, index, field) => {
        if (field === 'serviceName') {
            setServiceNameActiveIndex(index);
            if (value && checkServiceNameDuplicate(value, index)) {
                setServiceNameHelperText(true);
                setMultiValidation((prev) => ({
                    ...prev,
                    serviceName: prev?.serviceName?.map((item, sindex) =>
                        sindex === index
                            ? {
                                  ...item,
                                  isInvalid: true,
                                  message: 'Service name already used in another Virtual service, please use unique one.',
                              }
                            : item
                    ),
                }));
            }
        }
        if (field === 'contextPath') {
            setContextPathActiveIndex(index);
        }
        if (field === 'servicePort') {
            setServicePortActiveIndex(index);
        }
        updateMultiSelectionField({ value, index, selectedVS, field });
    };

    const handleAction = (type) => {
        if (type === 'yes') {
            removeContextPath({ confirmModal, selectedVS });
        }
        setConfirmModal(null);
        setModal(false);
    };

    const checkDuplicate = (service) => {
        const serviceValues = order?.aksVirtualService?.[selectedVS]?.aksService;
        const serviceDetailsValues = serviceValues?.map((item) => item?.serviceName?.concat(item?.servicePort, item?.contextPath));
        const duplicateServiceDetailsValues = serviceDetailsValues?.filter((item, index) => serviceDetailsValues?.indexOf(item) !== index);
        if (service?.serviceName && service?.servicePort && service?.contextPath) {
            const value = service?.serviceName.concat(service?.servicePort, service?.contextPath);
            return duplicateServiceDetailsValues?.indexOf(value) > -1;
        }
        return false;
    };

    const nullContextPathIndexes = order?.aksVirtualService?.[selectedVS]?.aksService?.reduce((indexes, service, index) => {
        if (service?.contextPath === null) {
            indexes.push(index);
        }
        return indexes;
    }, []);

    const nullServicePortIndexes = order?.aksVirtualService?.[selectedVS]?.aksService?.reduce((indexes, service, index) => {
        if (service?.servicePort === null) {
            indexes.push(index);
        }
        return indexes;
    }, []);

    const nullServiceNameIndexes = order?.aksVirtualService?.[selectedVS]?.aksService?.reduce((indexes, service, index) => {
        if (service?.serviceName === null) {
            indexes.push(index);
        }
        return indexes;
    }, []);

    React.useEffect(() => {
        if (order?.isConfigureTouched) {
            setMultiValidation({
                ...multiValdation,
                contextPath: multiValdation?.contextPath?.map((item, index) => {
                    if (nullContextPathIndexes.includes(index)) {
                        return { ...item, isTouched: true };
                    }
                    return { ...item, isTouched: false };
                }),
                servicePort: multiValdation?.servicePort?.map((item, index) => {
                    if (nullServicePortIndexes.includes(index)) {
                        return { ...item, isTouched: true };
                    }
                    return { ...item, isTouched: false };
                }),
                serviceName: multiValdation?.serviceName?.map((item, index) => {
                    if (nullServiceNameIndexes.includes(index)) {
                        return { ...item, isTouched: true };
                    }
                    return { ...item, isTouched: false };
                }),
            });
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.length, order?.isConfigureTouched]);

    const getVSValidationStatus = (serviceName) => {
        const failedServices = [];
        if (validationErrorStatus?.length > 0) {
            validationErrorStatus?.filter((error) => {
                return error?.services?.map((service) => {
                    if (service?.name === serviceName && error?.virtualServiceName === virtualServiceName) {
                        failedServices.push(service);
                    }
                    return null;
                });
            });
            return failedServices;
        }
        return false;
    };
    const getErrorMessage = (serviceName) => {
        const validationError = getVSValidationStatus(serviceName);
        if (!serviceNameHelperText && validationError?.length > 0) {
            return (
                <>
                    {validationError?.map((error, errorIndex) => (
                        <React.Fragment key={`error-${errorIndex}`}>
                            {error?.msg?.map((msg, index) => (
                                <p key={`error-msg-${index}`}>{msg}</p>
                            ))}
                        </React.Fragment>
                    ))}
                </>
            );
        }

        return '';
    };
    React.useEffect(() => {
        setVirtualServiceName(order?.aksVirtualService?.[selectedVS]?.virtualServiceName);
    }, []);
    return (
        <Grid container spacing={2}>
            {order?.aksVirtualService?.[selectedVS]?.aksService?.map((service, index) => (
                <React.Fragment key={`multi-sel-container-${index}`}>
                    <Grid item xs={12}>
                        <Grow
                            in={order?.aksVirtualService?.[selectedVS]?.aksService?.length > 0}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(order?.aksVirtualService?.[selectedVS]?.aksService?.length > 0 ? { timeout: 500 } : {})}
                        >
                            <Grid container spacing={2} sx={{ background: '#E5ECF8', pb: '18px', mt: '8px', pr: '18px' }}>
                                <Grid item xs={12}>
                                    <Box className="multi-selection-title-container">
                                        <Typography className="multi-selection-title">{`Service Details - ${
                                            index < 9 ? `0${index + 1}` : `${index + 1}`
                                        }`}</Typography>
                                        {checkDuplicate(service) && (
                                            <Box
                                                sx={{
                                                    color: '#d32f2f',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Duplicate service details not allowed.
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box
                                        className="multi-selection-title-container"
                                        sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 1 }}
                                        data-testid={`service-name-box-${index}`}
                                    >
                                        <CssTextField
                                            size="small"
                                            fullWidth
                                            autoComplete="off"
                                            label="Service Name *"
                                            value={service?.serviceName || ''}
                                            onChange={(e) => handleValueChange(e.target.value, index, 'serviceName')}
                                            error={
                                                !!(
                                                    (serviceNameHelperText && serviceNameActiveIndex === index) ||
                                                    multiValdation?.serviceName?.[index]?.isInvalid ||
                                                    multiValdation?.serviceName?.[index]?.isTouched ||
                                                    getErrorMessage(service?.serviceName)
                                                )
                                            }
                                            helperText={
                                                ((multiValdation?.serviceName?.[index]?.isInvalid || multiValdation?.serviceName?.[index]?.isTouched) &&
                                                    multiValdation?.serviceName?.[index]?.message) ||
                                                getErrorMessage(service?.serviceName)
                                            }
                                            className={service?.isInvalid ? 'validate' : ''}
                                        />
                                        <InfoImg
                                            title={
                                                <>
                                                    <Box className={ProvisioningStyles.infoStyle.wrapper}>
                                                        <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        <span style={ProvisioningStyles.infoStyle.headingStyle}>Service Name:</span>
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        A service in AKS acts as an abstract to expose an application running on a set of pods,
                                                                        providing stable IP addresses, load balancing, and service discovery.
                                                                    </Typography>{' '}
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle} />
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        <span style={ProvisioningStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        1) It can have alpha-numeric value with max 255 chars.
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        2) It can contain only hyphen(-) as special char.
                                                                    </Typography>
                                                                </Grid>{' '}
                                                                <Grid item xs={12} sx={ProvisioningStyles.infoStyle.bottomDividerRule}>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        <span style={ProvisioningStyles.infoStyle.headingStyle}>Example: </span>
                                                                        pep-cmp-dev-order-processor
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </>
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center" gap={1} width="100%" data-testid={`service-port-box-${index}`}>
                                        <CssTextField
                                            size="small"
                                            fullWidth
                                            autoComplete="off"
                                            label="Service Port *"
                                            placeholder="Please enter 2 to 5 digits"
                                            value={service?.servicePort || ''}
                                            onChange={(e) => handleValueChange(e.target.value, index, 'servicePort')}
                                            error={
                                                !!(
                                                    (serviceHelperText && servicePortActiveIndex === index) ||
                                                    multiValdation?.servicePort?.[index]?.isInvalid ||
                                                    multiValdation?.servicePort?.[index]?.isTouched
                                                )
                                            }
                                            helperText={
                                                (multiValdation?.servicePort?.[index]?.isInvalid || multiValdation?.servicePort?.[index]?.isTouched) &&
                                                multiValdation?.servicePort?.[index]?.message
                                            }
                                            className={service?.isInvalid || multiValdation?.servicePort?.[index]?.message ? 'validate' : ''}
                                        />
                                        <InfoImg
                                            title={
                                                <>
                                                    <Box className={ProvisioningStyles.infoStyle.wrapper}>
                                                        <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        <span style={ProvisioningStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        1) Service port can have only numeric values.
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        2) It can contain 2 to 5 digits.
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sx={ProvisioningStyles.infoStyle.bottomDividerRule}>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        <span style={ProvisioningStyles.infoStyle.headingStyle}>Example: </span>
                                                                        8080
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </>
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center" gap={1} width="100%" id="test" data-testid={`context-path-box-${index}`}>
                                        <CssTextField
                                            size="small"
                                            fullWidth
                                            autoComplete="off"
                                            label="Context Path *"
                                            error={
                                                !!(
                                                    (contextHelperText && contextPathActiveIndex === index) ||
                                                    multiValdation?.contextPath?.[index]?.isInvalid ||
                                                    multiValdation?.contextPath?.[index]?.isTouched
                                                )
                                            }
                                            helperText={
                                                (multiValdation?.contextPath?.[index]?.isInvalid || multiValdation?.contextPath?.[index]?.isTouched) &&
                                                multiValdation?.contextPath?.[index]?.message
                                            }
                                            value={service?.contextPath || ''}
                                            onChange={(e) => handleValueChange(e.target.value, index, 'contextPath')}
                                        />
                                        <InfoImg
                                            title={
                                                <>
                                                    <Box className={ProvisioningStyles.infoStyle.wrapper}>
                                                        <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        <span style={ProvisioningStyles.infoStyle.headingStyle}>Context Path:</span>
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        A context path in a virtual service is the root URL path that routes requests to a
                                                                        specific backend service.
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        <span style={ProvisioningStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        1) Context path should start with /.
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        2) It can have alpha-numeric value.
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        3) It can only contain slash(/), underscore(_) and hyphen(-) as special chars.
                                                                    </Typography>
                                                                </Grid>{' '}
                                                                <Grid item xs={12} sx={ProvisioningStyles.infoStyle.bottomDividerRule}>
                                                                    <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                                                        <span style={ProvisioningStyles.infoStyle.headingStyle}>Example: </span>
                                                                        /v1/authorize-client_id
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </>
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center" gap={1} width="100%" id="test">
                                        <Box sx={{ width: '100%' }}>&nbsp;</Box>
                                        {order?.aksVirtualService?.[selectedVS]?.aksService?.length > 1 ? (
                                            <Box sx={{ marginTop: '5px' }}>
                                                <Box
                                                    data-testid={`remove-ssc-${index}`}
                                                    style={ProvisioningStyles.vsType.delete}
                                                    onClick={() => {
                                                        setConfirmModal({ service, index });
                                                        setModal(true);
                                                    }}
                                                >
                                                    <img height="16px" width="16px" src={deleteIcon} alt="deleteIcon" />
                                                </Box>

                                                {/* <FaTrashAlt
                                                    data-testid={`remove-ssc-${index}`}
                                                    style={ProvisioningStyles.vsType.delete}
                                                    onClick={() => {
                                                        setConfirmModal({ service, index });
                                                        setModal(true);
                                                    }}
                                                    className="cursor-pointer"
                                                /> */}
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grow>
                        {index === order?.aksVirtualService?.[selectedVS]?.aksService?.length - 1 &&
                        order?.aksVirtualService?.[selectedVS]?.aksService?.length < 10 ? (
                            <Grid item xs={12}>
                                <Box display="flex" direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 2 }}>
                                    <Btn
                                        variant="contained"
                                        sx={style.serviceTagBtn}
                                        // sx={{ minWidth: '42px', width: 'auto', height: '32px', marginTop: 1 }}
                                        disableFocusRipple
                                        disableRipple
                                        className="cmpSubmitBtn add-button"
                                        onClick={() => addContextPath({ count: 1, selectedVS })}
                                        disabled={false}
                                        data-testid="add-ssc"
                                    >
                                        Add Another Group
                                    </Btn>
                                </Box>
                            </Grid>
                        ) : (
                            <span className="w-7 h-7" />
                        )}
                    </Grid>
                </React.Fragment>
            ))}{' '}
            {isModalOpen && (
                <ConfirmDialog
                    isModalOpen={isModalOpen}
                    title="Are you sure you want to remove Service details?"
                    primaryBtnText="Yes"
                    secondaryBtnText="No"
                    primaryBtnAction={() => handleAction('yes')}
                    secondaryBtnAction={() => handleAction('no')}
                />
            )}
        </Grid>
    );
};

export default MultiServiceNameContextPath;
