import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import React from 'react';
import PreviewStyles from 'pages/provisioning/virtual-service/components/preview/style';
import CorsDetails from 'pages/provisioning/virtual-service/components/preview/CorsDetails';
import { virtualServiceTypes } from 'pages/provisioning/virtual-service/utils';

const ServiceDetails = ({ serviceKey, vsDetails, vsType }) => {
    const location = useLocation();
    if (serviceKey === 'aksService') {
        return (
            <table style={{ width: '100%' }}>
                <tr>
                    {vsType === virtualServiceTypes?.[2]?.name ? (
                        <>
                            <th style={PreviewStyles?.vsTypeHeaders}>Service Name</th>
                            <th style={PreviewStyles?.vsTypeHeaders}>Service Port</th>
                            <th style={PreviewStyles?.vsTypeHeaders}>Context Path</th>
                        </>
                    ) : (
                        <>
                            <th
                                style={{
                                    ...PreviewStyles?.vsTypeHeaders,
                                    width: location?.pathname?.includes('order') ? '50%' : '33.33%',
                                }}
                            >
                                Service Port
                            </th>
                            <th
                                style={{
                                    ...PreviewStyles?.vsTypeHeaders,
                                    width: location?.pathname?.includes('order') ? '50%' : '33.33%',
                                }}
                            >
                                Context Path
                            </th>
                            {!location?.pathname?.includes('order') && (
                                <th style={PreviewStyles?.vsTypeHeaders} aria-label="service">
                                    {' '}
                                </th>
                            )}
                        </>
                    )}
                </tr>
                {vsDetails?.aksService?.map((item, idx1) => (
                    <tr key={idx1}>
                        {vsType === virtualServiceTypes?.[2]?.name ? (
                            <>
                                <td style={PreviewStyles?.valueText}>{item?.serviceName}</td>
                                <td style={PreviewStyles?.valueText}>{item?.servicePort}</td>
                                <td style={PreviewStyles?.valueText}>{item?.contextPath}</td>
                            </>
                        ) : (
                            <>
                                <td style={PreviewStyles?.valueText}>{item?.servicePort}</td>
                                <td style={PreviewStyles?.valueText}>{item?.contextPath}</td>
                                <td style={PreviewStyles?.valueText} aria-label="service">
                                    {' '}
                                </td>
                            </>
                        )}
                    </tr>
                ))}
            </table>
        );
    }
    if (serviceKey === 'aksCors') {
        return <CorsDetails details={vsDetails?.aksCors} />;
    }
    return null;
};

ServiceDetails.propTypes = {
    serviceKey: PropTypes.string.isRequired,
    vsDetails: PropTypes.any.isRequired,
    vsType: PropTypes.string.isRequired,
};

export default ServiceDetails;
