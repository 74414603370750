/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, lazy, Suspense } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { range } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { sspSubmitOrder, toggleSspModels, isMandatoryTagsFullfilled, isValidCluster, isInvalidMemoryQuota } from 'pages/provisioning/store/couchbase';
import { cartCount } from 'pages/cart/store';
import { selectOrder } from 'pages/orders/store';
// import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import Btn from 'components/button';
import { validateObject } from 'utils/validateObject';
import { getPayload } from 'pages/provisioning/couchbase/getPayload';
import { commonStyles as style } from 'assets/style';

const ErrorBanner = lazy(() => import('components/ErrorBanner'));

const CartBtn = () => {
    const history = useHistory();
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [isNodeEmpty, setNodeEmpty] = useState(false);
    const [isServiceMappingsEmpty, setServiceMappingsEmpty] = useState(false);
    const [isBucketInvalid, setBucketInvalid] = useState(false);
    const [isBucketMemSizeInvalid, setBucketMemSize] = useState(false);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const isMandatoryTags = useRecoilValue(isMandatoryTagsFullfilled);
    const [, setSelectedOrderId] = useRecoilState(selectOrder);
    const [serviceNodesInvalid, setServiceNodesInvalid] = useState(false);
    const validCluster = useRecoilValue(isValidCluster);
    const invalidMemoryQuota = useRecoilValue(isInvalidMemoryQuota);
    const [, setCartCount] = useRecoilState(cartCount);
    const [errorBannerOpen, setErrorBannerOpen] = React.useState(false);

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };

    const handleRedirect = async () => {
        await setSelectedOrderId(cartItemId);
        setLoading(false);
        history.push('/orders');
    };
    const handleSubmit = async () => {
        setLoading(true);
        const payload = await getPayload({ order, catalogCode, catalogServiceId });
        try {
            const validate = await validateObject({ data: payload, catalogCode });
            if (validate?.isValid) {
                if (cartItemId && operation && !catalogServiceId) {
                    if (!serviceNodesInvalid) {
                        const updateCartItem = await axios.put(`${endPoints.cart.addItem}/${cartItemId}`, payload);
                        if (updateCartItem?.isSuccess) {
                            setModal({ ...modal, isUpdatedToCart: true });
                            setLoading(false);
                        } else {
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }
                }
                if (cartItemId && operation && catalogServiceId) {
                    if (!serviceNodesInvalid) {
                        const saveChanges = await axios.put(
                            `${endPoints.order.saveOrder.replace('{orderId}', cartItemId).replace('{catalogServiceId}', catalogServiceId)}`,
                            payload?.catalogService
                        );
                        if (saveChanges?.isSuccess) {
                            handleRedirect();
                        } else {
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }
                }
                if (!cartItemId && !operation && !catalogServiceId) {
                    if (!serviceNodesInvalid) {
                        const submitOrder = await axios.post(endPoints.cart.addItem, payload);
                        if (submitOrder?.data) {
                            const updatedCart = await axios.get(endPoints.cart.getCartCount);
                            if (updatedCart?.data) {
                                setCartCount(updatedCart?.data?.itemCount);
                            }
                            setModal({ ...modal, isSubmitOrderSuccess: true });
                            setLoading(false);
                        } else {
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }
                }
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            // eslint-disable-next-line
            console.log('Exception during schema validation', e);
        }
    };

    React.useEffect(() => {
        order?.nodes?.forEach((z) => {
            if (
                !z?.osTypeVersion ||
                !z?.vmType ||
                !z?.domain ||
                !z?.haDeployment ||
                (z?.haDeployment === 'Availability Zones' && !z?.availabilityZone) ||
                (z?.haDeployment === 'Availability Zones' && z?.availabilityZone?.length === 0) ||
                !z?.isConfigDiskFulfilled
            ) {
                setNodeEmpty(true);
            } else setNodeEmpty(false);
        });
    }, [order?.nodes]);

    React.useEffect(() => {
        setServiceNodesInvalid(false);
        let errorCount = 0;
        const nodesArray = [];
        order?.serviceMappings?.forEach((z) => {
            if (!z?.clusterService || !z?.memoryQuota || !z?.storagePath || z?.nodes?.length === 0 || z?.nodes?.length === undefined) {
                // eslint-disable-next-line no-plusplus
                errorCount++;
            }
            setServiceMappingsEmpty(errorCount !== 0);
            if (z?.nodes?.length > 0) nodesArray.push(...z?.nodes);
        });
        const selectedNodes = range(1, Number(order?.nodesCount) + 1).map((i) => `Node${i.toString()}`);
        const assignedNodes = [...new Set(nodesArray)];
        const unassignedNodes = selectedNodes.filter((z) => assignedNodes.indexOf(z) === -1);
        setOrder({ ...order, unassignedNodes });
        if (order?.nodesCount !== [...new Set(nodesArray)]?.length.toString()) {
            setServiceNodesInvalid(true);
        } else {
            setServiceNodesInvalid(false);
        }
    }, [order?.serviceMappings]);

    React.useEffect(() => {
        let errorCount = 0;
        order?.buckets?.forEach((z) => {
            if (!z?.bucketType) {
                // eslint-disable-next-line no-plusplus
                errorCount++;
            }
            setBucketInvalid(errorCount !== 0);
        });
    }, [order?.buckets]);

    React.useEffect(() => {
        let errorCount = 0;
        order?.buckets?.forEach((z) => {
            if (z.bucketMemSize === '') {
                // eslint-disable-next-line no-plusplus
                errorCount++;
            }
            setBucketMemSize(errorCount !== 0);
        });
    }, [order?.buckets]);

    function duplicateBucketType() {
        const bucketTypeArr = order?.buckets?.map((data) => data?.bucketType);
        // eslint-disable-next-line
        for (var i = 0; i < bucketTypeArr?.length - 1; i++) {
            // eslint-disable-next-line
            for (var j = i + 1; j < bucketTypeArr?.length; j++) {
                if (bucketTypeArr[i] === bucketTypeArr[j]) {
                    return true;
                }
            }
        }
        return false;
    }

    function duplicateClusterService() {
        const clusterServiceArr = order?.serviceMappings?.map((data) => data?.clusterService);
        // eslint-disable-next-line
        for (var i = 0; i < clusterServiceArr?.length - 1; i++) {
            // eslint-disable-next-line
            for (var j = i + 1; j < clusterServiceArr?.length; j++) {
                if (clusterServiceArr[i] === clusterServiceArr[j]) {
                    return true;
                }
            }
        }
        return false;
    }

    const isReadyToSubmit = () =>
        !order?.appProfile ||
        !order?.sector ||
        !order?.environment ||
        !order?.appTier ||
        !order?.region ||
        !order?.landingZoneDetails ||
        !order?.billingEntity ||
        !order?.hCodeDetails ||
        !order?.cluster ||
        !order?.dbVersion ||
        !order?.nodesCount ||
        isNodeEmpty ||
        isServiceMappingsEmpty ||
        order?.unassignedNodes?.length > 0 ||
        validCluster ||
        invalidMemoryQuota ||
        duplicateClusterService() ||
        isBucketInvalid ||
        isBucketMemSizeInvalid ||
        duplicateBucketType() ||
        isMandatoryTags;

    const handleValidation = () => {
        if (!isReadyToSubmit()) {
            handleSubmit();
        } else {
            const mappingResult = [];
            const bucketResult = [];
            order?.serviceMappings?.forEach((z) => {
                if (!z?.clusterService || !z?.memoryQuota || !z?.storagePath || z?.nodes?.length === 0 || z?.nodes?.length === undefined) {
                    const updateIsTouched = { ...z, isServiceMappingTouched: true };
                    mappingResult.push(updateIsTouched);
                } else mappingResult.push(z);
            });

            order?.buckets?.forEach((z) => {
                if (!z?.bucketType || !z?.bucketMemSize) {
                    const updateIsTouched = { ...z, isBucketTouched: true };
                    bucketResult.push(updateIsTouched);
                } else bucketResult.push(z);
            });

            setOrder({ ...order, serviceMappings: mappingResult, buckets: bucketResult, isTouched: true });
            handleClickErrorBanner();
        }
    };

    return (
        <Suspense
            fallback={
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 100px)" width="100%">
                    <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    <Typography variant="body2" sx={{ color: '#000000' }}>
                        Loading
                    </Typography>
                </Box>
            }
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {cartItemId && operation && !catalogServiceId && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={isLoading}
                        // sx={ProvisioningStyles?.cartBtn}
                        sx={style?.primaryButton}
                        data-testid="update-cart-btn"
                    >
                        {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Update Cart'}
                    </Btn>
                )}
                {cartItemId && operation && catalogServiceId && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={isLoading}
                        sx={style?.primaryButton}
                        data-testid="save-changes-btn"
                    >
                        {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Save Changes'}
                    </Btn>
                )}
                {!cartItemId && !operation && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={isLoading}
                        // sx={ProvisioningStyles?.cartBtn}
                        sx={style?.primaryButton}
                        data-testid="add-to-cart-btn"
                    >
                        {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Add to Cart'}
                    </Btn>
                )}
            </Box>
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click submit."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </Suspense>
    );
};

CartBtn.propTypes = {};

CartBtn.defaultProps = {};

export default React.memo(CartBtn);
