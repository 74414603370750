import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Style from './style';

const Alert = (props) => {
    const { message, Icon, title, borderLeftColor, backgroundColor, color, ftSize, closeicon, onClose, boxShadow, borderRadius } = props;
    return (
        <Box
            data-testid="comments"
            sx={{
                ...Style.wrapper,
                background: `${backgroundColor} 0% 0% no-repeat padding-box`,
                borderLeft: `8px solid ${borderLeftColor}`,
                color,
                boxShadow,
                borderRadius,
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    {!title ? (
                        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
                            <Stack direction="row" justifyContent="flex-start" alignItems="center" width="100%">
                                {Icon && Icon}
                                {title && (
                                    <Typography variant="h6" sx={Style.title}>
                                        {title}
                                    </Typography>
                                )}
                                {message && (
                                    <Box style={{ ...Style.messageBoxWithoutTitle, marginLeft: `${title ? '8px' : '10px'}`, fontSize: ftSize }}>{message}</Box>
                                )}
                            </Stack>
                            {closeicon && <CloseIcon onClick={onClose} sx={Style.closeIconWithoutTitle} />}
                        </Stack>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                {' '}
                                <Stack direction="row" justifyContent="flex-start" width="100%">
                                    {Icon && Icon}
                                    {title && (
                                        <Typography variant="h6" sx={Style.title}>
                                            {title}
                                        </Typography>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                {' '}
                                {message && <Box style={{ ...Style.messageBox, marginLeft: `${title ? '8px' : '8px'}`, fontSize: ftSize }}>{message}</Box>}
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
Alert.propTypes = {
    message: PropTypes.any,
    Icon: PropTypes.element,
    title: PropTypes.string,
    borderLeftColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    boxShadow: PropTypes.string,
    ftSize: PropTypes.string,
    closeicon: PropTypes.bool,
    onClose: PropTypes.func,
    borderRadius: PropTypes.string,
};

Alert.defaultProps = {
    message: undefined,
    Icon: null,
    title: '',
    borderLeftColor: '#FFFFFF',
    backgroundColor: '#FFFFFFF',
    color: '#000000',
    // boxShadow: '4px 4px 12px #0000000F',
    boxShadow: 'none',
    ftSize: '16px',
    closeicon: false,
    onClose: undefined,
    borderRadius: '0px',
};

export default Alert;
