import { styled } from '@mui/material/styles';
import { TextareaAutosize } from '@mui/material';

const styles = {
    modal: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        groupWrapper: { width: '100%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1), marginTop: '10px' },
        deleteWrapper: {
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
            // background: (theme) => theme?.palette?.red?.darkest,
            // mt: 0.5,
        },
        delete: {
            // fontSize: (theme) => theme.spacing(2.25),
            // fill: '#FFFFFF',
            // color: (theme) => theme.palette.white,
            cursor: 'pointer',
            width: '16px',
            height: '16px',
        },
        add: { width: '16px', height: '16px', cursor: 'pointer', marginTop: '7px', fontSize: '24px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
    },
    selectStyle: {
        width: '100%',
    },
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    textField: {
        width: '100%',
        '& label': {
            marginTop: '-5px',
        },
        '& label.Mui-focused': {
            color: (theme) => theme.palette.blue.main,
        },
        '& label.MuiFormLabel-filled': {
            color: (theme) => theme.palette.blue.main,
            padding: (theme) => theme.spacing(0.5),
        },
        '& .MuiOutlinedInput-input': {
            height: (theme) => theme.spacing(0.75),
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: (theme) => theme.palette.gray.light,
                color: (theme) => theme.palette.blue.lightest,
            },
            '&:hover fieldset': {
                borderColor: (theme) => theme.palette.gray.light,
            },
            '&.Mui-focused fieldset': {
                borderColor: (theme) => theme.palette.gray.light,
            },
        },
    },
    helpText: { color: '#d40020' },
    errorText: { color: '#d40020', fontSize: '10px' },
    infoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    filenamePattern: {
        // color: '#0047BA',
        // fontSize: '12px',
        // fontWeight: 'normal',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    addedFilePattern: {
        maxHeight: '120px',
        padding: '10px',
        overflow: 'scroll',
        border: '1px solid',
        borderColor: 'lightgrey',
        borderRadius: '4px',
    },
    filenamePatternHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    filenamePatternText: {
        color: '#0047BA',
        fontSize: '12px',
        fontWeight: 'normal',
        marginTop: '9px',
    },
    ritmProjectText: {
        color: '#0047BA',
        fontSize: '12px',
        fontWeight: 'normal',
    },

    textArea: {
        width: '100%',
        background: (theme) => theme.palette.white,
        padding: 8,
    },
    ritmFormControl: {
        display: 'flex',
        alignItems: 'center',
        width: '100% !important',
        gap: '8px',
        flexWrap: 'wrap',
        flexDirection: 'row',
        border: '1px solid #E9E9E9',
        padding: '8.5px 14px',
        borderRadius: '4px',

        '&> div.ritmchipcontainer': {
            gap: '6px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        '& > div.ritmchipcontainer > span': {
            backgroundColor: 'gray',
            padding: '1px 3px',
            borderRadius: '4px',
        },
    },
    alertBox: {
        marginLeft: '30px',
        paddingTop: '4px',
        paddingBottom: '4px',
        background: (theme) => theme?.palette?.cmpPrimary2?.light,
        color: (theme) => theme?.palette?.cmpBlack?.main,
        font: (theme) => theme.typography.fontSize.$font_small_medium,
    },
};

export default styles;

export const StyledTextarea = styled(TextareaAutosize)(
    () => `    
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    border-color: #E9E9E9;
    color: #27344B;
    border-radius: 4px;
    resize: none;
    &:hover {
      border-color: #E9E9E9;
    }

    &:focus {
      border-color: #E9E9E9;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);
