import { Chip, FormControl, Box, Input, Typography, InputLabel, Grid } from '@mui/material';
// import { CssTextField } from 'components/select';

import { orderPayload, validProvisionForm } from 'pages/mdt-onboarding/store';
import React, { useState } from 'react';
import Btn from 'components/button';
import { useRecoilState } from 'recoil';
import HelpImg from 'pages/mdt-onboarding/components/helpImg';
import { commonStyles as style } from 'assets/style';
import styles from './style';

export default function RITMNumber() {
    const [isTouched, setIsTouched] = useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [validForm, setValidForm] = useRecoilState(validProvisionForm);
    const [values, setValues] = useState([]);
    const [currValue, setCurrValue] = useState('');
    const [inValidText, setInValidText] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [maxlength, setMax] = useState(false);

    const handleAddRitm = () => {
        if (currValue?.trim()?.length > 0 && !inValidText) {
            setValues((oldState) => {
                return [...oldState, currValue?.trim()];
            });

            setCurrValue('');
        } else {
            setMax(false);
        }
        return null;
    };
    const validateInput = () => {
        if ((isTouched && order?.isTouched) || inValidText || maxlength) {
            if (order?.ritmNumber?.length < 1 || inValidText || duplicate) return 'ritmvalidate';
            if (order?.ritmNumber?.length < 1 || inValidText || maxlength) return 'ritmvalidate';
        }
        return '';
    };

    const handleChange = (e) => {
        setCurrValue(e.target.value?.toUpperCase());
    };

    const handleDelete = (item, index) => {
        const arr = [...values];
        arr.splice(index, 1);
        setOrder({
            ...order,
            ritmNumber: arr,
        });
        setValues(arr);
    };

    React.useEffect(() => {
        setOrder({
            ...order,
            ritmNumber: values,
        });
    }, [values]);

    React.useEffect(() => {
        if (currValue?.trim()?.length > 0) {
            if (!/^RITM[0-9]{6,8}$/.test(currValue?.trim())) {
                setInValidText(true);
                setValidForm({ ...validForm, isRitmNumberValid: true });
            } else {
                setInValidText(false);
                setValidForm({ ...validForm, isRitmNumberValid: false });
            }
        } else {
            setInValidText(false);
            setValidForm({ ...validForm, isRitmNumberValid: false });
        }

        const value = currValue?.trim();
        if (order?.ritmNumber?.length > 4 && value) {
            return setMax(true);
            // eslint-disable-next-line
        } else {
            if (order?.ritmNumber?.includes(value)) {
                setValidForm({ ...validForm, isRitmNumberValid: true });

                return setDuplicate(true);
                // eslint-disable-next-line
            } else {
                setValidForm({ ...validForm, isRitmNumberValid: false });
                setDuplicate(false);
            }
            setMax(false);
        }

        return null;
    }, [currValue]);

    const getText = () => {
        if (inValidText) return "RITM number must start with 'RITM' followed by numbers within 6 to 8 chars.";
        // eslint-disable-next-line
        else if (duplicate) return 'RITM number cannot be duplicate.';
        else if (maxlength) return 'You can add maximum 5 RITM numbers.';
        else return '';
    };

    React.useEffect(() => {
        if (!order?.ritmNumber?.length && order?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [order?.sourceEmail, order?.isTouched]);

    const validateRITMButton = () => {
        return !validForm?.isRitmNumberValid && !inValidText && currValue?.trim()?.length > 0 && !maxlength;
    };

    return (
        <Box width="100%" flexDirection="row-reverse">
            <InputLabel htmlFor="short-description">
                <Box sx={styles.filenamePatternText}>RITM Number *</Box>
            </InputLabel>
            <Box display="flex" flexDirection="initial" alignItems="center">
                <FormControl sx={styles?.ritmFormControl} className={validateInput()}>
                    <Box className="ritmchipcontainer">
                        {values.map((item, index) => (
                            <Chip sx={{ borderRadius: '4px' }} width="100%" size="small" onDelete={() => handleDelete(item, index)} label={item} />
                        ))}
                    </Box>
                    <Input value={currValue} onChange={handleChange} placeholder="RITM000000" />
                </FormControl>
                <Box marginLeft="10px">
                    <HelpImg
                        title={
                            <>
                                <Box sx={styles.infoStyle.wrapper}>
                                    <Box sx={styles.infoStyle.namingRuleWrapper}>
                                        <Grid container spacing={1}>
                                            <Grid item xs sx={styles.infoStyle.topLeftNamingRule}>
                                                <Typography sx={styles.infoStyle.typographyStyle}>
                                                    <span style={styles.infoStyle.headingStyle}>RITM Convention Rules:</span>
                                                </Typography>
                                                <Typography sx={styles.infoStyle.typographyStyle}>
                                                    1) RITM number must start with RITM followed by numbers within 6 to 8 chars.
                                                </Typography>
                                                <Typography sx={styles.infoStyle.typographyStyle}>2) You can add maximum 5 RITM numbers.</Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={styles.infoStyle.bottomDividerRule}>
                                                <Typography sx={styles.infoStyle.typographyStyle}> Example: RITM000000</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </>
                        }
                    />
                </Box>
                <Box marginLeft={1}>
                    <Btn
                        data-testid="submit-btn"
                        size="small"
                        sx={style.serviceNewTagBtn}
                        className="btn-disabled custom-btn"
                        variant="contained"
                        // color="cmpPrimary"
                        disabled={!validateRITMButton()}
                        onClick={() => handleAddRitm()}
                    >
                        Add
                    </Btn>
                </Box>
            </Box>
            <span style={styles?.errorText}>{getText()}</span>
        </Box>
    );
}
