import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Grid, Typography, Chip, IconButton, TextField, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import AddIcon from '@mui/icons-material/Add';
import Btn from 'components/button';
import Alert from 'components/alert';
import { togglecatalogDetails, updateCatalog } from 'pages/provisioning/store/catalog';
import toast from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import { endPoints } from 'api/endpoints';
import { useAxios } from 'hooks';
import without from 'lodash/without';
import { commonStyles as style } from 'assets/style';
import closeIcon from 'assets/images/close_icon.svg';
import styles, { CustomTextField } from './styles';

const EditCatalogDetails = ({ data }) => {
    const axios = useAxios();
    const history = useHistory();
    const [showNewTagInput, setShowTagInput] = React.useState(false);
    const [seqenceError, SetSequenceError] = React.useState(false);
    const [newTag, setNewTag] = React.useState('');
    const [showBanner, setShowBanner] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [toggleCatalogDetails, setToggleCatalogDetails] = useRecoilState(togglecatalogDetails);
    const [isTouched, setTouched] = React.useState(false);
    const [, setUpdateCatalogsData] = useRecoilState(updateCatalog);

    const [form, setForm] = React.useState({
        oldSequence: data?.catalogSequence,
        newSequence: data?.catalogSequence,
        catalogService: data?.displayName,
        description: data?.description,
        tags: data?.tags?.Catalog?.split(','),
    });
    const catalogUpdateApi = useMutation((payload) => axios.put(endPoints.catalog.allList, payload));

    const handleCatalogChange = (name, value) => {
        setShowBanner(false);
        if (name === 'newSequence') {
            if (!/^[0-9]*$/.test(value)) {
                SetSequenceError(true);
            } else {
                SetSequenceError(false);
            }
        }
        const catalogobj = { ...form };
        catalogobj[name] = value;
        setForm(catalogobj);
    };
    const handleDelete = (chip) => {
        setForm({ ...form, tags: without(form?.tags, chip) });
    };
    const handleAdd = () => {
        setShowTagInput(true);
    };
    function handleChange(item) {
        let newSelectedItem = form?.tags;
        if (newSelectedItem.indexOf(item) === -1) {
            newSelectedItem = [...newSelectedItem, item];
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const newSelectedItem = form?.tags;
            const duplicatedValues = newSelectedItem.indexOf(event.target.value.toLowerCase().trim());

            if (duplicatedValues !== -1) {
                setNewTag('');
                return;
            }
            if (!event.target.value.replace(/\s/g, '').length) return;

            newSelectedItem.push(event.target.value.trim());
            setForm({ ...form, tags: newSelectedItem });
            setNewTag('');
        }
    };
    const handleInputChange = (val) => {
        setNewTag(val);
    };
    React.useEffect(() => {
        if (catalogUpdateApi?.isSuccess) {
            toast.success(`${catalogUpdateApi?.data?.messages[0]}`);
            setToggleCatalogDetails({
                isOpen: false,
                isEdit: false,
            });
            history.push('/catalogs');
            setUpdateCatalogsData(true);
        }
    }, [catalogUpdateApi?.isSuccess]);
    React.useEffect(() => {
        if (catalogUpdateApi?.isError) {
            setShowBanner(true);
            setAlertMessage(catalogUpdateApi?.error);
            setToggleCatalogDetails({
                isOpen: true,
                isEdit: true,
            });
        }
    }, [catalogUpdateApi?.isError]);
    const handleSaveCatalog = () => {
        let isEmpty = false;
        setTouched(true);
        Object.keys(form).forEach((key) => {
            if (form[key] === '' || form[key] === undefined || form[key] === null) isEmpty = true;
        });
        if (!seqenceError && !isEmpty) {
            setTouched(false);

            catalogUpdateApi.mutate({
                id: data?.catalogDefinitionId,
                catalogSequence: form?.newSequence,
                isActive: data?.isActive,
                description: form?.description,
                displayName: form?.catalogService,
                tags: {
                    Catalog: form?.tags.toString(),
                },
            });
        }
    };
    const validateInput = () => (seqenceError || (isTouched && !form?.newSequence) ? 'validate' : '');

    return (
        <Box sx={styles?.editCatalogdetails?.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {showBanner && (
                        <Alert
                            title={null}
                            message={alertMessage}
                            Icon={<WarningAmberRoundedIcon style={styles?.infoIcon} />}
                            borderLeftColor="#F4273B"
                            backgroundColor="#FFFFFF"
                            color="#000000"
                            ftSize="15px"
                            onClose={() => setShowBanner(false)}
                            closeicon
                        />
                    )}
                </Grid>
                <Grid xs={6} item>
                    <Stack direction="row" spacing={1} sx={styles?.wrapper}>
                        <CustomTextField size="small" label="Old Sequence" autoComplete="off" fullWidth disabled value={form?.oldSequence || ''} />
                    </Stack>
                </Grid>
                <Grid xs={6} item>
                    <Stack direction="row" spacing={1} sx={styles?.wrapper}>
                        <CustomTextField
                            className={validateInput()}
                            size="small"
                            label="New Sequence"
                            error={seqenceError || (isTouched && !form?.newSequence)}
                            helperText={`
                    ${seqenceError ? 'Enter numeric values only' : ''}
                    ${isTouched && !form?.newSequence ? 'New Sequence  is required' : ''}
                                 `}
                            autoComplete="off"
                            fullWidth
                            onChange={(event) => {
                                handleCatalogChange('newSequence', event.target.value);
                            }}
                            value={form?.newSequence || ''}
                        />
                    </Stack>
                </Grid>

                <Grid xs={6} item>
                    <Grid container>
                        <Grid xs={12} item>
                            <Stack direction="row" spacing={1} sx={styles?.wrapper}>
                                <CustomTextField
                                    size="small"
                                    label="Catalog Service"
                                    autoComplete="off"
                                    fullWidth
                                    onChange={(event) => handleCatalogChange('catalogService', event.target.value)}
                                    value={form?.catalogService || ''}
                                    error={isTouched && !form?.catalogService}
                                    helperText={`${isTouched && !form?.catalogService ? 'Catalog Service is required' : ''}`}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} item sx={{ mt: 3 }}>
                            <Typography variant="span" sx={styles?.editCatalogdetails?.tagstypo}>
                                Tags
                            </Typography>
                            <Box>
                                <span>
                                    {form.tags.map((e) => (
                                        <Chip
                                            label={e}
                                            key={e}
                                            deleteIcon={<img style={style.chipCloseIcon} src={closeIcon} alt="closeIcon" />}
                                            onDelete={() => handleDelete(e)}
                                            sx={style.chipTag}
                                        />
                                    ))}
                                </span>
                                <span styles={{ ml: 1 }}>
                                    {!showNewTagInput && (
                                        <IconButton variant="solid" sx={styles?.editCatalogdetails?.iconBtn} onClick={handleAdd}>
                                            <AddIcon sx={styles?.editCatalogdetails?.addicon} />
                                        </IconButton>
                                    )}
                                    {showNewTagInput && (
                                        <TextField
                                            value={newTag}
                                            data-testid="chipadd"
                                            onKeyDown={handleKeyDown}
                                            autoComplete="off"
                                            variant="outlined"
                                            sx={styles?.editCatalogdetails?.textfield}
                                            onBlur={() => {
                                                setShowTagInput(false);
                                            }}
                                            onChange={(e) => {
                                                handleInputChange(e.target.value);
                                                handleChange(e);
                                            }}
                                        />
                                    )}
                                </span>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={6} item>
                    <Stack direction="row" spacing={1} sx={styles?.wrapper}>
                        <CustomTextField
                            size="small"
                            label="Description"
                            autoComplete="off"
                            multiline
                            rows={8}
                            fullWidth
                            onChange={(event) => handleCatalogChange('description', event.target.value)}
                            value={form?.description || ''}
                            error={isTouched && !form?.description}
                            helperText={`${isTouched && !form?.description ? 'Description is required' : ''}`}
                        />
                    </Stack>
                </Grid>

                <Grid item xs={12} sx={styles?.editCatalogdetails?.actionWrapper}>
                    <Box sx={styles?.editCatalogdetails?.actioncontainser}>
                        <Btn
                            size="medium"
                            onClick={handleSaveCatalog}
                            disabled={catalogUpdateApi?.isLoading}
                            variant="contained"
                            // color="cmpPrimary"
                            sx={{ ...style.modalSaveButton, mr: 2 }}
                        >
                            Save
                            {catalogUpdateApi?.isLoading && <CircularProgress sx={styles?.editCatalogdetails?.circularProg} color="inherit" size={14} />}
                        </Btn>
                        <Btn
                            // sx={styles?.editCatalogdetails?.cancelbtn}
                            size="medium"
                            onClick={() => {
                                setToggleCatalogDetails({
                                    ...toggleCatalogDetails,
                                    isEdit: false,
                                });
                                history.push('/catalogs');
                            }}
                            variant="outlined"
                            // color="cmpWarning"
                            sx={style.modalCancelButton}
                            layout="outline"
                        >
                            Cancel
                        </Btn>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

EditCatalogDetails.propTypes = {
    data: PropTypes.object.isRequired,
};

export default EditCatalogDetails;
