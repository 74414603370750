/* istanbul ignore file */
import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';
import SearchIcon from '@mui/icons-material/Search';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import AddMember from 'pages/project-onboarding/components/Form/ServiceMembers/AddMember';

const ArtifactoryUser = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [searched, setSearched] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);

    const memberApi = useMutation(() => axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.member}?key=${ccInputValue}`));
    const artifactoryMemberPostApi = useMutation((payload) => axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.member}?key=${payload}`));

    const setArtifactoryUser = (artifactoryUser, artifactoryUserDetails) => {
        setOrder({
            ...order,
            artifactoryUser,
            artifactoryUserDetails,
        });
    };

    React.useEffect(() => {
        if (!order?.artifactoryUser && !order?.artifactoryUserDetails) {
            setOptions([]);
        }
    }, [order?.artifactoryUser, order?.artifactoryUserDetails]);

    const resetOptions = () => {
        setOptions([]);
        setArtifactoryUser(null, null);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                memberApi.mutate();
            }, 1000),
        []
    );
    const handleSearch = () => {
        // istanbul ignore if
        if (ccInputValue === '') {
            resetOptions();
        }
        // istanbul ignore else
        if (ccInputValue !== '' && ccInputValue.length > 2) fetch();
    };
    React.useEffect(() => {
        if (memberApi?.data?.data) {
            const combinedValue = memberApi?.data?.data?.map((app) => ({
                label: `${app?.gpid} - ${app?.name}`,
                gpid: `${app?.gpid}`,
                name: `${app?.name}`,
                email: app?.email,
            }));
            setOptions(combinedValue);
        }
    }, [memberApi?.data?.data]);

    React.useEffect(() => {
        if (artifactoryMemberPostApi?.data?.data) {
            const combinedValue = artifactoryMemberPostApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: app.email,
            }));
            setOptions(combinedValue);
        }
    }, [artifactoryMemberPostApi?.data?.data]);

    React.useEffect(() => {
        if (!order?.artifactoryUser && order?.artifactoryUserDetails) {
            artifactoryMemberPostApi.mutate(order?.artifactoryUserDetails?.name);
        }
    }, [order?.artifactoryUser, order?.artifactoryUserDetails]);

    const isExistsInOptions = (artifactoryUserValue) => options.find((option) => artifactoryUserValue === option.name);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.artifactoryUser && order?.artifactoryUserDetails) {
            setOrder({ ...order, artifactoryUser: options[0]?.label, artifactoryUserDetails: options?.[0] });
        }
        if (options && options?.length > 1 && order?.artifactoryUserDetails && !order?.artifactoryUser) {
            const artifactoryUserObj = isExistsInOptions(order?.artifactoryUserDetails?.name);
            setOrder({ ...order, artifactoryUser: options[0]?.label, artifactoryUserDetails: artifactoryUserObj });
        }
    }, [options]);

    React.useEffect(() => {
        if (value === null) {
            // resetOptions();
        } else {
            setOptions(options?.filter((z) => z?.gpid === value?.gpid));
            setArtifactoryUser(value?.label, value);
        }
    }, [value]);

    const validateMember = (member) => {
        const duplicate = order?.artifactoryUserList?.filter((item) => item?.gpid === member?.gpid);
        if (duplicate && duplicate?.length) {
            setValidForm({ ...isValidForm, isDuplicateArtifactoryUser: true });
        } else {
            setValidForm({ ...isValidForm, isDuplicateArtifactoryUser: false });
        }
    };

    const handleAddMember = () => {
        if (order?.artifactoryUserList?.length > 4) {
            setValidForm({ ...isValidForm, isArtifactoryMaxMemberLimit: true });
        } else {
            setValidForm({ ...isValidForm, isArtifactoryMaxMemberLimit: false });
            // eslint-disable-next-line
            if (!isValidForm?.isDuplicateArtifactoryUser) {
                setOrder({
                    ...order,
                    artifactoryUserList: [...order.artifactoryUserList, order?.artifactoryUserDetails],
                    artifactoryUser: null,
                    artifactoryUserDetails: null,
                });
            }
        }
    };

    const getHelperText = () => {
        let errorText;
        if (isValidForm?.isDuplicateArtifactoryUser) {
            errorText = 'Member already added in the list.';
        } else if (isValidForm?.isArtifactoryMaxMemberLimit) {
            errorText = 'You have reached max limit of 5 members.';
        }
        return errorText;
    };

    const getNoOptionText = () => {
        let text = '';
        if (searched === false) {
            text = 'Type & press enter to search by GPID, Firstname and Lastname';
        } else if (options?.length === 0) {
            text = 'No results found';
        }
        return text;
    };
    return (
        <>
            {' '}
            <Box display="flex" alignItems="flex-start" gap={1}>
                <Autocomplete
                    key="member"
                    size="small"
                    noOptionsText={getNoOptionText()}
                    includeInputInList
                    loading={memberApi?.isLoading}
                    value={order?.artifactoryUser || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue) setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        // istanbul ignore else
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                            validateMember(newValue);
                        }
                    }}
                    isOptionEqualToValue={(option, value1) => option.id === value1.id}
                    renderOption={(props, option) => (
                        <li {...props} key={option?.gpid}>
                            {option?.label}
                        </li>
                    )}
                    onBlur={() => {
                        if (!value || !order?.artifactoryUser) {
                            setSearched(false);
                            setOptions([]);
                        }
                    }}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            setSearched(true);
                            handleSearch();
                        }
                        if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                            setSearched(false);
                            resetOptions();
                            setValidForm({ ...isValidForm, isDuplicateartifactoryUser: false, isArtifactoryMaxMemberLimit: false });
                        }
                    }}
                    filterOptions={(optionValues, params) => {
                        // eslint-disable-next-line no-underscore-dangle
                        const _results = [];
                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < optionValues.length; i++) {
                            // eslint-disable-next-line no-restricted-syntax
                            for (const key in optionValues[i]) {
                                if (optionValues[i][key] !== null && typeof optionValues[i][key] !== 'boolean' && typeof optionValues[i][key] !== 'number') {
                                    if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                        _results.push(optionValues[i]);
                                    }
                                }
                            }
                        }
                        return [...new Set(_results)];
                        // return _results
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disableClearable
                    id="free-solo-with-text-demo"
                    options={options}
                    sx={{ width: '80%' }}
                    renderInput={(params) => (
                        <CssTextField
                            size="large"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Artifactory Members *"
                            helperText={<span style={{ color: '#d40020' }}>{getHelperText()}</span>}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        <SearchIcon
                                            sx={ProjectOnboardingStyles.MemberAction.searchIcon}
                                            onClick={() => {
                                                setSearched(true);
                                                handleSearch();
                                            }}
                                            data-testid="search-btn"
                                        />
                                    </>
                                ),
                            }}
                            className={order?.artifactoryUserList?.length === 0 && order?.isTouched ? 'validate' : ''}
                            fullWidth
                        />
                    )}
                />

                <AddMember handleAddMember={() => handleAddMember()} />
            </Box>
        </>
    );
};

export default React.memo(ArtifactoryUser);
