export const Styles = {
    formCard: {
        paper: {
            height: '100%',
            borderRadius: (theme) => theme.spacing(0.75),
            marginBottom: (theme) => theme.spacing(3.125),
            // border: '1px solid #E0E0E0',
            boxShadow: '0 0 24px rgba(0, 92, 188, 0.08)',
            padding: '10px',
            background: (theme) => theme.palette.background.$color_background_layer_01,
        },
        column: {
            padding: (theme) => theme.spacing(2),
            position: 'relative',
        },
        heading: {
            // color: (theme) => theme?.palette?.black?.dark,
            color: (theme) => theme?.palette?.cmpPrimary.main,
            font: (theme) => theme.typography.fontSize.$font_heading_h6_bold,
        },
        wrapper: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: (theme) => theme.spacing(2), py: (theme) => theme.spacing(1) },
    },
};

export default Styles;
