import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

export const InputLabel = (props) => {
    const { label, isRequired } = props;
    return (
        <>
            <Box component="span" sx={{ fontFamily: (theme) => theme.typography.fontFamily }}>
                {label}
            </Box>{' '}
            {isRequired && (
                <Box component="span" sx={{ color: '#d40020' }}>
                    *
                </Box>
            )}
        </>
    );
};

InputLabel.propTypes = {
    label: PropTypes.any,
    isRequired: PropTypes.bool,
};

InputLabel.defaultProps = {
    label: '',
    isRequired: false,
};

export default React.memo(InputLabel);
