import React from 'react';
import { Box } from '@mui/material';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { OrderPayload, validOnboardingForm, toggleModal } from 'pages/github-onboarding/store';
import defaultOrderValues, { defaultToggleModals, defaultValidOnboardingForm } from 'pages/github-onboarding/store/defaultValues';
import useDebounce from 'pages/myidm/components/hooks/use-debounce';

const ADOProjectName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const currentValue = order?.customProperties?.adoProjectName
        ? {
              label: order.customProperties.adoProjectName,
              value: order.customProperties.adoProjectName,
          }
        : null;
    const [value, setValue] = React.useState(currentValue);
    const [ccInputValue, setCCInputValue] = React.useState(currentValue);
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);
    const [validProjectName, setValidProjectName] = React.useState(null);
    const [, setValidProvisionForm] = useRecoilState(validOnboardingForm);
    const [, setToggleModal] = useRecoilState(toggleModal);

    const projectNameGetApi = useMutation((projectValue) =>
        axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.Jwt?.adoProjectName}/${projectValue}?adoPlatform=Production`)
    );

    const debouncedInputValue = useDebounce(validProjectName, 500);

    const setProjectName = (projectNameVal) => {
        const { customProperties, ...rest } = defaultOrderValues;
        const { sector, ...customRest } = customProperties;

        setOrder({
            customProperties: {
                ...customRest,
                sector: order.customProperties.sector,
                adoProjectName: projectNameVal,
            },
            ...rest,
        });
        setValidProvisionForm(defaultValidOnboardingForm);
        setToggleModal(defaultToggleModals);
    };

    const resetOptions = () => {
        setOptions(initOptions);
        setProjectName(null);
    };

    React.useEffect(() => {
        if (!projectNameGetApi?.isSuccess || !projectNameGetApi?.data?.data?.value) return;

        const combinedValue = projectNameGetApi.data.data.value.map((app) => ({
            label: app,
            value: app,
        }));

        setOptions(combinedValue);
        setInitOptions(combinedValue);
    }, [projectNameGetApi?.isSuccess]);

    React.useEffect(() => {
        if (debouncedInputValue !== null) {
            projectNameGetApi.mutate(debouncedInputValue);
        }
    }, [debouncedInputValue]);

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) setValidProjectName(ccInputValue);
    }, [ccInputValue]);

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else {
            setProjectName(value?.label);
            setOptions([]);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.customProperties.adoProjectName && order?.isTouched) setIsTouched(true);
    }, [order?.customProperties.adoProjectName, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} marginRight={3.4}>
            <Select
                disabled={!order?.customProperties.sector}
                dataTestId="existing-projectName-autocomplete"
                key="adoProjectName"
                label="ADO Project Name *"
                placeholder="Type to search"
                value={order?.customProperties.adoProjectName || null}
                isLoading={projectNameGetApi?.isLoading}
                onInputChange={(_, newInputValue) => {
                    if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue);
                }}
                onChange={(_, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            label: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        setValue({
                            label: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                onBlur={() => {
                    if (!value || !order?.customProperties.adoProjectName) {
                        setValidProjectName(null);
                        setOptions([]);
                        setProjectName(null);
                    }
                }}
                onKeyUp={(event) => {
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                        setOrder({
                            ...order,
                            customProperties: { ...order.customProperties, adoProjectName: null },
                        });
                        setOptions([]);
                    }
                }}
                filterOptions={(x) => x}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="adoProjectName"
                options={options}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.label;
                }}
                renderOption={({ key, ...rest }, option, { index }) => {
                    return (
                        <li key={`${key}-${index}`} {...rest}>
                            {option.label}
                        </li>
                    );
                }}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(ADOProjectName);
