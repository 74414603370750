import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                common: {
                    btnText: {
                        save: 'Save',
                        cancel: 'Cancel',
                    },
                },
                provisioning: {
                    sql: {
                        error: {
                            logicalHost: 'Unable to check logical host name.',
                        },
                    },
                },
                approval: {
                    multiApproval: {
                        terms: 'Please agree the Terms & conditions!',
                    },
                },
                appProfile: {
                    title: {
                        allAppProfile: 'All App Profiles',
                        myAppProfile: 'My App Profiles',
                        newApp: 'New App Profile',
                        modifyApp: 'Edit App Profile',
                        appDetails: 'App Profile Details',
                        appOwner: 'App Woner',
                        appProfiles: 'App Profiles',
                    },
                    label: {
                        appName: 'App Name',
                        businessCI: 'Business CI',
                        financialDetails: 'Financial Details',
                        projectName: 'Project Name',
                        recoveryPriority: 'Recovery Priority',
                        billingEntity: 'Billing Entity',
                        sector: 'Sector',
                        appOwner: 'App Owner',
                        status: 'Status',
                    },
                    placeholder: {
                        searchAppName: 'Search App Name / Business CI',
                    },
                    btnText: {
                        createNew: 'New App Profile',
                    },
                    confirmText: {
                        delete: 'Do you want to delete App Profile?',
                    },
                    infoText: {
                        businessCI: 'Type at least 3 characters for suggestions',
                        financialDetails: 'Type to Search H-Code (e.g. H-000XXX) or Enter a GL Account / PEP Element and hit enter to set the value.',
                        appOwner: 'Application Owner will be able to manage the DevX Infra App Profile & its settings.',
                    },
                },
                landingZone: {
                    title: {
                        landingZone: 'Landing Zones',
                        create: 'Create Landing Zone',
                        modify: 'Modify Landing Zone',
                    },
                    label: {
                        application: 'Application',
                        provider: 'Provider',
                        service: 'Service',
                        environment: 'Environment',
                        appTier: 'App Tier',
                        region: 'Region',
                        subscription: 'Subscription',
                        resourceGroup: 'Resource Group',
                        vnet: 'VNET',
                        subnet: 'Subnet',
                        availabilitySet: 'Availability Set',
                        availabilityZone: 'Availability Zone',
                    },
                    btnText: {
                        apply: 'Apply',
                        reset: 'Reset',
                        close: 'Close',
                    },
                    confirmText: {
                        delete: 'Do you want to delete Landing zone?',
                    },
                    infoText: {
                        resourceGroup: 'Type to search Resource Group',
                        vnet: 'Type to search VNET',
                    },
                },
            },
        },
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
