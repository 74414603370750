import React from 'react';
import { Box, IconButton, Badge } from '@mui/material';
import { FaClone } from 'react-icons/fa';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import Btn from 'components/button';
import { RI003Styles, CustomPaper, ResourceTitle, ConfigureBox } from 'pages/reference-implementation/provisioning/RI003O/style';
// import { InputInfo } from 'pages/reference-implementation/components/input-info';
import { commonStyles as style } from 'assets/style';
import ConfirmDialog from './ConfirmDialog';
import { checkValidFields } from '../../utils/checkValidFields';
import { imageURL } from '../../utils/constant';

const ResourceCard = ({ resource, index, handleClone, handleRemove, handleConfigure, disableCloneIcon, order, appShortNameValid }) => {
    const validation = checkValidFields(order, appShortNameValid);
    const disabled = !validation.isValid;
    const editButton = resource?.configureData ? Object.keys(resource?.configureData)?.length > 0 : false;
    const cloneStyle = {
        color: disableCloneIcon ? '#C1C1C1' : '#616161',
    };
    const [isModalOpen, setModal] = React.useState(false);
    const handleAction = (type, idx) => {
        if (type === 'yes') {
            handleRemove(idx);
        }
        setModal(false);
    };
    return (
        <>
            <CustomPaper evaluation={0}>
                <Box sx={RI003Styles?.ResourceMgmtSectionStyle?.resourceContainer}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Box sx={RI003Styles?.ResourceMgmtSectionStyle?.resourceImageContainer}>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
                                <img
                                    src={`${imageURL}/${resource.configurationComponentCode}.svg`}
                                    alt={`${resource.configurationComponentCode}`}
                                    style={RI003Styles?.ResourceMgmtSectionStyle?.resourceImagStyle}
                                />
                                <ResourceTitle sx={RI003Styles?.ResourceMgmtSectionStyle?.resourceTitle}>{resource?.componentName}</ResourceTitle>
                            </Box>
                            {/* <Box sx={{ paddingRight: '16px' }}>
                                {resource?.groupHeader === 'Mandatory' && (
                                    <InputInfo iconFontSize="16px" maxWidth="400px" title="Default Azure resources will be provisioned." />
                                )}
                            </Box> */}
                        </Box>
                    </Box>
                    <Box sx={{ ...RI003Styles?.ResourceMgmtSectionStyle?.resourceActionContainer, cursor: disabled ? 'not-allowed' : 'pointer' }}>
                        <ConfigureBox>
                            {resource?.configurable && (
                                <>
                                    <Badge
                                        sx={{ '& .MuiBadge-badge': { right: '3px' }, width: '7.5rem', maxWidth: '7.5rem' }}
                                        color="error"
                                        variant={editButton ? 'standard' : 'dot'}
                                    >
                                        <Btn
                                            size="medium"
                                            className="btn-disabled custom-btn"
                                            onClick={() => handleConfigure(index)}
                                            variant={editButton ? 'outlined' : 'contained'}
                                            disabled={disabled}
                                            sx={{
                                                ...style.configureMediumButton,
                                                // backgroundColor: `${editButton ? '#ffffff' : '#D0D0D0 !important'}`,
                                                // border: `${editButton ? '0.125rem solid #0047BA !important' : '0px solid #0047BA'}`,
                                                minWidth: `${editButton ? '130px' : '64px'}`,
                                            }}
                                            data-testid={`${resource?.componentId}-config-button`}
                                        >
                                            {editButton ? 'Edit Config' : 'Configure'}
                                        </Btn>
                                    </Badge>
                                </>
                            )}
                        </ConfigureBox>
                        <Box sx={RI003Styles?.ResourceMgmtSectionStyle?.actionWrapper}>
                            {resource?.cloneable && (
                                <IconButton
                                    onClick={() => {
                                        handleClone(index);
                                    }}
                                    disabled={disableCloneIcon || disabled}
                                    sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                                    data-testid="clone-icon"
                                >
                                    <FaClone sx={cloneStyle} />
                                </IconButton>
                            )}
                            {resource?.groupHeader !== 'Mandatory' && (
                                <IconButton
                                    onClick={() => {
                                        setModal(true);
                                    }}
                                >
                                    <DeleteIcon sx={RI003Styles?.ResourceMgmtSectionStyle?.deleteicon} />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                    {resource?.groupHeader === 'Mandatory' && (
                        <Box sx={RI003Styles?.ResourceMgmtSectionStyle?.mandatoryInfo}>Default Azure resources will be provisioned.</Box>
                    )}
                </Box>
            </CustomPaper>
            {isModalOpen && (
                <ConfirmDialog
                    isModalOpen={isModalOpen}
                    title="Are you sure you want to remove Resource?"
                    primaryBtnText="Yes"
                    secondaryBtnText="No"
                    primaryBtnAction={() => handleAction('yes', index)}
                    secondaryBtnAction={() => handleAction('no', index)}
                />
            )}
        </>
    );
};
ResourceCard.propTypes = {
    resource: PropTypes.any,
    index: PropTypes.number,
    handleClone: PropTypes.func,
    handleRemove: PropTypes.func,
    handleConfigure: PropTypes.func,
    disableCloneIcon: PropTypes?.any,
    order: PropTypes?.any,
    appShortNameValid: PropTypes.any.isRequired,
};

ResourceCard.defaultProps = {
    resource: null,
    index: null,
    handleClone: () => {},
    handleRemove: () => {},
    handleConfigure: () => {},
    disableCloneIcon: {},
    order: {},
};
export default ResourceCard;
