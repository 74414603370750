/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Modal from 'components/modal';
import { OrderPayload, isRolesValid, toggleModels } from 'pages/sso-okta/store';
import { addRoles, removeRoles } from 'pages/sso-okta/store/selector';
import { Box } from '@mui/material';
// import { FaTrashAlt } from 'react-icons/fa';
// import { MdAdd } from 'react-icons/md';
import deleteIcon from 'assets/images/delete_icon.svg';
import addIcon from 'assets/images/add_icon.svg';
import Role from 'pages/sso-okta/components/Form/Role';
import Btn from 'components/button';
import { commonStyles as style } from 'assets/style';
import { customToast } from 'api/utils';
import { Style } from './style';

const Roles = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleModels);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const addRole = useSetRecoilState(addRoles);
    const removeRole = useSetRecoilState(removeRoles);
    const [lastModifiedRoles, setLastModifiedRoles] = React.useState(undefined);
    const [, setRolesValid] = useRecoilState(isRolesValid);

    React.useEffect(() => {
        if (isOpen?.isRoleOpen) {
            setLastModifiedRoles(order?.roles);
        }
    }, [isOpen?.isRoleOpen]);
    const isFormValid = () => {
        let errorCount = 0;
        let isValid = false;
        if (order?.roles?.length) {
            order?.roles?.forEach((role) => {
                if (role && (role?.role === '' || role?.role === null || !/^[a-zA-Z0-9_-\s\\(\\)]{4,64}$/.test(role?.role))) {
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
        }
        isValid = errorCount === 0;
        return isValid;
    };
    const isDuplicateRoles = () => {
        const rolesArray = order?.roles?.map((item) => item?.role?.toLowerCase());
        return new Set(rolesArray).size !== rolesArray.length;
    };
    const handleSubmit = async () => {
        if (!isFormValid() || isDuplicateRoles()) {
            customToast(['Roles must not be empty, should be unique, and must be valid.']);
            setRolesValid(false);
            setOrder({ ...order, isRoleFormTouched: true });
        } else {
            setIsOpen({ ...isOpen, isRoleOpen: false });
            setRolesValid(true);
        }
    };

    return (
        <Modal
            title="Add Role"
            handleClose={() => {
                setIsOpen({ ...isOpen, isRoleOpen: false });
                setOrder({
                    ...order,
                    roles: lastModifiedRoles,
                });
            }}
            open={isOpen.isRoleOpen}
        >
            <Box sx={Style.modal.wrapper}>
                <Box sx={{ ...Style.modal.contentWrapper }}>
                    {order?.roles?.map((item, index) => (
                        <Box key={index.toString()} sx={Style.modal.rowWrapper} className="space-x-2 mb-5 animate-opacity">
                            <Box sx={Style.modal.groupWrapper}>
                                <React.Fragment key={index.toString()}>
                                    <Role indexVal={index} />
                                </React.Fragment>
                            </Box>
                            <Box sx={Style.modal.iconWrapper}>
                                <Box className="flex w-full ">
                                    {index !== 0 && order?.roles?.length > 1 && (
                                        <Box onClick={() => removeRole(item)} sx={Style.modal.deleteWrapper}>
                                            {/* <FaTrashAlt
                                                style={Style.modal.delete}
                                                onClick={() => removeRole(item)}
                                                data-testid="removeIcon"
                                                className="w-7 h-7 text-red-base cursor-pointer"
                                            /> */}
                                            <img data-testid="removeIcon" style={Style.modal.delete} src={deleteIcon} alt="deleteIcon" />
                                        </Box>
                                    )}
                                    {index === order?.roles.length - 1 && order?.roles?.length < 10 ? (
                                        // <MdAdd
                                        //     style={Style.modal.add}
                                        //     onClick={() => {
                                        //         addRole({ count: 1 });
                                        //     }}
                                        //     data-testid="addIcon"
                                        //     className="w-7 h-7 text-blue-base cursor-pointer"
                                        // />
                                        <Box onClick={() => addRole({ count: 1 })} sx={Style.modal.deleteWrapper}>
                                            <img style={Style.modal.add} src={addIcon} alt="addIcon" />
                                        </Box>
                                    ) : (
                                        <span className="w-7 h-7" />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="footer" sx={Style.modal.actionWrapper} gap={2}>
                    <Btn
                        className="custom-btn"
                        variant="contained"
                        // color="cmpPrimary"
                        sx={style.modalSaveButton}
                        onClick={() => {
                            handleSubmit();
                        }}
                        data-testid="save-btn"
                    >
                        Save
                    </Btn>
                    <Btn
                        className="custom-btn"
                        // sx={Style.modal.cancelBtn}
                        sx={style.modalCancelButton}
                        onClick={() => {
                            setIsOpen({ ...isOpen, isRoleOpen: false });
                            setOrder({
                                ...order,
                                roles: lastModifiedRoles,
                            });
                        }}
                        variant="outlined"
                        // color="cmpWarning"
                        layout="outline"
                        data-testid="cancel-btn"
                    >
                        Cancel
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

export default Roles;
