import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { orderPayload, maxVSErrorMessage, virtualServicesType } from 'pages/provisioning/virtual-service/store';
import { virtualServiceDefaultValues } from 'pages/provisioning/virtual-service/utils/resetFunction';
import { commonStyles as style } from 'assets/style';
import Btn from 'components/button';

export const VirtualServiceType = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [, setError] = useRecoilState(maxVSErrorMessage);
    const [options, setOptions] = useRecoilState(virtualServicesType);

    const virtualServiceTypeApi = useMutation(() => axios.get(endPoints.virtualService?.Jwt?.vsOnboardTypes));

    const setVirtualServiceType = (aksVirtualServiceType) => {
        setOrder({
            ...order,
            aksVirtualServiceType,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (virtualServiceType) => options.find((option) => virtualServiceType === option.value);

    const handleOnSelectChange = (selected) => setVirtualServiceType(selected.label);

    React.useEffect(() => {
        if (virtualServiceTypeApi?.isSuccess) {
            setOptions(virtualServiceTypeApi?.data?.data?.virtualServiceOnboardTypes?.map((app) => ({ label: app?.name, value: app?.code })));
        }
    }, [virtualServiceTypeApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.virtualServiceType) {
            setOrder({ ...order, virtualServiceType: options?.[0].value });
        }
        if (options && options?.length && order?.virtualServiceType) {
            const virtualServiceType = isExistsInOptions(order?.virtualServiceType);
            setOrder({ ...order, virtualServiceType: virtualServiceType?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        virtualServiceTypeApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.virtualServiceType && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.virtualServiceType, order?.isTouched]);

    const handleAddVSType = () => {
        if (order?.aksVirtualService?.length >= 10) {
            return setError('You can add only 10 Virtual Services');
        }
        return setOrder(() => ({
            ...order,
            aksVirtualService:
                order?.aksVirtualService?.[0]?.virtualServiceType !== null
                    ? [...order?.aksVirtualService, { ...virtualServiceDefaultValues, virtualServiceType: order?.aksVirtualServiceType }]
                    : [{ ...order?.aksVirtualService?.[0], hostNamePrefix: null, virtualServiceType: order?.aksVirtualServiceType }],
        }));
    };

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="vs-type-section">
            <Grid item container spacing={2}>
                <Grid item md={order?.aksVirtualServiceType ? 10 : 12} sm={order?.aksVirtualServiceType ? 9 : 12} xs={12}>
                    <Stack direction="column" spacing={2}>
                        <Select
                            dataTestId="service-type-autocomplete"
                            key="virtualServiceType"
                            label="Virtual Service Type *"
                            value={order?.aksVirtualServiceType || null}
                            options={options}
                            handleOnSelect={(value) => handleOnSelectChange(value)}
                            disabled={virtualServiceTypeApi.isLoading || !order?.sector || !order?.environment || !order?.aksCluster || !order?.aksNamespace}
                            isLoading={virtualServiceTypeApi.isLoading}
                            isRequired={isTouched}
                        />
                    </Stack>
                </Grid>
                {order?.aksVirtualServiceType && (
                    <Grid item md={2} sm={2} xs={12}>
                        <Stack direction="column" spacing={2}>
                            <Btn
                                variant="contained"
                                sx={style?.primaryMediumButton}
                                onClick={() => handleAddVSType()}
                                disabled={false}
                                data-testid="add-vs-btn"
                            >
                                Add
                            </Btn>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default React.memo(VirtualServiceType);
