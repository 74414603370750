import React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import GithubOnboardingStyles from 'pages/github-onboarding/style';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/github-onboarding/store';

const ApplicationDetails = () => {
    const [order] = useRecoilState(OrderPayload);
    const LabelValue = (label, value) => (
        <Grid item xs={6} sm={6}>
            <Box sx={GithubOnboardingStyles?.preview?.projectDetails?.subWrapper}>
                <Box sx={GithubOnboardingStyles?.preview?.projectDetails?.label}>{label}:</Box>
                <Box sx={GithubOnboardingStyles?.preview?.projectDetails?.value}>{value || '-'}</Box>
            </Box>
        </Grid>
    );

    return (
        <Box sx={GithubOnboardingStyles?.preview?.wrapper}>
            <Box sx={GithubOnboardingStyles?.preview?.heading}>Application Details</Box>
            <Divider variant="horizontal" sx={GithubOnboardingStyles?.preview?.divder} />
            <Grid container sx={GithubOnboardingStyles?.preview?.projectDetails?.container} spacing={2}>
                {LabelValue('Business Application Name', order?.customProperties?.businessApplication)}
                {LabelValue('Application Service Name', order?.customProperties?.applicationService)}
                {LabelValue('Application Owner', order?.customProperties?.applicationOwner)}
                {LabelValue('Application Support Group', order?.customProperties?.applicationSupportGroup)}
                {LabelValue('Capability', order?.customProperties?.capability)}
                {LabelValue('Cost Center Code', order?.customProperties?.cmpHCode)}
            </Grid>
        </Box>
    );
};

export default ApplicationDetails;
