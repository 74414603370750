const PreviewStyles = {
    title: {
        color: (theme) => theme?.palette?.black?.dark,
        fontSize: '24px',
        fontWeight: 'medium',
    },
    wrapper: { display: 'flex', scroll: 'auto', flexWrap: 'wrap' },
    previewDetailsTextWrap: { paddingRight: 3 },
    previewDetailsText: { fontSize: '18px', color: '#021D49', fontWeight: '600' },
    previewOrderText: { fontSize: '16px', color: '#000000', fontWeight: 'normal', opacity: 1 },
    card: {
        boxShadow: '0px 0px 2px #0000003E',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        marginTop: 2,
        padding: '15px 15px 15px 25px',
    },
    generalInfo: { fontSize: '18px', color: '#1378D4', fontWeight: '600', letterSpacing: '-0.14px ' },
    generalWrapper: { display: 'flex', flexDirection: 'column', marginTop: '-5px' },
    devider: { color: '#707070', marginTop: 1, marginBottom: 1 },
    generalKey: {
        fontSize: '16px',
        color: '#000000',
        fontWeight: '600',
        letterSpacing: '0px',
    },
    generalValue: {
        fontSize: '14px',
        color: '#000000',
        fontWeight: 'normal',

        letterSpacing: '0px ',
    },
    resourceWrapper: { paddingY: 2 },
    editBtn: {
        // border: ' 1px solid #707070',
        borderRadius: '4px',
        // background: '#FFFFFF 0% 0% no-repeat padding-box',
        height: (theme) => theme.spacing(5.625),
        fontSize: '16px !important',
        fontWeight: 'medium',
        textTransform: 'none',
    },
    downloadBtn: {
        width: (theme) => theme.spacing(31.5),
        marginLeft: 2,
        height: (theme) => theme.spacing(5.625),
        fontSize: '16px !important',
        fontWeight: 'medium',
        textTransform: 'none',
    },
    downloadPdfBtn: {
        // width: (theme) => theme.spacing(31.5),
        marginLeft: 2,
        height: (theme) => theme.spacing(5.625),
        fontSize: '16px !important',
        fontWeight: 'medium',
        textTransform: 'none',
        minWidth: (theme) => theme.spacing(21),
    },
    resourceItemWrapper: { marginTop: 2.5 },
    reourceCard: {
        boxShadow: '0px 0px 2px #0000003E',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        opacity: 1,
        padding: '15px 34px 15px 34px',
        marginBottom: 1.5,
    },
    resourceType: {
        fontSize: '18px',
        color: '#1378D4',
        fontWeight: '600',

        letterSpacing: '-0.14px ',
        paddingBottom: 2,
    },
    resourceProperty: { paddingTop: 1, borderTop: '1.5px solid #E8E8E8', marginTop: '10px' },
    roleAssgnWrapper: {
        paddingTop: 1.5,
        borderTop: '1.5px solid #E8E8E8',
        marginTop: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 2,
    },
    roleAssignMent: {
        textAlign: 'left',
        fontSize: '16px',
        fontWeight: '600',

        letterSpacing: '-0.13px',
        marginRight: 2,
    },
    role: {
        color: '#1378D4',
        fontSize: '16px',
        fontWeight: 'normal',

        paddingTop: 1,
        whiteSpace: 'nowrap',
    },
    roleVal: {
        color: '#000000',
        fontSize: '14px',
        fontWeight: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    roleAssignMentVal: { display: 'flex', flexWrap: 'wrap' },
    roleAssignMentWrapper: { padding: '2px 4px' },
};

export default PreviewStyles;
