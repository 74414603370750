import { styled } from '@mui/material/styles';
import { Radio, RadioGroup } from '@mui/material';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const SsoOktaStyles = {
    form: {
        radioLabel: { color: '#666666', font: (theme) => theme.typography.fontSize.$font_xsmall_regular, width: '100%' },
    },
    ssoOkta_wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: (theme) => theme.spacing('100%'),
        height: 'calc(100vh - 100px)',
    },
    circularProgressColor: { color: (theme) => theme.palette.black.dark },
    infoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { font: (theme) => theme.typography.fontSize.$font_xsmall_caps },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    addBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
    },
    resubmitBtn: {
        width: (theme) => theme.spacing(20.75),
        height: (theme) => theme.spacing(4.375),
    },
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitOrder: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypo: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        actionWrapper: { textAlign: 'center', my: (theme) => theme.spacing(0.25), py: (theme) => theme.spacing(2) },
        newOrder: { marginRight: (theme) => theme.spacing(2) },
        message: { font: (theme) => theme.typography.fontSize.$font_heading_h4_medium, color: (theme) => theme.palette.black.dark },
        description: { width: '500px', font: (theme) => theme.typography.fontSize.$font_body_regular, color: (theme) => theme.palette.black.dark },
    },
    removeBtn: {
        cursor: 'pointer',
        color: '#F4273B',
    },
    emptyStack: {
        width: '100%',
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    roleWrapper: {
        label: { font: (theme) => theme.typography.fontSize.$font_body_medium, color: '#0047BA' },
    },
    Dialog: {
        title: { font: (theme) => theme.typography.fontSize.$font_heading_h6_bold, color: '#000000', paddingTop: '10px', paddingBottom: '5px' },
        msgDiv: { font: (theme) => theme.typography.fontSize.$font_small_regular, padding: '5px 11px' },
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: '360px',
            height: '145px',
            paddingTop: '15px',
            paddingBottom: '5px',
        },
        IconColor: '#C02223',
        icon: { fill: '#C02223', fontSize: '60px' },
        AzIcon: '#d02424',
        backBtnBox: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2), paddingBottom: '5px' },
        backBtn: { paddingX: '65px', textTransform: 'none' },
        AgreeBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            paddingX: '65px',
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                border: '2px solid #00A0DD',
                background: 'transparent',
            },
        },
    },
    accessControl: {
        select: {
            '& label.Mui-focused': {
                color: '#0047BA',
            },

            '& label.MuiInputLabel-shrink': {
                color: '#0047BA',
                padding: '0 2px',
                fontWeight: 700,
            },
        },
        chip: {
            '& svg.MuiChip-deleteIcon': {
                color: 'red',
            },
            borderRadius: '5px',
            height: '20px',
        },
        deleteIcon: { fontSize: '14px', color: '#707070' },
    },
    modalBtnStyle: {
        stack: { alignItems: 'center', justifyContent: 'flex-start', marginBottom: (theme) => theme.spacing('20px') },
        btnWrapper: { width: '100%', ml: (theme) => theme.spacing(0.25) },
        badgeStyle: { width: '100%' },
        modalBtn: {
            font: (theme) => `${theme.typography.fontSize.$font_xsmall_caps}!important`,
            color: (theme) => theme.palette.interAction.$color_interaction_active,
            textTransform: 'none',
            background: 'transparent',
            borderRadius: (theme) => theme.palette.border.$border_radius,
            padding: '3px 15px',
            height: '24px',
            border: (theme) => `1px solid ${theme.palette.border.$color_border_secondary_button}`,
            '&:hover': {
                color: (theme) => theme.palette.button.$color_button_primary_hover,
                border: (theme) => `1px solid ${theme.palette.background.$color_background_button_primary_hover} !important`,
                background: 'transparent',
            },
            '&:active': {
                color: (theme) => theme.palette.button.$color_button_primary_active,
                border: (theme) => `1px solid ${theme.palette.button.$color_button_primary_active} !important`,
            },
        },
    },
    loginLogoutModal: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        consumerGrpWrapper: { width: '120%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        delete: {
            cursor: 'pointer',
            width: '16px',
            height: '16px',
        },
        add: { width: '16px', height: '16px', cursor: 'pointer', marginTop: '7px', fontSize: '24px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        warningTypography: { color: (theme) => theme.palette.red.light },
        deleteWrapper: {
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
            mt: 0.5,
        },
    },
};
export default SsoOktaStyles;

// export const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: '#0047BA',
//         color: theme.palette.white,
//         font: theme.typography.fontSize.$font_body_medium,
//         padding: '0px',
//         border: 0,
//         paddingLeft: 15,
//     },
//     [`&.${tableCellClasses.body}`]: {
//         font: theme.typography.fontSize.$font_small_regular,
//         color: '#242424',
//         '&:first-of-type': {
//             borderLeft: '1px solid rgba(224, 224, 224, 1)',
//         },
//         '&:last-child': {
//             borderRight: '1px solid rgba(224, 224, 224, 1)',
//         },
//     },
// }));

// export const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     backgroundColor: theme.palette.white,
//     border: 0,
//     paddingTop: '5px',
//     height: '50px',
//     '&:nth-of-type(1) td': {
//         height: '60px',
//         paddingTop: '15px',
//     },
//     '&:hover': {
//         backgroundColor: '#E5F5FC',
//     },
//     // hide last border
//     th: {
//         marginLeft: 20,
//     },
//     '&:last-child td, &:last-child th': {},
// }));
export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
