/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Badge } from '@mui/material';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { getLoggedUserClaims, getLoggedUserFullName } from 'utils';
import EnvironmentDetails from 'pages/provisioning/_components/EnvironmentDetails';
import { format } from 'date-fns';
import { sspSubmitOrder, environmentDetails, toggleSspModels, isMandatoryTagsFullfilled, isEnvDetailsLoading } from 'pages/provisioning/store/couchbase';
// import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import Btn from 'components/button';
import { commonStyles as style } from 'assets/style';

const EnvironmentContainer = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [envDetails, setEnvDetails] = useRecoilState(environmentDetails);
    const [, setLoading] = useRecoilState(isEnvDetailsLoading);
    const resetEnvDetails = useResetRecoilState(environmentDetails);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const [isMandatoryTags, setIsMandatoryTags] = useRecoilState(isMandatoryTagsFullfilled);
    const envDetailsDataApi = useMutation((payload) => axios.post(endPoints.ssp.vm.environmentDetails_v2, payload));
    const disasterRecoveryApi = useMutation((payload) => axios.post(endPoints.ssp.vm.disasterRecoveryOptions, payload));
    const tagsApi = useMutation(() => axios.get(endPoints.ssp.vm.mandatoryTags));
    const tagsBasedOnResoureGroupApi = useMutation(() => axios.get(`${endPoints.ssp.vm.resourceGroupTags}${order?.envDetails?.config?.resourceGroup}/tags`));
    const defaultApi = useMutation(() => axios.get(endPoints.ssp.couchbase.defaults));
    const userClaims = getLoggedUserClaims();

    const checkVarConditions = () =>
        order?.appProfile && order.environment && order.appTierCode && order.region && order?.cloudProvider && order?.cloudService && order?.landingZoneDetails;

    React.useEffect(() => {
        if (tagsApi?.data?.data?.values) {
            const tagsValue = tagsApi.data.data.values.map((tag) => {
                if (tag?.name === 'Environment' && (!tag?.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: order?.environment };
                }
                if (tag?.name === 'ApplicationService' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: order?.appProfile };
                }
                if (tag?.name === 'HoursOfOperation' && (!tag.editableOnBlank || !tag.editable)) {
                    if (order?.tagsQc?.HoursOfOperation) {
                        return { ...tag, defaultValue: order?.tagsQc?.HoursOfOperation };
                    }
                    return { ...tag, defaultValue: 'Always ON' };
                }
                if (tag?.name === 'FinancialTag' && (!tag.editableOnBlank || !tag.editable)) {
                    return tag;
                }
                if (tag?.name === 'Servicename' && (!tag.editableOnBlank || !tag.editable)) {
                    if (order?.tagsQc?.Servicename) {
                        return { ...tag, defaultValue: order?.tagsQc?.Servicename };
                    }
                    if (tagsBasedOnResoureGroupApi?.data?.data?.tags?.serviceName) {
                        return { ...tag, defaultValue: tagsBasedOnResoureGroupApi?.data?.data?.tags?.serviceName };
                    }
                    return tag;
                }
                if (tag?.name === 'Migration') {
                    if (order?.tagsQc?.Migration) {
                        return { ...tag, defaultValue: order?.tagsQc?.Migration };
                    }
                    return tag;
                }
                if (tag?.name === 'Requestedby' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: getLoggedUserFullName() };
                }
                if (tag?.name === 'Provisioneddate' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: format(new Date(), 'yyyy-MM-dd') };
                }
                if (tag?.name === 'Sector' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: order?.sector || '-' };
                }
                if (tag?.name === 'Billingentity' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: order?.billingEntity || '-' };
                }
                if (tag?.name === 'Costcenter' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: order?.hCode ? order?.hCode : '-' };
                }
                return tag;
            });
            setOrder({ ...order, tags: tagsValue, defaultTags: tagsValue });
        }
        // eslint-disable-next-line
    }, [tagsApi.data?.data, order?.tagsQc]);

    React.useEffect(() => {
        if (tagsBasedOnResoureGroupApi?.isSuccess) tagsApi.mutate();
    }, [tagsBasedOnResoureGroupApi?.isSuccess]);

    React.useEffect(() => {
        setIsMandatoryTags(false);
        if (order?.tags?.length) {
            const tagsMandateCheck = order?.tags.filter((tag) => tag.defaultValue === '' && tag.editableOnBlank)?.length > 0;
            setIsMandatoryTags(tagsMandateCheck);
        }
        // eslint-disable-next-line
    }, [order?.tags]);

    React.useEffect(() => {
        tagsBasedOnResoureGroupApi.reset();
        disasterRecoveryApi.reset();
        if (checkVarConditions()) {
            setLoading(true);
            envDetailsDataApi.mutate({
                landingZoneId: order?.landingZoneDetails?.id,
            });
        } else {
            resetEnvDetails();
        }
        // eslint-disable-next-line
    }, [order?.landingZoneDetails]);

    React.useEffect(() => {
        if (checkVarConditions() && order?.envDetails?.config?.resourceGroup) {
            tagsBasedOnResoureGroupApi.mutate();
        }
        // eslint-disable-next-line
    }, [order?.appProfile, order.environment, order.appTier, order.region, order?.envDetails?.config?.resourceGroup]);

    React.useEffect(() => {
        if (envDetailsDataApi?.data?.data && envDetailsDataApi?.data?.data?.drPriority && order?.appTierCode && order?.environment) {
            const env = envDetailsDataApi?.data?.data;
            disasterRecoveryApi.mutate({
                appTierCode: order?.appTierCode,
                drPriority: env?.drPriority,
                environment: order?.environment,
            });
            defaultApi.mutate();
        }
        // eslint-disable-next-line
    }, [envDetailsDataApi?.data?.data]);

    React.useEffect(() => {
        if (checkVarConditions() && defaultApi?.isSuccess) {
            const env = envDetailsDataApi?.data?.data;
            const disasterRecoveryData = disasterRecoveryApi?.data?.data;
            const defaults = defaultApi?.data?.data?.defaults;
            if (env) {
                setOrder({
                    ...order,
                    requestorGpid: userClaims?.accessToken?.claims?.gpid,
                    requestorName: getLoggedUserFullName(),
                    maxNodeCount: defaults?.maxNodes,
                    envDetails: {
                        ...env,
                        config: {
                            ...env?.config,
                            availabilityZone: env?.config?.availabilityZone?.map((item) => item.toString())?.sort() || [],
                        },
                        metallic: disasterRecoveryData?.metallic,
                        backupTier: disasterRecoveryData?.backupTier,
                        asr: defaults?.asr ? 'enabled' : 'disabled',
                    },
                    availabilityZoneOptions: env?.config?.availabilityZone?.map((item) => item.toString())?.sort() || [],
                });
                setEnvDetails({
                    Approval_Manager: env?.applicationManager,
                    Project_Name: order?.hCodeDetails?.projectName ? order?.hCodeDetails?.projectName : '-',
                    ASR: defaults?.asr ? 'enabled' : 'disabled',
                    Enable_Acceleration_Network: defaults?.accelaratedNetwork ? 'yes' : 'no',
                });
            }
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [defaultApi?.isSuccess]);

    return (
        <>
            <EnvironmentDetails envs={envDetails} isLoading={envDetailsDataApi?.isLoading}>
                <Box>
                    {order?.region && order?.tags?.length !== 0 && (
                        <>
                            <Badge color="error" variant={isMandatoryTags ? 'dot' : null} sx={style.tagBadge}>
                                <Btn
                                    className="btn-disabled custom-btn"
                                    onClick={() => setModal({ ...modal, isTagOpen: true })}
                                    variant="contained"
                                    disableElevation
                                    sx={style.serviceNewTagBtn}
                                    data-testid="tags-btn"
                                >
                                    Tags*
                                </Btn>
                            </Badge>
                        </>
                    )}
                    <Btn
                        className="btn-disabled custom-btn"
                        disabled={!order?.appProfile}
                        onClick={() =>
                            order?.appProfile
                                ? setModal({
                                      ...modal,
                                      isApplicationOpen: true,
                                  })
                                : false
                        }
                        variant="outlined"
                        sx={style.secondarySmallButton}
                        disableElevation
                        data-testid="app-details"
                    >
                        Application details
                    </Btn>
                </Box>
            </EnvironmentDetails>
        </>
    );
};

export default EnvironmentContainer;
