import React from 'react';
import { Box, Card, Typography, Divider } from '@mui/material';
import groupBy from 'lodash/groupBy';
import VSTypeDetails from 'pages/provisioning/virtual-service/components/preview/VSTypeDetails';
import PreviewStyles from 'pages/provisioning/virtual-service/components/preview/style';
import { useRecoilValue } from 'recoil';
import {
    orderPayload,
    isDivisionRequired,
    isAppRegionRequired,
    virtualServicesType,
    isDisplayProgram,
    certCreationRequired,
} from 'pages/provisioning/virtual-service/store';
import { convertToTitleCase } from 'utils';
import PreviewHeader from 'pages/provisioning/virtual-service/components/preview/PreviewHeader';
import { getOrderPayload } from 'pages/provisioning/virtual-service/utils/getFunction';

const PreviewDetails = () => {
    const order = useRecoilValue(orderPayload);
    const divisionReq = useRecoilValue(isDivisionRequired);
    const appRegionReq = useRecoilValue(isAppRegionRequired);
    const vsTypes = useRecoilValue(virtualServicesType);
    const displayProgram = useRecoilValue(isDisplayProgram);
    const isRequired = useRecoilValue(certCreationRequired);

    const previewData = getOrderPayload(order, vsTypes, isRequired);

    const generalInfo = {
        applicationService: previewData?.applicationService,
        sector: previewData?.sector,
        ...(divisionReq && { division: previewData?.division }),
        ...(appRegionReq && { applicationRegion: previewData?.region }),
        environment: previewData?.environment,
        ...(displayProgram && { program: previewData?.programName }),
        'AKS Cluster': previewData?.aksCluster,
        namespaceName: previewData?.aksNamespace,
        ...(previewData?.costCenter && { 'Budget Code': previewData?.costCenter }),
        ...(previewData?.applicationOwnerDetails?.name && { appOwner: previewData?.applicationOwnerDetails?.name }),
        ...(previewData?.certificateDetails && {
            'Certificate Owner One': previewData?.certificateDetails?.certificateOwnerOneName,
            'Certificate Owner Two': previewData?.certificateDetails?.certificateOwnerTwoName,
        }),
    };

    let virtualServiceData = null;
    if (order?.aksVirtualServiceRequestType?.toLowerCase() === 'update') {
        virtualServiceData = {
            'Update Virtual Service with Custom Domain Host Name': previewData?.aksVirtualService,
        };
    } else {
        virtualServiceData = groupBy(previewData?.aksVirtualService, 'virtualServiceTypeName');
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }} px={1} py={1}>
            <PreviewHeader />
            <Box display="flex" height="100%" width="100%" sx={PreviewStyles?.wrapper} gap={2}>
                <Box flex={1}>
                    <Card sx={PreviewStyles?.card}>
                        <Typography component="span" sx={PreviewStyles?.generalInfo}>
                            General Info
                        </Typography>
                        <Box sx={PreviewStyles?.generalWrapper}>
                            {generalInfo &&
                                Object.keys(generalInfo)?.length &&
                                Object.keys(generalInfo).map((key, idx) => (
                                    <React.Fragment key={`general-info-${idx}`}>
                                        <Divider variant="horizontal" sx={PreviewStyles?.divider} />
                                        <Typography component="span" sx={PreviewStyles?.keyText}>
                                            {convertToTitleCase(key)}:
                                        </Typography>
                                        <Typography component="span" sx={PreviewStyles?.valueText}>
                                            {generalInfo?.[key]}
                                        </Typography>
                                    </React.Fragment>
                                ))}
                        </Box>
                    </Card>
                </Box>
                <Box flex={3} py={2}>
                    {Object.keys(virtualServiceData)?.map((vsType, vsTypeIndex) => (
                        <React.Fragment key={`vs-type-details-${vsTypeIndex}`}>
                            <VSTypeDetails virtualServiceData={virtualServiceData} vsType={vsType} vsTypeIndex={vsTypeIndex} />
                        </React.Fragment>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default PreviewDetails;
