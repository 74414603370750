const Style = {
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingX: 2,
        paddingY: 1,
        minHeight: '50px',
    },
    leftContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    titleBox: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    title: {
        font: (theme) => theme.typography.fontSize.$font_small_medium,
        // font: '16px',
        // fontWeight: 500
    },
    messageBox: { fontWeight: 400, textAlign: 'left' },
    messageBoxWithoutTitle: { fontWeight: 400, textAlign: 'left', marginRight: '5px', width: '100%' },
    closeIcon: { float: 'right', color: '#616161', fontSize: '18px' },
    closeIconWithoutTitle: { float: 'right', color: '#616161', fontSize: '18px', marginLeft: '8px' },
};
export default Style;
