import { styled } from '@mui/material/styles';
import { TextField, Button } from '@mui/material';

const styles = {
    loadingStack: { width: (theme) => theme.spacing('100%'), height: 'calc(100vh - 100px)' },
    headercontainer: {
        px: 6,
        py: 1.2,
        background: '#FBFCFE 0% 0% no-repeat padding-box',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // borderBottom: '1px solid #E0E0E0',
        // boxShadow: '0px 1px 1px #0000004B',
        width: '100%',
        boxShadow: 'rgba(0, 92, 188, 0.15) 0px 0px 24px 0',
        zIndex: 99,
        // fontFamily: (theme) => theme.typography.fontFamily,
    },
    headingTypo: {
        font: (theme) => theme.typography.fontSize.$font_heading_h5_medium,
        color: (theme) => theme.palette.cmpBlack.doply,
        // '&:hover': {
        //     color: (theme) => theme.palette.cmpPrimary.main,
        // },
    },
    actionContainer: { display: 'flex', mr: (theme) => theme.spacing(1) },
    searchbox: {
        display: 'flex',
        alignItems: 'center',
        width: (theme) => theme.spacing(40),
        background: (theme) => theme.palette.white,
        borderRadius: '1px',
        mr: (theme) => theme.spacing(2),
    },
    clearicon: { color: (theme) => theme.palette.blue.main },
    searchiconBtn: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    searchicon: {
        color: (theme) => theme.palette.blue.main,
    },
    filterButton: {
        height: '38px',
        width: '100px',
        color: (theme) => theme.palette.blue.strongDark,
        backgroundColor: (theme) => theme.palette.background.$color_background_layer_02,
        border: '1px solid #D7E5F8',
        borderRadius: (theme) => theme.palette.border.$border_radius,
        '&:hover': {
            color: (theme) => theme.palette.blue.strongDark,
            border: '1px solid #D7E5F8',
            backgroundColor: (theme) => theme.palette.button.$color_buttongroup_default,
        },
    },
    mainstack: {
        width: (theme) => theme.spacing('100%'),
        // height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
    },
    cataloginncontainer: { width: 'auto', display: 'flex', justifyContent: 'center' },
    gridcontain: { backgroundColor: (theme) => theme.palette.grey.lighter },
    griditemcontain: { mb: 2, backgroundColor: (theme) => theme.palette.grey.lighter },
    orderFilter: {
        container: {
            color: (theme) => theme.palette.white,
            borderColor: '#0063cc',
            border: '2px solid',
        },
        wrapper: {
            background: (theme) => theme.palette.white,
            width: 260,
            padding: 2,
            position: 'relative',
            top: 2,
            right: '10px',
        },
        arrow: {
            position: 'absolute',
            fontSize: 60,
            top: -34,
            left: 100,
            fill: (theme) => theme.palette.white,
            zIndex: -1,
            right: '76px',
        },
        // filter: {
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     border: 'solid 1px #ffffff',
        //     marginRight: 1.5,
        //     paddingX: 0.5,
        // }
        filterIcon: { color: (theme) => theme.palette.white },
        closeIcon: { cursor: 'pointer' },
        popper: {
            container: { display: 'flex', justifyContent: 'end' },
        },
    },
};
/* page->reference-implementation->services->style.js css part */
export const RefImpServices = {
    mainTextContainer: {
        font: (theme) => theme.typography.fontSize.$font_heading_h5_bold,
        // fontSize: '24px',
        // fontWeight: '700',
        marginBottom: '8px',
    },
    subTextContainer: {
        font: (theme) => theme.typography.fontSize.$font_body_regular,
        display: 'flex',
        width: '100%',
        // paddingLeft: '158px',
        marginBottom: '10px',
        justifyContent: 'center',
        color: (theme) => theme.palette.black.dark,
        // fontFamily: (theme) => theme.typography.fontFamily,
        // fontSize: (theme) => theme.spacing('16px'),
    },

    gridWrapper: { paddingLeft: '40px', width: '100%' },
    newGridWrapper: { paddingLeft: { md: 3, xs: 2, lg: 0 }, paddingRight: { md: 3, xs: 2, lg: 0 }, width: '100%' },
    // staticCard class not used so commented
    /* staticCard: {
        position: 'relative',
        width: '290px',
        height: '440px',
        mr: 'auto',
        backgroundImage: 'url("/static/images/static-catalog-card.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }, */
};
export const ServicePage = {
    catalogServiceAllWraper: {
        boxShadow: '0 0 24px rgba(0, 92, 188, 0.08)',
        position: 'relative',
        width: '280px',
        minHeight: '330px',
        height: '100%',
        mr: 'auto',
    },
    gridWrapper: { maxWidth: '1160px' },
};
export const CssTextField = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: theme.palette.blue.main,
    },
    '& label.MuiFormLabel-filled': {
        // background: '#00709B',
        color: theme.palette.blue.main,
        padding: '0 2px',
    },
    '& label': {
        color: theme.palette.gray.graniteGray,
        // borderRadius: 10,
    },
    '& .MuiInputBase-input': {
        color: theme.palette.blue.dark,
        background: '#FFFFFF',
        '&.Mui-disabled': {
            background: 'transparent',
        },
        '&:focus': {
            borderColor: theme.palette.blue.main,
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.gray.light,
            color: theme.palette.blue.lightest,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.gray.light,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.gray.light,
        },
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E0E0E0',
        borderRadius: '20px',
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #0047BA',
        borderRadius: '20px',
    },
    '& .MuiMenuItem-root.Mui-selected': {
        backgroundColor: 'yellow',
    },
    '& .MuiMenuItem-root:hover': {
        backgroundColor: 'pink',
    },
    '& .MuiMenuItem-root.Mui-selected:hover': {
        backgroundColor: 'red',
    },
}));

export const ColorButton = styled(Button)(({ theme }) => ({
    fontWeight: 700,
    backgroundColor: theme.palette.blue.strongDark,
    borderRadius: theme.palette.border.$border_radius,
    color: theme.palette.white,
    '&:hover': {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.white,
    },
}));

export default styles;
