import React from 'react';
import { useRecoilState } from 'recoil';
import { toggleModal } from 'pages/github-onboarding/store';
import Modal from 'components/modal';
import AdoReposToMigrate from './AdoReposToMigrate/AdoReposToMigrate';
import AssignReposToTeams from './AssignReposToTeams/AssignReposToTeams';
import getModalTitle from '../../../utils/getModalTitle';
import TeamAndMembers from './TeamAndMembers';

const ConfigureServiceModal = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleModal);

    const getModalComponent = () => {
        if (isOpen?.isReposToMigrateOpen) {
            return <AdoReposToMigrate />;
        }
        if (isOpen?.isTeamsAndMembersOpen) {
            return <TeamAndMembers />;
        }
        if (isOpen?.isAssignReposOpen) {
            return <AssignReposToTeams />;
        }
        return null;
    };

    return (
        <Modal
            data-testid="configureServiceModal"
            title={getModalTitle(isOpen)}
            minwidth="40%"
            width="60%"
            handleClose={() => {
                setIsOpen({
                    ...isOpen,
                    isReposToMigrateOpen: false,
                    isTeamsAndMembersOpen: false,
                    isAssignReposOpen: false,
                });
            }}
            open={isOpen?.isReposToMigrateOpen || isOpen?.isTeamsAndMembersOpen || isOpen?.isAssignReposOpen}
        >
            {getModalComponent()}
        </Modal>
    );
};

export default ConfigureServiceModal;
