import theme from 'assets/style/theme';
import tableSortLabelClasses from '@mui/material/TableSortLabel/tableSortLabelClasses';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { TextField, Button } from '@mui/material';

const skuStyle = {
    wrapper: {
        // background: '#F8F8F8 0% 0% no-repeat padding-box',
        color: theme.palette.white,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        // px: theme.spacing(3),
        // pt: theme.spacing(2),
    },
    header: {
        // wrapper: { backgroundColor: theme.palette.white, borderBottom: '1px solid #0000000 !important', py: theme.spacing(2),paddingRight:theme.spacing(5),paddingLeft:theme.spacing(5) },
        wrapper: {
            background: '#FBFCFE 0% 0% no-repeat padding-box',
            px: 6,
            py: 1.2,
            zIndex: 99,
            boxShadow: 'rgba(0, 92, 188, 0.15) 0px 0px 24px 0',
        },
        skyTypeDD: {
            subscriptonWrapper: { maxWidth: '300px', width: '100%', backgroundColor: theme.palette.white },
            regionWrapper: { maxWidth: '300px', width: '100%', backgroundColor: theme.palette.white },
            wrapper: { backgroundColor: theme.palette.white, borderRadius: theme.spacing(0.25), width: '33.33%', maxWidth: theme.spacing(37.5) },
            select: {
                maxWidth: '300px',
                borderColor: '#E9E9E9',
            },
            textField: { backgroundColor: theme.palette.white, borderRadius: theme.spacing(0.625) },
            inputProps: { color: theme.palette.black.dark },
            totalCount: {
                borderRadius: theme.spacing(1.5),
                width: 'auto',
                marginRight: theme.spacing(2.75),
                color: theme.palette.blue.main,
                backgroundColor: theme.palette.white,
                fontWeight: '600',
                fontSize: theme.spacing(1.5),
                paddingLeft: theme.spacing(0.5),
                paddingRight: theme.spacing(0.5),
            },
        },
        search: {
            wrapper: { display: 'flex', alignItems: 'center', width: 300, border: '1px solid #CBCBCB', borderRadius: theme.spacing(3) },
            inputBase: { ml: theme.spacing(1), mt: theme.spacing(1), flex: theme.spacing(1), background: theme.palette.white, color: '#757575' },
            clearIcon: { color: theme.palette.blue.main },
            searchIcon1: { color: theme.palette.blue.main },
            searchIcon2: { color: theme.palette.blue.main },
        },
        headTextBox: { display: 'flex', gap: 1, width: 'auto' },
        headText: {
            font: theme.typography.fontSize.$font_heading_h5_medium,
            color: theme.palette.cmpBlack.doply,
        },
        searchContainer: { display: 'flex', justifyContent: 'flex-end', gap: 1, width: '80%' },
    },
    footer: {
        wrapper: {
            flexDirection: 'column',
            justifyContent: 'center',
            paddingRight: theme.spacing('53px'),
            paddingLeft: theme.spacing('53px'),
            paddingTop: theme.spacing('20px'),
        },
        subWrapper: { flexDirection: 'row', justifyContent: 'space-between', pb: theme.spacing(1) },
        selectedWrapper: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
        selectedText: { color: theme.palette.black.dark, fontSize: '14px' },
        activeInactiveBtn: { display: 'flex', gap: 1 },
        activeBtn: { textTransform: 'none' },
        InActiveBtn: { textTransform: 'none' },
        circularIcon: { marginLeft: '7px' },
    },
    pagination: {
        wrapper: { paddingLeft: theme.spacing('53px'), paddingRight: theme.spacing('53px') },
        subWrapper: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
        tableFooter: {
            height: '70px',
            border: '1px solid rgba(224, 224, 224, 1)',
            backgroundColor: '#FFFFFF',
        },
        tablePagination: {
            backgroundColor: '#FFFFFF',
            width: 'auto',
            color: theme.palette.black.dark,
            border: '1px solid #E0E0E0',
            '.MuiTablePagination-spacer': {
                flex: 0,
                // '-webkit-flex': 0,
            },
            '.MuiToolbar-root': {
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                alignItems: 'center',
            },
            '.MuiTablePagination-displayedRows': {
                fontWeight: 500,
                fontSize: '1rem',
                color: '#1E1E1E',
                paddingTop: '2px',
            },
        },
        footermsg: { fontSize: '14px', fontWeight: 'normal', color: theme.palette.black.dark },
    },
    details: {
        wrapper: { height: '400px', overflowY: 'auto', minWidth: '70%', overflowX: 'hidden', paddingTop: '10px' },
        // wrapper: { height: '400px',},
        jsonLabelValue: { margin: 0 },
        footer: { display: 'flex', justifyContent: 'flex-end', pt: theme.spacing(2) },
        activeBtn: {
            textTransform: 'none',
            // backgroundColor: '#91C24C',
            // '&:hover': {
            //     backgroundColor: '#91C24C',
            // },
        },
        circularIcon: { marginLeft: '7px' },
        headerText: { fontSize: '28px', fontWeight: 'normal', marginLeft: theme.spacing(4) },
        gridContainer: { marginLeft: theme.spacing('29px'), marginTop: theme.spacing('20px') },
    },
    list: {
        wrapper: {
            color: theme.palette.white,
            flexGrow: 1,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'row',
            pt: theme.spacing(1.7),
            pb: theme.spacing(1),
            paddingLeft: theme.spacing('53px'),
            paddingRight: theme.spacing('53px'),
        },
        tableContainer: {
            marginTop: theme.spacing(0.5),
            // border:'1px solid #E0E0E0',
            borderRadius: '3px',
            boxShadow: '1px 1px 2px #00000014',
        },
        table: { marginTop: theme.spacing(-1.125), borderSpacing: theme.spacing(0), minWidth: 500, height: 'auto' },
        checkboxTblHead: {
            height: theme.spacing(4),
            // color: theme.palette.white
            color: theme.palette.blue.strongDark,
        },
        loadingRow: { background: 'transparent !important' },
        loadingCell: { border: '0 !important' },
        checkbox: { height: theme.spacing(3.75), fill: theme.palette.blue.main },
        circularIcon: { marginLeft: theme.spacing(1.25), color: '#0047BA' },
        styledTableSortLabel: {
            [`&.${tableSortLabelClasses.active}`]: {
                // color: theme.palette.white,
                color: theme.palette.blue.strongDark,
            },
            '&:hover': {
                // color: theme.palette.white,
                color: theme.palette.blue.strongDark,
            },
        },
        styledTableCell: {
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: theme.palette.cyan['90'],
                color: theme.palette.blue.strongDark,
                font: theme.typography.fontSize.$font_small_bold,
                // backgroundColor: theme.palette.blue.main,
                // color: theme.palette.white,
                // fontSize: theme.spacing(2),
                padding: theme.spacing(0),
                height: '50px',
                border: 0,

                '&:second-child': {
                    '& svg': {
                        opacity: 1,
                        color: 'red',
                    },
                },
                '&:first-of-type': {
                    paddingLeft: '2px',
                },
                '&:not(:first-of-type)': {
                    paddingLeft: '13px',
                },
            },
            [`&.${tableCellClasses.body}`]: {
                font: theme.typography.fontSize.$font_xsmall_regular,
                color: theme.palette.cmpBlack.doply,
                // fontSize: 14,
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                paddingTop: '15px',
                // color: theme.palette.blue[30],
                height: '50px',
                cursor: 'pointer',
            },

            '&:first-of-type': {
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
            },
            '&:last-child': {
                borderRight: '1px solid rgba(224, 224, 224, 1)',
            },
        },
        styledTableRow: {
            backgroundColor: theme.palette.white,
            height: '50px',
            // '&:hover': {
            //     backgroundColor: theme.palette.cyan['90'],
            // },
            '&:nth-of-type(1) td': {
                height: '63px',
                paddingTop: '15px',
            },
            '&:last-child td, &:last-child th': {},
        },
        subscriptionCount: {
            fontSize: '16px',
            color: theme.palette.black.dark,
            paddingTop: theme.spacing(2.5),
            paddingLeft: theme.spacing('54px'),
            paddingBottom: theme.spacing(0.5),
        },
    },

    /* filterButton: { color: theme.palette.blue.main, borderColor: theme.palette.blue.main, border: '2px solid' },
    orderFilter: {
        container: {
            color: theme.palette.white,
            borderColor: '#0063cc',
            border: '2px solid',
        },
        wrapper: {
            background: '#FFFFFF',
            width: 260,
            padding: 2,
            position: 'relative',
            top: 2,
        },
        arrow: {
            position: 'absolute',
            fontSize: 60,
            top: -34,
            left: 130,
            fill: '#FFFFFF',
            zIndex: 1,
        },
        // filter: {
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     border: 'solid 1px #ffffff',
        //     marginRight: 1.5,
        //     paddingX: 0.5,
        // }
        filterIcon: { color: theme.palette.white },
        closeIcon: { cursor: 'pointer', '&:hover': { color: '#f99c24' } },
        popper: {
            container: { display: 'flex', justifyContent: 'end' },
        },
        dropdownStack: { mb: theme.spacing(2), pt: theme.spacing(1), width: '100%' },
    }, */
    loadingStack: { width: theme.spacing('100%'), height: 'calc(100vh - 100px)' },
};

export default skuStyle;

export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: theme.palette.blue.main,
    },
    '& label.MuiFormLabel-filled': {
        color: theme.palette.blue.main,
        padding: '0 2px',
    },
    '& label': {
        color: '#757575',
    },
    '& label.MuiFormLabel-filled+div fieldset': {
        border: ' 1px solid #0047BA',
    },
    '& div.subscriptionSelect .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.blue.main,
            color: '#757575',
        },
    },
    // '& .MuiOutlinedInput-root': {
    //     '& fieldset': {
    //         borderColor: '#E9E9E9',
    //         color: '#AFB8CC',
    //     },
    //     '&:hover fieldset': {
    //         borderColor: '#E9E9E9',
    //     },
    //     '&.Mui-focused fieldset': {
    //         borderColor: '#E9E9E9',
    //     },
    // },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#CBCBCB',
            color: '#757575',
        },
        '&:hover fieldset': {
            borderColor: theme.palette.blue.main,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.blue.main,
        },
        '&.MuiFormLabel-filled fieldset': {
            borderColor: '#0047BA',
        },
    },
});
export const SKuTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: theme.palette.blue.main,
    },
    '& label.MuiFormLabel-filled': {
        color: theme.palette.blue.main,
        padding: '0 2px',
    },
    '& label': {
        color: theme.palette.gray.light,
    },
    '& label.MuiFormLabel-filled+div fieldset': {
        border: ' 1px solid #0047BA',
    },
    '& div.subscriptionSelect .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.blue.main,
            color: theme.palette.gray.light,
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.blue.main,
            color: theme.palette.gray.light,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.blue.main,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.blue.main,
        },
        '&.MuiFormLabel-filled fieldset': {
            borderColor: '#0047BA',
        },
    },
});
export const ColorButton = styled(Button)(() => ({
    background: '#0047BA',
    color: theme.palette.white,
    '&:hover': {
        backgroundColor: '#0047BA',
        color: theme.palette.white,
    },
}));
