import React from 'react';
import { Stack, FormControl, Box, FormControlLabel } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/sso-okta/store';
import SsoOktaStyles, { CustomRadioGroup, CustomRadio } from 'pages/sso-okta/style';
import { ROLE_TO_SHOW } from 'pages/sso-okta/utils/constant';

const CertificateRadio = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);

    const setCertificateAvailable = (isCertificateAvailable) => {
        setOrder({
            ...order,
            isCertificateAvailable,
            serviceProviderSigningCert: null,
            businessJustification: null,
        });
    };
    const handleOnSelectChange = (selected) => {
        setCertificateAvailable(selected);
    };
    return (
        <>
            {ROLE_TO_SHOW?.includes(order?.dataClassification?.toLowerCase()) && (
                <Stack direction="row" spacing={1} sx={SsoOktaStyles.stack}>
                    <FormControl sx={SsoOktaStyles?.form?.radioLabel}>
                        <Box sx={{ color: '#0047BA', fontSize: '12px', fontWeight: 'bold' }} id="certRadioGrp">
                            Do you have Signing Certificate Available?
                        </Box>
                        <CustomRadioGroup
                            aria-label="AuthorizationRolesRadio"
                            name="authorizationRolesRequiredBtnGroup"
                            row
                            value={order?.isCertificateAvailable}
                            onChange={(event, value) => handleOnSelectChange(value)}
                        >
                            <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
                            <FormControlLabel
                                value="no"
                                sx={{
                                    marginLeft: '60px',
                                }}
                                control={<CustomRadio />}
                                label="No"
                            />
                        </CustomRadioGroup>
                    </FormControl>
                </Stack>
            )}
        </>
    );
};

export default CertificateRadio;
