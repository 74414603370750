import React from 'react';
import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import ApplicationSection from 'pages/reference-implementation/provisioning/RI002O/components/sections/Application';
import AccessMgmtSection from 'pages/reference-implementation/provisioning/RI002O/components/sections/AccessMgmtSection';
import ResourceMgmtSection from 'pages/reference-implementation/provisioning/RI002O/components/sections/ResourceMgmtSection';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI002O/store';

const IndexForm = () => {
    const order = useRecoilValue(OrderPayload);
    return (
        <Box flex={3} data-testid="index-form">
            <ApplicationSection />
            <ResourceMgmtSection />
            {!['production']?.includes(order?.environment?.toLowerCase()) && <AccessMgmtSection />}
        </Box>
    );
};

export default IndexForm;
